import {useEffect, useState} from "react";
import {loading, RequestState} from "../../../../Shared/StateHelper";
import axios from "axios";
import useDebounce from "../../../../Shared/Hooks";

const useGuests = () => {
    const [guests, setGuests] = useState(undefined)
    const [requestState, setRequestState] = useState(RequestState.IDLE)
    const [filter, setFilter] = useState(undefined)
    const debouncedGetGuests = useDebounce(filter, 1000)

    useEffect(() => {
        if(filter === undefined) return
        getGuests(filter)
    }, [debouncedGetGuests])

    useEffect(() => {
        if(filter === undefined) return
        setRequestState(loading(requestState))
    }, [filter])

    const getGuests = (filter) => {
        axios.get("/admin/console/medical_events/guests", {params: filter}).then(response => {
            setGuests(response.data)
            setRequestState(RequestState.SUCCESS)
        }).catch(error => {
            console.error(error)
            setRequestState(RequestState.ERROR)
        })
    }

    const create = (guest) => {
        return axios.post("/admin/console/medical_events/guest", {...guest})
    }

    return {guests, get: setFilter, create, state: requestState}
}

export default useGuests;