import React, {useEffect} from 'react';
import {t, wrapper} from "../../I18n/i18n";
import axios from "axios";
import {toast} from "react-toastify";
import OpticianCampaignsStore from "./OpticianCampaignsStore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import OpticianShopsStore from "../Shops/OpticianShopsStore";
import {observer} from "mobx-react";

const AddCampaignButton = observer(({id}) => {
    const {t: w} = wrapper("OPTICIAN_DASHBOARD.CAMPAIGNS")
    const [selectedShopId, setSelectedShopId] = React.useState(null);

    useEffect(() => {
        const elems = document.getElementById(id);
        const instance = M.Modal.init(elems, {});
        return () => instance.destroy();
    }, []);

    const createCampaign = () => {
        const name = document.getElementById("campaign_name").value;
        const website_url = document.getElementById("campaign_website_url").value;
        axios.put("/opticians/campaigns/create", {
            name: name,
            website_url: website_url,
            shop_id: selectedShopId
        }).then(response => {
            OpticianCampaignsStore.addCampaign(response.data)
            M.Modal.getInstance(document.getElementById(id)).close();
            toast.success(w("SUCCESS_CREATING_CAMPAIGN"))
        }).catch(_ => toast.error(w("ERROR_CREATING_CAMPAIGN")))
    }

    const shopToElem = (shop) => {
        if(!shop) return null;
        return {
            label: shop.name,
            value: shop.id
        }
    }

    const renderModal = () => {
        return <div id={id} className="modal">
            <div className="modal-content">
                <h4>{w("CREATE_CAMPAIGN")}</h4>
                <p>{w("CREATE_CAMPAIGN_DESCRIPTION")}</p>
                <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                    <div className="input-field outlined">
                        <input type="text" id="campaign_name"/>
                        <label htmlFor="campaign_name">{t("NAME")}</label>
                    </div>
                    <div className="input-field outlined">
                        <input type="text" id="campaign_website_url"/>
                        <label htmlFor="campaign_website_url">{w("WEBSITE_URL")}</label>
                    </div>
                    <div className="input-field">
                        <Autocomplete
                            id="shop_select"
                            options={OpticianShopsStore?.shops.map(shopToElem)}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => setSelectedShopId(newValue?.value)}
                            style={{width: "100%"}}
                            renderInput={(params) => <TextField {...params} label={w("SELECT_SHOP")} variant="outlined" />}
                        />
                    </div>

                </div>

            </div>
            <div className="modal-footer valign-wrapper" style={{paddingInline: 20, gap: 20, justifyContent: "right"}}>
                <a className="modal-close btn red darken-2 z-depth-0">{t("CANCEL")}</a>
                <a className="btn blue darken-2 z-depth-0" onClick={createCampaign}>{t("CREATE")}</a>
            </div>
        </div>
    }

    return <div>
        <a className="btn blue darken-2 z-depth-0 modal-trigger" data-target={id} style={{width: "100%"}}>
            {w("CREATE_CAMPAIGN")}
        </a>
        {renderModal()}
    </div>
});

AddCampaignButton.defaultProps = {
    id: "new_campaign_modal"
}

export default AddCampaignButton;