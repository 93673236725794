import React, {Component} from 'react';

export default class Prescription extends Component {
  render() {
    return (
      <div>
        <div className="row">
           <p style='white-space: pre-wrap;'>{this.props.prescription.text}</p>
        </div>
      </div>
    );
  }
}
