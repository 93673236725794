import React from "react";

export default function PdfReportsPanel({pdf_reports}){
    if(pdf_reports.length === 0){
        return null;
    }
    return (
        <div className="card-panel black" style={{borderRadius: '30px'}}>
            {
                pdf_reports.map((pdf_report, i) => (
                    <div key={i} className="row card-panel black" style={{borderRadius: '30px'}}>
                        <div className="col s12">
                            <embed src={pdf_report} width="100%" height="900px" />
                        </div>
                    </div>
                ))
            }

        </div>
    )
}