import React, {Component} from "react";
import axios from "axios";
import EditButton from "../../Shared/EditButton";
import {RequestState} from "../../Shared/StateHelper";
import {toast} from "react-toastify";
import Toastr from "../../Shared/Toastr";
import {ActNature} from "../../Shared/Enums/Enums";
import Loader from "../../Shared/Loader";
import Switch from "../../Shared/Switch";
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";

const _ = require('lodash');
const billings_json = require("./billings.json")
export default class Billing extends Component {
    constructor(props) {
        super(props);
        this.changePlatformPercent = this.changePlatformPercent.bind(this)
        this.changeOphtalmoPercent = this.changeOphtalmoPercent.bind(this)
        this.changeHnPrice = this.changeHnPrice.bind(this)
        this.changeOphtalmoHnPercent = this.changeOphtalmoHnPercent.bind(this)
        this.handlePlateform = this.handlePlateform.bind(this)
        this.handleRate = this.handleRate.bind(this)
        this.renderState = this.renderState.bind(this)
        this.state = {
            prices: [],
            selectedOption: null,
            disabledOptionParam: false,
            disabledOptionRate: false,
            requestState: RequestState.IDLE,
            billing_categories: undefined,
            billing_category: undefined,
            hn_fixed: false,
        }
        this.isHnFixed()
        this.getBillingCategory()
        this.getPrices()
        this.debouncedSavePrices = _.debounce(this.savePrices, 2 * 1000)
    }

    componentWillUnmount() {
        this.debouncedSavePrices.flush()
    }

    priceToPercent(price, total) {
        return total === 0 ? 0 : price * 100 / total
    }

    percentToPrice(percent, total) {
        return parseFloat((percent * total / 100).toFixed(2))
    }

    changePlatformPercent(v, pricing) {
        const platform_percent = v ? parseFloat(v.replace(',', '.')) : 0
        const totalPrice = pricing.price
        const percentExceeds = this.priceToPercent(pricing.ophtalmo_price, totalPrice) + platform_percent > 100
        if (pricing.platform_percent !== platform_percent && platform_percent <= 100) {
            // Need to change ophtalmo price if sum exceeds 100% of the total
            const new_price = Object.assign({}, pricing, {
                ophtalmo_price: percentExceeds ? this.percentToPrice(100 - platform_percent, totalPrice) : pricing.ophtalmo_price,
                platform_percent: platform_percent,
            })
            const new_prices = this.state.prices.map((price) => price.id === pricing.id ? new_price : price)
            this.setState({requestState: RequestState.LOADING, prices: new_prices}, () => this.debouncedSavePrices())
        }
    }

    changeOphtalmoPercent(v, pricing) {
        const ophtalmo_percent = v ? parseFloat(v) : 0
        const totalPrice = pricing.price
        const percentExceeds = pricing.platform_percent + ophtalmo_percent > 100
        if (pricing.ophtalmo_price !== this.percentToPrice(ophtalmo_percent, totalPrice) && ophtalmo_percent <= 100) {
            // Need to change platform price if sum exceeds 100% of the total
            const new_price = Object.assign({}, pricing, {
                ophtalmo_price: this.percentToPrice(ophtalmo_percent, totalPrice),
                platform_percent: percentExceeds ? 100 - v : pricing.platform_percent
            })
            const new_prices = this.state.prices.map((price) => price.id === pricing.id ? new_price : price)
            this.setState({requestState: RequestState.LOADING, prices: new_prices}, () => this.debouncedSavePrices())
        }
    }

    changeHnPrice(v, pricing) {
        v = v ? parseFloat(v.replace(',', '.')) : 0
        if (pricing.hn_price !== v) {
            const new_price = Object.assign({}, pricing, {hn_price: v})
            const new_prices = this.state.prices.map((price) => price.id === pricing.id ? new_price : price)
            this.setState({requestState: RequestState.LOADING, prices: new_prices}, () => this.debouncedSavePrices())
        }
    }

    changeOphtalmoHnPercent(v, pricing) {
        v = v ? parseFloat(v.replace(',', '.')) : 0
        if (pricing.hn_ophtalmo_percent !== v && v <= 100) {
            const new_price = Object.assign({}, pricing, {hn_ophtalmo_percent: v})
            const new_prices = this.state.prices.map((price) => price.id === pricing.id ? new_price : price)
            this.setState({requestState: RequestState.LOADING, prices: new_prices}, () => this.debouncedSavePrices())
        }
    }

    isHnFixed = () => {
        axios.get(`/admin/paiements/orthoptiste/${this.props.orthoptist_id}/hn_fixed`).then((resp) => {
            this.setState({hn_fixed: resp.data.is_hn_fixed})
        }).catch((err) => {
            toast.error("Une erreur s'est produite.")
        })
    }

    toggleHnFixed = () => {
        axios.patch(`/admin/paiements/orthoptiste/${this.props.orthoptist_id}/hn_fixed/toggle`).then((resp) => {
            this.setState({hn_fixed: resp.data.is_hn_fixed})
            this.getPrices()
        }).catch((err) => {
            toast.error("Une erreur s'est produite.")
        })
    }

    getBillingCategories = () => {
        axios.get(`/admin/paiements/billing_categories`).then((resp) => {
            this.setState({billing_categories: resp.data})
        }).catch((err) => {
            toast.error("Une erreur s'est produite.")
        })
    }

    getBillingCategory = () => {
        axios.get(`/admin/paiements/billing_category/${this.props.orthoptist_id}`).then((resp) => {
            this.setState({billing_category: resp.data}, this.getBillingCategories)
        }).catch((_) => {
            toast.error("Une erreur s'est produite.")
        })
    }

    setBillingCategory = (id) => {
        axios.patch(`/admin/paiements/orthoptiste/${this.props.orthoptist_id}/billing_category`, {billing_category_id: id}).then((resp) => {
            this.getPrices()
            this.getBillingCategory()
        }).catch((err) => {
            toast.error("Une erreur s'est produite.")
        })
    }

    getPrices() {
        axios.get(`/admin/paiements/orthoptiste/${this.props.orthoptist_id}/prices`).then((data) => {
            this.setState({prices: data.data.data})
        }).catch((err) => {
            toast.error("Une erreur s'est produite.")
            this.setState({requestState: RequestState.ERROR})
        })
    }

    savePrices() {
        axios.patch(`/admin/paiements/orthoptiste/${this.props.orthoptist_id}/prices`, {prices: this.state.prices}).then((data) => {
            this.setState({requestState: RequestState.SUCCESS})
        }).catch((err) => {
            toast.error("Une erreur s'est produite.")
            this.setState({requestState: RequestState.ERROR})
        })
    }

    handlePlateform = (event) => {
        this.setState({requestState: RequestState.LOADING})
        const {value} = event.target
        this.setState({selectedOption: parseInt(value, 10)}, () => {
            let prices = []
            for (let i = 0; i < billings_json.length; i++) {
                prices.push(billings_json[i].platform[this.state.selectedOption])
            }
            for (let i = 0; i < this.state.prices.length; i++) {
                if (!Object.keys(prices).some(key => prices[key].title === this.state.prices[i].title)) {
                    prices[Object.keys(prices).length] = this.state.prices[i];
                }
            }
            this.setState({prices: prices, disabledOptionParam: true}, () => {
                this.debouncedSavePrices()
            })
        })
    }

    handleRate = (value) => {
        value = value ? parseFloat(value.replace(',', '.')) : 0
        let prices = this.state.prices;
        const new_prices = prices.map((price) => {
            // if not telematical, nothing to do
            if (price.nature !== ActNature.TELEMEDICAL.name) return price

            // check if ophtalmo price changed, if not, nothing to do
            if (price.ophtalmo_price === value) return price

            const total = price.price
            // compute difference between new ophtalmo price and old one
            // it can be negative if ophtalmo price is decreased
            let diff = value - price.ophtalmo_price

            // Platform price must be decreased with the same amount, but can't be negative
            const platform_price = this.percentToPrice(price.platform_percent, total)

            if (platform_price - diff < 0) diff = platform_price

            // Ophtalmo price cannot exceed total price
            const ophtalmo_price = price.ophtalmo_price + diff
            const new_platform_price = platform_price - diff

            // if sum exceeds 100% of the total, we need to change platform price
            return Object.assign({}, price, {
                ophtalmo_price: ophtalmo_price,
                platform_percent: this.priceToPercent(ophtalmo_price + new_platform_price > total ? total - new_platform_price : new_platform_price, total)
            })
        })

        this.setState({requestState: RequestState.LOADING, prices: new_prices, disabledOptionRate: true}, () => {
            this.debouncedSavePrices()
        })
    }

    renderState() {
        switch (this.state.requestState) {
            case RequestState.IDLE:
                return <i></i>;
            case RequestState.SUCCESS:
                return <i className={`material-icons green-text`} style={{fontSize: "50px"}}>done</i>;
            case RequestState.LOADING:
                return <i className={`rotating material-icons grey-text`} style={{fontSize: "50px"}}>sync</i>;
            case RequestState.ERROR:
                return <i className={`material-icons red-text`} style={{fontSize: "50px"}}>close</i>;
            default:
                return null;
        }
    }

    renderTable = (prices, nature) => {
        return <div key={nature.name}>
            <h3 className="center">{nature.short}</h3>
            <table className="container striped bordered" style={{margin: "0px", width: "100%"}}>
                <thead>
                <tr>
                    <th className="center-align">Acte</th>
                    <th className="center-align">Quotation</th>
                    <th className="center-align">Gains Temeoo</th>
                    <th className="center-align">Gains Ophtalmo</th>
                    <th className="center-align">Gains Ortho</th>
                    <th className="center-align red-text">Prix HN</th>
                    <th className="center-align red-text">Part HN Ophtalmo</th>
                </tr>
                </thead>
                <tbody>
                {prices.sort((a, b) => a.title > b.title).map((pricing, index) => {
                        let ophtalmo_percent = this.priceToPercent(pricing.ophtalmo_price, pricing.price)
                        let orthoEarnings = pricing.price - this.percentToPrice(pricing.platform_percent, pricing.price) - this.percentToPrice(ophtalmo_percent, pricing.price)
                        const locked = this.state.billing_category?.billing_settings?.find((setting) => setting.actepossible_id === pricing.act_id) !== undefined
                        const locked_classname = locked ? "grey-text" : ""
                        return (
                            <tr key={index}>
                                <td className={locked_classname}>
                                    <i className={`material-icons left ${locked_classname} ${locked ? "": "hide"}`}
                                       style={{fontSize: "20px", marginRight: "5px"}}
                                       title={"Cet acte est lié à la catégorie de facturation, il sera modifié si vous modifiez la catégorie de facturation."}
                                    >lock
                                    </i>
                                    {pricing.title}
                                </td>
                                <td className={`center-align ${locked_classname}`}>{pricing.price}€</td>
                                <td className={`center-align ${locked_classname}`}>
                                    <EditButton color="transparent" textColor="blue"
                                                suffix={"%"}
                                                text={pricing.platform_percent.toFixed(2).toString()}
                                                onValidation={(v) => this.changePlatformPercent(v, pricing)}
                                                filter={(e) => {
                                                    return e.target.value.match(/^\d*[.,]?\d{0,2}$/g)
                                                }}/>
                                    {"(" + ((pricing.platform_percent / 100) * pricing.price).toFixed(2) + "€)"}
                                </td>
                                <td className={`center-align ${locked_classname}`}>
                                    <EditButton color="transparent" textColor="blue"
                                                suffix={"%"}
                                                text={ophtalmo_percent.toFixed(2).toString()}
                                                onValidation={(v) => this.changeOphtalmoPercent(v, pricing)}
                                                filter={(e) => {
                                                    return e.target.value.match(/^\d*[.,]?\d{0,2}$/g)
                                                }}/>
                                    {"(" + pricing.ophtalmo_price.toFixed(2) + "€)"}
                                </td>
                                <td className={`center-align ${locked_classname}`}>
                                    <div className={orthoEarnings === 0 ? "red-text" : ""}>
                                        {orthoEarnings.toFixed(2)}€
                                    </div>
                                </td>
                                <td className={`center-align ${locked_classname}`}>
                                    <EditButton color="transparent" textColor="blue"
                                                suffix={"€"}
                                                text={pricing.hn_price.toFixed(2).toString()}
                                                onValidation={(v) => this.changeHnPrice(v, pricing)}
                                                filter={(e) => {
                                                    return e.target.value.match(/^\d*[.,]?\d{0,2}$/g)
                                                }}/>
                                </td>
                                <td className={`center-align ${locked_classname}`}>
                                    <EditButton color="transparent" textColor="blue"
                                                suffix={"%"}
                                                text={pricing.hn_ophtalmo_percent.toFixed(2).toString()}
                                                onValidation={(v) => this.changeOphtalmoHnPercent(v, pricing)}
                                                filter={(e) => {
                                                    return e.target.value.match(/^\d*[.,]?\d{0,2}$/g)
                                                }}/>
                                    {`(${this.percentToPrice(pricing.hn_ophtalmo_percent, pricing.hn_price).toFixed(2)}€)`}
                                </td>
                            </tr>
                        )
                    }
                )
                }
                </tbody>
            </table>
        </div>
    }

    render() {
        if (!this.state.prices?.length) return <Loader/>
        return (
            <BorderedDisplay text="Tarifs" containerStyle={{borderRadius: 10}}>
                <div className="row" style={{gap: 20, paddingBlock: 20}}>
                    <div className="col s4">
                        {this.state.billing_categories ? <select className="browser-default" name="mySelect"
                                                                 defaultValue={this.state.billing_category?.id || 0}
                                                                 onChange={(e) => this.setBillingCategory(e.target.value)}>
                            <option value={0}>Paramètres personnalisés</option>
                            {this.state.billing_categories.map((billing_category) => {
                                return <option key={billing_category.id}
                                               value={billing_category.id}>{billing_category.name}</option>
                            })}
                        </select> : <Loader text="" size="small"/>
                        }
                    </div>
                    <div className="col s4">
                        <select className="browser-default" name="mySelectRate"
                                onChange={(e) => this.handleRate(e.target.value)}>
                            <option disabled={this.state.disabledOptionRate} defaultValue>Choisir un tarif pour
                                l'ophtalmo
                            </option>
                            <option value={1.0}>1.00 €</option>
                            <option value={1.5}>1.50 €</option>
                            <option value={2.0}>2.00 €</option>
                            <option value={2.5}>2.50 €</option>
                            <option value={3.0}>3.00 €</option>
                            <option value={3.5}>3.50 €</option>
                            <option value={4.0}>4.00 €</option>
                            <option value={50}>50.00 €</option>
                        </select>
                    </div>
                    <div className="col s4 valign-wrapper">
                        <Switch onChange={this.toggleHnFixed} id={"fixed_hn_switch"} left={"HN lié au tarif ophtalmo"} right={""} value={this.state.hn_fixed}/>
                    </div>
                    <div className="col s11 right-align">
                        {this.renderState()}
                    </div>
                </div>
                {Object.values(ActNature).map((nature) => {
                    const prices = this.state.prices.filter((price) => price.nature === nature.name)
                    if (!prices.length) return null
                    return this.renderTable(prices, nature)
                })}
                <Toastr/>
            </BorderedDisplay>
        )
    }
}