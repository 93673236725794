import React from 'react';
import Helper from "../../../Shared/Helper";
import {ActNature} from "../../../Shared/Enums/Enums";
import MedicalReport from "../../../admin/Controle/Report/MedicalReport";
import telemedical_questions_json
    from "../../../Appointments/Mesures/mesure_components/Questions/telemedical_questions.json";
import nurse_question_json from "../../../Appointments/Mesures/mesure_components/Questions/nurse_questions.json";
import DrugsReport from "../../../admin/Controle/Report/DrugsReport";
import BrandedElement from "../../../Shared/BrandedElement";
import {t} from "../../../I18n/i18n"

function AppointmentDetails({patient, act, details}) {

    const renderAnamnesis = () => {
        if (!details.anamnesis) return null
        return <>
            <h6 style={{fontWeight: "bolder", marginTop: 0}}>{t("ANAMNESIS")} :</h6>
            <h6 style={{whiteSpace: "pre-wrap"}}>{details.anamnesis}</h6>
        </>
    }

    const renderOrthopticText = () => {
        if (!details.orthoptic_text) return null
        return <BrandedElement temeoo>
            <h6 style={{fontWeight: "bolder"}}>{t("ORTHOPTIC")} :</h6>
            <h6 style={{whiteSpace: "pre-wrap"}}>{details.orthoptic_text}</h6>
        </BrandedElement>
    }

    const renderHistory = () => {
        if (!patient?.history) return null
        return <>
            <h6 style={{fontWeight: "bolder"}}>{t("BACKGROUND")} :</h6>
            <h6 style={{whiteSpace: "pre-wrap"}}>{patient.history}</h6>
        </>
    }

    const renderCommentForOphtalmologist = () => {
        if (!details?.comment_for_ophtalmologist) return null
        return <>
            <h6 style={{fontWeight: "bolder"}}>{t("COMMENT")} :</h6>
            <h6 style={{whiteSpace: "pre-wrap"}}>{details.comment_for_ophtalmologist}</h6>
        </>
    }

    const renderPathologies = () => {
        if (!details?.pathologies || !details.pathologies.length) return null
        return <>
            <h5 className="red-text" style={{fontWeight: "bolder"}}>{t("PATHOLOGY_SUSPECTED")}</h5>
            {details.pathologies.map((pathology, index) => {
                return <h6 className="red-text" key={index} style={{whiteSpace: "pre-wrap", fontWeight: "bolder"}}>- {pathology.pathologie}</h6>
            })}
        </>
    }

    const renderQuestions = () => {
        const report = details.report
        if (!report) return null
        switch (act?.nature) {
            case ActNature.TELEMEDICAL.name:
                return <MedicalReport report={report} json={telemedical_questions_json}/>
            case ActNature.NURSE.name:
                return <MedicalReport report={report} json={nurse_question_json}/>
            default:
                return null
        }
    }

    const renderDiabetesQuestions = () => {
        const questions = details.diabetes_questions
        if (!details?.diabetes_questions) return null
        return <>
            {questions.amy6_diabete ? <h5 style={{fontWeight: "bolder", margin: 0}}>Diabète : {questions.amy6_diabete}</h5> : null}
            {questions.annee_decouverte_diabete ? <h5 style={{margin: 0}}>Année : {questions.annee_decouverte_diabete}</h5> : null}
            {questions.hb1ac ? <h5 style={{margin: 0}}>Hb1ac : {questions.hb1ac}</h5> : null}
            {questions.ophtalmologiste_nom ? <h5 style={{margin: 0}}>Ophtalmologue : {questions.ophtalmologiste_nom}</h5> : null}
            {questions.suivi_ophtalmique ? <h5 style={{margin: 0}}>Suivi ophtalmique : {questions.suivi_ophtalmique}</h5> : null}
            {questions.ophtalmologiste_dernier_examen ? <h5 style={{margin: 0}}>Dernier examen : {questions.ophtalmologiste_dernier_examen}</h5> : null}
            {questions.hta ? <h5 style={{margin: 0}}>HTA : {questions.hta}</h5> : null}

        </>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div className="valign-wrapper" style={{gap: 20}}>
            <i className="material-icons red darken-2 white-text"
               style={{padding: 10, borderRadius: 25, fontSize: 16}}>description</i>
            <h4 style={{marginBlock: 0}}>{t("GENERAL_INFORMATION")}</h4>
        </div>
        <div
            className="card z-depth-0 blue lighten-5 row"
            style={{borderRadius: "0 0 30px 30px", borderTop: "thick solid #32a1ce"}}
        >
            <div className="col s12 m4">
                <h6 style={{marginTop: 0}}>{Helper.formatName(patient?.prenom, patient?.nom)}</h6>
                <h6>{patient?.birthday ? `${t("BORN_ON")} ${Helper.formatDate(patient?.birthday)} (${Helper.dateToAge(patient?.birthday)} ${t("YEARS_OLD")})` : t("NO_DATE_OF_BIRTH")}</h6>
                <h6>{patient?.telephone}</h6>
                <h6>{patient?.email}</h6>
                <h6>{t("ACTS")} : {act?.titre ?? t("NO_ACTS_SELECTED")}</h6>
                <h6>{details?.date ? `${t("VIEWED_ON")} ${details.date}` : ""}</h6>
            </div>
            <div className="col s12 m4">
                {renderAnamnesis()}
                {renderOrthopticText()}
                {renderHistory()}
                {renderCommentForOphtalmologist()}
                {renderPathologies()}
            </div>
            <div className="col s12 m4">
                {renderQuestions()}
                <DrugsReport drugs={patient?.drugs ?? []}/>
                {renderDiabetesQuestions()}
            </div>
        </div>
    </div>
}

export default AppointmentDetails;