import React, {useState} from "react"

const AnnualAppointmentsCount = ({appointmentsData}) => {

    const [showPercent, setShowPercent] = useState(false)

    const total_appointments = Object.values(appointmentsData).reduce((acc, currentValue) => acc + currentValue, 0)
    return <div style={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        marginTop: 10,
        width: "30%",
        margin: 20,
        position: "relative"
    }}>
        <div className="clickable"
             onClick={() => setShowPercent(!showPercent)}
             style={{
                 position: "absolute",
                 top: 15,
                 right: 10,
                 border: "1px solid grey",
                 borderRadius: "5px",
                 padding: "0 5px"
             }}>
            {showPercent ? "0.0" : "%"}
        </div>
        <p style={{marginLeft: 20, marginBottom: 0}}><b>Nombre de RDV par formule</b></p>
        <div style={{margin: 20, fontSize: 18}}>
            {Object.entries(appointmentsData)?.map((category, index) => {
                return <div key={index} style={{display: "flex", justifyContent: "space-between", gap: 20}}>
                    <div>{category[0].length ? category[0] : "Customisé"} :</div>
                    <div style={{display: "flex", gap: 5}}>
                        <div>{showPercent ?
                            `${(category[1] / total_appointments * 100).toFixed(2)}%` :
                            category[1]}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default AnnualAppointmentsCount