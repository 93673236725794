import React, {useState} from "react"
import CKEditor from "../Shared/CKEditor";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify";
import axios from "axios";
import {wrapper} from "../I18n/i18n";

const MAX_FILE_SIZE = 1048576 * 5

export default function Headers({signature_url, header, first_name, last_name}) {
    const {t} = wrapper("OPHTALMO_HEADERS")

    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content

    const [currentSignatureUrl, setSignatureUrl] = useState(signature_url)

    const handleHeaderChange = (text) => {
        axios.patch("/admin/headers/text", {text: text})
            .then((_) => toast.success(t("SUCCESSFUL_HEADER_SAVING")))
            .catch((_) => toast.error(t("ERROR_SAVING")))
    }

    const handleSignatureChange = (file) => {
        let formData = new FormData()

        file = file[0]
        const allowed_type = ["image/jpeg", "image/JPEG", "image/jpg", "image/JPG", "image/png", "image/PNG"]
        if (!allowed_type.includes(file.type)) {
            toast.error("Type de fichier invalide")
            return
        }
        formData.append(file.name, file, file.name)

        axios.patch("/admin/headers/signature", formData)
            .then((resp) => {
                setSignatureUrl(resp.data.signature_url)
                toast.success(t("SUCCESSFUL_SIGNATURE_SAVED"))
            })
            .catch((_) => toast.error(t("ERROR_SAVING")))

    }

    const renderCurrentSignature = () => {
        if(currentSignatureUrl) return <img src={currentSignatureUrl} style={{height: 200}} alt=""/>
        return <div style={{
            display: "flex",
            height: 200,
            fontSize: 25,
            fontFamily: "Brush Script MT Italic",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <em>{first_name[0].toUpperCase() + Helper.capitalizeFirstLetter(last_name)}</em>
        </div>
    }

    return <div className="container" style={{width: "90%"}}>
        <h1 className="center" style={{marginBottom: 10}}>{t("MANAGE_HEADER_SIGNATURE")}</h1>

        <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "center",}}>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <h3 className="center" style={{marginBottom: 10}}>{t("CURRENT_SIGNATURE")}</h3>
                {renderCurrentSignature()}
            </div>

            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <h3 className="center" style={{marginBottom: 10}}>{t("SELECT_NEW_SIGNATURE")}</h3>
                <Dropzone onDrop={handleSignatureChange} multiple={false} maxSize={MAX_FILE_SIZE}>
                    {({getRootProps, getInputProps}) => (
                        <section style={{cursor: "pointer"}}>
                            <div {...getRootProps()}>
                                <input data-cy="InputDropZone" {...getInputProps()} />
                                <div className="valign-wrapper"
                                     style={{height: 200, justifyContent: "center", border: "dashed 1px black"}}>
                                    <i className="material-icons medium">get_app</i>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        </div>
        <div className="divider" style={{marginTop: 10}}/>
        <div>
            <h3 className="center">{t("HEADER")}</h3>
            <div className="container" style={{width: "85%"}}>
                <CKEditor text={header} onChange={handleHeaderChange} options={{height: "25vh"}}/>
            </div>
        </div>
    </div>
}