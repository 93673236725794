import React, {useEffect, useRef, useState} from "react"
import {RequestState} from "../../../../Shared/StateHelper";
import Loader from "../../../../Shared/Loader";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";
import axios from "axios";
import {toast} from "react-toastify";
import {RecruitmentType} from "../../../../Shared/Enums/RecruitmentType";


export default function CommercialInformations({office_id}) {

    const [commercialsList, setCommercialsList] = useState([])
    const [selectedCommercial, setSelectedCommercial] = useState(null)
    const [selectedRecruitmentType, setSelectedRecruitmentType] = useState(null)
    const [comment, setComment] = useState("")
    const [requestState, setRequestState] = useState(RequestState.LOADING)

    const commercialRef = useRef(null)
    const recruitmentRef = useRef(null)

    useEffect(() => {
        getCommercialData()
        const instances = M.FormSelect.init([commercialRef, recruitmentRef], {})
        return () => instances?.destroy()
    }, []);

    const getCommercialData = () => {
        axios.get(`/admin/console/users/office/${office_id}/commercial_informations`)
            .then((resp) => {
                setCommercialsList(resp.data.internal_users)
                if (resp.data.commercial_informations) {
                    setSelectedCommercial(resp.data.commercial_informations.internal_user_id)
                    setSelectedRecruitmentType(RecruitmentType[resp.data.commercial_informations.recruitment_type]?.id ?? null)
                    setComment(resp.data.commercial_informations.comment ?? "")
                }
                setRequestState(RequestState.SUCCESS)
            })
            .catch((_) => console.log(_) && setRequestState(RequestState.ERROR))
    }

    const updateCommercialData = () => {
        axios.patch(`/admin/console/users/office/${office_id}/commercial_informations`, {
            comment: comment,
            internal_user_id: selectedCommercial,
            recruitment_type: selectedRecruitmentType,
        })
            .then((_) => toast.success("Enregistrement réussi"))
            .catch((_) => toast.error("Erreur dans l'enregistrement"))
    }

    const allowCommercialUpdate = () => {
        return selectedCommercial === null;
    }

    const renderCommercialInformations = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader wide/></div>
        if (requestState === RequestState.ERROR) return <div className="center">Une erreur est survenue...</div>
        if (requestState === RequestState.SUCCESS) return <div className="container">
            <div style={{marginBottom: 20}}>
                <label>Commercial en charge:</label>
                <select
                    style={{display: "block", opacity: 1}}
                    ref={commercialRef}
                    value={selectedCommercial ?? ""}
                    onChange={(e) => setSelectedCommercial(e.target.value)}>
                    <option value="">Selectionnez la personne en charge</option>
                    {commercialsList.map((commercialAgent, index) => {
                        return <option key={commercialAgent.id} value={commercialAgent.id}>
                            {Helper.formatName(commercialAgent.firstname, commercialAgent.lastname)}
                        </option>
                    })}
                </select>
                <label>Type de recrutement:</label>
                <select
                    style={{display: "block", opacity: 1}}
                    ref={recruitmentRef}
                    value={selectedRecruitmentType ?? ""}
                    onChange={(e) => setSelectedRecruitmentType(e.target.value)}
                    defaultValue={recruitmentRef.current?.value}>
                    <option value="">Selectionnez le type de recrutement</option>
                    {Object.values(RecruitmentType).map((type, index) => {
                        return <option key={index} value={type.id}>
                            {type.name}
                        </option>
                    })}
                </select>
            </div>


            <div className="input-field outlined">
                <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{resize: "none", padding: 10, height: 120, fontSize: 12}}
                />
                <label>Saisir un commentaire</label>
            </div>
            <a className={`btn blue darken-2 z-depth-0 right ${allowCommercialUpdate() ? "disabled" : ""}`}
               onClick={updateCommercialData}>Sauvegarder</a>
        </div>
    }


    return <div style={{paddingTop: 3}}>
        <BorderedDisplay text={"Informations commerciales"}>
            {renderCommercialInformations()}
        </BorderedDisplay>
    </div>

}