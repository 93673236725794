import React, {useEffect} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../../Shared/Loader";
import Helper from "../../../../Shared/Helper";
import ScopedElement from "../../../../Shared/ScopedElement";
import {Role} from "../../../../Shared/Enums/Scope";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import StatusButton from "../StatusButton";
import {Link} from "react-router-dom";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";
import {RequestState} from "../../../../Shared/StateHelper";

const _ = require('lodash');

function Opticians() {
    const modal_id = `console_opticians_modal`
    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [opticians, setOpticians] = React.useState([])
    const [onEditOptician, setOnEditOptician] = React.useState({})
    const [filter, setFilter] = React.useState("")

    useEffect(() => {
        get_opticians()
    }, []);

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [opticians, RightsStore.rights])

    const open_modal = () => {
        setTimeout(() => {
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.open();
        }, 100)
    }

    const new_optician = () => {
        setOnEditOptician({onEdit: false})
        open_modal()
    }

    const edit_optician = (extern) => {
        setOnEditOptician({...extern, onEdit: true})
        open_modal()
    }

    const get_opticians = () => {
        axios.get('/admin/console/users/optician/all').then((resp) => {
            setOpticians(resp.data)
            setRequestState(RequestState.SUCCESS)
        }).catch((_) => {
            toast.error("Impossible de récupérer les opticiens")
            setRequestState(RequestState.ERROR)
        })
    }

    const create_optician = () => {
        axios.put('/admin/console/users/optician', {
            optician: onEditOptician
        }).then((resp) => {
            setOpticians([...opticians, resp.data])
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.close();
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'opticien")
        })
    }

    const update_optician = (optician) => {
        axios.patch(`/admin/console/users/optician/${optician.id}`, {
            optician: optician
        }).then((resp) => {
            setOpticians(opticians.map(e => optician.id === e.id ? resp.data : e))
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.close();
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de sauvegarder les changements")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content container">
                <h3 className="center"
                    style={{marginTop: 20}}>{onEditOptician.onEdit ? "Éditer" : "Nouvel"} externe</h3>
                <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Nom d'utilisateur</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOptician.username || ""}
                                   onChange={(e) => setOnEditOptician({
                                       ...onEditOptician,
                                       username: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Email</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOptician.email || ""}
                                   onChange={(e) => setOnEditOptician({...onEditOptician, email: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Prénom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOptician.firstname || ""}
                                   onChange={(e) => setOnEditOptician({...onEditOptician, firstname: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Nom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOptician.lastname || ""}
                                   onChange={(e) => setOnEditOptician({...onEditOptician, lastname: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Mot de passe</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOptician.password || ""}
                                   onChange={(e) => setOnEditOptician({...onEditOptician, password: e.target.value})}/>
                        </div>
                    </div>
                    {/*<div className="col s12 valign-wrapper" style={{gap: 20}}>*/}
                    {/*    <span style={{width: 100}}>Cabinet</span>*/}
                    {/*    <select className="browser-default outlined" style={{width: "100%"}}*/}
                    {/*            value={onEditOptician.cabinet_id || ""}*/}
                    {/*            onChange={(e) => setOnEditOptician({*/}
                    {/*                ...onEditOptician,*/}
                    {/*                cabinet_id: e.target.value*/}
                    {/*            })}>*/}
                    {/*        <option value="" disabled>Choisir un cabinet</option>*/}
                    {/*        {offices.map((office) => {*/}
                    {/*            return <option key={office.id}*/}
                    {/*                           value={office.id}>{office.titre || "Cabinet sans nom"}</option>*/}
                    {/*        })}*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                {onEditOptician.onEdit ?
                    <a className="btn btn-small blue darken-2 z-depth-0"
                       onClick={() => update_optician(onEditOptician)}>Valider</a>
                    :
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={create_optician}>Créer</a>}
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderOptician = (optician) => {
        if (!optician) return (<div/>)
        return (
            <div className="collection-item transparent" key={optician.id} style={{display: "flex", width: "100%"}}>
                <div style={{width: "100%", margin: 0, display: "flex", alignItems: "center", gap: 10}}>
                    <div style={{width: "fit-content", margin: 0}}>
                        <StatusButton status={optician.status} id={optician.id}
                                      onChange={(value) => {
                                          if (optician.status !== value) {
                                              update_optician({...optician, status: value})
                                          }
                                      }}
                        />
                    </div>
                    <div style={{width: 40, margin: 0}}>
                        <span>#{optician.id}</span>
                    </div>
                    <div style={{width: "180px", margin: 0}}>
                        <span>{Helper.formatName(optician.username)}</span>
                    </div>
                    <div style={{width: "180px", margin: 0}}>
                        <span>{Helper.formatName(optician.firstname, optician.lastname)}</span>
                    </div>
                    <div style={{width: "300px", margin: 0}}>
                        <span>{optician.email}</span>
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <div style={{flex: 1}}>
                            <ResponsiveMoreButton id={`externs_${optician.id}`} options={[
                                <a className="btn btn-small blue darken-2 z-depth-0"
                                   onClick={() => edit_optician(optician)}>
                                    <i className="material-icons left">edit</i>
                                    Modifier
                                </a>,
                                <Link to={`/externs/${optician.id}`}
                                      className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">dashboard</i>
                                    Tableau de bord
                                </Link>
                            ]}/>
                        </div>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    if (requestState === RequestState.LOADING) return <div style={{margin: 20}}><Loader size="tiny" text=""/></div>
    if (requestState === RequestState.ERROR) return <div style={{margin: 20}}>Une erreur est survenue.</div>
    if (requestState === RequestState.SUCCESS) return <div
        style={{display: "flex", flexDirection: "column", gap: 20, padding: 20, height: "100%"}}>
        <h3 className="valign-wrapper" style={{margin: 20}}>
            <i className="material-icons left">storefront</i>
            Opticiens
        </h3>
        <div className="divider"/>
        <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div className="input-field outlined">
                    <input type="text" style={{height: 40}} placeholder={"Rechercher"} autoFocus
                           value={filter} onChange={e => setFilter(e.target.value)}/>
                </div>
                <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                    <a className="btn btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
                       onClick={new_optician}>
                        <i className="material-icons left">add</i>
                        Nouveau
                    </a>
                </RestrictedElement>
            </div>
            <div className="collection"
                 style={{borderRadius: 5, height: "90%", overflowY: "scroll"}}>
                {opticians.filter(o => {
                    if (!filter) return o
                    const firstname = o.firstname?.toLowerCase() || ""
                    const lastname = o.lastname?.toLowerCase() || ""
                    const email = o.email?.toLowerCase() || ""
                    const username = o.username?.toLowerCase() || ""
                    const testString = `${firstname} ${lastname} ${firstname} ${email} ${username}`.toLowerCase()
                    return testString.includes(filter.toLowerCase())
                })
                    .sort((a, b) => a.fullname > b.fullname)
                    .map((optician) => renderOptician(optician))}
            </div>
            <ScopedElement scope={[Role.SUPER_ADMIN, Role.ADMIN]}>
                {renderModal()}
            </ScopedElement>
        </div>
    </div>
}

export default Opticians;