import React, {useEffect, useState} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {t} from "../../../I18n/i18n";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import DisplayCard from "../../../Shared/UiKit/DisplayCard";

function IncompleteExaminations({}) {
    const [requestState, setRequestState] = useState(RequestState.LOADING)
    const [incompleteExamsCount, setIncompleteExamsscount] = useState(null);

    useEffect(() => getIncompleteFoldersCount(), [])

    const getIncompleteFoldersCount = () => {
        axios.get("/admin/statistiques/api/orthoptist/incompleteExams")
            .then(resp => {
                setIncompleteExamsscount(resp.data.uncomplete_exams_count)
                setRequestState(RequestState.SUCCESS)
            }).catch(() => {
            toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.NB_APPOINTMENTS"))
            setRequestState(RequestState.ERROR)
        })
    }

    const renderIncompleteFolderCount = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <h3
            className="valign-wrapper" style={{flex: 1, fontSize: "3em", margin: 0, paddingInline: "0.6em"}}>
            <b>{incompleteExamsCount}</b>
        </h3>

    }

    return <DisplayCard title="Dossiers incomplets" color="grey"
                        link="/agenda/index?done=true&uncomplete=true">{renderIncompleteFolderCount()}</DisplayCard>
}

export default IncompleteExaminations;