import React from "react";
import PatientInfo from "./PatientInfo";
import PatientSideBar from "../../patient_data/SideBar/PatientSideBar";
import HelpFAB from "../../Shared/Help/HelpFAB";
import CommentSideBar from "../../patient_data/SideBar/CommentSideBar";
import ExamHistory from "./History/ExamHistory";
import QuickAccess from "./QuickAccess";
import ScopedElement from "../../Shared/ScopedElement";
import {Role} from "../../Shared/Enums/Scope";

export default function Overview({data, cle, orthoptiste_signed, ophtalmologiste_signed, secretaire_signed, level_2}) {

    return <>
        <div id="full-height" className="row" style={{padding: 20, gap: 20}}>
            <div className="col s12 l3" style={{height: "100%"}}>
                <div className="fc" style={{gap: 20, height: "100%"}}>
                    <QuickAccess/>
                    <div className="fc" style={{gap: 20, height: "100%"}}>
                        <PatientInfo
                            style={{height: "50%"}}
                            patient={data.patient}
                            office_name={data.office_name}
                            cle={cle}
                            orthoptiste_signed={orthoptiste_signed}
                            ophtalmologiste_signed={ophtalmologiste_signed}
                            secretaire_signed={secretaire_signed}/>
                        <PatientSideBar style={{height: "50%"}} patient={data.patient}/>
                        {/*<CommentSideBar style={{height: "30%"}} patient={data.patient}/>*/}
                    </div>
                </div>

            </div>
            <div className="col s12 l9" style={{height: "100%"}}>
                <ExamHistory patient_id={data.patient.id}/>
            </div>

        </div>
        <HelpFAB url="https://www.temeoo.fr/orthoptists/documentation/fiche-patient" position="bottom-left"/>
    </>
}