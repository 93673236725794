import React from 'react';
import {formatAcuities, formatRetinos} from "../../Appointments/Mesures/mesure_components/mesures";
import {Side, TypesMesures} from "../../Shared/Enums/Enums";
import {t} from "../../I18n/i18n"

const DocumentMesure = ({mesures}) => {

    mesures.sort((mesureA, mesureB) => {
        const indexA = Object.values(TypesMesures).indexOf(mesureA.measure_name);
        const indexB = Object.values(TypesMesures).indexOf(mesureB.measure_name);
        return indexA - indexB;
    });
    const showMesure = (mesures) => {
        return <div style={{display: "flex", flexDirection: "column", gap: 10, paddingBlock: 10}}>
            {mesures.map((mesure) => {
                switch (mesure.measure_name) {
                    case TypesMesures.TENSION:
                        return <div key={mesure.measure_name}
                                    style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
                            <h5 style={{width: 140, margin: 0}}>PIO (mmHg)</h5>
                            <h5 style={{width: 320, paddingInline: 50, margin: 0}}>{mesure.od_tension}</h5>
                            <h5 style={{margin: 0}}>{mesure.og_tension}</h5>
                        </div>
                    case TypesMesures.PACHYMETRY:
                        return <div key={mesure.measure_name}
                                    style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
                            <h5 style={{width: 140, margin: 0}}>{mesure.measure_name}</h5>
                            <h5 style={{width: 320, paddingInline: 50, margin: 0}}>{mesure.od_pachymetrie}</h5>
                            <h5 style={{margin: 0}}>{mesure.og_pachymetrie}</h5>
                        </div>
                    case TypesMesures.KERATOMETRY:
                        return <div key={mesure.measure_name}
                                    style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
                            <h5 style={{width: 140, margin: 0}}>{mesure.measure_name}</h5>
                            <h5 style={{width: 320, paddingInline: 50, margin: 0}}>R1 {mesure.od_radius_1} R2 {mesure.od_radius_2} D {mesure.od_degree}</h5>
                            <h5 style={{margin: 0}}>R1 {mesure.og_radius_1} R2 {mesure.og_radius_2} D {mesure.og_degree}</h5>
                        </div>
                    default:
                        return <div key={mesure.measure_name} className="valign-wrapper"
                                    style={{paddingRight: 40}}>
                            <h5 style={{width: 140, margin: 0}}>{mesure.measure_name}</h5>
                            <h5 style={{width: 320, paddingInline: 50, margin: 0}}>
                                {formatRetinos(mesure, Side.RIGHT)}
                                {!formatRetinos(mesure, Side.RIGHT) || <br/>}
                                {formatAcuities(mesure, Side.RIGHT, true)}
                            </h5>
                            <h5 style={{margin: 0}}>
                                {formatRetinos(mesure, Side.LEFT)}
                                {!formatRetinos(mesure, Side.LEFT) || <br/>}
                                {formatAcuities(mesure, Side.LEFT, true)}
                            </h5>
                        </div>
                }
            })}
        </div>
    };

    return <>
        <div style={{display: "flex", flexDirection: "row", paddingRight: 40, marginBottom: 5}}>
            <h5 style={{width: 140, margin: 0}}><b>{t("MEASURES")} :</b></h5>
            <h5 style={{width: 320, paddingInline: 50, margin: 0}}>{t("RIGHT_EYE")}</h5>
            <h5 style={{margin: 0}}>{t("LEFT_EYE")}</h5>
        </div>
        {showMesure(mesures)}
    </>
}

export default DocumentMesure;