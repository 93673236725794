import React from 'react';
import AppointmentComments from "./AppointmentComments";
import AppointmentMeasures from "./AppointmentMeasures";
import AlertResults from "./AlertResults";

const AppointmentDetails = ({appointment}) => {
    return <div>
        <div className="divider"/>
        <div className="row gap-10" style={{paddingBlock: 20}}>
            <div className="col s12 m6">
                <AppointmentComments appointment={appointment}/>
            </div>
            <div className="col s12 m6">
                <AlertResults appointment={appointment}/>
                <AppointmentMeasures appointment={appointment}/>
            </div>
        </div>
    </div>

}

export default AppointmentDetails;