import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Modal from '@material-ui/core/Modal';
import {t} from "../../I18n/i18n";
import Helper from "../../Shared/Helper";
import Validator from "../../Shared/Validator";
import ControleStore from "../../admin/Controle/ControleStore"

// TODO : A retravailler
export default function PossibleExamens() {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const modal_element = useRef(null)

    const [examens, setExamens] = useState([])
    const [open, setOpen] = useState(false);
    const [examenToAdd, setExamenToAdd] = useState("")

    useEffect(() => {
        loadExamens()
        const modal = M.Modal.init(modal_element.current)
        return () => {
            modal.destroy()
        }
    }, [])
    const loadExamens = () => {
        axios.get("/admin/possible_examens/examens").then((data) => {
            setExamens(data.data.examens)
        })
    }

    function deletePossibleExam(exam_id) {
        axios.delete("/admin/possible_examens/examen/" + exam_id).then((response) => {
            toast.success(t("POSSIBLE_EXAM.TOAST.DELETE_SUCCESS"));
            setExamens(prevState => prevState.filter(examen => examen.id !== exam_id));
        }).catch((_) => {
            toast.error(t("POSSIBLE_EXAM.TOAST.DELETE_ERROR"));
        });
    }

    function handleSave() {
        axios.post("/admin/possible_examens/examen", {title: examenToAdd}).then((response) => {
            setExamens(prevState => [...prevState, response.data]);
            toast.success(t("POSSIBLE_EXAM.TOAST.CREATE_SUCCESS"));
        }).catch((_) => {
            toast.error(t("POSSIBLE_EXAM.TOAST.CREATE_ERROR"));
        });
    }

    const renderModal = () => {
        return <div style={{width: 700, background: "white", padding: "20px"}} id="create_exam_modal" className="modal"
                    ref={modal_element}>
            <div className="modal-content">
                <h4 className="center">{t("POSSIBLE_EXAM.ADD_EXAM")}</h4>
                <div className="row">
                    <div className="input-field outlined col s12">
                        <input id="name" type="text" className="validate" value={examenToAdd} onChange={(e) => {
                            setExamenToAdd(e.target.value);
                        }}/>
                        <label htmlFor="name">{t("POSSIBLE_EXAM.EXAM_NAME")}</label>
                    </div>
                </div>
                <br/>
                <div className="valign-wrapper right">
                    <a className="btn blue darken-2 z-depth-0 modal-close" onClick={(e) => {
                        e.preventDefault();
                        handleSave();
                    }}>
                        <i className="material-icons left">done</i>
                        {t("VALIDATE")}
                    </a>
                    <a className="btn red darken-2 z-depth-0 modal-close">
                        <i className="material-icons left">cancel</i>
                        {t("CANCEL")}
                    </a>
                </div>
            </div>
        </div>
    }

    return (
        <div style={{width: "90%", margin: "0 auto"}}>
            <p><a onClick={(e) => {
                e.preventDefault()
            }} className="btn blue darken-2 z-depth-0 modal-trigger"
                  data-target="create_exam_modal">{t("POSSIBLE_EXAM.ADD_EXAM")}</a></p>
            <div style={{height: "65vh", overflowY: "auto"}}>
                <table className="striped bordered">
                    <thead>
                    <tr>
                        <th style={{width: "90%", paddingLeft: 10}}>{t("NAME")}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {ControleStore.possible_examens.map((default_exam, index) => {
                        return (
                            <>
                                {default_exam.default === true ?
                                    <tr key={index}>
                                        <td style={{paddingLeft: 50}}>{default_exam.title}</td>
                                        <td>
                                            <a className="btn red darken-2 z-depth-0 disabled">
                                                <i className="material-icons">delete</i>
                                            </a>
                                        </td>
                                    </tr>
                                    :
                                    <></>
                                }
                            </>
                        )
                    })}
                    {examens.map((examen, index) => {
                        return (
                            <tr key={index}>
                                <td style={{paddingLeft: 50}}>{examen.title}</td>
                                <td>
                                    <Validator
                                        onValidation={() => deletePossibleExam(examen.id)}
                                        text={`${t("RISK_ALERT_MANAGEMENT.RISK_ALERT_MANAGEMENT")} ${Helper.capitalizeFirstLetter(examen.title)} ?`}
                                        detail={t("RISK_ALERT_MANAGEMENT.ACTION_IRREVERSIBLE")}
                                        validationText={t("DELETE")} validationColor="red darken-2"
                                        validationIcon="delete"
                                        abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                                        id={`delete_text_alert_${examen.id}`}>
                                        <a className="btn red darken-2 z-depth-0">
                                            <i className="material-icons">delete</i>
                                        </a>
                                    </Validator>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {renderModal()}
        </div>
    )
}
