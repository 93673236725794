import React, {useRef} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {t, wrapper} from "../../../I18n/i18n";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import HelpIcon from "../../../Shared/Help/HelpIcon";
import useShortcuts, {defaultShortcuts} from "../../../Shared/Preferences/hooks/useShortcuts";


const CustomizationPanel = () => {
    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    const {t: w} = wrapper("OPTIONS.CUSTOMIZATION")
    const triggerRef = useRef()
    const replacementRef = useRef()

    let {shortcuts, saveShortcuts} = useShortcuts()
    shortcuts = shortcuts.filter(shortcut => !shortcut.hidden)

    const save = (shortcuts) => {
        saveShortcuts(shortcuts).catch((_) => toast.error(w("ACCEPTED_ACTS.ERROR")))
    }

    const fillAddShortcut = (trigger, replacement) => {
        triggerRef.current.value = trigger
        replacementRef.current.value = replacement
    }

    const renderAddShortcut = () => {
        return <BorderedDisplay text={w("SHORTCUTS.ADD_SHORTCUT")}>
            <h6 style={{marginBlock: "0px 10px"}}>{w("SHORTCUTS.ADD_SHORTCUT_DESCRIPTION")}</h6>
            <div style={{display: "flex", gap: 10, alignItems: "center", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "column", gap: 10, flex: 1}}>
                    <div className="input-field outlined">
                        <input ref={triggerRef} type="text"/>
                        <label htmlFor="sujet">{w("SHORTCUTS.TRIGGER")}</label>
                    </div>
                    <div className="input-field outlined">
                        <textarea ref={replacementRef} style={{paddingBlock: 20, minHeight: 100}}/>
                        <label htmlFor="sujet">{w("SHORTCUTS.REPLACEMENT")}</label>
                    </div>
                </div>
                <a className="btn blue darken-2 z-depth-0" onClick={() => {
                    if(triggerRef.current.value === "" || replacementRef.current.value === "") return toast.error(w("SHORTCUTS.EMPTY_FIELDS"))
                    // if shortcut already exists, replace it
                    if(shortcuts.find(shortcut => shortcut.trigger === triggerRef.current.value.replace(/\s+/g, ""))){
                        let newShortcuts = shortcuts
                        newShortcuts = newShortcuts.map(shortcut => {
                            if(shortcut.trigger === triggerRef.current.value.replace(/\s+/g, "")){
                                return {trigger: triggerRef.current.value.replace(/\s+/g, ""), replacement: replacementRef.current.value}
                            }
                            return shortcut
                        })
                        save(newShortcuts)
                        return
                    }
                    let newShortcuts = shortcuts
                    newShortcuts.push({trigger: triggerRef.current.value.replace(/\s+/g, ""), replacement: replacementRef.current.value})
                    save(newShortcuts)
                }}>
                    <i className={"material-icons"}>add</i>
                </a>
            </div>

        </BorderedDisplay>

    }

    const renderShortcutTable = () => {
        return <table className="temeoo-table" style={{width: "100%"}}>
            <thead>
            <tr>
                <th>{w("SHORTCUTS.TRIGGER")}</th>
                <th>{w("SHORTCUTS.REPLACEMENT")}</th>
            </tr>
            </thead>
            <tbody style={{fontSize: "1.2em"}}>
            {shortcuts.map((shortcut, index) => {
                const isDefault = defaultShortcuts.find(defaultShortcut => defaultShortcut.trigger === shortcut.trigger && defaultShortcut.replacement === shortcut.replacement)
                return <tr className="highlighted" key={index}>
                    <td>
                        {shortcut.trigger}
                        {isDefault ?
                            <span title={w("SHORTCUTS.DEFAULT_TOOLTIP")} style={{fontStyle: "italic"}}>
                                &nbsp;(default)</span>
                            : null}
                    </td>
                    <td style={{whiteSpace: "pre-wrap"}}>{shortcut.replacement}</td>
                    <td>
                        <div className="valign-wrapper right">
                            <a className={`btn blue darken-2 z-depth-0 `}
                               onClick={() => fillAddShortcut(shortcut.trigger, shortcut.replacement)}>
                                <i className={"material-icons"}>edit</i>
                            </a>
                            <a className={`btn red darken-2 z-depth-0 ${isDefault ? "disabled" : ""}`}
                               onClick={() => {
                                let newShortcuts = shortcuts
                                newShortcuts.splice(index, 1)
                                save(newShortcuts)
                                fillAddShortcut("", "")
                            }}>
                                <i className={"material-icons"}>delete</i>
                            </a>

                        </div>

                    </td>
                </tr>
            })}
            </tbody>
        </table>
    }

    return <div className="container"
                style={{width: "100%", padding: 20, height: "100%", display: "flex", flexDirection: "column", gap: 20}}>
        <div className="center valign-wrapper"
             style={{display: 'flex', padding: 20, justifyContent: 'center', gap: 20}}>
            <h1 className="center" style={{margin: 0}}>{w("TITLE")}</h1>
            <HelpIcon url="https://www.temeoo.fr/orthoptists/documentation/gestion"/>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <h2 style={{margin: 0}}>{w("SHORTCUTS.TITLE")}
                <span className="badge blue darken-2 left white-text"
                      style={{marginRight: 10, borderRadius: 4}}>BETA</span>
            </h2>
            <h6 style={{fontStyle: "italic"}} className="blue-text text-darken-2">{w("SHORTCUTS.BETA_DETAIL")}</h6>
            <label>{w("SHORTCUTS.DESCRIPTION")}</label>
            <h6 style={{fontStyle: "italic", marginBlock: '0 20px'}}>{w("SHORTCUTS.HOW_TO")}</h6>

            {renderAddShortcut()}
            {renderShortcutTable()}
        </div>
    </div>

}

export default CustomizationPanel