import React from 'react';

const EquipedOfficesMetric = ({offices}) => {

    const part = (earning, nb) => {
        if (!nb || !earning) return 0;
        return (earning / nb).toFixed(2);
    }
    return <>
        <div style={{border: "1px solid lightgrey", borderRadius: "10px", width: 200, marginTop: 10, height: 120}}>
            <p style={{marginLeft: 20, marginBlock: 0}}><b>Cabinets équipés</b></p>
            <p style={{margin: "0px 20px", fontSize: 18}}>
                <b>{offices?.equiped?.nb_active ?? 0}</b>
            </p>
            <p style={{marginLeft: 20, marginBottom: 0}}><b>Cabinets non équipés</b></p>
            <p style={{margin: "0px 20px", fontSize: 18}}>
                <b>{offices?.unequiped?.nb_active ?? 0}</b>
            </p>
        </div>
        <div style={{border: "1px solid lightgrey", borderRadius: "10px", width: 250, marginTop: 10, height: 120}}>
            <p style={{marginLeft: 20, marginBlock: 0}}><b>CA cabinets équipés</b></p>
            <p style={{margin: "0px 20px", fontSize: 18}}>
                <b>{part(offices?.equiped?.earnings, offices?.equiped?.nb_active)} €</b>
            </p>
            <p style={{marginLeft: 20, marginBottom: 0}}><b>CA cabinets non équipés</b></p>
            <p style={{margin: "0px 20px", fontSize: 18}}>
                <b>{part(offices?.unequiped?.earnings, offices?.unequiped?.nb_active)} €</b>
            </p>
        </div>
    </>
}

export default EquipedOfficesMetric;