import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

function DraggableElement(props) {
    try{

    } catch (e) {
        props.onDragEnd(0,0)
    }
    const onDragEnd = (e) => {
        if(e.destination === null) props.onDragEnd(0,0)
        else props.onDragEnd(e.source.index, e.destination.index)
    };

    const onDragStart = (e) => {

        props.onDragStart()
    }

    return <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
    >
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {props.children.map((child, index) => {
                        return <Draggable key={child.key} draggableId={child.key} index={index}>
                            {(provided, snapshot) =>
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    {child}
                                </div>
                            }
                        </Draggable>
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
}

DraggableElement.defaultProps = {
    context: false
}
export default DraggableElement