import React from "react";
import EarningsFromOrthoptist from "./EarningsFromOrthoptist";
import {NavLink, Route, HashRouter as Router, Routes} from "react-router-dom";
import EarningsByOrthoptists from "./EarningsByOrthoptists";
import {t} from "../../I18n/i18n"
import GeneralEarnings from "./GeneralEarnings/GeneralEarnings";

const OphtalmologistMonitoring = ({orthoptists}) => {

    return <Router>
        <ul className="tabs tabs-fixed-width transparent">
            <li className="tab">
                <NavLink className="grey-text text-darken-3" to={"/"}>{t("MONITORING_PAGE.INCOME")}</NavLink>
            </li>
            <li className="tab">
                <NavLink className="grey-text text-darken-3"
                         to={"/by_orthoptist"}>{t("MONITORING_PAGE.INCOME_BY_ORTHO")}</NavLink>
            </li>
            <li className="tab">
                <NavLink className="grey-text text-darken-3" to={"/detail"}>{t("MONITORING_PAGE.DETAIL")}</NavLink>
            </li>
        </ul>
        <Routes>
            <Route path='/' element={<GeneralEarnings/>}/>
            <Route path='/by_orthoptist' element={<EarningsByOrthoptists/>}/>
            <Route path='/detail' element={<EarningsFromOrthoptist orthoptists={orthoptists}/>}/>
            <Route path='/detail/:id' element={<EarningsFromOrthoptist orthoptists={orthoptists}/>}/>
        </Routes>
    </Router>
}

export default OphtalmologistMonitoring;