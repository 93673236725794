import React, {useEffect} from 'react';
import {t, wrapper} from "../../I18n/i18n";
import axios from "axios";
import {toast} from "react-toastify";
import OpticianShopsStore from "./OpticianShopsStore";

const AddShopButton = ({id}) => {
    const {t: w} = wrapper("OPTICIAN_DASHBOARD.SHOPS")

    useEffect(() => {
        const elems = document.getElementById(id);
        const instance = M.Modal.init(elems, {});
        return () => instance.destroy();
    }, []);

    const createShop = () => {
        const name = document.getElementById("shop_name").value;
        const address = document.getElementById("shop_address").value;
        const phone = document.getElementById("shop_phone").value;
        const email = document.getElementById("shop_email").value;
        axios.put("/opticians/shops/create", {
            name: name,
            address: address,
            phone: phone,
            email: email
        }).then(response => {
            OpticianShopsStore.addShop(response.data)
            M.Modal.getInstance(document.getElementById(id)).close();
            toast.success(w("SUCCESS_CREATING_SHOP"))
        }).catch(_ => toast.error(w("ERROR_CREATING_SHOP")))
    }

    const renderModal = () => {
        return <div id={id} className="modal">
            <div className="modal-content">
                <h4>{w("CREATE_SHOP")}</h4>
                <p>{w("CREATE_SHOP_DESCRIPTION")}</p>
                <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                    <div className="input-field outlined">
                        <input type="text" id="shop_name"/>
                        <label htmlFor="shop_name">{t("NAME")}</label>
                    </div>
                    <div className="input-field outlined">
                        <input type="text" id="shop_address"/>
                        <label htmlFor="shop_address">{t("ADDRESS")}</label>
                    </div>
                    <div className="input-field outlined">
                        <input type="text" id="shop_phone"/>
                        <label htmlFor="shop_phone">{t("PHONE_NUMBER")}</label>
                    </div>
                    <div className="input-field outlined">
                        <input type="text" id="shop_email"/>
                        <label htmlFor="shop_email">{t("EMAIL")}</label>
                    </div>
                </div>

            </div>
            <div className="modal-footer valign-wrapper" style={{paddingInline: 20, gap: 20, justifyContent: "right"}}>
                <a className="modal-close btn red darken-2 z-depth-0">{t("CANCEL")}</a>
                <a className="btn blue darken-2 z-depth-0" onClick={createShop}>{t("CREATE")}</a>
            </div>
        </div>
    }

    return <div>
        <a className="btn blue darken-2 z-depth-0 modal-trigger" data-target={id} style={{width: "100%"}}>
            {w("CREATE_SHOP")}
        </a>
        {renderModal()}
    </div>
};

AddShopButton.defaultProps = {
    id: "new_shop_modal"
}

export default AddShopButton;