import React from 'react'
import {Logical, QuestionType} from "../../../Shared/Enums/Enums";

const _ = require('lodash');

function MedicalReport({report, json}) {

    const renderValue = (value, type) => {
        if (type === "YES_NO") return value ? "Oui" : "Non"
        return value
    }

    const renderReport = () => {
        const emptyReport = !Object.values(report).reduce((acc, value) => {
            return acc || !!value;
        }, false);

        if (_.isEmpty(report) || emptyReport) return <h5>Questionnaire : RAS</h5>
        return <div className="row">
            {json.map((category, index) => {
                const emptyCategory = !category.questions.reduce((acc, question) => {
                    const question_id = question.label
                    return acc || !!report[question_id];
                }, false);
                if (emptyCategory) return null
                return <div className="col s12" key={index}>
                    <h5 className="left-align"
                        style={{marginBlock: "0 5px", fontWeight: "bolder"}}>{category.label}</h5>
                    {category.questions.map((question) => {
                            const question_id = question.label
                            if (!report[question_id]) return
                            // if (question.type === QuestionType.INPUT && report[question_id] === Logical.NO) return
                            const needHighlight = question.type === QuestionType.YES_NO || (question.type === QuestionType.INPUT && report[question_id] === Logical.YES)
                            return <div key={`${index}_${question_id}`} style={{display: "flex", marginBlock: 8}}>
                                <div style={{display: "flex", alignItems: "center", width: "65%"}}>
                                    <h6 className={needHighlight ? "red-text" : "hide"}
                                        style={{fontWeight: "bold", marginBlock: 5}}>{question.label}</h6>
                                    <h6 className={needHighlight ? "hide" : ""} style={{marginBlock: 5}}>
                                        {question.label} : {renderValue(report[question_id], question.type)}
                                    </h6>
                                </div>
                                {question.type === QuestionType.YES_NO && report[question_id] && question.subquestions?.map((subquestion) => {
                                    const subquestion_id = `${question_id}##${subquestion.label}`
                                    if (!report[subquestion_id]) return <h6 style={{paddingLeft: 20}}>NA</h6>
                                    return <div key={`${index}_${subquestion_id}`} style={{width: "35%"}}>
                                        <div style={{display: "flex", alignItems: "center", paddingLeft: "20px"}}>
                                            <h6 className={subquestion.type === QuestionType.YES_NO ? "" : "hide"}
                                                style={{marginBlock: 5}}>
                                                {subquestion.label} :&nbsp;
                                            </h6>
                                            <h6 className={subquestion.type === QuestionType.YES_NO ? "hide" : ""}
                                                style={{marginBlock: 5}}>
                                                {renderValue(report[subquestion_id], subquestion.type)}
                                            </h6>
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    )}
                </div>
            })}
        </div>
    }
    return renderReport()
}

export default MedicalReport