import React, {useRef, useState} from 'react';
import moment from "moment/moment";
import axios from "axios";
import {toast} from "react-toastify";
import Helper from "../../../Shared/Helper";

const AppointmentDate = ({appointment, onChange}) => {
    const [onEdit, setOnEdit] = useState(false)
    const dateRef = useRef(null)
    const timeRef = useRef(null)

    const saveDate = () => {
        const date = dateRef.current.value
        const time = timeRef.current.value
        const dateTimeString = `${date}T${time}:00`
        const newDate = moment(dateTimeString)
        axios.post(`/patients/appointment_update/${appointment.id}`, {start: newDate}).then(_ => {
            onChange(newDate)
            setOnEdit(false)
        }).catch((error) => {
            toast.error("Une erreur s'est produite lors de la modification de la date.")
        })
    }

    if (onEdit) return <div className="fr valign-wrapper">
        {/*Input date*/}
        <input type="date" defaultValue={Helper.formatDate(appointment.start, "YYYY-MM-DD")} ref={dateRef}
               style={{height: 30}}/>
        {/*Input time*/}
        <input type="time" defaultValue={Helper.formatDate(appointment.start, "hh:mm")} ref={timeRef}
               style={{height: 30}}/>
        <div className="fr" style={{gap: 0}}>
            {/*Button save*/}
            <a className="btn-small green darken-2 z-depth-0" onClick={saveDate} style={{paddingInline: 5}}>
                <i className="material-icons">done</i>
            </a>
            {/*Button cancel*/}
            <a className={`btn-small red darken-2 z-depth-0`} onClick={() => setOnEdit(false)} style={{paddingInline: 5}}>
                <i className="material-icons">close</i>
            </a>
        </div>
    </div>

    return <div className="clickable" onClick={() => setOnEdit(true)}>
        {Helper.formatDate(appointment.start, "DD/MM/YYYY hh:mm")}
    </div>

}

export default AppointmentDate;