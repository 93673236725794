import React, {useEffect, useState} from 'react';
import axios from "axios";
import useDebounce from "../Shared/Hooks";
import {RequestState} from "../Shared/StateHelper";

const EditMessage = ({id, ...props}) => {
    const [message, setMessage] = useState(props.message)
    const [loaded, setLoaded] = useState(false)
    const [requestState, setRequestState] = useState(RequestState.IDLE)
    const debouncedSaveMessage = useDebounce(message, 1000)

    useEffect(() => {
        if (loaded) {
            saveMessage()
        }
        setLoaded(true)
    }, [debouncedSaveMessage]);

    const saveMessage = () => {
        console.log("Saving message", message)
        axios.patch(`/offices/${id}/message`, {message: message})
            .then(response => setRequestState(RequestState.SUCCESS))
            .catch(error => setRequestState(RequestState.ERROR))
    }

    return <>
        <td width="10%">
            <div className="valign-wrapper" style={{gap: 20}}>
                {message.default ?
                    <span style={{fontWeight: "bold", fontStyle: "italic"}}
                          title="Message par défaut hérité de l'ophtalmologiste">{message.title}</span>
                    : message.title}
                {requestState === RequestState.LOADING ? <i className="material-icons rotating tiny">sync</i> : null}
            </div>
        </td>
        <td width="45%">
            <div className="input-field outlined">
                                <textarea defaultValue={message.message}
                                          style={{minHeight: 100, paddingBlock: 10}}
                                          onChange={e => {
                                              setRequestState(RequestState.LOADING)
                                              setMessage(prevState => {
                                                  return {...prevState, message: e.target.value, default: false}
                                              })
                                          }}/>
            </div>
        </td>
        <td width="45%">
            <div className="input-field outlined">
                                <textarea defaultValue={message.hn_message}
                                          style={{minHeight: 100, paddingBlock: 10}}
                                          onChange={e => {
                                              setRequestState(RequestState.LOADING)
                                              setMessage(prevState => {
                                                  return {...prevState, hn_message: e.target.value, default: false}
                                              })
                                          }}/>
            </div>
        </td>
    </>

};


export default EditMessage