import React, {useEffect, useState} from "react";
import axios from "axios";
import MonoStatDisplay from "../../admin/Statistiques/MonoStatDisplay";
import {wrapper} from "../../I18n/i18n";

const {t} = wrapper("ORTHOPTIST_BILLING.BILLING_PREVIEW")

function BillingPreview() {
    const [toPay, setToPay] = useState(0)
    const [earnings, setEarnings] = useState(0)
    const [fixedAmount, setFixedAmount] = useState(0)
    const [variableAmount, setVariableAmount] = useState(0)

    useEffect(() => {
        getUnpaidData()
    }, [])

    const getUnpaidData = () => {
        axios.get("/orthoptistes/paiements/unpaid").then((resp) => {
            setToPay(resp.data.to_pay + resp.data.fixed_amount + resp.data.variable_amount)
            setEarnings(resp.data.total_earnings)
            setFixedAmount(resp.data.fixed_amount)
            setVariableAmount(resp.data.variable_amount)
        }).catch((error) => {
        })
    }

    return <div className="fc" style={{gap: 10, paddingTop: 10}}>
        <h2 style={{margin: "0 10px"}}>{t("NEXT_BILL")}</h2>
        <label style={{marginInline: 10}}>Retrouvez ici les revenus générés via votre activité orthoptique</label>
        <div className="row" style={{padding: 0, gap: 20}}>
            <div className="col s12 xl6 center-align"
                 style={{padding: "0 0.1vw 10px 0.1vw", display: "flex", justifyContent: "center"}}>
                <div style={{width: "100%"}}>
                    <MonoStatDisplay
                        label={t("MY_EARNINGS")}
                        value={earnings.toFixed(0)}
                        logo="euro"
                        color="green"/>
                </div>
            </div>
            <div className="col s12 xl6" style={{
                padding: "0 0.1vw 10px 0.1vw",
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <div style={{width: "100%"}}>
                    <MonoStatDisplay
                        label={t("TO_PAY")}
                        value={toPay.toFixed(0)}
                        logo="euro"
                        color="red"/>
                </div>
                {fixedAmount ? <h6 style={{fontStyle: "italic"}}>
                    {t("WITH_FIXED", {fixed: fixedAmount})}
                </h6> : <div/>}
                {variableAmount ? <h6 style={{fontStyle: "italic"}}>
                    {t("WITH_VARIABLE", {variable: `${variableAmount < 0 ? "remise de" : ""} ${Math.abs(variableAmount)}`})}
                </h6> : <div/>}
            </div>
        </div>
    </div>

}

export default BillingPreview