// Orthoptist validator
// This class is made to help orthoptists to validate their appointments
// It is purely temporary and will be deleted when the prescription bug phase is over

import React, {useEffect, useState} from 'react';
import {loading, RequestState} from "../../Shared/StateHelper";
import Loader from "../../Shared/Loader";
import Error from "../../Shared/Error";
import HelpButton from "../../Shared/HelpButton";
import axios from "axios";
import Validator from "../../Shared/Validator";
import {toast} from "react-toastify";
import Tooltip from "../../Shared/Tootip";
import {t} from "../../I18n/i18n"

const MAX_RESULTS = 25

function AppointmentValidator({}) {
    const [requestStatus, setRequestStatus] = React.useState(RequestState.LOADING);
    const [appointments, setAppointments] = React.useState([]);
    const [appointmentCount, setAppointmentCount] = React.useState(0);
    const [checkedIds, setCheckedIds] = useState([]);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        loadAppointments()
    }, [page, filter])

    useEffect(() => {
        if (document.getElementById('indeterminate-checkbox')) {
            document.getElementById('indeterminate-checkbox').indeterminate = checkedIds.length > 0 && checkedIds.length < appointments.filter(appt => appt.data.status_controle !== 1).length
        }
    }, [checkedIds])

    const check = (id) => {
        if (checkedIds.includes(id)) {
            setCheckedIds(checkedIds.filter(checkedId => checkedId !== id))
            return
        }
        setCheckedIds([...checkedIds, id])
    }

    const loadAppointments = () => {
        setRequestStatus(RequestState.LOADING);
        setCheckedIds([])
        axios.get('/orthoptistes/validator/list', {
            params: {
                page: page,
                nb_results: MAX_RESULTS,
                filter: filter
            }
        }).then(resp => {
            setAppointments(resp.data.appointments);
            setAppointmentCount(resp.data.count)
            setRequestStatus(RequestState.SUCCESS);
        }).catch(error => {
            setRequestStatus(RequestState.ERROR);
        });
    }

    const validate = (ids) => {
        if (ids.length === 0) return

        axios.post(`/orthoptistes/validator/validate`, {ids: checkedIds})
            .then(() => {
                if (ids.length > 1) {
                    toast.success(t("ORTHOPTIST_APPOINTMENT_VALIDATOR.TOAST_MESSAGES.EXAMS_SUCCESSFULLY_VALIDATED"))
                } else {
                    toast.success(t("ORTHOPTIST_APPOINTMENT_VALIDATOR.TOAST_MESSAGES.EXAM_SUCCESSFULLY_VALIDATED"))
                }
                loadAppointments()
                // intentList.filter(intent => ids.includes(intent.id)).forEach(intent => {
                //     validateIntent(intent.id)
                // })
            }).catch(() => {
            toast.error(t("ORTHOPTIST_APPOINTMENT_VALIDATOR.TOAST_MESSAGES.ERROR_WHEN_VALIDATION"))
        })
    }

    const renderPagination = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <h6 style={{
                    alignSelf: "center",
                    margin: 0
                }}>{appointmentCount} {t("ORTHOPTIST_APPOINTMENT_VALIDATOR.RESULT")}{appointments.length === 1 ? "" : "s"}</h6>
                <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <a id="hoverable" className={`btn-flat large-btn ${page === 1 ? "disabled" : ""}`}
                       onClick={(e) => setPage(page - 1)}><i className="material-icons">chevron_left</i></a>
                    <h6 style={{
                        alignSelf: "center",
                        margin: 0
                    }}>{page} / {Math.ceil(appointmentCount / MAX_RESULTS)}</h6>
                    <a id="hoverable"
                       className={`btn-flat large-btn ${page === Math.ceil(appointmentCount / MAX_RESULTS) ? "disabled" : ""}`}
                       onClick={(e) => setPage(page + 1)}><i className="material-icons">chevron_right</i></a>
                </div>
            </div>
        </div>
    }

    const renderOptions = () => {
        return <div style={{
            marginLeft: 22,
            display: "flex",
            alignItems: "center",
            gap: 15,
            flexWrap: "wrap",
            justifyContent: "center"
        }}>
            <label className="valign-wrapper">
                <input id="indeterminate-checkbox" type="checkbox"
                       checked={appointments.length && checkedIds.length === appointments.filter(appt => appt.data.status_controle !== 1).length}
                       disabled={!appointments.length}
                       onChange={() => {
                           if (checkedIds.length === appointments.filter(appt => appt.data.status_controle !== 1).length) {
                               setCheckedIds([])
                               return
                           }
                           setCheckedIds(appointments.filter(appt => appt.data.status_controle !== 1).map(appointment => appointment.data.id))
                       }}/>
                <span>{checkedIds.length === appointments.filter(appt => appt.data.status_controle !== 1).length ? t("ORTHOPTIST_APPOINTMENT_VALIDATOR.DESELECT_ALL") : t("ORTHOPTIST_APPOINTMENT_VALIDATOR.SELECT_ALL")}</span>
            </label>
            <Validator onValidation={() => validate(checkedIds)}
                       text={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.VALIDATE_SELECTION_QUESTION")}
                       detail={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.EXAM_CONSIDERED_CORRECT")}
                       validationText="Valider" validationColor="blue darken-2" validationIcon="check"
                       abortColor="black darken-2" abortIcon="cancel" abortText="Annuler"
                       id="validate-intents"
            >
                <a className={`btn btn-small blue darken-2 z-depth-0 ${checkedIds.length === 0 ? "disabled " : ""}`}>{t("ORTHOPTIST_APPOINTMENT_VALIDATOR.MARK_SELECTION_VALID")}</a>
            </Validator>
            <label className="valign-wrapper">
                <input type="checkbox" className="filled-in"
                       checked={filter.suspicious}
                       onChange={() => setFilter({...filter, suspicious: !filter.suspicious})}/>
                <span title={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.WARNING_ON_EXAM")}
                >
                    {t("ORTHOPTIST_APPOINTMENT_VALIDATOR.SUSPECT_EXAM_ONLY")}</span>
            </label>
            <div style={{marginLeft: "auto", marginRight: 20}}>
                {renderPagination()}
            </div>
        </div>
    }

    const renderAppointment = (appointment) => {
        const full_prescription = appointment.full_prescription
        appointment = appointment.data
        return <div className="row" style={{gap: 10}}>
            <div className="col s1 valign-wrapper">
                {appointment.status_controle === 1 ? <i className="material-icons grey-text text-darken-3" title={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.EXAM_NOT_REVIEWED")}>pending</i>
                    : <label className={`valign-wrapper`} style={{marginTop: 5}}>
                    <input id={`checkbox-appt-${appointment.id}`} type="checkbox" className="filled-in"
                           checked={checkedIds.includes(appointment.id)} onChange={() => check(appointment.id)}/>
                    <span/>
                </label>}
            </div>
            <div className="col s1 valign-wrapper">
                {appointment.date}
            </div>
            <div className="col s2">
                <a className="btn-flat" href={`/patients/${appointment.patient_id}`}>
                    {Helper.formatName(appointment.patient_firstname, appointment.patient_lastname)}
                </a>
            </div>
            <div className="col s2">
                <div style={{display: "flex", flexWrap: "wrap", flexDirection: "column"}}>
                    <span>{t("RIGHT_EYE")} : {appointment.right_finale || appointment.right_subj}</span>
                    <span>{t("LEFT_EYE")} : {appointment.left_finale || appointment.left_subj}</span>
                </div>
            </div>
            <Tooltip id={`tt_fullprescr_${appointment.id}`} text={full_prescription.trim()} position="right">
                <div className="col s2">
                    {
                        appointment.prescription_text?.includes("OG") ?
                            <div style={{display: "flex", flexDirection: "column"}}>
                        <span>
                            {appointment.prescription_text?.split("OG")[0]}
                        </span>
                                <span>
                            OG: {appointment.prescription_text?.split("OG")[1].replace(":", "")}
                        </span>
                            </div> : <span>{appointment.prescription_text}</span>
                    }
                </div>
            </Tooltip>
            <div className="col s3">
                {appointment.anamnesis.length > 50 ?
                    <Tooltip id={`tt_anamnesis_${appointment.id}`} text={appointment.anamnesis} position="left">
                        <span>{appointment.anamnesis.substring(0, 150)}...</span>
                    </Tooltip> : <span>{appointment.anamnesis}</span>}
            </div>
        </div>
    }

    const renderAppointments = () => {
        if (requestStatus === RequestState.LOADING) return <div style={{marginTop: 20}}><Loader text="" size="small"/>
        </div>
        if (requestStatus === RequestState.ERROR) return <div style={{marginTop: 20}}><Error
            errorText={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.UNABLE_LOAD_APPOINTMENT")}/></div>
        if (!appointments?.length) return <h4 className="center" style={{margin: 40}}>{t("ORTHOPTIST_APPOINTMENT_VALIDATOR.NO_ACT_AVAILABLE")}</h4>

        return <ul style={{borderRadius: "20px"}} className="collection">
            <li className="collection-item" key={-1}>
                <div className="row" style={{gap: 10}}>
                    <div className="col s1 offset-s1">
                        {t('DATE')}
                    </div>
                    <div className="col s2">
                        {t("PATIENT")}
                    </div>
                    <div className="col s2">
                        {t("MEASURES")}
                    </div>
                    <div className="col s2 valign-wrapper">
                        {t("PRESCRIPTION")}<i className="material-icons tiny" title={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.MOUSE_OVER_PRESCRIPTION")}>help</i>
                    </div>
                    <div className="col s4 valign-wrapper">
                        {t("ANAMNESIS")}<i className="material-icons tiny" title={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.MOUSE_OVER_ANAMNESE")}>help</i>
                    </div>
                </div>
            </li>
            {appointments.map((appointment, index) =>
                <li className="collection-item" key={index}
                    style={{backgroundColor: appointment.suspicious ? "#ffa5a5" : "transparent"}}>
                    {renderAppointment(appointment)}
                </li>
            )}
        </ul>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, margin: 20}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <h1 style={{margin: 0}}>{t("ORTHOPTIST_APPOINTMENT_VALIDATOR.VALIDATAE_PRESCRIPTION")}</h1>
            <HelpButton
                text={t("ORTHOPTIST_APPOINTMENT_VALIDATOR.BUG")}/>
        </div>
        {renderOptions()}
        {renderAppointments()}
    </div>
}

export default AppointmentValidator;