import React from 'react';
import AppointmentDate from "./AppointmentDate";
import Helper from "../../../Shared/Helper";
import ScopedElement from "../../../Shared/ScopedElement";
import {Role} from "../../../Shared/Enums/Scope";

const AppointmentMetadata = ({appointment, appointmentChanged}) => {
    const renderTeletransmissionState = () => {
        let data = {}
        switch (appointment.teletransmission_status) {
            case "WAITING_FOR_ACTION":
                data = {color: "grey", tooltip: "En attente de télétransmission"}
                break
            case "PENDING":
                data = {color: "orange", tooltip: "Télétransmission en attente"}
                break
            case "SUCCESS":
                data = {color: "green darken-2", tooltip: "Télétransmission réussie"}
                break
            case "FAILED":
                data = {color: "red darken-2", tooltip: "Erreur de télétransmission"}
                break
            default:
                return null
        }

        return <div className={`card ${data.color} white-text z-depth-0`}
                    style={{borderRadius: 30, padding: "0 4px", cursor: "default"}}
                    title={data.tooltip}>
            <i className={`material-icons tiny`} style={{paddingTop: 3, paddingLeft: 1}}>
                rss_feed
            </i>
        </div>
    }


    return <div className="fr gap-10 valign-wrapper" style={{flex: 1}}>
        <AppointmentDate appointment={appointment}
                         onChange={(date) => appointmentChanged({...appointment, start: date})}/>
        {Helper.formatName(appointment.orthoptist_firstname, appointment.orthoptist_lastname)}
        <ScopedElement scope={[Role.ORTHOPTIST]}>
            <div className="fr gap-0" style={{marginLeft: "auto"}}>
                {renderTeletransmissionState()}
            </div>
        </ScopedElement>
    </div>
}

export default AppointmentMetadata;