import {makeObservable, observable} from "mobx"
import axios from "axios";
import OpticianShopsStore from "../Shops/OpticianShopsStore";

const _ = require('lodash')
class OpticianCampaignsStore {
    @observable campaigns = []
    @observable selectedCampaign = null

    constructor() {
        makeObservable(this)
    }

    saveCampaign(id, obj) {
        const campaign = this.campaigns.find(c => c.id === id)
        axios.patch(`/opticians/campaigns/update/${id}`, {...campaign, ...obj})
            .then(response => {
                this.updateCampaign(response.data)
            })
    }

    saveSelectedCampaign(obj) {
        const campaign = obj.id ? obj : {...this.selectedCampaign, ...obj}
        axios.patch(`/opticians/campaigns/update/${campaign.id}`, campaign)
            .then(response => {
                this.updateCampaign(response.data)
            })
    }

    addCampaign(campaign) {
        this.campaigns.push(campaign)
    }

    selectCampaign(campaign) {
        this.selectedCampaign = this.selectedCampaign === campaign ? null : campaign
    }

    updateCampaign(campaign) {
        // let index = _.findIndex(this.campaigns, {id: campaign.id})
        // this.campaigns[index] = campaign
        this.campaigns = this.campaigns.map(c => {
            return c.id === campaign.id ? campaign : c
        })
        if(this.selectedCampaign && this.selectedCampaign.id === campaign.id) {
            this.selectedCampaign = campaign
        }
    }

    getShop(campaign) {
        return OpticianShopsStore.shops.find(shop => shop.id === campaign.shop_id)
    }
}

let store = new OpticianCampaignsStore
export default store