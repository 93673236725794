import React, {useEffect, useRef, useState} from "react"
import {RequestState} from "../Shared/StateHelper";
import axios from "axios";
import Loader from "../Shared/Loader";
import Helper from "../Shared/Helper"
import {toast} from "react-toastify"
import Validator from "../Shared/Validator";
import Tooltip from "../Shared/Tootip";
import {t} from "../I18n/i18n"
import SmartTextArea from "../Shared/UiKit/SmartTextArea";

export default function MainPage() {

    const modal_element = useRef(null)

    const [text_alerts, setTextAlerts] = useState(null)
    const [request_state, setRequestState] = useState(RequestState.LOADING)
    const [new_alert_title, setNewAlertTitle] = useState("")
    const [new_alert_text, setNewAlertText] = useState("")
    const [new_prev_text, setNewPrevText] = useState("")

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
        getTextAlertList()
        const modal = M.Modal.init(modal_element.current)
        return () => {
            modal.destroy()
        }
    }, []);

    function getTextAlertList() {
        axios.get("/admin/text_alerts/")
            .then((response) => {
                setTextAlerts(response.data.text_alerts)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((error) => setRequestState(RequestState.ERROR))
    }

    function isSubmitable() {
        return !(!new_alert_title || !new_alert_text);
    }

    function renderErrorTooltip() {
        let initial_text = t("RISK_ALERT_MANAGEMENT.ELEMENT_MISSING")
        if (!new_alert_title) initial_text += t("RISK_ALERT_MANAGEMENT.PLEASE_ENTER_TITLE")
        if (!new_alert_text) initial_text += t("RISK_ALERT_MANAGEMENT.PLEASE_ENTER_MESSAGE")
        return initial_text
    }

    function saveNewTextAlert() {
        if (!isSubmitable()) return

        axios.put("/admin/text_alerts/", {
            title: new_alert_title,
            message: new_alert_text
        })
            .then((response) => {
                setNewAlertText("")
                setNewAlertTitle("")
                setTextAlerts([...text_alerts, response.data.new_alert])
            })
            .catch((error) => toast.error(t("RISK_ALERT_MANAGEMENT.TOAST.ERROR_WHEN_RECORDING")))
    }

    function deleteTextAlert(id) {
        const alert_title = text_alerts.find((alert) => alert.id === id)?.title
        axios.delete(`/admin/text_alerts/${id}`)
            .then((response) => {
                setTextAlerts([...text_alerts].filter((text_alert) => text_alert.id !== id))
                toast.success(`${t("PATHOLOGY")} ${alert_title} ${t("DELETED")}.`)
            })
            .catch((_) => {
                toast.error(`${t("RISK_ALERT_MANAGEMENT.TOAST.ERROR_WHEN_DELETE")} ${alert_title}.`)
            })
    }

    function setAlertAndPrev(event) {
        setNewAlertText(event)
        setNewPrevText(`${t("RISK_ALERT_MANAGEMENT.SUSPICION")} ${event}.\n\n${t("RISK_ALERT_MANAGEMENT.SUSPICION")} Naevus + ${event} + Cataracte.`)
        if (event.length === 0) {
            setNewPrevText("")
        }
    }

    function renderAlertsText() {
        if (request_state === RequestState.LOADING) return <div className="center">
            <Loader wide text={t("RISK_ALERT_MANAGEMENT.LOADING")}/>
        </div>

        if (request_state === RequestState.ERROR) return <div className="center">
            {t("RISK_ALERT_MANAGEMENT.ERROR_OCCURED")}
        </div>

        return <table className="striped bordered">
            <thead>
            <tr>
                <th className="center">{t("TITLE")}</th>
                <th className="center">{t("MESSAGE")}</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {text_alerts.map((text, index) => {
                return (
                    <tr key={index}>
                        <td>{Helper.capitalizeFirstLetter(text.title)}</td>
                        <td>{text.message}</td>
                        <td>
                            <a className="btn blue darken-2 z-depth-0"
                               href={`/admin/text_alerts/edit/${text.id}`}>
                                <i className="material-icons">edit</i>
                            </a>
                        </td>
                        <td>
                            <Validator
                                onValidation={() => deleteTextAlert(text.id)}
                                text={`${t("RISK_ALERT_MANAGEMENT.RISK_ALERT_MANAGEMENT")} ${Helper.capitalizeFirstLetter(text.title)} ?`}
                                detail={t("RISK_ALERT_MANAGEMENT.ACTION_IRREVERSIBLE")}
                                validationText={t("DELETE")} validationColor="red darken-2"
                                validationIcon="delete"
                                abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                                id={`delete_text_alert_${text.id}`}>
                                <a className="btn red darken-2 z-depth-0">
                                    <i className="material-icons">delete</i>
                                </a>
                            </Validator>

                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    }

    const renderModal = () => {
        return <div id="create_pathology_modal" className="modal" ref={modal_element}>
            <div className="modal-content">
                <h1 className="center">{t("RISK_ALERT_MANAGEMENT.CREATE_NEW_RISK")}</h1>
                <div className="row" style={{marginBottom: "2vh"}}>
                    <div className="input-field col s12 outlined">
                        <h6>{t("RISK_ALERT_MANAGEMENT.TITLE_OF_PATHO")}</h6>
                        <SmartTextArea id="title"
                                       type="text"
                                       style={{width: "100%", resize: "none"}}
                                       value={new_alert_title}
                                       onChange={(e) => setNewAlertTitle(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 outlined">
                        <h6 style={{marginTop: "0"}}>{t("RISK_ALERT_MANAGEMENT.ALERT_MESSAGE")}</h6>
                        <SmartTextArea id="alert_message"
                                       style={{minHeight: "150px", paddingTop: "1.5vh", resize: "none"}}
                                       value={new_alert_text}
                                       onChange={(e) => setAlertAndPrev(e.target.value)}
                        />
                    </div>
                </div>
                <div className="input-field col s12 outlined">
                    <h6>{t("RISK_ALERT_MANAGEMENT.PREVIEW")}</h6>
                    <textarea
                        id="title"
                        value={new_prev_text || "Tapez un message pour voir un aperçu ici ..."}
                        disabled
                        style={{minHeight: "80px", paddingTop: "1.5vh", resize: "none"}}
                    />
                </div>
                {isSubmitable() ?
                    <div className="modal-footer">
                        <a className={`modal-close btn blue darken-2 z-depth-0 ${isSubmitable() ? "" : "disabled"}`}
                           style={{marginRight: "1vw"}}
                           onClick={saveNewTextAlert}>{t("RISK_ALERT_MANAGEMENT.REGISTERING_PATHO")}</a>
                        <a className="modal-close btn red darken-2 z-depth-0">{t("CANCEL")}</a>
                    </div>
                    :
                    <Tooltip text={renderErrorTooltip()} id="error_new_text_alert">
                        <div className="modal-footer">
                            <a className={`modal-close btn blue darken-2 z-depth-0 ${isSubmitable() ? "" : "disabled"}`}
                               style={{marginRight: "1vw"}}
                               onClick={saveNewTextAlert}>{t("RISK_ALERT_MANAGEMENT.REGISTERING_PATHO")}</a>
                            <a className="modal-close btn red darken-2 z-depth-0">{t("CANCEL")}</a>
                        </div>
                    </Tooltip>
                }
            </div>
        </div>
    }

    return <>
        <a className="btn blue darken-2 z-depth-0 modal-trigger"
           data-target="create_pathology_modal">{t("RISK_ALERT_MANAGEMENT.NEW_PATHO")}</a>
        <div style={{height: "100%"}}>
            {renderAlertsText()}
        </div>
        {renderModal()}
    </>
}