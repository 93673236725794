import {useState} from "react";

const useDebounceV2 = (func, delay) => {
    const [timer, setTimer] = useState(null);

    return (...args) => {
        if (timer) clearTimeout(timer);
        setTimer(setTimeout(() => {
            func(...args);
        }, delay))
    };
}

export default useDebounceV2;