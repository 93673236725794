import React, {useEffect, useState} from 'react';
import axios from "axios";
import {t} from "../../../../../I18n/i18n";

const OrthoptistSubscriptionSettings = () => {
    const [settings, setSettings] = useState([])

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        axios.get('/orthoptistes/paiements/subscription_settings').then((response) => {
            setSettings(response.data.settings)
        })
    }


    return <div className="fc" style={{gap: 20, paddingBlock: 20}}>
        <h5 style={{margin: 0, fontWeight: 600}}>
            Actes de télémédecine restants dans le forfait : {settings.free_acts_remaining ?? 0}
        </h5>
        <h5 style={{margin: 0}}>
            {settings.free_acts_amount ?? 0} acte(s) gratuit(s) sont inclus dans votre forfait.<br/>
            Les actes hors forfait seront facturés au tarif indiqué dans le tableau forfait télémédecine.
        </h5>
    </div>
}

export default OrthoptistSubscriptionSettings;