import {makeObservable, observable} from "mobx";
import axios from "axios";
import {MedicalEventStatus} from "../admin/Console/MedicalEvents/Enums/Enums";
import Helper from "../Shared/Helper";
import {construct_filter} from "../Shared/FilterHelper";
import qs from "qs";

class MedicalEventStore {
    @observable event = {}
    @observable guest = undefined
    @observable patients = []
    @observable measureTypes = []
    @observable act_types = []
    @observable currentMeasures = []
    @observable selectedMeasureTypeId = undefined

    constructor() {
        makeObservable(this)
        if (this.event.status !== MedicalEventStatus.CANCELED) this.getPatients()
    }

    // PATIENT AND APPPOINTMENT GENERAL DATA

    getPatients = (filter = undefined) => {
        return axios.get(`/medical_event/patients`, {
            params: {filter: construct_filter(filter ?? "")}, paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'brackets'})
            }
        })
            .then((response) => {
                this.patients = response.data ?? []
            })
    }

    addPatient = (patient) => {
        patient = {...patient, ...Helper.extractCodesFromOrganismCode(patient.organism_code)}
        return axios.post(`/medical_event/patient`, patient)
            .then((response) => {
                if (!response.data) return
                this.patients = [response.data, ...this.patients]
            })
    }

    updatePatient = (patient) => {
        patient = {...patient, ...Helper.extractCodesFromOrganismCode(patient.organism_code)}
        return axios.patch(`/medical_event/patient`, patient)
            .then((response) => {
                if (!response.data) return
                this.patients = this.patients.map(p => {
                    if (p.appointment_id === response.data.appointment_id) {
                        return response.data
                    }
                    return p
                })
            })
    }

    removePatient = (patient) => {
        return axios.delete(`/medical_event/patient/${patient.appointment_id}`)
            .then(resp => this.patients = this.patients.filter(p => p.appointment_id !== resp.data))
    }

    saveReport = (patient) => {
        return axios.patch(`/medical_event/report`, {report: patient.report, appointment_id: patient.appointment_id})
            .then(resp => {
                this.patients = this.patients.map(p => {
                    if (p.appointment_id === resp.data.appointment_id) {
                        return {...p, report: resp.data.report}
                    }
                    return p
                })
            })
    }

    saveAppointment = (appointment) => {
        return axios.patch(`/medical_event/appointment`, appointment)
            .then(resp => {
                this.patients = this.patients.map(p => {
                    if (p.appointment_id === resp.data.appointment_id) {
                        return {...p, ...resp.data}
                    }
                    return p
                })
            })
    }

    // ACT

    getActTypes = () => {
        return axios.get(`/medical_event/act_types`).then(resp => {
            this.act_types = resp.data ?? []
        })
    }

    updateAppointment = (appointment) => {
        return axios.patch(`/medical_event/appointment`, appointment).then(resp => {
            this.patients = this.patients.map(p => {
                if (p.appointment_id === resp.data.appointment_id) {
                    return {...p, ...resp.data}
                }
                return p
            })
        })
    }

    // EVENT

    startEvent = () => {
        return axios.post(`/medical_event/start`).then((resp) => {
            this.event = {...this.event, status: resp.data.status}
            this.getPatients()
        })
    }
    completeEvent = () => {
        return axios.post(`/medical_event/complete`).then((resp) => {
            this.event = {...this.event, status: resp.data.status}
            this.getPatients()
        })
    }
    cancelEvent = () => {
        return axios.post(`/medical_event/cancel`).then((resp) => {
            this.event = {...this.event, status: resp.data.status}
        })
    }

    export_event_data = () => {
        return axios.get(`/medical_event/export`).then(resp => resp.data)
    }


    // MEASURES

    getMeasures = (appointment_id) => {
        return axios.get(`/medical_event/measures/${appointment_id}`).then(resp => resp.data ?? [])
    }

    getMeasureTypes = () => {
        return axios.get(`/medical_event/measure_types`).then(resp => {
            this.measureTypes = resp.data ?? []
        })
    }

    saveMeasure = (measure, appointment_id) => {
        measure = {
            ...measure,
            od_av_loin: parseFloat(measure.od_av_loin) ? measure.od_av_loin / 10 : measure.od_av_loin,
            og_av_loin: parseFloat(measure.og_av_loin) ? measure.og_av_loin / 10 : measure.og_av_loin
        }
        return axios.post(`/medical_event/measure/${appointment_id}`, measure).then(resp => resp.data)
    }

    deleteMeasure = (measure_id, appointment_id) => {
        return axios.delete(`/medical_event/measure/${appointment_id}?id=${measure_id}`).then(resp => resp.data)
    }

    // COMMENTS

    getComment = (appointment_id) => {
        return axios.get(`/medical_event/appointment/${appointment_id}/comment`).then(resp => resp.data)
    }

    saveComment = (comment, appointment_id) => {
        return axios.post(`/medical_event/appointment/${appointment_id}/comment`, {comment}).then(resp => resp.data)
    }

    // PRESCRIPTIONS

    getPrescription = (appointment_id) => {
        return axios.get(`/medical_event/appointment/${appointment_id}/prescription`).then(resp => resp.data)
    }

    savePrescription = (prescription, appointment_id) => {
        return axios.post(`/medical_event/appointment/${appointment_id}/prescription`, {prescription}).then(resp => resp.data)
    }

    // MEDIA

    getMedia = (appointment_id) => {
        return axios.get(`/medical_event/appointment/${appointment_id}/media`).then(resp => resp.data)
    }

    addMedia = (appointment_id, files) => {
        const formData = new FormData()
        files.forEach(file => {
            if (file.name.includes(".jpg") || file.name.includes(".jpeg") || file.name.includes(".JPG") || file.name.includes(".JPEG") || file.name.includes(".png") || file.name.includes(".PNG")) {
                formData.append("files[]", file, file.name)
            } else if (file.name.includes(".mp4") || file.name.includes(".MP4") || file.name.includes(".mov") || file.name.includes(".MOV")) {
                formData.append("files[]", file, file.name)
            }
        })
        return axios.post(`/medical_event/appointment/${appointment_id}/media`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(resp => resp.data)
    }

    removeMedia = (appointment_id, link) => {
        return axios.delete(`/medical_event/appointment/${appointment_id}/media`, {params: {link: link}})
            .then(resp => resp.data.link)

    }

    subscribeToCable = (appointment_id, callback) => {
        App.media_changed = App.cable.subscriptions.create(
            {channel: 'MediaChangedChannel', appointment_id: appointment_id, uuid: this.event.uuid},
            {received: (_) => callback()}
        )
    }

    unsubscribeFromCable = () => App.media_changed.unsubscribe()
}

let store = new MedicalEventStore
global.MedicalEventStore = store
export default store
