import {Chart, registerables} from "chart.js";
import React from "react";
import {Pie} from "react-chartjs-2";

Chart.register(...registerables);

const _ = require('lodash');
const IncomeFormulaRepartition = ({data, title}) => {
    const options = {
        plugins: {
            title: {
                display: true,
                text: title
            },
            legend: {
                display: true,
                position: "right"
            }
        }

    }

    if (!data || _.isEmpty(data)) return null

    return <div>
        <div style={{marginRight: 50, width: "100%"}}>
            <Pie data={data} options={options}></Pie>
        </div>
    </div>

}

export default IncomeFormulaRepartition;