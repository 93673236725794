import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxCircle from "mapbox-gl-circle";

mapboxgl.accessToken = process.env.MAPBOX_TOKEN

const ZoneVisualization = ({lat, lng, onChange, ...props}) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [zoom, setZoom] = useState(8);
    const [radius, setRadius] = useState(props.radius ?? 30000);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom,
            dragPan: false, // can't drag the map
        });

        map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        // change cursor to pointer when hovering over a pin
        map.current.getCanvas().style.cursor = 'pointer';
    }, []);

    useEffect(() => {
        // add pin on current selected location
        const marker = new mapboxgl.Marker({
            draggable: false
        }).setLngLat([lng, lat]).addTo(map.current);

        return () => marker.remove();
    }, [lng, lat]);

    // draw a circle around the pin
    useEffect(() => {
        if (!map.current) return;
        const circle = new MapboxCircle({lat: lat, lng: lng}, radius, {
            editable: true,
            minRadius: 1500,
            fillColor: '#1976d2'
        }).addTo(map.current);

        circle.on('radiuschanged', function (circleObj) {
            console.log('New radius (meters):', circleObj.getRadius());
            onChange(circleObj.getRadius());
        });

        return () => circle.remove();
    }, [radius]);

    return <div ref={mapContainer} className="map-container" style={{height: "100%", width: "100%", borderRadius: 3}}/>

}

ZoneVisualization.defaultProps = {
   onChange: () => null
}

export default ZoneVisualization;