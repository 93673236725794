import React, {Component} from 'react';
import axios from "axios"
import {Pie} from 'react-chartjs-2'
import Loader from "../../../Shared/Loader";

const options = {
}
export default class PieRdvs extends Component {
  constructor(props) {
    super(props)
    this.loadData =this.loadData.bind(this)
    this.state = {
      data: [],
      loading: true
    }
    this.loadData();
  }
  loadData() {
    axios.get("/admin/statistiques/api/PieChart").then((data)=>{
      this.setState({
        data: data.data,
        loading: false
      })
    })
  }
  render() {
    return (
      <div className="card horizontal" style={{height: "100vh",width: "100%"}}>


      {this.state.loading ? (
          <div style={{width: "100%"}}>
            <Loader/>
          </div>
        ) : (
          <div className="center">
          <Pie
            width={window.screen.width*0.45}
            height={window.screen.height*0.6}
            data={this.state.data} options={options} />
          </div>
        ) }
      </div>

    );
  }
}
