import React, {Component} from 'react';
import {Link} from "react-router-dom"
import Strips from "../../Shared/Strips"

export default class Unmount extends Component {
  render() {
    return (
      <div>
        <h1 className="center">
        Veuillez déconnecter tous les appareils USB de l'ordinateur
        </h1>
        <p className='center'>
        Vous pouvez évidemment garder votre clavier et souris connecté
          <br /><br />
        Quand c'est fait, cliquez sur "Continuer"
        </p>

        <div className='center'>
          <Link to="Connect_usb" className="btn mauve"> <i className="material-icons left">done</i>Continuer</Link>
        </div>
        <Strips>white orthoptiste</Strips>

      </div>
    );
  }
}
