///create react hook
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import WebToDesktop from "./WebToDesktop";
import webToDesktop from "./WebToDesktop";
import socket from "../Shared/socket";
import ConnecteurV3 from "../BridgeV2/ConnecteurV3";

export default function WebToDesktopConfiguration(props) {
    const [receivers, setReceivers] = useState([]);
    const [availableReceivers, setAvailableReceivers] = useState([]);
    const [connectedUsbDevices, setConnectedUsbDevices] = useState([]);
    socket.setBridgeUid(props.bridge_uid)
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const loadReceivers = () => {
        axios.get("/web_to_desktop/receivers").then((data) => {
            setReceivers(data.data.receivers);
        })
    }
    const getCurrentReceivers = (data) => {
        setAvailableReceivers(data.message)
    }
    const addReceiver = (e) => {
        let receiver = e.target.dataset.receiver;
        let new_receivers = [...receivers, JSON.parse(receiver)];
        axios.put("/web_to_desktop/receivers", {receivers: new_receivers}).then((data) => {
            setReceivers(data.data.receivers);
        })

    }
    const deleteReceiver = (e) => {
        let receiver_engine = e.target.dataset.receiverengine;
        let new_receivers = receivers.filter((receiver) => receiver.engine !== receiver_engine);
        axios.put("/web_to_desktop/receivers", {receivers: new_receivers}).then((data) => {
            setReceivers(data.data.receivers);
        })

    }
    socket.on("web-to-desktop-response", (data) => {
        if (data.type === "GET-RECEIVERS-RESPONSE") {
            getCurrentReceivers(data)
        } else if (data.type === "GET-USB-CONNECTED-DEVICES") {

            setConnectedUsbDevices(data.message.result.ports)
        }
    })
    useEffect(() => {
        loadReceivers();
        webToDesktop.getReceivers()
        webToDesktop.getConnectedUsbDevices()


    }, []);

    return (
        <div>
            <ConnecteurV3 bridge_uid={props.bridge_uid} id={1}/>
            <h1 className="center">Configuration des receveurs</h1>
            <div className="container">
                <h2>Receveurs actuels</h2>
                <ul>
                    {receivers.map((receiver, index) => {
                        return <div className="valign-wrapper">
                            <table className="temeoo-table">
                                <tbody>
                                <tr>
                                    <th className="center">{receiver.type}</th>
                                    <th className="center">{receiver.engine}</th>
                                    <th className="center">{receiver.path}</th>
                                    {/*<th className="center">CTRL + SHIFT + {index + 1}</th>*/}
                                    <th className="right"><a className="btn-small red darken-2 z-depth-0"
                                                             data-receiverengine={receiver.engine}
                                                             onClick={deleteReceiver}>
                                        <i className="material-icons"
                                           data-receiverengine={receiver.engine}>delete</i>
                                    </a></th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    })}
                </ul>
                <h2>Receveurs disponibles</h2>
                <ul>
                    {availableReceivers.map((receiver, index) => {
                        return <div className="valign-wrapper">
                            <table className="temeoo-table">
                                <tbody>
                                <tr>
                                    <th className="center">{receiver.type}</th>
                                    <th className="center">{receiver.engine}</th>
                                    <th className="center">{receiver.path}</th>
                                    <th className="right">
                                        <a className="btn-small blue darken-2 z-depth-0"
                                           key={index} data-receiver={JSON.stringify(receiver)}
                                           onClick={addReceiver}>
                                            <i className="material-icons"
                                               data-receiver={JSON.stringify(receiver)}>add</i>
                                        </a>
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    })}
                </ul>
            </div>
        </div>
    )
}