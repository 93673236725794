import React from 'react';
import {formatAcuities, formatRetinos} from "../../../Appointments/Mesures/mesure_components/mesures";
import {Mesures, Side} from "../../../Shared/Enums/Enums";

const AppointmentMeasures = ({appointment}) => {
    const renderTension = (measure) => {
        return <div className="fr valign-wrapper card grey lighten-4 z-depth-0" style={{paddingBlock: 0, borderRadius: 5}}>
            <div style={{width: "150px"}}>{measure.title}</div>
            <div className="fc" style={{gap: 0}}>
                {measure.od_tension ?
                    <div style={{margin: 0}}>OD : {measure.od_tension < 0 ? "NM" : measure.od_tension}</div> : null}
                {measure.og_tension ?
                    <div style={{margin: 0}}>OG : {measure.og_tension < 0 ? "NM" : measure.og_tension}</div> : null}
            </div>
        </div>

    }

    const renderPachymetry = (measure) => {
        return <div className="fr valign-wrapper card grey lighten-4 z-depth-0" style={{paddingBlock: 0, borderRadius: 5}}>
            <div style={{width: "150px"}}>{measure.title}</div>
            <div className="fc" style={{gap: 0}}>
                {measure.od_pachymetrie ? <div style={{margin: 0}}>OD : {measure.od_pachymetrie} µm</div> : null}
                {measure.og_pachymetrie ? <div style={{margin: 0}}>OG : {measure.og_pachymetrie} µm</div> : null}
            </div>
        </div>
    }

    const renderMesure = (measure) => {
        return <div className="fr valign-wrapper card grey lighten-4 z-depth-0" style={{paddingBlock: 0, borderRadius: 5}}>
            <div style={{width: "150px"}}>{measure.title}</div>
            <div className="fc" style={{gap: 0}}>
                <div style={{margin: 0}}>
                    OD : {formatRetinos(measure, Side.RIGHT)} {formatAcuities(measure, Side.RIGHT, true)}
                </div>
                <div style={{margin: 0}}>
                    OG : {formatRetinos(measure, Side.LEFT)} {formatAcuities(measure, Side.LEFT, true)}
                </div>
            </div>
        </div>
    }

    function chooseMeasureRender(measure) {
        switch (measure.title) {
            case Mesures.TENSION.name :
                return renderTension(measure);
            case Mesures.PACHYMETRY.name :
                return renderPachymetry(measure);
            default :
                return renderMesure(measure);
        }
    }

    return <div className="fc">
        {appointment.measures && appointment.measures.length > 0 && <div><b>Mesures :</b></div>}
        {appointment.measures && appointment.measures.map((measure, index) => {
            return <div key={index}>
                {chooseMeasureRender(measure)}
            </div>
        })}
    </div>
}

export default AppointmentMeasures;