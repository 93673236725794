import React from 'react';
import CoordinatePickerModal from "../../Shared/Map/CoordinatePickerModal";
import OpticianShopsStore from "./OpticianShopsStore";
import {observer} from "mobx-react";

const ShopLine = observer(({shop}) => {
    const [expanded, setExpanded] = React.useState(false);

    const renderExpanded = () => {
        if (expanded) {
            return <div>
                <div className="row">
                    <h6 className="col s4">Address:</h6>
                    <h6 className="col s8">{shop.address}</h6>
                </div>
                <div className="row">
                    <h6 className="col s4">Phone:</h6>
                    <h6 className="col s8">{shop.phone}</h6>
                </div>
                <div className="row">
                    <h6 className="col s4">Email:</h6>
                    <h6 className="col s8">{shop.email}</h6>
                </div>
            </div>
        }
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div className="row">
            <h5 className="col s4 valign-wrapper" style={{margin: 0}}>{shop.name}</h5>
            <h5 className="col s7 valign-wrapper" style={{margin: 0}}>{shop.city}</h5>
            <div className="col s1 valign-wrapper">
                <CoordinatePickerModal
                    defaultCoordinates={shop.coordinates}
                    onValidation={(coordinates) => {
                        OpticianShopsStore.setShopCoordinates(shop.id, coordinates);
                    }}
                />
            </div>

        </div>
        {renderExpanded()}
    </div>
})

export default ShopLine;