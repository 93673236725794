import React, {Component} from 'react';
import {observer} from "mobx-react"
import InputDirectory from "../Shared/InputDirectory"

@observer
export default class ParametreDirectory extends Component {
  constructor(props) {
    super(props)
  }
  render(){
    return(
      <div className="card white ">
        <div className="card-content">
          <h2 className="center">Caméra non-mydriatique</h2>
          <p> le logiciel écoute sur le dossier : </p>
          <div className="row">
            <InputDirectory />
          </div>

        </div>
      </div>
      )
  }

}
