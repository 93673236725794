import React from "react";
import Helper from "../../Shared/Helper";

const DocumentIntro = ({orthoptist, appointment, patient}) => {


    return <>
        <h5 style={{textAlign: "center", marginBottom: 0}}><b>Compte rendu de la consultation de
            télé-ophtalmologie</b></h5>
        <h5 style={{marginBlock: 20}}>Cher Docteur,</h5>
        <h5 style={{marginBlock: 0}}>Dans le cadre d'un protocole de télé-ophtalmologie, j'ai été amené à
            interpréter les données transmises
            par {Helper.formatName(orthoptist.firstname, orthoptist.lastname)}, orthoptiste, qui a reçu en
            consultation
            le {Helper.formatDate(appointment.date_consult)} {Helper.formatName(patient.firstname, patient.lastname)}.
        </h5>
        <h5 style={{marginBlock: 0}}>Veuillez trouver ci-dessous la synthèse de cette consultation accompagnée
            de mon analyse du dossier.</h5>
    </>
}

export default DocumentIntro;