import React, {useEffect} from "react"
import MedicalEventStore from "./MedicalEventStore";
import MedicalEventHeader from "./MedicalEventHeader";
import {MedicalEventStatus} from "../admin/Console/MedicalEvents/Enums/Enums";
import {observer} from "mobx-react";
import ScheduledEventRouter from "./Scheduled/ScheduledEventRouter";
import axios from "axios";
import InProgressEventRouter from "./InProgress/InProgressEventRouter";
import EventCompleted from "./Completed/EventCompleted";
import EventCanceled from "./Canceled/EventCanceled";

const MedicalEvent = observer(({event, guest}) => {

    useEffect(() => {
        let csrfToken = document.querySelector("meta[name='csrf-token']").content
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
        MedicalEventStore.event = event
        MedicalEventStore.guest = guest
    }, []);

    const renderStates = () => {
        switch (MedicalEventStore.event.status) {
            case MedicalEventStatus.SCHEDULED:
                return <ScheduledEventRouter/>
            case MedicalEventStatus.IN_PROGRESS:
                return <InProgressEventRouter/>
            case MedicalEventStatus.COMPLETED:
                return <EventCompleted/>
            case MedicalEventStatus.CANCELED:
                return <EventCanceled/>
            default:
                return null
        }
    }

    return <div id="full-height" style={{display: "flex", flexDirection: "column"}}>
        <MedicalEventHeader/>
        <div style={{flex: 1, overflowY: "scroll"}}>
            {renderStates()}
        </div>
    </div>
})

export default MedicalEvent