import React, {useEffect} from 'react';
import axios from "axios";
import MesureStore from "../../MesureStore";
import useDebounce from "../../../../Shared/Hooks";
import {loading, RequestState} from "../../../../Shared/StateHelper";
import {observer, Observer} from "mobx-react";
import BrandedElement from "../../../../Shared/BrandedElement";
import {t} from "../../../../I18n/i18n"
import SmartTextArea from "../../../../Shared/UiKit/SmartTextArea";

const EditComments = observer((props) => {

    useEffect(() => {
        if(MesureStore.mesureSaving) props.loading()
        else props.loaded()
    }, [MesureStore.mesureSaving]);

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <SmartTextArea value={MesureStore.anamnesis ?? ""} id={"anamnesis"}
                       style={{resize: "none", padding: 10, height: 120, fontSize: 12}}
                       onChange={e => MesureStore.setAnamnesis(e.target.value)}
                       placeholder={t("ANAMNESIS")} shortcuts={MesureStore.textual_shortcuts}
        />
        <BrandedElement temeoo>
            <SmartTextArea value={MesureStore.orthoptic_text ?? ""} id={"orthoptic_text"}
                           style={{resize: "none", padding: 10, height: 80, fontSize: 12}}
                           onChange={e => MesureStore.setOrthotpicText(e.target.value)}
                           placeholder={t("ORTHOPTIC")} shortcuts={MesureStore.textual_shortcuts}
            />
        </BrandedElement>
        <div title={t("COMMENT_FOR_OPHTALMO_TOOLTIP")}>
            <SmartTextArea
                value={MesureStore.comment_for_ophtalmologist ?? ""} id={"private_comment"}
                style={{resize: "none", padding: 10, height: 80, fontSize: 12}}
                onChange={e => MesureStore.setOphtalmologistComment(e.target.value)}
                placeholder={t("COMMENT_FOR_OPHTALMO")} shortcuts={MesureStore.textual_shortcuts}
            />
        </div>
        <div title={t("PRIVATE_COMMENT_TOOLTIP")}>
            <SmartTextArea
                value={MesureStore.private_comment ?? ""} id={"private_comment"}
                style={{resize: "none", padding: 10, height: 80, fontSize: 12}}
                onChange={e => MesureStore.setPrivateComment(e.target.value)}
                placeholder={t("PRIVATE_COMMENT")} shortcuts={MesureStore.textual_shortcuts}
            />
        </div>
    </div>
})

export default EditComments;