import React from 'react';

const JoinPage = ({...props}) => {
    const [short, setShort] = React.useState(props.short ?? "");
    const [email, setEmail] = React.useState('');

    return <div id="full-height" className="center valign-wrapper" style={{justifyContent: "center"}}>
        <div style={{maxWidth: 600, textAlign: "left", paddingBottom: 200}}>
            <h1 style={{fontSize: "2.8rem", marginBlock: 10}}>La mobilité Temeoo pour tous</h1>
            <h4 style={{fontSize: "1.2rem", marginBlock: 20, paddingBottom: 50, color: "#5f6368"}}>
                Préparez, réalisez vos déplacements orthoptiques et suivez l'évolution des dossiers grâce à votre portail Temeoo
            </h4>
            <div className="valign-wrapper" style={{justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div className="input-field outlined" style={{maxWidth: 300}}>
                        <input id="short" type="text" className="validate" value={short}
                               onChange={(e) => {
                                   setShort(e.target.value.replace(" ", ""))
                               }}/>
                        <label htmlFor="short">Clé d'accès</label>
                    </div>
                    <div className="input-field outlined" style={{maxWidth: 300}}>
                        <input id="email" type="text" className="validate" value={email}
                               onChange={(e) => {
                                   setEmail(e.target.value.replace(" ", ""))
                               }}/>
                        <label htmlFor="email">Email</label>
                    </div>
                </div>

                <a style={{marginLeft: 10}}
                   className={`btn blue darken-2 z-depth-0`}
                   onClick={() => {
                       window.location.href = `/medical_events/join/${short}?email=${email}`
                   }}>
                    Rejoindre
                </a>
            </div>
        </div>
    </div>
}

export default JoinPage;