import React from 'react';

const EarningsMetric = ({earnings, monthly_preview}) => {

    const renderMonthly = () => {
        if(monthly_preview !== null) {
            return <p style={{margin: "0px 20px"}}>Estimation: {monthly_preview}€</p>
        }
    }

    return <div style={{
        borderRadius: "10px",
        width: 150,
        height: monthly_preview ? 120 : 80,
        color: "white",
        backgroundColor: "#006BBB",
        marginTop: 10
    }}>
        <p style={{marginLeft: 20, marginBottom: 0}}><b>CA réalisé</b></p>
        <p style={{margin: "0px 20px", fontSize: 18}}>
            <b>{earnings !== null ? earnings : 0} €</b>
        </p>
        {renderMonthly()}
    </div>
}

export default EarningsMetric;