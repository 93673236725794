import React from 'react';
import Mapbox from "./Mapbox";

const CoordinatePicker = ({onValidation, defaultCoordinates}) => {
    const [lnglat, setLnglat] = React.useState({});

    const handleValidation = () => {
        onValidation(lnglat);
    };

    return <div style={{display: "flex", flexDirection: "column", gap: 20, height: "100%", width: "100%"}}>
        <h6>Sélectionner une adresse</h6>
        <Mapbox defaultCoordinates={defaultCoordinates} onChange={(lnglat) => setLnglat(lnglat)}/>
        <div className="center">
            <a className="btn blue darken-2 z-depth-0" onClick={handleValidation}>Valider</a>
        </div>
    </div>
};

CoordinatePicker.defaultProps = {
    onValidation: (lat, lon) => console.log(`lat: ${lat}, lon: ${lon}`),
};

export default CoordinatePicker;

