import React, {useEffect, useRef, useState} from "react"
import axios from "axios"
import {Colors} from "./ColorEnums"
import {UserAlertsTabs} from "./UserAlertsTabs";
import {toast} from "react-toastify";
import Loader from "../Shared/Loader";
import AlertTab from "./AlertTab";
import ButtonRow from "./ButtonRow";
import SelectedAlert from "./SelectedAlert";
import {t} from "../I18n/i18n"
import {RequestState} from "../Shared/StateHelper";

const RESULT_PER_PAGE = 20
const SORT = {
    CHRONOLOGICAL: "chronological",
    ALPHABETICAL: "alphabetical"
}

export default function Alerts({role}) {

    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
    const TabsData = UserAlertsTabs[role]
    const defaultTab = TabsData.tabs.find(tab => tab.default_tab)
    const [currentFilters, setCurrentFilters] = useState([])
    const [currentTab, setCurrentTab] = useState(defaultTab.type)
    const [alertCounts, setAlertCounts] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [maxPages, setMaxPages] = useState({})
    const [alertLists, setAlertLists] = useState({})
    const [selectedAlert, setSelectedAlert] = useState(null)
    const [sortingBy, setSortingBy] = useState(SORT.CHRONOLOGICAL)
    const [requestState, setRequestState] = useState(RequestState.LOADING)

    const alertsTabs = useRef(null)

    useEffect(() => {
        let instance = M.Tabs.init(alertsTabs.current, {onShow: onShow})

        getAlertsCount()
        getAlerts()

        return () => {
            M.Tabs.getInstance(alertsTabs)?.destroy()
            let tooltips = document.querySelectorAll('[id=alertSecretaryCommentTooltip]')
            if (M.Tooltip.getInstance(tooltips)) M.Tooltip.getInstance(tooltips).destroy()
        }
    }, []);



    const onShow = (tab) => {
        const typeList = Object.values(TabsData.tabs).map((type) => type.type)
        if (typeList.includes(tab.id)) {
            setCurrentTab(tab.id)
            setCurrentPage(1)
        }
    }

    const getAlerts = (page = 1, type = "all", sorting = null) => {
        deleteTooltips()
        axios.get(TabsData.links.get_alert_list,
            {
                params: {
                    result_per_page: RESULT_PER_PAGE,
                    page: page,
                    type: type,
                    sorting: sorting ?? sortingBy,
                    filters: currentFilters
                }
            })
            .then((response) => {
                let updatedAlertList = {...alertLists}
                for (let type in response.data) {
                    updatedAlertList[type] = response.data[type]
                }
                setAlertLists(updatedAlertList)
                M.Tooltip.init(document.querySelectorAll('[id=alertSecretaryCommentTooltip]'), {
                    exitDelay: 0,
                    enterDelay: 50
                })
                setRequestState(RequestState.SUCCESS)
            })
            .catch((_) => toast.error(t("ALERTS_PAGE.AN_ERROR_OCCURRED")))
    }


    const getAlertsCount = () => {
        axios.get(TabsData.links.get_alert_count)
            .then((response) => {
                let alert_count = {...alertCounts}
                let max_pages = {...maxPages}

                for (let alert_type in response.data) {
                    max_pages[alert_type] = Math.floor(response.data[alert_type] / RESULT_PER_PAGE) + 1
                    alert_count[alert_type] = response.data[alert_type]
                }
                setAlertCounts(alert_count)
                setMaxPages(max_pages)
            })
            .catch((_) => toast.error("Une erreur est survenue"))
    }

    const handleFilters = (selectedColor) => {
        let current_filters = currentFilters
        if (current_filters.includes(selectedColor.gravity)) {
            if (current_filters.indexOf(selectedColor.gravity) > -1) {
                current_filters.splice(current_filters.indexOf(selectedColor.gravity), 1)
            }
        } else {
            current_filters.push(selectedColor.gravity)
        }
        setCurrentFilters([...current_filters])
        pageChange(1)
    }


    const handleSorting = (selectedSorting) => {
        if (selectedSorting !== sortingBy) {
            setSortingBy(selectedSorting)
            pageChange(1, "all", selectedSorting)
        }
    }

    const pageChange = (selectedPage, tab = currentTab, sorting = null) => {
        if (selectedPage < 1 || selectedPage > maxPages[currentTab]) return
        getAlerts(selectedPage, tab, sorting)
        setCurrentPage(selectedPage)
    }

    const archivateAlert = () => {
        axios.delete(TabsData.links.archivate_alert + selectedAlert.alert.id)
            .then((data) => {
                pageChange(1)
                getAlertsCount()
                setSelectedAlert(null)
                getAlerts(currentPage, "all")
                toast.success(t("ALERTS_PAGE.TOAST.DELETE_WITH_SUCCESS"))

            }).catch(() => toast.error(t("ALERTS_PAGE.TOAST.DELETE_WITH_ERROR")))
    }

    const findCorrespondingAttribute = (type, value) => {
        const color_index = Colors.findIndex((color) => color.alert_type === value)
        return color_index > 0 ? Colors[color_index][type] : Colors.slice(-1)[0][type]
    }

    const handleChecked = (field, checked) => {
        let new_selected_alert = {...selectedAlert}
        new_selected_alert.alert[field] = checked

        setSelectedAlert(new_selected_alert)
        getAlertsCount()
        getAlerts(currentPage, "all")
    }

    const handleSecretaryCommentChange = (alert_id, comment) => {
        let new_selected_alert = {...selectedAlert}
        new_selected_alert.alert.secretary_comment = comment
        setSelectedAlert(new_selected_alert)
        getAlertsCount()
        getAlerts(currentPage, "all")
    }

    const deleteTooltips = () => {
        let tooltips = document.querySelectorAll('[id=alertSecretaryCommentTooltip]')
        for (let tooltip of tooltips) {
            if (M.Tooltip.getInstance(tooltip)) M.Tooltip.getInstance(tooltip).destroy()
        }
    }

    const renderFiltersButtons = () => {
        return <div>
            <div style={{margin: "10px 0"}}>
                <b style={{textAlign: "center", margin: "10px", fontSize: "1.3em"}}>{t("ALERTS_PAGE.ORDER_BY")}</b>
                <a className={`${sortingBy === SORT.CHRONOLOGICAL ? "blue darken-2 btn" : "white blue-text text-darken-2 btn"}`}
                   style={{margin: "5px", borderRadius: "20px"}}
                   onClick={() => handleSorting(SORT.CHRONOLOGICAL)}>
                    {t("ALERTS_PAGE.CHRONOLOGICAL")}
                </a>
                <a className={`${sortingBy === SORT.ALPHABETICAL ? "blue darken-2 btn" : "white blue-text text-darken-2 btn"}`}
                   style={{margin: "5px", borderRadius: "20px"}}
                   onClick={() => handleSorting(SORT.ALPHABETICAL)}>
                    {t("ALERTS_PAGE.ALPHABETICAL")}
                </a>
            </div>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                <b style={{textAlign: "center", margin: "10px", fontSize: "1.3em"}}>{t("ALERTS_PAGE.FILTERS")}</b>
                <ButtonRow
                    handleFilters={handleFilters}
                    current_filters={currentFilters}
                    colors={Object.keys(Colors)}
                />
            </div>
        </div>
    }

    const renderTabsTitles = () => {
        return <ul ref={alertsTabs} className="tabs grey-text">
            {TabsData.tabs.map((tab, index) => {
                return (
                    <li className="tab" key={`tab_title_${index}`}>
                        <a href={`#${tab.type}`} className={tab.default_tab ? "active black-text" : "black-text"}>
                            {tab.title} ({alertCounts[tab.type] ?? 0})
                        </a>
                    </li>)
            })}
        </ul>
    }

    const renderTabsContent = () => {
        return TabsData.tabs.map((tab, index) => {
            return (
                <div id={tab.type} className="col s12" key={`tab_content_${index}`}>
                    {requestState === RequestState.SUCCESS ?
                        <AlertTab
                            alertsType={tab.type}
                            alertList={[...alertLists[tab.type]]}
                            selectAlert={setSelectedAlert}
                        />
                        : <Loader/>}
                </div>
            )
        })
    }

    const renderFooter = () => {
        if (alertCounts[currentTab] === 0) return null

        const firstResultOfPage = (currentPage - 1) * RESULT_PER_PAGE + 1
        const lastResultOfPage = currentPage * RESULT_PER_PAGE < alertCounts[currentTab] ? currentPage * RESULT_PER_PAGE : alertCounts[currentPage]
        const totalResults = alertCounts[currentTab]

        return <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10
        }}>
            <div>Résultats de {firstResultOfPage} à {lastResultOfPage} sur {totalResults}</div>
            <ul className="pagination"
                style={{display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: 0}}>
                <li>
                    <a className={currentPage === 1 ? "disabled" : "waves-effect"}
                       onClick={() => pageChange(currentPage - 1)}
                       style={{cursor: currentPage === 1 ? "not-allowed" : "pointer"}}>
                        <i className={`material-icons ${currentPage === 1 ? "grey-text" : "blue-text text-darken-2"}`}>chevron_left</i>
                    </a>
                </li>
                <li style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{fontSize: "1.1em"}}>Page {currentPage}</div>
                </li>
                <li>
                    <a className={currentPage === maxPages[currentTab] ? "disabled" : "waves-effect"}
                       onClick={() => pageChange(currentPage + 1)}
                       style={{cursor: currentPage === maxPages[currentTab] ? "not-allowed" : "pointer"}}>
                        <i className={`material-icons ${currentPage === maxPages[currentTab] ? "grey-text" : "blue-text text-darken-2"}`}>chevron_right</i>
                    </a>
                </li>
            </ul>
        </div>

    }

    return (
        <div className="center" style={{display: "flex", flexDirection: "column", gap: 20, width: "100%"}}>
            {renderFiltersButtons()}
            <div className="divider"/>
            <div>
                <SelectedAlert
                    deSelectAlert={() => setSelectedAlert(null)}
                    findCorrespondingAttribute={findCorrespondingAttribute}
                    handleChecked={handleChecked}
                    handleSecretaryCommentChange={handleSecretaryCommentChange}
                    archivateAlert={archivateAlert}
                    selectedAlert={selectedAlert}
                    convokedUrl={TabsData.links.convoked_url}
                    honoredUrl={TabsData.links.honored_url}
                    commentUrl={TabsData.links.comment_url}
                    role={role}
                />
            </div>
            <div className="container" style={{width: "80%"}}>
                {renderTabsTitles()}
                {renderTabsContent()}
            </div>
            {renderFooter()}
        </div>

    )

}