import React, {useEffect, useState} from "react"
import AddMeasure from "./AddMeasure";
import MesureOverview from "../../Appointments/Mesures/mesure_components/MesureOverview";
import {useParams} from "react-router-dom";
import MedicalEventStore from "../MedicalEventStore";
import {Mesures} from "../../Shared/Enums/Enums";
import useDebounceV2 from "../../Hooks/useDebounceV2";
import {observer} from "mobx-react";

const _ = require("lodash")

const Measures = observer(() => {
    const {appointment_id} = useParams();
    const [measures, setMeasures] = useState([])
    const [tension, setTension] = useState({})
    const [pachymetry, setPachymetry] = useState({})
    const debouncedSaveTension = useDebounceV2((t) => saveTension(t), 1000)
    const debouncedSavePachymetry = useDebounceV2((p) => savePachymetry(p), 1000)

    useEffect(() => {
        MedicalEventStore.getMeasures(appointment_id).then((measures) => setMeasures(measures))
    }, [])

    useEffect(() => {
        const tension = measures.find(m => m.name === Mesures.TENSION.name)
        if (tension) setTension(tension)
        const pachymetry = measures.find(m => m.name === Mesures.PACHYMETRY.name)
        if (pachymetry) setPachymetry(pachymetry)
        MedicalEventStore.currentMeasures = measures
    }, [measures]);

    const saveTension = (t) => {
        const tensionId = MedicalEventStore.measureTypes.find(t => t.name === Mesures.TENSION.name).id
        MedicalEventStore.saveMeasure({...t, measure_type_id: tensionId, name: Mesures.TENSION.name}, appointment_id)
    }

    const handleTensionChange = (t) => {
        setTension(t)
        debouncedSaveTension(t)
    }

    const savePachymetry = (t) => {
        const pachymetryId = MedicalEventStore.measureTypes.find(t => t.name === Mesures.PACHYMETRY.name).id
        MedicalEventStore.saveMeasure({...t, measure_type_id: pachymetryId, name: Mesures.PACHYMETRY.name}, appointment_id)
    }

    const handlePachymetryChange = (p) => {
        setPachymetry(p)
        debouncedSavePachymetry(p)
    }

    const addMeasure = (measure) => {
        MedicalEventStore.saveMeasure(measure, appointment_id).then((newMeasure) => {
            const ms = measures.filter(m => m.id !== newMeasure.id)
            setMeasures([...ms, newMeasure])
        })
    }

    const deleteMeasure = (measure) => {
        MedicalEventStore.deleteMeasure(measure.id, appointment_id).then((id) => {
            setMeasures(measures.filter(m => m.id !== id))
        })
    }

    const renderTension = () => {
        return <div className="row">
            <div className="col s2 valign-wrapper">
                Tension
            </div>
            <div className="col s10 valign-wrapper" style={{justifyContent: "space-between"}}>
                <div className="input-field outlined valign-wrapper" style={{marginLeft: 10, gap: 20}}>
                    <h6>OD</h6>
                    <input type="number" value={tension.od_tension || ""} style={{height: 40}}
                           onChange={(e) => handleTensionChange({...tension, od_tension: e.target.value})}/>
                </div>
                <div className="input-field outlined valign-wrapper" style={{marginLeft: 10, gap: 20}}>
                    <h6>OG</h6>
                    <input type="number" value={tension.og_tension || ""} style={{height: 40}}
                           onChange={(e) => handleTensionChange({...tension, og_tension: e.target.value})}/>
                </div>
            </div>

        </div>
    }

    const renderPachymetry = () => {
        return <div className="row">
            <div className="col s2 valign-wrapper">
                Pachymétrie
            </div>
            <div className="col s10 valign-wrapper" style={{justifyContent: "space-between"}}>
                <div className="input-field outlined valign-wrapper" style={{marginLeft: 10, gap: 20}}>
                    <h6>OD</h6>
                    <input type="number" value={pachymetry.od_pachymetrie || ""} style={{height: 40}}
                           onChange={(e) => handlePachymetryChange({...pachymetry, od_pachymetrie: e.target.value})}/>
                </div>
                <div className="input-field outlined valign-wrapper" style={{marginLeft: 10, gap: 20}}>
                    <h6>OG</h6>
                    <input type="number" value={pachymetry.og_pachymetrie || ""} style={{height: 40}}
                           onChange={(e) => handlePachymetryChange({...pachymetry, og_pachymetrie: e.target.value})}/>
                </div>
            </div>
        </div>
    }

    const renderMeasures = () => {
        const m = measures.filter((m) =>  ![Mesures.TENSION.name, Mesures.PACHYMETRY.name].includes(m.name))
        if (_.isEmpty(m)) return <div className="center">
            Aucune mesure n'a été ajoutée
        </div>

        return <ul className={"collection"}>
            {m.map((measure, index) => {
                return <li key={index} className="collection-item clickable"
                           onClick={() => MedicalEventStore.selectedMeasureTypeId = measure.measure_type_id}
                           style={{paddingBlock: 0}}>
                    <div className="valign-wrapper" style={{gap: 20}}>
                        <div className="row" style={{flex: 1}}>
                            <div className="col l4 s12 valign-wrapper">
                                <span>{measure.name}</span>
                            </div>
                            <div className="col l8 s12 valign-wrapper">
                                <MesureOverview mesure={measure} type={{name: measure.name}}/>
                            </div>
                        </div>
                        <a className={"btn-small btn-flat transparent red-text text-darken-2 z-depth-0"}
                           style={{marginLeft: "auto"}}
                           onClick={(e) => {
                               e.stopPropagation()
                               deleteMeasure(measure)
                           }}>
                            <i className={"material-icons"}>delete</i>
                        </a>
                    </div>
                </li>
            })}
        </ul>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, marginInline: 20}}>
        {renderTension()}
        {renderPachymetry()}
        <AddMeasure measures={measures} onAdd={addMeasure}/>
        {renderMeasures()}
    </div>
})

export default Measures