import React, {useEffect, useState} from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";

const OphthalmologistSelect = ({onChange, value, filter, disabled = false}) => {
    const [ophthalmologists, setOphthalmologists] = useState([]);

    useEffect(() => {
        getOphthalmologists()
    }, [filter]);

    const getOphthalmologists = () => {
        axios.get("/users/ophthalmologists", {params: {filter: filter}}).then(response => {
            setOphthalmologists(response.data)
        }).catch(error => {
            console.error(error)
        })
    }

    return <Autocomplete
        sx={{width: "100%"}}
        disablePortal
        disabled={disabled}
        filterSelectedOptions
        className="ui-autocomplete"
        value={ophthalmologists.find(orthoptist => orthoptist.id === value) ?? null}
        options={(ophthalmologists ?? [])}
        getOptionLabel={(orthoptist) => `#${orthoptist.id} ${Helper.formatName(orthoptist.firstname, orthoptist.lastname)}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, value) => onChange(value?.id)}
        renderInput={(params) => <TextField {...params} label="Sélectionner ophtalmologiste"/>}
    />
}

export default OphthalmologistSelect;