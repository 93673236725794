import React from "react"
import {Mesures, Side, TypesMesures} from "../Shared/Enums/Enums";
import Helper from "../Shared/Helper";
import BrandedElement from "../Shared/BrandedElement";
import {t} from "../I18n/i18n";
import {formatAcuities, formatRetinos} from "../Appointments/Mesures/mesure_components/mesures";

class ShowMesure extends React.Component {
    constructor(props) {
        super(props);
        this.click = this.click.bind(this)
        this.hasPachy = this.hasPachy.bind(this)
        this.pachymetrie = this.pachymetrie.bind(this)
        this.sortMesureTypes = this.sortMesureTypes.bind(this)
        this.state = {
            current_mesure_id: 0,
            mesure: []
        };
    }

    componentDidMount() {
        this.sortMesureTypes()
        this.setState({
            current_mesure_id: 0,
            mesure: this.props.mesures[0]
        })
    }

    click(e) {
        e.preventDefault()
        this.setState({
            current_mesure_id: e.target.id,
            mesure: this.props.mesures[e.target.id]
        })
    }

    mesure(key) {
        try {
            let currentMesure = this.state.mesure
            if (this.state.mesure == undefined || this.state.mesure[key] == undefined || this.state.mesure[key] == "") {
                for (var i = 0; i < this.props.mesures.length; i++) {
                    var mesure = this.props.mesures[i][key]
                    if (mesure != undefined && mesure != "") {
                        return <span className="">{mesure}</span>
                    }
                }
            } else {
                return (
                    <span>
          {this.state.mesure[key]}
        </span>
                )
            }
        } catch(error) {
            return (<span></span>)
        }
    }

    hasPachy() {
        var val = false;
        this.props.mesures.map((mesure) => {
            if (mesure["od_pachymetrie"] != undefined || mesure["og_pachymetrie"] != undefined) {
                val = true
            }
        })
        return (val)
    }

    pachymetrie() {
        try {
            if (this.state.mesure["od_pachymetrie"] != undefined || this.state.mesure["og_pachymetrie"] != undefined) {
                return (
                    <tr>
                        <td>pachymétrie</td>
                        <td> {this.state.mesure.od_pachymetrie} </td>
                        <td> {this.state.mesure.og_pachymetrie} </td>
                    </tr>
                )
            }
        } catch {

        }
        return (<React.Fragment></React.Fragment>)
    }

    getTension = (side) => {
        if(!this.props.mesures) return null
        const mesure = this.props.mesures.find(m => side === Side.LEFT ? !!m.og_tension : !!m.od_tension)
        if(!mesure) return null
        const tension = side === Side.LEFT ? mesure.og_tension : mesure.od_tension
        return tension < 0 ? "NM" : tension
    }

    sortMesureTypes() {
        const types = Object.values(TypesMesures)
        this.props.typedemesures.sort((a, b) => types.indexOf(a.titre) - types.indexOf(b.titre))
        this.props.mesures.sort((a, b) =>
            types.indexOf(this.props.typedemesures.filter((o) => o.id === a.typedemesure_id)[0].titre) - types.indexOf(this.props.typedemesures.filter((o) => o.id === b.typedemesure_id)[0].titre)
        )
    }

    formatRetinos = (mesure, side) => {
        return formatRetinos(mesure, side)
    }

    formatAcuities(mesure, side) {
        return formatAcuities(mesure, side)
    }

    render() {
        // Removing Tension and Pachy from conventional mesures because they are handled separately
        // Will need to change that if we add more mesures
        const mesures = this.props.mesures.filter(mesure => ![Mesures.PACHYMETRY.name, Mesures.TENSION.name].includes(this.props.typedemesures.find(t => t.id === mesure.typedemesure_id).titre))
        return (
            <React.Fragment>
                <div className="row tabs-mesures-synthese-v2" style={{paddingTop: "10px"}}>
                    <div className="col s12" style={{height: '100%'}}>
                        <table className="striped bordered">
                            <tbody>
                            <tr>
                                <th>{t("Title")}</th>
                                <th>{t("Right")}</th>
                                <th>{t("Left")}</th>
                            </tr>
                            </tbody>
                            <tbody style={{textAlign: "center"}}>
                            <BrandedElement temeoo>
                                <tr>
                                    <th>{t("Pressure")}</th>
                                    <td>{this.getTension(Side.RIGHT)}</td>
                                    <td>{this.getTension(Side.LEFT)}</td>
                                </tr>
                                {this.hasPachy() ? (
                                    <tr>
                                        <th>{t("Pachymetry")}</th>
                                        <td>{this.mesure("od_pachymetrie")}</td>
                                        <td>{this.mesure("og_pachymetrie")}</td>
                                    </tr>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </BrandedElement>

                            {mesures.filter(mesure => ![Mesures.PACHYMETRY.name, Mesures.TENSION.name].includes(mesure.titre)).map((mesure, index) => (
                                <React.Fragment key={mesure.id}>
                                    <tr>
                                        <th>{t(Helper.capitalizeFirstLetter(this.props.typedemesures[index].titre))}</th>
                                        <td style={{padding: "1vh 0.2vw"}}>
                                            <div>{this.formatRetinos(mesure, Side.RIGHT)}</div>
                                            <div>{this.formatAcuities(mesure, Side.RIGHT)}</div>
                                        </td>
                                        <td style={{padding: "1vh 0.2vw"}}>
                                            <div>{this.formatRetinos(mesure, Side.LEFT)}</div>
                                            <div>{this.formatAcuities(mesure, Side.LEFT)}</div>
                                        </td>
                                    </tr>
                                    {(mesure.od_av_trou_stenopeique !== null && mesure.od_av_trou_stenopeique != "") || (mesure.og_av_trou_stenopeique !== null && mesure.og_av_trou_stenopeique != "") ? (
                                        <tr>
                                            <th>AV trou sténopéique</th>
                                            <td style={{padding: "1vh 0.2vw"}}><div>{mesure.od_av_trou_stenopeique}</div></td>
                                            <td style={{padding: "1vh 0.2vw"}}><div>{mesure.og_av_trou_stenopeique}</div></td>
                                        </tr>
                                    ) : null}

                                </React.Fragment>
                            ))}


                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ShowMesure.defaultProps = {
    ortho: false,
}

export default ShowMesure
