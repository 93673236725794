import React, {useState} from "react"
import SearchBar from "../../Shared/Search/SearchBar";

const useFilter = (initialValue = "", array, filters) => {
    const [search, setSearch] = useState("")

    const filterList = () => {
        return array.filter(item => {
            if(!search) return true
            const check = filters.map(filter => {
                return item[filter]
            }).join("").toLowerCase()
            return check.includes(search.toLowerCase())
        })
    }

    const renderSearchBar = () => <SearchBar search={search} setSearch={setSearch} placeholder="Rechercher un patient"/>
    return {filteredArray: filterList(), searchBar: renderSearchBar()}
}

export default useFilter