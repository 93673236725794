import React from 'react';
import BrandedElement from "../../../../Shared/BrandedElement";
import {t} from "../../../../I18n/i18n"

const Comments = ({appointment}) => {
    const renderAnamnesis = () => {
        if (!appointment.comment) return <h6><b>{t("ANAMNESIS")} :</b> {t("NONE")}</h6>;
        return <div>
            <h6 style={{fontWeight: "bolder"}}>{t("ANAMNESIS")} :</h6>
            <h6>{appointment.comment}</h6>
        </div>
    }

    const renderPrivateComment = () => {
        if (!appointment.private_comment) return <h6><b>{t("PRIVATE_COMMENT")} :</b> {t("NONE")}</h6>;
        return <div>
            <h6 style={{fontWeight: "bolder"}}>{t("PRIVATE_COMMENT")} :</h6>
            <h6>{appointment.private_comment}</h6>
        </div>
    }
    const renderOrthopticText = () => {
        if (!appointment.orthoptic_text) return <h6><b>{t("ORTHOPTIC")} :</b> {t("NONE")}</h6>;
        return <BrandedElement temeoo>
            <div>
                <h6 style={{fontWeight: "bolder"}}>{t("ORTHOPTIC")} :</h6>
                <h6>{appointment.orthoptic_text}</h6>
            </div>
        </BrandedElement>
    }

    return <div style={{display: "flex", flexDirection: "column", fontSize: 14}}>
        {renderAnamnesis()}
        {renderPrivateComment()}
        {renderOrthopticText()}
    </div>
}

export default Comments;