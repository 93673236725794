import React, {useEffect} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../../Shared/Loader";
import Helper from "../../../../Shared/Helper";
import ScopedElement from "../../../../Shared/ScopedElement";
import {Role} from "../../../../Shared/Enums/Scope";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import StatusButton from "../StatusButton";
import {Link} from "react-router-dom";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";

const _ = require('lodash');

function Externs(props) {
    const {offices} = props
    const modal_id = `console_externs_modal`
    const [externs, setExterns] = React.useState(props.externs)
    const [onEditExtern, setOnEditExtern] = React.useState({})
    const [filter, setFilter] = React.useState("")

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [externs, RightsStore.rights])

    const open_modal = () => {
        setTimeout(() => {
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.open();
        }, 100)
    }

    const new_extern = () => {
        setOnEditExtern({onEdit: false})
        open_modal()
    }

    const edit_extern = (extern) => {
        setOnEditExtern({...extern, onEdit: true})
        open_modal()
    }

    const create_extern = () => {
        axios.put('/admin/console/users/extern', {
            extern: onEditExtern
        }).then((resp) => {
            setExterns([...externs, resp.data])
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.close();
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'externe")
        })
    }

    const update_extern = (extern) => {
        axios.patch(`/admin/console/users/extern/${extern.id}`, {
            extern: extern
        }).then((resp) => {
            setExterns(externs.map(e => extern.id === e.id ? resp.data : e))
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.close();
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de sauvegarder les changements")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content container">
                <h3 className="center"
                    style={{marginTop: 20}}>{onEditExtern.onEdit ? "Éditer" : "Nouvel"} externe</h3>
                <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Nom complet</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditExtern.fullname || ""}
                                   onChange={(e) => setOnEditExtern({
                                       ...onEditExtern,
                                       fullname: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Email</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditExtern.email || ""}
                                   onChange={(e) => setOnEditExtern({...onEditExtern, email: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Mot de passe</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditExtern.password || ""}
                                   onChange={(e) => setOnEditExtern({...onEditExtern, password: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Cabinet</span>
                        <select className="browser-default outlined" style={{width: "100%"}}
                                value={onEditExtern.cabinet_id || ""}
                                onChange={(e) => setOnEditExtern({
                                    ...onEditExtern,
                                    cabinet_id: e.target.value
                                })}>
                            <option value="" disabled>Choisir un cabinet</option>
                            {offices.map((office) => {
                                return <option key={office.id}
                                               value={office.id}>{office.titre || "Cabinet sans nom"}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                {onEditExtern.onEdit ?
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={() => update_extern(onEditExtern)}>Valider</a>
                    :
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={create_extern}>Créer</a>}
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderExtern = (extern) => {
        if (!extern) return (<div/>)
        return (
            <div className="collection-item transparent" key={extern.id} style={{display: "flex", width: "100%"}}>
                <div style={{width: "100%", margin: 0, display: "flex", alignItems: "center", gap: 10}}>
                    <div style={{width: "fit-content", margin: 0}}>
                        <StatusButton status={extern.status} id={extern.id}
                                      onChange={(value) => {
                                          if (extern.status !== value) {
                                              update_extern({...extern, status: value})
                                          }
                                      }}
                        />
                    </div>
                    <div style={{width: 40, margin: 0}}>
                        <span>#{extern.id}</span>
                    </div>
                    <div style={{width: "180px", margin: 0}}>
                        <span>{Helper.formatName(extern.fullname)}</span>
                    </div>
                    <div style={{width: "300px", margin: 0}}>
                        <span>{extern.email}</span>
                    </div>
                    <div style={{width: "300px", margin: 0}}>
                        <span className="truncate">{offices.find(office => extern.cabinet_id === office.id)?.titre || "Pas de cabinet aloué"}</span>
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <div style={{flex: 1}}>
                            <ResponsiveMoreButton id={`externs_${extern.id}`} options={[
                                <a className="btn btn-small blue darken-2 z-depth-0"
                                   onClick={() => edit_extern(extern)}>
                                    <i className="material-icons left">edit</i>
                                    Modifier
                                </a>,
                                <Link to={`/externs/${extern.id}`}
                                      className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">dashboard</i>
                                    Tableau de bord
                                </Link>
                            ]}/>
                        </div>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    if (_.isEmpty(externs)) {
        return <div style={{margin: 20}}>
            <Loader size="tiny" text=""/>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 20, height: "100%"}}>
        <h3 className="valign-wrapper" style={{margin: 20}}>
            <i className="material-icons left">alt_route</i>
            Externes
        </h3>
        <div className="divider"/>
        <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div className="input-field outlined">
                    <input type="text" style={{height: 40}} placeholder={"Rechercher"} autoFocus
                           value={filter} onChange={e => setFilter(e.target.value)}/>
                </div>
                <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                    <a className="btn btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
                       onClick={new_extern}>
                        <i className="material-icons left">add</i>
                        Nouveau
                    </a>
                </RestrictedElement>
            </div>
            <div className="collection"
                 style={{borderRadius: 5, height: "90%", overflowY: "scroll"}}>
                {externs.filter(o => {
                    const office_name = offices.find(office => office.id === o.cabinet_id)?.titre || ""
                    const str = `${o.fullname}${o.email}${office_name}`.toLocaleLowerCase()
                    return str.includes(filter.toLowerCase())
                })
                    .sort((a,b) => a.fullname > b.fullname)
                    .map((extern) => {
                        return renderExtern(extern)
                    })}
            </div>
            <ScopedElement scope={[Role.SUPER_ADMIN, Role.ADMIN]}>
                {renderModal()}
            </ScopedElement>
        </div>
    </div>
}

export default Externs;