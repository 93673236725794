import React from "react";
import PropTypes from "prop-types";

function BorderedDisplay(props) {

    const renderTitle = () => {
        if (!props.text) return null;
        return <h5 className={`white grey-text text-darken-2 bordered-display`}
                   style={{
                       top: -14,
                       left: "30px",
                       position: "relative",
                       margin: 0,
                       width: "fit-content",
                       padding: "5px 10px",
                       borderRadius: 20,
                       zIndex: 0
                   }}>{props.text}</h5>
    }
    return (
        <div className={`bordered-display ${props.hidden ? "hide" : ""} ${props.color}`}
             style={{height: props.height, display: "flex", flexDirection: "column", ...props.containerStyle}}>
            {renderTitle()}
            <div style={{margin: 10, marginTop: props.text ? 0 : 10, flex: 1, ...props.style}}>
                {props.children}
            </div>
        </div>
    );
}

BorderedDisplay.defaultProps = {
    color: "transparent",
    hidden: false,
    height: undefined,
    style: {},
    containerStyle: {},
    text: null
}
export default BorderedDisplay;