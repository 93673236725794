import React from 'react';

const EarningsByOrthoptistMetric = ({appointment_count}) => {

    return <div style={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        width: 200,
        height: 80,
        marginTop: 10
    }}>
        <p style={{marginLeft: 20, marginBottom: 0}}><b>Examens réalisés</b></p>
        <p style={{margin: "0px 20px", fontSize: 18}}>
            <b>{appointment_count ?? 0}</b>
        </p>
    </div>
}

export default EarningsByOrthoptistMetric;