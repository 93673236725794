import React, {useEffect, useState} from "react";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import axios from "axios";
import {wrapper} from "../../../I18n/i18n";

const UserOptions = ({orthoptist_id}) => {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const {t: w} = wrapper("USER_OPTIONS");

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = () => {
        axios.get("/admin/console/users/options/all").then((response) => {
            setOptions(response.data.options);
            getSelectedOptions()
        })

    }

    const getSelectedOptions = () => {
        axios.get(`/admin/console/users/orthoptist/${orthoptist_id}/options`).then((response) => {
            setSelectedOptions(response.data.options);
        })
    }

    const addOption = (option) => {
        axios.patch(`/admin/console/users/orthoptist/${orthoptist_id}/option/${option.id}/add`).then((response) => {
            setSelectedOptions(response.data.options);
        })
    }

    const removeOption = (option) => {
        axios.delete(`/admin/console/users/orthoptist/${orthoptist_id}/option/${option.id}/remove`).then((response) => {
            setSelectedOptions(response.data.options);
        })
    }

    const handleChange = (option, checked) => {
        checked ? addOption(option) : removeOption(option)
    }

    const renderOptionSelect = (option) => {
        const selected = selectedOptions.find(selectedOption => selectedOption.id === option.id);
        return <div className="valign-wrapper">
            {w(option.item_reference)}
            <div className="switch right-align" style={{marginLeft: "auto"}}>
                <label>
                    <input type="checkbox" id="rapport" checked={!!selected}
                           onChange={(e) => handleChange(option, e.target.checked)}/>
                    <span className="lever"></span>
                </label>
            </div>

        </div>
    }

    if(options.length === 0) return null;
    return <BorderedDisplay text={"Options facturables"} containerStyle={{borderRadius: 10}} style={{paddingInline: 20, marginBottom: 20}}>
        <div className="fc">
            {options.map(option => renderOptionSelect(option))}
        </div>
    </BorderedDisplay>
}

export default UserOptions;