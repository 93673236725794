import React from 'react';
import OpticianCampaignsStore from "./OpticianCampaignsStore";
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import CampaignCustomisation from "./CampaignCustomisation";
import {observer} from "mobx-react";
import CampaignStatistics from "./CampaignStatistics";

const CampaignDetails = observer(() => {
    const campaign = OpticianCampaignsStore.selectedCampaign
    if (!campaign) return null
    return <div className="card"
                style={{display: "flex", flexDirection: "column", gap: 20, width: "100%", height: "100%", paddingTop: 5}}>
        <div className="valign-wrapper">
            <div style={{flex: 1}}>
                <h3 className="center" style={{margin: 0}}>Détails de la campagne : {campaign.name}</h3>
            </div>
            <a className="btn-small btn-flat transparent" style={{padding: 0, marginLeft: "auto"}}
               onClick={() => OpticianCampaignsStore.selectCampaign(null)}>
                <i className={`material-icons`}>close</i>
            </a>
        </div>
        <div className="row" style={{flex: 1, gap: 10}}>
            <div className="col s6">
                <BorderedDisplay text={"Statistiques"} height={"100%"}>
                    <CampaignStatistics/>
                </BorderedDisplay>
            </div>
            <div className="col s6">
                <BorderedDisplay text={"Personnalisation"} height="100%" style={{overflowY: "scroll"}}>
                    <CampaignCustomisation/>
                </BorderedDisplay>
            </div>
        </div>
    </div>
})

export default CampaignDetails;