import React, {useEffect} from 'react';
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from '!mapbox-gl';

const Geocoder = ({onGeocode}) => {

    useEffect(() => {

        const geocoder = new MapboxGeocoder({
            accessToken: process.env.MAPBOX_TOKEN,
            mapboxgl: mapboxgl,
            marker: false,
            placeholder: 'Chercher une adresse...',
            countries: 'fr, be',
            types: 'address,poi,locality,region,place,postcode'
        });

        geocoder.addTo('#geocoder');

        const handleResult = (e) => {
            const result = e.result;
            const address_nb = result.address ?? ""
            const address_text = result.text ?? ""
            const address = `${address_nb} ${address_text}`.trim()
            const full_address = result.place_name
            const postcode = result.context.find(c => c.id.includes('postcode'))?.text
            const city = result.context.find(c => c.id.includes('place'))?.text
            const region = result.context.find(c => c.id.includes('region'))?.text
            const country = result.context.find(c => c.id.includes('country'))?.text
            const resultData = {
                address: address,
                full_address: full_address,
                postal_code: postcode,
                city: city,
                region: region,
                country: country,
                longitude: result.center[0],
                latitude: result.center[1]
            }
            onGeocode(resultData);
        }
        geocoder.on('result', handleResult);
        return () => {
            geocoder.off('result', handleResult);
        }

    }, []);

    return <div id="geocoder" style={{height: 50}}/>
};

export default Geocoder;