import React, {useEffect, useState} from 'react';
import {t} from "../I18n/i18n";

const CKEditor = ({text, onChange, options}) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    useEffect(() => {
        if (window.CKEDITOR) {
            initCKEditor();
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://cdn.ckeditor.com/4.15.0/full/ckeditor.js';
        script.onload = () => {
            setIsScriptLoaded(true);
            initCKEditor();
        };
        document.head.appendChild(script);
    }, [isScriptLoaded]);

    const initCKEditor = () => {
        if (isScriptLoaded && window.CKEDITOR) {
            window.CKEDITOR.replace("editor", {
                ...options,
                language: 'fr',
                uiColor: '#64b5f6',
                removePlugins: 'about,a11yhelp,flash,iframe,smiley,pagebreak,stylescombo,' +
                    'colorbutton,colordialog,div,find,flash,forms,iframe,image,newpage,preview,print,' +
                    'templates,video,showblocks,save,selectall,smiley,specialchar,scayt,' +
                    'resize,horizontalrule,htmlwriter,exportpdf',
            });
        }
    };

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div id="editor">
            <div dangerouslySetInnerHTML={{__html: text}}/>
        </div>
        <div style={{paddingInline: 5}}>
            <a className="btn blue darken-2 z-depth-0 right"
               onClick={() => onChange(window.CKEDITOR.instances.editor.getData())}>{t("SAVE")}</a>
        </div>
    </div>
};

export default CKEditor;