import React from 'react';
import MedicalReport from "../../../admin/Controle/Report/MedicalReport";
import telemedical_questions_json
    from "../../../Appointments/Mesures/mesure_components/Questions/telemedical_questions.json";

const AppointmentComments = ({appointment}) => {

    const renderComments = () => {
        const anamnesis = appointment.comment
        const orthoptic_text = appointment.orthoptic_text
        const ophtalmo_text = appointment.ophtalmo_text
        const private_comment = appointment.private_comment
        if (!anamnesis && !orthoptic_text && !ophtalmo_text && !private_comment) return null
        return <div className="fc gap-5">
            <b>Commentaires</b>
            <div className="card grey lighten-4 z-depth-0 " style={{whiteSpace: "pre-wrap"}}>
                {anamnesis && <div><b>Anamnèse :</b><br/>{anamnesis}</div>}
                {orthoptic_text && <div><b>Orthoptie :</b><br/>{orthoptic_text}</div>}
                {ophtalmo_text && <div><b>Commentaire pour ophtalmologiste :</b><br/>{ophtalmo_text}</div>}
                {private_comment && <div><b>Commentaire privé :</b><br/>{private_comment}</div>}
            </div>
        </div>
    }

    const renderPrescriptions = () => {
        const prescriptions = appointment.prescriptions
        const prescriptions_empty = !prescriptions.filter(prescription => prescription.length > 0).length
        if (!prescriptions || !prescriptions.length || prescriptions_empty) return null
        return <div className="fc gap-5">
            <b>Prescriptions</b>
            <div className="card grey lighten-4 z-depth-0 " style={{whiteSpace: "pre-wrap"}}>
                {prescriptions && prescriptions.length > 0 && <div>
                    {prescriptions.map((prescription, index) => {
                        return <div key={prescription.id}>
                            {prescriptions.length > 1 ? <div><b>Prescription {index + 1}</b></div> : null}
                            <div style={{whiteSpace: "pre-wrap"}}>{prescription}</div>
                        </div>
                    })}
                </div>
                }
            </div>
        </div>
    }

    const renderQuestions = () => {
        const report = appointment.report
        if (!report) return null
        return <div className="fc gap-5">
            <b>Questionnaire</b>
            <div className="fr valign-wrapper card grey lighten-4 z-depth-0" style={{paddingBlock: 10, borderRadius: 5}}>
                <MedicalReport report={report} json={telemedical_questions_json}/>
            </div>
        </div>
    }

    return <div className="fc gap-20" style={{fontSize: 12}}>
        {renderComments()}
        {renderPrescriptions()}
        {renderQuestions()}
    </div>
}

export default AppointmentComments;