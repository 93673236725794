import React, {useEffect, useState} from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import {t} from "../../../I18n/i18n";
import {toast} from "react-toastify";

const CSVButtonComponent = ({url, title, sub_title, onDataUpload}) => {
    const [dropZoneMessage, setDropZoneMessage] = useState(t("PATIENT_SEARCH.PLEASE_ENTER_CSV"));
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const modal = document.getElementById("csv_patient_modal");
        const instance = M.Modal.init(modal);
        return () => instance.destroy();
    }, []);

    const onDropCSV = (acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
            setDropZoneMessage(t("PATIENT_SEARCH.ONLY_ONE_FILE"));
            setIsError(true);
            return;
        }
        const file = acceptedFiles[0];
        if (!file.type.includes("csv")) {
            setDropZoneMessage(t("PATIENT_SEARCH.PLEASE_SELECT_CSV"));
            setIsError(true);
            return;
        }
        const formData = new FormData();
        formData.append("csvFiles", file);
        axios.post(url, formData).then((response) => {
            const modal = document.getElementById("csv_patient_modal");
            const instance = M.Modal.getInstance(modal);
            if (instance) {
                instance.close();
            }
            toast.success("L'import de vos données à bien été reçu");
            onDataUpload(response.data);
        }).catch((_) => {
        });
    };

    const renderPicker = () => {
        return (
            <div className="clickable" style={{minHeight: 200}}>
                <Dropzone onDrop={onDropCSV} accept=".csv">
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <div
                            {...getRootProps()}
                            style={{
                                border: '1px solid lightgrey',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80%",
                                minHeight: 200,
                                borderRadius: 5,
                                backgroundColor: isDragActive ? "lightgrey" : "white",
                                overflow: "hidden"
                            }}
                        >
                            <input {...getInputProps()} />
                            {isError ? <div style={{color: 'red'}}>{dropZoneMessage}</div> :
                                <div>{dropZoneMessage}</div>
                            }
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    };

    const renderModal = () => {
        return <div id="csv_patient_modal" className="modal" style={{
            position: 'fixed',
            width: "40%",
            background: "white",
            border: "white",
            padding: "20px",
            marginTop: 80,
            borderRadius: "10px",
            outline: "none"
        }}>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <h4 className="center" style={{margin: 0}}>{t("PATIENT_SEARCH.ADD_PATIENT_WITH")}</h4>
                <label style={{alignSelf: "center"}}>{sub_title}</label>
                <div style={{textAlign: "center"}}>
                    {renderPicker()}
                </div>
            </div>

        </div>
    };

    return (
        <>
            <a style={{fontWeight: 'bold'}} className="btn-small blue darken-2 z-depth-0 modal-trigger"
               data-target="csv_patient_modal"
               data-cy="AddPatientWIthCSV">
                {title}<i className="material-icons left">add</i>
            </a>
            {renderModal()}
        </>
    );
};

export default CSVButtonComponent;
