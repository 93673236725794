import React, {Component} from 'react';
import BridgeStore from "../BridgeStore"
import axios from "axios"
import {observer} from "mobx-react"

@observer
export default class Scan extends Component {
  constructor(props){
    super(props);
    this.loadBridges = this.loadBridges.bind(this)
    this.handleCustomIp = this.handleCustomIp.bind(this)
    this.state = {
      bridges: [],
      customip: BridgeStore.ip
    }
    this.loadBridges()
  }
  handleCustomIp(e){
    this.setState({customip: e.target.value})
  }
  loadBridges(){
    axios.get("/api/v2/bridge/avaiable_bridges").then((data)=>{
      this.setState({bridges: data.data})
    })
  }
  render() {
    return (
      <div>
        {this.state.bridges.map((bridge)=>(
          <div className="card white" key={bridge.id}>
        <div className="card-content grey-text">
          <span className="card-title black-text">{bridge.lan}</span>
          <p>identifiant {bridge.uid} <br/></p>
          <Statut ip={bridge.lan} />
        </div>
        <div className="card-action">
        {BridgeStore.ip==bridge.lan ? (
            <a> Vous êtes inscrit à ce pont</a>
          ) : (
            <a onClick={(e)=>BridgeStore.connect(bridge.lan)}>Se connecter</a>
          )}
        </div>
      </div>
          ))}

        <div className="card white">
        <div className="card-content black-text">
          <span className="card-title black-text">Connexion manuel</span>
          <div className="input-field">
            <input type="text" id="manual-ip" onChange={this.handleCustomIp} value={this.state.customip} />
            <label htmlFor="manual-ip" className="active">127.0.0.1 </label>
          </div>
        </div>
        <div className="card-action">
        {BridgeStore.ip==this.state.customip ? (
            <a> Vous êtes inscrit à ce pont</a>
          ) : (
            <a onClick={(e)=>BridgeStore.connect(this.state.customip)}>Connexion manuel</a>
          )}
        </div>
      </div>

      </div>
    );
  }
}

class Statut extends React.Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.state = {
      statut: "chargement"
    };
    this.load()
  }
  load(){
    const instance = axios.create();
    instance.defaults.timeout = 1000;

    instance.get("http://"+this.props.ip+":5000/info").then((data)=>{
      this.setState({statut: "Disponible"})
    }).catch((err)=>{
      this.setState({statut: "Indisponible"})
    })
  }
  render(){
    const statut = this.state.statut
    return(
        <div>
          <span className={"black-text bridge-statut bridge-"+statut}> {statut}</span>
        </div>
      )
  }
}
