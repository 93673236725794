import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Validator from "../../Shared/Validator";
import {c, t} from "../../I18n/i18n"

function SendCodeToPatient({patient}) {
    const sendEmail = () => {
        axios.put(`/patient/${patient.id}/send_key`).then(res => {
            toast.success("Email envoyé")
        }).catch(err => {
            if(err.response.status === 429) {
                toast.error("Un email a déjà été envoyé récemment.")
            } else {
                toast.error("Une erreur est survenue")
            }
        });
    }

    return (
        <Validator onValidation={sendEmail}
                   text={c("PATIENT_INFO.SEND_KEY_VALIDATOR", {patient: Helper.formatName(patient.prenom, patient.nom)})}
                   detail={t("PATIENT_INFO.SEND_KEY_TEXT")}
                   validationText={t("SEND")} validationColor="blue darken-2" validationIcon="send"
                   abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                   id="validate-patient_key_email">
            <a className="btn-small blue darken-2 z-depth-0"
               title={t("PATIENT_INFO.SEND_KEY")}>
                <i className="material-icons">mail</i>
            </a>
        </Validator>
    );
}

export default SendCodeToPatient;