export const Role = {
    ORTHOPTIST: "Orthoptiste",
    OPHTALMOLOGIST: "Ophtalmologiste",
    OPTICIAN: "Opticien",
    SECRETARY: "Secretaire",
    EXTERN: "Externe",
    SUPER_ADMIN: "SuperAdmin",
    ADMIN: "Admin",
    AUDITOR: "Auditor",
    CV_USER: "CvUser",
    LOCKED: "Désactivé",
    GUEST: {
        OPTICIAN: "OPTICIAN_GUEST",
        LOCAL_REFERENT: "LOCAL_REFERENT",
    }
}