import React, {useEffect, useState} from "react";
import axios from "axios";
import {PaymentTrackerAlertLabel} from "./Enums/PaymentTrackerEnums";
import Helper from "../../../Shared/Helper";
import {wrapper} from "../../../I18n/i18n";

const {t} = wrapper("PAYMENT_TRACKER")
const PaymentTracker = () => {
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        get_data();
    }, []);

    const get_data = () => {
        axios.get("/admin/console/payment_tracking").then((response) => {
            setPayments(label_payments(response.data))
        }).catch((error) => {
            console.log(error);
        })
    }

    const label_payments = (payments) => {
        return payments.map((payment) => {
            const failed = payment.error_message
            const will_fail = payment.error_reason

            let label = PaymentTrackerAlertLabel.OK
            if(will_fail) label = PaymentTrackerAlertLabel.WILL_FAIL
            if(failed) label = PaymentTrackerAlertLabel.FAILED

            return {...payment, label: label}
        })
    }

    // labels : FAILED > WILL_FAIL > OK
    const sort_payments = (payments) => {
        return payments.sort((a, b) => {
            if(a.label === PaymentTrackerAlertLabel.FAILED) return -1
            if(b.label === PaymentTrackerAlertLabel.FAILED) return 1

            if(a.label === PaymentTrackerAlertLabel.WILL_FAIL) return -1
            if(b.label === PaymentTrackerAlertLabel.WILL_FAIL) return 1

            return 0
        })
    }

    const renderLabelIcon = (label) => {
        switch (label) {
            case PaymentTrackerAlertLabel.FAILED:
                return <i className="material-icons red-text">error</i>
            case PaymentTrackerAlertLabel.WILL_FAIL:
                return <i className="material-icons orange-text">warning</i>
            case PaymentTrackerAlertLabel.OK:
                return <i className="material-icons green-text">check</i>
            default:
                return <i className="material-icons">check</i>
        }
    }

    const render_payment = (payment) => {
        return <div className="row" style={{width: "100%"}}>
            <div className="col s1 valign-wrapper">{renderLabelIcon(payment.label)}</div>
            <div className="col s1">#{payment.id}</div>
            <div className="col s2">{Helper.formatName(payment.user_firstname, payment.user_lastname)}</div>
            <div className="col s2">{Helper.formatDate(payment.date)}</div>
            <div className="col s6">
                {t(`ERROR_TYPE.${payment.error_reason}`)}
            </div>

        </div>
    }

    return <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
        <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">crisis_alert</i>
            {t("TITLE")}
        </h3>
        <div className="divider"/>
        <div style={{margin: 20}}>
            <ul className="collection" style={{borderRadius: 5}}>
                {sort_payments(payments).map((payment) => {
                    return <li className="collection-item transparent" key={`tracked_payment_${payment.id}`}
                                style={{display: "flex", width: "100%"}}>
                        {render_payment(payment)}
                    </li>
                })}
            </ul>
        </div>

    </div>
}

export default PaymentTracker;