import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";

const _ = require('lodash');
const AccountSwap = ({children, patient, accounts}) => {

    const connect = (id) => {
        if(id === patient.id) return
        axios.get(`/accounts/swap/${id}`)
            .then(() => window.location = "/list")
            .catch(err => toast.error("Impossible de naviguer vers le compte sélectionné"))
    }

    if (_.isEmpty(accounts)) return children
    return <select className="browser-default" style={{maxWidth: 200, marginBlock: 10}}
                   onChange={(e) => connect(e.target.value)}>
        <option value={patient.id} selected>{Helper.formatName(patient.first_name, patient.last_name)}</option>
        {accounts.map((account, idx) => <option key={idx} value={account.id}>
            {Helper.formatName(account.prenom, account.nom)}
        </option>)}
    </select>

}

export default AccountSwap;