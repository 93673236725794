import React, {Component} from "react";
import axios from "axios";
import SelectTemplate from "./Edit/SelectTemplate";
import TemplateEdit from "./Edit/TemplateEdit";
import DisplayBlocks from "./Display/DisplayBlocks";
import {toast} from "react-toastify";
import Loader from "../../Shared/Loader";

export default class Reports extends Component {
    constructor() {
        super();
        this.set = this.set.bind(this)
        this.onChange = this.onChange.bind(this)
        this.addTemplate = this.addTemplate.bind(this)
        this.editTemplateName = this.editTemplateName.bind(this)
        this.deleteTemplate = this.deleteTemplate.bind(this)
        this.state = {
            data: {templates: []},
            selectedTemplate: {},
            wip: false,
            edited: []
        }
    }

    componentDidMount() {
        this.get()
    }
    //
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.props !== prevProps) {
    //         this.updateState(this.props.reports)
    //     }
    // }

    updateState(reports) {
        if (reports !== undefined && reports.templates !== undefined) {
            this.setState({
                data: reports,
                selectedTemplate: reports.templates[0]
            })
        }
    }

    onChange(template) {
        this.setState({
            data: {
                ...this.state.data,
                templates: this.state.data.templates.map(t => {
                    return t.id === template.id ? template : t
                })
            },
            selectedTemplate: template,
            wip: true,
            edited: this.state.edited.includes(template.id) ? this.state.edited : this.state.edited.concat(template.id)
        })
    }

    nameExists(name) {
        if (this.state.data.templates && this.state.data.templates.length > 0) {
            let nameExists = false;
            this.state.data.templates.map((template) => {
                if (template.name === name) nameExists = true
            })
            return nameExists
        } else return false
    }

    addTemplate(name) {
        if (this.nameExists(name) === true) {
            toast.error("Ce nom existe déjà")
            return
        }

        this.setState({
            data: Object.assign({}, this.state.data, {
                templates: this.state.data.templates.concat({name: name, blocks: []}),
            }),
            wip: true
        }, () => this.setState({selectedTemplate: this.state.data.templates[this.state.data.templates.length - 1]}))
    }

    editTemplateName(name) {
        if (this.nameExists(name) === true) {
            toast.error("Ce nom existe déjà.")
            return
        }

        this.setState({
            data: Object.assign({}, this.state.data, {
                templates: this.state.data.templates.map((template) =>
                    template.name === this.state.selectedTemplate.name ? Object.assign({}, template, {name: name}) : template)
            }),
            wip: true,
            edited: this.state.edited.includes(this.state.selectedTemplate.id) ? this.state.edited : this.state.edited.concat(this.state.selectedTemplate.id)
        }, () => this.setState({selectedTemplate: this.state.data.templates.filter((template) => template.id === this.state.selectedTemplate.id)[0]}))
    }

    deleteTemplate() {
        this.setState({
            data: Object.assign({}, this.state.data, {
                templates: this.state.data.templates.filter((template) => template.name !== this.state.selectedTemplate.name)
            }),
            wip: true
        }, () => this.setState({selectedTemplate: this.state.data.templates.length !== 0 ? this.state.data.templates[0] : {}}))
    }

    importTemplate = (template) => {
        if (!template.name || template.name === "") {
            toast.error("Document invalide")
            return
        }

        if (this.nameExists(template.name) === true) {
            toast.error("Ce nom existe déjà")
            return
        }

        this.setState({
            data: Object.assign({}, this.state.data, {
                templates: this.state.data.templates.concat(template),
            }),
            wip: true
        }, () => this.setState({selectedTemplate: this.state.data.templates[this.state.data.templates.length - 1]}))
    }

    get() {
        axios.get("/orthoptistes/reports").then((resp) => {
            this.updateState(resp.data.reports)
        }).catch((err) => {
            toast.error("Les comptes rendus n'ont pas pu être récupérés")
        })
    }

    set() {
        axios.post("/orthoptistes/reports", {
            reports: this.state.data,
            edited: this.state.edited
        }).then((resp) => {
            this.setState({
                wip: false,
                edited: [],
                data: resp.data.reports,
                selectedTemplate: resp.data.reports.templates.filter((template) => template.name === this.state.selectedTemplate.name)[0]
            }, () => toast.success("Les comptes rendus ont été sauvegardés avec succès"))
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Les titres des contenus éditables doivent être uniques.")
            } else {
                toast.error("Les comptes rendus n'ont pas pu être sauvegardés")
            }
        })
    }

    // Scroll to focus BlockEdit component with block id
    focusBlock = (block) => {
        document.getElementById(`block_${block.id}`).scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest"
        })
        document.getElementById(`block_${block.id}`).style.transition = "background-color 1s"
        document.getElementById(`block_${block.id}`).style.backgroundColor = "#e6f7ff"
        setTimeout(() => {
            document.getElementById(`block_${block.id}`).style.backgroundColor = "transparent"
        }, 2000)

    }

    render() {
        if (!this.state.data) {
            return <Loader/>
        }
        return (
            <div style={{margin: 0, paddingLeft: 2, display: "flex"}} id="full-height">
                <div className="z-depth-1" style={{width: "34%", height: "100%", padding: 0, display: "flex", flexDirection: "column"}}>
                    <SelectTemplate data={this.state.data}
                                    selectedTemplate={this.state.selectedTemplate}
                                    addTemplate={(name) => this.addTemplate(name)}
                                    editTemplateName={(name) => this.editTemplateName(name)}
                                    deleteTemplate={() => this.deleteTemplate()}
                                    importTemplate={(template) => this.importTemplate(template)}
                                    onChoose={(template) => this.setState({selectedTemplate: template})}/>
                    <div style={{
                        backgroundColor: "#fbfbfd", width: "100%", fontStyle: "italic",
                        fontWeight: "bold", boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 0.10)", marginBottom: "3px"
                    }} className={`center red-text ${this.state.wip ? "" : "hide"}`}>
                        <h6>Les modifications ne seront pas sauvegardées tant que le bouton SAUVEGARDER n'aura pas été
                            cliqué</h6>
                    </div>
                    <div style={{backgroundColor: "#fbfbfd", height: "100%", overflowY: "scroll", overflowX: "hidden"}}>
                        <TemplateEdit template={this.state.selectedTemplate}
                                      onChange={(template) => this.onChange(template)}/>
                    </div>
                    <div className="white valign-wrapper"
                         style={{
                             boxShadow: "0px -3px 0px 0px rgba(0, 0, 0, 0.10)",
                             marginTop: "auto", alignSelf: "flex-end", width: "100%"
                         }}>
                        <i className="material-icons transparent-text" style={{paddingLeft: "1vw"}}>egg</i>
                        <div style={{padding: "2vh 2vw 2vh 2vw", marginLeft: "auto"}}>
                            <a
                                className={`btn-small blue darken-2 waves-light z-depth-0 ${this.state.wip ? "" : "disabled"}`}
                                onClick={this.set}>
                                <i className="material-icons left">save</i>Sauvegarder
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    paddingTop: "2vh",
                    paddingBottom: "1vh",
                    overflowY: "scroll",
                    width: "66%"
                }}>
                    <DisplayBlocks template={this.state.selectedTemplate}
                                   onBlockClick={(block) => this.focusBlock(block)}/>
                </div>
            </div>
        )
    }
}