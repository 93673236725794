import React, {useEffect} from "react";
import {Colors} from "./ColorEnums";
import {t} from "../I18n/i18n"


export default function AlertTab({alertList, alertsType, selectAlert}) {

    useEffect(() => {
        let tooltips = document.querySelectorAll('[id=alertSecretaryCommentTooltip]')
        if (tooltips) M.Tooltip.init(tooltips, {exitDelay: 0, enterDelay: 50})
    }, []);


    const findCorrespondingAttribute = (type, value) => {
        const color_index = Colors.findIndex((color) => color.alert_type === value)
        return color_index > 0 ? Colors[color_index][type] : Colors.slice(-1)[0][type]
    }


        return (
            <div className="center" style={{paddingTop: 20}}>
                {alertList && alertList.length > 0 ?
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        {alertList.map((alert, index) =>
                            <div key={`${alertsType}_${alert.appointment.id}_${index}`}
                                 style={{
                                     width: "23%",
                                     height: "50px",
                                     margin: "0.5vh 1%",
                                     borderRadius: "10px",
                                     display: "flex",
                                     wordBreak: "break-word",
                                     cursor: "pointer"
                                 }}
                                 className={`tooltipped ${findCorrespondingAttribute("secondary_color", alert.alert.gravity)}`}
                                 id={alert.alert.secretary_comment ? "alertSecretaryCommentTooltip" : null}
                                 data-target="alertSecretaryCommentTooltip"
                                 data-position="top"
                                 data-tooltip={alert.alert.secretary_comment}>

                                <a
                                    style={{
                                        display: "grid",
                                        width: "100%",
                                        gridTemplateColumns: "80% 20%"
                                    }}
                                    className={findCorrespondingAttribute("text_color", alert.alert.gravity)}
                                    onClick={() => selectAlert(alert)}>
                                    <div style={{
                                        gridColumn: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}><b>{alert.patient.last_name} {alert.patient.first_name}</b>
                                    </div>
                                    <i style={{
                                        gridColumn: 2,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                       className={`material-icons ${findCorrespondingAttribute("text_color", alert.alert.gravity)}`}>expand_circle_down</i>
                                </a>
                            </div>
                        )}
                    </div>
                    : <p>{t("ALERTS_PAGE.ALERT_CATEGORY_EMPTY")}</p>
                }
            </div>
        )

}