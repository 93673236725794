import {Rating} from "react-simple-star-rating";
import React, {useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {t} from "../I18n/i18n"
import {StarIcon} from "./StarIcon";


export default function RatingCard({last_orthoptist_name, google_rating_url}) {

    const [rating_note, setRatingNote] = React.useState(null)
    const [rating_comment, setRatingComment] = React.useState("")
    const [prepareGoogleReview, setPrepareGoogleReview] = useState(false)
    const [allow_rating, setAllowRating] = useState(true)

    const saveRating = () => {
        axios.put("/patient_rating", {note: rating_note, comment: rating_comment})
            .then((_) => {
                toast.success(t("PATIENT_PORTAL.SAVED_REVIEW"))
                if (rating_note >= 4) setPrepareGoogleReview(true)
                else setAllowRating(false)
            })
            .catch((_) => toast.error(t("PATIENT_PORTAL.ERROR_SAVING_REVIEW")))
    }

    const renderStars = () => {
        // if iphone
        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
            return <div style={{display: "flex", gap: 5, justifyContent: "center"}}>
                {Array(5).fill("").map((_, index) => {
                    return <div style={{cursor: "pointer"}}
                                onClick={(e) => setRatingNote(index)}>
                        <StarIcon
                            StarColor={getStarColor(index)}
                            size={40}/>
                    </div>
                })}
            </div>
        } else return <Rating
            showTooltip={false}
            allowFraction={true}
            titleSeparator={t("PATIENT_PORTAL.OUT_OF")}
            onRate={(e) => setRatingNote(e)}
            onClick={setRatingNote}
        />
    }

    const getStarColor = (index) => {
        if (rating_note === null) return "#d6d6cd"
        if (rating_note >= index) return "rgb(255, 188, 11)"
        return "#d6d6cd"
    }

    const renderAskRating = () => {
        return <>
            <div>
                <h4 className="card-title" style={{margin: 0}}>{t("PATIENT_PORTAL.GOOD_EXAM")}</h4>
                {last_orthoptist_name ?
                    <label>{t("PATIENT_PORTAL.HELP_ORTHOPTIST", {orthoptist_name: last_orthoptist_name})}</label> : null}
            </div>
            {renderStars()}
            <div className="input-field outlined">
                    <textarea
                        style={{resize: "none", padding: 10, height: 120, fontSize: 12}}
                        onChange={(e) => setRatingComment(e.target.value)}
                    />
                <label>{t("PATIENT_PORTAL.MY_COMMENT")}</label>
            </div>
            <a className={`btn blue darken-2 z-depth-0 center ${rating_note === null ? "disabled" : ""}`}
               style={{marginTop: 5}}
               onClick={saveRating}
            >{t("PATIENT_PORTAL.SEND_REVIEW")}</a></>
    }

    const renderGoogleReview = () => {
        return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <h4 style={{marginBottom: 10}}>{t("PATIENT_PORTAL.THANKS_REVIEW")}</h4>
            <h6 style={{paddingInline: 40, whiteSpace: "pre-wrap"}}>{t("PATIENT_PORTAL.THANKS_DETAIL")}</h6>
            <h6 style={{fontWeight: "bolder", paddingInline: 20}}>{t("PATIENT_PORTAL.THANKS_INCENTIVE")}</h6>
            {/*in anoter tab*/}
            <a className="btn blue darken-2 z-depth-0" target="_blank" style={{fontWeight: "bolder"}}
               href={google_rating_url?.length ? google_rating_url : "https://g.page/r/CbdHFX_sz4MHEBE/review"}>
                {t("PATIENT_PORTAL.GOOGLE_CTA")}
            </a>
        </div>
    }

    if (!allow_rating) return null

    return <div className="card" style={{width: "80%", margin: 20, paddingBottom: 0, paddingTop: 0}}>
        <div className="card-content center" style={{display: "flex", flexDirection: "column", gap: 20}}>
            {prepareGoogleReview ? renderGoogleReview() : renderAskRating()}
        </div>
    </div>
}