import React, {useEffect} from 'react';
import Mapbox from "./Mapbox";

const CoordinatePicker = ({onValidation, defaultCoordinates}) => {
    const [lnglat, setLnglat] = React.useState({});
    const [displayModal, setDisplayModal] = React.useState(false);

    useEffect(() => {
        if(!displayModal) return;
        if (defaultCoordinates) {
            setLnglat(defaultCoordinates);
        }
    }, [displayModal]);

    useEffect(() => {
        const handleKeypress = (e) => {
            if (e.key === "Escape") {
                setDisplayModal(false);
            }
        };
        window.addEventListener("keydown", handleKeypress);
        return () => {
            window.removeEventListener("keydown", handleKeypress);
        }
    }, []);


    const handleValidation = () => {
        setDisplayModal(false);
        onValidation({
            lat: lnglat.lat,
            lng: lnglat.lng
        });
    };

    const renderModal = () => {
        if (!displayModal) return null;
        return <div className="card"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") handleValidation()
                    }}
                    style={{
                        position: "fixed",
                        height: "70%",
                        width: "70%",
                        right: 0,
                        left: 0,
                        top: "10vh",
                        margin: "auto",
                        zIndex: 1000,
                        padding: 0,
                        borderRadius: 3
                    }}>
            <Mapbox defaultCoordinates={defaultCoordinates} onChange={(lnglat) => setLnglat(lnglat)}/>
            <div style={{
                position: "absolute",
                top: -15,
                display: "flex",
                justifyContent: "center",
                right: 0,
                margin: "40px 20px",
                gap: 10,
                zIndex: 1001,
                backgroundColor: "white",
                padding: 5, borderRadius: 30
            }}>
                <a className="btn btn-floating green darken-2">
                    <i className="material-icons white-text" onClick={handleValidation}>check</i>
                </a>
                <a className="btn btn-floating red darken-2">
                    <i className="material-icons white-text" onClick={() => setDisplayModal(false)}>close</i>
                </a>

            </div>
        </div>
    };

    return <>
        <a className={`btn btn-flat transparent ${defaultCoordinates ? "" : "red-text text-darken-2"}`}
           onClick={() => setDisplayModal(true)}>
            <i className={`material-icons`}>add_location</i>
        </a>
        {renderModal()}
    </>
};

CoordinatePicker.defaultProps = {
    onValidation: (latlng) => console.log(`lat: ${latlng.lat}, lon: ${latlng.lng}`),
    id: "mapbox"
};

export default CoordinatePicker;

