import React, {useEffect, useState} from "react";
import BillingOverview from "../../../../../Orthoptistes/Paiements/BillingManager/BillingOverview";
import PricesOverview from "../../../../../Orthoptistes/Paiements/BillingManager/PricesOverview";
import Simulate from "../../../../../Orthoptistes/Paiements/Simulate";
import axios from "axios";
import BorderedDisplay from "../../../../../Shared/UiKit/BorderedDisplay";
import BillingPreview from "../../../../../Orthoptistes/Paiements/BillingPreview";
import InvoiceList from "../../../../../Orthoptistes/Paiements/BillingManager/InvoiceList";
import OrthoptistOptions from "./OrthoptistOptions";
import OrthoptistSubscriptionSettings from "./OrthoptistSubscriptionSettings";


const OrthoptistBillingPanel = () => {
    const [orthoptist, setOrthoptist] = useState({})
    const [billing, setBilling] = useState({})
    const [pricing, setPricing] = useState({})

    useEffect(() => {
        getBillingData()
    }, []);

    const getBillingData = () => {
        axios.get('/orthoptistes/paiements/billing_data').then((response) => {
            setOrthoptist(response.data.orthoptist)
            setBilling(response.data.billing)
            setPricing(response.data.pricing)
        })
    }

    return <div className="full-height row" style={{padding: 20, gap: 20}}>
        <div className="col s12 m4 fc" style={{gap: 20}}>
            <BorderedDisplay containerStyle={{borderRadius: 20}}>
                <OrthoptistOptions/>
            </BorderedDisplay>
            <BorderedDisplay containerStyle={{borderRadius: 20}}>
                <OrthoptistSubscriptionSettings/>
            </BorderedDisplay>
            <BorderedDisplay containerStyle={{borderRadius: 20}}>
                <div className="fr" style={{justifyContent: "space-between"}}>
                    <h6>Retrouvez les Conditions Générales de Vente de Temeoo ici :</h6>
                    <a className={"btn-small blue darken-2 z-depth-0"} href="https://www.temeoo.fr/orthoptists/cgv" target="_blank">CGV</a>
                </div>
            </BorderedDisplay>
            <BorderedDisplay containerStyle={{borderRadius: 20}} style={{padding: 10}}>
                <PricesOverview prices={billing.prices}/>
            </BorderedDisplay>
        </div>
        <div className="col s12 m8 fc" style={{gap: 20}}>
            <BorderedDisplay containerStyle={{borderRadius: 20}}>
                <BillingOverview last4={orthoptist.last4}
                                 bankCode={orthoptist.bank_code}
                                 bankTitle={orthoptist.bank_title}
                                 lastPaymentDate={Helper.formatDate(orthoptist.last_payment)}
                                 lastDue={billing.last_due}
                                 nextDue={billing.next_due}
                                 fixed={orthoptist.fixed_amount / 100.0}
                                 variable={orthoptist.variable_amount / 100.0}/>
            </BorderedDisplay>
            <BorderedDisplay containerStyle={{borderRadius: 20}}>
                <BillingPreview/>
                <InvoiceList/>
            </BorderedDisplay>
            <BorderedDisplay containerStyle={{borderRadius: 20}}>
                <Simulate/>
            </BorderedDisplay>
        </div>
    </div>


    return <div className="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
        <h1 className="center" style={{margin: 0}}>Gestion des paiements</h1>

        <div className="row" style={{gap: 20}}>
            <div className="col s12 l6 card">
                <PricesOverview prices={billing.prices}
                                fixed={orthoptist.fixed_amount / 100.0}
                                variable={orthoptist.variable_amount / 100.0}
                                pricing={pricing}
                />
            </div>
            <div className="col s12 l6 card">
                <Simulate/>
            </div>
        </div>
    </div>
}

export default OrthoptistBillingPanel;