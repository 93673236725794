import React from "react";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import {t} from "../../../I18n/i18n"
import PossibleExamens from "../../../ophtalmologiste/possibleexamens/PossibleExamens";
import MainPage from "../../../AlertTexts/MainPage";

export default function ManagementPanel() {

    return <div className="row" style={{padding: 20, gap: 20}}>
        <div className="col s12 l6">
            <BorderedDisplay text="Gestion des examens">
                <p className="grey-text text-darken-2" style={{textAlign: "center"}}>{t("POSSIBLE_EXAM.CAN_DELETE")}</p>
                <PossibleExamens/>
            </BorderedDisplay>
        </div>
        <div className="col s12 l6">
            <BorderedDisplay text="Gestion des risques" style={{flex: 1}}>
                <p className="grey-text text-darken-2" style={{textAlign: "center"}}>Vous pouvez ici créer, modifier, et
                    supprimer des risques et leur attribuer un titre et un message (ces risques seront visibles dans la
                    conclusion du compte rendu)</p>
                <MainPage/>
            </BorderedDisplay>
        </div>
    </div>
}