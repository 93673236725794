import React, {useEffect, useRef} from "react";
import ActsAnalyses from "./ActsAnalyses";
import PatientSatisfaction from "./PatientSatisfaction";
import {t} from "../../../I18n/i18n"

function ActsStatistics() {

    const tabReference = useRef(null)

    useEffect(() => {
        const instance = M.Tabs.init(tabReference.current)
        return () => instance.destroy()
    }, []);


    return (
        <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
            <div className="row">
                <div className="col s12">
                    <ul className="ortho_stat_tabs tabs tabs-fixed-width center z-depth-1" ref={tabReference}>
                        <li className="tab col s3">
                            <a className="active" href="#acts"
                            >{t("STATISTICS_TABS.TAB_NAMES.ACTS")}</a>
                        </li>
                        <li className="tab col s3">
                            <a href="#patient_satisfaction"
                            >{t("STATISTICS_TABS.TAB_NAMES.PATIENT_SATISFACTION")}</a>
                        </li>
                    </ul>
                </div>
                <div id="acts" className="col s12">
                    <ActsAnalyses/>
                </div>
                <div id="patient_satisfaction" className="col s12">
                    <PatientSatisfaction/>
                </div>
            </div>
        </div>
    );
}

export default ActsStatistics