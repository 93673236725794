import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {isLoading, RequestState} from "../../../../../Shared/StateHelper";
import CVStore from "../../../../../CarteVitale/CVStore";
import BridgeStoreV2 from "../../../../../BridgeV2/BridgeStoreV2";
import {toast} from "react-toastify";
import axios from "axios";
import EditFse from "../../../../../CarteVitale/Fse/EditFse";
import MesureStore from "../../../MesureStore";
import useFseCreationRequest from "./hooks/useFseCreationRequest";
import {generatePdfFromUrl} from "../../../../../Shared/PDF/PDFManager";


const _ = require('lodash')
const FSEButton = observer(({retracted}) => {
    const {sendFseCreationRequest} = useFseCreationRequest(MesureStore.appointement_id)

    useEffect(() => {
        getDelayedAppointements()
        const modal = document.getElementById("fse_edition_modal");
        const instance = M.Modal.init(modal);
        return () => instance.destroy();
    }, []);

    useEffect(() => {
        CVStore.initFse(MesureStore.orthoptiste, [MesureStore.selected_act], MesureStore.patient, MesureStore.appointement_id)
    }, [MesureStore.orthoptiste, MesureStore.act_saved, MesureStore.patient, MesureStore.appointement_id]);

    const getDelayedAppointements = () => {
        const patient_id = MesureStore.patient_id
        axios.get("/api/appointment/delay/list", {
            params: {
                patient_id: patient_id,
                appointment_id: MesureStore.appointement_id
            }
        }).then(resp => {
            CVStore.delayedAppointments = resp.data
        })
    }

    const sendRequest = async (scor = false) => {
        if (isLoading(CVStore.fseRequestState)) return
        let scorData = null
        if (scor) {
            let pdfPromise = null
            const scor = MesureStore.selectedChain?.parent_prescription_url || MesureStore.prescription_url
            if(scor) {
                pdfPromise = axios.get(scor, {responseType: 'arraybuffer'})
                    .then((response) => new Blob([response.data], {type: 'application/pdf'}))
            } else {
                pdfPromise = generatePdfFromUrl("/api/appointment/scor?appointment_id=" + MesureStore.appointement_id)
            }
            scorData = await toast.promise(pdfPromise, {
                pending: "Génération du SCOR en cours ...",
                success: "SCOR envoyé à Méd'OC avec succès !",
                error: "Erreur lors de la génération du SCOR."
            }).then((data) => {
                return data
            })
        }

        const promise = sendFseCreationRequest(scorData)
        promise.then(() => {
            CVStore.fseRequestState = RequestState.SUCCESS
            toast.info('Feuille de soin créée avec succès !')
        }).catch((error) => {
            CVStore.fseRequestState = RequestState.ERROR
            toast.error(error.message)
        })
    }

    const renderButton = () => {
        let color = "blue darken-2"
        if (CVStore.fseRequestState === RequestState.SUCCESS) color = "green darken-2"
        if (CVStore.fseRequestState === RequestState.ERROR) color = "red darken-2"

        if (retracted) return <a
            className={`btn-small ${color} z-depth-0 modal-trigger ${CVStore.hasCV ? "" : "disabled"}`}
            data-target="fse_edition_modal"
            style={{paddingInline: 4}}>
            <i className="material-icons">rss_feed</i>
        </a>

        return <a className={`btn-small ${color} z-depth-0 modal-trigger ${CVStore.hasCV ? "" : "disabled"}`}
                  data-target="fse_edition_modal">
            <i className="material-icons left">rss_feed</i>
            Éditer feuille de soins
        </a>
    }

    const renderModal = () => {
        const orthoptist = {}
        return <div id="fse_edition_modal" className="modal" style={{
            width: "90%",
            borderRadius: 10,
        }}>
            <div className="modal-content" style={{height: "100%"}}>
                <EditFse uid={BridgeStoreV2.uid} orthoptist={orthoptist}/>
            </div>
            <div className="modal-footer" style={{backgroundColor: "#f3f3f3"}}>
                <div className="valign-wrapper" style={{gap: 10, justifyContent: "right"}}>
                    <a className={`btn blue darken-2 z-depth-0 ${CVStore?.fse?.acts?.length === 0 ? "disabled" : ""}`}
                       onClick={() => sendRequest()}
                    >
                        <i className="material-icons right">rss_feed</i>
                        Créer feuille de soin
                    </a>
                    {/*Uncomment that when medoc is ready to handle it*/}
                    <a className={`btn blue darken-2 z-depth-0 ${CVStore.canScorBeSent ? "" : "disabled"}`}
                       onClick={() => sendRequest(true)}
                    >
                        <i className="material-icons right">rss_feed</i>
                        Créer feuille de soin avec SCOR
                    </a>
                    <a className="modal-close btn red darken-2 darken-2 z-depth-0">Annuler</a>
                </div>
            </div>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        {renderButton()}
        {renderModal()}
    </div>
});

FSEButton.defaultProps = {
    retracted: false
};

export default FSEButton;

