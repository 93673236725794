import React, {useEffect} from 'react';
import InvoiceHeader from "./InvoiceHeader";
import InvoiceStamp from "./InvoiceStamp";
import Helper from "../../../Shared/Helper";
import InvoiceRetrocessionBody from "./InvoiceRetrocessionBody";

const RetrocessionDocument = ({invoice, orthoptist, ophtalmologist}) => {
    useEffect(() => {
        window.status = "ready"
    }, []);

    const name = () => {
        if (orthoptist.invoice_label) return orthoptist.invoice_label
        return Helper.formatName(orthoptist.prenom, orthoptist.nom)
    }

    return <div style={{padding: 20, display: "flex", justifyContent: "center"}}>
    <div className="card" style={{minHeight: "297mm", width: "210mm"}}>
        <div className="pdf-document" style={{display: "flex", flexDirection: "column"}}>
            <InvoiceHeader retrocession/>
            <div style={{display: "flex", justifyContent: "space-around", margin: "40px 0"}}>
                <div style={{display: "flex", flexDirection: "column", margin: "40px 0"}}>
                    <h5 style={{margin: 0}}><b>Au nom et pour le compte de :</b></h5>
                    <h5 style={{margin: 0}}>{name()}</h5>
                    <h5 style={{margin: 0}}>{orthoptist.rpps}</h5>
                </div>
                <div style={{display: "flex", flexDirection: "column", margin: "40px 0"}}>
                    <h5 style={{margin: 0}}><b>Adressé à :</b></h5>
                    <h5 style={{margin: 0}}>Dr {Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom)}</h5>
                    <h5 style={{margin: 0}}>RPPS :{ophtalmologist.rps}</h5>
                    <h5 style={{margin: 0}}>ADELI :{ophtalmologist.adeli}</h5>
                </div>
            </div>
            <div style={{margin: "100px 0 0 0"}}>
                <InvoiceRetrocessionBody invoice={invoice} orthoptist={orthoptist}/>
            </div>
            <div id="fixed-div" style={{position: "fixed", top: "180px", left: "800px", opacity: 0.3}}>
                <InvoiceStamp status={invoice.status}/>
            </div>
        </div>
    </div>
    </div>
}

export default RetrocessionDocument;