import React, {useEffect} from 'react';
import Helper from "../../../Shared/Helper";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceStamp from "./InvoiceStamp";

const GeneralInvoice = ({invoice, orthoptist, retrocession_amount}) => {
    useEffect(() => {
        window.status = "ready"
    }, []);

    const name = () => {
        if (orthoptist.invoice_label) return orthoptist.invoice_label
        return Helper.formatName(orthoptist.prenom, orthoptist.nom)
    }

    const body = () => {
        const total_ttc = ((invoice.totalOrthoptiste ?? 0) + (retrocession_amount ?? 0) ) / 100.0
        const plateforme_ttc = (invoice.totalPlateforme ?? 0) / 100.0
        const ophtalmo_ttc = retrocession_amount !== null ? (retrocession_amount / 100.0) : (total_ttc - plateforme_ttc)
        const plateforme_ht = plateforme_ttc / 1.2
        const plateforme_tva = plateforme_ttc - plateforme_ht
        const date = Helper.formatDate(invoice.created_at, "DD/MM/YYYY")

        return <div style={{display: "flex", flexDirection: "column"}}>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Justificatif de paiement pour votre activité
                du {Helper.formatDate(invoice.from)} au {Helper.formatDate(invoice.to)}.
            </h5>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Montant prélevé : {total_ttc.toFixed(2)}€
            </h5>
            <div style={{display: "inline-flex", justifyContent: "flex-end"}}>
                <h5 style={{fontWeight: "bold", margin: 0, color: "#de4791"}}>Émis le {date}</h5>
            </div>
            <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
            <div style={{backgroundColor: "#e2e2e2", padding: "0 10px"}}>
                <div style={{display: "flex", flexDirection: "column", padding: "20px 0", gap: 5}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0, fontWeight: "bolder"}}>Coût plateforme TEMEOO</h5>
                        <h5 style={{margin: 0, fontWeight: "bolder"}}>{plateforme_ttc.toFixed(2)}€</h5>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0, paddingLeft: 20, fontStyle: "italic"}}>Montant hors taxe</h5>
                        <h5 style={{margin: 0, fontStyle: "italic"}}>{plateforme_ht.toFixed(2)}€</h5>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0, paddingLeft: 20, fontStyle: "italic"}}>Dont TVA (20%)</h5>
                        <h5 style={{margin: 0, fontStyle: "italic"}}>{plateforme_tva.toFixed(2)}€</h5>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                        <h5 style={{margin: 0, fontWeight: "bolder"}}>Rétrocession ophtalmologiste</h5>
                        <h5 style={{margin: 0, fontWeight: "bolder"}}>{ophtalmo_ttc.toFixed(2)}€</h5>
                    </div>
                </div>
            </div>
            <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
        </div>
    }

    return <div style={{padding: 20, display: "flex", justifyContent: "center"}}>
        <div className="card" style={{minHeight: "297mm", width: "210mm"}}>
            <div className="pdf-document" style={{display: "flex", flexDirection: "column"}}>
                <InvoiceHeader title=""/>
                <div style={{display: "flex", margin: "40px 0"}}>
                    <h5 style={{margin: 0}}><b>Prélevé à :</b> {name()}</h5>
                </div>
                <div style={{margin: "100px 0 0 0"}}>
                    {body()}
                </div>
                <h6 style={{fontStyle: "italic"}} className="right-align">
                    Le détail des factures est disponible sur les documents associées.
                </h6>
                <div id="fixed-div" style={{position: "fixed", top: "180px", left: "800px", opacity: 0.3}}>
                    <InvoiceStamp status={invoice.status}/>
                </div>
            </div>
        </div>
    </div>
};

export default GeneralInvoice;