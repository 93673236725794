import React from 'react';
import {IS_OPTICIAN} from "../Helper";

const Logo = ({style, text}) => {

    const temeoo_uri = text ? "/imgs/TemeooColoredText.png" : "/imgs/TemeooColoredNoText.png"
    const uri = IS_OPTICIAN ? "/imgs/placeholder-logo.png" : temeoo_uri;
    const alt = IS_OPTICIAN ? "ExpertVision Logo" : "Temeoo Logo";

    return <img src={uri} style={{...style}} alt={alt}/>
}

Logo.defaultProps = {
    style: {},
    text: false
}

export default Logo;