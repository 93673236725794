import React from 'react';
import NavBar from "./NavBar";
import UserDropdown from "./UserDropdown";
import {wrapper} from "../../I18n/i18n";

export default function OpticianNavbar({image_path, user, topbar_prop = "", status}) {
    const {c: t} = wrapper("NAVBAR");

    const components = [{component: <UserDropdown user={user}/>, dataCy: "UserDropdown"},]

    const urls = [
        {url: "/", name: t("DASHBOARD")},
        {url: "/invoices", name: t("INVOICES")}
    ]

    return <NavBar image_path={image_path} urls={urls} topbar_prop={topbar_prop} components={components} status={status}/>
}
