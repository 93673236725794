import React from 'react';
import Helper, {JOB} from "../../../Shared/Helper";
import {Role} from "../../../Shared/Enums/Scope";

const InvoiceRetrocessionBody = ({invoice, orthoptist}) => {
    const debt = invoice.v2 ? (invoice.synthese.payed_debt) / 100.0 : 0

    const ophtalmologistes_earnings = (debt) => {
        const length = Object.entries(invoice.ophtalmologists_earnings).length
        if (length === 0) return <div/>


        const totalRetro = Object.entries(invoice.ophtalmologists_earnings).reduce((acc, [key, value]) => {
            return acc + parseFloat(value)
        }, debt || 0);

        return <tr style={{color: "#de4791"}}>
            <th>Total</th>
            <th><b>{totalRetro.toFixed(2)}€</b></th>
        </tr>
    }

    return <div style={{display: "flex", flexDirection: "column"}}>
        <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
            Etat des rétrocessions
            du {Helper.formatDate(invoice.from)} au {Helper.formatDate(invoice.to)}
        </h5>
        <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
        <div style={{backgroundColor: "#e2e2e2", padding: "0 10px"}}>
            <table>
                <thead>
                <tr className="black-text">
                    <th>Descriptif</th>
                    <th>Montant</th>
                </tr>
                </thead>
                <tbody>
                {_.isEmpty(invoice.ophtalmologists_earnings) ? (<tr>
                        <th>Rétrocessions pour le
                            Dr {invoice.first_appointment_analyst.name}</th>
                        <th>{(invoice.total_ophtalmologiste / 100.0).toFixed(2)}€</th>
                    </tr>) :
                    (
                        Object.entries(invoice.ophtalmologists_earnings).map(([key, value], index) => {
                            return <tr key={index}>
                                <th>Rétrocessions pour le Dr {key}</th>
                                <th><b>{parseFloat(value).toFixed(2)}€</b></th>
                            </tr>
                        })
                    )}
                {debt ? (<tr>
                        <th>Remboursement montant tampon</th>
                        <th>{debt.toFixed(2)}€</th>
                    </tr>
                ) : <div/>}
                {ophtalmologistes_earnings(debt)}
                </tbody>
            </table>
        </div>
        <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
        {debt ? (<h6 style={{fontStyle: "italic"}}>Reste à rembourser
            : {((orthoptist.creance || 0) / 100.0).toFixed(2)}€</h6>) : <div/>}
    </div>}

export default InvoiceRetrocessionBody;