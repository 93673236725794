import React from 'react';

const AlertResults = ({appointment}) => {

    if(!appointment.alert_level && !appointment.ophtalmologist_conclusion) return null;

    const renderConclusionCard = () => {
        if(!appointment.ophtalmologist_conclusion) return null;
        return <div className="card red lighten-1 white-text z-depth-0" style={{padding: 10, borderRadius: 5, marginBlock: 5}}>
            <div style={{fontWeight: 400}}><b>Conclusion ophtalmologiste</b><br/>{appointment.ophtalmologist_conclusion}</div>
        </div>
    }

    return <div className="fc" style={{gap: 0, fontSize: 12, fontWeight: 600, color: "red", whiteSpace: "pre-wrap"}}>
        {appointment.alert_level && <div><b>Niveau d'alerte : </b>{appointment.alert_level}</div>}
        {renderConclusionCard()}
    </div>
}

export default AlertResults;