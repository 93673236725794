import React, {useEffect} from 'react';
import CampaignDisplay from "../../Opticians/Campaigns/CampaignDisplay";

const AdsPanel = ({campaigns}) => {

    useEffect(() => {
        const modal = document.getElementById("detail-modal");
        const instance = M.Modal.init(modal, {});
        return () => instance?.destroy()
    }, [campaigns]);

    const renderDetailsModal = () => {
        return <>
            <a className="btn-small blue darken-2 btn-floating modal-trigger"
               data-target="detail-modal"
               style={{
                   position: "absolute",
                   top: -13,
                   right: -13
               }}>
                <i className="material-icons transparent white-text">question_mark</i>
            </a>
            <div id="detail-modal" className="modal">
                <div className="modal-content">
                    <h5 className="center bolder">
                        Avertissement aux utilisateurs : Partenariats et Indépendance Professionnelle<br/><br/>
                    </h5>
                    <h5>
                        Les partenaires présentés sur cette plateforme sont des entités distinctes et indépendantes de
                        l'orthoptiste ayant réalisé la consultation ainsi que de l'ophtalmologiste ayant analysé le
                        dossier
                        médical. Il est important de souligner que ni l'orthoptiste ni l'ophtalmologiste ne sont
                        affiliés à
                        ces partenaires et ne bénéficient d'aucun lien, ni promotionnel ni financier, avec les entités
                        citées.
                    </h5>
                    <h5>
                        Les informations fournies par les partenaires sont sous leur entière responsabilité et leur
                        utilisation relève de la seule appréciation des utilisateurs. Nous recommandons aux utilisateurs
                        de
                        consulter directement les professionnels de la santé pour toute question médicale ou pour des
                        recommandations spécifiques à leur situation.
                    </h5>
                    <h5>
                        Nous sommes déterminés à maintenir l'indépendance professionnelle et l'intégrité des services de
                        santé offerts sur cette plateforme, et nous nous engageons à fournir des informations
                        transparentes
                        et impartiales à nos utilisateurs.
                    </h5>
                    <h5>
                        Pour toute question ou clarification concernant nos partenariats ou notre approche en matière
                        d'indépendance professionnelle, n'hésitez pas à nous contacter.
                    </h5>
                </div>
                <div className="center" style={{padding: 10}}>
                    <a className="btn blue darken-2 z-depth-0 modal-close">J'ai compris</a>
                </div>
            </div>
        </>
    }

    if (!campaigns.length) return null
    return <div style={{
        display: "flex",
        flexDirection: "column",
        padding: 5,
        border: "1px solid grey",
        borderRadius: 5,
        position: "relative"
    }}>
        {renderDetailsModal()}
        <h2 className="center" style={{fontWeight: 600, padding: "10px 20px 0 20px", margin: 0}}>
            Les opticiens partenaires de confiance de TEMEOO<sup>®</sup>
        </h2>
        <div className="hide-on-med-and-down" style={{height: 20}}/>
        <div className="row">
            {campaigns.map((c, index) => {
                const lastAndOdd = index === campaigns.length - 1 && campaigns.length % 2 === 1;
                return <div className={`col s12 l6 ${lastAndOdd ? "offset-l3" : ""}`}>
                    <CampaignDisplay key={c.id} campaign={c}/>
                </div>
            })}
        </div>

    </div>
}

export default AdsPanel;