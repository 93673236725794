import Cookies from "universal-cookie";
import {Role} from "./Enums/Scope";
import {SCREEN_SIZE} from "./Enums/ScreenSize";
import moment from "moment";
import {Mesures, Side} from "./Enums/Enums";

class Helper {
    toCamelCase(str) {
        if(!str) return ""
        return str.toLowerCase().split(" ").map(s => s[0] ? s[0].toUpperCase() + s.substring(1) : "").join(" ")
    }

    formatName(first_name, last_name) {
        return `${this.toCamelCase(first_name || "")} ${(last_name || "").toUpperCase()}`.trim()
    }

    formatSsn(ssn) {
        if (!ssn) return ""
        ssn = ssn.replace(/\s+/g, '')
        if (ssn.length !== 15) return ""
        return `${ssn.substring(0, 13)} ${ssn.substring(13, 15)}`
    }

    formatPatientCodes(regime_code, fund_code, centre_code) {
        return `${regime_code || ""} ${fund_code || ""} ${centre_code || ""}`.trim()
    }

    formatDate(date, format = "DD/MM/YYYY") {
        if (!date) return undefined
        const m = moment(date)
        if (!m.isValid()) return undefined
        return m.format(format)
    }

    // regime : 2 digits, fund : 3 digits, centre : 4 digits
    extractCodesFromOrganismCode(code) {
        if(!code) return {}
        return {
            regime_code: code.substring(0, 2),
            fund_code: code.substring(2, 5),
            centre_code: code.substring(5, 9)
        }
    }

    dateToAge(date) {
        if(!date) return undefined
        return moment().diff(moment(date), "years")
    }

    checkSsn(ssn) {
        ssn = ssn.replace(' ', '')
        if (!ssn) return false
        if (ssn.length !== 15) return false

        const regex_ssn = new RegExp(/^[12][0-9]{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}([0-9]{2})$/g);
        const regex_ssn_provisional = new RegExp(/^[3478][0-9]{12}[0-9]{2}$/g);
        return ssn.match(regex_ssn) || ssn.match(regex_ssn_provisional)
    }

    checkSsnKey(ssn) {
        ssn = ssn.replace(' ', '').replace(/[A-Z]/g, '0')

        const nb = parseInt(ssn.substring(0, 13))
        if (isNaN(nb)) return false
        const key = parseInt(ssn.substring(13, 15))
        if (isNaN(key)) return false

        return (97 - (nb % 97)) === key
    }

    computeDate(date) {
        const now = new Date()
        date = new Date(date)
        if (now.toDateString() === date.toDateString()) {
            if (now.getHours() === date.getHours()) {
                if (now.getMinutes() - date.getMinutes() === 0) {
                    return "A l'instant"
                } else if (now.getMinutes() - date.getMinutes() < 60) {
                    return `Il y a ${now.getMinutes() - date.getMinutes()} minutes`
                }
            } else if (now.getHours() - date.getHours() <= 24) {
                return `Il y a ${now.getHours() - date.getHours()} heure${now.getHours() - date.getHours() > 1 ? "s" : ""}`
            }
        } else {
            const daysDiff = Math.ceil((now.getTime() - date.getTime()) / (3600 * 1000 * 24))
            if (daysDiff === 1) return "Hier"
            else if (daysDiff < 7) return `Il y a ${daysDiff} jours`
        }

        return `Le ${date.toLocaleDateString("fr-FR")}`
    }

    // Return -1 if date1 is after date2, 1 if date1 is before date2, 0 if they are the same
    compareDates(date1, date2) {
        if (!date1 && !date2) return 0
        if(!date1) return 1
        if(!date2) return -1
        date1 = new Date(date1)
        date2 = new Date(date2)
        if (date1.toDateString() === date2.toDateString()) return 0
        if (date1 > date2) return -1
        return 1
    }

    formatPhoneNumber(phone_number) {
        if (!phone_number || (phone_number.length !== 10 && phone_number[0] !== "+")) return phone_number
        if (phone_number[0] === "+") return phone_number.slice(0, 3) + " " + phone_number[3] + " " + phone_number.slice(4).toString().match(/.{1,2}/g).join(" ")
        else return phone_number.toString().match(/.{1,2}/g).join(" ")
    }

    capitalizeFirstLetter(string){
        if(string) return string[0].toUpperCase() + string.slice(1)
    }

    moveItemToGivenIndex(arr, old_index, new_index) {
        //Take 2 indexes and move the value of the first one to the second index
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    };

    formatDistance(distance){
        if(distance < 1000) return `${distance.toFixed(0)} m`
        return `${(distance / 1000).toFixed(1)} km`
    }
    // from Side.LEFT and Mesures.SPHERE, return "og_sphere"
    computeMeasureName = (side, measure_name) => {
        side = side === Side.LEFT ? "og" : "od"
        const compute_measure_name = (measure_name) => {
            switch(measure_name) {
                case Mesures.SPHERE:
                    return "sphere"
                case Mesures.CYLINDER:
                    return "cylindre"
                case Mesures.AXIS:
                    return "axe"
                case Mesures.ADDITION:
                    return "addition"
                case Mesures.ACUITY:
                    return "av_loin"
                case Mesures.PARINAUD:
                    return "parinaud"
            }
        }
        const measure = compute_measure_name(measure_name)
        return `${side}_${measure}`
    }

    // from seconds to xJ yH zM
    secondsToJhms(d, expanded = false) {
        d = Number(d);
        var j = Math.floor(d / (3600 * 24));
        var h = Math.floor(d % (3600 * 24) / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 60);
        if(expanded) return {days: j, hours: h, minutes: m, seconds: s}
        return `${j}J ${h}H ${m}M`
    }

}

let helper = new Helper
global.Helper = helper
export default helper

export const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const normalizeUppercaseString = (str) => {
    return normalizeString(str).toUpperCase()
}

export const compareNormalizedString = (str1, str2) => {
    return normalizeUppercaseString(str1) === normalizeUppercaseString(str2)
}

export function isMatch(media) {
    const query = `min-width: ${SCREEN_SIZE["by_query"][media]}`
    return window.matchMedia(query).matches
}

export function findClosestByMediaQuery(queries) {
    for (let i = queries.length - 1; i >= 0; i--) {
        if (isMatch(queries[i])) {
            return queries[i];
        }
    }
    return 'sm'
}

export function getScreenDimensions(){
    return {"width": window.innerWidth, height: window.innerHeight}
}

export function findClosestByWindow(){
    const width = getScreenDimensions().width
    let size = SCREEN_SIZE["by_inner_window"].reduce((accumulator, object) => {
        return Math.abs(width - object.pixels) < Math.abs(width - accumulator.pixels) ? object : accumulator
    })
    return size["size"]
}

function getAndCheckEnv(){
    var environment = document.getElementById("ENV")
    if(environment) return JSON.parse(environment.getAttribute("data-env"))
    return null
}

export const ENV = getAndCheckEnv()

export const getEnv = (name = null) => {
    if (name === null) return ENV
    else if (!ENV || typeof (ENV) !== "object") return null
    else return ENV[name]
}

export const COOKIES = new Cookies()
export const SCOPE = COOKIES.get("scope")
export const JOB = getEnv("BRAND") === "WHITE" ? Role.OPTICIAN : Role.ORTHOPTIST
export const IS_OPTICIAN = JOB === Role.OPTICIAN
export const CENTRE_OPHTALMO_URL = getEnv("BRAND") === "WHITE" ? "https://patients.expert-vision.eu" : "https://patients.temeoo.net"

const scope_list = SCOPE?.split("&") ?? []
export const IS_ORTHOPTIST = scope_list.includes(Role.ORTHOPTIST)
export const IS_OPHTALMOLOGIST = scope_list.includes(Role.OPHTALMOLOGIST)
export const IS_SECRETARY = scope_list.includes(Role.SECRETARY)
export const IS_LEVEL_2 = IS_OPHTALMOLOGIST || IS_SECRETARY