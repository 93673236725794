import React, {Component} from "react";
import GeneralStatistics from "./GeneralStatistics";
import NombreRdvs from "./NombreRdvs";
import {capitalize, t as _t} from "../../../I18n/i18n";
import GlobalNotification from "../../../Shared/Notifications/GlobalNotification";

const t = (key) => {
    return capitalize(_t(key))
};
export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0
        }
    }

    componentDidMount() {
        const elems = document.getElementById("opht-stats-tabs")
        let instance = M.Tabs.init(elems, {});
    }

    toggle(event, page) {
        event.preventDefault()
        this.setState({page: page})
    }

    renderBody() {
        switch (this.state.page) {
            case 0:
                return <GeneralStatistics/>
            case 1:
                return <NombreRdvs/>
            default:
                return null
        }
    }

    render() {
        return <div>
                <ul id="opht-stats-tabs " className="tabs tabs-fixed-width z-depth-1">
                    <li className="tab col s3">
                        <a className="black-text active" href="#" onClick={(event) => this.toggle(event, 0)}
                           style={{backgroundColor: `${this.state.page === 0 ? "rgba(179, 179, 179,0.4)" : "rgb(242, 242, 242)"}`}}>{t("GENERAL")}</a>
                    </li>
                    <li className="tab col s3">
                        <a className="black-text" href="#" onClick={(event) => this.toggle(event, 1)}
                           style={{backgroundColor: `${this.state.page === 1 ? "rgba(179, 179, 179,0.4)" : "rgb(242, 242, 242)"}`}}>{t("CHART")}</a>
                    </li>
                    <div className="indicator blue" style={{zIndex: "1"}}></div>
                </ul>
                {this.renderBody()}
            </div>

    }
}
