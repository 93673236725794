import React from 'react';
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import {t} from "../../I18n/i18n"

const AlertDetails = ({status, analyst_name, details}) => {
    const renderStatus = () => {
        if (status === 2) {
            return <span className="badge green darken-2 white-text">{t("VALIDATED")}</span>
        } else if (status === 3) {
            return <span className="badge red darken-2 white-text">{t("REFUSED")}</span>
        }
        return null
    }

    const renderAlertDetails = () => {
        if (status !== 3) return null
        return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            {details.todo_list?.length ? <BorderedDisplay text={t("ALERT_DETAIL_OPHTALMO.RECOMMENDED_CONSULTATION")}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    {details.todo_list?.map((todo) => {
                        return <span><i className="material-icons left">arrow_right</i>{todo}<br/></span>
                    })}
                </div>
            </BorderedDisplay> : null}
            {details.risks ? <BorderedDisplay text={t("ALERT_DETAIL_OPHTALMO.RISKS")}>
                <p>{details.risks}</p>
            </BorderedDisplay> : null}
            <BorderedDisplay text={t("CONCLUSION")}>
                <p>{details.conclusion ?? t("ALERT_DETAIL_OPHTALMO.NO_CONCLUSION_REACHED")}</p>
            </BorderedDisplay>
        </div>
    }

    return (
        <div style={{margin: 10, paddingInline: 10, display: "flex", flexDirection: "column", gap: 10}}>
            <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                <h2 className="center"
                    style={{flex: 1, marginBlock: 5}}>{t("ALERT_DETAIL_OPHTALMO.SUMMARY_OF_PROOFREADING")}</h2>
                {renderStatus()}
            </div>
            <h5>Par : {analyst_name}</h5>
            {renderAlertDetails()}
        </div>
    );
}

export default AlertDetails;