import React from 'react';

const EarningsByActMetric = ({pricing}) => {

    return <div style={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        width: 200,
        height: 80,
        marginTop: 10
    }}>
        <p style={{marginLeft: 20, marginBottom: 0}}><b>CA moyen/acte</b></p>
        <p style={{margin: "0px 20px", fontSize: 18}}>
            <b>{pricing.nb_bills === 0 ? 0 : (pricing.earnings / pricing.nb_bills).toFixed(2)} €</b>
        </p>
    </div>
}

export default EarningsByActMetric;