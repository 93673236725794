import React, {useEffect, useState} from "react";
import Helper from "../../../Shared/Helper";
import OphtalmologistPricing from "./OphtalmologistPricing";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import Switch from "../../../Shared/Switch";
import axios from "axios";
import r from "../../../Shared/Validation/Regex";
import {useBillingSettings} from "../../../Hooks/console/billing/useBillingSettings";


function OphtalmologistPricingConsole(props) {
    const [currentOphtalmologist, setCurrentOphtalmologist] = useState(props.ophtalmologists.filter(ophtalmologist => ophtalmologist.nom)[0]);
    const [ophtalmologists, setOphtalmologists] = useState(props.ophtalmologists);
    const [rpps, setRpps] = useState("");
    const [adeli, setAdeli] = useState("");
    const hn_categories = useBillingSettings("/admin/console/ophtalmologist/hn_settings").categories;
    const [searchTerm, setSearchTerm] = useState("")

    const categoryRef = React.createRef();

    useEffect(() => {
        setRpps(currentOphtalmologist.rps ?? "");
        setAdeli(currentOphtalmologist.adeli ?? "");
    }, [currentOphtalmologist])

    const toggleCVEnabled = () => {
        axios.patch(`/admin/parameters/ophtalmologists/${currentOphtalmologist.id}/cvEnabled/toggle`).then((resp) => {
            setOphtalmologists(ophtalmologists.map(ophtalmologist => {
                return ophtalmologist.id === currentOphtalmologist.id ? {
                    ...ophtalmologist,
                    cvEnabled: resp.data.cvEnabled
                } : ophtalmologist
            }))
        })
    }

    const updateCategory = (event) => {
        const id = event.target.value === "-1" ? null : event.target.value;
        axios.patch(`/admin/parameters/ophtalmologists/${currentOphtalmologist.id}/hn_category/${id}`).then((resp) => {
            categoryRef.current.value = id || -1;
            setOphtalmologists(ophtalmologists.map(ophtalmologist => {
                return ophtalmologist.id === currentOphtalmologist.id ? {
                    ...ophtalmologist,
                    hn_category_id: resp.data.id
                } : ophtalmologist
            }))
        })
    }

    useEffect(() => {
        document.getElementById(`cv_enabled_opht_${currentOphtalmologist.id}`).checked = currentOphtalmologist.cvEnabled;
        categoryRef.current.value = currentOphtalmologist.hn_category_id || -1;
    }, [currentOphtalmologist])

    const updateOphtalmologist = () => {
        axios.patch(`/admin/parameters/ophtalmologist/${currentOphtalmologist.id}`, {
            id: currentOphtalmologist.id,
            rps: rpps?.length > 0 ? rpps : null,
            adeli: adeli?.length > 0 ? adeli : null
        }).then((resp) => {
            document.getElementById(`category_id_opht_${currentOphtalmologist.id}`).value = id;
            setOphtalmologists(ophtalmologists.map(ophtalmologist => {
                return ophtalmologist.id === currentOphtalmologist.id ? Object.assign({}, ophtalmologist, resp.data.ophtalmologist) : ophtalmologist
            }))
        })

    }

    const updatable = () => {
        if (currentOphtalmologist.rps === rpps && currentOphtalmologist.adeli === adeli) return false;

        if (!r.validateRpps(rpps)) return false;
        if (!r.validateAdeli(adeli)) return false;

        return true;
    }

    const renderOphtalmologists = () => {
        const filteredOphtalmologists = ophtalmologists.filter((ophtalmologist) => {
            let search = searchTerm.toLowerCase()
            let ophtalmologist_name = `${ophtalmologist.nom} ${ophtalmologist.prenom}`.toLowerCase()
            return ophtalmologist_name.includes(search)
        })
        return <div>
            <input type="text"
                   placeholder="Chercher un ophtalmologiste"
                   value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
            <div className="collection" style={{
                maxWidth: "300px",
                backgroundColor: "#efefef",
                margin: 0,
                height: "100%",
                overflowY: "scroll"
            }}>
                {filteredOphtalmologists.sort((a, b) => a.nom > b.nom).map((ophtalmologist, index) => {
                    if (!ophtalmologist.nom) return null;
                    return <a
                        className={`collection-item black-text truncate ${currentOphtalmologist.id === ophtalmologist.id ? "pink lighten-3" : ""}`}
                        key={index} onClick={() => setCurrentOphtalmologist(ophtalmologist)}>
                        {Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom)}
                        <i className="material-icons left">{ophtalmologist.cvEnabled ? "credit_card" : "credit_card_off"}</i>
                    </a>
                })}
            </div>
        </div>

    }

    const renderOphtalmologistsMobile = () => {
        const filteredOphtalmologists = ophtalmologists.filter((ophtalmologist) => {
            let search = searchTerm.toLowerCase()
            let ophtalmologist_name = `${ophtalmologist.nom} ${ophtalmologist.prenom}`.toLowerCase()
            return ophtalmologist_name.includes(search)
        })

        return <div>
            <input type="text"
                   placeholder="Chercher un ophtalmologiste"
                   value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
            <div style={{margin: 20}}>
                <select value={currentOphtalmologist.id} className="browser-default"
                        onChange={(event) => setCurrentOphtalmologist(ophtalmologists.find((o) => o.id === parseInt(event.target.value)))}>
                    {filteredOphtalmologists.sort((a, b) => a.nom > b.nom).map((ophtalmologist, index) => {
                        if (!ophtalmologist.nom) return null;
                        return <option key={index} value={ophtalmologist.id}>
                            {Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom)}
                        </option>
                    })}
                </select>
            </div>
        </div>
    }

    return (
        <div style={{display: "flex", height: "500px"}}>
            <div className="hide-on-small-only">
                {renderOphtalmologists()}
            </div>
            <div className="row" style={{width: "100%"}}>
                <div className="hide-on-med-and-up col s12">
                    {renderOphtalmologistsMobile()}
                </div>
                <div className="col s12 m12 l12 xl6">
                    <div style={{margin: 20}}>
                        <BorderedDisplay text="Options">
                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <h5>Télétransmission active</h5>
                                    <Switch left="" right="" onChange={toggleCVEnabled}
                                            id={`cv_enabled_opht_${currentOphtalmologist.id}`}/>
                                </div>
                                <div className="divider"/>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <h5>Profil HN</h5>
                                    <select ref={categoryRef} className="browser-default"
                                            onChange={updateCategory} style={{width: "fit-content"}}>
                                        <option value={-1}>Aucun profil</option>
                                        {hn_categories?.map((category, index) => {
                                            return <option key={index} value={category.id}>{category.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="divider"/>
                                <div className="form-group">
                                    <label>N° RPPS (Répertoire Partagé des Professionels de Santé)</label>
                                    <input type="text"
                                           className={!rpps?.length ? "" : `form-control ${r.validateRpps(rpps.replace(/\s+/g, "")) ? "valid" : "invalid"}`}
                                           value={rpps} onChange={(e) => setRpps(e.target.value)} required/>
                                    {!rpps?.length || <span className="helper-text"
                                                            data-error={`Le RPPS est composé de 11 chiffres (ici : ${rpps.length})`}></span>}

                                </div>
                                <div className="form-group">
                                    <label>Adeli</label>
                                    <input type="text"
                                           className={!adeli?.length ? "" : `form-control ${r.validateAdeli(adeli.replace(/\s+/g, "")) ? "valid" : "invalid"}`}
                                           value={adeli} onChange={(e) => setAdeli(e.target.value)} required/>
                                    {!adeli?.length || <span className="helper-text"
                                                             data-error={`Doit être composé de 9 chiffres (ici : ${adeli.length})`}></span>}
                                </div>
                                <a className={`btn btn-small blue darken-2 z-depth-0 ${updatable() ? "" : "disabled"}`}
                                   onClick={updateOphtalmologist} style={{marginLeft: "auto"}}>
                                    Sauvegarder</a>
                            </div>
                        </BorderedDisplay>
                    </div>
                </div>
                <div className="col s12 m12 l12 xl6">
                    <OphtalmologistPricing ophtalmologist={currentOphtalmologist}/>
                </div>
            </div>
        </div>
    );
}

export default OphtalmologistPricingConsole;