import React from 'react';
import {observer} from "mobx-react";
import SmartTextArea from "../../../Shared/UiKit/SmartTextArea";
import MesureStore from "../MesureStore";
import {t} from "../../../I18n/i18n";
import RawSCORButton from "../mesure_components/SideBar/Teletransmission/RawSCORButton";

const Reeducation = observer(() => {
    const history = MesureStore.last_appointements

    const renderReportButton = () => {
        if (!MesureStore.selectedChain?.parent_has_report) return null
        const parent_appointment_id = MesureStore.selectedChain && MesureStore.selectedChain.parent_appointment_id
        return <div className="center">
            <a className={`btn blue darken-2 z-depth-0 ${parent_appointment_id ? "" : "disabled"} `}
               href={`/documents/orthoptic?appointment_id=${parent_appointment_id}`}
               target="_blank" rel="noopener noreferrer"
            >{t("Visualiser rapport du bilan")}</a>
        </div>
    }

    const renderPrescriptionButton = () => {
        if (!MesureStore.selectedChain?.parent_prescription_url) return null
        // Download prescription located at url through button
        return <div className="center valign-wrapper">
            <a className={`btn blue darken-2 z-depth-0 ${MesureStore.selectedChain?.parent_prescription_url ? "" : "disabled"} `}
                download="prescription.pdf" href={MesureStore.selectedChain?.parent_prescription_url}
            >{t("Télécharger prescription")}</a>
            <RawSCORButton scorUrl={MesureStore.selectedChain?.parent_prescription_url}/>
        </div>
    }

    const renderAppointmentHistory = (appt) => {
        if (!appt.anamnesis && !appt.orthoptic_text) return null
        return <div className="card z-depth-0"
                    style={{whiteSpace: "pre-wrap", margin: "10px 30px", backgroundColor: "#e3f2fd"}}>
            <h6><i>Le {Helper.formatDate(appt.start)}</i></h6>
            {appt.anamnesis ?
                <div>
                    <h6><b>{t("ANAMNESIS")}</b></h6>
                    <h6>{appt.anamnesis}</h6>
                </div>
                : null
            }
            {appt.orthoptic_text ?
                <div>
                    <h6><b>{t("ORTHOPTIC")}</b></h6>
                    <h6>{appt.orthoptic_text}</h6>
                </div>
                : null
            }
        </div>
    }


    return <div style={{padding: 20, overflowY: "scroll"}}>
        <h3 className="center">{t("APPOINTMENT_ROUTES.REEDUCATION_TITLE")}</h3>
        <div className="row" style={{gap: 20}}>
            <div className="col s12 m6">
                <SmartTextArea
                    value={MesureStore.anamnesis}
                    onChange={e => MesureStore.setAnamnesis(e.target.value)}
                    placeholder={t("ANAMNESIS")} shortcuts={MesureStore.textual_shortcuts}
                    style={{height: 300}}
                />
            </div>
            <div className="col s12 m6">
                <SmartTextArea
                    value={MesureStore.orthoptic_text}
                    onChange={e => MesureStore.setOrthotpicText(e.target.value)}
                    placeholder={t("ORTHOPTIC")} shortcuts={MesureStore.textual_shortcuts}
                    style={{height: 300}}
                />
            </div>
        </div>

        <div className="valign-wrapper" style={{justifyContent: "center", alignItems: "center", gap: 20}}>
            <h3 className="center">{t("HISTORY")}</h3>
            {renderReportButton()}
            {renderPrescriptionButton()}
        </div>
        {
            history.map((appt, i) => renderAppointmentHistory(appt))
        }

    </div>
});

export default Reeducation;