import {TypesMesures} from "../../../Shared/Enums/Enums";
import {SX, EB, SH, ET, NL} from "../../Enums";

const LF = "\r\n"; // line feed : new line
export default class REXXAM_DR900_FSM {
    constructor(measures, serialWriter) {
        this.state = "START";
        this.serialWriter = serialWriter;
        this.measures = measures;
    }

    next(message = "") {
        switch (this.state) {
            case "START": {
                console.debug("START REXXAM_DR900");
                const data = REXXAM_DR900(this.measures);
                console.debug("sending with measures: ", this.measures);
                console.debug("sending with data: ", data)
                this.serialWriter({data: data, waitForResponse: 0});
                console.debug("END REXXAM_DR900");
                this.state = "END";

                return true;// if no CTRL message keep looping on next messages
            }
            case "END": {
                return false;
            }
            default:
                return false
        }

    }
}

const checksum = (payload) => {
    // sum the bytes of the payload
    let sum = payload.split('').reduce((acc, c) => acc + c.charCodeAt(0), 0);
    sum %= 65536
    // Convert to a 4-character ASCII hexadecimal string (padded) hightest 4 bits
    return sum.toString(16).padStart(4, '0')
}

export function REXXAM_DR900(measures) {
    const lm = PRE_REXXAM_DR900_LM(measures)
    const rm = PRE_REXXAM_DR900_RM(measures)
    const payload = `*${lm}${rm}@`
    console.debug("PAYLOAD : ", payload)

    const check = checksum(payload)
    const full_payload = `${payload}${check}${LF}`
    console.debug("CHECKSUM : \n", check)

    return full_payload
}

export function print(number, hasPolarity, afterDot, totalSpace) {
    // ex -2 , true, 2,5 => -  2.00
    let result = "";
    let str = (Math.abs(number)).toFixed(afterDot);
    result += hasPolarity ? (number > 0 ? "+" : number === 0 ? " " : "-") : "";
    result += " ".repeat(totalSpace - (((hasPolarity ? 1 : 0) + str.toString().length)));
    result += str;
    return result;

}

export function PRE_REXXAM_DR900_RM(measures) {
    // RM = autorefactometer
    //loop on measures
    for (let i = 0; i < measures.length; i++) {
        let mesure = measures[i];
        if (mesure.titre === TypesMesures.AUTOREF) {
            const pre_ar = `#RM${LF}`
            const right_ar = `# R ${print(mesure.od_sphere, true, 2, 6)} ${print(mesure.od_cylindre, true, 2, 6)} ${print(mesure.od_axe, false, 0, 3)}${LF}`
            const left_ar = `# L ${print(mesure.og_sphere, true, 2, 6)} ${print(mesure.og_cylindre, true, 2, 6)} ${print(mesure.og_axe, false, 0, 3)}${LF}`
            const prism_right = `#pB 00.0${LF}`
            const prism_left = `#pb 00.0${LF}`
            const ar_payload = `${LF}${pre_ar}${right_ar}${left_ar}${prism_right}${prism_left}$${LF}`
            console.debug("AUTOREF: ", ar_payload)
            return ar_payload
        }
    }
    return "";
}

export function PRE_REXXAM_DR900_LM(measures) {
    // LMM = LM
    //loop on measures
    for (let i = 0; i < measures.length; i++) {
        let mesure = measures[i];
        if (mesure.titre === TypesMesures.LENSE_METER) {
            const pre_lm = `#LM${LF}`
            const right_lm = `# R ${print(mesure.od_sphere, true, 2, 6)} ${print(mesure.od_cylindre, true, 2, 6)} ${print(mesure.od_axe, false, 0, 3)}${LF}`
            const left_lm = `# L ${print(mesure.og_sphere, true, 2, 6)} ${print(mesure.og_cylindre, true, 2, 6)} ${print(mesure.og_axe, false, 0, 3)}${LF}`
            const addition_right = `#AR ${print(mesure.od_addition, true, 2, 6)}${LF}`
            const addition_left = `#AL ${print(mesure.og_addition, true, 2, 6)}${LF}`
            const prism_right = `#PR O 0.00 D 0.00${LF}`
            const prism_left = `#PL O 0.00 D 0.00${LF}`
            const prism_binocular = `#pB 00.0${LF}`
            const lm_payload = `${LF}${pre_lm}${right_lm}${left_lm}${addition_right}${addition_left}${prism_right}${prism_left}${prism_binocular}$${LF}`
            console.debug("LM: ", lm_payload)
            return lm_payload
        }
    }
    return "";
}