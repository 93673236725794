import React from 'react';
import OpticianInvoiceLine from "./OpticianInvoiceLine";

const OpticianInvoices = ({invoices}) => {

    if (!invoices || invoices.length === 0) {
        return <div className="container"
                    style={{padding: 50, display: "flex", flexDirection: "column", alignItems: "center", gap: 10}}>
            <h3 style={{margin: 0}}>Aucune facture n'est disponible pour le moment</h3>
            <label className="center">Votre première facture sera générée le premier jour du prochain mois</label>
        </div>
    }

    return <div className="container" style={{padding: 20}}>
        <div className="collection" style={{borderRadius: 5}}>
            {invoices.map(invoice => {
                return <div className="collection-item transparent" key={`invoice_${invoice.id}`}>
                    <OpticianInvoiceLine invoice={invoice}/>
                </div>
            })}
        </div>
    </div>
}

export default OpticianInvoices;