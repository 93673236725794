import {Chart, registerables} from "chart.js";
import React from "react";
import {Bar} from "react-chartjs-2";

Chart.register(...registerables);

const _ = require('lodash');
const ExamFormulaRepartition = ({data, title}) => {

    const options = {
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    autoSkip: false
                }
            }
        },
        maintainAspectRatio: false
    }

    if (_.isEmpty(data)) return null

    if (!data.datasets[0].data.length) return <div
        style={{
            padding: 10,
            marginTop: 25,
            marginBottom: 25
        }}
        className="center">
        <h3 style={{margin: 0, padding: 0}}>{title}</h3>
        Aucune donnée pour cette formule.
    </div>
    return <div key={`category_${title}`}>
        <h3 style={{margin: 0, padding: 0}} className="center">{title}</h3>
        <div><Bar height={200 + (data.datasets[0].data.length * 12)} data={data} options={options}></Bar></div>
    </div>

}

export default ExamFormulaRepartition;