import React, {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {t} from "../../../I18n/i18n";
import CoordinatePickerModal from "../../../Shared/Map/CoordinatePickerModal";
import HelpIcon from "../../../Shared/Help/HelpIcon";
import Switch from "../../../Shared/Switch";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";

export default function OfficePanel({office = {}, url_prefix}) {
    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    const [coordinates, setCoordinates] = useState(office.coordinates)
    const [officeOptions, setOfficeOptions] = useState(office)

    const save = (e) => {
        e.preventDefault()
        axios.post(`${url_prefix}/administration/update_office`, {...officeOptions, coordinates: coordinates})
            .then((response) => {
                toast.success(t("OPTIONS.OFFICE.SUCCESS"))
            }).catch((error) => {
            toast.error(t("OPTIONS.OFFICE.ERROR"))
        })
    }

    const renderSwitch = () => {
        return <>
            <BorderedDisplay text="Informations accès">
                <div className="row">
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>Accès PMR</h5>
                        <Switch id={`switch_PMR`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, has_pmr_access: val})}
                                value={officeOptions.has_pmr_access}/>
                    </div>
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>Parking</h5>
                        <Switch id={`switch_parking`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, has_parking: val})}
                                value={officeOptions.has_parking}/>
                    </div>
                </div>
            </BorderedDisplay>
            <BorderedDisplay text="Recherche">
                <div className="row">
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>Stagiaire</h5>
                        <Switch id={`switch_trainee`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, looking_for_trainee: val})}
                                value={officeOptions.looking_for_trainee}/>
                    </div>
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>Collaborateur</h5>
                        <Switch id={`switch_collab`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, looking_for_collaborator: val})}
                                value={officeOptions.looking_for_collaborator}/>
                    </div>
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>Remplaçant</h5>
                        <Switch id={`switch_remplacement`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, looking_for_replacement: val})}
                                value={officeOptions.looking_for_replacement}/>
                    </div>
                </div>
            </BorderedDisplay>
            <BorderedDisplay text="Examens réalisés">
                <label>Les informations de cette catégories ne changent que la page cabinet sur temeoo.com</label>
                <div className="row">
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>RNM</h5>
                        <Switch id={`switch_rnm`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, perform_rnm: val})}
                                value={officeOptions.perform_rnm}/>
                    </div>
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>Rétinopathie diabétique</h5>
                        <Switch id={`switch_rpd`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, perform_rpd: val})}
                                value={officeOptions.perform_rpd}/>
                    </div>
                    <div className="col s12 valign-wrapper" style={{justifyContent: "space-between"}}>
                        <h5>Patients de plus de 50 ans</h5>
                        <Switch id={`switch_gt_50`} left="Non" right="Oui"
                                onChange={(val) => setOfficeOptions({...officeOptions, perform_gt_50: val})}
                                value={officeOptions.perform_gt_50}/>
                    </div>
                </div>
            </BorderedDisplay>
        </>
    }

    const renderForm = () => {
        return <form style={{display: "flex", flexDirection: "column", gap: 20}} onSubmit={save}>
            <div className="row" style={{gap: 20}}>
                <div className="input-field outlined col s12 m6">
                    <input id="office_name" type="text" className="validate" value={officeOptions.name}
                           onChange={e => setOfficeOptions({...officeOptions, name: e.target.value})}/>
                    <label htmlFor="office_name">{t("NAME")}</label>
                </div>
                <div className="input-field outlined col s12 m6">
                    <input id="telephone" type="text" className="validate" value={officeOptions.telephone}
                           onChange={e => setOfficeOptions({...officeOptions, telephone: e.target.value})}/>
                    <label htmlFor="telephone">{t("PHONE_NUMBER")}</label>
                </div>
                <div className="input-field outlined col s12 m6">
                    <input id="office_address" type="text" className="validate" value={officeOptions.address}
                           onChange={e => setOfficeOptions({...officeOptions, address: e.target.value})}/>
                    <label htmlFor="office_address">{t("ADDRESS")}</label>
                </div>
                <div className="input-field outlined col s12 m6">
                    <input id="office_city" type="text" className="validate" value={officeOptions.city}
                           onChange={e => setOfficeOptions({...officeOptions, city: e.target.value})}/>
                    <label htmlFor="office_city">{t("CITY")}</label>
                </div>
                <div className="input-field outlined col s12 m6">
                    <input id="office_postal_code" type="text" className="validate" value={officeOptions.postal_code}
                           onChange={e => setOfficeOptions({...officeOptions, postal_code: e.target.value})}/>
                    <label htmlFor="office_postal_code">{t("POSTAL_CODE")}</label>
                </div>
                <div className="input-field outlined col s12 m6">
                    <input id="office_director_name" type="text" className="validate"
                           value={officeOptions.director_fullname}
                           onChange={e => setOfficeOptions({...officeOptions, director_fullname: e.target.value})}/>
                    <label htmlFor="office_director_name">{t("OPTIONS.OFFICE.DIRECTOR_NAME")}</label>
                </div>
                <div className="input-field outlined col s12 m6">
                    <input id="office_google_rating_url" type="text" className="validate"
                           value={officeOptions.google_rating_url}
                           onChange={e => setOfficeOptions({...officeOptions, google_rating_url: e.target.value})}/>
                    <label htmlFor="office_google_rating_url">{t("OPTIONS.OFFICE.BUSINESS_LINK")}</label>
                </div>
                <div className="input-field outlined col s12 m6"/>

                <div className="input-field outlined col s12 m6">
                    <input id="office_google_rating_url" type="text" className="validate"
                           value={officeOptions.doctolib_url}
                           onChange={e => setOfficeOptions({...officeOptions, doctolib_url: e.target.value})}/>
                    <label htmlFor="office_google_rating_url">{t("OPTIONS.OFFICE.DOCTOLIB_URL")}</label>
                </div>
                <div className="input-field outlined col s12 m6">
                    <input id="office_google_rating_url" type="text" className="validate"
                           value={officeOptions.appointment_making_url}
                           onChange={e => setOfficeOptions({
                               ...officeOptions,
                               appointment_making_url: e.target.value
                           })}/>
                    <label htmlFor="office_google_rating_url">{t("OPTIONS.OFFICE.APPOINTMENT_MAKING_URL")}</label>
                </div>
            </div>
            <div className="valign-wrapper">
                <h5 style={{margin: 0}}>{t("OPTIONS.OFFICE.COORDINATES")}</h5>
                <CoordinatePickerModal defaultCoordinates={coordinates} onValidation={setCoordinates}/>
            </div>
            {renderSwitch()}
            <div><button className="btn blue darken-2 z-depth-0" type="submit" name="action">{t("SAVE")}</button></div>
        </form>
    }

    return <div className="container" style={{width: "100%", paddingBlock: 20}}>
        <div className="center valign-wrapper" style={{display: 'flex', justifyContent: 'center', gap: 20}}>
            <h1 style={{marginBlock: 20}} className="center">{t("OPTIONS.OFFICE.TITLE")}</h1>
            <HelpIcon url="https://www.temeoo.fr/orthoptists/documentation/configuration"/>
        </div>
        {renderForm()}
    </div>
}