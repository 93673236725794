import React, {useEffect, useState} from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import {t} from "../../../I18n/i18n";
import {toast} from "react-toastify";

const ImportFromPerfactiveButton = (props) => {
    const modal_id = "import_perfactiv_modal";

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const instance = M.Modal.init(modal);
        return () => instance.destroy();
    }, []);

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
            toast.error(t("PATIENT_SEARCH.ONLY_ONE_FILE"));
            return;
        }
        const file = acceptedFiles[0];
        if (!file.type.includes("vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            toast.error(t("PATIENT_SEARCH.PLEASE_SELECT_XLSX"));
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        axios.put("/agenda/perfactive", formData).then((response) => {
            props.onImportSuccess();
            const modal = document.getElementById(modal_id);
            const instance = M.Modal.getInstance(modal);
            if (instance) instance.close();
            toast.success(t("PATIENT_SEARCH.UPLOAD_SUCCESS"))
        }).catch((_) => {
        });
    };

    const renderPicker = () => {
        return (
            <div className="clickable" style={{minHeight: 200}}>
                <Dropzone onDrop={onDrop} accept=".csv">
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <div
                            {...getRootProps()}
                            style={{
                                border: '1px solid lightgrey',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80%",
                                minHeight: 200,
                                borderRadius: 5,
                                backgroundColor: isDragActive ? "lightgrey" : "white",
                                overflow: "hidden"
                            }}
                        >
                            <input {...getInputProps()} />
                            <div>{t("PATIENT_SEARCH.DROP_OR_CLICK")}</div>
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    };

    const renderModal = () => {
        return <div id={modal_id} className="modal" style={{
            position: 'fixed',
            width: "40%",
            background: "white",
            border: "white",
            padding: "20px",
            marginTop: 80,
            borderRadius: "10px",
            outline: "none"
        }}>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <h4 className="center" style={{margin: 0}}>{t("PATIENT_SEARCH.DROP_FILE_HERE")}</h4>
                <label style={{alignSelf: "center"}}>{t("PATIENT_SEARCH.FILE_DESCRIPTION")}</label>
                <div style={{textAlign: "center"}}>
                    {renderPicker()}
                </div>
            </div>

        </div>
    };

    return (
        <>
            <a className="btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
               data-cy="AddPatientWIthCSV">
                {t("PATIENT_SEARCH.IMPORT_PERFACTIVE")}<i className="material-icons left">download</i>
            </a>
            {renderModal()}
        </>
    );
};

export default ImportFromPerfactiveButton;