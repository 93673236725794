import React, {useEffect, useState} from "react";
import {InvoiceStatus} from "../../../Shared/Enums/Enums";
import Tooltip from "../../../Shared/Tootip";
import axios from "axios";
import {wrapper} from "../../../I18n/i18n";
import useDebounceV2 from "../../../Hooks/useDebounceV2";
import Pagination from "../../../Shared/Pagination";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";

const _ = require('lodash')

const INVOICE_PER_PAGE = 5

function InvoiceList() {
    const {t} = wrapper("ORTHOPTIST_BILLING.BILLING_OVERVIEW")
    const [invoices, setInvoices] = useState([])
    const [invoiceCount, setInvoiceCount] = useState(0)
    const [page, setPage] = useState(1)
    const debouncedSetPage = useDebounceV2(setPage, 300)
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    useEffect(() => {
        loadInvoices()
    }, [page]);

    const loadInvoices = () => {
        setRequestState(RequestState.LOADING)
        axios.get("/orthoptistes/paiements/invoices", {
            params: {
                page: page,
                nb_results: INVOICE_PER_PAGE
            }
        }).then((resp) => {
            setInvoices(resp.data.invoices)
            setInvoiceCount(resp.data.count)
            setRequestState(RequestState.SUCCESS)
        })
    }

    const renderStatus = (invoice) => {
        switch (invoice.status) {
            case InvoiceStatus.WAITING:
                return <Tooltip id={`tooltip-invoice-waiting-${invoice.id}`} text={t("TOOLTIP.WAITING")}>
                    <i className="material-icons left grey-text">schedule</i>
                </Tooltip>
            case InvoiceStatus.PENDING:
                return <Tooltip id={`tooltip-invoice-pending-${invoice.id}`} text={t("TOOLTIP.PENDING")}>
                    <i className="material-icons orange-text">pending</i>
                </Tooltip>
            case InvoiceStatus.SUCCESS:
                return <Tooltip id={`tooltip-invoice-success-${invoice.id}`} text={t("TOOLTIP.SUCCESS")}>
                    <i className="material-icons green-text">done</i>
                </Tooltip>
            case InvoiceStatus.FAILED:
                return <Tooltip id={`tooltip-invoice-error-${invoice.id}`} text={t("TOOLTIP.FAILED")}>
                    <i className="material-icons red-text">error</i>
                </Tooltip>
            default:
                return null
        }
    }

    const renderLine = (invoice, index) => {
        const ophtalmo_earnings = Object.entries(invoice.ophtalmologists_earnings ?? {})
        let total_ophtalmo = !_.isEmpty(ophtalmo_earnings) ? ophtalmo_earnings.reduce((acc, [key, value]) => acc + parseFloat(value), 0) : invoice.total_ophtalmologiste / 100.0
        total_ophtalmo = invoice.v2 ? total_ophtalmo : 0
        const debt = invoice.v2 && !_.isEmpty(ophtalmo_earnings) ? (invoice.synthese.payed_debt) || 0 : 0
        const total = invoice.totalPlateforme + debt

        return <div style={{display: "flex", gap: 10}}>
            <div style={{flex: 1}}>
                <div className="row valign-wrapper" style={{margin: 0}}>
                    <div className="col s1">{renderStatus(invoice)}</div>
                    <div className="col s3">
                        {`${((total) / 100 + total_ophtalmo).toFixed(2)}€`}
                    </div>
                    <div className="col s8">
                        {t("BILLED_THE", {date: new Date(invoice.created_at).toLocaleDateString()})}
                    </div>
                </div>
            </div>
            <div>
                <a className="btn-small blue darken-2 z-depth-0" target="_blank"
                   href={`/orthoptistes/paiements/invoice/documents/${invoice.id}`}>
                    <i className="material-icons left">receipt_long</i>
                    {t("GO_TO_INVOICES")}
                </a>
            </div>
        </div>
    }

    const renderInvoices = () => {
        if (requestState === RequestState.LOADING) return <BorderedDisplay containerStyle={{borderRadius: 20}}>
            <div className="fc" style={{justifyContent: "center", height: 290}}>
                <Loader wide/>
            </div>
        </BorderedDisplay>
        return <ul className="collection" style={{borderRadius: "20px"}}>
            <div style={{paddingTop: "10px"}}/>
            {invoices.map((invoice, index) =>
                <li key={index} className="collection-item">{renderLine(invoice, index)}</li>
            )}
        </ul>
    }


    if (_.isEmpty(invoices) && requestState === RequestState.SUCCESS) return <h2 className="center">{t("NO_BILL_AVAILABLE")}</h2>
    return (
        <div>
            <div className="fr" style={{justifyContent: "space-between"}}>
                <h2 id="main-title" style={{margin: 10}}>Mes factures</h2>
                <Pagination page={page} onChange={debouncedSetPage} count={invoiceCount} limit={INVOICE_PER_PAGE}/>
            </div>
            {renderInvoices()}
        </div>
    )

}

export default InvoiceList