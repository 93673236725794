// FSM for NIDEK_RT_5100
import {TypesMesures} from "../../../Shared/Enums/Enums";
import {SX, EB, SH, ET} from "../../Enums";

export default class NIDEK_RT_5100_FSM {
    constructor(measures, serialWriter) {
        this.state = "START";
        this.serialWriter = serialWriter;
        this.measures = measures;
    }

    next(message = "") {
        switch (this.state) {
            case "START": {
                console.log("sending PRE_NIDEK_RT_5100")
                this.serialWriter({data: PRE_NIDEK_RT_5100(), waitForResponse: 2000});
                this.state = "WAIT_CTRL";
                return true;
            }
            case "WAIT_CTRL": {
                if (message.includes("CRL")) {
                    console.log("sending NIDEK_RT_5100")
                    this.serialWriter({data: NIDEK_RT_5100(this.measures), waitForResponse: 0});
                    this.state = "END";
                }
                return true;// if no CTRL message keep looping on next messages
            }
            case "END": {
                return false;
            }
            default:
                return false
        }

    }
}

export function NIDEK_RT_5100(measures) {
    // I expect measures to be an array of Measure objects
    //return `\x01DRM\x02OR- 0.50- 0.50165\x0FOL- 0.50- 0.50165\x0FDLM\x02 R- 0.75- 0.20155\x0F L- 0.75- 0.20155\x0F\x04`
    console.log(`${SH}${NIDEK_RT_5100_LM(measures)}${NIDEK_RT_5100_ADD_BLOCK(measures)}${ET}`)
    return (
        `${SH}${NIDEK_RT_5100_RM(measures)}${NIDEK_RT_5100_LM(measures)}${NIDEK_RT_5100_ADD_BLOCK(measures)}${ET}`);
}

export function PRE_NIDEK_RT_5100() {
    //"\001C**\002RS\017\004"
    //return "\x01C**\x02RS\x0f\x04";
    return `${SH}C**${SX}RS${EB}${ET}`;

}

export function print(number, hasPolarity, afterDot, totalSpace) {
    // ex -2 , true, 2,5 => -  2.00
    let result = "";
    let str = (Math.abs(number)).toFixed(afterDot);
    result += hasPolarity ? (number > 0 ? "+" : number === 0 ? " " : "-") : "";
    result += " ".repeat(totalSpace - (((hasPolarity ? 1 : 0) + str.toString().length)));
    result += str;
    return result;

}

export function NIDEK_RT_5100_RM(measures) {
    // RM = autorefactometer
    //loop on measures
    for (let i = 0; i < measures.length; i++) {
        let mesure = measures[i];
        if (mesure.titre === TypesMesures.AUTOREF) {
            return (
                `DRM${SX}OR${print(mesure.od_sphere, true, 2, 6)}${print(mesure.od_cylindre, true, 2, 6)}${print(mesure.od_axe, false, 0, 3)}${EB}OL${print(mesure.og_sphere, true, 2, 6)}${print(mesure.og_cylindre, true, 2, 6)}${print(mesure.og_axe, false, 0, 3)}${EB}`);
        }
    }
    return "";
}

export function NIDEK_RT_5100_LM(measures) {
    // LMM = LM
    //loop on measures
    for (let i = 0; i < measures.length; i++) {
        let mesure = measures[i];
        if (mesure.titre === TypesMesures.LENSE_METER) {
            return (
                `DLM${SX} R${print(mesure.od_sphere, true, 2, 6)}${print(mesure.od_cylindre, true, 2, 6)}${print(mesure.od_axe, false, 0, 3)}${EB} L${print(mesure.og_sphere, true, 2, 6)}${print(mesure.og_cylindre, true, 2, 6)}${print(mesure.og_axe, false, 0, 3)}${EB}`);
        }
    }
    return "";
}

export function NIDEK_RT_5100_ADD_BLOCK(measures) {
    // ADD = add
    //loop on measures
    for (let i = 0; i < measures.length; i++) {
        let mesure = measures[i];
        if (mesure.titre === TypesMesures.LENSE_METER) {
            return (
                `AR${print(mesure.od_addition, true, 2, 6)}${EB}AL${print(mesure.og_addition, true, 2, 6)}${EB}`)
        }
    }
    return "";
}
