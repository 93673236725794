import React, {useEffect} from "react";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import axios from "axios";
import {toast} from "react-toastify";

const MonthRevenue = ({}) => {

    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [revenues, setRevenues] = React.useState({});

    useEffect(() => {
        getMonthRevenues()
    }, []);

    const getMonthRevenues = () => {
        axios.get("/admin/statistiques/api/orthoptist/monthEarning")
            .then((response) => {
                setRevenues(response.data)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((err) => {
                toast.error("Une erreur est survenue.")
                setRequestState(RequestState.ERROR)
            })
    }

    const renderMonthRevenues = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <>
            <h3 style={{margin: "0px 20px", paddingBlock: 10}}>
                <b style={{fontSize: 25}}> {revenues.general?.toFixed(2) ?? 0 ?? 0} €</b>
            </h3>
            <h5 style={{marginInline: 20, paddingBottom: 5}}><b>{revenues.telemedicine?.toFixed(2) ?? 0}€</b> en télémédecine</h5>
        </>
    }

    return <div style={{border: "1px solid lightgrey", borderRadius: "10px", height: "100%"}}>
        <h2 style={{marginLeft: 20, marginBlock: 10}}>CA du mois</h2>
        {renderMonthRevenues()}
    </div>
}

export default MonthRevenue;