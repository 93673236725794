import React from "react";

const DocumentPrescription = ({user_type, islocked, prescription}) => {
    const text_to_remove = "\n\nLa prescription ne peut pas être modifiée sauf avis médical."
    if (user_type === "patient") return islocked ? (
        <div style={{whiteSpace: "pre-wrap"}}>
            <h5 style={{marginBlock: 0}}><b>{prescription.text ? "La correction suivante a été prescrite :" : ""}</b></h5>
            <h5 style={{marginBottom: 20, marginTop: 5}}>{prescription.text?.replace(text_to_remove, "")}</h5>
            <h5 style={{marginBlock: 0}}><b>{prescription.text_prescription_secondaire ? "Prescription Secondaire :" : ""}</b></h5>
            <h5 style={{marginBottom: 20, marginTop: 5}}>{prescription.text_prescription_secondaire ? prescription.text_prescription_secondaire?.replace(text_to_remove, "") : ""}</h5>
        </div>
    ) : null

    return <div style={{whiteSpace: "pre-wrap"}}>
        <h5 style={{marginBlock: 0}}><b>{prescription.text ? "La correction suivante a été prescrite :" : ""}</b></h5>
        <h5 style={{marginBottom: 20, marginTop: 5}}>{prescription.text.replace(text_to_remove, "")}</h5>
        <h5 style={{marginBlock: 0}}><b>{prescription.text_prescription_secondaire ? "Prescription Secondaire :" : ""}</b></h5>
        <h5 style={{marginBottom: 20, marginTop: 5}}>{prescription.text_prescription_secondaire ? prescription.text_prescription_secondaire?.replace(text_to_remove, "") : ""}</h5>
    </div>
}
export default DocumentPrescription;