import React from 'react';

const ActiveUsersMetric = ({pricing}) => {

    return <div style={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        width: 200,
        height: 80,
        marginTop: 10
    }}>
        <p style={{marginLeft: 20, marginBottom: 0}}><b>Utilisateurs actifs</b></p>
        <div style={{margin: "0px 20px" ,fontSize: 18}}><b>{pricing.nb_active}</b></div>
    </div>
}

export default ActiveUsersMetric;