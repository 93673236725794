import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {i18n, t} from "../../I18n/i18n";
import moment from "moment";
import DisplayMeasures from "../../Orthoptistes/Appointment/Check/DisplayMeasures";
import {format_measures} from "../../Appointments/Mesures/mesure_components/mesures";
import PlaceholderSignature from "../../Shared/PlaceholderSignature";

export default function ScreeningReport(props) {
    const [appointment, setAppointment] = useState({
        status_controle: 0
    })
    const [mesures, setMesures] = useState([])
    const [comment, setComment] = useState({})
    const [ophtalmologist, setOphtalmologist] = useState("AN ERROR OCCURED")
    const [patient, setPatient] = useState({})
    const [typedemesures, setTypedemesures] = useState([])
    const [images, setImages] = useState([])
    const [pdf_reports, setPdfReports] = useState([])
    const [signature, setSignature] = useState("")
    const [risques, setRisques] = useState([])
    const [alert, setAlert] = useState({})

    const formatMeasures = format_measures(mesures, typedemesures)
    const cleanedMeasures = formatMeasures.filter(measure => measure.name != "Tension")

    const getData = () => {
        axios.get('/externes/rapports/data/' + props.appointment_id)
            .then(res => {
                setMesures(res.data.mesures)
                setPatient(res.data.patient)
                setTypedemesures(res.data.typedemesures)
                setComment(res.data.comment)
                setOphtalmologist(res.data.ophtalmologist)
                setImages(res.data.images)
                setPdfReports(res.data.pdf_reports)
                setSignature(res.data.signature)
                setAlert(res.data.alert)
                setRisques(res.data.comment == null ? [] : res.data.comment.risque.split(","))
                setAppointment(res.data.appointment)
            })
            .catch(err => {
            })
    }
    useEffect(() => {
        i18n.locale = "fr"
        getData()

    }, []);

    const renderAlertTable = () => {
        return (
            <div className="col s6 red-text">
                <p>Au vu des ces informations, l'ophtalmologue suspecte la présence de</p>
                <ul>
                    {risques.map((risque, index) => {
                        return (
                            <li key={index}>{risque}</li>
                        )
                    })}
                </ul>
                <p>Il est donc nécessaire de réaliser des examens complémentaires</p>
            </div>

        )

    }

    const renderSignature = () => {
        if (signature) {
            return <img src={signature} style={{height: "140px", padding: "10px", border: "black solid 1px"}} alt=""/>
        }
        return <PlaceholderSignature
            style={{textAlign: "center"}}
            firstname={null}
            lastname={ophtalmologist}
        />
    }

    if (appointment.status_controle == 0) {
        return (
            <div>
                <p className={"center"}> loading </p>
            </div>
        )
    }
    window.status = "ready"

    return (
        <div className="pdf-document">
            <div style={{paddingLeft: "20px", paddingRight: "00px"}}>
                <div className="row">

                    <div className="col s4">
                        <h1 style={{float: "left"}}><img src="/imgs/European_Commission.png" height="100px" alt=""/>
                        </h1>
                    </div>
                    <div className="col s6">
                        <h2>Commission européenne</h2>
                        <p><i>Direction-générale - Ressources humaines et sécurité</i> <br/>
                            <i>Direction D - Lieu de travail & bien-être</i> <br/>
                            <b>HR.D.3 - Service médical - Bruxelles</b></p>
                    </div>
                </div>
                <h3 className="center red-text" style={{marginTop: "2px"}}>Résultats à envoyer au Service médical -
                    Avenue d'Auderghem 19 - 1049 Bruxelles - BREY 06/339</h3>
                <div className="row">
                    <div className="col s6">
                        <h2 className={"center"}>{t("General information")}</h2>
                        <ul style={{paddingInlineStart: "0px", lineHeight: "30px"}}>
                            <li><b>{t("Name")}:</b> {patient.prenom} {patient.nom}</li>
                            <li><b>{t("Email")}:</b> {patient.email}</li>
                            <li><b>{t("Birthday")}:</b> {moment(patient.birthday).format("DD-MM-Y")} </li>
                            <li><b>{t("Controlled by")}</b> {ophtalmologist}</li>
                            <li><b>{t("Controlled the")}</b> {moment(appointment.analisis_date).format("DD-MM-Y")}</li>
                        </ul>
                    </div>
                    <div className="col s6">
                        <h2 className="center">Signature ophtalmologue</h2>
                        {renderSignature()}
                    </div>
                </div>
                {appointment.status_controle === 2 || (appointment.status_controle === 3 && alert.comment.gravite === "bénin") ? (
                    <div>
                        <table className="bordered">
                            <tbody>
                            <tr>
                                <th>Diagnostic</th>
                                <td><span className="green-text">NA</span></td>
                            </tr>
                            <tr>
                                <th>Antécédents oculaires</th>
                                <td style={{whiteSpace: "pre-wrap"}}>{
                                    patient.history
                                }</td>
                            </tr>
                            <tr>
                                <th>Inspection : Examen biomiscropique</th>
                                <td><span className="green-text">RAS ODG</span></td>
                            </tr>
                            <tr>
                                <th>Fond oeil</th>
                                <td><span className="green-text">RAS ODG</span></td>
                            </tr>
                            <tr>
                                <th>Diplopie</th>
                                <td><span className="green-text">Non</span></td>
                            </tr>
                            <tr>
                                <th>Champ visuel</th>
                                <td><span className="green-text">Normaux</span></td>
                            </tr>
                            <tr>
                                <th>Acuité</th>
                                <td>
                                    <DisplayMeasures title={false} measures={cleanedMeasures}/>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                ) : (
                    renderAlertTable()
                )}

                {appointment.status_controle == 2 ? (
                    <div>
                        <h2>Conclusion</h2>
                        <p>Je soussigné(e) Dr {ophtalmologist}, ophtalmologue, déclare que, conformément à l'annexe 6 ,
                            III de l'arrêté <br/>
                            royal du 23 mars 1998 relatif au permis de conduire, le candidat est <b>apte sans condition
                                sur le plan ophtalmologique à la conduite </b><br/>
                        </p>
                        <p>Cette attestation est valable
                            jusqu'au <b>{moment(appointment.analisis_date).add(365, "days").format("DD-MM-Y")}</b></p>
                        <p>Date <b>{moment(appointment.analisis_date).format("DD-MM-Y")}</b></p>
                    </div>
                ) : (<React.Fragment>{alert.comment.gravite === "bénin" ? (
                    <div>
                        <h2>Conclusion</h2>
                        <p>Je soussigné(e) Dr {ophtalmologist}, ophtalmologue, déclare que, conformément à l'annexe 6 ,
                            III de l'arrêté <br/>
                            royal du 23 mars 1998 relatif au permis de conduire, le candidat est <b>apte sous conditions
                                de ports de lunettes ou lentilles de contact (code 01.06)</b><br/>
                        </p>
                        <p>Cette attestation est valable
                            jusqu'au <b>{moment(appointment.analisis_date).add(365, "days").format("DD-MM-Y")}</b></p>
                        <p>Date <b>{moment(appointment.analisis_date).format("DD-MM-Y")}</b></p>
                    </div>

                ) : (
                    <div>
                        <h2>Conclusion</h2>
                        <p>Je soussigné(e) Dr {ophtalmologist}, ophtalmologue, déclare que, conformément à l'annexe 6 ,
                            III de l'arrêté <br/>
                            royal du 23 mars 1998 relatif au permis de conduire, le candidat est <b
                                className={"red-text"}>inapte à la conduite</b><br/>
                        </p>
                        <p>Cette attestation est valable
                            jusqu'au <b>{moment(appointment.analisis_date).add(365, "days").format("DD-MM-Y")}</b></p>
                        <p>Date <b>{moment(appointment.analisis_date).format("DD-MM-Y")}</b></p>
                    </div>

                )} </React.Fragment>)}


                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    background: "black",
                    marginLeft: "-20px",
                    marginRight: "-20px"
                }}>
                    {images.map((image, index) => {
                        return (
                            <div key={image.id} style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "line",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                                <img src={image.url} height="250px" alt=""/>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}