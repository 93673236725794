import React, {useEffect} from "react";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import axios from "axios";
import {toast} from "react-toastify";
import {Doughnut} from "react-chartjs-2";

const MonthActivity = ({}) => {

    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [monthActivity, setMonthActivity] = React.useState({});

    useEffect(() => {
        getMonthActivity()
    }, []);

    const getMonthActivity = () => {
        axios.get("/admin/statistiques/api/orthoptist/monthActivity")
            .then((response) => {
                setMonthActivity(response.data)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((err) => {
                toast.error("Une erreur est survenue.")
                setRequestState(RequestState.ERROR)
            })
    }

    const renderMonthActivity = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) {
            if (!Object.keys(monthActivity).length) return <div className="center">Aucun rendez-vous ce mois.</div>
            let data = {
                labels: Object.keys(monthActivity),
                datasets: [{
                    data: Object.values(monthActivity)
                }]
            }
            const options = {
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom",
                        align: "start",
                        maxHeight: 100,
                        labels: {
                            boxWidth: 10,
                            padding: 10,
                            font: {size: 10, lineHeight: 1}
                        }
                    }
                }
            }
            return <Doughnut data={data} options={options}/>
        }
    }

    return <div style={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        height: "100%",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        gap: 10
    }}>
        <h2 className="center" style={{margin: 0}}>
            Activité du mois
        </h2>
        <div style={{flex: 1, display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            {renderMonthActivity()}
        </div>
    </div>
}

export default MonthActivity;