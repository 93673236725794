import React, {useEffect} from "react"
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";

const _ = require('lodash');
const SelectAct = observer(() => {
    const {appointment_id} = useParams();

    useEffect(() => {
        MedicalEventStore.getActTypes()
    }, []);

    const selectedAppointment = MedicalEventStore.patients.find(p => p.appointment_id === parseInt(appointment_id))
    const selectedAct = MedicalEventStore.act_types.find(act => act.name === selectedAppointment?.act_name)

    const handleChange = (e) => {
        const appt = MedicalEventStore.patients.find(p => p.appointment_id === parseInt(appointment_id))
        console.log({...appt, act_type_id: e.target.value})
        MedicalEventStore.updateAppointment({...appt, act_type_id: parseInt(e.target.value)})
    }

    if(_.isEmpty(MedicalEventStore.act_types)) return null

    return <div>
        <select className="browser-default" style={{width: "fit-content"}} value={selectedAct?.id ?? ""} onChange={handleChange}>
            <option value="" disabled>Aucun acte</option>
            {MedicalEventStore.act_types.map((act) => {
                return <option value={act.id}>{act.name}</option>
            })}
        </select>
    </div>
})

export default SelectAct