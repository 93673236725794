import React, {useState} from "react";
import DisplayFromCV from "../patient_data/DisplayFromCV";
import {compareNormalizedString} from "../Shared/Helper";
import axios from "axios";
import ChoosePatientFromDB from "../patient_data/ChoosePatientFromDB";
import EditPatient from "../patient_data/EditPatient";

function PatientHandler(props) {
    const State = {
        DISPLAY_FROM_CV: "DISPLAY_FROM_CV",
        CHOOSE_FROM_DB: "CHOOSE_FROM_DB",
        APPOINTMENT_DETAILS: "APPOINTMENT_DETAILS",
        NEW_PATIENT: "NEW_PATIENT"
    }

    const [appointment, setAppointment] = useState({})
    const [foundPatients, setFoundPatients] = useState([])
    const [cvPatient, setCvPatient] = useState({})

    const patientSelected = (patient) => {
        setCvPatient(patient)
        if (checkIfPatientInAgenda(patient)) {
            props.changeState(State.APPOINTMENT_DETAILS)
        } else checkIfPatientInDB(patient)
    }

    const checkIfPatientInAgenda = (patient) => {
        return props.agenda.reduce((acc, appt) => {
            if (compareNormalizedString(appt.patient_firstname, patient.sPrenomPatient) &&
                compareNormalizedString(appt.patient_lastname, patient.sNomUsuelPatient)) {
                setAppointment(appt)
                return true
            }
            return acc
        }, false)
    }

    const checkIfPatientInDB = (patient) => {
        axios.get("/api/v2/search/carte_vitale?num_carte_vitale=" + patient.sNumeroSecuriteSocialePatient?.replace(/\s/g,''), {}).then((data) => {
            if (data.data !== null) {
                setFoundPatients([data.data])
                props.changeState(State.CHOOSE_FROM_DB)
            } else {
                checkSimilarPatientsInDB(patient)
            }
        })
    }

    const checkSimilarPatientsInDB = (patient) => {
        const query = patient.sNomUsuelPatient + " " + patient.sPrenomPatient
        axios.get(`/api/v2/search/patients?q=${query}`).then((data) => {
            let patients = data.data.filter((value) => (value.num_carte_vitale === null || value.num_carte_vitale.length === 0))
            setFoundPatients(patients || [])
            if ((patients || []).length === 0) {
                props.changeState(State.NEW_PATIENT)
            } else {
                props.changeState(State.CHOOSE_FROM_DB)
            }
        })
    }

    const display = () => {
        switch (props.state) {
            case State.DISPLAY_FROM_CV:
                return <DisplayFromCV data={props.data} onClick={(patient) => patientSelected(patient)}/>
            case State.CHOOSE_FROM_DB:
                return <div style={{display: "flex", flexDirection: "column"}}>
                    <ChoosePatientFromDB patients={foundPatients}/>
                    <div style={{marginBottom: 0, display: "flex"}}>
                        <a className="btn blue darken-2 z-depth-0" style={{marginLeft: "auto"}}
                           onClick={() => props.changeState(State.NEW_PATIENT)}>
                            <i className="material-icons left">add</i>Nouveau patient</a>
                    </div>
                </div>
            case State.APPOINTMENT_DETAILS:
                return <div>
                    <h4>Le patient sélectionné correspond à ce rendez-vous</h4>
                    <div className="row">
                        <div className="col s6">
                            <h5>Prénom</h5>
                            <h4>{Helper.toCamelCase(appointment.patient_firstname)}</h4>
                        </div>
                        <div className="col s6">
                            <h5>Nom</h5>
                            <h4>{Helper.toCamelCase(appointment.patient_lastname)}</h4>
                        </div>
                        <div className="col s6">
                            <h5>Age</h5>
                            <h4>{appointment.patient_age} ans</h4>
                        </div>
                        <div className="col s6">
                            <h5>Heure de l'examen</h5>
                            <h4>{Helper.toCamelCase(appointment.start)}</h4>
                        </div>
                    </div>
                    <a className="btn blue darken-2 z-depth-0" href={`/mesure/${appointment.id}`}>Commencer examen</a>
                </div>
            case State.NEW_PATIENT:
                return <EditPatient
                    patient={{
                        prenom: cvPatient.sPrenomPatient,
                        nom: cvPatient.sNomUsuelPatient,
                        birthday: cvPatient.dDateDeNaissance,
                        num_carte_vitale: cvPatient.sNumeroSecuriteSocialePatient,
                        telephone: "",
                        email: "",
                        amo: cvPatient.sCouvAMO,
                        amo_expiration: cvPatient.sDateFinAMO,
                        amc: cvPatient.sCouvAMO,
                        amc_expiration: cvPatient.sDateFinAMC
                    }}
                    bridge_uid={undefined}
                />
            default:
                return null
        }
    }

    const back = () => {
        switch (props.state) {
            case State.DISPLAY_FROM_CV:
                return null
            case State.CHOOSE_FROM_DB:
                props.changeState(State.DISPLAY_FROM_CV)
                break
            case State.APPOINTMENT_DETAILS:
                props.changeState(State.DISPLAY_FROM_CV)
                break
            case State.NEW_PATIENT:
                props.changeState(State.DISPLAY_FROM_CV)
                break
            default:
                props.changeState(State.DISPLAY_FROM_CV)
        }
    }

    return <div>
        <div className="valign-wrapper" style={{marginBottom: "1vh"}}>
            <div id={`${props.state !== State.DISPLAY_FROM_CV ? "hoverable" : ""}`}
                 className={`${props.state !== State.DISPLAY_FROM_CV ? "" : "transparent-text"}`}
                 onClick={() => back()}>
                <i className="material-icons">chevron_left</i>
            </div>
            <h1 style={{margin: "auto"}}>Carte vitale</h1>
        </div>
        {display()}
    </div>

}

export default PatientHandler;