import React from 'react';
import NavBar from "./NavBar";
import UserDropdown from "./UserDropdown";
import {capitalize, t as t_i18n} from '../../I18n/i18n'

const t = (key) => capitalize(t_i18n("NAVBAR." + key))

export default function SecretaryNavbar({image_path, user, topbar_prop = ""}) {

    const components = [
        {component: <UserDropdown user={user}/>, dataCy: "UserDropdown"},
    ]

    const urls = [
        {name: t("PATIENTS"), url: "/secretariat/patients"},
        {
            name: t("SECRETARY"),
            dropdown: [
                // {name: t("ACCOUNTING"), url: "/admin/statistics/ophtalmologist"},
                {name: t("BLANK_EMAIL"), url: "/secretariat/email"},
                {name: "Patients non vus", url: "/unseen"},
                {name: t("TELETRANSMISSION"), url: "/secretariat/teletransmission"},
                // {name: t("INVOICES"), url: "/ophtalmologistes/paiements/overview"},
                {name: t("HISTORY"), url: "/admin/logs/appointments/index"}
            ]
        }
    ]

    return <NavBar image_path={image_path} urls={urls} topbar_prop={topbar_prop} components={components}/>
}
