import {action, computed, configure, makeObservable, observable} from "mobx"
import PrescriptionStore from "../PrescriptionStore"
import axios from "axios"
import {toast} from 'react-toastify';
import Helper, {JOB} from "../../Shared/Helper";
import {Role} from "../../Shared/Enums/Scope";
import {Act, ActNature, TypesMesures} from "../../Shared/Enums/Enums";
import {LockingElement} from "../AppointmentValidation";
import {t} from "../../I18n/i18n"
import WebToDesktop from "../../WebToDesktop/WebToDesktop";

const _ = require('lodash');
configure({enforceActions: 'never'}) // done during the migration

class MesureStore {
    @observable od_tension = ""
    @observable od_sphere = ""
    @observable od_cylindre = ""
    @observable od_av_loin = ""
    @observable od_axe = ""
    @observable og_axe = ""
    @observable od_addition = ""
    @observable od_parinaud = ""
    @observable od_pachymetrie = null
    @observable og_tension = ""
    @observable og_sphere = ""
    @observable og_cylindre = ""
    @observable og_av_loin = ""
    @observable og_addition = ""
    @observable og_parinaud = ""
    @observable og_pachymetrie = null
    @observable od_av_trou_stenopeique = ""
    @observable og_av_trou_stenopeique = ""
    @observable commentaire = ""
    @observable od_degree = ""
    @observable og_degree = ""
    @observable od_radius_1 = ""
    @observable og_radius_1 = ""
    @observable od_radius_2 = ""
    @observable og_radius_2 = ""
    @observable typedemesure = ""
    @observable appointement_id = ""
    @observable appointment_teletransmission_status = null
    @observable appointment_date = ""
    @observable patient_id = ""
    @observable patient = {}
    @observable.shallow existingMesures = []
    @observable.shallow lastData = []
    @observable.shallow typesdemesures = []
    @observable.shallow last_appointements = []
    @observable orthoptiste = {}
    @observable cabinet = {}
    @observable images = false
    @observable act_saved = false
    @observable selected_act = undefined
    @observable selectedChain = null
    @observable chains = []
    @observable report = null
    @observable stereoscopy = ""
    @observable anamnesis = ""
    @observable comment_for_ophtalmologist = ""
    @observable orthoptic_text = ""
    @observable private_comment = ""
    @observable constraints_bypassed = false
    @observable isAppointmentComplete = null
    @observable media = {}
    @observable pathologies = []
    @observable selected_template = null
    @observable is_appointment_new = false
    @observable textual_shortcuts = []
    @observable mesureSaving = false
    @observable prescription_url = null
    @observable diabetes_questions = {}
    metadata = {}

    bridges = []
    refreshListeners = []

    constructor() {
        makeObservable(this)
        this.debouncedSaveMesure = _.debounce(this.saveMesure, 1000)
    }

    checkAV = (av) => {
        const val = parseFloat(av)
        if (isNaN(val)) {
            return true
        } else {
            return val >= 0.9
        }
    }

    @computed get needAlert() {
        let alert = false
        let message = []
        this.existingMesures.map((mesure) => {
            if (mesure.od_tension > 21 || mesure.og_tension > 21) {
                alert = true
                message.push("Une tension trop élevée")
            }
            if (mesure.titre == 'Réfraction subjective') {
                if (!this.checkAV(mesure.od_av_loin) || !this.checkAV(mesure.og_av_loin)) {
                    alert = true
                    message.push("Une acuité visuelle trop basse")
                }
            }
        })

        return ([alert, Array.from(new Set(message))])
    }

    @computed get age() {
        let data = this.patient.birthday
        if (data === undefined) {
            return 99;
        }
        data = data.split("-")
        if (data[0] === "") {
            return 99;
        }

        let age = Helper.dateToAge(this.patient.birthday)
        if (age < 0) {
            return 99;
        } else {
            return age;
        }
    }

    @computed get need_trou_stenopeique() {
        // si une av de ref subjective < 0.9 => trou stenopeique
        return this.typedemesure == 12 && !(this.checkAV(this.od_av_loin) && this.checkAV(this.og_av_loin))
    }

    @computed get AvMesuresAnalisis() {

        /*
        c'est un patch simplifié des demandes de dinu, masi bcp bcp bcp plus simple a faire
        - une AV lunettes de loins
        - une AV lunettes de pres
        ou
        - une AV sans correction de pres
        - une AV sans correction de loins
         */
        let mesure_has_av = (mesure) => {
            return !([null, ""].includes(mesure.og_av_loin)) && !([null, ""].includes(mesure.od_av_loin))
        }
        let lunettes_loin = false
        let lunettes_pres = false
        let sans_loin = false
        let sans_pres = false
        this.existingMesures.map((mesure) => {
            if (mesure.titre === TypesMesures.LONGSIGHT_GLASSES) {
                lunettes_loin = mesure_has_av(mesure)
            } else if (mesure.titre === TypesMesures.SHORTSIGHT_GLASSES) {
                lunettes_pres = mesure_has_av(mesure)
            } else if (mesure.titre === TypesMesures.NO_LONGSIGHT_CORRECTION) {
                sans_loin = mesure_has_av(mesure)
            } else if (mesure.titre === TypesMesures.NO_SHORTSIGHT_CORRECTION) {
                sans_pres = mesure_has_av(mesure)
            }
        })
        if (!lunettes_loin && !lunettes_pres && !sans_loin && !sans_pres) {
            return t("ACTIVE_TABS.PROVIDE_A_MINIMUM")
        } else if (lunettes_loin && !(lunettes_pres || sans_pres)) {
            return t("ACTIVE_TABS.ENTER_NEAR_VA")
        } else if (!(lunettes_loin || sans_loin) && lunettes_pres) {
            return t("ACTIVE_TABS.ENTER_FAR_VA")
        } else if (sans_loin && !(sans_pres || lunettes_pres)) {
            return t("ACTIVE_TABS.ENTER_NEAR_VA")
        } else if (!(sans_loin || lunettes_loin) && sans_pres) {
            return t("ACTIVE_TABS.ENTER_FAR_VA")
        }
        return ""
    }

    setStatus(completed) {
        return axios.patch(`/api/appointment/status`, {
            appointment_id: this.appointement_id,
            completed: completed
        }).then((resp) => {
            this.isAppointmentComplete = resp.data.completed
        })
    }

    @computed get completed() {
        const completed = this.listLockingElements().length === 0
        this.isAppointmentComplete = completed
        return completed
    }

    listLockingElements() {
        if (this.constraints_bypassed) return []
        if (PrescriptionStore.hasSkiacol()) return [];
        if (this.selected_act?.titre.trim() === Act.NOTHING) return []
        if (this.selected_act && this.selected_act.nature !== ActNature.TELEMEDICAL.name) return []

        const consideredElementsForOpticians = [LockingElement.RIGHT_ACUITY, LockingElement.LEFT_ACUITY, LockingElement.ACT]

        const lockingElements = JOB === Role.OPTICIAN ? consideredElementsForOpticians : Object.values(LockingElement).filter(element => !element.optional)

        const lockingMesures = lockingElements.reduce((acc, element) => {
            acc[element.prompt] = !element.isValid() // if not valid so it's a locking element
            return acc
        }, {})

        return Object.keys(lockingMesures).filter(key => lockingMesures[key])
    }

    lockedElementToString() {
        let str = "Il vous reste à valider ces différents éléménts avant de pouvoir valider la consultation\n"
        this.listLockingElements().map((element) => {
            str += `${element}\n`
        })
        return str
    }

    @action lastMesure() {
        axios.post("/api/getLastMesure", {
            appointement_id: this.appointement_id,
            patient_id: this.patient_id,
            typedemesure_id: this.typedemesure
        }).then((data) => {
            this.lastData = data.data
        })
    }

    @action hasImages() {
        axios.post("/api/appointements/hasImages", {appointement_id: this.appointement_id}).then((data) => {
            this.images = data.data.response
        })
    }

    @action loadKindsMesures(trigger_web_to_desktop = false) {
        const instance = axios.create();
        instance.defaults.timeout = 2500;

        instance.post("/api/kindMesures", {patient: this.patient_id, appointement_id: this.appointement_id})
            .then((data) => {
                this.existingMesures = data.data
                if(trigger_web_to_desktop) this.triggerWebToDesktop()

            })
            .catch((data) => {
                toast.error('☢ Erreur connexion internet !', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
        // this.lastMesure()

    }

    @action
    updated() {
        axios.post("/api/appointement/updated", {appointment_id: this.appointement_id}).then(() => {
        })
    }

    @action deleteMesure(id) {
        const instance = axios.create();
        instance.defaults.timeout = 2500;

        instance.delete("/mesure/" + id, {params: {appointment_id: this.appointement_id}})
            .then((res) => this.loadKindsMesures())
            .catch((err) => {
                toast.error('☢ Erreur connexion internet !');
            });
    }

    @action saveMesure() {
        const instance = axios.create();
        instance.defaults.timeout = 2500;

        instance.post("/mesure", {
            app: {
                "od_tension": this.od_tension,
                "od_sphere": this.od_sphere,
                "od_cylindre": this.od_cylindre,
                "od_av_loin": this.od_av_loin,
                "og_axe": this.og_axe,
                "od_axe": this.od_axe,
                "od_addition": this.od_addition,
                "od_parinaud": this.od_parinaud,
                "od_pachymetrie": this.od_pachymetrie,
                "og_pachymetrie": this.og_pachymetrie,
                "og_parinaud": this.og_parinaud,
                "og_tension": this.og_tension,
                "og_cylindre": this.og_cylindre,
                "og_sphere": this.og_sphere,
                "og_av_loin": this.og_av_loin,
                "og_addition": this.og_addition,
                "commentaire": this.commentaire,
                "od_av_trou_stenopeique": this.od_av_trou_stenopeique,
                "og_av_trou_stenopeique": this.og_av_trou_stenopeique,
                "od_degree": this.od_degree,
                "og_degree": this.og_degree,
                "od_radius_1": this.od_radius_1,
                "og_radius_1": this.og_radius_1,
                "od_radius_2": this.od_radius_2,
                "og_radius_2": this.og_radius_2,
            }, appointement_id: this.appointement_id, patient: this.patient_id, typedemesure_id: this.typedemesure
        })
            .then((data) => {
                const mesure = data.data
                console.log(mesure.id)
                console.log(this.existingMesures.map((m) => [m.id, m]))
                this.existingMesures = this.existingMesures.map((m) => (m.mesure_id === mesure.mesure_id) ? mesure : m)
                console.log("MESURE GIGA SAVED")
                this.triggerWebToDesktop()
                this.loadKindsMesures()
            })
            .catch((data) => {
                toast.error('☢ Erreur connexion internet !', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
    }

    @action updateForm(typedemesure_id, type = {}, callback = () => {
    }) {
        axios.post("/api/mesure", {
            patient: this.patient_id,
            appointement_id: this.appointement_id,
            typedemesure_id: typedemesure_id
        })
            .then((data) => {
                if (data.data == null) {
                    this.od_tension = ""
                    this.od_sphere = ""
                    this.od_cylindre = ""
                    this.od_av_loin = ""
                    this.od_axe = ""
                    this.og_axe = ""
                    this.od_addition = ""
                    this.od_parinaud = ""
                    this.od_pachymetrie = null
                    this.og_pachymetrie = null
                    this.og_tension = ""
                    this.og_sphere = ""
                    this.og_cylindre = ""
                    this.og_av_loin = ""
                    this.og_addition = ""
                    this.og_parinaud = ""
                    this.od_av_trou_stenopeique = ""
                    this.og_av_trou_stenopeique = ""
                    this.commentaire = ""
                    this.od_degree = ""
                    this.og_degree = ""
                    this.od_radius_1 = ""
                    this.og_radius_1 = ""
                    this.od_radius_2 = ""
                    this.og_radius_2 = ""
                } else {
                    var d = data.data
                    this.od_tension = d["od_tension"] || ""
                    this.od_sphere = d["od_sphere"] !== null ? d["od_sphere"] : ""
                    this.od_cylindre = d["od_cylindre"] !== null ? d["od_cylindre"] : ""
                    this.od_axe = d["od_axe"] == null ? "" : d["od_axe"]
                    this.od_addition = d["od_addition"] || ""
                    this.od_av_loin = d["od_av_loin"] || ""
                    this.od_parinaud = d["od_parinaud"] || ""
                    this.od_pachymetrie = d["od_pachymetrie"]
                    this.og_pachymetrie = d["og_pachymetrie"]
                    this.og_tension = d["og_tension"] || ""
                    this.og_sphere = d["og_sphere"] !== null ? d["og_sphere"] : ""
                    this.og_cylindre = d["og_cylindre"] !== null ? d["og_cylindre"] : ""
                    this.og_axe = d["og_axe"] == null ? "" : d["og_axe"]
                    this.og_av_loin = d["og_av_loin"] || ""
                    this.og_addition = d["og_addition"] || ""
                    this.og_parinaud = d["og_parinaud"] || ""
                    this.od_av_trou_stenopeique = d["od_av_trou_stenopeique"] || ""
                    this.og_av_trou_stenopeique = d["og_av_trou_stenopeique"] || ""
                    this.commentaire = ""
                    this.od_degree = d["od_degree"] || ""
                    this.og_degree = d["og_degree"] || ""
                    this.od_radius_1 = d["od_radius_1"] || ""
                    this.og_radius_1 = d["og_radius_1"] || ""
                    this.od_radius_2 = d["od_radius_2"] || ""
                    this.og_radius_2 = d["og_radius_2"] || ""
                }
                this.typedemesure = parseInt(typedemesure_id)
                callback(typedemesure_id, this, PrescriptionStore, type)
                this.lastMesure()

            })
    }

    @action importFromOtherappointement(typedemesure_id, appointement_id, type = {}, callback = () => {
    }) {
        axios.post("/api/mesure", {
            patient: this.patient_id,
            appointement_id: appointement_id,
            typedemesure_id: typedemesure_id
        })
            .then((data) => {
                if (data.data == null) {
                    this.od_tension = ""
                    this.od_sphere = ""
                    this.od_cylindre = ""
                    this.od_av_loin = ""
                    this.od_axe = ""
                    this.og_axe = ""
                    this.od_addition = ""
                    this.od_parinaud = ""
                    this.od_pachymetrie = null
                    this.og_pachymetrie = null
                    this.og_tension = ""
                    this.og_sphere = ""
                    this.og_cylindre = ""
                    this.og_av_loin = ""
                    this.og_addition = ""
                    this.og_parinaud = ""
                    this.od_av_trou_stenopeique = ""
                    this.og_av_trou_stenopeique = ""
                    this.commentaire = ""
                    this.od_degree = ""
                    this.og_degree = ""
                    this.od_radius_1 = ""
                    this.og_radius_1 = ""
                    this.od_radius_2 = ""
                    this.og_radius_2 = ""
                } else {
                    var d = data.data
                    this.od_tension = d["od_tension"] || ""
                    this.od_sphere = d["od_sphere"] !== null ? d["od_sphere"] : ""
                    this.od_cylindre = d["od_cylindre"] !== null ? d["od_cylindre"] : ""
                    this.od_axe = d["od_axe"] == null ? "" : d["od_axe"]
                    this.od_addition = d["od_addition"] || ""
                    this.od_av_loin = d["od_av_loin"] || ""
                    this.od_parinaud = d["od_parinaud"] || ""
                    this.od_pachymetrie = d["od_pachymetrie"]
                    this.og_pachymetrie = d["og_pachymetrie"]
                    this.og_tension = d["og_tension"] || ""
                    this.og_sphere = d["og_sphere"] !== null ? d["og_sphere"] : ""
                    this.og_cylindre = d["og_cylindre"] !== null ? d["og_cylindre"] : ""
                    this.og_axe = d["og_axe"] == null ? "" : d["og_axe"]
                    this.og_addition = d["og_addition"] || ""
                    this.og_av_loin = d["og_av_loin"] || ""
                    this.og_parinaud = d["og_parinaud"] || ""
                    this.od_av_trou_stenopeique = d["od_av_trou_stenopeique"] || ""
                    this.og_av_trou_stenopeique = d["og_av_trou_stenopeique"] || ""
                    this.commentaire = ""
                    this.od_degree = d["od_degree"] || ""
                    this.og_degree = d["og_degree"] || ""
                    this.od_radius_1 = d["od_radius_1"] || ""
                    this.og_radius_1 = d["og_radius_1"] || ""
                    this.od_radius_2 = d["od_radius_2"] || ""
                    this.og_radius_2 = d["og_radius_2"] || ""
                }
                callback(typedemesure_id, this, PrescriptionStore, type)
            })
    }

    @action set_od_tension(val) {
        this.od_tension = val
        this.saveMesure()
    }

    @action set_og_tension(val) {
        this.og_tension = val
        this.saveMesure()
    }

    @action set_od_sphere(val) {
        this.od_sphere = val
        this.debouncedSaveMesure()
    }

    @action set_og_sphere(val) {
        this.og_sphere = val
        this.debouncedSaveMesure()
    }

    @action set_od_cylindre(val) {
        this.od_cylindre = val
        this.debouncedSaveMesure()
    }

    @action set_og_cylindre(val) {
        this.og_cylindre = val
        this.debouncedSaveMesure()
    }

    @action set_od_axe(val) {
        this.od_axe = val
        this.debouncedSaveMesure()
    }

    @action set_og_axe(val) {
        this.og_axe = val
        this.debouncedSaveMesure()
    }

    @action set_od_av_loin(val) {
        this.od_av_loin = val
        this.debouncedSaveMesure()
    }

    @action set_og_av_loin(val) {
        this.og_av_loin = val
        this.debouncedSaveMesure()
    }

    @action set_od_addition(val) {
        this.od_addition = val
        this.debouncedSaveMesure()
    }

    @action set_og_addition(val) {
        this.og_addition = val
        this.debouncedSaveMesure()
    }

    @action set_od_parinaud(val) {
        this.od_parinaud = val
        this.debouncedSaveMesure()
    }

    @action set_og_parinaud(val) {
        this.og_parinaud = val
        this.debouncedSaveMesure()
    }

    @action set_od_pachymetrie(val) {
        this.od_pachymetrie = val
        this.debouncedSaveMesure()
    }

    @action set_og_pachymetrie(val) {
        this.og_pachymetrie = val
        this.debouncedSaveMesure()
    }

    @action set_od_av_trou_stenopeique(val) {
        this.od_av_trou_stenopeique = val
        this.debouncedSaveMesure()
    }

    @action set_og_av_trou_stenopeique(val) {
        this.og_av_trou_stenopeique = val
        this.debouncedSaveMesure()
    }

    @action set_od_degree(val) {
        this.od_degree = val
        this.debouncedSaveMesure()
    }

    @action set_og_degree(val) {
        this.og_degree = val
        this.debouncedSaveMesure()
    }

    @action set_od_radius_1(val) {
        this.od_radius_1 = val
        this.debouncedSaveMesure()
    }

    @action set_og_radius_1(val) {
        this.og_radius_1 = val
        this.debouncedSaveMesure()
    }

    @action set_od_radius_2(val) {
        this.od_radius_2 = val
        this.debouncedSaveMesure()
    }

    @action set_og_radius_2(val) {
        this.og_radius_2 = val
        this.debouncedSaveMesure()
    }


    @action subscribe(bridge) {
        this.bridges.push(bridge)
    }

    @action signalBridges(id) {
        this.bridges.map((bridge) => {
            bridge.updateAppointment(id)
        })
    }

    @action actSaved(bool) {
        this.act_saved = bool
    }

    @action cleanValues() {
        this.od_tension = ""
        this.od_sphere = ""
        this.od_cylindre = ""
        this.od_av_loin = ""
        this.od_axe = ""
        this.og_axe = ""
        this.od_addition = ""
        this.od_parinaud = ""
        this.od_pachymetrie = 0
        this.og_pachymetrie = 0
        this.og_tension = ""
        this.og_sphere = ""
        this.og_cylindre = ""
        this.og_av_loin = ""
        this.og_addition = ""
        this.typedemesure = ""
        this.og_parinaud = ""
        this.commentaire = ""
        this.od_av_trou_stenopeique = ""
        this.og_av_trou_stenopeique = ""
        this.od_degree = ""
        this.og_degree = ""
        this.od_radius_1 = ""
        this.og_radius_1 = ""
        this.od_radius_2 = ""
        this.og_radius_2 = ""
        return true
    }

    addListenerOnRefresh(callback) {
        this.refreshListeners.push(callback)
    }

    @action refresh() {
        this.cleanValues()
        this.loadKindsMesures(true)
        this.hasImages()
        try {
            for (let listener of this.refreshListeners) {
                listener()
            }

        } catch (e) {
            console.error("error on automatic transmission", e)
        }
    }

    @action isAppointmentInChain(act_id) {
        return this.selectedChain != null && this.selectedChain.actepossible_id === act_id
    }

    @computed get displayable_act_natures() {
        if (this.orthoptiste.is_nurse) return [ActNature.NURSE]
        let arr = []
        if (this.orthoptiste.telemedecine_enabled) arr.push(ActNature.TELEMEDICAL)
        if (this.orthoptiste.orthoptic_acts_enabled) arr.push(ActNature.ORTHOPTIC_EVALUATION, ActNature.REEDUCATION, ActNature.FUNCTIONAL_EXPLORATION)
        return arr
    }


    saveComments(comments) {
        axios.post("/api/appointement/saveCommentaireLibre", {
            appointement_id: this.appointement_id,
            text: this.anamnesis,
            private_comment: this.private_comment,
            orthoptic_text: this.orthoptic_text,
            comment_for_ophtalmologist: this.comment_for_ophtalmologist
        }).then((_) => {
            this.mesureSaving = false
        })
    }

    debouncedSaveComments = _.debounce(this.saveComments, 1000)

    @action setAnamnesis(text) {
        this.anamnesis = text
        this.mesureSaving = true
        this.debouncedSaveComments()
    }

    @action setPrivateComment(text) {
        this.private_comment = text
        this.mesureSaving = true
        this.debouncedSaveComments()
    }

    @action setOrthotpicText(text) {
        this.orthoptic_text = text
        this.mesureSaving = true
        this.debouncedSaveComments()
    }

    @action setOphtalmologistComment(text) {
        this.comment_for_ophtalmologist = text
        this.mesureSaving = true
        this.debouncedSaveComments()
    }

    getAttachedPrescription = () => {
        axios.get("/api/appointment/evaluation/prescription", {params: {appointment_id: this.appointement_id}}).then((response) => {
            this.prescription_url = response.data.url
        }).catch((_) => {})
    }

    @computed get hasParentAppointment() {
        return this.selectedChain?.parent_appointment_id
    }

    triggerWebToDesktop() {
        const w2d_buttons = document.querySelectorAll(`button[id^=${WebToDesktop.BUTTON_ID}]`);

        if(w2d_buttons.length === 0) return
        console.log("AUTOMATED : sending data to machines");
        w2d_buttons.forEach(button => button.click());
    }

}

let store = new MesureStore
global.MesureStore = store
export default store
