import React from 'react';
import {IbanElement, useElements, useStripe} from '@stripe/react-stripe-js';

import IbanForm from './IbanForm';

export default function CheckoutForm({clientSecret, onValidation, user}) {
    const [iban, setIban] = React.useState({});
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const iban_element = elements.getElement(IbanElement);

        // For brevity, this example is using uncontrolled components for
        // the accountholder's name and email. In a real world app you will
        // probably want to use controlled components.
        // https://reactjs.org/docs/uncontrolled-components.html
        // https://reactjs.org/docs/forms.html#controlled-components

        const accountholderName = event.target['accountholder-name'];
        const email = event.target.email;

        const result = await stripe.confirmSepaDebitSetup(clientSecret, {
            payment_method: {
                sepa_debit: iban_element,
                billing_details: {
                    name: accountholderName.value,
                    email: email.value,
                },
            }
        });

        if (result.error) {
            // Show error to your customer.
            console.error(result.error.message);
        } else {
            // Show a confirmation message to your customer.
            // The PaymentIntent is in the 'processing' state.
            // SEPA Direct Debit payments are asynchronous,
            // so funds are not immediately available.
            onValidation({
                payment_method: result.setupIntent.payment_method,
                bank_name: iban.bankName,
                fullname: accountholderName.value,
                email: email.value
            })
        }
    };

    const onChange = (event) => {
        setIban(event)
    }

    return <IbanForm onSubmit={handleSubmit} disabled={!stripe || !iban.complete} onChange={onChange} user={user}/>
}
