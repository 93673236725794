import React from "react"
import axios from "axios"
import {toast} from 'react-toastify';


class SecretaryComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            done: false

        };
    }

    emptyComment = (e) => {
        this.setState({comment: ""})
    }

    handeWriting = (e) => {
        this.setState({comment: e.target.value})
    }


    sendComment = () => {
        axios.post(this.props.url, {
            alert: this.props.alert_id,
            text: this.state.comment
        }).then((data) => {
            this.props.onChange(this.props.alert_id, this.state.comment)
            toast.success('Commentaire enregistré', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            let tooltip = document.getElementById(`secretaryCommentTooltip_${this.props.alert_id}`)
            let modal = document.getElementById(`secretaryCommentModal_${this.props.alert_id}`)

            if (M.Tooltip.getInstance(tooltip)) M.Tooltip.getInstance(tooltip).close()
            if (M.Modal.getInstance(modal)) M.Modal.getInstance(modal).close()
            this.setState({done: true})
        })
    }

    componentDidMount() {
        let modals = document.getElementById(`secretaryCommentModal_${this.props.alert_id}`)
        let tooltips = document.getElementById(`secretaryCommentTooltip_${this.props.alert_id}`)
        if (this.props.comment) M.Tooltip.init(tooltips, {})
        M.Modal.init(modals, {dismissible: true})
        this.setState({comment: this.props.comment})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.alert_id !== this.props.alert_id || prevProps.comment !== this.props.comment) {
            this.setState({comment: this.props.comment, done: false})
            let tooltip = document.getElementById(`secretaryCommentTooltip_${this.props.alert_id}`)
            if (M.Tooltip.getInstance(tooltip)) M.Tooltip.getInstance(tooltip).destroy()
            if (this.props.comment) M.Tooltip.init(tooltip, {})
        }
        else{
            let tooltip = document.getElementById(`secretaryCommentTooltip_${this.props.alert_id}`)
            if(this.state.comment.length === 0){
                if (M.Tooltip.getInstance(tooltip)) M.Tooltip.getInstance(tooltip).destroy()
            }
            else{
                if (this.state.comment.length) M.Tooltip.init(tooltip, {})
            }
        }
    }

    componentWillUnmount() {
        let tooltip = document.getElementById(`secretaryCommentTooltip_${this.props.alert_id}`)
        let modal = document.getElementById(`secretaryCommentModal_${this.props.alert_id}`)

        if (M.Tooltip.getInstance(tooltip)) M.Tooltip.getInstance(tooltip).destroy()
        if (M.Modal.getInstance(modal)) M.Modal.getInstance(modal).destroy()
    }

    render() {
        return (
            <div style={{marginTop: "20px"}}>
                <div style={{cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}}
                     className={this.state.comment ? "blue-text modal-trigger tooltipped" : "blue-text modal-trigger"}
                     id={`secretaryCommentTooltip_${this.props.alert_id}`}
                     data-target={`secretaryCommentModal_${this.props.alert_id}`}
                     data-position="top"
                     data-tooltip={this.state.comment}
                >

                    <i className="material-icons center"
                       style={{marginRight: "7px"}}
                    >{this.state.comment ? "comment" : "mode_comment"}</i>
                    {this.props.text}<br/>
                </div>
                <div id={`secretaryCommentModal_${this.props.alert_id}`} className="modal">
                    <div className="col s12 modal-content">
                        <div>
                            <a onClick={this.sendComment}
                               style={{marginRight: "20px"}}
                               className="waves-effect blue waves-light btn"><i
                                className="material-icons left">send</i>Envoyer Commentaire</a>
                            <a onClick={this.emptyComment}
                               className="waves-effect red waves-light btn"><i
                                className="material-icons left">delete</i>Effacer le contenu actuel</a>
                        </div>
                        <div className="input-field col s12">
                                                <textarea id="textarea1" rows="30" cols="40" style={{height: "60vh"}}
                                                          onChange={this.handeWriting} value={this.state.comment}
                                                          className=""></textarea>
                            <label htmlFor="textarea1"></label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SecretaryComment.defaultProps = {
    onChange: () => null
}

export default SecretaryComment
