import React, {useEffect, useState} from "react";
import axios from "axios";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../Controle/Loader";
import ExamFormulaRepartition from "./ExamFormulaRepartition";
import {observer} from "mobx-react";
import ConsoleDashboardStore from "../ConsoleDashboardStore";


const DetailledDashboard = observer(({}) => {

    const [requestState, setRequestState] = useState(RequestState.LOADING)
    const [categories, setCategories] = useState([])
    const [appointmentsCount, setAppointmentsCount] = useState([])

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        setRequestState(RequestState.RELOADING)
        getData()
    }, [
        ConsoleDashboardStore.currentDateOption,
        ConsoleDashboardStore.startDate,
        ConsoleDashboardStore.endDate,
        ConsoleDashboardStore.selectedFormulas,
        ConsoleDashboardStore.selectedActs,
        ConsoleDashboardStore.selectedOphtalmologists,
        ConsoleDashboardStore.selectedOffices,
        ConsoleDashboardStore.selectedOrthoptists,
    ]);

    const getData = () => {

        let dateRange = ConsoleDashboardStore.getDateRange()

        axios.get("/admin/console/bills/detailled_formulas", {
            params: {
                start_date: dateRange.start_date,
                end_date: dateRange.end_date,
                dinu: ConsoleDashboardStore.includeDinu,
                formula_ids: ConsoleDashboardStore.selectedFormulas.map((formula) => formula.id) || undefined,
                act_codes: ConsoleDashboardStore.selectedActs.map((act) => act.code) || undefined,
                office_ids: ConsoleDashboardStore.selectedOffices.map((office) => office.id) || undefined,
            }
        })
            .then((response) => {
                setAppointmentsCount(response.data.offices)
                setCategories(response.data.billing_categories)
                setRequestState(RequestState.SUCCESS)
            }).catch((_) => {
            setRequestState(RequestState.ERROR)
        })
    }

    const renderFormulaCharts = () => {
        if (requestState === RequestState.LOADING || requestState === RequestState.RELOADING) return <div className="center"><Loader wide/><br/>
            En fonction des dates et des filtres, le chargement peut être long
        </div>
        if (requestState === RequestState.ERROR) return <div className="center">Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <div className="container" style={{
            width: "90%",
            display: "flex",
            flexDirection: "column"
        }}>
            {categories.map((category) => {
                let offices = appointmentsCount.filter((office) => {
                    return office.orthoptists.some((orthoptist) => orthoptist.appointments[category.name] !== 0)
                })
                let labels = []
                let counts = []
                offices.map((office) => {
                    labels.push(office.title)
                    counts.push(office.orthoptists.reduce((acc, current_value) => acc + current_value.appointments[category.name], 0))
                })
                return <ExamFormulaRepartition title={category.name}
                                               data={{labels: labels, datasets: [{data: counts}]}}/>

            })}
        </div>
    }

    return <div id="full-height"
                style={{display: "flex", flexDirection: "column", paddingBlock: 10}}>
        <h3 className="center">Informations formules</h3>
        {renderFormulaCharts()}
    </div>
})

export default DetailledDashboard;