import {action, observable} from "mobx"
import axios from "axios"
import MesureStore from "../Appointments/Mesures/MesureStore";
import socket from "../Shared/socket";

class BridgeStore{
  @observable uid=null
  @observable api_token=0
  @observable my_machine_status=0 // 1 si connecte, 0 sinon
  @observable orthoptiste_id=0
  @observable email=0
  @observable avaiable_images=[]
  @observable connected_usbs=[]
  @observable machines=[]
  @observable samba_machines=[]
  @observable local_ip=null
  @observable loading= true
  @observable url= {}
  constructor() {
    this.subscribeMesureStore()
  }
  connect(uid){
    this.uid = uid
    axios.post("/api/v3/bridge/subscribe",{uid: uid}).then((data)=>{
      this.redirecttomain() // essayons de pas duppliquer le code degeu :p
    })
  }
  pingCurrent(){
    if(this.uid != undefined){
      socket.emit("knocknockMachine",{machine: this.uid})
    }
  }
  redirecttomain(){
      document.location ="/orthoptistes/v2/bridge"

  }
  init(uid,api_token,email,id){
    this.uid = uid
    this.api_token = api_token
    this.email = email
    this.orthoptiste_id = id
    this.loading = false
    var that =this
    socket.on("machine_presente",(data)=>{
      if(data.machine==this.uid){
        this.my_machine_status = 1
      }
      this.avaiable_images.push(data.machine)
    })
    this.socket.on("usbs_connectes",(data)=>{
      console.log(data.usbs, "USB CONNECTEES")
      this.connected_usbs = data.usbs
    })
    socket.emit("machineID",{machine: this.uid});
    socket.on("getMachines",data=>{
      this.machines = data.machines
      this.samba_machines = data.samba
      this.local_ip = data.localIp
    })
    if(that.uid != null){
      socket.emit("identify",{machine: that.uid,orthoptiste: that.orthoptiste_id})
      setInterval(()=>{
        socket.emit("identify",{machine: that.uid,orthoptiste: that.orthoptiste_id})
      },5000)
   }
  }

  @action
  updateAppointment(id){
    this.app_id = id
    if(socket != null){
      socket.emit("current_appointment",{machine: this.uid ,id: id})
    }else{
      setTimeout(() => this.updateAppointment(id), 250)
    }

  }

  disconnect(){
    if(socket !== null){
      socket.disconnect()
    }
  }

  subscribeMesureStore(){
    this.updateAppointment(MesureStore.appointement_id)
    MesureStore.subscribe(this)
  }

  updateMachines(){
    socket.emit("askMachines",{machine: this.uid})
    socket.emit("usbPorts",{machine: this.uid})
  }

  addNewMachine(baudrate, desc, hwid,marque,modele,eol,eot,port, eot_bin, eol_bin, parser,callback=null){
    socket.emit("addUsbMachine", {machine: this.uid, baudrate: baudrate, desc:desc, hwid:hwid, marque:marque, modele:modele, eol:eol, eot:eot, port:port, eot_bin:eot_bin, eol_bin:eol_bin, parser:parser})
    if(callback != null){
      setTimeout(callback,100)
      return true;
    }else{
      document.location ="/orthoptistes/v2/bridge"

    }
  }

  addNewSamba(modele, marque, pattern, parser){
    socket.emit("addSambaMachine", {machine: this.uid, marque: marque, modele:modele, pattern:pattern, parser:parser})
    if(callback != null){
      setTimeout(callback,100)
      return true;
    }else{
      document.location ="/orthoptistes/v2/bridge"
    }
  }
  get_url(){
    axios.post("/api/v3/bridge/get_url",{}).then((data)=>{
      this.url = data.data.url
    })
  }
  deleteMachine(hwid){
    socket.emit("deleteMachine", {machine: this.uid, id: hwid})
    location.reload()
  }

  deleteMachineSamba(uid){
    socket.emit("deleteSamba", {machine:this.uid, id:uid})
    location.reload()
  }
}
let bridgeStore = new BridgeStore();
global.Bridgev2Store = bridgeStore;
export default bridgeStore;
