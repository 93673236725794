import React, {useEffect} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../../Shared/Loader";
import Helper from "../../../../Shared/Helper";
import ScopedElement from "../../../../Shared/ScopedElement";
import {Role} from "../../../../Shared/Enums/Scope";
import {InternalUserRole, UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import RightsStore from "../../RightsStore";
import Error from "../../../../Shared/Error";
import {Observer} from "mobx-react";
import EditButton from "../../../../Shared/EditButton";

const _ = require('lodash');

function InternalUsers(props) {
    const modal_id = `console_internal_users_modal`
    const [internalUsers, setInternalUsers] = React.useState(props.internal_users)
    const [onEditInternalUser, setOnEditInternalUser] = React.useState({})
    const [roles, setRoles] = React.useState([])
    const [filter, setFilter] = React.useState("")

    useEffect(() => {
        get_roles()
    }, [])

    useEffect(() => {
        setInternalUsers(props.internal_users)
    }, [props.internal_users])

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [internalUsers, RightsStore.rights])

    const get_roles = () => {
        axios.get('/admin/console/users/role/all').then((resp) => {
            setRoles(resp.data)
        }).catch((err) => {
            toast.error("Impossible de récupérer les roles")
        })
    }


    const new_internal_user = () => {
        setOnEditInternalUser({onEdit: false})
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.getInstance(modal);
        modalInstance.open();
    }

    const edit_internal_user = (internalUser) => {
        setOnEditInternalUser({...internalUser, onEdit: true})
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.getInstance(modal);
        modalInstance.open();
    }

    const create_internal_user = () => {
        axios.put('/admin/console/users/internal', {
            internal_user: onEditInternalUser
        }).then((resp) => {
            setInternalUsers([...internalUsers, resp.data])
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'utilisateur")
        })
    }

    const update_internal_user = (internal_user) => {
        axios.patch(`/admin/console/users/internal/${internal_user.id}`, {
            internal_user: internal_user
        }).then((resp) => {
            setInternalUsers(internalUsers.map(user => user.id === internal_user.id ? resp.data : user))
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'utilisateur")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content container">
                <h3 className="center"
                    style={{marginTop: 20}}>{onEditInternalUser.onEdit ? "Éditer" : "Nouvel"} utilisateur</h3>
                <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Prénom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditInternalUser.firstname || ""}
                                   onChange={(e) => setOnEditInternalUser({
                                       ...onEditInternalUser,
                                       firstname: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Nom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditInternalUser.lastname || ""}
                                   onChange={(e) => setOnEditInternalUser({
                                       ...onEditInternalUser,
                                       lastname: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Mot de passe</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditInternalUser.password || ""}
                                   onChange={(e) => setOnEditInternalUser({
                                       ...onEditInternalUser,
                                       password: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Email</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditInternalUser.email || ""}
                                   onChange={(e) => setOnEditInternalUser({
                                       ...onEditInternalUser,
                                       email: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <ScopedElement scope={[Role.SUPER_ADMIN]}>
                        <div className="col s12 valign-wrapper" style={{gap: 20}}>
                            <span style={{width: 100}}>Role</span>
                            <select className="browser-default outlined" style={{width: "100%"}}
                                    value={roles.find(r => r.id === parseInt(onEditInternalUser.role_id))?.id || ""}
                                    onChange={(e) => setOnEditInternalUser({
                                        ...onEditInternalUser,
                                        role_id: e.target.value
                                    })}>
                                <option value="" disabled>Choisir un role</option>
                                {roles.map((role) => {
                                    return <option key={role.id}
                                                   value={role.id}>{InternalUserRole[role.name].name}</option>
                                })}
                            </select>
                        </div>
                    </ScopedElement>
                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                {onEditInternalUser.onEdit ?
                    <a className="btn btn-small blue darken-2 z-depth-0"
                       onClick={() => update_internal_user(onEditInternalUser)}>Valider</a>
                    :
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={create_internal_user}>Créer</a>}
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderInternalUser = (internalUser) => {
        return (
            <div className="collection-item transparent" key={internalUser.id} style={{display: "flex", width: "100%", gap: 10}}>
                <div style={{width: "100%", margin: 0, display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                    <div style={{width: 300, margin: 0}}>
                        <span>{Helper.formatName(internalUser.firstname, internalUser.lastname)}</span>
                    </div>
                    <div style={{width: 300, margin: 0}}>
                        <span>{internalUser.email}</span>
                    </div>
                    <div style={{width: 200, margin: 0}}>
                        <EditButton text={InternalUserRole[internalUser.role.name].name} type="select"
                                    disabled={!RightsStore.can_do(UserRights.ADMIN, UserPermissions.WRITE_ONLY)}
                                    options={roles.map((role) => InternalUserRole[role.name].name)}
                                    onValidation={v => update_internal_user({
                                        ...internalUser,
                                        role_id: roles.find(role => InternalUserRole[role.name].name === v).id
                                    })} minWidth="100%" flat/>
                    </div>
                </div>
                <RestrictedElement field={UserRights.ADMIN} permission={UserPermissions.WRITE_ONLY}>
                    <div style={{display: "flex", gap: 10, width: "fit-content"}}>
                        <a className="btn btn-small blue darken-2 z-depth-0 hide-on-small-only"
                           onClick={() => edit_internal_user(internalUser)}>Modifier</a>
                        <a className="btn btn-small blue darken-2 z-depth-0 hide-on-med-and-up"
                           onClick={() => edit_internal_user(internalUser)}><i
                            className="material-icons">edit</i></a>
                    </div>
                </RestrictedElement>
            </div>
        )
    }

    if (_.isEmpty(internalUsers)) {
        return <div style={{margin: 20}}>
            <Loader size="tiny" text=""/>
        </div>
    }

    const render = () => {
        if (RightsStore.can_do(UserRights.ADMIN, UserPermissions.READ_ONLY) === undefined) return <div id="full-height"
                                                                                                       style={{margin: 50}}>
            <Loader text=""/>
        </div>

        if (!RightsStore.can_do(UserRights.ADMIN, UserPermissions.READ_ONLY)) return <div id="full-height"
                                                                                          style={{margin: 50}}>
            <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
        </div>

        return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
            <h3 className="valign-wrapper" style={{margin: 20}}>
                <i className="material-icons left">group</i>
                Utilisateurs internes
            </h3>
            <div className="divider"/>
            <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div className="input-field outlined">
                        <input type="text" style={{height: 40}} placeholder={"Rechercher"} autoFocus
                               value={filter} onChange={e => setFilter(e.target.value)}/>
                    </div>
                    <RestrictedElement field={UserRights.ADMIN} permission={UserPermissions.WRITE_ONLY}>
                        <a className="btn btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
                           onClick={new_internal_user}>
                            <i className="material-icons left">add</i>
                            Nouveau
                        </a>
                    </RestrictedElement>
                </div>

                <div className="collection"
                     style={{borderRadius: 5, height: "100%", overflowY: "scroll"}}>
                    {internalUsers.filter(user => {
                        const str = `${user.firstname}${user.lastname}${user.email}`.toLocaleLowerCase()
                        return str.includes(filter.toLowerCase())
                    })
                        .sort((a,b) => a.lastname > b.lastname).map((internal_user) => {
                        return renderInternalUser(internal_user)
                    })}
                </div>
                <RestrictedElement field={UserRights.ADMIN} permission={UserPermissions.WRITE_ONLY}>
                    {renderModal()}
                </RestrictedElement>
            </div>
        </div>
    }

    return <Observer>
        {() => render()}
    </Observer>
}

export default InternalUsers;