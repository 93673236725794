import React, {useEffect, useState} from 'react';
import Pagination from "../../Shared/Pagination";
import axios from "axios";
import {RequestState} from "../../Shared/StateHelper";
import Helper from "../../Shared/Helper";
import useDebounceV2 from "../../Hooks/useDebounceV2";

const DISPLAYED_ITEMS = 20;
const AppointmentsToRead = () => {
    const [page, setPage] = useState(1);
    const debounedSetPage = useDebounceV2(setPage, 500);
    const [appointments, setAppointments] = useState([]);
    const [appointmentCount, setAppointmentCount] = useState(0);
    const [requestState, setRequestState] = useState(RequestState.IDLE);

    // const [patientFilter, setPatientFilter] = useState({});
    // useEffect(() => {
    //     setPage(1);
    // }, [patientFilter]);

    useEffect(() => {
        getAppointments();
    }, [page]);

    const getAppointments = () => {
        setRequestState(RequestState.LOADING);
        axios.get(`/api/admin/controle/appointments`, {
            params: {
                page: page,
                limit: DISPLAYED_ITEMS,
            }
        }).then(response => {
            setAppointments(response.data.appointments);
            setAppointmentCount(response.data.total);
            setRequestState(RequestState.SUCCESS);
        }).catch(error => {
            setRequestState(RequestState.ERROR);
            console.log(error);
        });

    }


    const renderPagination = () => {
        if(!appointmentCount) return null;
        return <div className="valign-wrapper">
            <a className="btn-small blue darken-2 z-depth-0" href={"/admin/controle_rdv/controler"}>
                <i className="material-icons left">remove_red_eye</i>
                Contrôler les {appointmentCount} prescriptions
            </a>
            <div style={{marginLeft: "auto"}}>
                <Pagination count={appointmentCount} limit={DISPLAYED_ITEMS} page={page}
                            onChange={(page) => debounedSetPage(page)}/>
            </div>
        </div>
    }

    const renderList = () => {
        if(requestState === RequestState.LOADING) return null;
        if(!appointmentCount) return <div className="center">Aucune prescription à relire</div>;
        return <table className="temeoo-table">
            <thead>
            <tr>
                <th style={{width: 50}}>Status</th>
                <th>Date</th>
                <th>Nom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th style={{width: 100}}></th>
            </tr>
            </thead>
            <tbody>
            {appointments.map(appointment => {
                return <tr className="highlighted">
                    <td>{appointment.is_alerted ? <i className="material-icons red-text valign-wrapper">warning</i> : null}</td>
                    <td>{Helper.formatDate(appointment.last_update)}</td>
                    <td>{Helper.formatName(appointment.firstname, appointment.lastname)}</td>
                    <td>{appointment.email}</td>
                    <td>{appointment.telephone}</td>
                    <td>
                        <a className={"btn-small blue darken-2 z-depth-0"} href={`/admin/controle_rdv/${appointment.id}`}>
                            Contrôler
                        </a>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    }

    const renderLoader = () => {
        if (requestState === RequestState.LOADING) {
            return <div className="progress">
                <div className="indeterminate"></div>
            </div>
        }
    }


    return <div>
        {renderLoader()}
        <div className="container">
            <h1 className="center">Contrôle des prescriptions</h1>
            {renderPagination()}
            {renderList(appointments)}
        </div>
    </div>

}

export default AppointmentsToRead;