import React, {useEffect} from 'react';
import {wrapper} from "../../I18n/i18n";
import AddShopButton from "./AddShopButton";
import axios from "axios";
import ShopLine from "./ShopLine";
import {RequestState} from "../../Shared/StateHelper";
import Error from "../../Shared/Error";
import Loader from "../../Shared/Loader";
import {observer} from "mobx-react";
import OpticianShopsStore from "./OpticianShopsStore";

const ShopsView = observer(() => {
    const {t} = wrapper("OPTICIAN_DASHBOARD.SHOPS")
    const [requestState, setRequestState] = React.useState(RequestState.IDLE)
    const [shops, setShops] = React.useState([]);

    useEffect(() => {
        getShops()
    }, []);

    const getShops = () => {
        setRequestState(RequestState.LOADING)
        axios.get("/opticians/shops")
            .then(response => {
                OpticianShopsStore.shops = response.data
                setRequestState(RequestState.SUCCESS)
            })
            .catch(_ => {
                setRequestState(RequestState.ERROR)
            })
    }

    const renderShops = () => {
        if (requestState === RequestState.ERROR) {
            return <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                paddingBottom: 200,
                gap: 20
            }}>
                <Error errorText={t("ERROR_LOADING_SHOPS")}/>
            </div>
        }

        if (requestState === RequestState.LOADING) {
            return <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                paddingBottom: 200,
                gap: 20
            }}>
                <Loader size="small" wide/>
            </div>
        }

        if (!OpticianShopsStore.shops.length) return <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingBottom: 200,
            gap: 20
        }}>
            <h3 className="center" style={{margin: 0}}>{t("NO_SHOPS")}</h3>
            <AddShopButton/>
        </div>

        return <div className="collection" style={{borderRadius: 5, flex: "1 1 0", overflowY: "scroll"}}>
            {OpticianShopsStore.shops.map(shop => <div className="collection-item transparent" key={shop.name}>
                    <ShopLine shop={shop}/>
                </div>
            )}
        </div>
    };

    return <div className="card" style={{display: "flex", flexDirection: "column", gap: 20}}>
        <h3 style={{margin: 10}}>{t("TITLE")}</h3>
        {renderShops()}
        {OpticianShopsStore.shops?.length ? <div style={{paddingInline: 40}}>
            <AddShopButton id="create_shop_button"/>
        </div> : null}

    </div>
})

export default ShopsView;