import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';


export default function UserPaiements(){
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [paiements, setPaiements] = useState([]);
    const params = useParams();
    const pid = params.id;
    useEffect(() => {
        loadData(page);
    }, []);
    const loadData = (page)=> {
        axios.get("/admin/console/bills/orthoptistes/"+pid+"/paiements?page="+page).then((data)=>{
            setPaiements(paiements.concat(data.data.data))
            if(data.data.data.length === 0){
                setHasMore(false);
            }
        })
        setPage(page + 1);
    }
    const fetchMoreData = () => {
        loadData(page);
    }
    const actions = (i) => {
        if(i.status === "SUCCESS"){
            return (<span></span>)
        }else{
            return (
                <span>
                    TODO
                </span>
            )
        }
    }
    return (
        <div>
            <InfiniteScroll
                dataLength={paiements.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4 className="center">Chargement...</h4>}
            >
                <table className="striped bordered">
                    <thead>
                    <tr>
                        <th> ID </th>
                        <th> STATUS </th>
                        <th> date création</th>
                        <th> Dates couverture</th>
                        <th> Montant orthoptiste</th>
                        <th> Montant ophtalmologiste</th>
                        <th> Montant temeoo</th>
                        <th> Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paiements.map((i, index) => (
                        <tr key={index}>
                            <td>{i.id}</td>
                            <td>
                                {i.status !== "SUCCESS" ? (
                                    <span>
                                        <i className="material-icons orange-text left">warning</i>{i.status}
                                    </span>
                                ) : (
                                    <span>
                                        <i className="material-icons green-text left">done</i>{i.status}
                                    </span>
                                )}

                            </td>
                            <td>{moment(i.created_at).format("DD/MM/YYYY")}</td>
                            <td>{moment(i.from).format("DD/MM")} - {moment(i.to).format("DD/MM")}</td>
                            <td>{i.totalOrthoptiste / 100.0} €</td>
                            <td>{i.total_ophtalmologiste / 100.0} €</td>
                            <td>{i.totalPlateforme / 100.0} €</td>
                            <td>
                                {actions(i)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>
    )
}
