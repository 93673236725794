import axios from "axios"
import {makeObservable, observable} from "mobx";
import {construct_filter} from "../Shared/FilterHelper";
import qs from "qs";

class PrescriptorStore {
    @observable prescriptor_id = null
    @observable patients = []
    @observable patient_count = 0
    @observable selected_ortho = []

    constructor() {
        makeObservable(this)
    }

    getPatients = (page = 1, nb_result = 10, filter = undefined) => {
        return axios.get(`/prescriptors/dashboard/patients`,
            {
                params: {page: page, nb_results: nb_result, filter: construct_filter(filter ?? "")},
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'brackets'})
                }
            })
            .then((response) => {
                this.patients = response.data.patients ?? []
                this.patient_count = response.data.count ?? 0
                this.selected_ortho = response.data.selected_data ?? []
            })
    }

    getPatient = (patient_id) => {
        return axios.get(`/prescriptors/dashboard/patient/${patient_id}`)
    }
}


let store = new PrescriptorStore
global.PrescriptorStore = store
export default store