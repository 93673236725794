import React, {useEffect, useState} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {t} from "../../../I18n/i18n";
import SmartTextArea from "../../../Shared/UiKit/SmartTextArea";
import PrescriptionStore from "../../../Appointments/PrescriptionStore";
import MesureStore from "../../../Appointments/Mesures/MesureStore";
import {observer} from "mobx-react";
import ControleStore from "../../../admin/Controle/ControleStore";

const CommentFundus = observer(({appointment_id}) => {
    const [comments, setComments] = useState({});
    const [saved, setSaved] = useState(false);

    useEffect(() => {
        get_comments()
    }, [])

    useEffect(() => {
        setSaved(false)
    }, [comments]);

    const get_comments = () => {
        axios.get(`/appointment/${appointment_id}/comments/fundus`).then(response => {
            setComments(response.data)
        })
    }

    const save_comment_fo = () => {
        axios.patch(`/appointment/${appointment_id}/comments/fundus`, {
            comment_fo_od: comments.comment_fo_od,
            comment_fo_og: comments.comment_fo_og
        })
            .then(_ => setSaved(true))
            .catch(_ => toast.error("Le commentaire FO n'a pas pu être enregistré"));
    };

    const renderButton = () => {
        const color = saved ? "green lighten-5 green-text text-darken-2" : "blue lighten-5 blue-text text-darken-2";
        const tooltip = saved ? "Commentaires enregistrés" : "Enregistrer les commentaires"
        return <a
            className={`btn-small ${color} z-depth-0`} title={tooltip} onClick={save_comment_fo}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', marginTop: 22}}
        >
            <i className="material-icons">{saved ? "check" : "save"}</i>
        </a>
    }

    return <div className="fr"
                style={{marginBlock: 10, alignItems: 'center', gap: 20, justifyContent: "center"}}>
        <p style={{marginTop: 40}}><b>Commentaire fond d'œil</b></p>
        <div className="fc center gap-0">
            <label>Droite</label>
            <SmartTextArea
                style={{width: "15vw", resize: 'vertical'}}
                value={comments.comment_fo_od}
                onChange={(e) => setComments(prevState => {
                    return {...prevState, comment_fo_od: e.target.value}
                })}
                shortcuts={ControleStore.shortcuts}
            />
        </div>
        <div className="fc center gap-0">
            <label>Gauche</label>
            <SmartTextArea
                style={{width: "15vw", resize: 'vertical'}}
                value={comments.comment_fo_og}
                onChange={(e) => setComments(prevState => {
                    return {...prevState, comment_fo_og: e.target.value}
                })}
                shortcuts={ControleStore.shortcuts}
            />
        </div>
        {renderButton()}
    </div>
})

export default CommentFundus;