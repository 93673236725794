import React, {useEffect} from 'react';
import InvoiceHeader from "../../Orthoptistes/Paiements/Invoice/InvoiceHeader";
import Helper from "../../Shared/Helper";
import InvoiceStamp from "../../Orthoptistes/Paiements/Invoice/InvoiceStamp";


const OpticianInvoiceDocument = ({optician, invoice}) => {
    useEffect(() => {
        window.status = "ready"
    }, []);

    const renderBody = () => {
        const total = invoice.amount / 100.0
        // first day of last month to last day of last month
        const date = new Date(invoice.date)
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)

        return <div style={{display: "flex", flexDirection: "column"}}>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Justificatif de paiement pour votre activité
                du {Helper.formatDate(firstDay)} au {Helper.formatDate(lastDay)}.
            </h5>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Montant total : {total.toFixed(2)}€
            </h5>
            <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                <h5 style={{fontWeight: "bold", margin: 0}}>Facture N°{invoice.id}</h5>
                <h5 style={{fontWeight: "bold", margin: 0, color: "#de4791"}}>
                    Émis le  : {Helper.formatDate(invoice.date)}
                </h5>
            </div>
            <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
            <div style={{backgroundColor: "#e2e2e2", padding: "0 10px"}}>
                <div style={{display: "flex", flexDirection: "column", padding: "20px 0", gap: 5}}>
                    {invoice.campaigns.map((campaign, index) => {
                        const amount = (campaign.campaign_amount ?? 0) / 100.0
                        return <div key={index} style={{display: "flex", justifyContent: "space-between"}}>
                            <h5 style={{margin: 0}}>{campaign.campaign_name}</h5>
                            <h5 style={{margin: 0}}>{amount.toFixed(2)}€</h5>
                        </div>
                    })}
                </div>
            </div>
            <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
        </div>
    }

    return <div style={{padding: 20, display: "flex", justifyContent: "center"}}>
        <div className="card" style={{minHeight: "297mm", width: "210mm"}}>
            <div className="pdf-document" style={{display: "flex", flexDirection: "column"}}>
                <InvoiceHeader/>
                <div style={{display: "flex", margin: "40px 0"}}>
                    <h5 style={{margin: 0}}><b>Pour :</b> {optician.name}</h5>
                </div>
                <div style={{margin: "100px 0 0 0"}}>
                    {renderBody()}
                </div>
                <div id="fixed-div" style={{position: "fixed", top: "160px", left: "800px", opacity: 0.3}}>
                    <InvoiceStamp status={invoice.status}/>
                </div>
            </div>
        </div>
    </div>
}

export default OpticianInvoiceDocument;