import React, {useEffect} from 'react'

const HelpFAB = ({url, id}) => {
    useEffect(() => {
        const elems = document.getElementById(id);
        const instance = M.FloatingActionButton.init(elems, {});
        return () => instance.destroy()
    }, []);

    return <div className="fixed-action-btn" id={id}>
        <a className="btn-floating btn-large blue darken-2" href={url} title="Voir le manuel d'explication" target="_blank">
            <i className="large material-icons white-text">question_mark</i>
        </a>
    </div>
}

HelpFAB.defaultProps = {
    id: "help-fab"
}

export default HelpFAB