import {IS_OPTICIAN} from "../Shared/Helper";
import React, {useState} from "react";
import axios from "axios";
import Loader from "../Shared/Loader";
import {toast} from "react-toastify";
import {ACCESS_BY, ACCESS_PATIENT_STRING} from "./Patient_Access_Strings";
import InputText from "../Shared/UiKit/InputText";
import Pincode from "../Shared/Pincode/Pincode";
import TurnstileWidget from "../Shared/Security/TurnstileWidget";

const PIN_COUNT = 6
const _ = require('lodash');


export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            access_by: ACCESS_BY.code.type,
            input: "",
            pin_numbers: "",
            office_list: [],
            office_title: "",
            selected_office: null,
            display_office_table: true,
            message: "",
            waiting_token: true,
            captcha_token: "",
            turnstile_token: null,
            need_patient_id: false,
            patient_id: null,
            patient_list: [],
            email_sent: false,
            phase: 0,
            question_phase_years: [],
            question_phase_names: []
        }
        // check params key in url
        let params = new URLSearchParams(window.location.search)
        if (params.has("key") && params.get("key").length === PIN_COUNT) {
            this.state.pin_numbers = params.get("key")
        }
    }

    handleAccessType = (type) => {
        if (type !== this.state.access_by) this.setState({
            access_by: type,
            office_title: "",
            selected_office: null,
            pin_numbers: "",
            email: "",
            need_patient_id: false,
            patient_id: null
        })
    }

    isRequestValid = () => {
        if (this.state.access_by === "code") {
            let code = this.state.pin_numbers
            //debugger;
            if (code.length !== PIN_COUNT) return false
        } else {
            if (!this.state.input || (this.state.need_patient_id && !this.state.patient_id)) return false
        }
        return true
    }

    onTokenAcquisition = (token, turnstile) => {
        this.setState({turnstile_token: token, waiting_token: false})
    }

    login_patient = () => {
        if (!this.state.turnstile_token) return
        const token = this.state.turnstile_token

        let params = {
            access_by: this.state.access_by,
            "cf-turnstile-response": token
        }

        if (this.state.access_by === "code") {
            params["code"] = this.state.pin_numbers
        } else {
            params["patient_id"] = this.state.patient_id
        }

        axios.post("/patient_login", params)
            .then((response) => {
                if (response.data.message) {
                    toast.success((ACCESS_PATIENT_STRING["connexion"][response.data.message]))
                    this.setState({email_sent: true})
                } else {
                    this.setState({
                        phase: "question",
                        question_phase_years: response.data.birthyears,
                        question_phase_names: response.data.names
                    })
                }
            })
            .catch((error) => {
                toast.error(ACCESS_PATIENT_STRING["error"][error.response.data.message] ?? "Impossible de se connecter")
            })
    }

    send_recovery_message = () => {
        axios.get("/recovery/sms", {params: {phone_number: this.state.input}})
            .then((_) => null)
            .catch((error) => {
                if(error.response.status === 429) toast.error("Un message a déjà été envoyé, veuillez patienter")
            })
    }

    handleValueChanges = (value) => {
        value = value.replace(/(\s|\W|_)/g, "")
        if(value.length > PIN_COUNT) return
        this.setState({pin_numbers: value}, () => {
            if (this.state.pin_numbers.length === PIN_COUNT) {
                this.login_patient()
            }
        })
    }

    renderPinLogin = () => {
        return <div className="center" style={{margin: "50px 0 20px 0"}}>
            <div className="row">
                <div className="col s12" style={{display: "flex", justifyContent: "center"}}>
                    {/*<Pincode*/}
                    {/*    onChange={this.handleValueChanges}*/}
                    {/*    initialValue={this.state.pin_numbers}*/}
                    {/*/>*/}
                    <div className="input-field outlined pincode">
                        <input id="pincode" type="text" className="validate"
                               value={this.state.pin_numbers}
                               onChange={(e) => this.handleValueChanges(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    }

    renderEmailLogin = () => {
        return <div>
            <div style={{
                margin: "50px 0 20px 0", display: "flex",
                flexDirection: "column", justifyContent: "center",
                alignItems: "center", gap: 10
            }}
            >
                <span>Saisissez votre numéro de téléphone</span>
                <InputText width={300} value={this.state.input || ""}
                           onChange={(e) => this.setState({input: e.target.value})}/>
            </div>
        </div>
    }

    render() {
        return (
            <div className="container">
                <h1 className="center" style={{marginBlock: '40px 10px'}}>Récupérez vos prescriptions </h1>
                <h5 className="center-align red-text text-darken-2 hide-on-large-only">
                    Attention : nous rencontrons des soucis sur certains téléphones mobiles et tablettes. Si vous ne
                    parvenez pas à
                    vous connecter, merci d'essayer de vous connecter avec un ordinateur.
                </h5>
                {this.state.phase === 0 ? (
                    <div>
                        <h5>
                            Sur cette plateforme, vous pourrez récupérer vos prescriptions effectuées par
                            votre {IS_OPTICIAN ? `opticien d'Expert Vision` : `orthoptiste`}.<br/>
                            Pour accéder à celles-ci, vous pouvez, au choix:
                        </h5>
                        {Object.values(ACCESS_BY).map((access_type, index) => {
                            return (
                                <div key={index}>
                                    <div className="row">
                                        <div className="col s12">
                                            <label>
                                                <input name="access_by" type="radio"
                                                       defaultChecked={access_type.default_checked}
                                                       onChange={() => this.handleAccessType(access_type.type)}
                                                       disabled={access_type.disabled}
                                                />
                                                <span
                                                    className={access_type.disabled ? "" : "black-text"}>{access_type.text}</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                            )
                        })}
                        <div>
                            <div className="row">
                                <div className="col s12">
                                    {this.state.access_by === "code" ? this.renderPinLogin() : this.renderEmailLogin()}
                                </div>
                            </div>
                            <div className="center">
                                <br/>
                                {this.state.message ?
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "5px",
                                            width: "fit-content"
                                        }}
                                        className={`white-text ${this.state.message === "email sent" ? "green" : "red lighten-2"} center"`}>
                                        {this.state.message}
                                    </div> : null}
                                <div className="fr" style={{justifyContent: "center"}}>
                                    <a disabled={!this.isRequestValid() || this.state.waiting_token || (this.state.access_by === ACCESS_BY.email.type && this.state.email_sent)}
                                       className="btn blue darken-2 center z-depth-0"
                                       onClick={this.state.access_by === ACCESS_BY.email.type ? !this.state.patient_id ? this.send_recovery_message : this.login_patient : this.login_patient}>
                                        {this.state.access_by === ACCESS_BY.email.type ? "Envoyer un sms de récupération" : "Se connecter"}
                                    </a>
                                    {this.state.waiting_token ? <Loader show_text={false} size="small" style={{width: 50, alignSelf: "center", margin: 0}}/> : null}
                                </div>
                                <div style={{marginBlock: 10}}>
                                    <TurnstileWidget onTokenAcquisition={this.onTokenAcquisition}/>
                                </div>
                            </div>
                        </div>
                        <h6 className="center" style={{fontStyle: "italic"}}>
                            Si vous rencontrez des difficultés pour vous connecter à votre espace patient, merci de
                            contacter
                            le·la praticien·ne de santé qui a effectué votre examen.
                        </h6>
                        <h6 className="center" style={{fontStyle: "italic"}}>
                            En cas d'impossibilité, vous pouvez&nbsp;
                            <a style={{fontSize: "1em"}} target={"_blank"}
                               href="https://temeoo.atlassian.net/servicedesk/customer/portal/1">
                                contacter le support
                            </a>
                            &nbsp;pour obtenir de l'aide, les délais peuvent être plus longs.
                        </h6>
                    </div>


                ) : (
                    <QuestionPhase
                        years={this.state.question_phase_years}
                        names={this.state.question_phase_names}
                    />
                )}

            </div>
        )
    }
}
const QuestionPhase = ({years, names}) => {
    //extarct url params year and name
    const params = new URLSearchParams(window.location.search)

    let initial_year = params.get("year")
    const initial_name = params.get("name")

    const [year, setYear] = useState(initial_year || null)
    const [name, setName] = useState(initial_name || null)

    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content

    const login = () => {
        axios.post("/patient_login_phase2", {
            year: year === "0" ? null : year,
            name: name === "0" ? null : name
        }).then((res) => {
            window.location.href = res.data.redirect_url
        }).catch((err) => {
                toast.error("Réponse invalide")
            }
        )

    }
    return (
        <div>
            <h5>
                Pour accéder à vos prescriptions, merci de répondre à la question suivante:
            </h5>
            <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                <div className="row" style={{gap: 20}}>
                    <div className="col s12 l6">
                        <p>Quelle est le prénom du patient concerné ? </p>
                        <div className="input-field">
                            <select className="browser-default" value={name} onChange={(e) => setName(e.target.value)}>
                                <option disabled selected value={""}>Choisir un nom</option>
                                <option value={0}>Aucun des autres noms</option>
                                {names.map((name, index) => {
                                    return <option key={index} value={name}>{Helper.toCamelCase(name)}</option>
                                })}
                            </select>

                        </div>
                    </div>
                    <div className="col s12 l6">
                        <p>Quelle est l'année de naissance de ce patient ? </p>
                        <div className="input-field">
                            <select className="browser-default" value={year} onChange={(e) => setYear(e.target.value)}>
                                <option disabled selected value={""}>Choisir une année</option>
                                <option value={0}>Aucune des autres années</option>
                                {years.map((year, index) => {
                                        return <option key={index} value={year}>{year}</option>
                                    }
                                )}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="center">
                    <a disabled={year === null || name === null} className="btn blue darken-2 center z-depth-0"
                       onClick={login}>
                        Valider
                    </a>
                </div>
            </div>
        </div>


    )
}