import React, {useEffect, useState} from "react";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import axios from "axios";
import EditButton from "../../../Shared/EditButton";

const SubscriptionSettings = ({orthoptist_id}) => {
    const [settings, setSettings] = useState({});

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        axios.get(`/admin/console/users/orthoptist/${orthoptist_id}/subscription_settings`).then((response) => {
            setSettings(response.data.settings);
        })
    }

    const updateSettings = (settings) => {
        axios.patch(`/admin/console/users/orthoptist/${orthoptist_id}/subscription_settings`, settings).then((response) => {
            setSettings(response.data.settings);
        })
    }

    return <BorderedDisplay text={"Abonnement annuel"} containerStyle={{borderRadius: 10}}
                            style={{paddingInline: 20, marginBottom: 20}}>
        <div className="fc">
            <div className="valign-wrapper" style={{justifyContent: "space-between", marginRight: 10}}>
                Dernière facturation
                {settings.subscription_start ?
                    <span>le {Helper.formatDate(settings.subscription_start)}</span>
                    :
                    <span style={{fontStyle: "italic"}}>Aucune facturation à ce jour</span>
                }
            </div>
            <div className="valign-wrapper" style={{justifyContent: "space-between", marginRight: 10}}>
                Montant prochaine facturation
                <span>{((settings.subscription_price ?? 0) + (settings.free_act_price ?? 0) * (settings.free_acts_amount ?? 0)) / 100.0} €</span>
            </div>
            <div className="valign-wrapper" style={{justifyContent: "space-between"}}>
                Montant abonnement
                <EditButton text={(settings.subscription_price ?? 0) / 100} suffix="€"
                            onValidation={(v) => updateSettings({...settings, subscription_price: v * 100.0})}
                            filter={(e) => e.target.value.match(/^-?\d*[.,]?\d{0,2}$/g)}
                />
            </div>
            <div className="valign-wrapper" style={{justifyContent: "space-between", marginRight: 10}}>
                Nombre d'examens gratuits restants
                <span>{settings.free_acts_remaining}</span>
            </div>
            <div className="valign-wrapper" style={{justifyContent: "space-between"}}>
                Prix initial examen gratuit
                <EditButton text={(settings.free_act_price ?? 0) / 100} suffix="€"
                            onValidation={(v) => updateSettings({...settings, free_act_price: v * 100.0})}
                            filter={(e) => e.target.value.match(/^-?\d*[.,]?\d{0,2}$/g)}
                />
            </div>
            <div className="valign-wrapper" style={{justifyContent: "space-between"}}>
                Nb examens gratuits annuels
                <EditButton text={settings.free_acts_amount ?? 0} suffix="€"
                            onValidation={(v) => updateSettings({...settings, free_acts_amount: v})}
                            filter={(e) => e.target.value.match(/^-?\d*[.,]?\d{0,2}$/g)}
                />
            </div>

        </div>

    </BorderedDisplay>
}

export default SubscriptionSettings;