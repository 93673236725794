import React, {Component} from "react";
import InputText from "../../../../Shared/UiKit/InputText";
import JustificationSelect from "../JustificationSelect";
import ChipsInput from "../../../../Shared/ChipsInput";
import FontSelect from "../FontSelect";
import MarkdownEditor from "../../../../Shared/UiKit/MarkdownEditor";

const _ = require('lodash');
export default class EditInput extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.init = this.init.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onDelete = this.onDelete.bind(this)

        this.state = {
            expanded: false,
            showOptions: false,
            input: {
                justify: "",
                weight: "",
                style: "",
                size: ""
            }
        }
    }

    componentDidMount() {
        this.setState({input: this.props.input})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({input: this.props.input})
        }
    }

    toggle(e) {
        e.preventDefault()
        this.setState({expended: !this.state.expended})
    }

    init() {
        const types = {
            "TEXT": {icon: "text", render: this.renderTextInput()},
            "SELECT": {icon: "", render: this.renderSelectInput()},
            "IMAGE": {icon: "", render: this.renderImageInput()},
        }
        return types[this.state.input.type] || null
    }

    changeProperty(obj) {
        this.setState({input: Object.assign({}, this.state.input, obj)}, this.onChange)
    }

    onChange() {
        this.props.onChange(this.state.input)
    }

    onDelete() {
        this.props.onDelete(this.state.input)
    }

    renderTextInput() {
        return (
            <div style={{width: "100%"}}>
                <div className="card z-depth-0" style={{padding: 0}}>
                    <div className="card-content" style={{paddingBlock: 5}}>
                        <div className="valign-wrapper" style={{width: "100%", justifyContent: "center", gap: 10}}>
                            <div className="valign-wrapper"
                                 style={{width: "100%", justifyContent: "center", gap: 10, flexWrap: "wrap"}}>
                                <FontSelect
                                    size={this.state.input.size} weight={this.state.input.weight}
                                    style={this.state.input.style}
                                    onWeightChange={(v) => this.changeProperty({weight: v})}
                                    onSizeChange={(v) => this.changeProperty({size: v})}
                                    onStyleChange={(v) => this.changeProperty({style: v})}
                                />
                                <JustificationSelect
                                    justify={this.state.input.justify}
                                    onChange={(v) => this.changeProperty({justify: v})}
                                />
                            </div>
                            <a className="btn btn-flat activator transparent"><i
                                className="material-icons">more_vert</i></a>
                        </div>
                        <h6>Titre</h6>
                        <InputText value={this.state.input.name}
                                   onChange={(e) => this.changeProperty({name: e.target.value})} width="100%"/>
                        <h6>Texte</h6>
                        <InputText value={this.state.input.text}
                                   onChange={(e) => this.changeProperty({text: e.target.value})} width="100%"/>
                        <h6>Texte par défaut</h6>
                        <MarkdownEditor defaultValue={this.state.input.default_text}
                                        style={{fontSize: "1.2em"}}
                                        onChange={(v) => this.changeProperty({default_text: v})} noImages/>
                    </div>
                    <div className="card-reveal" style={{backgroundColor: "lightgray"}}>
                        <span className="card-title" style={{fontSize: "1.2em", marginBottom: 20}}>Zone de texte<i
                            className="material-icons right">close</i></span>
                        <div className="valign-wrapper">
                            <h5>Largeur</h5>
                            <div style={{padding: "0 1vw", width: "100%"}}>
                                <input type="range" id={`input_width_range_${this.state.input.id}}`} min="100" max="650"
                                       onChange={(event) => this.changeProperty({width: event.target.value})}
                                       value={this.state.input.width || 200}
                                       style={{
                                           borderRadius: "15px",
                                           backgroundColor: "#c2c0c2",
                                           borderColor: "transparent"
                                       }}/>
                            </div>
                        </div>
                        <div className="valign-wrapper">
                            <h5>Hauteur</h5>
                            <div style={{padding: "0 1vw", width: "100%"}}>
                                <input type="range" id={`input_width_range_${this.state.input.id}}`} min="40" max="500"
                                       onChange={(event) => this.changeProperty({height: event.target.value})}
                                       value={this.state.input.height || 40}
                                       style={{
                                           borderRadius: "15px",
                                           backgroundColor: "#c2c0c2",
                                           borderColor: "transparent"
                                       }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSelectInput() {
        return (
            <div style={{width: "100%"}}>
                <div className="valign-wrapper" style={{width: "100%", justifyContent: "center"}}>
                    <FontSelect
                        size={this.state.input.size} weight={this.state.input.weight} style={this.state.input.style}
                        onWeightChange={(v) => this.changeProperty({weight: v})}
                        onSizeChange={(v) => this.changeProperty({size: v})}
                        onStyleChange={(v) => this.changeProperty({style: v})}
                    />
                    <JustificationSelect
                        justify={this.state.input.justify}
                        onChange={(v) => this.changeProperty({justify: v})}
                    />
                </div>
                <h6>Titre</h6>
                <InputText value={this.state.input.name} onChange={(e) => this.changeProperty({name: e.target.value})}
                           width="100%"/>
                <h6>Texte</h6>
                <InputText value={this.state.input.text} onChange={(e) => this.changeProperty({text: e.target.value})}
                           width="100%"/>
                <h6>Options</h6>
                <ChipsInput options={this.state.input.options} name={`select_input_${this.state.input.id}`}
                            onChange={(options) => this.changeProperty({options: options})}
                />
            </div>
        )
    }

    renderImageInput() {
        const width = this.state.input.width
        return (
            <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 20}}>
                <h6>Titre</h6>
                <InputText value={this.state.input.name} onChange={(e) => this.changeProperty({name: e.target.value})}
                           width="100%"/>
                <div style={{display: "flex",justifyContent: "center"}}>
                    <JustificationSelect
                        justify={this.state.input.justify}
                        onChange={(v) => this.changeProperty({justify: v})}
                    />
                </div>
                <div className="valign-wrapper container" style={{display: "flex", gap: 20, justifyContent: "center"}}>
                    <label>Largeur</label>
                    <input type="range" min="10" max="100" value={width || 100}
                           onChange={(e) => this.changeProperty({width: e.target.value})}
                           style={{
                               borderRadius: "15px",
                               backgroundColor: "#c2c0c2",
                               borderColor: "transparent"
                           }}
                    />
                </div>
            </div>
        )
    }

    render() {
        const input = this.init()
        if (input !== null) {
            return (
                <div>
                    <div>
                        {/*HEAD*/}
                        <div>
                            <a href="#"
                               onClick={(e) => this.toggle(e)}
                               onMouseEnter={() => this.setState({showOptions: true})}
                               onMouseLeave={() => this.setState({showOptions: false})}
                            >
                                <div className="black-text valign-wrapper"
                                     style={{display: "flex", padding: "1vh 1vw 1vh 1vw"}}>
                                    <h5 style={{margin: 0}}>{this.state.input.name}</h5>
                                    <div style={{marginLeft: "auto"}}>
                                        <div onClick={this.onDelete}
                                             className={`btn-floating btn-small waves-light z-depth-0 transparent ${this.state.showOptions ? "" : "hide"}`}>
                                            <i className="material-icons red-text">delete</i>
                                        </div>
                                        <div className="btn-floating btn-small waves-light z-depth-0 transparent"
                                             style={{transition: "all 1s"}}>
                                            <i className="material-icons grey-text"
                                               style={{
                                                   transform: `rotate(${this.state.expended ? "90deg" : "0"})`,
                                                   transition: "all 0.5s"
                                               }}>
                                                chevron_right
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {this.state.expended ? (
                            <div className="black-text" style={{display: "flex", padding: 10}}>
                                {input.render}
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        } else return null
    }
}