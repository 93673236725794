import React, {useEffect} from "react";

const ImagePreview = ({id, src, onRemove, style}) => {
    useEffect(() => {
        const images = document.getElementById(id)
        const instance = M.Materialbox.init(images, {})

        return () => instance.destroy()
    }, []);

    return <div style={{position: "relative", width: "100%", display: "flex", justifyContent: "center"}}>
        <img id={id} className="materialboxed responsive-img" src={src} alt={"Image"} placeholder={"Image"}
             style={{...style}}/>
        <i className="material-icons tiny white-text"
           style={{position: "absolute", top: 4, left: 4}}>
            photo_camera
        </i>
        <i className={`material-icons tiny red-text clickable`}
           style={{position: "absolute", top: 4, right: 4}}
           onClick={onRemove}>
            cancel
        </i>
    </div>
}

export default ImagePreview