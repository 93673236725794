import React from 'react';
import Helper from "../../../Shared/Helper";
import {ItemReference} from "../../../Shared/Enums/Enums";

const _ = require('lodash');
const InvoiceBody = ({invoice, transactions}) => {
    const total_plateforme = invoice.v2 ? (invoice.totalPlateforme) / 100 : (invoice.totalPlateforme - invoice.total_ophtalmologiste) / 100
    const fixed_amount = invoice.v2 ? (invoice.synthese.fixed_amount) / 100 : 0
    const variable_amount = invoice.v2 ? (invoice.synthese.variable_amount) / 100 : 0

    const total_ttc = total_plateforme
    const total_ht = (total_plateforme - fixed_amount - variable_amount) / 1.2
    const fixed_ht = fixed_amount / 1.2
    const variable_ht = variable_amount / 1.2
    const tva = total_ttc - total_ht - fixed_ht - variable_ht
    const date = Helper.formatDate(invoice.created_at, "DD/MM/YYYY")

    const renderTransactions = () => {
        const renderItem = (title, price) => {
            return <div style={{display: "flex", justifyContent: "space-between"}}>
                <h5 style={{margin: 0}}>{title}</h5>
                <h5 style={{margin: 0}}>{price.toFixed(2)}€</h5>
            </div>
        }
        if (_.isEmpty(transactions)) return renderItem("Facture HT", total_ht)

        return transactions.sort((a, b) => ItemReference[a.item_reference].code - ItemReference[b.item_reference].code)
            .map(transaction => {
                const price = transaction.price / 120.0 // 20% TVA cent to euro
                return renderItem(ItemReference[transaction.item_reference].name, price)
            })
    }

    return <div style={{display: "flex", flexDirection: "column"}}>
        <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
            Justificatif de paiement pour votre activité
            du {Helper.formatDate(invoice.from)} au {Helper.formatDate(invoice.to)}.
        </h5>
        <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
            Montant prélevé : {total_ttc.toFixed(2)}€
        </h5>
        <div style={{display: "inline-flex", justifyContent: "space-between"}}>
            <h5 style={{fontWeight: "bold", margin: 0}}>Facture N°{invoice.id}</h5>
            <h5 style={{fontWeight: "bold", margin: 0, color: "#de4791"}}>Émis le {date}</h5>
        </div>
        <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
        <div style={{backgroundColor: "#e2e2e2", padding: "0 10px"}}>
            <div style={{display: "flex", flexDirection: "column", padding: "20px 0", gap: 5}}>
                {renderTransactions()}
                {fixed_ht ? (<div style={{display: "flex", justifyContent: "space-between"}}>
                    <h5 style={{margin: 0}}>Charges</h5>
                    <h5 style={{margin: 0}}>{fixed_ht.toFixed(2)}€</h5>
                </div>) : null}
                {variable_ht ? (<div style={{display: "flex", justifyContent: "space-between"}}>
                    <h5 style={{margin: 0}}>Variable</h5>
                    <h5 style={{margin: 0}}>{variable_ht.toFixed(2)}€</h5>
                </div>) : null}
                <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                    <h5 style={{margin: 0}}>TVA (20%)</h5>
                    <h5 style={{margin: 0}}>{tva.toFixed(2)}€</h5>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", color: "#de4791"}}>
                    <h5 style={{margin: 0}}><b>Total à payer TTC *</b></h5>
                    <h5 style={{margin: 0}}><b>{total_ttc.toFixed(2)}€</b></h5>
                </div>
                <h6 style={{fontStyle: "italic"}}>* Cette somme sera automatiquement prélevée sur votre compte</h6>
            </div>
        </div>
        <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
    </div>
}

export default InvoiceBody;