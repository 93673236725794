import React from 'react';
import {Role} from "../../Shared/Enums/Scope";
import ScopedElement from "../../Shared/ScopedElement";

const QuickAccess = () => {
    return <ScopedElement scope={[Role.ORTHOPTIST]}>
        <div className="card" style={{padding: 0}}>
            <div className="card-content valign-wrapper fr">
                <h4 style={{margin: 0}}>Accès rapide</h4>
                <div className="fr" style={{gap: 0, marginLeft: "auto"}}>
                    <a className={`btn btn-small blue darken-2 z-depth-0`} target="_blank"
                       href={"https://wps-cps.dmp.monespacesante.fr/"}
                       style={{marginLeft: "auto", paddingInline: 5, fontWeight: 600}}>
                        DMP
                    </a>
                    <a className={`btn btn-small blue darken-2 z-depth-0`} target="_blank"
                       href={"https://authentification.mssante.fr/verificationpsc?site=fromInviterConnexionOIDC&site1=invConfreres&redirection=webmail&method=connexionProSante&urlFrom=https://authentification.mssante.fr/web/mss/connexion?p_p_id=msslogin_WAR_mssloginportlet_INSTANCE_Hl3E&p_p_lifecycle=1&p_p_state=normal"}
                       style={{marginLeft: "auto", paddingInline: 5, fontWeight: 600}}>
                        Mailiz
                    </a>
                </div>
            </div>
        </div>
    </ScopedElement>
}

export default QuickAccess;