import React from 'react'
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import PhoneStore from "./PhoneStore";
import axios from "axios";
import SimpleCamera from "../../Orthoptistes/Phone/SimpleCamera";

const TakePicture = observer(() => {
    const nav = useNavigate()
    const [image, setImage] = React.useState(null)

    const renderCamera = () => <SimpleCamera onImageCapture={(img) => setImage(img)}/>

    const savePicture = () => {
        PhoneStore.savePicture(image).then(() => {
            setImage(null)
        }).catch((_) => {
            console.log("Erreur lors de l'envoi de l'image")
        })
    }
    const renderImage = () => {
        return <div className="center">
            <img src={image} alt={"Image prise"}/>
        </div>

    }

    const renderBody = () => {
        if (image) return renderImage()
        else return renderCamera()
    }

    const renderPreviewButtons = () => {
        if(!image) return null
        return <>
            <a className="btn-small green darken-2 z-depth-0" style={{marginLeft: "auto"}}
               onClick={() => savePicture()}>
                <i className="material-icons hide-on-med-and-down left">done</i>
                <i className="material-icons hide-on-large-only">done</i>
                <span className="hide-on-med-and-down">Envoyer</span>
            </a>
            <a className="btn-small red darken-2 z-depth-0" onClick={() => setImage(null)}>
                <i className="material-icons hide-on-med-and-down left">close</i>
                <i className="material-icons hide-on-large-only">close</i>
                <span className="hide-on-med-and-down">Prendre une autre photo</span>
            </a>
        </>
    }

    return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: "1rem", padding: 10}}>
        <div className="valign-wrapper">
            <a className="btn-small blue darken-2 z-depth-0" onClick={() => nav("/")}>
                <i className="material-icons hide-on-med-and-down left">chevron_left</i>
                <i className="material-icons hide-on-large-only">chevron_left</i>
                <span className="hide-on-med-and-down">Retour</span>
            </a>
            <div style={{flex: 1}}>
                <h5 className="center" style={{fontWeight: 600}}>PRISE D'IMAGE</h5>
            </div>
            {renderPreviewButtons()}
        </div>
        <div>
            {renderBody()}
        </div>
    </div>

})

export default TakePicture