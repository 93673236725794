import React, {useEffect, useState} from "react";
import MesureStore from "../Appointments/Mesures/MesureStore";
import axios from "axios";
import Tooltip from "../Shared/Tootip";
import Helper from "../Shared/Helper";
import BorderedDisplay from "../Shared/UiKit/BorderedDisplay";
import ChainedActsRequest from "./ChainedActs/ChainedActsRequest";
import {Act, ActNature} from "../Shared/Enums/Enums";
import {Observer} from "mobx-react";

const _ = require("lodash");
export default function Teletransmission(props) {
    const [delayed, setDelayed] = useState(false);
    const [delayedAppointments, setDelayedAppointments] = useState([]);

    useEffect(() => {
        getDelayedAppointements()
    }, [])

    useEffect(() => {
    }, [props.acts])

    const getDelayedAppointements = () => {
        const patient_id = MesureStore.patient_id
        axios.get("/api/appointment/delay/list", {
            params: {
                patient_id: patient_id,
                appointment_id: MesureStore.appointement_id
            }
        }).then(resp => {
            setDelayedAppointments(resp.data)
        })
    }

    // Returns a string with last delayed appointments dates
    const delayedAppointmentsDetails = () => {
        if (delayedAppointments.length === 1) {
            return `L'examen' du ${Helper.formatDate(delayedAppointments[0].start)} est en attente de télétransmission.`
        }
        let details = "Les examens suivants sont en attente de télétransmission :\n"
        delayedAppointments.forEach((appointment, index) => {
            details += `${Helper.formatDate(appointment.start)}\n`
        })
        return details
    }

    const renderAppointmentStatus = () => {
        const status = MesureStore.appointment_teletransmission_status
        switch (status) {
            case "WAITING_FOR_ACTION":
                return <h6 className="center" style={{fontStyle: "italic"}}>
                    Cet examen est en attente de télétransmission
                </h6>
            default:
                return <h6 className="valign-wrapper" style={{fontStyle: "italic", justifyContent: "center"}}>
                    <i className="material-icons green-text">done</i>&nbsp;Cet examen a été télétransmis
                </h6>
        }
    }

    const renderChainedActsRequest = () => {
        if (_.isEmpty(props.acts)) return null

        if([Act.AMY8_AO, Act.AMY15_AO].includes(props.acts[0].titre)){
            return <div style={{paddingBottom: "10px"}}><ChainedActsRequest/></div>
        }
        if (props.acts[0].nature !== ActNature.ORTHOPTIC_EVALUATION.name) return null

        return <div style={{paddingBottom: "10px"}}><ChainedActsRequest/></div>
    }

    return <Observer>
        {() => <BorderedDisplay text="Télétransmission">
            <div className="valign-wrapper">
                <h5>Les fonctionnalités de télétransmission Méd'OC sont dans la barre latérale</h5>
                <i className="material-icons">north_east</i>
            </div>
            {renderChainedActsRequest()}
            {MesureStore.isAppointmentInChain(props.acts[0].id) && MesureStore.appointment_teletransmission_status === "WAITING_FOR_ACTION" ? (
                <div>
                    <div className={`${delayedAppointments.length === 0 ? "hide" : ""}`}>
                        {MesureStore.selectedChain && MesureStore.selectedChain.nb_remaining === 1 ? (
                            <h6 className="red-text" style={{fontWeight: "bolder"}}>
                                Il s'agit du dernier examen de la série, n'oubliez pas la télétransmission
                            </h6>
                        ) : (<div/>)}
                        {delayedAppointments.length === 1 ? (
                                <Tooltip id="delayed_appt_tooltip" text={delayedAppointmentsDetails()} position="top">
                                    <h6 className="center" style={{fontStyle: "italic"}}>
                                        {`Un rendez-vous antérieur est en attente de télétransmission`}
                                    </h6>
                                </Tooltip>
                            ) :
                            (
                                <Tooltip id="delayed_appt_tooltip" text={delayedAppointmentsDetails()} position="top">
                                    <h6 className="center" style={{fontStyle: "italic"}}>
                                        {`${delayedAppointments.length} rendez-vous antérieurs sont en attente de télétransmission`}
                                    </h6>
                                </Tooltip>
                            )
                        }
                    </div>
                    <div>
                        {renderAppointmentStatus()}
                    </div>
                </div>
            ) : (<div/>)}
        </BorderedDisplay>}
    </Observer>
}