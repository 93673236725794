import React from "react"
import {CENTRE_OPHTALMO_URL} from "../Shared/Helper";
import Logo from "../Shared/UiKit/Logo";

class PrintAcces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txt: "",
            key: ""
        };
    }

    render() {
        return <div style={{display: "flex", flexDirection: "column", marginLeft: 40, marginRight: 40}}>
            <div className="center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Logo text style={{maxWidth: 240}}/>
            </div>
            <p style={{marginTop: 70}}>{this.props.patient.prenom} {this.props.patient.nom},</p>
            <p> Vous avez effectué un contrôle de vue chez un orthoptiste collaborant avec Temeoo dans le cadre d'un
                protocole de télémédecine. Votre dossier va être examiné par un ophtalmologue sous 8 jours. Vos
                résultats seront alors disponible sur ce lien : <b
                    style={{fontSize: "18px"}}>{CENTRE_OPHTALMO_URL}</b></p>
            <div style={{display: "flex"}}>
                <h2 style={{marginRight: 20}}>Votre code patient :</h2>
                {this.props.access_key.split("").map((char, index) => (
                    <h2 key={index}
                        style={{textDecoration: char === char.toUpperCase() && Number.isNaN(parseInt(char)) ? "underline" : "none"}}>
                        {char}
                    </h2>
                ))}
            </div>
            <h5 style={{fontStyle: "italic", marginTop: 0}}>* Les lettres majuscules sont soulignées</h5>
            {/*<div className="center">*/}
            {/*  <img src="/imgs/recaptcha.png" className="responsive-img" alt=""/>*/}
            {/*</div>*/}
        </div>
    }
}

export default PrintAcces
