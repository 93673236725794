import {makeObservable, observable} from "mobx"
import axios from "axios"

const _ = require('lodash')

class OpticianShopsStore {
    @observable shops = []

    constructor() {
        makeObservable(this)
    }

    addShop(shop) {
        this.shops.push(shop)
    }

    updateShop(shop) {
        axios.patch(`/opticians/shops/update/${shop.id}`, shop)
            .then(response => {
                let index = this.shops.findIndex(s => s.id === shop.id)
                this.shops[index] = shop
            })
    }

    setShopCoordinates(shopId, coordinates) {
        let shop = this.shops.find(s => s.id === shopId)
        shop.coordinates = coordinates
        this.updateShop(shop)
    }
}

let store = new OpticianShopsStore
export default store