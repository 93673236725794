import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../../Shared/Loader";
import axios from "axios";
import {toast} from "react-toastify";
import {RequestState} from "../../../../Shared/StateHelper";
import Error from "../../../../Shared/Error";
import OfficeGeneralOptions from "./OfficeGeneralOptions";
import CommercialInformations from "./CommercialInformations";
import OfficeOphRef from "./OfficeOphRef";

function OfficeDashboard() {
    const {id} = useParams();
    const [office, setOffice] = useState(null);
    const [externs, setExterns] = useState([])
    const nav = useNavigate();
    const [requestState, setRequestState] = useState(RequestState.LOADING);

    useEffect(() => {
        loadOffice();
    }, []);

    useEffect(() => {
        if (office && office.id) {
            loadExterns(office.id);
        }
    }, [office]);

    const loadOffice = () => {
        axios.get(`/admin/console/users/office/${id}`)
            .then(res => {
                setOffice(res.data);
                setRequestState(RequestState.SUCCESS);
            })
            .catch(_ => {
                setRequestState(RequestState.ERROR);
            })
    }

    const loadExterns = () => {
        axios.get(`/admin/console/users/extern/${office.id}`)
            .then(res => {
                setExterns(res.data)
            })
            .catch(_ => {
            });
    }

    const saveOffice = (office) => {
        axios.patch(`/admin/console/users/office/${office.id}`, {
            office: office
        }).then((resp) => {
            setOffice(resp.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de modifier le cabinet")
        })
    }

    if (requestState === RequestState.LOADING) return <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
        <Loader/>
    </div>


    if (requestState === RequestState.ERROR) return <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
        <Error errorText="Impossible de charger le cabinet"/>
    </div>

    return <div id="full-height" style={{overflowY: "clip"}}>
        <div className="valign-wrapper" style={{marginLeft: 10}}>
            <a className="btn-flat" style={{borderRadius: 20}} onClick={() => nav("/offices")}>
                <i className="material-icons">arrow_back</i>
            </a>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">business</i>
                Gestion de {Helper.formatName(office.titre)}
            </h3>
        </div>
        <div className="divider"/>
        <div className="row" style={{padding: 20, gap: 10, height: "100%"}}>
            <div className="col s6" style={{overflowY: "hidden", paddingBlock: 10}}>
                <OfficeGeneralOptions office={office} onOfficeChange={(office) => saveOffice(office)}/>
                <OfficeOphRef externs={externs}/>
            </div>
            <div className="col s6" style={{overflowY: "hidden", paddingBlock: 10}}>
                <CommercialInformations office_id={office.id}/>
            </div>
        </div>
    </div>
}

export default OfficeDashboard;