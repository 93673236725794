import React from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const StripeSepaFrom = ({clientSecret, stripePublic, onValidation, user}) => {
    console.log(stripePublic)
    const stripePromise = loadStripe(stripePublic);

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} onValidation={onValidation} user={user}/>
        </Elements>
    );
};

export default StripeSepaFrom;