import React from 'react';
import PaymentErrors from "./PaymentErrors";

function PaymentErrorsConsole() {
    return <div id="full-height" className="fc" style={{overflowY: "scroll"}}>
        <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">error</i>
            Erreurs de paiements
        </h3>
        <div className="divider"/>
        <div style={{margin: 20}}>
            <PaymentErrors/>
        </div>
    </div>
}

export default PaymentErrorsConsole;