import React from "react"
import axios from "axios"
import {toast} from 'react-toastify';


export default function ConvoqueBox({alert_id, checked, onChange, url, text}) {

    const handleChange = () => {
        axios.post(url, {
            alert: alert_id,
            convoque: !checked
        }).then((_) => {
            onChange(!checked)
            toast.success("Patient convoqué.");
        })
            .catch(_ => toast.error("Une erreur est survenue."))
    }

    return <label>
        <input checked={checked} onChange={handleChange}
               type="checkbox"/>
        <span>{text}</span>
    </label>
}

