import React from 'react';
import {JOB} from "../../../Shared/Helper";
import {Role} from "../../../Shared/Enums/Scope";

const InvoiceHeader = ({retrocession, title}) => {
    return <div style={{display: "flex"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
            <img height="50px" src={JOB === Role.OPTICIAN ? "" : "/imgs/TemeooColoredNoText.png"}/>
            <h2 style={{
                    fontWeight: "normal",
                    left: retrocession ? 80 : 150,
                    position: "absolute",
                    top: 20,
                    color: "#ef7c6b"
                }}>{retrocession ? "Etat des rétrocessions" : title}
            </h2>
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "right", marginLeft: "auto"}}>
            <h5 style={{fontWeight: "bold", margin: 0, marginLeft: "auto", marginBottom: "5px"}}>Temeoo</h5>
            <h5 style={{margin: 0, marginLeft: "auto"}}>70 rue du Dr Yersin</h5>
            <h5 style={{margin: 0, marginLeft: "auto"}}>59120 Loos</h5>
            <h5 style={{margin: 0, marginLeft: "auto"}}>France</h5>
            <h5 style={{margin: 0, marginLeft: "auto"}}>N° de TVA : FR42901319160</h5>
        </div>
    </div>
}

InvoiceHeader.defaultProps = {
    retrocession: false,
    title: "FACTURE"
}

export default InvoiceHeader;