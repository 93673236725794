import React, {useEffect, useState} from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";

const OrthoptistSelect = ({onChange, value, filter, multiple, disabled}) => {
    const [orthoptists, setOrthoptists] = useState([]);

    useEffect(() => {
        getOrthoptists()
    }, [filter]);

    const getOrthoptists = (searchTerm = null) => {
        axios.get("/users/orthoptists", {params: {filter: filter ?? searchTerm}})
            .then(response => setOrthoptists(response.data))
            .catch(error => console.error(error))
    }

    const handleChange = (v) => {
        if (multiple) onChange(v.map((orthoptist) => orthoptist.id))
        else onChange(v?.id)
    }

    return <Autocomplete
        sx={{width: "100%"}}
        multiple={multiple}
        disablePortal
        disabled={disabled}
        filterSelectedOptions
        className="ui-autocomplete"
        // value={value ?? []} //La value s'update pas si la fonction est appelée par elle même, je sais pas trop pourquoi
        options={(orthoptists ?? [])}
        getOptionLabel={(orthoptist) => `#${orthoptist.id} ${Helper.formatName(orthoptist.firstname, orthoptist.lastname)}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, value) => handleChange(value)}
        onInputChange={(event) => getOrthoptists(event?.target.value)}
        renderInput={(params) => <TextField {...params} label="Sélectionner orthoptiste"/>}
    />
}

OrthoptistSelect.defaultProps = {
    multiple: false,
    disabled: false
}

export default OrthoptistSelect;