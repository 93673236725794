import React, {useEffect} from "react"
import {observer} from "mobx-react";
import ListPatients from "./ListPatients";
import DownloadDocuments from "./DownloadDocuments";
import MedicalEventStore from "../MedicalEventStore";
import {authorized} from "../../Shared/ScopedElement";
import {Role} from "../../Shared/Enums/Scope";
import Helper from "../../Shared/Helper";

const EventCompleted = observer(() => {

    const authorizedDocumentsRoles = [Role.SUPER_ADMIN, Role.ADMIN, Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.GUEST.LOCAL_REFERENT]
    const getDocuments = () => {
        if (!authorized(authorizedDocumentsRoles)) return []
        return MedicalEventStore.patients.map((patient) => {
            if (patient.status_controle < 2) return
            const patient_fullname = Helper.formatName(patient.firstname, patient.lastname).replace(/ /g, "_")
            return {uri: `/documents/general?appointment_id=${patient.appointment_id}`, name: `Compte_rendu_${patient_fullname}`}
        }).filter(Boolean)
    }

    const authorizedPrescriptionRoles = [Role.OPHTALMOLOGIST, Role.ORTHOPTIST, Role.SUPER_ADMIN, Role.ADMIN, Role.GUEST.LOCAL_REFERENT, Role.GUEST.OPTICIAN]
    const getPrescriptionUris = () => {
        if (!authorized(authorizedPrescriptionRoles)) return []
        return MedicalEventStore.patients.map((patient) => {
            if (!patient.prescription_id) return
            const patient_fullname = Helper.formatName(patient.firstname, patient.lastname).replace(/ /g, "_")
            return {
                uri: `/documents/prescription?appointment_id=${patient.appointment_id}&prescription_id=${patient.prescription_id}`,
                name: `Ordonnance_${patient_fullname}`
            }
        }).filter(Boolean)
    }

    const exportData = () => {
        MedicalEventStore.export_event_data().then((csv) => {
            const blob = new Blob([csv], {type: 'text/csv'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const max_length = 30;
            let event_name = MedicalEventStore.event.name.replace(/ /g, "_");
            if (event_name.length > max_length) event_name = event_name.substring(0, max_length);
            a.download = `export_${event_name}.csv`;
            a.click();
        })
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 10}}>
        <div className="valign-wrapper" style={{justifyContent: "right"}}>
            <a className={`btn-small blue darken-2 z-depth-0`}
               onClick={exportData}>
                <i className={"material-symbols-outlined left"}>csv</i>
                Importer données
            </a>
            <DownloadDocuments documents={[...getDocuments(), ...getPrescriptionUris()]} title={"Télécharger les documents"}/>
        </div>
        <ListPatients/>
    </div>
})

export default EventCompleted