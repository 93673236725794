import React, {useEffect} from 'react';
import PrescriptorForm from "./PrescriptorForm";

const EditPrescriptorButton = ({prescriptor, onEdit}) => {
    const modal_id = `edit_prescriptor_${prescriptor.id}`

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const instance = M.Modal.init(modal);
        return () => instance.destroy();
    }, []);

    const renderModal = () => {
        return <div className="modal" id={modal_id} style={{borderRadius: 20}}>
            <PrescriptorForm onSubmit={onEdit} buttonText="Modifier" prescriptor={prescriptor}/>
        </div>
    }

    return <>
        <a className="btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}>
            <i className="material-icons">edit</i>
        </a>
        {renderModal()}
    </>

}

export default EditPrescriptorButton;