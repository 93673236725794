import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import React from "react";
import {Bar} from 'react-chartjs-2';
import {t} from "../../I18n/i18n";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function BarChart({data, title, legend, withLegend, beginAtZero = false}) {
    const options = {
        responsive: true,
        barThickness: "10",
        borderRadius: "10",
        height: "100%",
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: beginAtZero,
                grid: {
                    display: false
                },
            },
        },
        plugins: {
            legend: {
                display: withLegend,
                position: 'top',
            },
            title: {
                display: true,
                text: legend,
            },
        },
    }

    const translated_labels = data.labels.map((label) => t(`MONTH.${label}`))
    return (
        <div style={{ height: "100%" }}>
            <Bar data={{...data, labels: translated_labels}} options={options} style={{ height: "100%" }} />
            <h2 className="center-align grey-text text-darken-2" style={{ fontWeight: "bold" }}>{title}</h2>
        </div>
    )
}

export default BarChart;
