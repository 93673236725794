import React from 'react';

const NewAppointment = ({appointments_todo}) => {
    if (appointments_todo.length === 0) return null

    const todo = appointments_todo[0]

    return <div className="fr valign-wrapper collection-item" style={{gap: 20}}>
        <i className="material-icons black-text"
           title="Nouvel examen">start</i>
        <div>{Helper.formatDate(todo.start, "DD/MM/YYYY hh:mm")} - Nouvel examen</div>
        <a className="btn btn-small green darken-2 z-depth-0" style={{marginLeft: "auto", marginRight: 10}}
           href={`/mesure/${todo.id}`} title={`Démarrer la consultation`}>
            <i className="material-icons left">play_arrow</i>
            Démarrer consultation
        </a>
    </div>
}

export default NewAppointment;

