import React, {useState} from 'react'
import {toast} from "react-toastify";
import BorderedDisplay from "../Shared/UiKit/BorderedDisplay";
import MedicalEventStore from "./MedicalEventStore";
import Regex from "../Shared/Validation/Regex";
import PatientForm from "./PatientForm";

const _ = require('lodash')
const EditPatient = (props) => {
    const defaultPatient = {
        firstname: "",
        lastname: "",
        email: "",
        telephone: "",
        birthday: "",
        ssn: "",
        organism_code: undefined,
        health_insurance: undefined
    }
    const [patient, setPatient] = useState(props.patient || defaultPatient)

    const editPatient = () => {
        console.log(patient)
        MedicalEventStore.updatePatient(patient).then(_ => {
            setPatient(defaultPatient)
            props.onSuccess()
        }).catch(_ => toast.error("Erreur lors de l'ajout du patient"))
    }

    return <PatientForm patient={patient} onChange={setPatient} onSubmit={editPatient}
                        title={`Éditer un  ${Helper.formatName(patient.firstname, patient.lastname)}`}
                        buttonText={`Éditer`} buttonIcon={"save"} cancel onCancel={props.onCancel}/>
}

export default EditPatient