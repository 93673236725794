import React from 'react';

const PlaceholderSignature = ({firstname, lastname, style}) => {
    const firstnameValue = firstname ? (firstname[0].toUpperCase() + "." ?? "") : "";
    return <div className="signature" style={{
        height: 150,
        fontSize: 18,
        fontFamily: "Brush Script MT Italic",
        ...style
    }}>
        {firstnameValue} {Helper.toCamelCase(lastname)}
    </div>
}

PlaceholderSignature.defaultProps = {
    style: {}
}

PlaceholderSignature.propTypes = {
    firstname: (props, propName) => {
        if (typeof props[propName] !== 'string') {
            return new Error('The firstname must be a string');
        }
    },
    lastname: (props, propName) => {
        if (typeof props[propName] !== 'string') {
            return new Error('The lastname must be a string');
        }
    }
}
export default PlaceholderSignature;