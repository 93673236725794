import {t} from "../I18n/i18n"

export const AlertTypes = {
    UNREAD: {
        type: "unread",
        title: t("ALERTS_PAGE.UNREAD"),
    },
    TO_RECONVOKE: {
        type: "to_reconvoke",
        title: t("ALERTS_PAGE.TO_BE_RECONVENED"),
    },
    RECONVOKED: {
        type: "reconvoked",
        title: t("ALERTS_PAGE.RECONVENED")
    }
}