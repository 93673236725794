import React, {useEffect} from 'react';
import {TimeRange} from "../../Shared/Enums/Enums";
import axios from "axios";
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import {RequestState} from "../../Shared/StateHelper";
import Loader from "../../Shared/Loader";
import Error from "../../Shared/Error";
import {useParams} from "react-router-dom";
import {t} from "../../I18n/i18n"

const _ = require('lodash');
const EarningsFromOrthoptist = ({orthoptists}) => {
    const {id} = useParams()
    const [selectedOrthoptistId, setSelectedOrthoptistId] = React.useState(id);
    const startDateRef = React.useRef(null);
    const endDateRef = React.useRef(null);
    const [data, setData] = React.useState({});
    const [requestState, setRequestState] = React.useState(RequestState.IDLE);

    useEffect(() => {
        startDateRef.current.value = TimeRange.MONTH.start();
        endDateRef.current.value = TimeRange.MONTH.end();
        getData()
    }, [])

    const setTime = (range) => {
        startDateRef.current.value = range.start();
        endDateRef.current.value = range.end();
    }

    const getData = () => {
        if (!selectedOrthoptistId) return
        setRequestState(RequestState.LOADING)
        axios.get(`/admin/statistics/${selectedOrthoptistId}`, {
            params: {
                start_date: startDateRef.current.value,
                end_date: endDateRef.current.value
            }
        }).then((response) => {
            setData(response.data)
            setRequestState(RequestState.SUCCESS)
        }).catch((error) => {
            setRequestState(RequestState.ERROR)
        })
    }

    const renderFilter = () => {
        return <div className="valign-wrapper" style={{gap: 10, flexWrap: "wrap", justifyContent: "center"}}>
            <div>
                <select value={selectedOrthoptistId ?? ""} className="browser-default"
                        onChange={(e) => setSelectedOrthoptistId(e.target.value)}>
                    <option value={""} disabled>{t("MONITORING_PAGE.SELECT_AN_ORTHO")}</option>
                    {orthoptists.map((orthoptist) => {
                        return <option key={`orthoptist_option_${orthoptist.id}`} value={orthoptist.id}>
                            #{orthoptist.id} - {Helper.formatName(orthoptist.prenom, orthoptist.nom)}
                        </option>
                    })}
                </select>
            </div>
            <div>
                <select className="browser-default" defaultValue=""
                        onChange={(e) => setTime(TimeRange[e.target.value])}>
                    <option value={""} disabled>{t("MONITORING_PAGE.SELECT_A_PERIOD")}</option>
                    {Object.values(TimeRange).map((range, index) => {
                        return <option key={`time_range_${index}`} value={range.value}>{range.name}</option>
                    })}
                </select>
            </div>
            {/*START DATE*/}
            <h6>{t("FROM")}</h6>
            <div>
                <input type="date" ref={startDateRef}/>
            </div>
            {/*END DATE*/}
            <h6>{t("TO")}</h6>
            <div>
                <input type="date" ref={endDateRef}/>
            </div>
            <a className="btn blue darken-2 z-depth-0" onClick={getData}>{t("SUBMIT")}</a>
        </div>
    }

    const renderData = () => {
        if (_.isEmpty(data)) return
        const total = data.appt_earnings.reduce((acc, earnings) => {
            return acc + parseFloat(earnings.appointements_earnings ?? 0) + parseFloat(earnings.hn_earnings ?? 0)
        }, 0)
        const totalAppt = data.appt_earnings.reduce((acc, earnings) => {
            return acc + parseFloat(earnings.appointements_earnings ?? 0)
        }, 0)
        const totalHn = data.appt_earnings.reduce((acc, earnings) => {
            return acc + parseFloat(earnings.hn_earnings ?? 0)
        }, 0)
        return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <BorderedDisplay text={"Bilan sur la periode"}>
                <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                    <h4 className="center">{t("MONITORING_PAGE.TOTAL_INCOMES")} : {total.toFixed(2) ?? 0}€</h4>
                    <div className="row">
                        <div className="col s12 m6"><h5 className="center">{t("MONITORING_PAGE.NUMBER_OF_REVIEWS")}</h5>
                        </div>
                        <div className="col s12 m6"><h5 className="center">{data.appointment_count}</h5></div>
                        <div className="col s12 m6"><h5
                            className="center">{t("MONITORING_PAGE.INCOME_FROM_PROOFREADING")}</h5></div>
                        <div className="col s12 m6"><h5 className="center">{totalAppt.toFixed(2)}€</h5></div>
                        <div className="col s12 m6"><h5 className="center">{t("MONITORING_PAGE.HN_INCOME")}</h5></div>
                        <div className="col s12 m6"><h5 className="center">{totalHn.toFixed(2)}€</h5></div>
                    </div>
                </div>
            </BorderedDisplay>
        </div>
    }

    const renderActs = () => {
        if (_.isEmpty(data.appt_earnings)) return <h3 className="center"
                                                      style={{marginTop: 100}}>{t("MONITORING_PAGE.NO_REVIEW_CARRIED")}</h3>
        return <BorderedDisplay text={t("MONITORING_PAGE.INCOME_PER_ACT")}>
            <table>
                <thead>
                <tr>
                    <th>{t("ACTS")}</th>
                    <th>{t("MONITORING_PAGE.INCOME_FROM_PROOFREADING")}</th>
                    <th>{t("MONITORING_PAGE.HN_INCOME")}</th>
                    <th>{t("TOTAL")}</th>
                </tr>
                </thead>
                <tbody>
                {Object.values(data.appt_earnings).map((earnings) => {
                    const appt = parseFloat(earnings.appointements_earnings ?? 0)
                    const hn = parseFloat(earnings.hn_earnings ?? 0)
                    const total = appt + hn
                    if (total === 0) return null
                    return <tr key={`acte_${earnings.act_name}`}>
                        <td>{earnings.act_name}</td>
                        <td>{appt.toFixed(2)}€</td>
                        <td>{hn.toFixed(2)}€</td>
                        <td>{total.toFixed(2)}€</td>
                    </tr>
                })}
                </tbody>
            </table>
        </BorderedDisplay>
    }

    const renderBody = () => {
        if (_.isEmpty(data) && RequestState.IDLE === requestState) return <h1
            className="center">{t("MONITORING_PAGE.DO_RESEARCH")}</h1>
        if (requestState === RequestState.LOADING) return <div style={{margin: 40}}>
            <Loader text="" size="small"/>
        </div>
        if (requestState === RequestState.ERROR) return <div style={{marginTop: 40}}>
            <Error errorText={t("MONITORING_PAGE.UNABLE_TO_LOAD")}/>
        </div>

        return <div className="row" style={{gap: 20}}>
            <div className="col s12 m6">
                {renderData()}
            </div>
            <div className="col s12 m6">
                {renderActs()}
            </div>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
        {renderFilter()}
        {renderBody()}
    </div>
}

export default EarningsFromOrthoptist;