import {observer} from "mobx-react";
import React from "react"
import ConsoleDashboardStore from "../../ConsoleDashboardStore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const FormulasFilter = observer(() => {

    return <Autocomplete
        disablePortal
        className="ui-autocomplete"
        multiple
        value={ConsoleDashboardStore.selectedFormulas}
        options={ConsoleDashboardStore.formulaList}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        onChange={(_, array) => ConsoleDashboardStore.selectedFormulas = array}
        sx={{width: "300px"}}
        renderInput={(params) => <TextField {...params} label="Sélectionner formules"/>}
    />
})

export default FormulasFilter