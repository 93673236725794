import React, {useEffect} from "react"

const VideoPreview = ({id, style, src, onRemove}) => {
    const modal_id = `video_preview_modal_${id}`

    useEffect(() => {
        const elems = document.getElementById(modal_id);
        const instance = M.Modal.init(elems, {});
        return () => instance.destroy();
    }, []);

    // need the video to be contained in a div to be displayed properly, I don't want it to overflow
    const renderModal = () => {
        return <div id={modal_id} className="modal z-depth-0 transparent modal-close" style={{height: "95vh"}}>
            <div className="modal-content transparent" style={{padding: 0, height: "100%", display: "flex", justifyContent: "center"}}>
                <video src={src} autoPlay={true} loop={true} muted={true} controls id="videoPreview"
                       className="z-depth-3"
                       style={{objectFit: "contain", maxHeight: "95vh"}}/>
            </div>
        </div>
    }

    return <>
        <div className="modal-trigger materialboxed" data-target={modal_id} style={{position: "relative"}}>
            <video src={src} style={{...style}}/>
            <i className="material-icons tiny"
               style={{position: "absolute", top: 4, left: 4, color: "white"}}>videocam</i>
            <i className={`material-icons tiny red-text clickable`}
               style={{position: "absolute", top: 4, right: 4, color: "white"}}
               onClick={e => {
                   e.stopPropagation()
                   onRemove()
               }}>
                cancel
            </i>
        </div>
        {renderModal()}
    </>
}

export default VideoPreview