export const MedicalEventStatus = {
    SCHEDULED: 'SCHEDULED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED',
}

export const GuestTypes = {
    OPTICIAN: 'OPTICIAN',
    LOCAL_REFERENT: 'LOCAL_REFERENT',
}