import React, {Component} from "react";
import DisplayBlocks from "./DisplayBlocks";
import {Observer} from "mobx-react";
import MarkdownEditor from "../../../Shared/UiKit/MarkdownEditor";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify";
import axios from "axios";
import OpticianCampaignsStore from "../../../Opticians/Campaigns/OpticianCampaignsStore";
import {RequestState} from "../../../Shared/StateHelper";
import MesureStore from "../../../Appointments/Mesures/MesureStore";

export default class DisplayInput extends Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this)
        this.renderText = this.renderText.bind(this)
        this.renderSelect = this.renderSelect.bind(this)
        this.state = {
            block: {},
            values: {}
        }
    }

    componentDidMount() {
        this.setState({
            block: this.props.block,
            values: this.props.values
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({
                block: this.props.block,
                values: this.props.values
            })
        }
    }

    changeValue(input, value) {
        let newValues = Object.assign({}, this.state.values)
        newValues[input.id] = value
        this.setState({
            values: newValues
        }, () => this.props.onChange(this.state.values))
    }

    renderText(input) {
        return <div style={{display: "flex", width: "100%", gap: 10, justifyContent: `${input.justify}`}}>
            {input.text ? <div style={{
                display: "flex", alignItems: "baseline", marginTop: 15,
                maxWidth: 300,
                fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                fontWeight: `${input.weight}`,
                fontStyle: `${input.style}`
            }}>
                {input.text}
            </div> : null}
            <div style={{
                width: input.width ? `${input.width}px` : '',
                flexGrow: input.width ? "" : "1"
            }}>
                {this.props.appointment_pending && this.state.values[input.id] !== null ?
                    <div className="hover-container"
                         style={{position: "relative", paddingBlock: 10, textAlign: input.justify}}>
                        <MarkdownEditor
                            style={{fontSize: DisplayBlocks.computeSize(input.size)}}
                            defaultValue={this.state.values[input.id]}
                            onChange={(v) => this.changeValue(input, v)}/>
                        <a className={`hover-button btn-small blue darken-2 z-depth-0 ${input.default_text ? "" : "hide"}`}
                           style={{position: "absolute", right: -20, top: -5, borderRadius: 20}}
                           onClick={() => this.changeValue(input, null)}>
                            <i className="material-icons">refresh</i>
                        </a>
                    </div>
                    :
                    <div style={{
                        borderRadius: "4px", border: "thin solid lightgrey",
                        width: input.width ? `${input.width}px` : '100%',
                        height: input.height ? input.height < 40 ? "fit-content" : `${input.height}px` : 'fit-content',
                        fontSize: DisplayBlocks.computeSize(input.size),
                        minHeight: "40px",
                        maxWidth: "100%"
                    }}>
                        <div id="display_block_html" dangerouslySetInnerHTML={{__html: input.default_text || ""}}
                             style={{textAlign: input.justify}}
                        />
                    </div>
                }
            </div>
        </div>
    }

    renderSelect(input) {
        if (input.options) {
            return <div className="valign-wrapper" style={{justifyContent: `${input.justify}`}}>
                {input.text ? <div style={{
                    display: "flex", alignItems: "center",
                    fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                    fontWeight: `${input.weight}`,
                    fontStyle: `${input.style}`
                }}>
                    {input.text} &nbsp;
                </div> : null}
                <div>
                    <select className="browser-default"
                            style={{
                                fontSize: DisplayBlocks.computeSize(input.size),
                                fontFamily: "inherit",
                            }}
                            value={this.state.values[input.id] ?? ""}
                            onChange={(e) => this.changeValue(input, e.target.value)}>
                        <option value="" disabled hidden></option>
                        {input.options.map((option) =>
                            <option value={option} key={option}>{option}</option>
                        )}
                    </select>
                </div>
            </div>
        } else return null
    }

    uploadImage = (file, input) => {
        if (file.length === 0) return;
        file = file[0]
        let formData = new FormData();
        let hasImage = false;
        if (file.name.includes(".jpg") || file.name.includes(".jpeg") || file.name.includes(".JPG") || file.name.includes(".JPEG") || file.name.includes(".png") || file.name.includes(".PNG")) {
            formData.append("image_data", file, file.name)
            hasImage = true
        }

        if (!hasImage) {
            toast.error("Le fichier n'est pas une image")
            return
        }

        axios.put(`/orthoptistes/reports/template/${MesureStore.selected_template.id}/image?appointment_id=${MesureStore.appointement_id}`, formData)
            .then((response) => {
                this.changeValue(input, response.data.url)
            }).catch(() => {
            toast.error("Erreur lors de du téléchargement des images")
            this.setState({isLoaded: RequestState.SUCCESS})
        })
            .then(() => MesureStore.hasImages())
    }

    renderDropzone = (input) => {
        const placeholder = <i className="material-icons">
            cloud_upload
        </i>
        const image_url = this.state.values[input.id]
        const render = image_url ? <img src={image_url} alt={"Document"} width="100%"/> : placeholder
        return <Dropzone onDrop={(file) => this.uploadImage(file, input)}>
            {({getRootProps, getInputProps, isDragActive}) => <div {...getRootProps()} style={{
                border: '1px solid lightgrey',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                minHeight: 50,
                borderRadius: 5,
                backgroundColor: isDragActive ? "lightgrey" : "white",
                overflow: "hidden",
                width: "100%"
            }}>
                <input {...getInputProps()} />
                {render}
            </div>
            }
        </Dropzone>
    }

    renderImage = (input) => {
        if (this.props.appointment_pending) {
            return <div className="hover-container"
                        style={{
                            display: "flex",
                            justifyContent: input.justify,
                            width: `${input.width ?? 100}%`,
                            position: "relative",
                            marginInline: 5
                        }}>
                {this.renderDropzone(input)}
                {this.state.values[input.id] ?
                    <a className="hover-button btn-small btn-flat transparent blue darken-2 white-text"
                       style={{
                           position: "absolute",
                           right: -16,
                           top: -15,
                           borderRadius: 20,
                           paddingInline: 6,
                           zIndex: 100
                       }}
                       onClick={() => this.changeValue(input, null)}>
                        <i className="material-icons">delete</i>
                    </a> : null}
            </div>
        }
        const name = input.name
        let placeholder_name = "Mon image"
        if(name !== "Nouveau") {
            placeholder_name = name.split(" ").join("+")
        }
        return <div style={{display: "flex", justifyContent: input.justify, marginInline: 5}}>
            <img
                src={`https://placehold.co/2100x2960.png?text=${placeholder_name}`}
                alt={"Document"} width={`${input.width ?? 100}%`}
            />
        </div>
    }

    renderInput(input) {
        switch (input.type) {
            case "TEXT":
                return this.renderText(input)
            case "SELECT":
                return this.renderSelect(input)
            case "IMAGE":
                return this.renderImage(input)
            default:
                return null
        }
    }

    render() {
        if (this.state.block.data) {
            return (
                <Observer>
                    {() => <div style={{display: "flex", alignItems: "stretch", paddingInline: 7}}>
                        {this.props.block.data.map((input, index) =>
                            <div key={index}
                                 style={{
                                     width: "100%",
                                     paddingBlock: 10,
                                     display: "flex",
                                     justifyContent: `${input.justify}`
                                 }}>
                                {this.renderInput(input)}
                            </div>
                        )}
                    </div>}
                </Observer>
            );
        } else return null
    }

    static previewText(input, values) {
        console.log(input.width)
        return (
            <div style={{display: "flex", alignItems: "baseline", whiteSpace: "pre-wrap"}}>
                {input.text ? <span style={{
                    fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                    fontWeight: `${input.weight}`,
                    fontStyle: `${input.style}`,
                    maxWidth: "100%",
                    display: "inline",
                    whiteSpace: "nowrap",
                }}>
                    {input.text}&nbsp;
                </span> : null}
                <div className="report-input-display" dangerouslySetInnerHTML={{__html: values[input.id] ?? ""}}
                     style={{
                         whiteSpace: "pre-wrap",
                         width: input.width ? `${input.width}px` : '100%',
                         maxWidth: "100%",
                         margin: 0,
                         fontSize: DisplayBlocks.computeSize(input.size)
                     }}/>
            </div>
        )
    }

    static previewSelect(input, values) {
        return (
            <div className="valign-wrapper">
                {input.text ? <div style={{
                    fontSize: `${DisplayBlocks.computeSize(input.size)}`,
                    fontWeight: `${input.weight}`,
                    fontStyle: `${input.style}`
                }}>
                    {input.text}&nbsp;
                </div> : null}
                <div style={{whiteSpace: "pre-wrap", fontSize: DisplayBlocks.computeSize(input.size)}}>
                    {values[input.id] ? values[input.id] : ""}
                </div>
            </div>
        )
    }

    static previewImage(input, values) {
        if (values[input.id] === null) return null
        return (
            <div style={{display: "flex", justifyContent: input.justify, marginInline: 5}}>
                {/*Image not streched*/}
                <img
                    src={values[input.id]}
                    alt={"Document"} width={`${input.width ?? 100}%`}
                    style={{objectFit: "contain"}}
                />
            </div>
        )
    }

    static previewInput(input, values) {
        switch (input.type) {
            case "TEXT":
                return this.previewText(input, values)
            case "SELECT":
                return this.previewSelect(input, values)
            case "IMAGE":
                return this.previewImage(input, values)
            default:
                return null
        }
    }

    static preview(block, values = {}) {
        if (block.data) {
            return <div style={{
                display: "flex",
                alignItems: "stretch",
                // pageBreakInside: "avoid"
            }}>
                {
                    block.data.map((input, index) => {
                            if (!values[input.id] && !input.default_text) return
                            return <div key={index}
                                        style={{
                                            width: "100%",
                                            paddingBlock: 10,
                                            display: "flex",
                                            justifyContent: `${input.justify}`,
                                            textAlign: `${input.justify}`,
                                        }}>
                                {this.previewInput(input, values)}
                            </div>
                        }
                    )
                }
            </div>
        } else return null
    }
}

DisplayInput.defaultProps = {
    block: {},
    values: {},
    appointment_pending: false
}