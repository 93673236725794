import React, {useEffect, useState} from 'react';
import BorderedDisplay from "../Shared/UiKit/BorderedDisplay";
import {wrapper} from "../I18n/i18n";
import axios from "axios";
import {RequestState} from "../Shared/StateHelper";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify";
import Loader from "../Shared/Loader";
import Error from "../Shared/Error";
import {observer} from "mobx-react";
import MesureStore from "../Appointments/Mesures/MesureStore";

const EvaluationPrescription = observer(() => {
    const {t} = wrapper("REGULATION_PAGE.EVALUATION_PRESCRIPTION");

    const [requestState, setRequestState] = useState(RequestState.SUCCESS);

    const uploadImage = (files) => {
        if (files.length !== 1) {
            toast.error("Please select a single file")
            return
        }
        if (!files[0].type.includes("image") && !files[0].type.includes("pdf")) {
            toast.error("Please select an image or pdf file")
            return
        }

        setRequestState(RequestState.LOADING)
        const formData = new FormData();
        formData.append("prescription", files[0]);
        formData.append("appointment_id", MesureStore.appointement_id);
        axios.post("/api/appointment/evaluation/prescription", formData).then((response) => {
            setRequestState(RequestState.SUCCESS)
            MesureStore.prescription_url = response.data.url
        }).catch((_) => setRequestState(RequestState.ERROR))
    }

    const renderImage = () => {
        const prescriptionUrl = MesureStore.prescription_url
        if (prescriptionUrl) {
            if(prescriptionUrl.includes(".pdf")) {
                return <iframe src={prescriptionUrl} style={{borderRadius: 5, height: "50vh", width: "100%", marginBlock: 20}}/>
            }
            return <img src={prescriptionUrl} alt="prescription" style={{borderRadius: 5, maxHeight: "50vh"}}/>
        } else {
            return <div>{t("DROP_PRESCRIPTION")}</div>
        }
    }

    const renderPicker = () => {
        if (requestState === RequestState.LOADING) return <Loader wide size="small"/>
        else if (requestState === RequestState.LOADING) return <Error errorText="Impossible de télécharger la prescription"/>
        else if (requestState === RequestState.SUCCESS) {
            return <div className="clickable" style={{minHeight: 200}}>
                <Dropzone onDrop={uploadImage}>
                    {({getRootProps, getInputProps, isDragActive}) => <div {...getRootProps()} style={{
                        border: '1px solid lightgrey',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        minHeight: 200,
                        borderRadius: 5,
                        backgroundColor: isDragActive ? "lightgrey" : "white",
                        overflow: "hidden"
                    }}>
                        <input {...getInputProps()} />
                        {renderImage()}
                    </div>
                    }
                </Dropzone>
            </div>
        }
    }

    return <BorderedDisplay text={t("TITLE")}>
        <div style={{minHeight: 200}}>{renderPicker()}</div>
    </BorderedDisplay>
});

export default EvaluationPrescription;