import React, {Component} from "react";

export default class MultiSelector extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
        this.state = {
            selectedData: []
        }
    }

    componentDidMount() {
        var elems = document.querySelectorAll('.chips');
        var instances = M.Chips.init(elems, {
            autocompleteOptions: {data: this.props.data},
            onChipAdd: () => this.onChange(elems[0]),
            onChipDelete: () => this.onChange(elems[0]),
        });

    }

    onChange(elem) {
        const arr = M.Chips.getInstance(elem).chipsData
        this.props.onChange(arr.map(elem => elem.text))
    }

    render() {
        return (
            <div className="valign-wrapper" style={{gap: 20}}>
                <span>{this.props.txt}</span>
                <div className="chips chips-autocomplete"/>
            </div>
        )
    }
}

MultiSelector.defaultProps = {
    txt: "",
    data: {"aaaaaaa": null, "bbbbbbbb": null, "ccccccccc": null},
    onChange: () => null
}