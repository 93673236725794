import React, {useRef} from 'react';

const EditableTextarea = ({value, placeholder, onValidation, style, validateOnEnter}) => {
    const [onEdit, setOnEdit] = React.useState(false)
    const ref = useRef(null)


    if (!onEdit) return <div className="editable" onClick={() => setOnEdit(true)}
                             style={{...style, border: "1px solid lightgrey", borderRadius: 5, padding: 10, minHeight: 36}}>
        <h6 style={{whiteSpace: "pre-wrap", margin: 0}}>
            {value}
        </h6>
    </div>

    const handleKeyDown = (e) => {
        if (e.key === "Escape") setOnEdit(false)
        if (e.key === "Enter") {
            if (validateOnEnter) {
                handleValidation()
            }
            if (e.ctrlKey) {
                handleValidation()
            }
        }
    }

    // cursor at the end of the text
    const handleFocus = (e) => e.target.selectionStart = e.target.selectionEnd = e.target.value.length;

    const handleValidation = () => {
        setOnEdit(false)
        if(ref.current.value === value) return
        onValidation(ref.current.value)
    }

    return <div style={{...style, display: "flex", flexDirection: "column", gap: 10}}
                onKeyDown={handleKeyDown}
    >
        <div className="input-field outlined" style={{flex: 1}}>
            <textarea defaultValue={value} ref={ref} autoFocus onFocus={handleFocus}
                      placeholder={placeholder}
                      style={{height: "100%", resize: "none", paddingBlock: 10, fontSize: "0.8em"}}/>
        </div>
        <div style={{display: "flex", justifyContent: "center", gap: 10}}>
            <a className="btn-small btn-floating z-depth-0 green darken-2" onClick={handleValidation}>
                <i className={"material-icons white-text"}>check</i>
            </a>
            <a className="btn-small btn-floating z-depth-0 red darken-2" onClick={() => setOnEdit(false)}>
                <i className={"material-icons white-text"}>close</i>
            </a>
        </div>
    </div>
};

EditableTextarea.defaultProps = {
    value: "",
    placeholder: "",
    onValidation: () => null,
    style: {},
    validateOnEnter: false
}

export default EditableTextarea;