import React, {Component} from 'react';
import BridgeStore from "../BridgeStore"
import {observer} from "mobx-react"
import Strips from "../../Shared/Strips"
import InputDirectory from "../Shared/InputDirectory"

@observer
export default class Directory extends Component {
  constructor(props) {
    super(props);
    this.state={}

  }

  render() {
    return (
      <div>
        <Strips>white mini</Strips>
          <div className="container">

            <h1 className="center"> Sélection du dossier d'exportation</h1>
            <p className="center">
            Veuillez sélectionner le dossier  où les images de la caméra non-mydriatique sont envoyées (au format jpg uniquement).
            Vous pouvez ensuite soit entrer l'adresse exacte ici,
            soit demander de sélectionner le dossier par une fenêtre intéractive.</p>

            <InputDirectory />
            {BridgeStore.directory !="" ? (
            <p style={{textAlign: 'right'}}><a href="/" data-turbolinks="false" className="btn btn-large"> Terminer</a></p>
            ) : (
            <p style={{textAlign: 'right'}}><a href="/" className="btn btn-large disabled"> Terminer</a></p>

            )}
          </div>
      </div>
    );
  }
}
