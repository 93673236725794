import React, {Component} from 'react';
import BridgeStore from "../BridgeStore"
import {observer} from "mobx-react"

@observer
export default class Statut extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }
  render(){


    if(BridgeStore.connected){
    return(
      <div>
        <h2 className="center bridge-statut bridge-Disponible">Logiciel connecté et fonctionnel</h2>
      </div>
    )
    }else{
      return(
      <div>
        <h2 className="center bridge-statut bridge-Indisponible"> Non connecté</h2>
        {BridgeStore.ip =="" ? (
            <p> Il semblerait que vous n'ayez pas sélectionné de pont, veuillez choisir ci-plus bas le connecteur avec lequel vous voulez communiquer </p>
          ) : (
            <p> Vous êtes connecté à {BridgeStore.ip}, <a onClick={this.initSocket}> reconnexion </a></p>
          )}
        </div>
      )

    }
  }

}
