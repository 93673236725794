import React from "react";
import {Role} from "../../../../Shared/Enums/Scope";
import OpticianBillingPanel from "./Optician/OpticianBillingPanel";
import OrthoptistBillingPanel from "./Orthoptist/OrthoptistBillingPanel";

const BillingPanel = (props) => {
    switch (props.user_type) {
        case Role.ORTHOPTIST:
            return <OrthoptistBillingPanel/>;
        case Role.OPHTALMOLOGIST:
            // return <OphthalmologistBillingPanel {...props} />;
        case Role.EXTERN:
            // return <ExternBillingPanel {...props} />;
        case Role.SECRETARY:
            // return <SecretaryBillingPanel {...props} />;
        case Role.OPTICIAN:
            return <OpticianBillingPanel url_prefix={props.url_prefix} stripePublic={props.stripe_public} />;
        default:
            return <div>Erreur</div>;
    }
}

export default BillingPanel;