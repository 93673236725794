import {useEffect, useState} from "react";

export const useCoordinates = (success_callback, error_callback) => {
    const [coordinates, setCoordinates] = useState(null)

    useEffect(() => {
        getUserCoordinates();
    }, []);

    const getUserCoordinates = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success_callback, error_callback, {timeout: 5000});
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }

    return coordinates
}