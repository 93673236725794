import React from "react";
import {Role} from "../Shared/Enums/Scope";
import axios from "axios";
import InputText from "../Shared/UiKit/InputText";
import {toast} from "react-toastify";

const roles = [Role.SECRETARY, Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.EXTERN, Role.OPTICIAN]
export default class ConnectAs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            filter: roles,
            namefilter: ""
        }

    }

    componentDidMount() {
        this.setState({
            users: this.props.users
        })
    }

    toggle = (role) => {
        if (this.state.filter.includes(role)) this.setState({filter: this.state.filter.filter((r) => role !== r)})
        else this.setState({filter: [...this.state.filter, role]})
    }

    connectAs = (email, role) => {
        axios.post("/connectAs", {
            email: email,
            role: role
        }).then(() => {
            document.location.href = "/";
        }).catch(err => {
            if (err.response?.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
                return
            }
            toast.error("Erreur lors de la connexion")
        })
    }

    render() {
        const users = this.state.users.filter((user) => this.state.filter.includes(user.role))
        return (
            <div className="container" id="full-height">
                <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                    <ul className="collection" style={{margin: 0}}>
                        <li className="collection-header">
                            <div style={{display: "flex", gap: 20, width: "100%", margin: 20}}>
                                {
                                    roles.map((role, index) =>
                                        <label key={index}>
                                            <input type="checkbox" className="filled-in"
                                                   checked={this.state.filter.includes(role)}
                                                   onChange={() => this.toggle(role)}/>
                                            <span>{role}</span>
                                        </label>
                                    )
                                }
                                <div style={{marginLeft: "auto", marginRight: 50}}>
                                    <div style={{display: "flex", gap: 20}}>
                                        <h6>Filtre</h6>
                                        <InputText type={"input"} autoFocus
                                                   value={this.state.namefilter} width={200}
                                                   placeholder={"Filtrer par nom"}
                                                   onChange={(e) => this.setState({namefilter: e.target.value})}/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div className="card" style={{overflowY: "scroll", height: "100%"}}>
                            {users.filter(user => ((user.name ?? "") + (user.email ?? ""))?.toUpperCase().replace(/s+/g, "")?.includes(this.state.namefilter?.toUpperCase().replace(/s+/g, "")))
                                .map((user, index) =>
                                    <a className="collection-item black-text" key={index}>
                                        <div style={{display: "flex", gap: 20, alignItems: "center", width: "100%"}}>
                                            <div style={{display: "flex", gap: 20}}>
                                                <h5 style={{width: 200, margin: 0}}>{user.name}</h5>
                                                <h5 style={{width: 200, margin: 0}}>{user.email}</h5>
                                            </div>
                                            <a id="bold-on-hover" style={{marginLeft: "auto", height: "100%"}}
                                               className="secondary-content btn-small btn-flat transparent"
                                               onClick={() => this.connectAs(user.email, user.role)}>
                                                <i className="material-icons left">key</i>Se connecter en tant que cet
                                                utilisateur</a>
                                        </div>
                                    </a>
                                )}
                        </div>
                    </ul>
                </div>
            </div>
        );
    }

}