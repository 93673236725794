import React, {useEffect, useState} from "react";
import {t} from "../../../I18n/i18n";
import axios from "axios";
import {toast} from "react-toastify";
import Helper from "../../../Shared/Helper";
import Validator from "../../../Shared/Validator";

export default function AddOphRef() {
    const [externes, setExternes] = useState([])
    const [formData, setFormData] = useState({
        lastname: "",
        firstname: "",
        rpps: "",
        telephone: "",
        email: "",
        address: "",
        postal_code: "",
        city: ""
    })

    useEffect(() => {
        axios.get(`/orthoptistes/referent_ophta`)
            .then((res) => {
                setExternes(res.data.externes)
            })
            .catch(() => {
                toast.error("Une erreur est survenue lors du chargement des ophtalmologues référents")
            })
    }, [])

    const handleChange = (e) => {
        const {id, value} = e.target
        setFormData({
            ...formData,
            [id]: value
        })
    }

    const save = (e) => {
        e.preventDefault()

        const {lastname, firstname, rpps, telephone, email, address, postal_code, city} = e.target.elements;

        if (rpps.value.length !== 11) {
            toast.error(t("OPTIONS.ADD_OPH_REF.BASS_RPPS_NUMBER"))
            return
        }

        setFormData({
            lastname: lastname.value,
            firstname: firstname.value,
            rpps: rpps.value,
            telephone: telephone.value,
            email: email.value,
            address: address.value,
            postal_code: postal_code.value,
            city: city.value
        })

        axios.post(`/orthoptistes/create_referent_ophta`, {
            name: lastname.value.replace(/\s/g, '') + " " + firstname.value.replace(/\s/g, ''),
            rpps: rpps.value,
            tel: telephone.value,
            email: email.value,
            address: address.value,
            postal_code: postal_code.value,
            city: city.value
        }).then((resp) => {
            setExternes([...externes, resp.data])
            toast.success(t("OPTIONS.ADD_OPH_REF.SUCCES_WHEN_CREATE"))
        }).catch(() => {
            toast.error(t("OPTIONS.ADD_OPH_REF.ERROR_WHEN_CREATE"))
        })
    }

    const deleteOphRef = (rpps) => {
        axios.delete(`/orthoptistes/referent_ophta/` + rpps)
            .then(() => {
                setExternes(externes.filter(externe => externe.rpps !== rpps))
                toast.success(t("OPTIONS.ADD_OPH_REF.SUCCES_WHEN_DELETE"))
            })
            .catch(() => {
                toast.error(t("OPTIONS.ADD_OPH_REF.ERROR_WHEN_DELETE"))
            })
    }

    return <div className="container" style={{gap: 20, width: "100%", height: "100%"}}>
        <div className="center valign-wrapper" style={{display: 'flex', justifyContent: 'center', gap: 20}}>
            <h1 className="center">Ophtalmologiste référent</h1>
        </div>
        <form style={{display: "flex", flexDirection: "column", gap: 20}} onSubmit={save}>
            <div style={{display: 'flex', gap: 20}}>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="lastname" type="text" className="validate" required value={formData.lastname}
                           onChange={handleChange}/>
                    <label htmlFor="lastname">{t("LASTNAME")} *</label>
                </div>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="firstname" type="text" className="validate" required value={formData.firstname}
                           onChange={handleChange}/>
                    <label htmlFor="firstname">{t("FIRSTNAME")} *</label>
                </div>
            </div>
            <div style={{display: 'flex', gap: 20}}>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="email" type="text" className="validate" value={formData.email}
                           onChange={handleChange}/>
                    <label htmlFor="email">Mail</label>
                </div>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="telephone" type="number" className="validate" value={formData.telephone} onChange={handleChange}/>
                    <label htmlFor="telephone">Téléphone</label>
                </div>
            </div>
            <div style={{display: 'flex', gap: 20}}>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="rpps" type="number" className="validate" required value={formData.rpps}
                           onChange={handleChange}/>
                    <label htmlFor="rpps">RPPS *</label>
                </div>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="address" type="text" className="validate" value={formData.address}
                           onChange={handleChange}/>
                    <label htmlFor="address">{t("ADDRESS")}</label>
                </div>
            </div>
            <div style={{display: 'flex', gap: 20}}>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="city" type="text" className="validate" value={formData.city} onChange={handleChange}/>
                    <label htmlFor="city">{t("CITY")}</label>
                </div>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="postal_code" type="text" className="validate" value={formData.postal_code}
                           onChange={handleChange}/>
                    <label htmlFor="postal_code">{t("POSTAL_CODE")}</label>
                </div>
            </div>
            <div style={{color: "gray"}}>
                * {t("OPTIONS.ADD_OPH_REF.REQUIRED_FIELD")}
            </div>
            <div>
                <button className="btn blue darken-2 z-depth-0" type="submit"
                        name="action">{t("OPTIONS.ADD_OPH_REF.ADD_REF_OPH")}</button>
            </div>
        </form>
        <div>
            <table className="highlight">
                <thead>
                <tr>
                    <th>{t("FULLNAME")}</th>
                    <th style={{textAlign: 'center'}}>Rpps</th>
                    <th style={{textAlign: 'center'}}>{t("ADDRESS")}</th>
                    <th style={{textAlign: 'center'}}>{t("CITY")}</th>
                    <th style={{textAlign: 'center'}}>{t("POSTAL_CODE")}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {externes.map((externe, index) => (
                    <tr key={index}>
                        <td>{externe.fullname}</td>
                        <td style={{textAlign: 'center'}}>{externe.rpps}</td>
                        <td style={{textAlign: 'center'}}>{externe.address}</td>
                        <td style={{textAlign: 'center'}}>{externe.city}</td>
                        <td style={{textAlign: 'center'}}>{externe.postal_code}</td>
                        <td style={{textAlign: 'right'}}><Validator
                            onValidation={() => deleteOphRef(externe.rpps)}
                            text={`${t("RISK_ALERT_MANAGEMENT.RISK_ALERT_MANAGEMENT")} ?`}
                            detail={t("RISK_ALERT_MANAGEMENT.ACTION_IRREVERSIBLE")}
                            validationText={t("DELETE")} validationColor="red darken-2"
                            validationIcon="delete"
                            abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                            id={`delete_oph_ref_${externe.rpps}`}>
                            <a className="btn red darken-2 z-depth-0">
                                <i className="material-icons">delete</i>
                            </a>
                        </Validator></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    </div>
}
