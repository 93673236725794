export const ArsDocumentsType = {
    HONOR_ATTESTATION_6_15: {
        document_type: "HONOR_ATTESTATION_6_15",
        title: "Attestation sur l'honneur 6 à 15 ans",
        url: "/ars_documents/honor_attestation_6_15.pdf",
    },
    EMPLOYER_ACCORD_6_15: {
        document_type: "EMPLOYER_ACCORD_6_15",
        title: "Accord de l'employeur 6 à 15 ans",
        url: "/ars_documents/employer_accord_6_15.pdf"
    },
    PREREQUISITE_VALIDATION_6_15: {
        document_type: "PREREQUISITE_VALIDATION_6_15",
        title: "Attestation des prérequis 6 à 15 ans",
        url: "/ars_documents/prerequisite_attestation_6_15.pdf"
    },
    ENGAGEMENT_ACCORD_6_15: {
        document_type: "ENGAGEMENT_ACCORD_6_15",
        title: "Accord d'engagement 6 à 15 ans",
        url: "/ars_documents/engagement_accord_6_15.pdf"
    },
    HONOR_ATTESTATION_16_50: {
        document_type: "HONOR_ATTESTATION_16_50",
        title: "Attestation sur l'honneur 16 à 50 ans",
        url: "/ars_documents/honor_attestation_16_50.pdf"
    },
    EMPLOYER_ACCORD_16_50: {
        document_type: "EMPLOYER_ACCORD_16_50",
        title: "Accord de l'employeur 16 à 50 ans",
        url: "/ars_documents/employer_accord_16_50.pdf"
    },
    PREREQUISITE_VALIDATION_16_50: {
        document_type: "PREREQUISITE_VALIDATION_16_50",
        title: "Attestation des prérequis 16 à 50 ans",
        url: "/ars_documents/prerequisite_attestation_16_50.pdf"
    },
    ENGAGEMENT_ACCORD_16_50: {
        document_type: "ENGAGEMENT_ACCORD_16_50",
        title: "Accord d'engagement 16 à 50 ans",
        url: "/ars_documents/engagement_accord_16_50.pdf"
    },
    HONOR_ATTESTATION_RETINO: {
        document_type: "HONOR_ATTESTATION_RETINO",
        title: "Attestation sur l'honneur rétinopathie diabétique",
        url: "/ars_documents/honor_attestation_rino.pdf"
    },
    EMPLOYER_ACCORD_RETINO: {
        document_type: "EMPLOYER_ACCORD_RETINO",
        title: "Accord de l'employeur rétinopathie diabétique",
        url: "/ars_documents/employer_accord_rino.pdf"
    },
    PREREQUISITE_VALIDATION_RETINO: {
        document_type: "PREREQUISITE_VALIDATION_RETINO",
        title: "Attestation des prérequis rétinopathie diabétique",
        url: "/ars_documents/prerequisite_attestation_rino.pdf"
    },
    ENGAGEMENT_ACCORD_RETINO: {
        document_type: "ENGAGEMENT_ACCORD_RETINO",
        title: "Accord d'engagement rétinopathie diabétique",
        url: "/ars_documents/engagement_accord_rino.pdf"
    },
    ACQUISITION_ATTESTATION_RETINO: {
        document_type: "ACQUISITION_ATTESTATION_RETINO",
        title: "Attestation d'acquisition de compétences rétinopathie diabétique",
        url: "/ars_documents/acquisition_attestation_rino.pdf"
    },
}