import React from 'react';
import DocumentHeader from "../../../Documents/DocumentsComponents/DocumentHeader";

const Scor = ({prescription, patient, orthoptist, ophtalmologist, appointment}) => {

    const renderWatermark = () => {
        return <div style={{
            zIndex: 1,
            color: "grey",
            opacity: 0.7,
            transform: "translate(10%) rotate(45deg)",
            transformOrigin: "left top",
            position: "absolute",
            top: 200,
            left: 200,
        }}>
            <h1 className="center" style={{margin: 0}}>ORDONNANCE NON VALABLE POUR PATIENT</h1>
            <h1 className="center" style={{margin: 0}}>À USAGE DE REMBOURSEMENT ORTHOPTISTE</h1>
        </div>
    }

    const renderHeader = () => {
        if (prescription.header) return <div dangerouslySetInnerHTML={{__html: prescription.header}}/>
        return <DocumentHeader ophtalmologist={ophtalmologist}
                               orthoptist={orthoptist}
                               appointment={appointment}
                               patient={{
                                   firstname: patient.firstname,
                                   lastname: patient.lastname,
                                   email: patient.email,
                                   tel: patient.telephone,
                                   birthday: patient.birthday,
                               }}

        />
    }

    return <div style={{display: "flex", justifyContent: "center"}}>
        <div className="card page" style={{width: "210mm", height: "296mm", margin: 20}}>
            <div className="pdf-document" style={{display: "flex", flexDirection: "column", height: "270mm"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    {renderHeader()}
                    <h4 className="center">Prescription</h4>
                    <h5 style={{marginInline: 100, whiteSpace: "pre-wrap"}}>{prescription.text}</h5>
                </div>
                <div className="pdf-footer prevent-break"
                     style={{marginTop: "auto", display: "flex", flexDirection: "column", gap: 20}}>
                    <div>
                        {prescription.signature ?
                            <img style={{height: "150px", float: "right"}}
                                 src={prescription.signature} alt="Signature de l'ophtalmologiste"/>
                            : null
                        }
                    </div>
                </div>
                {renderWatermark()}
            </div>
        </div>

    </div>
}

export default Scor;