import React from "react";
import DocumentPrescription from "./DocumentPrescription";

const DocumentConclusion = ({prescription, conclusion, appointment, message, islocked, user_type, analyst}) => {

    const hasSkiaCol = () => {
        return prescription.text.toLowerCase().includes("skiacol")
    }

    const hasCorrection = () => {
        return prescription.text && prescription.text.length > 0
    }

    const renderPrescription = () => {
        if (appointment.status_controle === 3) return <h5>Aucune ordonnance n'a été délivrée.</h5>
        if (hasSkiaCol()) return <h5>Une ordonnance de skiacol a été délivrée pour un examen complémentaire.</h5>
        if (!hasCorrection()) return <h5>Aucune ordonnance n'a été délivrée.</h5>

        return <DocumentPrescription prescription={prescription} islocked={islocked} user_type={user_type}/>
    }

    const renderValidatedConclusion = () => {
        if (appointment.ophtalmologist_comment) {
            return appointment.ophtalmologist_comment
        } else if (appointment.is_amy_67) {
            return "Examen ophtalmologique normal, prochain contrôle dans 1 an."
        }
        return "À la lecture du dossier, l'examen est rassurant."
    }

    const renderDeniedConclusion = () => {
        if (conclusion.conclusion_text) {
            return conclusion.conclusion_text
        } else if (message[0]?.message) { // from textemessageorthoptiste : message set by orthoptist for ophtalmologist
            return message[0]?.message
        } else {
            return "À la suite de l'analyse ophtalmologique, un nouvel examen est conseillé."
        }
    }
    const renderConclusionV2 = () => {
        switch (appointment.status_controle) {
            case 2: // Validated
                return renderValidatedConclusion()
            case 3: // Denied
                return renderDeniedConclusion()
            default:
                return null
        }
    }

    const renderSignature = () => {
        return analyst?.nom
    }

    const renderMessageV2 = () => {
        return <>
            <h5 style={{whiteSpace: "pre-wrap", margin: 0}}>{renderConclusionV2()}</h5>
            <h5 style={{whiteSpace: "pre-wrap", margin: 0}}>{renderPrescription()}</h5>
            <h5 style={{margin: 0, alignSelf: "flex-end"}}>{renderSignature()}</h5>
        </>
    }

    return <div className="fc gap-20">
        <h5 style={{marginBlock: 0}}>
            <b>Conclusion : </b>
        </h5>
        {appointment.status_controle >= 2 ? renderMessageV2() :
            <h5 style={{marginBottom: 20, marginTop: 3}}>L'examen n'a pas encore été contrôlé par un
                ophtalmologiste.</h5>}
    </div>
};

export default DocumentConclusion
