import React from 'react';
import {formatAcuities, formatRetinos} from "./mesures";
import {Mesures, Side, TypesMesures} from "../../../Shared/Enums/Enums";

function MesureOverview({mesure, type}) {

    const renderTension = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            {mesure.od_tension ? <h6 style={{margin: 0}}>OD : {mesure.od_tension < 0 ? "NM" : mesure.od_tension}</h6> : null}
            {mesure.og_tension ? <h6 style={{margin: 0}}>OG : {mesure.og_tension < 0 ? "NM" : mesure.og_tension}</h6> : null}
        </div>
    }

    const renderPachymetry = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            {mesure.od_pachymetrie ? <h6 style={{margin: 0}}>OD : {mesure.od_pachymetrie} µm</h6> : null}
            {mesure.og_pachymetrie ? <h6 style={{margin: 0}}>OG : {mesure.og_pachymetrie} µm</h6> : null}
        </div>
    }

    const renderMesure = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            <h6 style={{margin: 0, fontSize: 10}}>
                OD : {formatRetinos(mesure, Side.RIGHT)}
                {formatAcuities(mesure, Side.RIGHT, true)}
            </h6>
            <h6 style={{margin: 0, fontSize: 10}}>
                OG : {formatRetinos(mesure, Side.LEFT)}
                {formatAcuities(mesure, Side.LEFT, true)}
            </h6>
        </div>
    }

    const renderKerato = () => {
        return <div  style={{display: "flex", flexDirection: "column"}}>
            {mesure.od_radius_1 && mesure.od_radius_2 && mesure.od_degree ? <h6 style={{margin: 0, fontSize: 10}}>OD : R1 {mesure.od_radius_1} R2 {mesure.od_radius_2} D {mesure.od_degree}°</h6> : null}
            {mesure.og_radius_1 && mesure.og_radius_2 && mesure.og_degree ? <h6 style={{margin: 0, fontSize: 10}}>OG : R1 {mesure.og_radius_1} R2 {mesure.og_radius_2} D {mesure.og_degree}°</h6> : null}
        </div>
    }

    switch (type.name) {
        case Mesures.TENSION.value :
            return renderTension();
        case Mesures.PACHYMETRY.value :
            return renderPachymetry();
        case Mesures.KERATOMETRY.value :
            return renderKerato();
        default :
            return renderMesure();
    }

}

export default MesureOverview;