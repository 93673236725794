import React, {Component} from 'react';
import {observer} from "mobx-react"
import InputRefracteur from "../Shared/InputRefracteur"

@observer
export default class ParametreRefracteur extends Component {
  render() {
    return (
      <div className="card white ">
        <div className="card-content">
          <h2 className="center"> Réfracteur</h2>
          <div className="row">
            <InputRefracteur/>
          </div>

        </div>
      </div>
    );
  }
}
