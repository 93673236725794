import React, {useEffect, useState} from 'react';
import axios from "axios";
import {t} from "../../../../../I18n/i18n";

const OrthoptistOptions = () => {
    const [options, setOptions] = useState([])
    const [billingCategoryName, setBillingCategoryName] = useState(null)

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        axios.get('/orthoptistes/paiements/options').then((response) => {
            setOptions(response.data.options)
            setBillingCategoryName(response.data.billing_category_name)
        })
    }

    const renderOptions = () => {
        const price = options.reduce((acc, option) => acc + (option.selected ? option.price : 0), 0) / 100.0
        return <div className="fc" style={{gap: 20}}>
            <h5 style={{fontWeight: 600, margin: 0}}>
                <span>Mes abonnements : </span>
                <span style={{fontSize: "1.3em"}}>{price.toFixed(2)} €</span>
                <span> /mois</span>
            </h5>
            <div className="fc container">
                {options.map((option, index) => {
                    return <div key={`option_${option.item_reference}`}
                                className={`card z-depth-0 ${option.selected ? "green darken-2 white-text" : "grey lighten-2 grey-text"}`}
                                style={{padding: 0}}>
                        <h6 className="center" style={{marginBlock: 7}}>{t(`USER_OPTIONS.${option.item_reference}`)}</h6>
                    </div>
                })}
            </div>
        </div>
    }

    return <div className="fc" style={{gap: 20}}>
        <h5 style={{margin: 0}}>
            <span style={{fontWeight: 600}} >Mon forfait télémédecine : </span>{billingCategoryName}
        </h5>
        {renderOptions()}
        <label style={{fontSize: "0.7em"}}>Pour toute modification de votre abonnement ou de vos options, veuillez contacter le support Temeoo.</label>
    </div>
}

export default OrthoptistOptions;