import React, {useEffect, useState} from "react";
import {isLoading, loading, RequestState} from "../Shared/StateHelper";
import StateButton from "../Shared/StateButton";
import {Role} from "../Shared/Enums/Scope";
import ScopedElement from "../Shared/ScopedElement";
import socket from "../Shared/socket";
import {displayMedocError} from "../Shared/Enums/ErrorCodes";
import Tooltip from "../Shared/Tootip";


function NoemiReturnButton({bridge_uid, invoices, onClick, onComplete, rpps, adeli, disabled}) {
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    useEffect(() => {
        socket.on("noemiReturnResponse", (resp) => {
            handleNoemiReturnResponse(resp)
        })

        return () => {
            socket.off("noemiReturnResponse")
        }
    }, [])

    const handleNoemiReturnResponse = (resp) => {
        if (resp.status === "success") {
            console.debug("NOEMI return retrieved successfully with data : ", resp.data)
            setRequestState(RequestState.SUCCESS)
            onComplete(resp.data)
        } else {
            console.debug("Error on NOEMI return retrieving with error: ", displayMedocError(resp.errorCode))
            setRequestState(RequestState.ERROR)
        }
    }

    const openSocket = (uid) => {
        socket.emit("machineID", {machine: uid});
    }

    const sendNoemiReturnRequest = (uid) => {
        onClick()
        if (isLoading(requestState)) return
        openSocket(uid);

        if (uid !== null) {
            const data = {
                machine: uid,
                data: {
                    rpps: rpps,
                    adeli: adeli,
                    invoices: invoices.map((invoice) => {
                        return {
                            id: invoice.id,
                            medoc_id: invoice.medoc_id,
                        }
                    })
                }
            }
            console.debug("Sending NOEMI return request with payload: ", data)
            socket.emit("noemiReturnRequest", data);
            setRequestState(loading(requestState))
            setTimeout(() => {
                if (isLoading(requestState)) setRequestState(RequestState.ERROR)
            }, 60 * 5 * 1000)
        } else {
            setRequestState(RequestState.ERROR)
        }
    }


    return (
        <Tooltip id="noemi_tooltip"
                 text="Demander un retour NOEMI à Med'OC pour synchroniser les rendez-vous">
            <div>
                <ScopedElement scope={[Role.CV_USER]}>
                    <StateButton text="Synchroniser" icon="sync"
                                 side="left" color="blue darken-2"
                                 state={requestState}
                                 disabled={disabled}
                                 onClick={() => sendNoemiReturnRequest(bridge_uid)}
                    />
                </ScopedElement>
            </div>
        </Tooltip>
    )

}

export default NoemiReturnButton;

NoemiReturnButton.defaultProps = {
    onClick: () => null,
    onComplete: (data) => console.log("NOEMI return received with data: ", data),
    invoices: [],
    disabled: false
}
