import React, {useEffect, useState} from 'react';
import Preferences from "../Preferences/Preferences";
import axios from "axios";
import DOMPurify from "dompurify"

const GlobalNotification = () => {
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        get_notification();
    }, []);

    const get_notification = () => {
        Preferences.get("global_notification").then((notification) => {
            setNotification(notification)
        })
    }

    const close = () => {
        Preferences.set({global_notification: null}).then(() => {
            setNotification(null)
        })
    }

    const closeAndConfirm = () => {
        axios.get(`/global_notifications/${notification.id}/confirm`).then(() => {
            setNotification(null)
        })
    }

    if (!notification) return null

    const renderActionButton = () => {
        if (notification?.need_confirmation) return <div style={{position: "absolute", bottom: 5, right: 5}}>
            <a className={"btn-flat btn-small white-text grey darken-2"} onClick={() => setNotification(null)}>
                Me rappeler plus tard
            </a>
            <a className={"btn-flat btn-small white-text grey darken-2"} onClick={closeAndConfirm}>
                J'ai pris connaissance
            </a>
        </div>

        return <a className={"btn-flat btn-small white-text grey darken-2"} onClick={close}
                  style={{position: "absolute", top: 5, right: 5, borderRadius: 25, paddingInline: 5}}>
            <i className="material-icons">close</i>
        </a>
    }

    return <div className="card z-depth-0 grey darken-4 white-text fr"
                style={{
                    position: "fixed",
                    inset: "0 0 auto 0",
                    minHeight: "20vh",
                    borderRadius: 0, opacity: 0.8,
                    maxHeight: "70vh",
                    overflowY: "clip"
                }}>
        {renderActionButton()}
        <div className="card-content" style={{flex: 1, overflowY: "scroll"}}>
            <div className="container">
                <h4>{notification.title}</h4>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(notification.content)}}/>
            </div>
        </div>
    </div>
}

export default GlobalNotification;