import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {t} from "../../../I18n/i18n";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import DisplayCard from "../../../Shared/UiKit/DisplayCard";

function PatientsToSee() {
    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [patientsCount, setPatientsCount] = React.useState(null);

    React.useEffect(() => getPatientsCount(), [])

    const getPatientsCount = () => {
        axios.get("/admin/statistiques/api/orthoptist/appointments/daily")
            .then(resp => {
                setPatientsCount(resp.data.number)
                setRequestState(RequestState.SUCCESS)
            }).catch(() => {
            toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.NB_APPOINTMENTS"))
            setRequestState(RequestState.ERROR)
        })
    }

    const renderConsultationsCount = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <h3
            className="valign-wrapper" style={{flex: 1, fontSize: "3em", margin: 0, paddingInline: "0.6em"}}>
            <b>{patientsCount}</b>
        </h3>

    }

    return <DisplayCard title="Patients à voir" color="blue lighten-2" link="/agenda/index">
        {renderConsultationsCount()}
    </DisplayCard>
}

export default PatientsToSee;