import React, {createRef, useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {wrapper} from "../../I18n/i18n";

const {c} = wrapper("ORTHOPTIST_PHONE");
export default function SimpleVideoRecorder({handleSave, onVideoCapture}) {
    const [recording, setRecording] = useState(false);
    const videoPreview = createRef();
    const [stream, setStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recorded, setRecorded] = useState(false);
    const [recordedVideoBlob, setRecordedVideoBlob] = useState(null);
    //ref for video live
    const videoLive = createRef();


    const getVideoPermission = async () => {
        try {
            const stream_prefered = await navigator.mediaDevices.getUserMedia({
                video: {facingMode: {exact: "environment"}},
                zoom: {ideal: 1},
                audio: false,
            })
            setStream(stream_prefered);

        } catch (e) {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false,
            })
            setStream(stream);
        }

    }
    const toggleRecord = () => {
        recording ? stopRecording() : startRecording();
    }
    const startRecording = () => {
        setRecording(true);
        // start save stream
        mediaRecorder.start();


    }
    const stopRecording = () => {
        setRecording(false);
        mediaRecorder.stop();
    }
    //on media recorder data available
    const onRecordingReady = (e) => {
        const videoURL = window.URL.createObjectURL(e.data);
        setRecordedVideoBlob(e.data);
        //videoPreview.current.src = videoURL;
        document.querySelector('#videoPreview').src = videoURL; // jsp pq , mais useref ne marche pas
        setRecorded(true);
        onVideoCapture(e.data, videoURL);
    }

    if (!MediaRecorder.isTypeSupported('video/webm')) { // <2>
        console.warn('video/webm is not supported')
    }
    useEffect(() => {
        getVideoPermission();
    }, []);

    useEffect(() => {
        videoLive.current.srcObject = stream;
    }, [videoLive]);
    useEffect(() => {
        if (stream !== null) {
            const mediaRecorder = new MediaRecorder(stream, { // <3>
                mimeType: 'video/webm',

            })
            mediaRecorder.ondataavailable = onRecordingReady;
            setMediaRecorder(mediaRecorder);

            videoLive.current.srcObject = stream;

        }
    }, [stream]);


    return (
        <div>
            {
                recording ? (
                    <p className={{color: "red", textAlign: "center"}}> {c("RECORDING")} </p>
                ) : null
            }

            {/*/* video player*/}
            <div onClick={toggleRecord} style={{display: recorded ? "none" : "block"}}>
                <video autoPlay
                       muted
                       playsInline
                       id="videoLive"
                       ref={videoLive}
                       style={{
                           maxHeight: "90vh",

                           width: "98vw",
                           margin: "auto",
                           boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
                           border: recording ? "5px solid red" : "5px solid rgba(0,0,0,0)",
                           animation: recording ? "pulse 1s infinite" : "none",


                       }}


                ></video>
            </div>
            <div>
                <div className="row" style={{display: recorded ? "block" : "none"}}>
                    <div className="row">
                        <video autoPlay={true} loop={true} controls playsInline id="videoPreview"
                               style={{width: "100vw"}} ref={videoPreview}></video>
                    </div>
                    <div className="row">
                        <div className="col s6">
                            <button className="btn green white-text" onClick={(e) => {
                                handleSave(recordedVideoBlob);
                                setRecorded(false);
                                toast.success("Video sauvegardée avec succès");
                            }}>{c("SUBMIT")}</button>
                        </div>
                        <div className="col s6">
                            <button className="btn red white-text" onClick={(e) => {
                                setRecorded(false);
                                videoPreview.current.src = "";

                            }}>{c("CANCEL")}</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )
}