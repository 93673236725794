import React from 'react';
import OpticianCampaignsStore from "./OpticianCampaignsStore";
import {observer} from "mobx-react";
import {CampaignStatus} from "../Enums";
import Validator from "../../Shared/Validator";
import {t, wrapper} from "../../I18n/i18n";
import ZoneVisualizationModal from "../../Shared/Map/ZoneVisualizationModal";

const CampaignLine = observer(({campaign}) => {
    const {t: w} = wrapper("OPTICIAN_DASHBOARD.CAMPAIGNS")

    const renderStatusButton = () => {
        const active = campaign.status === CampaignStatus.ACTIVE;
        const action_color = active ? "red darken-2" : "green darken-2";
        const action_icon = active ? "stop_circle" : "play_circle";

        return <Validator
            onValidation={() => {
                OpticianCampaignsStore.saveSelectedCampaign({...campaign, status: active ? CampaignStatus.INACTIVE : CampaignStatus.ACTIVE})
            }}
            text={w(`STATUS.${campaign.status}_TOGGLE.TITLE`)}
            detail={w(`STATUS.${campaign.status}_TOGGLE.DESCRIPTION`)}
            validationText={w(`STATUS.${campaign.status}_TOGGLE.ACTION`)} validationColor={action_color}
            validationIcon={action_icon}
            abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
            id={`change_status_${campaign.id}`}>
            <a className="btn btn-flat transparent">
                <i className={`material-icons ${active ? "green" : "red"}-text text-darken-2`}>
                    {active ? "play_circle" : "stop_circle"}
                </i>
            </a>
        </Validator>

    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div className="row">
            <div className="col s1 valign-wrapper">
                {renderStatusButton()}
            </div>
            <h5 className="col s4 valign-wrapper" style={{margin: 0}}>{campaign.name}</h5>
            <h5 className="col s6 valign-wrapper" style={{margin: 0}}>{campaign.shop_name}</h5>
            <div className="col s1 valign-wrapper">
                <ZoneVisualizationModal coordinates={OpticianCampaignsStore.getShop(campaign)?.coordinates}
                                        radius={campaign.radius}
                                        onValidation={(radius) => OpticianCampaignsStore.saveCampaign(campaign.id, {radius: radius})}/>
                <a className="btn-small btn-flat transparent" style={{padding: 0}}
                   onClick={() => OpticianCampaignsStore.selectCampaign(campaign)}>
                    <i className={`material-icons`}>
                        {OpticianCampaignsStore.selectedCampaign?.id !== campaign.id ? "analytics" : "close"}
                    </i>
                </a>
            </div>
        </div>
    </div>
})

export default CampaignLine;