import React from "react"
import axios from "axios"
import {toast} from 'react-toastify';
import {t} from "../../I18n/i18n"


class Mail extends React.Component {
    constructor(props) {
        super(props);
        this.handeWriting = this.handeWriting.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this)
        this.sendEmail = this.sendEmail.bind(this)
        this.modelOne = this.modelOne.bind(this)
        this.modelTwo = this.modelTwo.bind(this)
        this.vider = this.vider.bind(this)
        this.state = {
            email: "",
            text: '',
            sujet: ""

        };
    }

    onEditorStateChange(editorState) {
        this.setState({});
    }

    vider(e) {
        e.preventDefault()
        this.setState({text: ""})
    }

    handeWriting(e) {
        this.setState({text: e.target.value})
    }


    sendEmail(e) {
        e.preventDefault()
        axios.post("/secretariat/sendEmail", this.state).then((data) => {
            toast.success(t("SECRETARIAT_MAIL_PAGE.MESSAGE_SENT"), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        })
    }

    componentDidMount() {
        this.setState({
            text: "",
            email: this.props.patient.email
        })
    }

    modelOne() {
        let txt = `

Bonjour  ${this.props.patient.nom} ${this.props.patient.prenom}

Suite à votre Rendez vous de La Bassée/Saint Omer/Orchies, vous deviez être revu par le médecin ophtalmologue sur le cabinet de La Madeleine. Or vous ne souhaitez pas venir jusque La Madeleine.
Sachez que c'est important que vous soyez revu par un ophtalmologue, l'examen avec l'orthoptiste n'est pas suffisant.
Auquel cas je me tiens à votre disposition au 03.20.32.16.13 afin de vous refixer un RDV.

Dr STANESCU

    `
        this.setState({text: txt})
    }

    modelTwo() {
        let txt = `

Bonjour  ${this.props.patient.nom} ${this.props.patient.prenom}

Suite à votre Rendez vous de La Bassée/Saint Omer/Orchies, vous deviez être revu par le médecin ophtalmologue sur le cabinet de La Madeleine. Or vous ne vous êtes pas présenté à votre rendez vous du  (DATE CONSULT). Sachez que l'examen avec l'orthoptiste n'est pas suffisant.
Si vous souhaitez reprendre rendez-vous n'hésitez pas à nous rappeler au 03.20.32.16.13.

Dr Stanescu

Merci beaucoup.
Si besoin je me tiens à votre disposition Mon n°personnel 0602665218


    `
        this.setState({text: txt})
    }

    render() {
        const {editorState} = this.state;

        return (
            <div id="full-height">
                <div className="container" style={{display: "flex", flexDirection: "column", height: "100%", padding: 20}}>
                    <h1 className="center">{t("SECRETARIAT_MAIL_PAGE.SEND_AN_EMAIL")}</h1>
                    <div className="row" style={{gap: 20, height: "100%"}}>
                        <div className="col s9" style={{height: "100%"}}>
                            <div style={{display: "flex", flexDirection: "column", gap: 20, height: "100%"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div className="input-field outlined" style={{width: "50%", minWidth: 200}}>
                                        <input type="email" id="email" value={this.state.email}
                                               onChange={(e) => this.setState({email: e.target.value})}/>
                                        <label htmlFor="email">{t("SECRETARIAT_MAIL_PAGE.ADDRESSEE")}</label>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: "center", width: "50%"}}>
                                        <a onClick={this.sendEmail} className="blue darken-2 z-depth-0 btn" title={t("SECRETARIAT_MAIL_PAGE.SEND_THE_EMAIL")}><i
                                            className="material-icons left">send</i>{t("SEND")}</a>
                                    </div>
                                </div>
                                <div className="input-field outlined">
                                    <input type="text" id="sujet" value={this.state.sujet}
                                           onChange={(e) => this.setState({sujet: e.target.value})}/>
                                    <label htmlFor="sujet">{t("SECRETARIAT_MAIL_PAGE.SUBJECT")}</label>
                                </div>
                                <div className="input-field outlined" style={{height: "100%"}}>
                                    <textarea style={{height: "100%", padding: 20}} onChange={this.handeWriting} value={this.state.text}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col s3" style={{display: "flex", flexDirection: "column", marginTop: 300}}>
                            <a onClick={this.vider} className="red darken-2 z-depth-0 btn"><i
                                className="material-icons left">delete</i>{t("SECRETARIAT_MAIL_PAGE.CLEAN")}</a>
                            <div className="divider"/>
                            <h6>{t("SECRETARIAT_MAIL_PAGE.INSERT")}</h6>
                            <a onClick={this.modelOne} className="green darken-2 z-depth-0 btn" title="Déplacement important"><i
                                className="material-icons left">label</i>dépl. imp.</a>
                            <a onClick={this.modelTwo} className="green darken-2 z-depth-0 btn" title="Rendez-vous manqué"><i
                                className="material-icons left">label</i>rdv manqué.</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Mail
