import React, {useEffect} from "react";
import CancelButton from "../../../Orthoptistes/Appointment/CancelButton";
import Cookies from "js-cookie";
import axios from "axios";
import MesureStore from "../MesureStore";
import {toast} from "react-toastify";
import Helper from "../../../Shared/Helper";
import {ActOrderEnum} from "./ActOrderEnum";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import {Act, ActNature, RouteurState} from "../../../Shared/Enums/Enums";

const _ = require("lodash")

const extractFirstDecimal = (str) => {
    const match = str.match(/-?\d+(\.\d+)?/);
    return match ? parseFloat(match[0]) : null;
}

function ActSelection({acts, chains, changeState, patient}) {
    const [patientAge, setPatientAge] = React.useState(Helper.dateToAge(patient.birthday))

    acts = acts.reduce((acc, current_value) => {
        if (current_value.nature in acc) acc[current_value.nature].push(current_value)
        else acc[current_value.nature] = [current_value]
        return acc
    }, {})
    acts = Object.entries(acts).reduce((acc, [key, value]) => {
        acc[key] = value.sort((a, b) => {
            const dec_a = extractFirstDecimal(a.titre)
            const dec_b = extractFirstDecimal(b.titre)
            if (dec_a && dec_b) {
                return dec_a - dec_b
            }
        })
        return acc
    }, {})

    useEffect(() => {
        setPatientAge(Helper.dateToAge(patient.birthday))
    }, [patient])

    const startAppointmentinChain = (chain) => {
        axios.post("/api/saveActesAndPayment", {
            actes: [chain.act],
            appointement_id: MesureStore.appointement_id
        }).then((resp) => {
            axios.patch("/orthoptistes/chained_acts/start", {
                chain_id: chain.id,
                appointment_id: MesureStore.appointement_id
            }).then((resp) => {
                changeState(RouteurState.IN_APPOINTMENT)
                MesureStore.selectedChain = chain
                MesureStore.actSaved(true)
                // MesureStore.acte = [chain.act]
                MesureStore.selected_act = chain.act
            }).catch((err) => {
                toast.error("Impossible de lancer le rendez-vous. Veuillez réessayer")
            })
        })
    }

    const startAppointment = (act) => {
        axios.post("/api/saveActesAndPayment", {
            actes: [act],
            appointement_id: MesureStore.appointement_id
        }).then((resp) => {
            changeState(RouteurState.IN_APPOINTMENT)
            MesureStore.actSaved(true)
            // MesureStore.acte = act
            MesureStore.selected_act = act
        })
    }

    const render_no_patient = () => {
        return <div style={{display: "flex", gap: 20, alignItems: "center", margin: 20}}>
            <h4 style={{whiteSpace: "nowrap", margin: 0}}>Patient absent</h4>
            <CancelButton name="act_selection_cancel_btn" id={MesureStore.appointement_id} onCancel={
                () => {
                    Cookies.remove('last_appointements_data_done')
                    Cookies.remove('last_appointement_id')
                    Cookies.remove('last_appointement_done')
                    Cookies.remove('appointement_id')
                    window.history.back()
                }
            }/>
        </div>
    }

    const locked = (act) => {
        switch (act.titre) {
            case Act.RNM:
            case Act.RNO:
                return patientAge >= 50 || patientAge < 6
            default:
                return false
        }
    }

    const render_orthoptic_acts = (nature) => {
        const enabled = MesureStore.orthoptiste.orthoptic_acts_enabled
        return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <h4 className="valign-wrapper" style={{margin: 0}}>{ActNature[nature].description}&nbsp; {enabled ? <div/> :
                <i className="tiny material-icons grey-text"
                   title="Les actes orthoptiques ne sont pas disponibles avec les réglages actuels. Contacter l'assistance pour y remédier !"
                >help</i>
            }</h4>
            <div className="row" style={{gap: '5px 20px'}}>
                {acts[nature].sort((a, b) => a.prix > b.prix ? 1 : -1).map((act, index) =>
                    <div key={`${nature}_${index}`}
                         className="col s3">
                        <a className={`btn blue darken-2 z-depth-0 ${enabled && !locked(act) ? "" : "disabled"}`}
                           style={{width: "100%"}} title={act.description}
                           data-cy="OrthopticActs"
                           onClick={() => startAppointment(act)}>
                            {act.titre}
                        </a>
                    </div>
                )}
            </div>
        </div>
    }

    const render_teletransmission_acts = () => {
        const enabled = MesureStore.orthoptiste.telemedecine_enabled
        return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <h4 className="valign-wrapper" style={{margin: 0}}>{ActNature.TELEMEDICAL.description}&nbsp;{enabled ?
                <div/> :
                <i className="tiny material-icons grey-text"
                   title="Les actes de télémédecine ne sont pas disponibles avec les réglages actuels. Contacter l'assistance pour y remédier !"
                >help</i>
            }</h4>
            <div className="row" style={{gap: '5px 20px'}}>
                {acts[ActNature.TELEMEDICAL.name].map((act, index) =>
                    <div key={`${ActNature.TELEMEDICAL.name}_${index}`}
                         className="col s3">
                        <a className={`btn blue darken-2 z-depth-0 ${enabled && !locked(act) ? "" : "disabled"}`}
                           style={{width: "100%"}} title={act.description}
                           data-cy="TelemedicineActs"
                           onClick={() => startAppointment(act)}>
                            {act.titre}
                        </a>
                    </div>
                )}
            </div>
        </div>
    }

    const render_nurse_acts = () => {
        return <div>
            <h4 className="valign-wrapper">{ActNature.NURSE.description}</h4>
            <div className="row" style={{gap: '5px 20px'}}>
                {acts[ActNature.NURSE.name].map((act, index) =>
                    <div key={`${ActNature.TELEMEDICAL.name}_${index}`}
                         className="col s3">
                        <a className={`btn blue darken-2 z-depth-0`}
                           style={{width: "100%"}} title={act.description}
                           data-cy="NurseActs"
                           onClick={() => startAppointment(act)}>
                            {act.titre}
                        </a>
                    </div>
                )}
            </div>
        </div>
    }

    const render_screening_acts = () => {
        return (
            <div>
                <h4 className="valign-wrapper">{ActNature.SCREENING.description}</h4>
                <div className="row" style={{gap: '5px 20px'}}>
                    {acts[ActNature.SCREENING.name].map((act, index) =>
                        <div key={`${ActNature.TELEMEDICAL.name}_${index}`}
                             className="col s3">
                            <a className={`btn blue darken-2 z-depth-0`}
                               data-cy="ScreeningActs"
                               style={{width: "100%"}} title={act.description}
                               onClick={() => startAppointment(act)}>
                                {act.titre}
                            </a>
                        </div>
                    )}
                </div>
            </div>

        )
    }
    const render_acts = () => {

        for (let type in acts) {
            for (let i = 0; i < ActOrderEnum[type].length; i++) {
                let old_index = acts[type].findIndex((act) => act.id === ActOrderEnum[type][i].id)
                Helper.moveItemToGivenIndex(acts[type], old_index, ActOrderEnum[type][i].place_order)
            }
        }

        if (MesureStore.orthoptiste.is_nurse) {
            return render_nurse_acts()
        }
        if (MesureStore.orthoptiste.is_screening) {
            return render_screening_acts()
        }

        return <div style={{display: "flex", flexDirection: "column", gap: 40}}>
            {render_teletransmission_acts()}
            {Object.keys(acts).map((nature, index) => {
                if ([ActNature.NURSE.name,ActNature.SCREENING.name, ActNature.TELEMEDICAL.name].includes(nature)) return null
                return <div key={index}>{render_orthoptic_acts(nature)}</div>
            })}
        </div>
    }

    if (_.isEmpty(chains)) {
        return <div className="container" style={{padding: 20}}>
            {render_acts()}
        </div>
    }

    return <div style={{margin: 20}}>
        <BorderedDisplay text="Une ou plusieurs série(s) d'actes disponible(s)">
            <div style={{display: "flex", gap: 20, flexWrap: "wrap", margin: "0 40px"}}>
                {chains.map((chain, index) =>
                    <a key={index} className="btn blue darken-2 z-depth-0"
                       onClick={() => startAppointmentinChain(chain)}>
                        Démarrer consultation {chain.act.titre} (acte {chain.amount - chain.nb_remaining + 1}/{chain.amount})
                        <i className="material-icons left">play_arrow</i>
                    </a>
                )}
            </div>
        </BorderedDisplay>
        <br/>
        {render_acts()}
    </div>

}

export default ActSelection;