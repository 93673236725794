import React, {Component} from "react";
import EditInput from "./EditInput";

export default class InputBlockEdit extends Component {
    constructor(props) {
        super(props);
        this.addBlock = this.addBlock.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.state = {
            inputs: []
        }
    }

    componentDidMount() {
        this.setState({
            inputs: this.props.inputs
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                inputs: this.props.inputs
            })
        }
    }

    addBlock(type) {
        this.state.inputs.push({text: "", name: "Nouveau", id: Date.now(), type: type})
        this.props.onChange(this.state.inputs)
    }

    onChange(input) {
        this.setState({
            inputs: this.state.inputs.map((i) => {
                return input.id === i.id ? input : i
            })
        }, () => this.props.onChange(this.state.inputs))
    }

    onDelete(input) {
        this.state.inputs.splice(this.state.inputs.indexOf(input), 1)
        this.props.onChange(this.state.inputs)
    }

    render() {
        if (this.state.inputs) {
            return (
                <div style={{width: "100%"}}>
                    <ul className="collection">
                        {this.state.inputs.map((input, index) =>
                            <li className="collection-item" key={index} style={{padding: 0}}>
                                <EditInput input={input}
                                           onChange={(input) => this.onChange(input)}
                                           onDelete={(input) => this.onDelete(input)}
                                />
                            </li>
                        )}
                        <li style={{backgroundColor: "#f6f6f6"}}>
                            <div style={{display: "flex", flexDirection: "row", gap: 10, padding: 5}}>
                                <h6>Ajouter la section suivante</h6>
                                <a onClick={() => this.addBlock("TEXT")} title="Ajouter un champ texte"
                                   style={{marginLeft: "auto"}}
                                   className={`z-depth-0 btn-small blue darken-2 ${this.state.inputs.length >= 3 ? "disabled" : ""}`}><i
                                    className="material-icons left">add</i>
                                    Texte
                                </a>
                                <a onClick={() => this.addBlock("SELECT")} title="Ajouter un déroulant de choix"
                                   className={`z-depth-0 btn-small blue darken-2 ${this.state.inputs.length >= 3 ? "disabled" : ""}`}>
                                    <i className="material-icons left">add</i>
                                    Liste
                                </a>
                                <a onClick={() => this.addBlock("IMAGE")} title="Ajouter un champ d'ajout d'image"
                                   className={`z-depth-0 btn-small blue darken-2 ${this.state.inputs.length >= 3 ? "disabled" : ""}`}>
                                    <i className="material-icons left">add</i>
                                    Image
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            );
        } else return null
    }
}

InputBlockEdit.defaultProps = {
    inputs: []
}