import {makeObservable, observable} from "mobx"
import axios from "axios"
import {toast} from "react-toastify";
import {notification} from "antd";

const _ = require('lodash')

class GlobalNotificationsStore {
    @observable notifications = []
    @observable onEditNotification = null

    constructor() {
        makeObservable(this)
    }

    getNotifications = () => {
        axios.get("/admin/console/global_notifications").then((response) => {
            this.notifications = response.data
        })
    }


    createNotification = (notification) => {
        return axios.post("/admin/console/global_notifications", notification).then((response) => {
            this.notifications = this.notifications.concat(response.data)
        })
    }

    updateNotification = (notification) => {
        return axios.patch(`/admin/console/global_notifications/${notification.id}`, notification).then((response) => {
            this.notifications = this.notifications.map((n) => {
                this.onEditNotification = null
                if (n.id === notification.id) return response.data
                return n
            })
        })
    }


    deleteNotification = (id) => {
        return axios.delete(`/admin/console/global_notifications/${id}`).then(() => {
            this.notifications = this.notifications.filter((notification) => notification.id !== id)
        })
    }

    notify = (notification, userTypes = []) => {
        return axios.post(`/admin/console/global_notifications/${notification.id}/notify`, {user_types: userTypes}).then(() => {
            toast.success("Notification envoyée")
        })
    }
}

let store = new GlobalNotificationsStore
export default store