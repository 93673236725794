import {ToastContainer} from 'react-toastify'
import React from "react";

function Toastr() {
    return <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
    />
}

export default Toastr;