import React from 'react';
import NavBar from "./NavBar";
import MessengerButton from "../../Messenger/MessengerButton";
import UserDropdown from "./UserDropdown";
import {wrapper} from '../../I18n/i18n'

export default function OphtalmologistNavbar({image_path, user, configuration, topbar_prop = ""}) {
    const {c} = wrapper("NAVBAR");
    const {is_stripe_paid, superadmin} = configuration
    const components = [
        {component: <MessengerButton/>, dataCy: "MessengerButton"},
        {component: <UserDropdown user={user}/>, dataCy: "UserDropdown"},
    ]

    const urls = [
        {
            name: c("ADMIN"),
            dropdown: [
                {name: c("PRACTICE_MANAGEMENT"), url: "/cabinets"},
                {name: c("MESSAGES_MANAGEMENT"), url: "/admin/textemessages/index"},
                {name: c("THIRD_PARTY_SOFT"), url: "/admin/tokens/index"},
                {name: c("HEADERS"), url: "/admin/headers/header"},
                {name: c("MESSAGES"), url: "/messages/ophtalmologiste/conversations"},
                {name: c("PAYMENTS"), url: "/ophtalmologistes/paiements", show: is_stripe_paid},
                {name: c("BANKING_SETTINGS"), url: "/ophtalmologistes/paiements/customer"},
                {name: c("PARAMETERS"), url: "/parametres", show: superadmin},
                {name: c("ORTHOPTISTS"), url: "/admin/paiements/dashboard", show: superadmin}
            ],
        },
        {name: c("ALERTS"), url: "/admin/alert/index"},
        {name: c("PRESCRIPTION_CONTROL"), url: "/admin/controle_rdv"},
        {
            name: c("SECRETARY"),
            dropdown: [
                {name: c("ACCOUNTING"), url: "/admin/statistics/ophtalmologist"},
                {name: c("BLANK_EMAIL"), url: "/secretariat/email"},
                {name: c("PATIENTS"), url: "/secretariat/patients"},
                {name: c("TELETRANSMISSION"), url: "/secretariat/teletransmission"},
                {name: c("INVOICES"), url: "/ophtalmologistes/paiements/overview"},
                {name: c("RNM"), url: "/admin/statistiques/rnm"},
                {name: c("HISTORY"), url: "/admin/logs/appointments/index"}
            ]
        }
    ]

    return <NavBar image_path={image_path} urls={urls} topbar_prop={topbar_prop} components={components}/>
}
