import React, {createRef, useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {wrapper} from "../../I18n/i18n";
const {c} = wrapper("ORTHOPTIST_PHONE");
export default function SimpleCamera({handleSave, onImageCapture}){
    const videoPreview = createRef();
    const [stream, setStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const imagePreview = createRef();
    //ref for video live
    const videoLive = createRef();
    const [hasImagePreview, setHasImagePreview] = useState(false);
    const [blob, setBlob] = useState(null);



    const getVideoPermission = async () => {
        try{
            const stream_prefered = await navigator.mediaDevices.getUserMedia({
                video:  {facingMode: {exact: "environment"}},
                zoom: { ideal: 1 },
                audio: false,
              })
            setStream(stream_prefered);

        }catch (e) {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false,
            })
            setStream(stream);
        }

    }

    const onRecordingReady = (e) => {
        const videoURL = window.URL.createObjectURL(e.data);
        setRecordedVideoBlob(e.data);
        //videoPreview.current.src = videoURL;
        document.querySelector('#videoPreview').src = videoURL; // jsp pq , mais useref ne marche pas
        setRecorded(true);
    }

    if (!MediaRecorder.isTypeSupported('video/webm')) { // <2>
        console.warn('video/webm is not supported')
      }
    useEffect(() => {
        getVideoPermission();
    }, []);

    useEffect(() => {
        videoLive.current.srcObject = stream;
    }, [videoLive]);
    useEffect(() => {
            if(stream !== null) {
                const mediaRecorder = new MediaRecorder(stream, { // <3>
                    mimeType: 'video/webm',

                })
                mediaRecorder.ondataavailable = onRecordingReady;
                setMediaRecorder(mediaRecorder);

                videoLive.current.srcObject = stream;

            }
    }, [stream]);

    const takePicture = () => {
        console.log("take picture")
        const canvas = imagePreview.current;
        canvas.width = videoLive.current.videoWidth;
        canvas.height = videoLive.current.videoHeight;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(videoLive.current, 0, 0);
        const dataURI = canvas.toDataURL('image/jpeg');
        setBlob(dataURI);

        setHasImagePreview(true);
        onImageCapture(dataURI);
    }
    return (
        <div>

            {/*/* video player*/}
            <div onClick={takePicture} style={{display: !hasImagePreview ? "block" : "none"}}>
                <video autoPlay
                       muted
                       playsInline
                       id="videoLive"
                       ref={videoLive}
                       style={{maxHeight: "90vh",

                           width: "98vw",
                           margin: "auto",
                           boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
                       }}


                ></video>
            </div>


            <div className={"image-preview"} style={{display: hasImagePreview ? "block" : "none"}}>
                <canvas ref={imagePreview} style={{display: "none"}}></canvas>
                <img className={"responsive-img"} src={blob}/>
                <div className="left">
                    <button onClick={() => {
                        setHasImagePreview(false);
                    }}>{c("CANCEL")}</button>
                </div>
                <div className="right">
                    <button onClick={() => {
                        handleSave(blob);
                        setHasImagePreview(false);
                    }}>{c("SUBMIT")}</button>
                </div>
            </div>


        </div>


    )
}