import React from "react";
import CVStore from "./CVStore";
import StateButton from "../Shared/StateButton";
import {Observer} from "mobx-react";
import {hasFailed} from "../Shared/StateHelper";

const ChooseMode = (props) => {
    return (
        <Observer>
            {() => <div>
                <h3 className="center">Lancer la lecture de la Carte Vitale ?</h3>
                <div className="valign-wrapper" style={{justifyContent: "center"}}>
                    <div style={{padding: "0 1vw"}}>
                        <StateButton onClick={() => CVStore.askForCVInput(props.uid)}
                                     state={CVStore.cvRequestState} color="blue darken-2"
                                     icon="credit_card" text="Lecture carte vitale" side="left"
                        />
                    </div>
                    <div style={{padding: "0 1vw"}}>
                            <a className="btn z-depth-0 blue darken-2" title="Procéder sans carte vitale"
                               onClick={() => CVStore.triggerDegradedMode(MesureStore.patient)}>
                                <i className="material-icons left">credit_card_off</i>Mode dégradé</a>
                    </div>
                </div>
                <h6 className={`center red-text ${hasFailed(CVStore.cvRequestState) ? "" : "hide"}`} style={{fontStyle: "italic"}}>Impossible de lire la Carte Vitale de votre patient. Vérifier que votre
                    carte CPS est insérée et que vous avez rentré votre code porteur sur Medoc.</h6>
                <h6 className="center" style={{fontStyle: "italic", marginTop: "2vh"}}>
                    Nous allons nous interfacer avec le logiciel Medoc, vérifiez que ce dernier est bien lancé et que
                    vous êtes connecté.<br/>
                    N'oubliez pas d'insérer votre carte CPS et de rentrer votre code de carte dans Medoc.
                </h6>
            </div>}
        </Observer>
    );
}

export default ChooseMode