import React, {useState} from 'react'
import {toast} from "react-toastify";
import BorderedDisplay from "../Shared/UiKit/BorderedDisplay";
import MedicalEventStore from "./MedicalEventStore";
import Regex from "../Shared/Validation/Regex";

const PatientForm = ({patient, onChange, onSubmit, buttonText, buttonIcon, title, cancel, onCancel}) => {

    const validatePatient = () => {
        if (_.isEmpty(patient.firstname)) {
            toast.error("Le prénom est obligatoire")
            return false
        }
        if (_.isEmpty(patient.lastname)) {
            toast.error("Le nom est obligatoire")
            return false
        }
        if (!_.isEmpty(patient.email) && !Regex.validateEmail(patient.email)) {
            toast.error("L'email est invalide")
            return false
        }
        if (!_.isEmpty(patient.telephone) && !Regex.validatePhoneNumber(patient.telephone)) {
            toast.error("Le téléphone est invalide")
            return false
        }
        if (_.isEmpty(patient.birthday)) {
            toast.error("La date de naissance est obligatoire")
            return false
        }
        if (_.isEmpty(patient.ssn)) {
            toast.error("Le numéro de sécurité sociale est obligatoire")
            return false
        }
        if (!Regex.validateSsn(patient.ssn)) {
            toast.error("Le numéro de sécurité sociale est invalide")
            return false
        }
        if (!Helper.checkSsnKey(patient.ssn)) {
            toast.error("La clé du numéro de sécurité sociale est invalide")
            return false
        }
        if (!_.isEmpty(patient.organism_code) && !Regex.validateOrganismCode(patient.organism_code)) {
            toast.error("Le code organisme est invalide")
            return false
        }
        return true
    }

    const submit = () => {
        if (!validatePatient()) return
        onSubmit()
    }


    return <BorderedDisplay text={title}>
        <div className="valign-wrapper" style={{gap: 20, alignItems: "flex-start"}}>
            <div className="valign-wrapper" style={{gap: 20, flexWrap: "wrap", flex: 1}}>
                <div className="input-field outlined">
                    <input id="firstname" type="text" className="validate" value={patient.firstname}
                           onChange={e => onChange({...patient, firstname: e.target.value})}/>
                    <label htmlFor="firstname">Prénom</label>
                </div>
                <div className="input-field outlined">
                    <input id="lastname" type="text" className="validate" value={patient.lastname}
                           onChange={e => onChange({...patient, lastname: e.target.value})}/>
                    <label htmlFor="lastname">Nom</label>
                </div>
                <div className="input-field outlined">
                    <input id="email" type="email" className="validate" value={patient.email}
                           onChange={e => onChange({...patient, email: e.target.value})}/>
                    <label htmlFor="lastname">Email</label>
                </div>
                <div className="input-field outlined">
                    <input id="telephone" type="text" className="validate" value={patient.telephone}
                           onChange={e => onChange({...patient, telephone: e.target.value})}/>
                    <label htmlFor="lastname">Téléphone</label>
                </div>
                <div className="input-field outlined">
                    <input id="birthdate" type="date" className="validate" value={patient.birthday}
                           onChange={e => onChange({...patient, birthday: e.target.value})}/>
                    <label htmlFor="birthdate">Date de naissance</label>
                </div>
                <div className="input-field outlined">
                    <input id="phone" type="text" className="validate" value={patient.ssn}
                           onChange={e => onChange({...patient, ssn: e.target.value})}/>
                    <label htmlFor="phone">N° sécurité sociale</label>
                </div>
                <div className="input-field outlined">
                    <input id="phone" type="text" className="validate" value={patient.organism_code}
                           onChange={e => onChange({...patient, organism_code: e.target.value})}/>
                    <label htmlFor="phone">Code organisme</label>
                </div>
                <div className="input-field outlined">
                    <input id="phone" type="text" className="validate" value={patient.health_insurance}
                           onChange={e => onChange({...patient, health_insurance: e.target.value})}/>
                    <label htmlFor="phone">Mutuelle</label>
                </div>
            </div>
            <div className="fc">
                <a className="btn blue darken-2 z-depth-0 truncate" onClick={submit}>
                    <i className="material-icons left">{buttonIcon}</i>
                    {buttonText}
                </a>
                {cancel ? <a className="btn red darken-2 z-depth-0 truncate" onClick={onCancel}>
                    <i className="material-icons left">close</i>
                    Annuler
                </a> : null}
            </div>
        </div>
    </BorderedDisplay>
}

export default PatientForm