import React, {useEffect} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../../Shared/Loader";
import Helper from "../../../../Shared/Helper";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import RightsStore from "../../RightsStore";
import Error from "../../../../Shared/Error";
import {Observer} from "mobx-react";
import StatusButton from "../StatusButton";
import {Link} from "react-router-dom";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";
import {l, t} from "../../../../I18n/i18n";
import Tooltip from "../../../../Shared/Tootip"

const _ = require('lodash');

function Ophtalmologists(props) {
    const modal_id = `console_ophtalmologists_modal`
    const [ophtalmologists, setOphtalmologists] = React.useState(props.ophtalmologists)
    const [onEditOphtalmologist, setOnEditOphtalmologist] = React.useState({})
    const [filter, setFilter] = React.useState("")

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [ophtalmologists, RightsStore.rights])

    const new_ophtalmologist = () => {
        setOnEditOphtalmologist({onEdit: false})
        open_modal()
    }

    const edit_ophtalmologist = (extern) => {
        setOnEditOphtalmologist({...extern, onEdit: true})
        open_modal()
    }

    const open_modal = () => {
        setTimeout(() => {
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.open();
        }, 100)
    }

    const renderTooltip = (ophtalmologist) => {
        if (!ophtalmologist.orthoptistes.length) return <div className="grey lighten-5 z-depth-0  grey-text text-darken-3"
                                                             style={{padding: "5px 10px", borderRadius: 20, border: "thin solid #616161"}}>
            Aucun orthoptiste lié
        </div>
        const orthoptist_list = ophtalmologist.orthoptistes.reduce((acc, current_value) => acc += `${Helper.formatName(current_value.prenom, current_value.nom)}\n`, "")
        return <>
            <Tooltip id={`ophtalmologist_${ophtalmologist.id}`}
                     position="right"
                     text={orthoptist_list}>
                <div className="blue lighten-5 grey-text text-darken-3" style={{padding: "5px 10px", borderRadius: 20, border: "thin solid #1976d2"}}>
                    {ophtalmologist.orthoptistes.length} orthoptiste(s) lié(s)
                </div>
            </Tooltip>

        </>
    }

    const create_ophtalmologist = () => {
        axios.put('/admin/console/users/ophtalmologist', {
            ophtalmologist: onEditOphtalmologist
        }).then((resp) => {
            setOphtalmologists([...ophtalmologists, resp.data])
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer le cabinet")
        })
    }

    const update_ophtalmologist = (ophtalmologist) => {
        axios.patch(`/admin/console/users/ophtalmologist/${ophtalmologist.id}`, {
            ophtalmologist: ophtalmologist
        }).then((resp) => {
            setOphtalmologists(ophtalmologists.map(ophta => ophta.id === ophtalmologist.id ? resp.data : ophta))
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de modifier l'ophtalmologiste")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}>
                <h3 className="center"
                    style={{marginTop: 20}}>{onEditOphtalmologist.onEdit ? t("EDIT") : t("CREATE")} {l("USERS_CONSOLE.OPHTALMOLOGIST.NAME")}</h3>
                <div style={{flex: "1 1 0", overflowY: "scroll"}}>
                    <div className="container">
                        <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("FIRSTNAME")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate" value={onEditOphtalmologist.prenom || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               prenom: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("LASTNAME")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate" value={onEditOphtalmologist.nom || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               nom: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("EMAIL")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate" value={onEditOphtalmologist.email || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               email: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("ADDRESS")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate" value={onEditOphtalmologist.address || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               address: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("CITY")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate" value={onEditOphtalmologist.city || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               city: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("POSTAL_CODE")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate"
                                           value={onEditOphtalmologist.postal_code || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               postal_code: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("USERS_CONSOLE.OPHTALMOLOGIST.OFFICE_NAME")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate"
                                           value={onEditOphtalmologist.office_name || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               office_name: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("PASSWORD")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>
                                    <input type="text" className="validate" value={onEditOphtalmologist.password || ""}
                                           onChange={(e) => setOnEditOphtalmologist({
                                               ...onEditOphtalmologist,
                                               password: e.target.value
                                           })}/>
                                </div>
                            </div>
                            <div className="col l2 s12 valign-wrapper">
                                <span>{t("USERS_CONSOLE.OPHTALMOLOGIST.EDIT.FAST_EDIT")}</span>
                            </div>
                            <div className="col l10 s12 valign-wrapper">
                                <div className="input-field outlined" style={{width: "100%"}}>

                                    <select className={"browser-default"}
                                            value={!!onEditOphtalmologist.edit_prescription}
                                            onChange={(e) => setOnEditOphtalmologist({
                                                ...onEditOphtalmologist,
                                                edit_prescription: e.target.value
                                            })}>
                                        <option value={true}>{t("YES")}</option>
                                        <option value={false}>{t("NO")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", gap: 10, justifyContent: "right"}}>
                    {onEditOphtalmologist.onEdit ?
                        <a className="btn btn-small blue darken-2 z-depth-0"
                           onClick={() => update_ophtalmologist(onEditOphtalmologist)}>{t("VALIDATE")}</a>
                        :
                        <a className="btn btn-small blue darken-2 z-depth-0"
                           onClick={create_ophtalmologist}>{t("CREATE")}</a>}
                    <a className="modal-close btn btn-small red darken-2 z-depth-0">{t("CANCEL")}</a>
                </div>
            </div>

        </div>
    }

    const renderOphtalmologists = (ophtalmologist) => {
        return (
            <div className="collection-item transparent" key={ophtalmologist.id}
                 style={{display: "flex", width: "100%", gap: 10}}>
                <div style={{
                    width: "100%",
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 10
                }}>
                    <div style={{width: "fit-content", margin: 0}}>
                        <StatusButton status={ophtalmologist.status} id={ophtalmologist.id}
                                      onChange={(value) => {
                                          if (ophtalmologist.status !== value) {
                                              update_ophtalmologist({...ophtalmologist, status: value})
                                          }
                                      }}
                        />
                    </div>
                    <div style={{width: 40, margin: 0}}>
                        <span>#{ophtalmologist.id}</span>
                    </div>
                    <div style={{width: 300, margin: 0}}>
                        <span>{Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom)}</span>
                    </div>
                    <div style={{width: 300, margin: 0}}>
                        <span>{ophtalmologist.email}</span>
                    </div>
                    {renderTooltip(ophtalmologist)}
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <div style={{flex: 1}}>
                            <ResponsiveMoreButton id={`ophtalmologist_${ophtalmologist.id}`} options={[
                                <a className="btn btn-small blue darken-2 z-depth-0"
                                   onClick={() => edit_ophtalmologist(ophtalmologist)}>
                                    <i className="material-icons left">edit</i>
                                    {t("EDIT")}
                                </a>,
                                <Link to={`/ophtalmologists/${ophtalmologist.id}`}
                                      className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">dashboard</i>
                                    {t("CONSOLE.DASHBOARD")}
                                </Link>
                            ]}/>
                        </div>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    if (_.isEmpty(ophtalmologists)) {
        return <div style={{margin: 20}}>
            <Loader size="tiny" text=""/>
        </div>
    }

    const render = () => {
        if (RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY) === undefined) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Loader text=""/>
            </div>
        }
        if (!RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY)) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
            </div>
        }

        return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
            <h3 className="valign-wrapper" style={{margin: 20}}>
                <i className="material-icons left">preview</i>
                {t("USERS_CONSOLE.OPHTALMOLOGIST.PLURAL")}
            </h3>

            <div className="divider"/>
            <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div className="input-field outlined">
                        <input type="text" style={{height: 40}} placeholder={t("SEARCH")} autoFocus
                               placeholder={"Chercher un opthalmologiste"}
                               value={filter} onChange={e => setFilter(e.target.value)}/>
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <a className="btn btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
                           onClick={() => setOnEditOphtalmologist({onEdit: false})}>
                            <i className="material-icons left">add</i>
                            {t("NEW")}
                        </a>
                    </RestrictedElement>
                </div>

                <div className="collection"
                     style={{borderRadius: 5, height: "100%", overflowY: "scroll"}}>
                    {ophtalmologists.filter(ophta => {
                        const str = `${ophta.nom}${ophta.prenom}${ophta.email}`.toLocaleLowerCase()
                        const matched_orthoptist = ophta.orthoptistes.some((orthoptist) => {
                            const str = `${orthoptist.nom} ${orthoptist.prenom}`.toLocaleLowerCase()
                            return str.includes(filter.toLowerCase())
                        })
                        if (matched_orthoptist) return true
                        return str.includes(filter.toLowerCase())
                    })
                        .sort((a, b) => (a.nom || "zzz") > (b.nom || "aaa")).map((ophtalmologist) => {
                            return renderOphtalmologists(ophtalmologist)
                        })}
                </div>
                <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                    {renderModal()}
                </RestrictedElement>
            </div>
        </div>
    }

    return <Observer>
        {() => render()}
    </Observer>
}

export default Ophtalmologists;