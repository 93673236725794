import {makeObservable, observable} from "mobx";
import axios from "axios";
import {useEffect} from "react";

class PhoneStore {
    @observable appointment_id = {}

    constructor() {
        makeObservable(this)
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
    }

    savePicture = (data) => {
        console.log("photo")
        const formData = new FormData();
        formData.append('image', data);
        formData.append('appointment_id', this.appointment_id);
        return axios.post('/orthoptistes/phone/eye_fundus', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
    }

    saveVideo = (data) => {
        const formData = new FormData();
        formData.append('video', data);
        formData.append('appointment_id', this.appointment_id);
        return axios.post('/orthoptistes/phone/slitlamp', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    saveMultipleFiles = (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }
        formData.append('appointment_id', this.appointment_id);

        return axios.post('/orthoptistes/phone/media', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

let store = new PhoneStore
global.PhoneStore = store
export default store
