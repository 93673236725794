import React, {useEffect} from 'react';
import logoTemeoo from '../../../../public/imgs/logo-temeoo-new.svg';
import Helper from "../Shared/Helper";
import BrandedElement from "../Shared/BrandedElement";
import {capitalize, t as t_i18n} from "../I18n/i18n";

const PrescriptorNavbar = ({prescripteur}) => {

    const t = (key) => capitalize(t_i18n("USER DROPDOWN." + key))

    useEffect(() => {
        const elems = document.querySelectorAll('.prescriptor-dropdown');
        const instances = M.Dropdown.init(elems, {coverTrigger: false, constrainWidth: false});

        return () => {
            instances.forEach(instance => instance.destroy());
        }
    }, []);

    const initials = (prescripteur) => {
        const initials = (prescripteur.firstname?.charAt(0)?.toUpperCase() ?? "") + (prescripteur.lastname?.charAt(0)?.toUpperCase() ?? "");
        return initials ? initials : (prescripteur.email?.charAt(0)?.toUpperCase() ?? "?");
    }

    return <nav>
        <div className={"nav-wrapper"}>
            <a href="/" className="brand-logo valign-wrapper" style={{height: "90px"}}>
                <img height="45px" src={logoTemeoo} alt="Temeoo Logo"/>
            </a>
            <ul className="right" style={{height: "100%"}}>
                <li style={{height: "100%"}}>
                    <a className="prescriptor-dropdown" data-target='prescriptor-dropdown'
                       style={{height: "100%", display: "flex", alignItems: "center"}}>
                            <span className="circle"
                                  style={{backgroundColor: "#1976d2", color: "white"}}>
                                {initials(prescripteur)}
                            </span>
                    </a>
                    <ul id="prescriptor-dropdown" className="dropdown-content">
                        <li className="user_dropdown_header">
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                justifyContent: "center",
                                margin: 20
                            }}>
                                <h5 style={{fontWeight: "bold"}}>{Helper.formatName(prescripteur.firstname, prescripteur.lastname)}</h5>
                                <h5>{prescripteur.email}</h5>
                            </div>
                        </li>
                        <li className="divider" tabIndex="-1"></li>
                        {/*<li>*/}
                        {/*    <a href="https://temeoo.fr/documentation" target="_blank">{t("HELP")}</a>*/}
                        {/*</li>*/}
                        <BrandedElement temeoo>
                            <li>
                                <a href="https://temeoo.atlassian.net/servicedesk/customer/portal/1"
                                   target="_blank">
                                    {t("CONTACT_SUPPORT")}
                                </a>
                            </li>
                        </BrandedElement>
                        <li>
                            <a data-cy="MainDisconnect" href="/prescriptors/sign_out"
                               data-method={"delete"}>{t("LOGOUT")}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
}

export default PrescriptorNavbar;