import Validator from "../../Shared/Validator";
import React from "react";
import axios from "axios";
import {wrapper} from "../../I18n/i18n";

const AskForReworkButton = ({appointment_id, reworkable, onValidation, big}) => {
    const {t} = wrapper("EXAM_HISTORY.REWORK")
    const askForRework = (reason) => {
        reason = reason || ""
        axios.post(`/admin/appointement/rework?id=${appointment_id}`, {reason: reason}).then(() => {
            onValidation(appointment_id)
        })
    }

    const renderButton = () => {
        if(big) return <a style={{borderRadius: "10px"}} className="btn-large blue darken-2 truncate-nohide z-depth-0">
            {t("REWORK_OPHTA")}
        </a>
        return <a className={`btn-small blue darken-2 z-depth-0 ${reworkable || "disabled"}`}>
            <i className="material-icons left hide-on-med-and-down">undo</i>
            <i className="material-icons hide-on-large-only">undo</i>
            <span>{t("TO_REWORK")}</span>
        </a>
    }

    return <Validator id={`ask_for_rework_${appointment_id}`}
                      onValidation={(reason) => askForRework(reason)}
                      text={t("VALIDATION_TEXT")}
                      detail={t("VALIDATION_DETAIL")}
                      validationColor="blue darken-2" validationIcon="undo"
                      validationText={t("VALIDATION")}
                      abortColor="black" abortIcon="close"
                      input placeholder={t("PLACEHOLDER")}
    >
        {renderButton()}
    </Validator>
};

AskForReworkButton.defaultProps = {
    reworkable: true,
    onValidation: () => {
    },
    big: false
}

export default AskForReworkButton;