import React, {useEffect} from 'react';
import {HashRouter as Router, NavLink, Route, Routes} from "react-router-dom";
import BillingConsole from "./Billing/BillingConsole";
import PricingConsole from "./Prices/PricingConsole";
import TeletransmissionConsole from "././Teletransmission/TeletransmissionConsole";
import DashboardConsole from "./Dashboard/DashboardConsole";
import ActsConsole from "./Acts/ActsConsole";
import UserPaiements from "././UserStatuses/UserPaiements";
import UserStatusesConsole from "./UserStatuses/UserStatusesConsole";
import OphtalmologistsStatistics from "./OphtalmologistsStatistics/OphtalmologistsStatistics";
import SocketsDebugger from "./Devs/Sockets/SocketsDebugger";
import PaiementsStatistics from "./Summary/Paiements";
import {TeamCabinets, TeamCabinetsEdit} from "./Team/TeamCabinets";
import {TeamOphtalmologists} from "./Team/TeamOphtalmologists";
import TeamSecretaries from "./Team/TeamSecretaries";
import ScheduledAppointmentsConsole from "./ScheduledAppointments/ScheduledAppointmentsConsole";
import DataConsole from "./Data/DataConsole";
import SecurityConsole from "./Security/SecurityConsole";
import {Role} from "../../Shared/Enums/Scope";
import ScopedElement from "../../Shared/ScopedElement";
import Orthoptists from "./Users/Orthoptists/Orthoptists";
import OrthoptistPricing from "./Users/Orthoptists/OrthoptistPricing";
import InternalUsers from "./Users/InternalUsers/InternalUsers";
import ConnectAs from "../../Login/ConnectAs";
import Helper from "../../Shared/Helper";
import RightsAndRoles from "./Users/InternalUsers/RightsAndRoles";
import {Observer} from "mobx-react";
import Offices from "./Users/Offices/Offices";
import Ophtalmologists from "./Users/Ophtalmologists/Ophtalmologists";
import Secretaries from "./Users/Secretaries/Secretaries";
import Externs from "./Users/Externs/Externs";
import BillingSettings from "./Orthoptists/Billing/BillingSettings";
import Ophtalmologist from "./Users/Ophtalmologists/Ophtalmologist";
import Orthoptist from "./Users/Orthoptists/Orthoptist";
import Extern from "./Users/Externs/Extern";
import Secretary from "./Users/Secretaries/Secretary";
import HnSettings from "./Ophtalmologists/Billing/HnSettings";
import Patients from "./Users/Patients/Patients";
import PatientDashboard from "./Users/Patients/PatientDashboard";
import PaymentErrorsConsole from "./PaiementsErrors/PaiementsErrorsConsole";
import ApplicationMonitoringConsole from "./Monitoring/Applications/ApplicationMonitoringConsole";
import OfficeDashboard from "./Users/Offices/OfficeDashboard";
import Opticians from "./Users/Opticians/Opticians";
import MedicalEvents from "./MedicalEvents/MedicalEvents";
import CreateMedicalEvent from "./MedicalEvents/CreateMedicalEvent";
import PaymentTracker from "./Prices/PaymentTracker";
import PaiementErrorDetails from "./PaiementsErrors/PaiementErrorDetails";
import GlobalNotificationConsole from "./GlobalNotifications/GlobalNotificationConsole";

function ConsoleRouter({orthoptists, ophtalmologists, secretaries, externs, internal_users, offices, opticians}) {

    // GENERAL //////////////////////////////////////////////////////////////////////
    const DASHBOARD = {
        name: "Tableau de bord",
        icon: "speed",
        path: "/",
        element: <DashboardConsole orthoptists={orthoptists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }
    const BILLING = {
        name: "Facturation",
        icon: "receipt",
        path: "/billing",
        element: <BillingConsole orthoptists={orthoptists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]

    }
    const TELETRANSMISSION = {
        name: "Télétransmission",
        icon: "rss_feed",
        path: "/teletransmission",
        element: <TeletransmissionConsole orthoptists={orthoptists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }
    const ACTS = {
        name: "Actes", icon: "medical_services", path: "/acts", element: <ActsConsole/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }
    const DATA = {
        name: "Données",
        icon: "monitor_heart",
        path: "/data",
        element: <DataConsole orthoptists={orthoptists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]

    }
    const SCHEDULED_APPOINTMENTS = {
        name: "Examens futurs",
        icon: "calendar_month",
        path: "/scheduled_appointments",
        element: <ScheduledAppointmentsConsole/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]

    }

    // USERS //////////////////////////////////////////////////////////////////////

    const ORTHOPTISTS = {
        name: "Orthoptistes",
        icon: "remove_red_eye",
        path: "/orthoptists",
        element: <Orthoptists orthoptists={orthoptists} offices={offices}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const OPHTALMOLOGISTS = {
        name: "Ophtalmologistes",
        icon: "preview",
        path: "/ophtalmologists",
        element: <Ophtalmologists ophtalmologists={ophtalmologists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const SECRETARIES = {
        name: "Secrétaires",
        icon: "phone_in_talk",
        path: "/secretaries",
        element: <Secretaries ophtalmologists={ophtalmologists} secretaries={secretaries}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const EXTERNS = {
        name: "Externes",
        icon: "alt_route",
        path: "/externs",
        element: <Externs externs={externs} offices={offices}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const PATIENTS = {
        name: "Patients", icon: "personal_injury", path: "/patients", element: <Patients/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const OFFICES = {
        name: "Cabinets",
        icon: "business",
        path: "/offices",
        element: <Offices externs={externs} offices={offices} ophtalmologists={ophtalmologists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const OPTICIANS = {
        name: "Opticiens",
        icon: "storefront",
        path: "/opticians",
        element: <Opticians/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const GLOBAL_NOTIFICATIONS = {
        name: "Notifications globales",
        icon: "campaign",
        path: "/notifications/global",
        element: <GlobalNotificationConsole/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN]
    }

    // MEDICAL EVENTS //////////////////////////////////////////////////////////////////////

    const MEDICAL_EVENTS = {
        name: "Évènements",
        icon: "calendar_month",
        path: "/medical_events",
        element: <MedicalEvents/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    // BILLING /////////////////////////////////////////////////////////////////////////////

    const ORTHOPTISTS_BILLING_SETTINGS = {
        name: "Orthoptistes",
        icon: "remove_red_eye",
        path: "/orthoptists/billing_settings",
        element: <BillingSettings/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const OPHTALMO_PRICES = {
        name: "Ophtalmologistes",
        icon: "preview",
        path: "/prices",
        element: <PricingConsole orthoptists={orthoptists} ophtalmologists={ophtalmologists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN]

    }

    const OPHTALMOLOGISTS_HN_SETTINGS = {
        name: "Hors-Nomenclatures", icon: "payments", path: "/ophtalmologists/hn_settings", element: <HnSettings/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const PAYMENT_TRACKER = {
        name: "Suivi des paiements",
        icon: "crisis_alert",
        path: "/payment_tracker",
        element: <PaymentTracker/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }
    // MONITORING ////////////////////////////////////////////////////////////////////////

    const OPHTALMOLOGIST_STATISTICS = {
        name: "Ophtalmologiste",
        icon: "timeline",
        path: "/ophtalmologist/statistics",
        element: <OphtalmologistsStatistics/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const APPLICATIONS_MONITORING = {
        name: "Suivi des applications",
        icon: "query_stats",
        path: "/monitoring/applications",
        element: <ApplicationMonitoringConsole/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN]
    }

    // TEAMS ////////////////////////////////////////////////////////////////////////////

    const TEAM_CABINETS = {
        name: "Cabinets", icon: "business", path: "/team/cabinets", element: <TeamCabinets/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const TEAM_OPHTALMOLOGISTS = {
        name: "Ophtalmologistes",
        icon: "people",
        path: "/team/ophtalmologists",
        element: <TeamOphtalmologists ophtalmologists={ophtalmologists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    const TEAM_SECRETARIES = {
        name: "Secrétaires",
        icon: "people",
        path: "/team/secretaries",
        element: <TeamSecretaries ophtalmologists={ophtalmologists}/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }

    // SUPER ADMIN //////////////////////////////////////////////////////////////////////

    const INTERNAL_USERS = {
        name: "Internes",
        icon: "people",
        path: "/internal_users",
        element: <InternalUsers internal_users={internal_users}/>,
        scope: [Role.SUPER_ADMIN]
    }

    const RIGHTS_AND_ROLES = {
        name: "Droits d'utilisation",
        icon: "admin_panel_settings",
        path: "/rights_and_roles",
        element: <RightsAndRoles/>,
        scope: [Role.SUPER_ADMIN]
    }

    const PAIEMENT_ERRORS = {
        name: "Erreurs de paiement",
        icon: "error",
        path: "/paiement_errors",
        element: <PaymentErrorsConsole/>,
        scope: [Role.SUPER_ADMIN]
    }

    const PAIEMENT_STATUSES = {
        name: "Etat des configurations",
        icon: "flag",
        path: "/paiements/statuses/users",
        element: <UserStatusesConsole/>,
        scope: [Role.SUPER_ADMIN]
    }

    // OTHERS //////////////////////////////////////////////////////////////////////

    const SECURITY = {
        name: "Sécurité", icon: "security", path: "/security", element: <SecurityConsole/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
    }


    const CONNECT_AS = {
        name: "Connexion", icon: "key", path: "/connect_as", element: <ConnectAs users={
            [...orthoptists.map(orthoptist => {
                return {
                    id: orthoptist.id,
                    name: Helper.formatName(orthoptist.prenom, orthoptist.nom),
                    email: orthoptist.email,
                    role: Role.ORTHOPTIST
                }
            }),
                ...ophtalmologists.map(ophtalmologist => {
                    return {
                        id: ophtalmologist.id,
                        name: Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom),
                        email: ophtalmologist.email,
                        role: Role.OPHTALMOLOGIST
                    }
                }),
                ...secretaries.map(secretary => {
                    return {
                        id: secretary.id,
                        name: Helper.formatName(secretary.prenom, secretary.nom),
                        email: secretary.email,
                        role: Role.SECRETARY
                    }
                }),
                ...externs.map(extern => {
                    return {
                        id: extern.id,
                        email: extern.email,
                        name: extern.fullname,
                        role: Role.EXTERN
                    }
                }),
                ...opticians.map(optician => {
                    return {
                        id: optician.id,
                        email: optician.username,
                        name: Helper.formatName(optician.firstname, optician.lastname),
                        role: Role.OPTICIAN
                    }
                })
            ]
        }/>,
        scope: [Role.SUPER_ADMIN, Role.ADMIN]
    }


    const layout = {
        HOME: {
            name: undefined,
            entries: [DASHBOARD, BILLING, TELETRANSMISSION, ACTS, DATA,
                SCHEDULED_APPOINTMENTS],
            scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
        },
        USERS: {
            name: "Utilisateurs",
            entries: [ORTHOPTISTS, OPHTALMOLOGISTS, SECRETARIES, EXTERNS, PATIENTS, OFFICES, OPTICIANS, GLOBAL_NOTIFICATIONS],
            scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
        },
        SCREENING: {
            name: "Dépistage",
            entries: [MEDICAL_EVENTS],
            scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
        },
        BILLING: {
            name: "Tarification",
            entries: [ORTHOPTISTS_BILLING_SETTINGS, OPHTALMO_PRICES, OPHTALMOLOGISTS_HN_SETTINGS, PAYMENT_TRACKER],
            scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
        },
        STATISTICS: {
            name: "Monitoring",
            entries: [OPHTALMOLOGIST_STATISTICS, APPLICATIONS_MONITORING],
            scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
        },
        TEAM: {
            name: "Equipe",
            entries: [TEAM_CABINETS, TEAM_OPHTALMOLOGISTS, TEAM_SECRETARIES],
            scope: [Role.SUPER_ADMIN, Role.ADMIN]
        },
        SUPER_ADMIN: {
            name: "Administration",
            entries: [INTERNAL_USERS, RIGHTS_AND_ROLES, PAIEMENT_ERRORS, PAIEMENT_STATUSES],
            scope: [Role.SUPER_ADMIN]
        },
        SECURITY: {
            name: undefined,
            entries: [SECURITY, CONNECT_AS],
            scope: [Role.SUPER_ADMIN, Role.ADMIN, Role.AUDITOR]
        },
    }

    useEffect(() => {
        const instance = M.Sidenav.init(document.getElementById('console_sidenav'), {});
        const collapsibles = document.querySelectorAll('.collapsible');
        const collapsible_instances = M.Collapsible.init(collapsibles, {});
        return () => {
            instance.destroy();
            collapsible_instances.forEach(collapsible_instance => {
                collapsible_instance.destroy();
            })
        }
    }, [])

    return (
        <Observer>
            {() => <Router>
                <div id='full-height' style={{display: "flex"}}>
                    <div style={{minWidth: "300px", boxShadow: "2px 0 0 0 #E4E4E4"}}>
                        <ul id="console_sidenav" className="sidenav sidenav-fixed white">
                            {
                                Object.values(layout).map((category, index) => {
                                    // No name = no category
                                    if (!category.name) {
                                        {
                                            return [...category.entries.map((entry, index) => {
                                                return <ScopedElement scope={entry.scope}
                                                                      key={`console_entry_${entry.name}`}>
                                                    <li className="navlink">
                                                        <NavLink to={entry.path} style={{paddingLeft: 5}}>
                                                            <i className="material-icons">{entry.icon}</i>
                                                            {entry.name}
                                                        </NavLink>
                                                    </li>
                                                </ScopedElement>
                                            }),
                                                <li key={`divider_noname_${index}`}>
                                                    <div className="divider"
                                                         style={{margin: "10px 0"}}></div>
                                                </li>
                                            ]
                                        }
                                    }
                                    return <ScopedElement scope={category.scope}
                                                          key={`console_category_${category.name}`}>
                                        <li>
                                            <ul className="collapsible collapsible-accordion">
                                                <li>
                                                    <a className="collapsible-header grey-text text-darken-2">
                                                        {category.name}
                                                        <i className="material-icons">arrow_drop_down</i>
                                                    </a>
                                                    <ul className="collapsible-body">
                                                        {category.entries.map((entry, index) => {
                                                            return <ScopedElement scope={entry.scope}
                                                                                  key={`console_entry_${entry.name}`}>
                                                                <li className="navlink">
                                                                    <NavLink to={entry.path} style={{paddingLeft: 5}}>
                                                                        <i className="material-icons">{entry.icon}</i>
                                                                        {entry.name}
                                                                    </NavLink>
                                                                </li>
                                                            </ScopedElement>
                                                        })}

                                                    </ul>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <div className="divider" key={`divider_${category.name}`}
                                                 style={{margin: "10px 0"}}></div>
                                        </li>
                                    </ScopedElement>
                                })
                            }
                        </ul>
                    </div>
                    <div style={{width: "100%"}}>
                        <Routes>
                            {Object.values(layout).reduce((acc, category) => [...acc, ...category.entries], []).map((category, index) => {
                                return <Route key={category.name} path={category.path} element={category.element}/>
                            })}
                            <Route path="/paiements/statuses/users/:id" key={"UserPaiement"} exact
                                   element={<UserPaiements/>}/>
                            <Route path="/devs/sockets" key={"SocketsDebugger"} exact element={<SocketsDebugger/>}/>
                            <Route path="/paiement_errors/:object_type/:id" key={"PaiementsErrors"} exact element={<PaiementErrorDetails/>}/>
                            <Route path="/summary/statistics/paiements" key={"PaiementsStatistics"} exact
                                   element={<PaiementsStatistics/>}/>
                            <Route path="/team/cabinets/:id" key={"TeamCabinetsEdit"} exact
                                   element={<TeamCabinetsEdit/>}/>
                            <Route path="/security" key={"SecurityConsole"} exact element={<SecurityConsole/>}/>
                            <Route path="/ophtalmologists/:id" key={"OphtalmologistEdit"} exact
                                   element={<Ophtalmologist ophtalmologists={ophtalmologists}/>}/>
                            <Route path="/orthoptists/:id" key={"OrthoptistEdit"} exact
                                   element={<Orthoptist orthoptists={orthoptists}/>}/>
                            <Route path="/orthoptists/:id/prices" key={"OrthoptistSettings"} exact
                                   element={<OrthoptistPricing orthoptists={orthoptists.map(o => {
                                       return {id: o.id, firstname: o.prenom, lastname: o.nom}
                                   })}/>}/>
                            <Route path="/secretaries/:id" key={"SecretaryEdit"} exact
                                   element={<Secretary secretaries={secretaries}/>}/>
                            <Route path="/externs/:id" key={"ExternEdit"} exact element={<Extern externs={externs}/>}/>
                            <Route path="/patients/:id" key={"PatientDashboard"} exact element={<PatientDashboard/>}/>
                            <Route path="/offices/:id" key={"OfficeDashboard"} exact element={<OfficeDashboard/>}/>
                            <Route path="/medical_events/new" key={"CreateMedicalEvent"} exact element={<CreateMedicalEvent/>}/>
                        </Routes>
                    </div>
                </div>
            </Router>}
        </Observer>


    );
}

export default ConsoleRouter;