import React, {cloneElement, useEffect} from 'react';

function MoreButton({id, options}) {
    const dd_id = `more-dropdown-${id}`;
    const dt_id = `more-dropdown-trigger-${id}`;

    useEffect(() => {
        const elem = document.getElementById(dt_id);
        // Use dropdownWrapper to avoid dropdown to be hidden because of overflow:hidden
        const dropdownWrapper = document.querySelector('.dropdown-wrapper');
        const instance = M.Dropdown.init(elem, {coverTrigger: false, constrainWidth: false, container: dropdownWrapper});
        return () => {
            instance.destroy()
        }
    }, []);


    return <>
        <a id={dt_id} className="more-button btn-small btn-flat dropdown-trigger" data-target={dd_id}>
            <i className="material-icons">more_vert</i>
        </a>
        <ul id={dd_id} className='dropdown-content more-button-dropdown'>
            {options.filter(o => !!o).map((button, i) => <li key={`${dd_id}_${i}`}>{cloneElement(button, {className: "transparent"})}</li>)}
        </ul>
    </>
}

export default MoreButton;