import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {t} from '../../I18n/i18n'
import moment from 'moment'
import PrescriptorStore from "../PrescriptorStore";
import {observer} from "mobx-react";
import Pagination from "../../Shared/Pagination";
import useDebounceV2 from "../../Hooks/useDebounceV2";
import {construct_filter, Filter} from "../../Shared/FilterHelper";

const NB_RESULT = 20
const PatientList = observer(() => {
    const nav = useNavigate()
    const [search, setSearch] = useState("")
    const debouncedGetPatients = useDebounceV2(PrescriptorStore.getPatients, 500)

    useEffect(() => {
        if (!search) return
        debouncedGetPatients(1, NB_RESULT, {nom_prenom_email: {[Filter.CONTAINS_ALL_CASE_INSENSITIVE]: search.split(" ")}})
    }, [search])

    const renderSearchBar = () => {
        return <div className="input-field outlined" style={{marginBottom: 10}}>
            <input type="text"
                   onChange={e => setSearch(e.target.value)}
                   autoFocus style={{height: 40}}
                   placeholder={t('PATIENT_SEARCH.SEARCH')}
                   data-cy="SearchBarPatient"/>
        </div>

    }

    const onPageChange = (page) => {
        debouncedGetPatients(page, NB_RESULT, {nom_prenom_email: {[Filter.CONTAINS_ALL_CASE_INSENSITIVE]: search.split(" ")}})
    }

    const renderPatientList = () => {
        return <div style={{display: "flex", justifyContent: "center"}}>
            {PrescriptorStore.patients.length > 0 ? (
                <table className="temeoo-table">
                    <thead>
                    <tr>
                        <th>Patient</th>
                        <th>Date de naissance</th>
                        <th>Orthoptiste</th>
                        <th>Date de début de suivi</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {PrescriptorStore.patients.map((patient, index) => {
                        return (
                            <tr key={index}>
                                <td>{Helper.formatName(patient.firstname, patient.lastname)}</td>
                                <td>{moment(patient.birthday).format('DD/MM/YYYY')}</td>
                                <td>{Helper.formatName(patient.last_orthoptist_firstname, patient.last_orthoptist_lastname)}</td>
                                <td>{moment(patient.last_appointment_date).format('DD/MM/YYYY')}</td>
                                <td>
                                    <a className="btn-flat truncate" onClick={() => nav(`/patient/${patient.id}`)}>
                                        <i className="material-icons left">account_circle</i>
                                        Accéder aux détails du patient
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            ) : (
                <p className="center">Aucun patient à afficher</p>
            )}
        </div>
    }

    return <div style={{display: "flex", gap: 10, padding: 20}}>
        <div style={{width: "20%"}}>
            <h3 className="center">Le saviez-vous ?</h3>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 70}}>
                <img src="/imgs/pub_prescripteur_dashboard_1.png" alt="Pub Prescripteur Dashboard"
                     style={{width: "80%", marginBottom: "10px"}}/>
                <img src="/imgs/pub_prescripteur_dashboard_2.png" alt="Pub Prescripteur Dashboard"
                     style={{width: "80%"}}/>
            </div>
        </div>
        <div style={{width: "80%", paddingInline: 40}}>
            <h3 className="center" style={{margin: 0}}>Mes patients</h3>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {renderSearchBar()}
                <Pagination count={PrescriptorStore.patient_count} limit={NB_RESULT} onChange={onPageChange}
                            limitButtons/>
            </div>
            {renderPatientList()}
        </div>
    </div>

})

export default PatientList