import React from "react"
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import Texts from "../Appointment/Texts";
import Measures from "../Appointment/Measures";
import Imagery from "../Appointment/Media/Imagery";

const Examination = () => {

    return <div className="row" style={{gap: 20}}>
        <div className="col m6 s12" style={{display: "flex", flexDirection: "column", gap: 20}}>
            <BorderedDisplay text={"Mesures"}>
                <Measures/>
            </BorderedDisplay>
            <BorderedDisplay text={"Mesures"}>
                <Imagery/>
            </BorderedDisplay>
        </div>
        <div className="col m6 s12">
            <BorderedDisplay text={"Textes"}>
                <Texts/>
            </BorderedDisplay>
        </div>

    </div>
}

export default Examination