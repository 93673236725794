import React from 'react';
import Helper, {JOB} from "../../../Shared/Helper";
import {Role} from "../../../Shared/Enums/Scope";
import {InvoiceStatus} from "../../../Shared/Enums/Enums";

const InvoiceDetails = ({details}) => {
    const ids = new Set();
    const appointments = details.map((appt) => {
        if (ids.has(appt.id)) null
        ids.add(appt.id);
        return appt
    }).filter((appt) => appt !== null);

    if (appointments.length === 0) return null
    return (
        <div style={{pageBreakBefore: "always", display: "flex", flexDirection: "column", gap: 20, marginTop: 20}}>
            <h5 className="center">Détail des actes</h5>
            <table align="left" style={{
                fontSize: "12px",
                width: "100%",
                fontFamily: "Courier, sans-serif",
                lineHeight: "1.5"
            }}>
                <thead align="left">
                <tr>
                    <th>Nombre d'actes</th>
                    <th>Type</th>
                    <th>Prix</th>
                    <th>Dû</th>
                </tr>
                </thead>
                <tbody align="left">
                {Object.entries(appointments.reduce((acc, appointment) => {
                    if (appointment.act_price === 0) return acc
                    if (Object.keys(acc).includes(appointment.act_label)) {
                        acc[appointment.act_label].number++
                        acc[appointment.act_label].price += appointment.act_price
                        acc[appointment.act_label].to_pay += parseFloat(appointment.to_pay)
                        return acc
                    }
                    acc[appointment.act_label] = {
                        number: 1,
                        price: appointment.act_price,
                        to_pay: parseFloat(appointment.to_pay)
                    }
                    return acc
                }, {})).map(([act_label, value], index) => {
                    return <tr key={index} style={{lineHeight: 0, fontSize: 10}}>
                        <td style={{paddingBlock: 10}}>{value.number}</td>
                        <td style={{paddingBlock: 10}}>{act_label}</td>
                        <td style={{paddingBlock: 10}}>{value.price.toFixed(2)}€</td>
                        <td style={{paddingBlock: 10}}>{value.to_pay.toFixed(2)}€</td>
                    </tr>
                })}
                </tbody>
            </table>
            <h5 className="center">Détail des consultations</h5>
            <table align="left" style={{
                fontSize: "12px",
                fontFamily: "Courier, sans-serif",
                width: "100%"
            }}>
                <thead align="left">
                <tr>
                    <th>Date</th>
                    <th>Patient</th>
                    <th>Acte</th>
                    <th>Prix</th>
                    <th>Dû</th>
                </tr>
                </thead>
                <tbody align="left">
                {appointments.map((appointment, index) => {
                    return <tr key={index} style={{lineHeight: 0, fontSize: 10}}>
                        <td style={{paddingBlock: 10}}>{Helper.formatDate(appointment.date)}</td>
                        <td style={{paddingBlock: 10}}>{appointment.patient_name}</td>
                        <td style={{paddingBlock: 10}}>{appointment.act_label}</td>
                        <td style={{paddingBlock: 10}}>{appointment.act_price}€</td>
                        <td style={{paddingBlock: 10}}>{appointment.to_pay}€</td>
                    </tr>
                })
                }
                </tbody>
            </table>
        </div>
    )
}

export default InvoiceDetails;