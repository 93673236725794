import React, {useEffect, useRef} from "react";

const SmartTextArea = ({id, value, onChange, style, containerStyle, placeholder, defaultValue, shortcuts, ...props}) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current.addEventListener("keydown", handleKeyDown);
        return () => {
            ref.current?.removeEventListener("keydown", handleKeyDown);
        }
    }, [shortcuts]);

    // Adding a shortcut, if the cursor is after the word "cp" and the user presses the "ctrl" and space keys, the word "cp" will be replaced by "copy"
    const handleKeyDown = (e) => {
        // only if focus is on this text area
        if (document.activeElement !== ref.current) return
        if (e.key === " " && e.ctrlKey) {
            const value = ref.current.value;
            const cursorPosition = ref.current.selectionStart;

            const before = value.substring(0, cursorPosition);
            const after = value.substring(cursorPosition, value.length);
            const lastWord = before.replace(/\n/g, " ").split(" ").pop();
            const matchingShortcut = shortcuts.find(shortcut => shortcut.trigger === lastWord);

            if (matchingShortcut) {
                const newValue = before.substring(0, before.length - matchingShortcut.trigger.length) + matchingShortcut.replacement + after;
                ref.current.value = newValue;
                onChange({target: {value: newValue}});
                ref.current.selectionStart = cursorPosition - matchingShortcut.trigger.length + matchingShortcut.replacement.length;
                ref.current.selectionEnd = cursorPosition - matchingShortcut.trigger.length + matchingShortcut.replacement.length;
            }
        }
    }

    return <div className="input-field outlined" style={{...containerStyle}}>
        <textarea className="smart-text-area"
            id={id}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            {...props}
            style={{...style}}
            ref={ref}
        />
        <label className="truncate">{placeholder}</label>
    </div>
};

SmartTextArea.defaultProps = {
    id: "",
    placeholder: "",
    value: undefined,
    defaultValue: undefined,
    onChange: () => {
    },
    style: {},
    shortcuts: [],
    containerStyle: {}
};

export default SmartTextArea;