import React, {useEffect, useState} from "react"
import SmartTextArea from "../../Shared/UiKit/SmartTextArea";
import useDebounce from "../../Shared/Hooks";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import useDebounceV2 from "../../Hooks/useDebounceV2";

const Comment = () => {
    const {appointment_id} = useParams()
    const [comment, setComment] = useState(undefined)
    const debouncedSaveComment = useDebounceV2((comment) => MedicalEventStore.saveComment(comment, appointment_id), 1000)

    useEffect(() => {
        MedicalEventStore.getComment(appointment_id).then((comment) => {
            setComment(comment)
        }).catch(() => {
            setComment("")
            toast.error("Impossible de charger le commentaire")
        })
    }, []);

    const saveComment = (comment) => {
        setComment(comment)
        debouncedSaveComment(comment)
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
        <h6 className="center" style={{margin: 0}}>Commentaire</h6>
        <SmartTextArea value={comment ?? ""} onChange={(e) => saveComment(e.target.value)}
                       style={{height: 400, resize: "none"}}
        />
    </div>
}

export default Comment