import React, {useEffect} from 'react';
import Dropzone from "react-dropzone";
import {RequestState} from "../../Shared/StateHelper";
import axios from "axios";
import {toast} from "react-toastify";
import MesureStore from "../../Appointments/Mesures/MesureStore";
import OpticianCampaignsStore from "./OpticianCampaignsStore";
import EditableTextarea from "../../Shared/UiKit/EditableTextarea";
import {observer} from "mobx-react";
import CampaignDisplay from "./CampaignDisplay";

const CampaignCustomisation = observer(() => {
    const campaign = OpticianCampaignsStore.selectedCampaign

    useEffect(() => {
        const elems = document.getElementById('campaign-preview');
        const instances = M.Modal.init(elems, {});
        return () => instances.destroy()
    }, []);

    const uploadImage = (file) => {
        if (file.length === 0) return;
        file = file[0]
        let formData = new FormData();
        let hasImage = false;
        if (file.name.includes(".jpg") || file.name.includes(".jpeg") || file.name.includes(".JPG") || file.name.includes(".JPEG") || file.name.includes(".png") || file.name.includes(".PNG")) {
            formData.append("image_data", file, file.name)
            hasImage = true
        }

        if(!hasImage) {
            toast.error("Le fichier n'est pas une image")
            return
        }

        axios.post(`/opticians/campaigns/attach_image/${campaign.id}`, formData)
            .then((response) => {
                OpticianCampaignsStore.updateCampaign({...campaign, image_url: response.data.url})
            }).catch(() => {
            toast.error("Erreur lors de du téléchargement des images")
            this.setState({isLoaded: RequestState.SUCCESS})
        })
            .then(() => MesureStore.hasImages())
    }

    const renderImage = () => {
        if (!campaign.image_url) return <h6 className="center">Déposer une image ici ou cliquez pour en ajouter une</h6>
        return <img src={campaign.image_url} alt={campaign.name} style={{borderRadius: 5, maxHeight: 150}}/>
    }

    const renderCampaignPreview = () => {
        if(!campaign) return null
        return <div className="center" style={{padding: 20}}>
            <h3 className="center">Prévisualisation de la campagne</h3>
            <h6>Cet encart publicitaire sera affiché lorsque les patients proches téléchargeront leur ordonnance.</h6>
            <div style={{maxWidth: 500}}>
                <CampaignDisplay campaign={campaign} preview />
            </div>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", height: "100%", gap: 10}}>
        <div className="row" style={{gap: 10, flex: 1}}>
            <div className="col s4 center" style={{height: "100%", display: "flex", flexDirection: "column", gap: 5}}>
                <h6 style={{margin: 0}}>Image affichée</h6>
                <div style={{flex: "1 1 0"}}>
                    <Dropzone onDrop={uploadImage}>
                        {({getRootProps, getInputProps, isDragActive}) => <div {...getRootProps()} style={{
                            border: '1px solid lightgrey',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            borderRadius: 5,
                            backgroundColor: isDragActive ? "lightgrey" : "white",
                            overflow: "hidden"
                        }}>
                            <input {...getInputProps()} />
                            {renderImage()}
                        </div>
                        }
                    </Dropzone>
                </div>
            </div>
            <div className="col s4" style={{height: "100%", display: "flex", flexDirection: "column", gap: 5}}>
                <h6 className="center" style={{margin: 0}}>Texte affiché</h6>
                <EditableTextarea value={campaign.text} validateOnEnter
                                  onValidation={(value) => OpticianCampaignsStore.saveSelectedCampaign({text: value})}
                                  style={{height: "100%"}}
                />
            </div>
            <div className="col s4" style={{height: "100%", display: "flex", flexDirection: "column", gap: 5}}>
                    <h6 className="center" style={{margin: 0}}>Informations</h6>
                <div style={{flex: "1 1 0", overflowY: "scroll"}}>
                    <label>Nom de la campagne</label>
                    <EditableTextarea value={campaign.name} validateOnEnter placeholder="Ma campagne publicitaire"
                                      onValidation={(value) => OpticianCampaignsStore.saveSelectedCampaign({name: value})}
                    />
                    <label>Lien de redirection</label>
                    <EditableTextarea value={campaign.website_url} validateOnEnter
                                      placeholder="https://www.votre-site.com"
                                      onValidation={(value) => OpticianCampaignsStore.saveSelectedCampaign({website_url: value})}
                    />
                    <label>Seuil de paiement (€) <i>0 = pas de seuil</i></label>
                    <EditableTextarea value={(campaign.monthly_threshold ?? 0) / 100.0} validateOnEnter
                                      onValidation={(value) => OpticianCampaignsStore.saveSelectedCampaign({monthly_threshold: value * 100})}
                    />
                    <label>Rayon de la campagne (km) <i>Défault : 30km</i></label>
                    <EditableTextarea value={campaign.radius ? (campaign.radius) / 1000.0 : null} validateOnEnter
                                      onValidation={(value) => OpticianCampaignsStore.saveSelectedCampaign({radius: (value ?? 0) * 1000})}
                    />
                </div>

            </div>
        </div>
        <div className="row">
            <div className="col s12 center">
                <a className="btn blue darken-2 z-depth-0 modal-trigger" data-target="campaign-preview">
                    Prévisualiser campagne
                </a>
            </div>
        </div>
        <div className="modal" id="campaign-preview">
            <div style={{display: "flex", justifyContent: "center"}}>
                {renderCampaignPreview()}
            </div>
        </div>
    </div>

})

export default CampaignCustomisation;