import {action, makeObservable, observable} from "mobx";
import axios from "axios";

class MessengerStore {
    @observable userId = null;
    @observable currentConversationId = null;
    @observable unreadByConversation = {};
    @observable lastUnreadIdByConversation = {};
    @observable conversation = {};
    @observable conversations = [];
    @observable office_users = [];

    constructor() {
        makeObservable(this)
        this.fetchUnreadByConversation();
    }

    @action
    messageReceived = data => {
        this.fetchUnreadByConversation();
        this.conversations = this.conversations.map((conversation) => {
            if (conversation.id === data.conversation_id) {
                conversation.last_message_date = new Date();
            }
            return conversation;
        });
    }

    @action
    fetchUnreadByConversation = () => {
        axios.get("messenger/conversations/unread").then((response) => {
            this.unreadByConversation = response.data.unread_by_conversation;
            this.lastUnreadIdByConversation = response.data.last_unread_id_by_conv;
        });
    }

    getUnreadByConversation = (conversationId) => {
        return this.unreadByConversation[conversationId] || 0;
    }

    @action
    addMessagesToConversation = (messages, conversationId) => {
        if (this.conversation[conversationId] === undefined) {
            this.conversation[conversationId] = [];
        }
        this.conversation[conversationId] = this.conversation[conversationId].concat(messages);
        // Sort messages by id and remove duplicates
        this.conversation[conversationId] = this.conversation[conversationId].sort((a, b) => a.id - b.id)
            .filter((message, index, self) => index === self.findIndex((m) => (
                m.id === message.id
            )));
    }

    @action
    newConversation = (conversation) => {
        this.conversations.push(conversation);
        this.currentConversationId = conversation.id;
        this.conversation[conversation.id] = [];
    }
}

let store = new MessengerStore
global.MessengerStore = store
export default store
