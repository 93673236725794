import React, {useEffect} from 'react';
import axios from "axios";
import OpticianCampaignsStore from "./OpticianCampaignsStore";
import BarChart from "../../admin/Statistiques/BarChart";
import {observer} from "mobx-react";

const CampaignStatistics = observer(() => {
    const url = "/opticians/campaigns/statistics"
    const [monthlyEvents, setMonthlyEvents] = React.useState({});
    const [monthlyExpenses, setMonthlyExpenses] = React.useState({});

    useEffect(() => {
        getEvents()
    }, [OpticianCampaignsStore.selectedCampaign.id]);

    const getEvents = () => {
        axios.get(`${url}/${OpticianCampaignsStore.selectedCampaign.id}/events`)
            .then(response => {
                console.log(response.data)
                setMonthlyEvents(response.data.monthly_events)
                setMonthlyExpenses(response.data.monthly_expenses)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const computeEvents = () => {
        // need {labels: [], datasets: [{label: "", data: []}]}
        let labels = []
        let prints = []
        let clicks = []
        Object.entries(monthlyEvents).map(([key, value]) => {
            labels.push(key)
            prints.push(value.prints)
            clicks.push(value.clicks)
        })

        return {
            labels: labels,
            datasets: [
                {label: "Vues", backgroundColor: "#1976d2", data: prints},
                {label: "Clics", backgroundColor: "#2196f3", data: clicks}
            ]
        }
    }

    const computeExpenses = () => {
        // need {labels: [], datasets: [{label: "", data: []}]}
        let labels = []
        let expenses = []
        Object.entries(monthlyExpenses).map(([key, value]) => {
            labels.push(key)
            expenses.push(value)
        })

        return {
            labels: labels,
            datasets: [
                {label: "Dépenses (€)", backgroundColor: "#1976d2", data: expenses}
            ]
        }
    }

    if (!Object.keys(monthlyEvents).length) return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    }}>
        <h4 style={{margin: 0}}><i className="material-icons left">pending_actions</i>
            Rien à signaler pour le moment
        </h4>
        <label>Mais c'est ici que la magie va opérer !</label>
    </div>

    return <div className="row" style={{flex: 1, gap: 10}}>
        <div className="col s6" style={{maxHeight: "20vh"}}>
            <h4 className="center" style={{margin: 0}}>
                Statistiques mensuelles
            </h4>
            <BarChart data={computeEvents()}/>
        </div>
        <div className="col s6" style={{maxHeight: "20vh"}}>
            <h4 className="center" style={{margin: 0}}>
                Dépenses mensuelles
            </h4>
            <BarChart data={computeExpenses()}/>
        </div>
    </div>
});

export default CampaignStatistics;