import React from 'react';

const Questions = ({report}) => {
    if(!report) return <div/>
    return <div style={{display: "flex", flexDirection: "column", fontSize: 14}}>
        <h6 style={{fontWeight: "bolder"}}>Questionnaire :</h6>
        <div className="row">
            {Object.entries(report).map(([title, value]) => {
                if (value === false) return
                const valueRender = (value) => {
                    if (typeof value === "boolean") {
                        return ""
                    }
                    return " -- " + value
                };
                return <div className="col s6">
                    <h6>{title.split("##")[0]}{valueRender(value)}</h6>
                </div>
            })}
        </div>



    </div>
};

export default Questions;