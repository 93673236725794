import React, {useEffect} from 'react'
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import SimpleVideoRecorder from "../../Orthoptistes/Phone/SimpleVideoRecorder";
import PhoneStore from "./PhoneStore";
import {toast} from "react-toastify";

const TakeVideo = observer(() => {
    const nav = useNavigate()
    const [video, setVideo] = React.useState(null)
    const [videoUrl, setVideoUrl] = React.useState("")

    useEffect(() => {
        console.log(video)
    }, [video]);

    const saveVideo = (data) => {
        PhoneStore.saveVideo(video).then(() => {
            setVideo(null)
            setVideoUrl("")
        }).catch((_) => {
            toast.error("Erreur lors de l'envoi de la vidéo")
        })
    }

    const renderCamera = () => {
        return <SimpleVideoRecorder
            onVideoCapture={(video, url) => {
                setVideo(video)
                setVideoUrl(url)
            }}
        />
    }

    const renderImage = () => {
        return <div className="center">
            <video src={videoUrl} autoPlay={true} loop={true} controls playsInline id="videoPreview"
                   style={{width: "100vw"}}/>
        </div>
    }

    const renderBody = () => {
        if (video) return renderImage()
        else return renderCamera()
    }

    const renderPreviewButtons = () => {
        if (!video) return null
        return <>
            <a className="btn-small green darken-2 z-depth-0" style={{marginLeft: "auto"}}
               onClick={() => saveVideo()}>
                <i className="material-icons hide-on-med-and-down left">done</i>
                <i className="material-icons hide-on-large-only">done</i>
                <span className="hide-on-med-and-down">Envoyer</span>
            </a>
            <a className="btn-small red darken-2 z-depth-0" onClick={() => setVideo(null)}>
                <i className="material-icons hide-on-med-and-down left">close</i>
                <i className="material-icons hide-on-large-only">close</i>
                <span className="hide-on-med-and-down">Prendre une autre video</span>
            </a>
        </>
    }

    return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: "1rem", padding: 10}}>
        <div className="valign-wrapper">
            <a className="btn-small blue darken-2 z-depth-0" onClick={() => nav("/")}>
                <i className="material-icons hide-on-med-and-down left">chevron_left</i>
                <i className="material-icons hide-on-large-only">chevron_left</i>
                <span className="hide-on-med-and-down">Retour</span>
            </a>
            <div style={{flex: 1}}>
                <h5 className="center" style={{fontWeight: 600}}>PRISE VIDEO</h5>
            </div>
            {renderPreviewButtons()}
        </div>
        <div>
            {renderBody()}
        </div>
    </div>
})

export default TakeVideo
