import React from "react"
import axios from "axios"
import {toast} from 'react-toastify';


export default function HonoreBox({alert_id, checked, onChange, url, text}) {

    const handleChange = () => {
        axios.post(url, {
            alert: alert_id,
            honore: !checked
        }).then((_) => {
            onChange(!checked)
            toast.success('Rendez-vous honoré.')
        }).catch(_ => toast.error("Une erreur est survenue."))
    }

    return <label>
        <input checked={checked} onChange={handleChange}
               type="checkbox"/>
        <span>{text}</span>
    </label>
}

