import React from "react"
import {Mesures, Side} from "../../../Shared/Enums/Enums";
import MesureStore from "../MesureStore";
import {isLocked, LockingElement} from "../../AppointmentValidation";

export default class MesureTextBox extends React.Component {
    constructor(props) {
        super(props);
        this.startEdit = this.startEdit.bind(this)
        this.validateSphere = this.validateSphere.bind(this)
        this.validateCylindre = this.validateCylindre.bind(this)
        this.validateAxe = this.validateAxe.bind(this)
        this.validateAddition = this.validateAddition.bind(this)
        this.validateAvLoin = this.validateAvLoin.bind(this)
        this.validateParinaud = this.validateParinaud.bind(this)
        this.state = {
            onEdit: false,
            sphere: "",
            cylindre: "",
            axe: "",
            addition: "",
            avLoin: "",
            parinaud: "",
            focusedElement: null
        }
    }

    startEdit() {
        this.setState({
            onEdit: true
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.init()
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        const cyl = parseFloat(this.props.cylindre) * -1
        this.setState({
            sphere: this.props.sphere,
            cylindre: isNaN(cyl) ? "" : cyl.toString(),
            axe: this.props.axe,
            addition: this.props.addition,
            avLoin: this.props.avLoin.match(/^\d+[\.,]?\d*$/) ? (parseFloat(this.props.avLoin) * 10).toString() : this.props.avLoin,
            parinaud: this.props.parinaud,
        })
    }

    handleKeypress = (e) => {
        if (e.key === 'Tab' || e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            const inputElements = Array.from(document.querySelectorAll('.textbox_value'));
            const currentIndex = inputElements.indexOf(this.state.focusedElement);
            const nextIndex = (currentIndex + (e.shiftKey ? -1 : +1)) % inputElements.length;
            inputElements[nextIndex].focus();
            this.setState({focusedElement: inputElements[nextIndex]});
        }
    }

    handleFocus = (e) => {
        const element = e.target
        this.setState({focusedElement: element}, () => element.select());
    };


    validateSphere(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^-?\d*\.?\d{0,2}$/) || value === "") {
            this.props.changeSphere(value)
            document.getElementById("cylindre" + this.props.side.toString()).focus()
        } else {
            this.setState({
                sphere: this.props.sphere
            })
        }
    }

    changeSphere(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^[-+]?\d*\.?\d{0,2}$/) || value === "") {
            this.setState({sphere: e.target.value})
            this.props.changeSphere(value)
        }
    }

    validateCylindre(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d*\.?\d{0,2}$/) || value === "") {
            this.props.changeCylindre(value)
            document.getElementById("axe" + this.props.side.toString()).focus()

        } else {
            this.setState({
                cylindre: this.props.cylindre
            })
        }
    }

    changeCylindre(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d*\.?\d{0,2}$/) || value === "") {
            this.setState({cylindre: e.target.value})
            this.props.changeCylindre(value)
        }
    }


    validateAxe(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d{0,3}$/) || value === "") {
            this.props.changeAxe(value)
            document.getElementById("addition" + this.props.side.toString()).focus()
        } else {
            this.setState({
                axe: this.props.axe
            })
        }
    }

    changeAxe(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d{0,3}$/) || value === "") {
            this.setState({axe: e.target.value})
            this.props.changeAxe(value)
        }
    }

    validateAddition(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d*\.?\d{0,2}$/) || value === "") {
            this.props.changeAddition(value)
            document.getElementById("avLoin" + this.props.side.toString()).focus()
        } else {
            this.setState({
                addition: this.props.addition
            })
        }
    }

    changeAddition(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d*\.?\d{0,2}$/) || value === "") {
            this.setState({addition: e.target.value})
            this.props.changeAddition(value)
        }
    }

    validateAvLoin(e) {
        this.props.changeAvLoin(e.target.value)
        document.getElementById("parinaud" + this.props.side.toString()).focus()
    }

    changeAvLoin(e) {
        this.setState({avLoin: e.target.value})
        this.props.changeAvLoin(e.target.value)
    }

    validateParinaud(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d*\.?\d{0,2}$/) || value === "") {
            this.props.changeParinaud(value)
            if (this.props.nextBoxSide !== "") {
                document.getElementById("sphere" + this.props.nextBoxSide).focus()
            }

        } else {
            this.setState({
                parinaud: this.props.parinaud
            })
        }
    }

    changeParinaud(e) {
        const value = e.target.value.replace(",", ".")
        if (value.match(/^\d*\.?\d{0,2}$/) || value === "") {
            this.setState({parinaud: e.target.value})
            this.props.changeParinaud(value)
        }
    }

    lastValue(mesure) {
        let val = undefined
        switch (this.props.side) {
            case Side.LEFT:
                val = this.props.lastValue(`og_${mesure}`)
                return val ? `(${val})` : ""
            case Side.RIGHT:
                val = this.props.lastValue(`od_${mesure}`)
                return val ? `(${val})` : ""
        }
        return ""
    }

    acuity_required = () => {
        // const mesure_name = MesureStore.typesdemesures.find(m => m.id === MesureStore.typedemesure).titre;
        //
        // const glass_type = ["lunette", "correction"].reduce((acc, cur) => {
        //     return acc || mesure_name.toLowerCase().includes(cur)
        // }, false)
        // if (!glass_type) return false

        const element = this.props.side === Side.RIGHT ? LockingElement.RIGHT_ACUITY : LockingElement.LEFT_ACUITY
        const av_required = isLocked(element)
        return av_required && this.props.mesureDisplayList.includes(Mesures.ACUITY.value)
    }

    parinaud_required = () => {
        // const mesure_name = MesureStore.typesdemesures.find(m => m.id === MesureStore.typedemesure).titre;
        //
        // const glass_type = ["lunette", "correction"].reduce((acc, cur) => {
        //     return acc || mesure_name.toLowerCase().includes(cur)
        // }, false)
        // if (!glass_type) return false

        const element = this.props.side === Side.RIGHT ? LockingElement.RIGHT_PARINAUD : LockingElement.LEFT_PARINAUD
        const av_required = isLocked(element)
        return av_required && this.props.mesureDisplayList.includes(Mesures.PARINAUD.value)
    }

    renderSphere = () => {
        const hasValue = ![null, ""].includes(this.state.sphere)
        if (!hasValue && !this.props.mesureDisplayList.includes(Mesures.SPHERE.value)) return null
        return <div className="col xl2 s4 valign-wrapper">
            <div className="input-field outlined">
                <input id={`sphere${this.props.side.toString()}`} value={this.state.sphere}
                       style={{maxWidth: "50px", padding: 0}}
                       className="textbox_value center" autoFocus={this.props.autofocus}
                       data-cy={`Sphere${this.props.side}`}
                       onChange={event => this.changeSphere(event)}
                       onFocus={this.handleFocus}
                       onKeyDown={this.handleKeypress}/>
                <h6 style={{margin: 0}} className="grey-text center">{`Sphere ${this.lastValue("sphere")}`}</h6>
            </div>
        </div>
    }

    renderCylinder = () => {
        const hasValue = ![null, ""].includes(this.state.cylindre)
        if (!hasValue && !this.props.mesureDisplayList.includes(Mesures.CYLINDER.value)) return null
        return <div className="col xl2 s4 valign-wrapper">
            <h1 style={{marginTop: 15}}>(&nbsp;-&nbsp;</h1>
            <div className="input-field outlined">
                <input id={`cylindre${this.props.side.toString()}`} value={this.state.cylindre}
                       style={{maxWidth: "50px", padding: 0}}
                       className="textbox_value center"
                       data-cy={`Cylinder${this.props.side}`}
                       pattern="\d+[\.]{0,1}\d*"
                       onChange={event => this.changeCylindre(event)}
                       onFocus={this.handleFocus}
                       onKeyDown={this.handleKeypress}/>
                <h6 style={{margin: 0}} className="grey-text center">{`Cylindre ${this.lastValue("cylindre")}`}</h6>
            </div>
            <h1 style={{marginTop: 15}}>)</h1>
        </div>
    }

    renderAxis = () => {
        const hasValue = ![null, ""].includes(this.state.axe)
        if (!hasValue && !this.props.mesureDisplayList.includes(Mesures.AXIS.value)) return null
        return <div className="col xl2 s4 valign-wrapper">
            <div className="input-field outlined">
                <input id={`axe${this.props.side.toString()}`} value={this.state.axe}
                       style={{maxWidth: "50px", padding: 0}}
                       className="textbox_value center"
                       data-cy={`Axis${this.props.side}`}
                       onChange={event => this.changeAxe(event)}
                       onFocus={this.handleFocus}
                       onKeyDown={this.handleKeypress}
                />
                <h6 style={{margin: 0}} className="grey-text center">{`Axe ${this.lastValue("axe")}`}</h6>
            </div>
            <h3 style={{marginTop: 0}}>°</h3>
        </div>
    }

    renderAddition = () => {
        const hasValue = ![null, ""].includes(this.state.addition)
        if (!hasValue && !this.props.mesureDisplayList.includes(Mesures.ADDITION.value)) return null
        return <div className="col xl2 s4 valign-wrapper">
            <h3 style={{marginTop: 10}}>add&nbsp;</h3>
            <div className="input-field outlined">
                <input id={`addition${this.props.side.toString()}`} value={this.state.addition}
                       style={{maxWidth: "50px", padding: 0}}
                       className="textbox_value center"
                       data-cy={`Addition${this.props.side}`}
                       onChange={event => this.changeAddition(event)}
                       onFocus={this.handleFocus}
                       onKeyDown={this.handleKeypress}
                />
                <h6 style={{margin: 0}} className="grey-text center">{`Addition ${this.lastValue("addition")}`}</h6>
            </div>
        </div>
    }

    renderAcuity = () => {
        const hasValue = ![null, ""].includes(this.state.avLoin)
        let acuity = this.state.avLoin
        let strongness = 0
        if (acuity.match(/^\d+[.,]?\d*F$/)) {
            strongness = 1
            acuity = acuity.slice(0, -1)
        }
        if (acuity.match(/^\d+[.,]?\d*f$/)) {
            strongness = -1
            acuity = acuity.slice(0, -1)
        }
        if (!hasValue && !this.props.mesureDisplayList.includes(Mesures.ACUITY.value)) return null
        return <div className="col xl2 s4 valign-wrapper" style={{gap: 5}}>
            <h1>&nbsp;</h1>
            <div className="input-field outlined">
                <input id={`avLoin${this.props.side.toString()}`} value={acuity}
                       style={{maxWidth: "70px", padding: 0}}
                       className="textbox_value center"
                       data-cy={`Acuity${this.props.side}`}
                       onChange={event => this.changeAvLoin(event)}
                       onFocus={this.handleFocus}
                       onKeyDown={this.handleKeypress}
                />
                <h6 style={{margin: 0}} className={`${this.acuity_required() ? "red" : "grey"}-text center`}>
                    {`AV Loin ${this.lastValue("av_loin")}`}
                </h6>
            </div>
            {acuity.match(/^\d+[\.,]?\d*$/) ?
                <div className="valign-wrapper" style={{marginBottom: 30}}>
                    <h5 style={{margin: 0}}>/10</h5>
                    <div style={{display: "flex", flexDirection: "column", marginRight: 30}}>
                        <a className={`btn-small btn-flat ${strongness === 1 ? "grey lighten-2" : "transparent"}`}
                           style={{width: 20, height: 20, lineHeight: "20px", borderRadius: "50%", padding: 0}}
                           onClick={() => {
                               const new_acuity = acuity + (strongness === 1 ? "" : "F")
                               this.setState({avLoin: new_acuity})
                               this.props.changeAvLoin(new_acuity)
                           }}
                        >
                            <i className="material-icons tiny">add</i>
                        </a>
                        <a className={`btn-small btn-flat ${strongness === -1 ? "grey lighten-2" : "transparent"}`}
                           style={{width: 20, height: 20, lineHeight: "20px", borderRadius: "50%", padding: 0}}
                           onClick={() => {
                               const new_acuity = acuity + (strongness === -1 ? "" : "f")
                               this.setState({avLoin: new_acuity})
                               this.props.changeAvLoin(new_acuity)
                           }}
                        >
                            <i className="material-icons tiny">remove</i>
                        </a>
                    </div>
                </div> : null
            }
        </div>
    }

    renderParinaud = () => {
        const hasValue = ![null, ""].includes(this.state.parinaud)
        if (!hasValue && !this.props.mesureDisplayList.includes(Mesures.PARINAUD.value)) return null
        return <div className="col xl2 s4 valign-wrapper">
            <h3 style={{marginTop: 10}}>P&nbsp;</h3>
            <div className="input-field outlined">
                <input id={`parinaud${this.props.side.toString()}`} value={this.state.parinaud}
                       style={{maxWidth: "50px", padding: 0}}
                       className="textbox_value center"
                       data-cy={`Parinaud${this.props.side}`}
                       onChange={event => this.changeParinaud(event)}
                       onFocus={this.handleFocus}
                       onKeyDown={this.handleKeypress}
                />
                <h6 style={{margin: 0}} className={`${this.parinaud_required() ? "red" : "grey"}-text center`}>
                    {`Parinaud ${this.lastValue("parinaud")}`}
                </h6>
            </div>
        </div>
    }

    render() {
        return (
            <div className="valign-wrapper" style={{paddingLeft: "2vw"}}>
                <form className="valign-wrapper row" style={{width: "100%"}}>
                    {this.renderSphere()}
                    {this.renderCylinder()}
                    {this.renderAxis()}
                    {this.renderAddition()}
                    {this.renderAcuity()}
                    {this.renderParinaud()}
                </form>
            </div>
        )
    }
}

MesureTextBox.defaultProps = {
    nextBoxSide: ""
}
