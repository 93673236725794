import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import Helper from "../../../Shared/Helper";
import {toast} from "react-toastify";
import {isLoading, loading, RequestState} from "../../../Shared/StateHelper";
import Error from "../../../Shared/Error";
import {ErrorType, InvoiceStatus, PaymentObject} from "../../../Shared/Enums/Enums";
import {wrapper} from "../../../I18n/i18n";

const {t} = wrapper("PAYMENT_ERRORS");

function PaiementErrorDetails() {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('[name=csrf-token]').content;

    const {object_type, id} = useParams();
    const nav = useNavigate();

    const [userName, setUserName] = useState("");
    const [errors, setErrors] = useState([])
    const [payment, setPayment] = useState({})
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    useEffect(() => {
        getErrors();
    }, []);

    const getErrors = () => {
        setRequestState(loading(requestState));
        axios.get(`/admin/console/payments/errored/${object_type}/${id}`).then((resp) => {
            setErrors(resp.data.errors);
            setUserName(resp.data.user_name);
            setPayment({status: resp.data.payment_status, amount: resp.data.payment_amount, ...resp.data.payment});
            setRequestState(RequestState.SUCCESS);
        }).catch((err) => {
            setRequestState(RequestState.ERROR);
        })
    }

    const replayPayment = () => {
        axios.patch(`/admin/console/payments/errored/${object_type}/${id}/replay`).then((resp) => {
            setPayment((prev) => ({...prev, status: resp.data.payment_status}))
        }).catch((err) => {
            toast.error(err.response.data.errorMsg)
        })
    }

    const deleteError = (error) => {
        axios.delete(`/admin/console/payments/errored/${object_type}/${id}/error/${error.id}`)
            .then((resp) => {
                setErrors(errors.filter((e) => e.id !== resp.data.id))
            }).catch((err) => {
            toast.error("Impossible de supprimer l'erreur")
        })
    }


    const deleteAllErrors = () => {
        axios.delete(`/admin/console/payments/errored/${object_type}/${id}/errors`)
            .then((resp) => {
                nav("/paiement_errors")
            }).catch((err) => {
            toast.error("Impossible de supprimer les erreurs")
        })
    }

    const renderStatus = (status) => {
        switch (status) {
            case InvoiceStatus.WAITING:
                return <i className="material-icons grey-text left" title="En attente">schedule</i>
            case InvoiceStatus.PENDING:
                return <i className="material-icons orange-text left" title="En cours">pending</i>
            case InvoiceStatus.SUCCESS:
                return <i className="material-icons green-text left" title="Réussi">done</i>
            case InvoiceStatus.FAILED:
                return <i className="material-icons red-text left" title="Échoué">error</i>
            default:
                return null
        }
    }


    const renderHeader = () => {
        return <div className="fc" style={{gap: 0}}>
            <div className="fr valign-wrapper" style={{paddingInline: 20, gap: 0}}>
                <a className="btn-flat" style={{borderRadius: 20}} onClick={() => nav("/paiement_errors")}>
                    <i className="material-icons">arrow_back</i>
                </a>
                <h4 className="valign-wrapper" style={{margin: 20, marginBlock: 15}}>
                    {renderStatus(payment.status)}
                    Erreurs de paiement
                </h4>
                <h6 style={{marginBlock: 3}}>{t(object_type.toUpperCase())}
                    {[RequestState.IDLE, RequestState.LOADING].includes(requestState) ? "" : ` - ${userName} - ${Helper.formatDate(payment.created_at)}`}</h6>
                {<h4 className="card blue darken-2 z-depth-0 white-text"
                     style={{margin: "0 20px", padding: "5px 10px", borderRadius: 3}}>
                    {(payment.amount ?? 0) / 100.0}€
                </h4>}
                <a className="btn-small blue darken-2 z-depth-0" onClick={replayPayment}
                   style={{marginLeft: "auto"}} title="Relancer le paiement">
                    <i className={"material-icons left"}>replay</i>
                    Relancer paiement
                </a>
                <a className="btn-small red darken-2 z-depth-0" onClick={deleteAllErrors} title="Tout supprimer">
                    <i className={"material-symbols-outlined left"}>delete_sweep</i>
                    Tout supprimer
                </a>
                <a className="btn-small btn-flat transparent z-depth-0" onClick={getErrors} title="Rafraichir erreurs">
                    <i className={"material-icons"}>refresh</i>
                </a>
            </div>
            <div className="divider"/>
        </div>
    }

    const renderLoader = () => {
        if (isLoading(requestState)) {
            return <div className="progress" style={{margin: 0, position: "absolute"}}>
                <div className="indeterminate"/>
            </div>
        }
    }

    const renderErrors = () => {
        if (requestState === RequestState.ERROR) {
            return <Error errorText="Impossible de charger les erreurs"/>
        }

        return <table className="temeoo-table">
            <thead>
            <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Message</th>
                <th>Date</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {errors.map((error) => {
                const color = ErrorType[error.error_type].color;
                return <tr key={error.id}>
                    <td>#{error.id}</td>
                    <td>
                        <div className={`card ${color} z-depth-0 white-text center-align`}
                             style={{padding: 0, borderRadius: 3, maxWidth: 200}}>
                            {t(`TYPE.${error.error_type}`)}
                        </div>
                    </td>
                    <td>{error.message}</td>
                    <td>{Helper.formatDate(error.created_at)}</td>
                    <td>
                        <a className="btn-small red darken-2 z-depth-0 right" title="Supprimer"
                           onClick={() => deleteError(error)}>
                            <i className={"material-icons"}>delete</i>
                        </a>
                    </td>
                </tr>
            })}
            </tbody>

        </table>
    }


    return <div id="full-height" className="fc" style={{overflowY: "scroll"}}>
        <div className="fc" style={{gap: 0}}>
            {renderLoader()}
            {renderHeader()}
        </div>
        <div className="container">
            {renderErrors()}
        </div>
    </div>

}

export default PaiementErrorDetails;