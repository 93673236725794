import React, {useState} from "react";
import r from "../../../Shared/Validation/Regex";
import Tooltip from "../../../Shared/Tootip";
import {toast} from "react-toastify";
import axios from "axios"
import {Role} from "../../../Shared/Enums/Scope";
import BrandedElement from "../../../Shared/BrandedElement";
import {c, wrapper} from "../../../I18n/i18n";
import HelpIcon from "../../../Shared/Help/HelpIcon";

export default function PersonalInformationPanel(props) {
    const {t} = wrapper("OPTIONS.GENERAL")

    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    const [rpps, setRpps] = useState(props.rpps == null ? "" : props.rpps);
    const [adeli, setAdeli] = useState(props.adeli == null ? "" : props.adeli?.replace(/ /g, ""));
    const [phone_number, setPhoneNumber] = useState(props.phone_number == null ? "" : props.phone_number);
    const [email, setEmail] = useState(props.email == null ? "" : props.email);
    const [first_name, setFirstName] = useState(props.first_name === null ? "" : props.first_name)
    const [last_name, setLastName] = useState(props.last_name === null ? "" : props.last_name)
    const [username, setUsername] = useState(props.username === null ? "" : props.username)
    const [invoiceLabel, setInvoiceLabel] = useState(props.last_name === null ? "" : props.invoice_label)
    const [full_name, setFullName] = useState(props.fullname === null ? "" : props.full_name)
    const [address, setAddress] = useState(props.address === null ? "" : props.address)
    const [city, setCity] = useState(props.city === null ? "" : props.city)
    const [postalCode, setPostalCode] = useState(props.postal_code === null ? "" : props.postal_code)

    const FIELDS = [
        {
            category_title: t("PERSONAL_INFO"),
            cy_data: "PersonalInformations",
            size: 45,
            user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.EXTERN, Role.OPTICIAN],
            fields: [
                {
                    title: c("USERNAME"),
                    var_name: username,
                    setter: setUsername,
                    cy_data: "Username",
                    id: "username",
                    validator_func: () => true,
                    tooltip_info: t("TOOLTIP.USERNAME"),
                    error_rendering: renderUsernameError,
                    user_types: [Role.OPTICIAN]
                },
                {
                    title: c("FIRSTNAME"),
                    var_name: first_name,
                    setter: setFirstName,
                    cy_data: "FirstName",
                    id: "first_name",
                    validator_func: () => true,
                    tooltip_info: t("TOOLTIP.FIRSTNAME"),
                    error_rendering: renderFirstNameError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.OPTICIAN]
                },
                {
                    title: c("LASTNAME"),
                    var_name: last_name,
                    setter: setLastName,
                    cy_data: "LastName",
                    id: "last_name",
                    validator_func: () => true,
                    tooltip_info: t("TOOLTIP.LASTNAME"),
                    error_rendering: renderLastNameError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.OPTICIAN]
                },
                {
                    title: c("ADDRESS"),
                    var_name: address,
                    setter: setAddress,
                    cy_data: "Address",
                    id: "address",
                    validator_func: () => true,
                    tooltip_info: t("TOOLTIP.ADDRESS"),
                    error_rendering: () => null,
                    user_types: [Role.OPHTALMOLOGIST]
                },
                {
                    title: c("CITY"),
                    var_name: city,
                    setter: setCity,
                    cy_data: "City",
                    id: "city",
                    validator_func: () => true,
                    tooltip_info: t("TOOLTIP.CITY"),
                    error_rendering: () => null,
                    user_types: [Role.OPHTALMOLOGIST]
                },
                {
                    title: c("POSTAL_CODE"),
                    var_name: postalCode,
                    setter: setPostalCode,
                    cy_data: "PostalCode",
                    id: "postal_code",
                    validator_func: () => true,
                    tooltip_info: t("TOOLTIP.POSTAL_CODE"),
                    error_rendering: () => null,
                    user_types: [Role.OPHTALMOLOGIST]
                },
                {
                    title: t("INVOICE_LABEL"),
                    var_name: invoiceLabel,
                    setter: setInvoiceLabel,
                    cy_data: "InvoiceLabel",
                    id: "invoice_label",
                    description: t("DESCRIPTION.INVOICE_LABEL"),
                    validator_func: () => true,
                    tooltip_info: "",
                    error_rendering: () => null,
                    user_types: [Role.ORTHOPTIST]
                },
                {
                    title: c("PHONE_NUMBER"),
                    var_name: phone_number,
                    setter: setPhoneNumber,
                    cy_data: "PhoneNumber",
                    id: "phone_number",
                    validator_func: r.validatePhoneNumber,
                    tooltip_info: t("TOOLTIP.PHONE_NUMBER"),
                    error_rendering: renderPhoneNumberError,
                    user_types: [Role.ORTHOPTIST, Role.OPTICIAN]
                },
                {
                    title: c("EMAIL"),
                    var_name: email,
                    setter: setEmail,
                    cy_data: "Email",
                    id: "email",
                    validator_func: r.validateEmail,
                    tooltip_info: t("TOOLTIP.EMAIL"),
                    error_rendering: () => null,
                    user_types: [Role.OPTICIAN]
                },
                {
                    title: c("FULLNAME"),
                    var_name: full_name,
                    setter: setFullName,
                    cy_data: "FullName",
                    id: "full_name",
                    validator_func: () => true,
                    tooltip_info: t("TOOLTIP.FULLNAME"),
                    error_rendering: renderFullNameError,
                    user_types: [Role.EXTERN]
                },
            ]
        },
        {
            category_title: t("INVOICE_INFO"),
            size: 55,
            cy_data: "BillInfos",
            hideOnOptician: true,
            user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST],
            fields: [
                {
                    title: t("RPPS"),
                    var_name: rpps,
                    setter: setRpps,
                    cy_data: "RPPS",
                    id: "rpps",
                    validator_func: r.validateRpps,
                    tooltip_info: t("TOOLTIP.RPPS"),
                    error_rendering: renderRppsError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
                },
                {
                    title: t("ADELI"),
                    var_name: adeli,
                    setter: setAdeli,
                    cy_data: "ADELI",
                    id: "adeli",
                    validator_func: r.validateAdeli,
                    tooltip_info: t("TOOLTIP.ADELI"),
                    error_rendering: renderAdeliError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
                }
            ]
        }
    ]

    function updateInfos() {
        let data
        switch (props.user_type) {
            case Role.ORTHOPTIST:
                data = {
                    rpps: rpps,
                    adeli: adeli,
                    phone_number: phone_number,
                    first_name: first_name,
                    last_name: last_name,
                    invoice_label: invoiceLabel
                }
                break
            case Role.OPHTALMOLOGIST:
                data = {
                    rpps: rpps,
                    adeli: adeli,
                    first_name: first_name,
                    last_name: last_name,
                    address: address,
                    city: city,
                    postal_code: postalCode
                }
                break
            case Role.EXTERN :
                data = {full_name: full_name}
                break
            case Role.OPTICIAN:
                data = {
                    username: username,
                    firstname: first_name,
                    lastname: last_name,
                    phone: phone_number,
                    email: email
                }
                break
            default:
                data = {}
        }
        axios.post(`${props.url_prefix}/administration/update`,
            data)
            .then((_) => {
                toast.success("Informations sauvegardées.")
                setTimeout(() => document.location.reload(), 500)
            })
            .catch((_) => toast.error("Erreur lors de l'enregistrement."))
    }

    function isValidToSubmit() {
        switch (props.user_type) {
            case Role.ORTHOPTIST:
                if (!first_name) return false
                if (!last_name) return false
                if (!phone_number || !r.validatePhoneNumber(phone_number)) return false
                if (rpps && !r.validateRpps(rpps)) return false
                if (adeli && !r.validateAdeli(adeli)) return false
                break

            case Role.OPHTALMOLOGIST:
                if (!first_name) return false
                if (!last_name) return false
                if (rpps && !r.validateRpps(rpps)) return false
                if (adeli && !r.validateAdeli(adeli)) return false
                break
            case Role.EXTERN:
                if (!full_name) return false
                break
        }
        return true
    }

    function formatSubmitTooltip() {
        let initial_text = t("MISSING.INITIAL")
        switch (props.user_type) {
            case Role.ORTHOPTIST:
                if (!last_name) initial_text += t("MISSING.LASTNAME")
                if (!first_name) initial_text += t("MISSING.FIRSTNAME")
                if (!phone_number) initial_text += t("MISSING.PHONE_NUMBER")
                if (!r.validatePhoneNumber(phone_number)) initial_text += t("MISSING.VALID_PHONE_NUMBER")
                if (rpps && !r.validateRpps(rpps)) initial_text += t("MISSING.RPPS")
                if (adeli && !r.validateAdeli(adeli)) initial_text += t("MISSING.ADELI")
                break

            case Role.OPHTALMOLOGIST:
                if (!last_name) initial_text += t("MISSING.LASTNAME")
                if (!first_name) initial_text += t("MISSING.FIRSTNAME")
                if (rpps && !r.validateRpps(rpps)) initial_text += t("MISSING.RPPS")
                if (adeli && !r.validateAdeli(adeli)) initial_text += t("MISSING.ADELI")
                break
            case Role.EXTERN:
                if (!full_name) initial_text += t("MISSING.FULLNAME")
                break

            default:

        }

        return initial_text
    }

    function renderUsernameError() {
        if (!username) return (
            <div className="red-text"
                 data-cy="MissingUsername"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>{t("ERROR.USERNAME")}
            </div>
        )
    }

    function renderLastNameError() {
        if (!last_name) return (
            <div className="red-text"
                 data-cy="MissingLastName"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>{t("ERROR.LASTNAME")}
            </div>
        )
    }

    function renderFirstNameError() {
        if (!first_name) return (
            <div className="red-text"
                 data-cy="MissingFirstName"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>{t("ERROR.FIRSTNAME")}
            </div>
        )
        return null
    }

    function renderFullNameError() {
        if (!full_name) return (
            <div className="red-text"
                 data-cy="MissingFullName"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>{t("ERROR.FULLNAME")}
            </div>
        )
        return null
    }

    function renderPhoneNumberError() {
        if (props.user_type === Role.OPTICIAN) return null
        if (!phone_number) return (
            <div className="red-text"
                 data-cy="MissingPhoneNumber"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>{t("ERROR.PHONE_NUMBER")}
            </div>
        )
        if (!r.validatePhoneNumber(phone_number)) return (
            <div className="red-text"
                 data-cy="WrongPhoneNumber"
                 style={{display: "flex", alignItems: "center", gap: "1vw"}}>
                <i className="material-icons">error</i>{t("ERROR.VALID_PHONE_NUMBER")}
            </div>
        )
        return null
    }

    function renderAdeliError() {
        if (!adeli) return null
        if (!r.validateAdeli(adeli)) return (
            <div className="red-text"
                 data-cy="WrongAdeli"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>{t("ERROR.ADELI")}
            </div>
        )
        return null
    }

    function renderRppsError() {
        if (!rpps) return null
        if (!r.validateRpps(rpps)) return (
            <div className="red-text"
                 data-cy="WrongRpps"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>{t("ERROR.RPPS")}
            </div>
        )
        return null
    }

    const renderHelpIcon = (text) => {
        if (!text) return null
        return <Tooltip id="help_icon" position="bottom" text={text}>
            <i className="material-icons" style={{cursor: "default"}}>help</i>
        </Tooltip>
    }

    return (
        <div className="container"
             style={{width: "100%", padding: 20, display: "flex", flexDirection: "column", gap: 10, overflowY: "scroll"}}>
            <div className="valign-wrapper" style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
                <h1 className="center" style={{marginBlock: 20}}>{t("TITLE")}</h1>
                <HelpIcon url="https://www.temeoo.fr/orthoptists/documentation/configuration"/>
            </div>
            {FIELDS.map((category, category_index) => {
                if (!category.user_types.includes(props.user_type)) return null
                return (
                    <BrandedElement temeoo optician={category.hideOnOptician}>
                        <div key={`option_category_${category_index}`}
                             style={{display: "flex", flexDirection: "column", gap: 20}}>
                            <h2 data-cy={category.cy_data}>{category.category_title}</h2>
                            {category.fields.map((field, field_index) => {
                                if (!field.user_types.includes(props.user_type)) return null
                                return (
                                    <div key={`option_${field_index}`}
                                         style={{
                                             display: "flex",
                                             alignItems: "center",
                                             width: "100%", gap: 10
                                         }}>
                                        <Tooltip id={`administrator_${field.id}`}
                                                 position="right"
                                                 text={field.tooltip_info}>
                                            <div
                                                className="input-field outlined "
                                                style={{marginRight: "2vw", minWidth: `${category.size}%`}}>
                                                <input
                                                    data-cy={field.cy_data}
                                                    id={field.id}
                                                    type="text"
                                                    defaultValue={field.var_name}
                                                    onChange={(e) => field.setter(e.target.value)}
                                                    className="validate"
                                                />
                                                <label htmlFor={field.var_name}>{field.title}</label>
                                            </div>
                                        </Tooltip>
                                        {renderHelpIcon(field.description)}
                                        {field.error_rendering()}
                                    </div>
                                )
                            })}
                        </div>
                    </BrandedElement>
                )
            })}
            <div>

                {isValidToSubmit() ?
                    <button className="btn blue darken-2 z-depth-0"
                       onClick={updateInfos}
                       data-cy="EnabledSaveButton"
                    >{c("SAVE")}</button>
                    :
                    <Tooltip id="missing_info_tooltip" position="right" text={formatSubmitTooltip()}>
                        <div style={{width: "fit-content", height: "fit-content"}} data-cy="DisabledSaveToolTip">
                            <a className="btn blue darken-2 z-depth-0 disabled"
                               style={{margin: "auto"}}
                               onClick={updateInfos}
                            >{c("SAVE")}</a>
                        </div>
                    </Tooltip>
                }
            </div>


        </div>
    )
}