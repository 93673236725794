import React, {useState} from 'react'
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";
import MesureStore from "../../MesureStore";
import {LockingElement} from "../../../AppointmentValidation";
import {QuestionType} from "../../../../Shared/Enums/Enums";
import Switch from "../../../../Shared/Switch";
import SmartTextArea from "../../../../Shared/UiKit/SmartTextArea";

const MedicalQuestionnaire = ({json, report, onChange, firstYesNo, setFirstYesNo}) => {

    const renderCategory = (category, key) => {
        if (!category) return null
        return <div className="col s12 l6" key={key} style={{width: "100%", margin: "20px 0"}}>
            <BorderedDisplay text={category.label} style={{marginBottom: 0}}>
                {category.questions?.map((question, index) => {
                    return <div key={`question_${category.label}_${index}`}>
                        {renderQuestion(question, key)}
                        <div className={`divider ${index === category.questions.length - 1 ? "hide" : ""}`}/>
                    </div>
                })}
            </BorderedDisplay>
        </div>
    }

    const renderQuestion = (question, key, id = question.label) => {
        const required = MesureStore.listLockingElements().includes(LockingElement[question.condition]?.prompt)
        switch (question.type) {
            case QuestionType.YES_NO:
                if (firstYesNo === undefined) setFirstYesNo(id)
                const yes_label = question.yes_label ?? "Oui"
                const no_label = question.no_label ?? "Non"
                return <div key={`${key}_${id}`}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h5 className={required ? "red-text" : ""}>{question.label}</h5>
                        <Switch id={`switch_${id}`} left={no_label} right={yes_label}
                                onChange={(val) => onChange(id, val)}
                                value={report?.[id]}/>
                    </div>
                    <div style={{paddingLeft: "30px"}}>
                        {
                            report?.[id] ?
                                (question.subquestions?.map((subquestion, index) => {
                                    return <div key={`subquestion_${question.label.label}_${index}`}>
                                        <div className="divider"/>
                                        {renderQuestion(subquestion, key, `${id}##${subquestion.label}`)}
                                    </div>
                                })) : null
                        }
                    </div>
                </div>
            case QuestionType.INPUT:
                return <div key={`${key}_${id}`}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h5 className={required ? "red-text" : ""}>{question.label}</h5>
                        <select className="browser-default" style={{maxWidth: "30%"}}
                                id={`select_${id}`} value={report?.[id] || ""}
                                onChange={(e) => onChange(id, e.target.value)}>
                            <option value="" disabled>Choisissez</option>
                            {question.options.map((option, index) => <option key={index}
                                                                             value={option}>{option}</option>)}
                        </select>
                    </div>
                </div>
            case QuestionType.TEXT:
                return <div key={`${key}_${id}`}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: 20}}>
                        <h5 className={required ? "red-text" : ""}>{question.label}</h5>
                        <SmartTextArea onChange={(event) => onChange(id, event.target.value)}
                                       shortcuts={MesureStore.textual_shortcuts}
                                       value={report?.[id] ?? ""}
                                       style={{width: "100%", resize: "none", height: 40}}/>
                    </div>
                </div>
            default:
                return null
        }
    }

    return <div className="row" style={{gap: 20}}>
        <div className="col s12 l6">
            {json.slice(0, json.length / 2).map((category, index) => renderCategory(category, index))}
        </div>
        <div className="col s12 l6">
            {json.slice(json.length / 2, json.length).map((category, index) => renderCategory(category, index))}
        </div>
    </div>
}

MedicalQuestionnaire.defaultProps = {
    json: [],
    report: {},
    onChange: () => null,
    setFirstYesNo: () => null,
    firstYesNo: undefined
}

export default MedicalQuestionnaire