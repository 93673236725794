import React, {useEffect, useState} from "react";
import Switch from "../../../../Shared/Switch";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";
import {Logical, QuestionType} from "../../../../Shared/Enums/Enums";
import InputText from "../../../../Shared/UiKit/InputText";
import axios from "axios";
import {toast} from "react-toastify";
import useDebounce from "../../../../Shared/Hooks";
import MesureStore from "../../MesureStore";
import {Observer} from "mobx-react";
import StateButton from "../../../../Shared/StateButton";
import {loading, RequestState} from "../../../../Shared/StateHelper";
import {LockingElement} from "../../../AppointmentValidation";
import {t} from "../../../../I18n/i18n"
import SmartTextArea from "../../../../Shared/UiKit/SmartTextArea";
import MedicalQuestionnaire from "./MedicalQuestionnaire";

const _ = require("lodash")
function MedicalQuestions({json}) {
    const [questions, setQuestions] = useState(undefined)
    const [loaded, setLoaded] = useState(false)
    const [firstYesNo, setFirstYesNo] = useState(undefined)
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    useEffect(() => {
        fetchQuestions()
    }, [])

    const debouncedSave = useDebounce(questions, 100)

    useEffect(() => {
        if (loaded) {
            saveQuestions()
        }
        if (questions) setLoaded(true)
    }, [debouncedSave])

    const handleChange = (id, value) => {
        setQuestions(prevState => Object.assign({}, prevState, {[id]: value}))
    }

    const fetchQuestions = () => {
        axios.get("/api/appointements/report", {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
            setQuestions(resp.data.report ?? {})
            MesureStore.report = resp.data.report ?? {}
        }).catch((err) => {
            toast.error(t("MEDICAL_QUESTIONS.TOAST_ERROR_LOAD"))
        })
    }

    const saveQuestions = () => {
        setRequestState(loading(requestState))
        axios.patch("/api/appointements/report", {
            report: questions,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
            MesureStore.report = questions
            setRequestState(RequestState.IDLE)
        }).catch((err) => {
            toast.error(t("MEDICAL_QUESTIONS.TOAST_ERROR_SAVE"))
            setRequestState(RequestState.ERROR)
        })
    }

    const handleNothingToReport = () => {
        let allInputsToNo = Object.values(json).reduce((acc, category) => {
            category.questions.forEach((question) => {
                if (question.type === QuestionType.INPUT) {
                    if(question.options.includes(Logical.NO)) acc[question.label] = Logical.NO
                }
            })
            return acc
        }, {})
        allInputsToNo = _.isEmpty(allInputsToNo) ? {[firstYesNo]: false} : allInputsToNo

        setQuestions(allInputsToNo)
        setRequestState(RequestState.LOADING)
    }

    return <Observer>
        {() => {
            return <div className="center" style={{paddingInline: 30}}>
                <div style={{display: "flex", gap: 20, alignItems: "center", justifyContent: "center"}}>
                    <h2>{t("MEDICAL_QUESTIONS.QUESTIONNAIRE")}</h2>
                    <StateButton color="blue darken-2" text={t("EXAM_HISTORY.NOTHING_TO_REPORT")}
                                 state={requestState}
                                 data_cy="NothingSpecial"
                                 onClick={handleNothingToReport}/>
                </div>
                <MedicalQuestionnaire json={json}
                                      report={questions}
                                      onChange={handleChange}
                                      setFirstYesNo={setFirstYesNo}
                                      firstYesNo={firstYesNo}
                />
            </div>
        }}
    </Observer>
}

export default MedicalQuestions