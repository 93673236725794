import {ALERT_COLORS} from "./AlertColorEnum";
import React from "react";
import Helper from "../../Shared/Helper";
import axios from "axios";
import {wrapper} from "../../I18n/i18n"

export default function AlertCard(props) {
    const {t} = wrapper("ALERTS_FOLLOW_UP")

    function deleteNotification(e) {
        e.preventDefault()
        axios.delete(`/notifications/delete/${props.notification.id}`).then((resp) =>
            props.handleNotificationDeletion(parseInt(resp.data.id))
        )
    }

    return (
        <div className="card"
             style={{borderLeft: `solid 20px ${ALERT_COLORS[props.notification.alert.gravity]}`, marginBottom: "30px"}}>
            <i className="material-icons-outlined clickable"
               style={{cursor: "pointer", position: "absolute", top: "5px", right: "5px"}}
               onClick={deleteNotification}>delete</i>
            <div className="card-content" style={{paddingBlock: 0}}>
                <div className="row">
                    <div className="col s3">
                        <p><a href={`/patients/${props.notification.patient.id}`}>
                            {Helper.formatName(props.notification.patient.first_name, props.notification.patient.last_name)}
                        </a></p>
                        {Helper.formatPhoneNumber(props.notification.patient.phone) || t("UNKNOWN_PHONE")}<br/>
                        {t("AT", {date: Helper.formatDate(props.notification.alert.created_at)})}<br/>
                        {t("GRAVITY", {gravity: Helper.toCamelCase(props.notification.alert.gravity)})}<br/>
                        {props.notification.patient.email || t("UNKNOWN_EMAIL")} <br/>
                        {t("BORN", {date: Helper.formatDate(props.notification.patient.birthday)})} ({t("YEARS_OLD", {age: Helper.dateToAge(props.notification.patient.birthday)})})
                    </div>
                    <div className="col s3" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        {props.notification.appointment.pathologies.length ?
                            <div className="center">
                                <p><b>{t("SUSPECTED_PATHOLOGY")} </b></p>
                                <ul className="left">
                                    {props.notification.appointment.pathologies.map((pathology, index) =>
                                        <li key={index}> - {pathology.pathologie}</li>)}
                                </ul>
                            </div>
                            : <p style={{textAlign: "center"}}>{t("NO_PATHOLOGY")}</p>
                        }

                        {props.notification.appointment.type === "alert" ?
                            <a className="btn blue darken-2 z-depth-0 center"
                               href={`/syntheses/rendez-vous/${props.notification.appointment.id}`}
                               style={{width: "fit-content", alignSelf: "center", marginTop: "10px"}}
                            >{t("APPOINTMENT_SYNTHESIS")}</a>
                            : <p style={{marginTop: "15px"}}>{t("PRESCRIPTION_DELIVERED")}</p>
                        }
                    </div>
                    <div className="col s3" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                        {props.notification.need_to_pay ?
                            <div className="center" style={{display: "flex", alignItems: "center"}}>
                                <i className="material-icons-outlined">warning</i>{t("AWAITING_PAYMENT")}
                            </div> : null}
                        <br/>
                        <div className="center">
                            <a className="btn blue darken-2 z-depth-0"
                               target="_blank"
                               href={`/patients/documents/${props.notification.appointment.id}`}
                               style={{margin: "2px", width: "100%"}}>
                                {t("ACCESS_DOCUMENTS")}
                            </a>
                        </div>

                    </div>
                    <div className="col s3">
                        {props.notification.alert.text ?
                            <div>
                                {t("COMMENT")}<br/>
                                <p>{props.notification.alert.text}</p>
                            </div>
                            : <div>{t("NO_COMMENT")}</div>
                        }
                        <div className="divider"
                             style={{marginTop: "5px", marginBottom: "5px", backgroundColor: "#616161"}}/>
                        {props.notification.alert.risks ?
                            <div>
                                {t("RISKS")}<br/>
                                <p>{props.notification.alert.risks}</p>
                            </div>
                            : <div>{t("NO_RISK")}</div>
                        }
                        <div className="divider"
                             style={{marginTop: "5px", marginBottom: "5px", backgroundColor: "#616161"}}/>
                        {t("TO_DO_EXAMS")} <br/>
                        {JSON.parse(props.notification.alert.todo).join(", ")}
                    </div>
                </div>
            </div>

        </div>
    )
}