import React, {useEffect} from 'react'
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import PhonePortal from "./PhonePortal";
import TakePicture from "./TakePicture";
import TakeVideo from "./TakeVideo";
import PhoneStore from "./PhoneStore";

const PhoneRouter = ({appointment_id, patient_fullname}) => {

    useEffect(() => {
        PhoneStore.appointment_id = appointment_id
    }, []);

    return <div id="phone-view">
        <Router>
            <Routes>
                <Route path="/" exact element={<PhonePortal patient_fullname={patient_fullname}/>}/>
                <Route path="/picture" exact element={<TakePicture/>}/>
                <Route path="/video" exact element={<TakeVideo/>}/>
            </Routes>
        </Router>
    </div>

}

export default PhoneRouter