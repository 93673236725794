import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {isLoading, RequestState} from "../../../../../Shared/StateHelper";
import CVStore from "../../../../../CarteVitale/CVStore";
import BridgeStoreV2 from "../../../../../BridgeV2/BridgeStoreV2";
import {toast} from "react-toastify";
import axios from "axios";
import EditFse from "../../../../../CarteVitale/Fse/EditFse";
import MesureStore from "../../../MesureStore";
import useFseCreationRequest from "./hooks/useFseCreationRequest";
import {generatePdfFromUrl} from "../../../../../Shared/PDF/PDFManager";
import BrandedElement from "../../../../../Shared/BrandedElement";
import {t} from "../../../../../I18n/i18n"


const RawSCORButton = observer(({scorUrl}) => {
    const {sendFseCreationRequest} = useFseCreationRequest(MesureStore.appointement_id)

    console.log(scorUrl)
    const sendRequest = async () => {
        let pdfPromise = null
        if (scorUrl) {
            pdfPromise = axios.get(scorUrl, {responseType: 'arraybuffer'})
                .then((response) => {
                    return new Blob([response.data], {type: 'application/pdf'});
                }).catch((error) => console.log(error))
        } else {
            pdfPromise = generatePdfFromUrl("/api/appointment/scor?appointment_id=" + MesureStore.appointement_id)
        }

        const scorData = await toast.promise(pdfPromise, {
            pending: "Génération du SCOR en cours ...",
            success: "SCOR généré avec succès !",
            error: "Erreur lors de la création du SCOR."
        }).then((data) => {
            return data
        })

        const promise = sendFseCreationRequest(scorData, {scorOnly: true})
        promise.catch((error) => toast.error(error.message))
    }

    return <BrandedElement temeoo>
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <a className={`btn green darken-2 z-depth-0`}
               onClick={() => sendRequest()} title={"Enregistrer le SCOR sur son PC via TemeooDesktop"}>
                <i className="material-icons left">save</i>
                {t("PRESCRIPTION_AUTOFILL.REGISTER_SCOR")}
            </a>
        </div>
    </BrandedElement>
});

export default RawSCORButton;

