import React, {useState} from 'react';
import {t} from "../../../I18n/i18n";
import axios from "axios";
import {toast} from "react-toastify";

const ShowKey = () => {
    const [key, setKey] = useState(null)

    const getKey = () => {
        axios.get(`/api/appointment/patient/key?appointment_id=${MesureStore.appointement_id}`).then(response => {
            setKey(response.data.key)
        })
    }

    const keyToClipboard = () => {
        navigator.clipboard.writeText(key)
        toast.success(t("PRESCRIPTIONS_PAGES.KEY_COPIED"))
    }

    if (!key) return <a className={"btn-small btn-flat z-depth-0 grey lighten-4"}
                        onClick={getKey} style={{minWidth: 130}}>
        <i className="material-icons left">key</i>
        {t("PRESCRIPTIONS_PAGES.SHOW_KEY")}
    </a>

    return <a className={"btn-small btn-flat z-depth-0 grey lighten-4"}
              onClick={keyToClipboard} style={{minWidth: 130}}>
        <i className="material-icons left">key</i>
        {key}
    </a>
}

export default ShowKey;