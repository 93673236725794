import React from "react"

const SearchBar = ({search, setSearch, placeholder}) => {
    return <div className="input-field outlined" style={{width: "15%"}}>
        <input type="text"
               onChange={e => setSearch(e.target.value)}
               value={search}
               autoFocus style={{height: 40}}
               placeholder={placeholder}
               data-cy="SearchBarPatient"/>
    </div>
}

SearchBar.defaultProps = {
    search: "",
    setSearch: () => {},
    placeholder: "Rechercher ..."
}

export default SearchBar