import React, {useEffect} from 'react';
import axios from "axios";
import {observer} from "mobx-react";
import ShopsView from "./Shops/ShopsView";
import CampaignsView from "./Campaigns/CampaignsView";
import CampaignDetails from "./Campaigns/CampaignDetails";
import OpticianCampaignsStore from "./Campaigns/OpticianCampaignsStore";

const Dashboard = observer(() => {
    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
    }, []);

    return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
        <div style={{
            flex: 1,
            display: "grid", gap: 20,
            gridTemplateColumns: `repeat(2, ${!OpticianCampaignsStore.selectedCampaign ? 1 : 2}fr)`
        }}
        >
            <div style={{
                display: "grid", gap: 20,
                gridTemplateRows: "repeat(1, 1fr)"
            }}>
                <ShopsView/>
            </div>
            <div style={{
                display: "grid", gap: 20,
                gridTemplateRows: `repeat(1, 1fr)`
            }}>
                <CampaignsView/>
            </div>
        </div>
        {OpticianCampaignsStore.selectedCampaign ? <div style={{height: "50%"}}>
            <CampaignDetails/>
        </div> : null}
    </div>
});

export default Dashboard;