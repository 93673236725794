import React from 'react';
import Helper from "../../Shared/Helper";
import StatusIcon from "../../Shared/Invoices/StatusIcon";

const OpticianInvoiceLine = ({invoice}) => {
    const [expanded, setExpanded] = React.useState(false);

    const renderLine = () => {
        const amount = invoice.amount / 100.0;
        return <div>
            <div style={{display: "flex", gap: 10}} onClick={() => setExpanded(!expanded)}>
                <div className="row valign-wrapper clickable" style={{flex: 1}}>
                    <div className="valign-wrapper col s1">
                        <StatusIcon status={invoice.status}/>
                    </div>
                    <div className="col s3">
                        Le {Helper.formatDate(invoice.date)}
                    </div>
                    <div className="col s3">
                        {amount.toFixed(2)}€
                    </div>
                </div>
                <a className="btn-small blue darken-2 z-depth-0" target={"_blank"}
                   href={`/documents/invoice/${invoice.id}/viewer`}>
                    Voir documents
                </a>
                <a className="btn-small transparent btn-flat">
                    <i className={`material-icons ${expanded ? "flipped" : ""}`}>expand_more</i>
                </a>
            </div>
            {renderExpanded()}
        </div>
    }

    const renderExpanded = () => {
        if (!expanded) return null
        return <div style={{paddingLeft: 20, display: "flex", flexDirection: "column", gap: 10, paddingBlock: 5}}>
            {renderInvoiceDetail()}
        </div>
    }

    const renderInvoiceDetail = () => {
        if (!invoice.details || invoice.details.length === 0) return null;
        return invoice.details.sort((a, b) => b.amount - a.amount).map((detail) => {
            return <div key={`invoice_${invoice.id}_campaign${detail.campaign_name}`} className="valign-wrapper"
                        style={{paddingLeft: 20, gap: 20}}>
                <h5 style={{
                    margin: 0,
                    marginBlock: 3
                }}>{detail.campaign_name} : {(detail.campaign_amount / 100.0).toFixed(2)}€</h5>
            </div>
        })
    }

    return <div>
        {renderLine()}
    </div>
}

export default OpticianInvoiceLine;