import React from 'react';
import EditButton from "../../../../Shared/EditButton";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";

function OfficeGeneralOptions({office, onOfficeChange}) {
    return (
        <div style={{paddingTop: 3}}>
            <BorderedDisplay text="Information supplémentaires">
                <ul className="collection" style={{borderColor: "transparent"}}>
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">Prix leasing</span>
                        <div className="input-field right-align"
                             style={{marginLeft: "auto", marginTop: "0px", marginBottom: "0px", padding: "0px"}}>
                            <EditButton text={(parseFloat(office.leasing_price ?? 0) / 100.0).toFixed(2)} suffix="€"
                                        onValidation={(v) => onOfficeChange({...office, leasing_price: v * 100})}
                                        filter={(e) => e.target.value.match(/^\d*[.,]?\d{0,2}$/g)}
                            />
                        </div>
                    </li>
                    {office.orthoptistes?.length > 0 ? <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">Chef d'établissement</span>
                        <div className="input-field right-align"
                             style={{marginLeft: "auto", marginTop: "0px", marginBottom: "0px", padding: "0px"}}>
                            <select
                                className="browser-default" value={office?.leader_id ?? ""}
                                onChange={(e) => onOfficeChange({...office, leader_id: e.target.value})}
                            >
                                <option value="">Aucun chef d'établissement</option>
                                {office.orthoptistes.map((orthoptist) => (
                                    <option key={orthoptist.id} value={orthoptist.id}>{Helper.formatName(orthoptist.prenom, orthoptist.nom)}</option>
                                ))}
                            </select>
                        </div>
                    </li> : null}
                </ul>
            </BorderedDisplay>
        </div>
    );
}

export default OfficeGeneralOptions;