import React, {useEffect} from 'react';
import {getEnv} from "../Helper";
import {createPortal} from "react-dom";
import {UserStatus} from "../Enums/Enums";


function NavLi({element}) {
    if (element.show !== undefined && !element.show) {
        return null;
    }
    return (
        <li>
            <a href={element.url}>{element.name}</a>
        </li>
    )
}

const DropDownElementNav = ({element}) => {
    const triggerRef = React.useRef("bloup");
    useEffect(() => {
        M.Dropdown.init(triggerRef.current, {coverTrigger: false, constrainWidth: false});
    }, []);
    return <React.Fragment>
        {createPortal(
            <ul id={element.name} className="dropdown-content">
                {element.dropdown.map((sub_element) => {
                    return <NavLi key={sub_element.name} element={sub_element}/>
                })}
            </ul>
            , document.body)}
        <li>
            <a className="dropdown-trigger" ref={triggerRef} href={"#"} data-target={element.name}>{element.name}
                <i className="material-icons right" style={{lineHeight: "inherit"}}> arrow_drop_down</i>
            </a>
        </li>
    </React.Fragment>
}


function DropDownMobileNav({element}) {
    return (
        <React.Fragment>
            {element.dropdown.map((sub_element) => {
                    return <NavLi key={sub_element.name} element={sub_element}/>

                }
            )}
        </React.Fragment>
    )
}

export default function NavBar({
                                   image_path,
                                   urls,
                                   components = [],
                                   topbar_prop = "",
                                   status = UserStatus.ACTIVE.value
                               }) {
    const mobileRef = React.useRef(null);
    useEffect(() => {
        new M.Sidenav(mobileRef.current, {});
    }, []);

    const color = (status) => {
        switch (status) {
            case UserStatus.ACTIVE.value:
                return "white";
            case UserStatus.ARCHIVED.value:
            case UserStatus.TEST.value:
                return "grey lighten-2";
            case UserStatus.LOCKED.value:
                return "red darken-3";
            default:
                return "white";
        }
    }

    const statusInfo = (status) => {
        switch (status) {
            case UserStatus.ARCHIVED.value:
                return <li
                    title="Ce compte est archivé, vous pouvez consulter les informations mais pas réaliser d'examen">
                    <h3 style={{margin: "35px 10vh", fontWeight: "bolder"}}>Compte archivé</h3>

                </li>;
            case UserStatus.TEST.value:
                return <li
                    title="Ce compte est en test, vous pouvez réaliser des examens mais ils ne seront pas pris en compte">
                    <h3 style={{margin: "35px 10vh", fontWeight: "bolder"}}>Mode test</h3>
                </li>;
            case UserStatus.LOCKED.value:
                return <li>
                    <h3 style={{margin: "35px 10vh", fontWeight: "bolder"}}>Compte bloqué</h3>
                </li>;
            default:
                return null;
        }
    }

    return <nav className={topbar_prop}>
        <div className={`nav-wrapper ${color(status)}`}>
            <a href="/" className="brand-logo valign-wrapper" style={{height: "90px"}}>
                {getEnv("BRAND") === "WHITE" ?
                    <img height="70px" style={{margin: "10px 40px"}} src={image_path}/> :
                    <img height="45px" src={image_path}/>
                }
            </a>
            <a href="#" data-target="mobile-sidenav" className="sidenav-trigger"><i
                className="material-icons black-text">menu</i></a>

            <ul id="nav-mobile" className="right hide-on-med-and-down">
                {statusInfo(status)}
                {urls.map((element) => {
                    if (element.dropdown) {
                        return (
                            <DropDownElementNav key={element.name} element={element}/>
                        )
                    } else {
                        return (
                            <NavLi key={element.name} element={element}/>
                        )
                    }

                })}
                {components.map((component) => {
                    return (
                        <li key={component.dataCy} dataCy={component.dataCy}>{component.component}</li>
                    )
                })}
            </ul>
        </div>
        {/*mobile*/}
        <ul className="sidenav" id="mobile-sidenav" ref={mobileRef}>
            {urls.map((element) => {
                if (element.dropdown) {
                    return (
                        <DropDownMobileNav key={element.name} element={element}/>
                    )
                } else {
                    return (
                        <NavLi key={element.name} element={element}/>
                    )
                }

            })}
        </ul>
    </nav>
}