import {useEffect} from 'react';

function MessageNotifier({onReceived, conversation_id}) {
    useEffect(() => {
        subscribeToConversation(conversation_id);
    }, [conversation_id]);

    const subscribeToConversation = (conversation_id) => {
        App.messages = App.cable.subscriptions.create({channel: 'InstantMessageChannel', id: conversation_id}, {
            received: function (data) {
                onReceived(data);
            }
        });
    };
}

MessageNotifier.defaultProps = {
    conversation_id: null,
    onMessageReceived: () => {}
};

export default MessageNotifier;