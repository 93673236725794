import InfiniteScroll from 'react-infinite-scroll-component';
import React, {Component} from "react";
import axios from "axios";
import ShowEye from "./shared/ShowEye"

export default class AllAnalyseImage extends Component {
    constructor(props){
        super(props);
        this.loadData = this.loadData.bind(this)
        this.fetchMoreData = this.fetchMoreData.bind(this)
        this.state = {
            page:1,
            data: [],
            loading: false,
            response: [],
        }
        this.loadData(1);

    }

    loadData(page){
        axios.get("/analystes/images/data?page="+page).then((data)=> {
            this.setState({
                page: page + 1,
                data: this.state.data.concat(data.data)
            })
        })
    }
    fetchMoreData(){
        this.loadData(this.state.page)
    }



    render() {
        const data = this.state.data

        return (
            <div>
                <InfiniteScroll
                    dataLength={this.state.data.length}
                    next={this.fetchMoreData}
                    hasMore={true}
                    loader={<h4 className="center">Chargement...</h4>}
                >
                <div className="">
                    <div className="row">
                        {data.map((value,index)=>( //c est mieux non  ? Ouais c'est pas mal
                          <ShowEye value={value} key={index}/>
                        ))}
                      </div>
                </div>

                </InfiniteScroll>
                <div className="container">
                      <div className="col s3" style={{marginTop:40}}>
                          <div className="center">
                              <a href="/" className="btn"> <i
                                  className="material-icons left">sentiment_satisfied_alt</i>Continuer les analyses</a>
                          </div>
                      </div>
                </div>

            </div>
        );


    }
}
