import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {t} from "../../I18n/i18n";
import Helper from "../../Shared/Helper";
import PrescriptorStore from "../PrescriptorStore";
import moment from "moment";
import {RequestState} from "../../Shared/StateHelper";
import Error from "../../Shared/Error";

const PatientDetails = () => {
    const nav = useNavigate()
    const {patient_id} = useParams()
    const [patient, setPatient] = useState({})
    const [appointments, setAppointments] = useState([])
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    useEffect(() => {
        getPatient()
    }, [patient_id])

    const getPatient = () => {
        setRequestState(RequestState.LOADING)
        PrescriptorStore.getPatient(patient_id).then((response) => {
            setPatient(response.data.patient)
            setAppointments(response.data.appointments)
            setRequestState(RequestState.SUCCESS)
        }).catch(() => {
            setRequestState(RequestState.ERROR)
        })
    }

    const getBirthday = () => {
        const today = new Date()
        const patientBirthday = new Date(patient.birthday)

        const diffTime = Math.abs(today - patientBirthday)
        return Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365))
    }

    const cardInfoPatient = () => {
        return <div className="card">
            <div className="valign-wrapper" style={{justifyContent: "center", gap: 20, padding: 10}}>
                <h2 className="center" style={{margin: 0}}>{t("PATIENT_INFO.PATIENT_TITLE")}</h2>
            </div>
            <div className="divider"/>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginBlock: "2vh",
                paddingInline: 20,
                overflowY: "scroll",
            }}>
                <div className="valign-wrapper">
                    <i className="material-icons small left" style={{color: "#1976D2"}}>person</i>
                    {Helper.formatName(patient.prenom, patient.nom)}
                    <h6 style={{fontWeight: 600, marginInline: "auto 5px"}}>{patient.is_cmu ? "PATIENT CMU" : ""}</h6>
                </div>
                <div className="valign-wrapper">
                    <i className="material-icons blue-text text-darken-2 small left">mail</i>
                    {patient.email}
                </div>
                <div className="valign-wrapper">
                    <i className="material-icons blue-text text-darken-2 small left">phone</i>
                    {patient.telephone || t("PATIENT_INFO.WITHOUT_TEL")}
                </div>
                <div className="valign-wrapper center">
                    <i className="material-icons blue-text text-darken-2 small left">
                        calendar_month
                    </i>{" "}
                    {getBirthday()} ans
                </div>
                <div className="valign-wrapper">
                    <i className="material-icons blue-text text-darken-2 small left">house</i>
                    {patient.address ?? t("PATIENT_INFO.WITHOUT_ADDRESS")}
                </div>
                {patient.num_carte_vitale != null ? (
                    <div className="valign-wrapper">
                        <i className="material-icons blue-text text-darken-2 small left">credit_card</i>
                        {patient.num_carte_vitale}
                    </div>
                ) : (
                    <div className="valign-wrapper">
                        <i className="material-icons blue-text text-darken-2 small left">credit_card</i>
                        {t("PATIENT_INFO.WITHOUT_CARD_VITAL")}
                    </div>
                )}
            </div>
        </div>
    }

    const renderDocumentButton = (appointment) => {
        if (appointment.document_count === 0) {
            return <a className="btn-small blue darken-2 z-depth-0 disabled truncate">
                Aucun document disponible
            </a>
        }
        return <a className="btn blue darken-2 z-depth-0 truncate"
                  href={`/prescriptors/dashboard/documents/${appointment.id}`}
                  target="_blank">{appointment.document_count} documents</a>
    }

    const cardAppointmentInfo = () => {
        return <div className="card">
            <div className="valign-wrapper" style={{justifyContent: "center", gap: 20, padding: 10}}>
                <h2 className="center" style={{margin: 0}}>Rendez-vous avec l'un de nos orthoptistes</h2>
            </div>
            <table className="temeoo-table">
                <thead>
                <tr>
                    <th>Date examen</th>
                    <th>Orthoptiste</th>
                    <th>Commentaire</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {appointments.map((appointment, index) => (
                    <tr key={index}>
                        <td>{moment(appointment.start).format('DD/MM/YYYY')}</td>
                        <td>{appointment.orthoptist_fullname}</td>
                        <td style={{width: 300, whiteSpace: "pre-wrap"}}>{appointment.comment ? appointment.comment : "Aucun commentaire n'a été laissé"}</td>
                        <td className="right">
                            {renderDocumentButton(appointment)}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    }


    if (requestState === RequestState.LOADING) {
        return <div>
            <div className="progress">
                <div className="indeterminate"/>
            </div>
        </div>
    }

    if (requestState === RequestState.ERROR) {
        return <div>
            <a style={{margin: 20}} className="btn-flat" onClick={() => nav(`/`)}>
                <i className={"material-icons left"}>arrow_back</i>
                Liste des patients
            </a>
            <div id="full-height" style={{padding: 200}}>
                <Error errorText="Impossible de charger la page, veuillez contacter le support Temeoo"/>
            </div>
        </div>
    }

    return <div>
        <a style={{margin: 20}} className="btn-flat" onClick={() => nav(`/`)}>
            <i className={"material-icons left"}>arrow_back</i>
            Liste des patients
        </a>
        <div className="container">
            {patient ? (
                <div style={{display: "flex", width: "100%", gap: 20}}>
                    <div style={{width: "30%"}}>
                        {cardInfoPatient()}
                    </div>
                    <div style={{width: "70%"}}>
                        {cardAppointmentInfo()}
                    </div>
                </div>
            ) : (
                <div style={{display: "flex", width: "100%", gap: 20}}>
                    <div className="card container">
                        <h3 className="center">Une erreur s'est produite lors du chargement des rendez-vous..</h3>
                    </div>
                </div>
            )}
        </div>
    </div>
}

export default PatientDetails