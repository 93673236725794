import React from 'react';
import OrthoptistSelect from "../../../Users/OrthoptistSelect";
import OphthalmologistSelect from "../../../Users/OphthalmologistSelect";
import AddGuests from "./AddGuests";

const MedicalEventForm = ({medicalEvent, onChange}) => {

    return <div className="col s12" style={{display: "flex", flexDirection: "column", gap: 20}}>
        <div className="input-field outlined col s12">
            <input id="name" type="text" value={medicalEvent.name}
                   onChange={(e) => onChange({...medicalEvent, name: e.target.value})}/>
            <label htmlFor="name">Nom de l'évènement</label>
        </div>
        <div className="input-field outlined col s12">
            <textarea id="description" value={medicalEvent.description}
                      style={{height: 100, paddingBlock: 10, resize: "none"}}
                      onChange={(e) => onChange({...medicalEvent, description: e.target.value})}/>
            <label htmlFor="name">Description de l'évènement</label>
        </div>
        <div className="row" style={{gap: 20}}>
            <div className="input-field outlined col s6">
                <input id="date" type="date" value={medicalEvent.date} min={new Date().toISOString().split("T")[0]}
                       onChange={(e) => onChange({...medicalEvent, date: e.target.value})}/>
                <label htmlFor="date">Date de l'évènement</label>
            </div>
            <div className="input-field outlined col s6">
                <input id="time" type="time" value={medicalEvent.time}
                       onChange={(e) => onChange({...medicalEvent, time: e.target.value})}/>
                <label htmlFor="time">Heure de l'évènement</label>
            </div>
        </div>
        <div className="row" style={{gap: 20}}>
            <div className="col s6">
                <OrthoptistSelect value={medicalEvent.orthoptiste_id}
                                  onChange={(value) => onChange({...medicalEvent, orthoptiste_id: value})}/>
            </div>
            <div className="col s6">
                <OphthalmologistSelect value={medicalEvent.ophtalmologiste_id}
                                       disabled={!medicalEvent.orthoptiste_id}
                                       filter={{orthoptiste_id: medicalEvent.orthoptiste_id}}
                                       onChange={(value) => onChange({...medicalEvent, ophtalmologiste_id: value})}/>
            </div>
        </div>
        <div style={{paddingTop: 20}}>
            <AddGuests selectedGuests={medicalEvent.guests}
                       onAdd={(guest) => onChange({...medicalEvent, guests: [...medicalEvent.guests, guest]})}
                       onDelete={(guest) => onChange({
                           ...medicalEvent,
                           guests: medicalEvent.guests.filter(g => g.id !== guest.id)
                       })}/>
        </div>

    </div>
}

export default MedicalEventForm;