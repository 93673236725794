import React, {useEffect} from 'react';
import {RequestState} from "../../../Shared/StateHelper";
import axios from "axios";
import OphtalmologistEarningLine from "./OphtalmologistEarningLine";

const GeneralEarnings = () => {
    const [requestState, setRequestState] = React.useState(RequestState.IDLE)
    const [invoices, setInvoices] = React.useState([])

    useEffect(() => {
        getInvoices()
    }, []);

    const getInvoices = () => {
        setRequestState(RequestState.LOADING)
        axios.get("/admin/statistics/ophtalmologist/general_earnings", {}).then((response) => {
            setInvoices(response.data.invoices)
            setRequestState(RequestState.SUCCESS)
        }).catch((error) => {
            setRequestState(RequestState.ERROR)
        })
    }

    return <div className="container" style={{padding: 20}}>
        <div className="collection" style={{borderRadius: 5}}>
            {invoices.map(invoice => {
                return <div className="collection-item transparent" key={`invoice_${invoice.id}`}>
                    <OphtalmologistEarningLine invoice={invoice}/>
                </div>
            })}
        </div>
    </div>
}

export default GeneralEarnings;