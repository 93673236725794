import React, {useEffect} from 'react';
import {wrapper} from "../../I18n/i18n";
import AddCampaignButton from "./AddCampaignButton";
import axios from "axios";
import CampaignLine from "./CampaignLine";
import {RequestState} from "../../Shared/StateHelper";
import Error from "../../Shared/Error";
import Loader from "../../Shared/Loader";
import OpticianCampaignsStore from "./OpticianCampaignsStore";
import {observer} from "mobx-react";

const CampaignsView = observer(() => {
    const {t} = wrapper("OPTICIAN_DASHBOARD.CAMPAIGNS")
    const [requestState, setRequestState] = React.useState(RequestState.IDLE)

    useEffect(() => {
        getCampaigns()
    }, []);

    const getCampaigns = () => {
        setRequestState(RequestState.LOADING)
        axios.get("/opticians/campaigns")
            .then(response => {
                OpticianCampaignsStore.campaigns = response.data
                setRequestState(RequestState.SUCCESS)
            })
            .catch(_ => {
                setRequestState(RequestState.ERROR)
            })
    }

    const renderCampaigns = () => {
        if (requestState === RequestState.ERROR) {
            return <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                paddingBottom: 200,
                gap: 20
            }}>
                <Error errorText={t("ERROR_LOADING_CAMPAIGNS")}/>
            </div>
        }

        if (requestState === RequestState.LOADING) {
            return <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                paddingBottom: 200,
                gap: 20
            }}>
                <Loader size="small" wide/>
            </div>
        }

        if (!OpticianCampaignsStore.campaigns.length) return <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingBottom: 200,
            gap: 20
        }}>
            <h3 className="center" style={{margin: 0}}>{t("NO_CAMPAIGNS")}</h3>
            <AddCampaignButton/>
        </div>

        return <div className="collection" style={{borderRadius: 5, flex: "1 1 0", overflowY: "scroll"}}>
            {OpticianCampaignsStore.campaigns.slice().sort((a, b) => a.status > b.status ? 1 : -1).map(campaign => <div className="collection-item transparent" key={campaign.name}>
                    <CampaignLine campaign={campaign}/>
                </div>
            )}
        </div>
    };

    return <div className="card" style={{display: "flex", flexDirection: "column", gap: 20}}>
        <h3 style={{margin: 10}}>{t("TITLE")}</h3>
        {renderCampaigns()}
        {OpticianCampaignsStore.campaigns?.length ? <div style={{paddingInline: 40}}>
            <AddCampaignButton id="create_campaign_button"/>
        </div> : null}
    </div>
})

export default CampaignsView;