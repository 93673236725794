import React from "react";
import Acces from "../../syntheses/Acces";
import Helper from "../../Shared/Helper";
import SendCodeToPatient from "./SendCodeToPatient";
import BrandedElement from "../../Shared/BrandedElement";
import {t} from "../../I18n/i18n"
import moment from "moment"

function PatientInfo({
                         patient,
                         cle,
                         orthoptiste_signed,
                         ophtalmologiste_signed,
                         secretaire_signed,
                         style,
                         office_name
                     }) {
    const getBirthday = () => {
        const today = new Date()
        const patientBirthday = new Date(patient.birthday)

        const diffTime = Math.abs(today - patientBirthday)
        return Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365))
    };

    return (
        <div className="card" style={{...style, display: "flex", flexDirection: "column"}} data-cy="PatientInfoComponent">
            <div className="fr">
                <div className="fr valign-wrapper" style={{justifyContent: "center", flex: 1}}>
                    <h2 className="center" style={{margin: 0}}>{t("PATIENT_INFO.PATIENT_TITLE")}</h2>
                    <a className="btn-small blue darken-2 z-depth-0"
                       data-cy="EditPatientButton"
                       style={{paddingInline: 10}}
                       href={`/patients/${patient.id}/edit`}>
                        <i className="material-icons">edit</i>
                    </a>
                </div>
            </div>
            <div className="divider"/>
            <div style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "3vh",
                overflowY: "scroll",
                height: "100%"
            }}>
                <div>
                    <i className="material-icons small left" style={{color: "#1976D2"}}>person</i>
                </div>
                <div>
                    <div style={{ fontSize: "23px", marginBottom: 5}}>
                        {Helper.formatName(patient.prenom, patient.nom)}
                        {/*<h6 style={{fontWeight: 600, marginInline: "auto 5px"}}>{patient.is_cmu ? "PATIENT CMU" : ""}</h6>*/}
                    </div>
                    <div>
                        {moment(patient.birthday).format("DD/MM/YYYY")} ({getBirthday()} ans)
                    </div>
                    <div>
                        {patient.email}
                    </div>
                    <div>
                        + {patient.telephone || t("PATIENT_INFO.WITHOUT_TEL")}
                    </div>
                </div>
            </div>
            <div className="valign-wrapper center" style={{ marginBlock: "2vh"}}>
                <i className="material-icons blue-text text-darken-2 small left">key</i>
                <BrandedElement temeoo bypass={!orthoptiste_signed}>
                    {orthoptiste_signed ? (
                        <b><a href={`/orthoptistes/connect_as_patient?key=${cle}`} target="_blank"
                              title={t("PATIENT_INFO.LINK_ACCESPATIENT")}>{cle}</a></b>
                    ) : ophtalmologiste_signed ? (
                        <b><a href={`/ophtalmologistes/connect_as_patient?key=${cle}`}
                              target="_blank" title={t("PATIENT_INFO.LINK_ACCESPATIENT")}>{cle}</a></b>
                    ) : secretaire_signed ? (
                        <b><a href={`/secretaires/connect_as_patient?key=${cle}`} target="_blank"
                              title={t("PATIENT_INFO.LINK_ACCESPATIENT")}>{cle}</a></b>
                    ) : null
                    }
                </BrandedElement>
                <BrandedElement optician>
                    {orthoptiste_signed ?<b>******</b> : null}
                </BrandedElement>
                <div className="valign-wrapper" style={{marginLeft: "auto", gap: 10}}>
                    <SendCodeToPatient patient={patient}/>
                    <Acces patient={patient} access_key={cle}/>
                </div>
            </div>
        </div>
    );
}

export default PatientInfo;
