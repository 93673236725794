import React, {useEffect} from "react"
import moment from "moment/moment";
import DocumentHeader from "./DocumentsComponents/DocumentHeader";
import Helper from "../Shared/Helper";
import PlaceholderSignature from "../Shared/PlaceholderSignature";
import {Act} from "../Shared/Enums/Enums";

const Prescription = ({prescription}) => {

    useEffect(() => {
        window.status = "ready";
    }, []);

    const renderHeader = () => {
        const ophtalmologist = {
            nom: prescription.ophtalmo_lastname,
            address: prescription.ophtalmo_address,
            city: prescription.ophtalmo_city,
            postal_code: prescription.ophtalmo_postal_code,
            office_name: prescription.ophtalmo_office_name,
            adeli: prescription.ophtalmo_adeli,
            rps: prescription.ophtalmo_rps
        }
        const orthoptist = {
            phone_number: prescription.orthoptist_phone_number,
            last_name: prescription.ortho_lastname,
            first_name: prescription.ortho_firstname,
            adeli: prescription.ortho_adeli,
            address: prescription.ortho_address,
            city: prescription.ortho_city,
            postal_code: prescription.ortho_postal_code,
        }
        const patient = {
            firstname: prescription.patient_firstname,
            lastname: prescription.patient_lastname,
            birthday: prescription.patient_birthday,
            email: prescription.patient_email,
            tel: prescription.patient_telephone,
        }
        const appointment = {
            date: moment(prescription.date, "DD-MM-YYYY"),
        }
        if (!prescription.header) return <DocumentHeader
            orthoptist={orthoptist}
            ophtalmologist={ophtalmologist}
            patient={patient}
            appointment={appointment}
            display_ortho_info
        />
        return <>
            <div dangerouslySetInnerHTML={{__html: prescription.header}}/>
            {prescription.orthoptist_phone_number ?
                <div style={{marginBlock: 0, fontSize: 14, whiteSpace: "nowrap"}}>
                    Pour toute questions relatives à la consultation, merci de contacter
                    le {prescription.orthoptist_phone_number}
                </div> : <div/>}
            <p style={{
                textAlign: "right",
                fontSize: 14
            }}>{prescription.date}</p>
            <p style={{textAlign: "left", fontSize: 14}}>
                {prescription.patient_firstname} {prescription.patient_lastname}
            </p>
            <p style={{marginBottom: "40px"}}/>
            <p className="divider-xl"/>
        </>
    }

    const renderTitle = () => {
        let text = "Ordonnance de lunettes"
        const glasses_keywords = [
            "OD : ", "OG : ", "OEIL DROIT : ", "OEIL GAUCHE : ", "monture pour vision"
        ]
        if (!glasses_keywords.reduce((acc, val) => acc || prescription.text.includes(val), false)) {
            text = "Prescription"
        }
        return <h4 className="center" style={{fontWeight: 600}}>{text}</h4>
    }

    const ophtalmo_name = Helper.formatName(prescription.ophtalmo_firstname, prescription.ophtalmo_lastname)
    const ortho_name = Helper.formatName(prescription.ortho_firstname, prescription.ortho_lastname)

    const renderFooter = () => {
        return <div className="pdf-footer prevent-break"
                    style={{marginTop: "auto", display: "flex", flexDirection: "column", gap: 20}}>
            <div>
                {prescription.signature ?
                    <img style={{height: "150px", float: "right"}}
                         src={prescription.signature} alt=""/>
                    : <PlaceholderSignature
                        style={{float: "right"}}
                        firstname={prescription.ophtalmo_firstname}
                        lastname={prescription.ophtalmo_lastname}
                    />
                }
            </div>
            <div className={prescription.act_name === Act.RNM ? "" : "hide"}>
                <p className="center" style={{
                    marginInline: 40,
                    fontSize: "0.6rem",
                    fontWeight: "bolder",
                    fontStyle: "italic"
                }}>
                    Examen réalisé par {ortho_name} dans le cadre du protocole Muraine co-signé
                    entre l'ARS
                    la CPAM et {ophtalmo_name}, Article 51 de la loi HPST du 21 juillet 2009.
                </p>
                <p className="center" style={{fontSize: "0.5rem", fontStyle: "italic"}}>
                    Membre d'une association de gestion agréée. Le réglement par chèque est accepté.
                </p>
            </div>
        </div>
    }

    const renderDocument = () => {
        return <div className="pdf-document pdf-page"
                    style={{display: "flex", flexDirection: "column", height: "275mm"}}>
            <div style={{fontFamily: "Arial, sans-serif"}}>
                {renderHeader()}
                {renderTitle()}
                <div style={{margin: "auto", marginLeft: "50px"}}>
                    <p style={{whiteSpace: "pre-wrap", fontSize: 14}}>{prescription.text}</p>
                    {prescription.override_lock && prescription.appointment_alerted ?
                        <p style={{textAlign: "center"}}>Cette prescription ne vous dispense pas de
                            la
                            consultation d'un ophtalmologiste suite aux pathologies détectées
                            lors de l'analyse.</p>
                        : null
                    }
                </div>
            </div>
            {renderFooter()}
        </div>

    }

    return <div className="container">
        <div className="card" style={{width: "210mm", height: "297mm", margin: 20}}>
            {renderDocument()}
        </div>
    </div>
}

export default Prescription