import React from 'react';
import CKEditor from "../Shared/CKEditor";
import axios from "axios";
import {toast} from "react-toastify";
import EditMessage from "./EditMessage";

const EditMessages = ({id, header, messages}) => {

    const saveHeader = (value) => {
        axios.post(`/cabinets/${id}/headers`, {header: value})
            .then(response => toast.success("En-tête enregistré"))
            .catch(error => console.log(error))
    }

    const renderHeader = () => {
        return <div>
            <h1 className="center">En-tête</h1>
            <CKEditor text={header} onChange={value => saveHeader(value)} options={{height: "25vh"}}/>
        </div>
    }

    const renderMessages = () => {
        return <div>
            <h1 className="center">Messages</h1>
            <table>
                <thead>
                <tr>
                    <th>Titre</th>
                    <th>Message</th>
                    <th>Message HN</th>
                </tr>
                </thead>
                <tbody>
                {messages.map((message, index) => {
                    return <tr key={index}>
                        {<EditMessage message={message} id={id}/>}
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    }

    return <div id="full-height" style={{
        display: "flex", flexDirection: "column", gap: 10, overflowY: "scroll", padding: 20
    }}>
        {renderHeader()}
        {renderMessages()}
    </div>
};


export default EditMessages;