import React, {useEffect} from "react";
import {toast} from "react-toastify";
import SendNotificationButton from "./SendNotificationButton";
import GlobalNotificationsStore from "./GlobalNotificationsStore";
import {observer} from "mobx-react";
import DOMPurify from "dompurify";

const GlobalNotificationList = observer(() => {

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = () => {
        GlobalNotificationsStore.getNotifications()
    }

    const deleteNotification = (id) => {
        GlobalNotificationsStore.deleteNotification(id).catch(() => {
            toast.error("Impossible de supprimer la notification")
        })
    }

    return <div style={{padding: 40}}>
        <table className="temeoo-table">
            <thead>
            <tr>
                <th>Titre</th>
                <th>Contenu</th>
                <th>Exiger confirmation</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {GlobalNotificationsStore.notifications.map((notification) => {
                return <tr key={notification.id}>
                    <td>{notification.title}</td>
                    <td>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(notification.content)}}/>
                    </td>
                    <td>{notification.need_confirmation ? "Oui" : "Non"}</td>
                    <td className="right">
                        <SendNotificationButton notification={notification}/>
                        <a className="btn-small blue darken-2 z-depth-0"
                           onClick={() => GlobalNotificationsStore.onEditNotification = notification}>
                            <i className="material-icons">edit</i>
                        </a>
                        <a className="btn-small red darken-2 z-depth-0"
                           onClick={() => deleteNotification(notification.id)}>
                            <i className="material-icons">delete</i>
                        </a>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    </div>
})

export default GlobalNotificationList