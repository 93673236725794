import React from 'react';
import {InvoiceStatus} from "../../../Shared/Enums/Enums";

const InvoiceStamp = ({status}) => {
    if (status === InvoiceStatus.SUCCESS) {
        return <div style={{
            height: "100px",
            width: "300px",
            border: "solid 5px #5ca85e",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#5ca85e"
        }}>
            <i className="material-icons" style={{fontSize: "5rem"}}>check_circle</i>
            <span style={{fontWeight: "bolder", fontSize: "xxx-large", marginLeft: "10px"}}>PAYÉ</span>
        </div>
    }
}

export default InvoiceStamp;