import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {t} from "../../../I18n/i18n";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import DisplayCard from "../../../Shared/UiKit/DisplayCard";

function CurrentlyAnalysed() {
    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [filesCount, setfilesCount] = React.useState(null);

    React.useEffect(() => getfilesCount(), [])

    const getfilesCount = () => {
        axios.get("/admin/statistiques/api/orthoptist/analysisExams")
            .then(resp => {
                setfilesCount(resp.data.in_analysis_exams)
                setRequestState(RequestState.SUCCESS)
            }).catch(() => {
            toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.NB_APPOINTMENTS"))
            setRequestState(RequestState.ERROR)
        })
    }

    const renderFilesAnalysedCount = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <h3
            className="valign-wrapper" style={{flex: 1, fontSize: "3em", margin: 0, paddingInline: "0.6em"}}>
            <b>{filesCount}</b>
        </h3>
    }
    return <DisplayCard title="En analyse" color="amber lighten-1"
                        link="/agenda/index?done=true&analyse=true">{renderFilesAnalysedCount()}</DisplayCard>
}

export default CurrentlyAnalysed;