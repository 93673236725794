import Preferences from "../Preferences";
import {useEffect, useState} from "react";

export const defaultShortcuts = [
    {
        trigger: "shrug",
        replacement: "¯\\_(ツ)_/¯",
        hidden: true
    },
    {
        trigger: "tableflip",
        replacement: "(╯°□°）╯︵ ┻━┻",
        hidden: true
    },
    {
        trigger: "unflip",
        replacement: "┬─┬ ノ( ゜-゜ノ)",
        hidden: true
    }
]


const useShortcuts = () => {
    const [shortcuts, setShortcuts] = useState(defaultShortcuts)

    useEffect(() => {
        getShortcuts()
    }, []);

    const mergeShortcuts = (parsedShortcuts) => {
        const mergedShortcuts = defaultShortcuts.map(defaultShortcut => {
            const parsedShortcut = parsedShortcuts.find(parsedShortcut => parsedShortcut.trigger === defaultShortcut.trigger)
            return parsedShortcut ? parsedShortcut : defaultShortcut
        })
        const newShortcuts = parsedShortcuts.filter(parsedShortcut => !defaultShortcuts.find(defaultShortcut => defaultShortcut.trigger === parsedShortcut.trigger))
        return newShortcuts.concat(mergedShortcuts)
    }

    const getShortcuts = () => {
        Preferences.get('textual_shortcuts').then(shortcuts => {
            setShortcuts(mergeShortcuts(JSON.parse(shortcuts)))
        })
    }

    const saveShortcuts = (shortcuts) => {
        // Don't save the default shortcuts unless they've been modified
        const shortcutToSave = shortcuts.filter(shortcut => !defaultShortcuts.find(defaultShortcut => defaultShortcut.trigger === shortcut.trigger && defaultShortcut.replacement === shortcut.replacement))
        return Preferences.set({textual_shortcuts: JSON.stringify(shortcutToSave)}).then((resp) => {
            setShortcuts(mergeShortcuts(JSON.parse(resp.textual_shortcuts)))
        })
    }

    return {shortcuts, saveShortcuts}
}

export default useShortcuts;