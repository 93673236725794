import {I18n} from "i18n-js";
import en from "./translations/en/en.js"
import fr from "./translations/fr/fr.js"

let i18n = new I18n({
    en: en,
    fr: fr
});
i18n.defaultLocale = "en";
i18n.fallbacks = true;
i18n.missingBehavior = "guess";

// Detect browser language and set it as the locale
const browserLang = navigator.language || navigator.userLanguage;
const lang = browserLang.split("-")[0];
i18n.locale = lang === "en" ? "en" : "fr";

const t = i18n.t.bind(i18n);

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const uncapitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toLowerCase() + s.slice(1)
}

const upper = (s) => {
    if (typeof s !== 'string') return ''
    return s.toUpperCase()
}

const lower = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase()
}

const u = (key, opts) => upper(t(key, opts))
const l = (key, opts) => lower(t(key, opts))
const c = (key, opts) => capitalize(t(key, opts))

const wrapper = (prefix) => {
    return {
        t: (key, opts) => t(prefix + "." + key, opts),
        u: (key, opts) => u(prefix + "." + key, opts),
        l: (key, opts) => l(prefix + "." + key, opts),
        c: (key, opts) => c(prefix + "." + key, opts)
    }
}


global.i18n = i18n;
export {
    i18n,
    t,
    upper,
    lower,
    capitalize,
    uncapitalize,
    wrapper,
    u,
    l,
    c
}
