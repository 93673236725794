import Helper from "../Shared/Helper";
import ConvoqueBox from "../secretariat/alerts/ConvoqueBox";
import HonoreBox from "../secretariat/alerts/HonoreBox";
import SecretaryComment from "../secretariat/alerts/SecretaryComment";
import React from "react";
import {wrapper} from "../I18n/i18n";

export default function SelectedAlert({
                                          findCorrespondingAttribute,
                                          selectedAlert,
                                          deSelectAlert,
                                          archivateAlert,
                                          handleChecked,
                                          handleSecretaryCommentChange,
                                          honoredUrl,
                                          convokedUrl,
                                          commentUrl,
                                          role
                                      }) {

    const {t} = wrapper("ALERTS_FOLLOW_UP")

    const renderPatientBasicInfos = () => {
        return <div style={{textAlign: "left"}}>
            <a href={`/patients/${selectedAlert.patient.id}`}>
                <div className={findCorrespondingAttribute("text_color", selectedAlert.alert.gravity)}
                     style={{fontSize: 20}}>
                    <b>{Helper.formatName(selectedAlert.patient.first_name, selectedAlert.patient.last_name)}</b>
                </div>
            </a>
            <div>Age: {selectedAlert.patient.birthday ? Helper.dateToAge(selectedAlert.patient.birthday) + " ans" : "Non renseigné"}</div>
            <div>Téléphone: {selectedAlert.patient.phone ?? "Non renseigné"}</div>
            <div>Vu par: {selectedAlert.appointment.orthoptist_full_name ?? "Non renseigné"}</div>
            <div>Le: {selectedAlert.alert.created_at ?? "Non renseigné"}</div>
        </div>
    }

    const renderAppointmentsRisksAndTodo = () => {
        return <div>
            {selectedAlert.alert.risks ?
                <div>
                    {t("RISKS")}<br/>
                    <p>{selectedAlert.alert.risks}</p>
                </div>
                : <div>{t("NO_RISK")}</div>
            }
            <div className="divider"
                 style={{marginTop: "5px", marginBottom: "5px", backgroundColor: "#616161"}}/>
            {t("TO_DO_EXAMS")} <br/>
            {JSON.parse(selectedAlert.alert.todo).join(", ")}
            <div className="divider"
                 style={{marginTop: "5px", marginBottom: "5px", backgroundColor: "#616161"}}/>
            Commentaire :<br/>
            {selectedAlert.alert.comment.length ? selectedAlert.alert.comment : "Aucun commentaire"}
        </div>
    }

    const renderDocumentLinkAndPatientPresence = () => {

        return <div>
            <a href={`/patients/documents/${selectedAlert.appointment.id}`}
               target="_blank"
               className={`z-depth-2 z-depth-0 ${findCorrespondingAttribute("button_color", selectedAlert.alert.gravity)} btn`}
               style={{borderRadius: "20px"}}
            >
                Documents
            </a>
            <a href={`/syntheses/rendez-vous/${selectedAlert.appointment.id}`}
               target="_blank" title={"Synthèse du rendez-vous"}
               className={`z-depth-2 z-depth-0 ${findCorrespondingAttribute("button_color", selectedAlert.alert.gravity)} btn`}
               style={{borderRadius: "20px", paddingInline: 10}}
            >
                <i className={"material-icons"}>description</i>
            </a>

            <ul style={{textAlign: "left", paddingLeft: "20%"}}>
                <li><ConvoqueBox alert_id={selectedAlert.alert.id}
                                 text={role === "orthoptist" ? "À réorienter" : "À reconvoquer"}
                                 checked={selectedAlert.alert.convoked}
                                 onChange={(checked) => handleChecked("convoked", checked)}
                                 url={convokedUrl}
                />
                </li>
                <li><HonoreBox alert_id={selectedAlert.alert.id}
                               text={role === "orthoptist" ? "Réorienté" : "Reconvoqué"}
                               checked={selectedAlert.alert.honored}
                               onChange={(checked) => handleChecked("honored", checked)}
                               url={honoredUrl}
                />
                </li>
            </ul>
        </div>
    }

    const renderComments = () => {
        return <div>
            <a className={`btn-floating ${findCorrespondingAttribute("button_color", selectedAlert.alert.gravity)}`}
               href={`/secretariat/email?patient=${selectedAlert.patient.id}`}>
                <i className="material-icons-outlined">email</i>
            </a>
            <SecretaryComment alert_id={selectedAlert.alert.id}
                              comment={selectedAlert.alert.secretary_comment}
                              onChange={handleSecretaryCommentChange}
                              url={commentUrl}
                              text={role === "orthoptist" ? "Commentaire" : "Commentaire secrétaire"}
            />
            <div className="left-align" style={{display: "flex", flexDirection: "column", gap: 5, paddingBlock: 10}}>
                <h6 style={{margin: 0}}>Commentaire orthoptiste :</h6>
                <h6 title={selectedAlert.appointment.comment_for_ophtalmologist ?? null} style={{margin: 0}}>
                    {selectedAlert.appointment.comment_for_ophtalmologist?.substring(0, 100) ?? "Aucun commentaire"}
                    {selectedAlert.appointment.comment_for_ophtalmologist?.length > 100 ? "..." : ""}
                </h6>
            </div>
        </div>
    }

    const renderPatientFullInfos = () => {
        return <div className="col s5" style={{textAlign: "left"}}>
            <a href={`/patients/${selectedAlert.patient.id}`}
               className={findCorrespondingAttribute("text_color", selectedAlert.alert.gravity)}
               style={{fontSize: 20}}>
                <b>{Helper.formatName(selectedAlert.patient.first_name, selectedAlert.patient.last_name)}</b>
            </a>
            <div>Email: {selectedAlert.patient.email} </div>
            <div>Téléphone: {selectedAlert.patient.phone} </div>
            <div>Date de naissance: {Helper.formatDate(selectedAlert.patient.birthday)} </div>
            <div>Age: {selectedAlert.patient.birthday ? Helper.dateToAge(selectedAlert.patient.birthday) + " ans" : "Non renseigné"}</div>
        </div>
    }

    const renderAppointmentInfos = () => {
        return <div className="col s4" style={{textAlign: "left"}}>
            <b>Détails du rendez-vous: </b>
            <div>Montant du rendez-vous: {selectedAlert.appointment.amount_due}€</div>
            <div>Vu par: {selectedAlert.appointment.orthoptist_full_name ?? "Non renseigné"}</div>
            <div>Le: {selectedAlert.alert.created_at ?? "Non renseigné"}</div>
            <div>Gravité: {findCorrespondingAttribute("name", selectedAlert.alert.gravity)}</div>
        </div>
    }

    if (!selectedAlert) return null

    return <div className="container" style={{width: "80%"}}>
        <div style={{borderRadius: "10px", padding: 0}}
             className={`card ${findCorrespondingAttribute("secondary_color", selectedAlert.alert.gravity)}`}>
            <div className="card-content black-text" style={{paddingTop: 0}}>
                <span className="card-title" style={{position: "absolute", right: "10px", top: "10px"}}>
                    <i className="material-icons right" style={{cursor: "pointer"}} onClick={deSelectAlert}>close</i>
                    <i className="material-icons activator right" style={{cursor: "pointer"}}>more_vert</i>
                 </span>
                <br/>
                <div className="row" style={{gap: 10}}>
                    <div className="col s3">{renderPatientBasicInfos()}</div>
                    <div className="col s3">{renderAppointmentsRisksAndTodo()}</div>
                    <div className="col s3">{renderDocumentLinkAndPatientPresence()}</div>
                    <div className="col s3">{renderComments()}</div>
                </div>
            </div>
            <div className={`card-reveal ${findCorrespondingAttribute("secondary_color", selectedAlert.alert.gravity)}`}
                 style={{textAlign: "center"}}>
                        <span className="card-title"
                              style={{position: "absolute", right: "10px", top: "10px"}}>
                                            <i className="material-icons right" style={{cursor: "pointer"}}>close</i>
                        </span>
                <div className="row">
                    {renderPatientFullInfos()}
                    {renderAppointmentInfos()}
                    <div className="col s3 center">
                        <a className="waves-effect waves-light red btn"
                           onClick={() => archivateAlert()}
                        >Archiver</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}