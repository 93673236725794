import React, {useEffect, useState} from "react";
import GlobalNotificationsStore from "./GlobalNotificationsStore";
import {toast} from "react-toastify";
import MarkdownEditor from "../../../Shared/UiKit/MarkdownEditor";
import {observer} from "mobx-react";

const CreateGlobalNotification = observer(() => {
    const onEdit = !!GlobalNotificationsStore.onEditNotification
    const [notification, setNotification] = useState(null)

    useEffect(() => {
        reset()
    }, [GlobalNotificationsStore.onEditNotification]);

    const reset = () => {
        setNotification(null)
        // forced re-render because bangle markdown editor doesn't update the value and only allows to set it once
        setTimeout(() => setNotification(GlobalNotificationsStore.onEditNotification || {}), 1)
    }

    const createNotification = () => {
        GlobalNotificationsStore.createNotification(notification).then(() => {
            reset()
        }).catch(() => {
            toast.error("Impossible de créer la notification")
        })
    }

    const updateNotification = () => {
        GlobalNotificationsStore.updateNotification(notification).then(() => {
            reset()
        }).catch(() => {
            toast.error("Impossible de mettre à jour la notification")
        })
    }

    if (!notification) return null
    return <div className="fc">
        <h6>Titre</h6>
        <div className="input-field outlined">
            <input id="title" type="text" value={notification.title}
                   onChange={(e) => setNotification({...notification, title: e.target.value})}/>
        </div>
        <h6>Texte</h6>
        <MarkdownEditor defaultValue={notification.content} noImages
                        onChange={(v) => setNotification(prevState => {return {...prevState, content: v}})}/>
        <div className="switch">
            <label>
                Exiger confirmation
                <input type="checkbox" id={"test"} checked={notification.need_confirmation}
                       onChange={(e) => setNotification({...notification, need_confirmation: e.target.checked})}/>
                <span className="lever"></span>
            </label>
        </div>
        <div className="fr" style={{justifyContent: "right"}}>
            <a className={"btn-small blue darken-2 z-depth-0"}
               onClick={onEdit ? updateNotification : createNotification}>
                <i className="material-icons left">save</i>
                {onEdit ? "Mettre à jour" : "Créer"}
            </a>
            {onEdit ? <a className={"btn-small red darken-2 z-depth-0"}
                         onClick={() => GlobalNotificationsStore.onEditNotification = null}>
                <i className="material-icons left">clear</i>
                Annuler
            </a> : null}
        </div>
    </div>
})

export default CreateGlobalNotification