import {Side} from "../Enums/Enums";

const PRESCRIPTION_TYPE = {
    SHORTSIGHT_GLASSES: {
        label: "Lunettes de près", text: `Une Monture pour Vision de Près :

D : {ODSPHEREADD} ({ODCYLINDRE} à {ODAXE}°)
G : {OGSPHEREADD} ({OGCYLINDRE} à {OGAXE}°)`
    },
    LONGSIGHT_GLASSES: {
        label: "Lunettes de loin", text: `Une Monture pour Vision de Loin :

D : {ODSPHERE} ({ODCYLINDRE} à {ODAXE}°) 
G : {OGSPHERE} ({OGCYLINDRE} à {OGAXE}°)`
    },
    PROGRESSIVE_GLASSES: {
        label: "Lunettes progressives", text: `Verres Progressifs et Monture :

D : {ODSPHERE} ({ODCYLINDRE} à {ODAXE}°) Add {ODADD} 
G : {OGSPHERE} ({OGCYLINDRE} à {OGAXE}°) Add {OGADD}

Ou si intolérance

Une Monture pour Vision de Loin :

D : {ODSPHERE} ({ODCYLINDRE} à {ODAXE}°) 
G : {OGSPHERE} ({OGCYLINDRE} à {OGAXE}°)

Une Monture pour Vision de Près :

D : {ODSPHEREADD} ({ODCYLINDRE} à {ODAXE}°)
G : {OGSPHEREADD} ({OGCYLINDRE} à {OGAXE}°)`
    },
    DOUBLE_CORED_GLASSES: {
        label: "Lunettes double-foyer", text: `Verres Double-foyer et Monture :

D : {ODSPHERE} ({ODCYLINDRE} à {ODAXE}°) Add {ODADD} 
G : {OGSPHERE} ({OGCYLINDRE} à {OGAXE}°) Add {OGADD}`
    },
}

const PRESCRIPTION_TOKEN = {
    OD_SPHERE: {t: "{ODSPHERE}", var: "od_sphere", signed: true},
    OD_CYLINDRE: {t: "{ODCYLINDRE}", var: "od_cylindre", signed: false},
    OD_AXE: {t: "{ODAXE}", var: "od_axe", signed: false},
    OD_SPHERE_ADD: {t: "{ODSPHEREADD}", var: ["od_sphere", "od_addition"], signed: true},
    OD_ADD: {t: "{ODADD}", var: "od_addition", signed: true},
    OG_SPHERE: {t: "{OGSPHERE}", var: "og_sphere", signed: true},
    OG_CYLINDRE: {t: "{OGCYLINDRE}", var: "og_cylindre", signed: false},
    OG_AXE: {t: "{OGAXE}", var: "og_axe", signed: false},
    OG_SPHERE_ADD: {t: "{OGSPHEREADD}", var: ["og_sphere", "og_addition"], signed: true},
    OG_ADD: {t: "{OGADD}", var: "og_addition", signed: true},
}

export const measure_to_prescription = (measure, type) => {
    const text = PRESCRIPTION_TYPE[type].text
    return replace_tokens(text, measure)
}

const replace_tokens = (text, measure) => {
    const subjective_ref = measure
    let replaced = Object.values(PRESCRIPTION_TOKEN).reduce((acc, elem) => {
        console.log(elem)
        let value = elem.var instanceof Array ? elem.var.reduce((acc, v) => {
                return acc + (parseFloat(subjective_ref[v]) || 0)
            }, 0)
            : subjective_ref[elem.var]

        // No sphere : plan
        let sign = value >= 0 ? "+" : ""
        if (!value &&
            (elem.var.includes("og_sphere") || elem.var.includes("od_sphere") ||
                elem.var === "og_sphere" || elem.var === "od_sphere")) {
            value = "plan"
            sign = ""
        }

        // No addition : remove "Add"
        acc = acc.replaceAll(elem.t, `${elem.signed ? sign : ""}${value ?? ""}`)
        if (!value && (elem.var === "og_addition" || elem.var === "od_addition")) {
            acc = acc.replace(/Add\s*\+?/g, "")
        }
        return acc
    }, text).replace(/\(\s*à\s*°\)/g, "") // Remove "( à °)" when no cylinder and no axis

    // Check if there is values for each side
    const has_values = (side) => Object.entries(subjective_ref).reduce((acc, [k, v]) => {
        if (!k.includes(side === Side.LEFT ? "og" : "od")) return acc
        return acc || !!v
    }, false)
    if (!has_values(Side.LEFT)) replaced = replaced.replace(/G : [^\n]*/g, "G : Non mesurable")
    if (!has_values(Side.RIGHT)) replaced = replaced.replace(/D : [^\n]*/g, "D : Non mesurable")
    return replaced
}