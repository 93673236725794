import React, {useState} from "react"
import MedicalQuestionnaire from "../../Appointments/Mesures/mesure_components/Questions/MedicalQuestionnaire";

const nurse_question_json = require("../../Appointments/Mesures/mesure_components/Questions/nurse_questions.json")

// STANDALONE EVENT QUESTIONNAIRE COMPONENT
// USELESS AT THE MOMENT BUT I FEEL IT WILL BE USEFUL IN THE FUTURE
const EventQuestionnaire = ({report, onChange}) => {
    const [firstYesNo, setFirstYesNo] = useState(undefined)


    const handleChange = (key, value) => {
        let new_report = {...report}
        new_report[key] = value

        onChange(cleanReport(new_report))
    }

    const handleNothingToReport = () => {
        let new_report = {}
        new_report[firstYesNo] = false

        onChange(cleanReport(new_report))
    }

    const cleanReport = (report) => {
        let cleanedReport = {...report}
        Object.keys(report).forEach((key) => {
            if (report[key] === false) {
                // delete all keys that start with the key + "##"
                Object.keys(report).forEach((subkey) => {
                    if (subkey.startsWith(key + "##")) {
                        delete cleanedReport[subkey]
                    }
                })
            }
        })
        return cleanedReport
    }


    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div className="valign-wrapper" style={{justifyContent: "center"}}>
            <a className="btn-small btn-flat blue lighten-5 blue-text text-darken-2 z-depth-0"
               title={"Signaler le questionnaire comme rempli sans rien à signaler"}
               onClick={handleNothingToReport}>
                <i className="material-icons left">done</i>
                Rien à signaler
            </a>
        </div>
        <MedicalQuestionnaire json={nurse_question_json}
                              report={report}
                              onChange={handleChange}
                              firstYesNo={firstYesNo}
                              setFirstYesNo={setFirstYesNo}
        />
    </div>
}

export default EventQuestionnaire