import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import Helper from "../../Shared/Helper";
import {observer} from "mobx-react";
import MedicalQuestionnaire from "../../Appointments/Mesures/mesure_components/Questions/MedicalQuestionnaire";
import {toast} from "react-toastify";
import {MedicalEventStatus} from "../../admin/Console/MedicalEvents/Enums/Enums";

const nurse_question_json = require("../../Appointments/Mesures/mesure_components/Questions/nurse_questions.json")

const _ = require('lodash')
const MedicalEventQuestionnaire = observer(() => {
    const {appointment_id} = useParams();
    const nav = useNavigate();
    const [patient, setPatient] = useState(MedicalEventStore.patients.find(p => p.appointment_id === parseInt(appointment_id)) ?? {})
    const [firstYesNo, setFirstYesNo] = useState(undefined)


    useEffect(() => {
        setPatient(MedicalEventStore.patients.find(p => p.appointment_id === parseInt(appointment_id)) ?? {})
    }, [MedicalEventStore.patients]);


    const validate = (nothingToReport = false) => {
        const report = nothingToReport ? {} : patient.report
        return MedicalEventStore.saveReport({...patient, report: cleanReport(report)}).then(() => {
            toast.success("Questionnaire enregistré")
        }).catch(() => {
            toast.error("Erreur lors de l'enregistrement du questionnaire")
        })
    }

    // If yesno questions are false, then subquestions need to be erased
    const cleanReport = (report) => {
        let cleanedReport = {...report}
        Object.keys(report).forEach((key) => {
            if (report[key] === false) {
                // delete all keys that start with the key + "##"
                Object.keys(report).forEach((subkey) => {
                    if (subkey.startsWith(key + "##")) {
                        delete cleanedReport[subkey]
                    }
                })
            }
        })

        if (_.isEmpty(cleanedReport)) cleanedReport[firstYesNo] = false

        return cleanedReport
    }

    const handleChange = (key, value) => {
        setPatient(prevState => {
            return {...prevState, report: {...prevState.report, [key]: value}}
        })
    }

    return <div style={{display: "flex", flexDirection: "column", padding: 0}}>
        <div className="valign-wrapper" style={{gap: 20, padding: 5, marginInline: 10}}>
            <a className="btn-small blue darken-2 z-depth-0"
               onClick={() => validate().then(() => nav("/"))}>
                <i className="material-icons left">save</i>
                Enregistrer et retour liste
            </a>
            <a className="btn-small btn-flat grey lighten-4 grey-text text-darken-2 z-depth-0"
               onClick={() => {
                   nav("/")
               }}>
                <i className="material-icons left">list</i>
                Retour liste sans sauvegarder
            </a>
            <h5 style={{margin: 0}}>Dossier de {Helper.formatName(patient.firstname, patient.lastname)}</h5>
            <a className="btn-small btn-flat blue lighten-5 blue-text text-darken-2 z-depth-0"
               title={"Signaler le questionnaire comme rempli sans rien à signaler"}
               style={{marginLeft: "auto"}}
               onClick={() => validate(true)}>
                <i className="material-icons left">done</i>
                Rien à signaler
            </a>
            {MedicalEventStore.event.status === MedicalEventStatus.IN_PROGRESS ? (
                <a className="btn-small btn-flat grey lighten-4 grey-text text-darken-2 z-depth-0"
                   onClick={() => validate().then(() => nav(`/appointment/${appointment_id}`))}>
                    <i className="material-icons right">chevron_right</i>
                    Passer au dossier
                </a>
            ) : null}
        </div>
        <div className="divider"/>
        <div style={{padding: "20px 40px"}}>
            <MedicalQuestionnaire json={nurse_question_json}
                                  report={patient.report}
                                  onChange={handleChange}
                                  firstYesNo={firstYesNo}
                                  setFirstYesNo={setFirstYesNo}
            />
        </div>
    </div>
})

export default MedicalEventQuestionnaire