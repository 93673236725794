import React, {useEffect, useState} from "react";
import axios from "axios";
import {Bar} from "react-chartjs-2";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../Controle/Loader";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Montants',
        },
    },
};
/*
this component will display all the statistics of an ophtalmologist, at the moment only elpased time on controle validate
 the data is ready to  add charts
 */
export default function OphtalmologistsStatistics() {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('[name=csrf-token]').content;
    const [dataByOphtalmologist, setDataByOphtalmologist] = useState({});
    const [requestState, setRequestState] = useState(RequestState.LOADING)
    const min = 0;
    const max = 60;
    const bins = 20;
    const binSize = (max - min) / bins;

    const labels = Array(bins).fill(0).map((_, index) => {
        return (index * binSize + min) + '-' + (index * binSize + min + binSize) + ' sec'
    })


    useEffect(() => {
        loadDatas()
    }, [])
    const loadDatas = () => {

        axios.get("/admin/console/ophtalmologist/statistics")
            .then((response) => {
                let dataByOphtalmologist = {}
                response.data.forEach((data) => {
                    let ophtalmo = data.ophtalmologiste.id
                    if (dataByOphtalmologist[ophtalmo] === undefined) {
                        dataByOphtalmologist[ophtalmo] = {
                            data: Array(bins).fill(0),
                            nom: data.ophtalmologiste.nom,
                            prenom: data.ophtalmologiste.prenom
                        }
                    }
                    data.duration_ophtalmology_control /= 1000;
                    let index = Math.floor((data.duration_ophtalmology_control - min) / binSize)
                    if (index >= bins) {
                        index = bins - 1
                    }
                    dataByOphtalmologist[ophtalmo].data[index] += 1

                })
                setDataByOphtalmologist(dataByOphtalmologist)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((_) => setRequestState(RequestState.ERROR))
    }

    if (requestState === RequestState.LOADING) return <div className="center" style={{marginTop: "20px"}}><Loader/>
    </div>
    if (requestState === RequestState.ERROR) return <div className="center">Une erreur est survenue.</div>
    if (requestState === RequestState.SUCCESS) return (
        <div className="container" style={{width: "90%"}}>
            <h3 className="center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <i className="material-icons">analytics</i>Statistiques des ophtalmologistes sur les 6 derniers mois
            </h3>
            <h4>Histogramme de répartition du temps de contrôle ophtalmologique</h4>
            <div>
                {Object.keys(dataByOphtalmologist).map((ophtalmo) => {
                    let data = dataByOphtalmologist[ophtalmo]
                    return (
                        <div key={ophtalmo} style={{width: "250px", height: "250px"}}>
                            <Bar
                                data={{
                                    labels: labels,
                                    datasets: [
                                        {
                                            data: data.data,
                                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                        }
                                    ]
                                }}
                                options={{
                                    plugins: {
                                        legend: {display: false},
                                        title: {
                                            display: true,
                                            text: Helper.formatName(data.prenom, data.nom),
                                        }
                                    },
                                    maintainAspectRatio: false
                                }}/>
                        </div>
                    )
                })}
            </div>

        </div>
    )

}