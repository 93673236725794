import React, {useEffect} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../../Shared/Loader";
import Helper from "../../../../Shared/Helper";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import RightsStore from "../../RightsStore";
import Error from "../../../../Shared/Error";
import {Observer} from "mobx-react";
import StatusButton from "../StatusButton";
import {Link} from "react-router-dom";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";

const _ = require('lodash');

function Secretaries(props) {
    const modal_id = `console_secretaries_modal`
    const ophtalmologists = props.ophtalmologists
    const [secretaries, setSecretaries] = React.useState(props.secretaries)
    const [onEditSecretaries, setOnEditSecretaries] = React.useState({})
    const [filter, setFilter] = React.useState("")

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [secretaries, RightsStore.rights])

    const new_secretary = () => {
        setOnEditSecretaries({onEdit: false})
        open_modal()
    }

    const edit_secretary = (extern) => {
        setOnEditSecretaries({...extern, onEdit: true})
        open_modal()
    }

    const open_modal = () => {
        setTimeout(() => {
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.open();
        }, 100)
    }

    const create_secretary = () => {
        axios.put('/admin/console/users/secretary', {
            secretary: onEditSecretaries
        }).then((resp) => {
            setSecretaries([...secretaries, resp.data])
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Mail invalide")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer le cabinet")
        })
    }

    const update_secretary = (secretary) => {
        axios.patch(`/admin/console/users/secretary/${secretary.id}`, {
            secretary: secretary
        }).then((resp) => {
            setSecretaries(secretaries.map(secr => secr.id === secretary.id ? resp.data : secr))
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de modifier le cabinet")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content container">
                <h3 className="center"
                    style={{marginTop: 20}}>{onEditSecretaries.onEdit ? "Éditer" : "Nouvel"} orthoptiste</h3>
                <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Email</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditSecretaries.email || ""}
                                   onChange={(e) => setOnEditSecretaries({
                                       ...onEditSecretaries,
                                       email: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Mot de passe</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditSecretaries.password || ""}
                                   onChange={(e) => setOnEditSecretaries({
                                       ...onEditSecretaries,
                                       password: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Ophtalmologiste</span>
                        <select className="browser-default outlined" style={{width: "100%"}}
                                value={ophtalmologists.find(r => r.id === parseInt(onEditSecretaries.ophtalmologiste_id))?.id || ""}
                                onChange={(e) => setOnEditSecretaries({
                                    ...onEditSecretaries,
                                    ophtalmologiste_id: e.target.value
                                })}>
                            <option value="" disabled>Choisir un ophtalmologiste</option>
                            {ophtalmologists.filter(o => !_.isEmpty(o.nom + o.prenom))
                                .sort((a, b) => (a.nom || "zzz") > (b.nom || "aaa"))
                                .map((ophtalmologist) => {
                                    return <option key={ophtalmologist.id}
                                                   value={ophtalmologist.id}>{Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom)}</option>
                                })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                {onEditSecretaries.onEdit ?
                    <a className="btn btn-small blue darken-2 z-depth-0"
                       onClick={() => update_secretary(onEditSecretaries)}>Valider</a>
                    :
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={create_secretary}>Créer</a>}
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderSecretaries = (secretary) => {
        const ophtalmologist = ophtalmologists.find(o => o.id === secretary.ophtalmologiste_id)
        return (
            <div className="collection-item transparent" key={secretary.id}
                 style={{display: "flex", width: "100%", gap: 10}}>
                <div style={{width: "100%", margin: 0, display: "flex", alignItems: "center"}}>
                    <StatusButton status={secretary.status} id={secretary.id}
                                  onChange={(value) => {
                                      if (secretary.status !== value) {
                                          update_secretary({...secretary, status: value})
                                      }
                                  }}
                    />
                    <div style={{width: 40, margin: 0}}>
                        <span>#{secretary.id}</span>
                    </div>
                    <div style={{width: 400, margin: 0}}>
                        <span>{secretary.email}</span>
                    </div>
                    <div style={{width: 300, margin: 0}}>
                        <span>{ophtalmologist ? Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom) : ""}</span>
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <div style={{flex: 1}}>
                            <ResponsiveMoreButton id={`secretary_${secretary.id}`} options={[
                                <a className="btn btn-small blue darken-2 z-depth-0"
                                   onClick={() => edit_secretary(secretary)}>
                                    <i className="material-icons left">edit</i>
                                    Modifier
                                </a>,
                                <Link to={`/secretaries/${secretary.id}`}
                                      className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">dashboard</i>
                                    Tableau de bord
                                </Link>
                            ]}/>
                        </div>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    if (_.isEmpty(secretaries)) {
        return <div style={{margin: 20}}>
            <Loader size="tiny" text=""/>
        </div>
    }

    const render = () => {
        if (RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY) === undefined) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Loader text=""/>
            </div>
        }
        if (!RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY)) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
            </div>
        }

        return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
            <h3 className="valign-wrapper" style={{margin: 20}}>
                <i className="material-icons left">phone_in_talk</i>
                Secrétaires
            </h3>
            <div className="divider"/>
            <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div className="input-field outlined">
                        <input type="text" style={{height: 40}} placeholder={"Rechercher"} autoFocus
                               value={filter} onChange={e => setFilter(e.target.value)}/>
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <a className="btn btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
                           onClick={() => setOnEditSecretaries({onEdit: false})}>
                            <i className="material-icons left">add</i>
                            Nouveau
                        </a>
                    </RestrictedElement>
                </div>

                <div className="collection"
                     style={{borderRadius: 5, height: "100%", overflowY: "scroll"}}>
                    {secretaries.filter(secretary => {
                        const str = `${secretary.email}`.toLocaleLowerCase()
                        return str.includes(filter.toLowerCase())
                    })
                        .sort((a, b) => a.email > b.email).map((secretary) => {
                            return renderSecretaries(secretary)
                        })}
                </div>
                <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                    {renderModal()}
                </RestrictedElement>
            </div>
        </div>
    }

    return <Observer>
        {() => render()}
    </Observer>
}

export default Secretaries;