import React from 'react';
import {wrapper} from "../../I18n/i18n"
import Validator from "../../Shared/Validator";

function EditAppointmentButton({appointment}) {
    const {t} = wrapper("EXAM_HISTORY.EDIT")

    const redirect = () => {
        window.location.href = `/mesure/${appointment.id}`
    }


    const renderButton = () => {
        return <a className={`btn-small z-depth-0 blue darken-2 ${appointment.status_controle > 1 ? "disabled" : ""}`}
                  style={{paddingInline: 10}}>
            <i className={`material-icons`}>edit</i>
        </a>
    }

    if (appointment.status_controle < 1) return <a title="Accéder au dossier"
        className={`btn-small z-depth-0 blue darken-2 ${appointment.status_controle > 1 ? "disabled" : ""}`}
        style={{paddingInline: 10}} href={`/mesure/${appointment.id}`}>
        <i className={`material-icons`}>edit</i>
    </a>

    return <Validator id={`edit_appointment_${appointment.id}`}
                      onValidation={redirect}
                      text={t("VALIDATION_TEXT")}
                      detail={t("VALIDATION_DETAIL")}
                      validationColor="blue darken-2" validationIcon="edit"
                      validationText={t("VALIDATION")}
                      abortColor="black" abortIcon="close" warning
    >
        {renderButton()}
    </Validator>
}

export default EditAppointmentButton;