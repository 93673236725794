import React, {useEffect} from "react";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import axios from "axios";

const PatientsNotation = ({}) => {

    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [patientsNotation, setPatientsNotation] = React.useState([]);

    useEffect(() => {
        getPatientsNotation()
    }, []);

    const getPatientsNotation = () => {
        axios.get("/patient_ratings/orthoptist")
            .then((response) => {
                setPatientsNotation(response.data.patient_ratings.slice(0, 3))
                setRequestState(RequestState.SUCCESS)
            })
            .catch((_) => setRequestState(RequestState.ERROR))
    }

    const renderNotations = () => {
        return <div className="container" style={{width: "90%"}}>
            {patientsNotation.sort((a, b) => a.date < b.date ? 1 : -1).map((rating) => {
                return renderNotation(rating)
            })}
        </div>
    }

    const renderNotation = (rating) => {
        return <div style={{display: "flex", flexDirection: "column", gap: 5}}>
            <h6 style={{display: "flex", justifyContent:"space-between"}}>
                <div>{Helper.formatName(rating.first_name, rating.last_name)}</div>
                <div>{Helper.computeDate(rating.date)}</div>
            </h6>
            <div className="card" style={{padding: 10, margin: 0}}>
                <div style={{fontWeight: "bolder"}}>Note : {rating.note}/5</div>
                <div style={{whiteSpace: "pre-wrap"}}>{rating.comment}</div>
            </div>
        </div>
    }

    const renderPatientsNotation = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <>{renderNotations()}</>
    }

    return <div style={{border: "1px solid lightgrey", borderRadius: "10px", height: "100%", padding: 20, display: "flex", flexDirection: "column", gap: 10}}>
        <h2 className="center" style={{margin: 0}}>
            Avis Patients
        </h2>
        {renderPatientsNotation()}
    </div>
}

export default PatientsNotation;