import React from "react";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";

function BillingOverview({last4, bankCode, bankTitle, lastPaymentDate, lastDue, nextDue, fixed, variable}) {
    const renderData = () => {
        if (!last4) return <>
            <h5 style={{fontStyle: "italic", margin: 0}}>
                Aucun moyen de paiement enregistré
            </h5>
            <label style={{fontStyle: "italic", fontSize: "0.7em"}}>Tous les actes sont considérés comme examens de test et ne sont pas présentés à un ophtalmologiste lecteur ou disponibles pour le patient</label>
        </>
        return <div className="row">
            <h6 className="valign-wrapper col s12 m6">
            <i className="material-icons left blue-text text-darken-2">card_membership</i>
                {"Paiment SEPA"}
            </h6>
            <h6 className="valign-wrapper col s12 m6">
                <i className="material-icons left blue-text text-darken-2">credit_card</i>
                {"Numéro de carte : **** **** **** " + last4}
            </h6>
            <h6 className="valign-wrapper col s12 m6">
                <i className="material-icons left blue-text text-darken-2">account_balance</i>
                {"Banque : " + bankTitle}
            </h6>
            <h6 className="valign-wrapper col s12 m6">
                <i className="material-icons left blue-text text-darken-2">local_atm</i>
                {"Code banque : " + bankCode}
            </h6>

        </div>
    }
    const renderButtons = () => {
        if (!last4) return <a className="btn blue darken-2 z-depth-0" target="_blank"
                              href="/orthoptistes/paiements/entrer_carte">
            <i className="material-icons left">credit_card</i>
            Enregistrer un moyen de paiement
        </a>

        return <div className="valign-wrapper" style={{gap: 10}}>
            <a className="btn blue darken-2 z-depth-0" href="/orthoptistes/paiements/entrer_carte">
                <i className="material-icons left">currency_exchange</i>
                Changer
            </a>
            {/*<a className="btn blue darken-2 z-depth-0" href="/orthoptistes/paiements/historique">*/}
            {/*    <i className="material-icons left">history</i>*/}
            {/*    Historique*/}
            {/*</a>*/}
        </div>
    }
    return <div className="fc">
        <div className="fr" style={{justifyContent: "space-between"}}>
            <h4 style={{fontWeight: 600, margin: 5}}>Mon moyen de paiement</h4>
            {renderButtons()}
        </div>
        <div className="container center">
            {renderData()}
        </div>
    </div>
}

export default BillingOverview;