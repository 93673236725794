import socket from "../../../../../../Shared/socket";
import {displayMedocError} from "../../../../../../Shared/Enums/ErrorCodes";
import moment from "moment";
import axios from "axios";
import MesureStore from "../../../../MesureStore";
import CVStore from "../../../../../../CarteVitale/CVStore";
import BridgeStoreV2 from "../../../../../../BridgeV2/BridgeStoreV2";

const useFseCreationRequest = (appointment_id) => {

    const sendFseCreationRequest = (scorData, options) => {
        const uid = BridgeStoreV2.uid
        return new Promise((resolve, reject) => {
            console.log("FSE CREATION REQUEST")
            if (!uid) return reject("No UID provided")
            fseCreationRequest(uid, scorData, options)

            const timeout = setTimeout(() => {
                reject("Timeout")
            }, 60 * 60 * 1000)

            const responseHandler = (resp) => {
                if (resp.status === "success") {
                    console.debug("Fse response received with data: ", resp.data, resp.meta)
                    if (resp.data.bFactureCreee) {
                        // toast.success("Feuille de soin électronique envoyée avec succès")
                        axios.patch('/orthoptistes/chained_acts/paid', {appointment_id: appointment_id}).then()
                        const medoc_id = resp.data.sNumeroFSE
                        axios.patch("/api/appointements/medoc_id", {
                            medoc_id: medoc_id,
                            appointment_id: appointment_id
                        }).then()
                        MesureStore.appointment_teletransmission_status = "PENDING"
                        console.debug("FSE created with id: ", medoc_id)
                        resolve(resp)
                    } else {
                        console.debug("Fse creation failed: ", resp.data)
                        reject(resp)
                    }
                } else {
                    console.debug("Error occured while creating FSE with error: ", displayMedocError(resp.errorCode));
                    reject(resp)
                    clearTimeout(timeout)
                }
                socket.off("fseCreationResponse", responseHandler)
            }
            socket.on("fseCreationResponse", responseHandler);
        })
    }

    const fseCreationRequest = (uid, scorData = undefined, options = {}) => {
        const patient = CVStore.patient
        const fse = CVStore.fse
        const selectedPrescriptor = CVStore.selectedPrescriptor ?? {}
        const delayedAppointments = CVStore.delayedAppointments ?? []
        const appointmentDates = [moment(), ...delayedAppointments.map(appt => moment(appt.created_at))]

        let data
        if (options.scorOnly) {
            data = {
                scor: {},
                scor_pdf_data: {
                    filename: `${MesureStore.appointement_id}_${moment().format("YYYY-MM-DD-HH-mm")}_${patient.prenom}_${patient.nom}`,
                    data: scorData
                }
            }
            console.debug("Sending SCOR with data : ", data)
        } else {
            data = {
                invoiceId: fse.invoiceId,
                time: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"), // "2022-05-03T15:31:27.452",
                comment: "Feuille de soin générée par Temeoo",
                rpps: fse.adeli, // MedOc takes Adeli on Rpps slot in case of orthoptist (which has no rpps)
                adeli: fse.adeli,
                patient_firstname: patient.prenom,
                patient_lastname: patient.nom,
                patient_ssn: patient.num_carte_vitale,
                patient_birthday: Helper.formatDate(patient.birthday, "YYYYMMDD"),
                patient_rank: patient?.birth_rank || 1,
                patient_regime_code: patient.regime_code,
                patient_fund_code: patient.fund_code,
                patient_centre_code: patient.centre_code,
                acts: fse.acts.map((act, index) => {
                    return appointmentDates.reduce((acc, date) => {
                            acc = acc.concat({
                                type: "NGAP",
                                action: "creation",
                                isValid: true,
                                isBillable: act.prix !== 0,
                                isFree: act.prix === 0,
                                emiterId: "7", // TODO but not mandatory so what's the point ?
                                actCode: act.actCode || "",
                                activityCode: act.activityCode || "",
                                phaseCode: act.phaseCode || "",
                                associationCode: (index + 1).toString(),
                                price: act.prix * 100,
                                time: date.format("YYYY-MM-DDTHH:mm:ss.SSS"), // "2022-05-03T15:31:27.452",
                                coefficient: act.coefficient,
                                quantity: 1
                            })
                            return acc
                        }
                        , [])
                }).flat(),
                // prescriptor
                scor: {
                    prescriptor_adeli: selectedPrescriptor.adeli,
                    prescriptor_rpps: selectedPrescriptor.rpps,
                    prescriptor_specialty_code: selectedPrescriptor.specialty_code,
                    prescription_date: CVStore.prescriptionDate ? moment(CVStore.prescriptionDate).format('YYYY-MM-DD') : undefined, //"2022-05-03",
                    prescriptor_firstname: selectedPrescriptor.firstname?.toUpperCase(),
                    prescriptor_lastname: selectedPrescriptor.lastname?.toUpperCase(),
                    type: selectedPrescriptor.practitioner_type, // 0 = Libéral, 1 =  Dentiste, 2 = Salarié, 3 = Bénévole
                    prescriptor_structure_number: "", // empty string according to Med'OC
                },
                scor_pdf_data: {
                    filename: `${MesureStore.appointement_id}_${moment().format("YYYY-MM-DD-HH-mm")}_${patient.prenom}_${patient.nom}`,
                    data: scorData
                }
            }
            console.debug("Requesting FSE creation with data : ", data)
        }

        // log if data > 1Mo
        if(scorData && scorData.size > 1000000) {
            throw new Error("Le SCOR doit faire moins d'1Mo pour être envoyé. Vous pouvez toujours procéder manuellement.")
        }

        socket.emit("fseCreationRequest",
            {
                machine: uid,
                data: data
            }
        );
    }

    return {sendFseCreationRequest}
}

export default useFseCreationRequest;