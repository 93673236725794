import React from "react";
import CreateGlobalNotification from "./CreateGlobalNotification";
import GlobalNotificationList from "./GlobalNotificationList";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import axios from "axios";

const GlobalNotificationConsole = () => {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content

    return <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
        <h3 className="valign-wrapper" style={{margin: 20}}>
            <i className="material-icons left">campaign</i>
            Notifications globales
        </h3>
        <div className="divider"/>
        <div className="fc" style={{marginLeft: 2}}>
            <BorderedDisplay containerStyle={{margin: "10px 40px"}}>
                <CreateGlobalNotification/>
            </BorderedDisplay>
            <GlobalNotificationList/>
        </div>
    </div>

}

export default GlobalNotificationConsole