import React, {useEffect, useState} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../../Shared/Loader";
import Helper from "../../../../Shared/Helper";
import ScopedElement from "../../../../Shared/ScopedElement";
import {Role} from "../../../../Shared/Enums/Scope";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import StatusButton from "../StatusButton";
import {Link} from "react-router-dom";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";

const _ = require('lodash');

function Orthoptists(props) {
    const {offices} = props
    const modal_id = `console_orthoptists_modal`
    const [orthoptists, setOrthoptists] = useState(props.orthoptists)
    const [onEditOrthoptist, setOnEditOrthoptist] = useState({})
    const [filter, setFilter] = useState("")

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [RightsStore.rights])


    const new_orthoptist = () => {
        setOnEditOrthoptist({onEdit: false})
        open_modal()
    }

    const edit_orthoptist = (orthoptist) => {
        setOnEditOrthoptist({...orthoptist, onEdit: true})
        open_modal()
    }

    const open_modal = () => {
        setTimeout(() => {
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.open();
        }, 100)
    }

    const create_orthoptist = () => {
        axios.put('/admin/console/users/orthoptist', {
            orthoptist: onEditOrthoptist
        }).then((resp) => {
            setOrthoptists([...orthoptists, resp.data])
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.close();
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'orthoptiste")
        })
    }

    const update_orthoptist = (orthoptist) => {
        axios.patch(`/admin/console/users/orthoptist/${orthoptist.id}`, {
            orthoptist: orthoptist
        }).then((resp) => {
            setOrthoptists(orthoptists.map(ortho => ortho.id === orthoptist.id ? resp.data : ortho))
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.close();
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'orthoptiste")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content container">
                <h3 className="center"
                    style={{marginTop: 20}}>{onEditOrthoptist.onEdit ? "Éditer" : "Nouvel"} orthoptiste</h3>
                <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Prénom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOrthoptist.prenom || ""}
                                   onChange={(e) => setOnEditOrthoptist({
                                       ...onEditOrthoptist,
                                       prenom: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Nom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOrthoptist.nom || ""}
                                   onChange={(e) => setOnEditOrthoptist({...onEditOrthoptist, nom: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Email</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOrthoptist.email || ""}
                                   onChange={(e) => setOnEditOrthoptist({...onEditOrthoptist, email: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Mot de passe</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOrthoptist.password || ""}
                                   onChange={(e) => setOnEditOrthoptist({
                                       ...onEditOrthoptist,
                                       password: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Cabinet</span>
                        <select className="browser-default outlined" style={{width: "100%"}}
                                value={onEditOrthoptist.cabinet_id || ""}
                                onChange={(e) => setOnEditOrthoptist({
                                    ...onEditOrthoptist,
                                    cabinet_id: e.target.value
                                })}>
                            <option value="" disabled>Choisir un cabinet</option>
                            {offices.sort((a, b) => a.titre > b.titre ? 1 : -1).map((office) => {
                                return <option key={office.id}
                                               value={office.id}>{office.titre || "Cabinet sans nom"}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                {onEditOrthoptist.onEdit ?
                    <a className="btn btn-small blue darken-2 z-depth-0"
                       onClick={() => update_orthoptist(onEditOrthoptist)}>Valider</a>
                    :
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={create_orthoptist}>Créer</a>}
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderOrthoptist = (orthoptist) => {
        if (!orthoptist) return (<div/>)
        return (
            <div className="collection-item transparent" key={orthoptist.id} style={{display: "flex", width: "100%"}}>
                <div style={{
                    width: "100%",
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 10
                }}>
                    <StatusButton status={orthoptist.status} id={orthoptist.id}
                                  onChange={(value) => {
                                      if (orthoptist.status !== value) {
                                          update_orthoptist({...orthoptist, status: value})
                                      }
                                  }}
                    />
                    <div style={{width: 40, margin: 0}}>
                        <span>#{orthoptist.id}</span>
                    </div>
                    <div style={{width: "180px", margin: 0}}>
                        <span>{Helper.formatName(orthoptist.prenom, orthoptist.nom)}</span>
                    </div>
                    <div style={{width: "180px", margin: 0}}>
                        <span>{Helper.formatName(orthoptist.ophtalmologist_first_name, orthoptist.ophtalmologist_last_name)}</span>
                    </div>
                    <div style={{width: "300px", margin: 0}}>
                        <span
                            className="truncate">{orthoptist.email}</span>
                    </div>
                    <div style={{width: "250px", margin: 0}}>
                        <span>{offices.find(office => orthoptist.cabinet_id === office.id)?.titre || "Pas de cabinet aloué"}</span>
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <div style={{flex: "auto"}}>
                            <ResponsiveMoreButton id={`orthoptist_${orthoptist.id}`} options={[
                                <a className="btn btn-small blue darken-2 z-depth-0"
                                   onClick={() => edit_orthoptist(orthoptist)}>
                                    <i className="material-icons left">edit</i>
                                    Modifier
                                </a>,
                                <Link to={`/orthoptists/${orthoptist.id}`}
                                      className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left ">dashboard</i>
                                    Tableau de bord
                                </Link>,
                                <Link to={`/orthoptists/${orthoptist.id}/prices`}
                                      className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">euro_symbol</i>
                                    Tarifs
                                </Link>
                            ]}/>
                        </div>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    if (_.isEmpty(orthoptists)) {
        return <div style={{margin: 20}}>
            <Loader size="tiny" text=""/>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 20, height: "100%"}}>
        <h3 className="valign-wrapper" style={{margin: 20}}>
            <i className="material-icons left">remove_red_eye</i>
            Orthoptistes
        </h3>
        <div className="divider"/>
        <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div className="input-field outlined">
                    <input type="text" style={{height: 40}} placeholder={"Rechercher"} autoFocus
                           value={filter} onChange={e => setFilter(e.target.value)}/>
                </div>
                <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                    <a className="btn btn-small blue darken-2 z-depth-0"
                       onClick={new_orthoptist}>
                        <i className="material-icons left">add</i>
                        Nouveau
                    </a>
                </RestrictedElement>
            </div>
            <div className="collection"
                 style={{borderRadius: 5, height: "90%", overflowY: "scroll"}}>
                {orthoptists.filter(o => {
                    const office_name = offices.find(office => office.id === o.cabinet_id)?.titre || ""
                    const str = `${o.id} ${o.nom} ${o.prenom} ${o.email} ${office_name} ${o.ophtalmologist_first_name} ${o.ophtalmologist_last_name}`.toLocaleLowerCase()
                    return str.includes(filter.toLowerCase())
                })
                    .sort((a, b) => (a.nom || "zzz") > (b.nom || "aaa"))
                    .map((orthoptist) => {
                        return renderOrthoptist(orthoptist)
                    })}
            </div>
            <ScopedElement scope={[Role.SUPER_ADMIN, Role.ADMIN]}>
                {renderModal()}
            </ScopedElement>
        </div>
    </div>
}

export default Orthoptists;