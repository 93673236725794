import React, {Component} from "react";
import TemplateEdit from "../TemplateEdit";

export default class GroupBlockEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                blocks: [],
                options: {}
            }
        }
    }

    componentDidMount() {
        this.setState({data: this.props.data})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({data: this.props.data})
        }
    }

    onChange(template) {
        const new_data = {...this.state.data, blocks: template.blocks}
        this.setState({data: new_data}, () => this.props.onChange(this.state.data))
    }

    setOptions(options) {
        const new_data = {...this.state.data, options: options}
        this.setState({data: new_data}, () => this.props.onChange(this.state.data))
    }

    renderGapSlider() {
        if (this.props.data.options?.direction !== "row") return null
        const options = this.props.data.options
        return <div className="valign-wrapper container" style={{display: "flex", gap: 20, justifyContent: "center"}}>
            <label>Espacement</label>
            <input type="range" min="0" max="50" value={options?.gap || 0}
                   onChange={(e) => this.setOptions({...options, gap: e.target.value})}
                   style={{
                       borderRadius: "15px",
                       backgroundColor: "#c2c0c2",
                       borderColor: "transparent"
                   }}
            />
        </div>

    }

    renderOptions() {
        const options = this.props.data.options
        return <div className="switch center">
            <label>
                Vertical
                <input type="checkbox" checked={this.props.data.options?.direction === "row"}
                       onChange={(e) => this.setOptions({...options, direction: e.target.checked ? "row" : "column"})}
                />
                <span className="lever"></span>
                Horizontal
            </label>
            {this.renderGapSlider()}
        </div>
    }

    render() {
        return (
            <div style={{width: "100%"}}>
                {this.renderOptions()}
                <TemplateEdit
                    template={{blocks: this.state.data.blocks, id: this.props.id}}
                    onChange={(template) => this.onChange(template)}
                    groupId={this.props.id}
                />
            </div>
        );
    }
}