import React, {Component, useEffect, useState} from 'react';
import axios from "axios"
import MesureStore from "../../MesureStore"
import {observer} from "mobx-react"
import MedicalQuestions from "./MedicalQuestions";
import Drugs from './ListDrugs.js';
import {t} from "../../../../I18n/i18n"
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {ReportLockable} from "../../../AppointmentValidation";
import {Act, ActNature} from "../../../../Shared/Enums/Enums";

const _ = require('lodash');
const nurse_question_json = require("./nurse_questions.json")
const telemedical_question_json = require("./telemedical_questions.json")

@observer
export default class QuestionsAlerte extends Component {
    constructor(props) {
        super(props)
        this.loadAppointement = this.loadAppointement.bind(this)
        this.questions67 = this.questions67.bind(this)
        this.updateQuestions67 = this.updateQuestions67.bind(this)
        this.saveQuestions67 = this.saveQuestions67.bind(this)
        this.saveQuestions67 = _.debounce(this.saveQuestions67, 700)
        this.state = {
            diabete: false,
            glaucome_familiaux: false,
            cortisone: false,
            amy6_diabete: "",
            annee_decouverte_diabete: "",
            hb1ac: "",
            ophtalmologiste_nom: "",
            suivi_ophtalmique: "",
            ophtalmologiste_dernier_examen: "",
            prescripteur_email: "",
            hta: ""
        }
        this.loadAppointement()

    }

    loadAppointement() {
        axios.get("/api/appointements/get", {params: {appointment_id: MesureStore.appointement_id}}).then((data) => {
            this.setState(data.data)
            const appt = data.data
            MesureStore.diabetes_questions = {
                amy6_diabete: appt.amy6_diabete,
                annee_decouverte_diabete: appt.annee_decouverte_diabete,
                hb1ac: appt.hb1ac,
                ophtalmologiste_nom: appt.ophtalmologiste_nom,
                suivi_ophtalmique: appt.suivi_ophtalmique,
                ophtalmologiste_dernier_examen: appt.ophtalmologiste_dernier_examen,
                prescripteur_email: appt.prescripteur_email,
                hta: appt.hta
            }
        })
    }

    saveQuestions67() {
        let questions = {
            amy6_diabete: this.state.amy6_diabete,
            annee_decouverte_diabete: this.state.annee_decouverte_diabete,
            hb1ac: this.state.hb1ac,
            ophtalmologiste_nom: this.state.ophtalmologiste_nom,
            suivi_ophtalmique: this.state.suivi_ophtalmique,
            ophtalmologiste_dernier_examen: this.state.ophtalmologiste_dernier_examen,
            prescripteur_email: this.state.prescripteur_email,
            hta: this.state.hta
        }
        MesureStore.diabetes_questions = questions
        console.log(questions)
        axios.post("/api/appointements/updateQuestions67", {
            questions: questions,
            appointment_id: MesureStore.appointement_id
        }).catch(() => {
        })

    }

    updateQuestions67(e) {
        let key = e.target.dataset.el
        this.setState({
            [key]: e.target.value
        })
        this.saveQuestions67();
    }

    questions67() {
        const amy67acts = [
            Act.AMY8_5_AMY_6_7_2,
            Act.AMY8_AMY_6_7_2,
            Act.AMY6_7
        ]
        if (!amy67acts.includes(MesureStore.selected_act?.titre)) return;

        return (
            <div>
                <h2 className="center red-text">{t("MEDICAL_QUESTIONS.QUESTION_FOR")}</h2>
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <div className="row valign-wrapper" style={{gap: 10}}>
                        <div className="col l6 s12">
                            <label>{t("MEDICAL_QUESTIONS.DIABETES")}</label>
                            <div className="input-field">
                                <select className="browser-default" data-el="amy6_diabete"
                                        onChange={this.updateQuestions67}
                                        value={this.state.amy6_diabete}>
                                    <option value="" disabled>Aucune sélection</option>
                                    <option value="aucun">{t("MEDICAL_QUESTIONS.NONE")}</option>
                                    <option value="type 1">{t("MEDICAL_QUESTIONS.TYPE_1")}</option>
                                    <option value="type 2">{t("MEDICAL_QUESTIONS.TYPE_2")}</option>
                                    <option value="grossesse">{t("MEDICAL_QUESTIONS.PREGNANCY")}</option>
                                </select>
                            </div>
                        </div>

                        <div className="col l6 s12">
                        {this.state.amy6_diabete != "aucun" && this.state.amy6_diabete != "" ? (
                                <div className="input-field outlined">
                                    <input type="number" id="annee_dec" data-el="annee_decouverte_diabete"
                                           onChange={this.updateQuestions67}
                                           value={this.state.annee_decouverte_diabete}
                                           data-cy="DiabetusDiscoveryYear"/>
                                    <label htmlFor="annee_dec">{t("MEDICAL_QUESTIONS.YEAR_OF_DISCOVERY")}</label>
                                </div>
                            ) : (
                                <span></span>
                            )}
                        </div>
                        <div className="col l6 s12">
                            <div className="input-field outlined">
                                <input type="text" id="hb1ac" data-el="hb1ac" onChange={this.updateQuestions67}
                                       value={this.state.hb1ac}
                                       data-cy="LastHB1AC"/>
                                <label htmlFor="hb1ac">{t("MEDICAL_QUESTIONS.LAST_HB1AC")}</label>
                            </div>
                        </div>
                        <div className="col l6 s12">
                            <label>{t("MEDICAL_QUESTIONS.HTA")}</label>
                            <div className="input-field outlined">
                                <select className="browser-default" data-el="hta" onChange={this.updateQuestions67}
                                        value={this.state.hta}>
                                    <option value="" disabled>Aucune sélection</option>
                                    <option value="oui">{t("YES")}</option>
                                    <option value="non">{t("NO")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col l6 s12">
                            <label>{t("MEDICAL_QUESTIONS.OPHTHAL_FOLLOW_UP")}</label>
                            <div className="input-field outlined">
                                <select className="browser-default" data-el="suivi_ophtalmique"
                                        onChange={this.updateQuestions67} value={this.state.suivi_ophtalmique}>
                                    <option value="" disabled>Aucune sélection</option>
                                    <option value="oui">{t("YES")}</option>
                                    <option value="non">{t("NO")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col l6 s12">
                            <div className="input-field outlined">
                                <input type="text" id="ophtalmologiste" data-el="ophtalmologiste_nom"
                                       onChange={this.updateQuestions67}
                                       value={this.state.ophtalmologiste_nom}
                                       data-cy="OphtalmologistName"/>
                                <label htmlFor="ophtalmologiste">{t("MEDICAL_QUESTIONS.NAME_OF_OPHTHALMO")}</label>
                            </div>
                        </div>
                        <div className="col l6 s12">
                            <div className="input-field outlined">
                                <input type="text" id="ophtalmologiste_date" data-el="ophtalmologiste_dernier_examen"
                                       onChange={this.updateQuestions67}
                                       value={this.state.ophtalmologiste_dernier_examen}/>
                                <label htmlFor="ophtalmologiste_date">{t("MEDICAL_QUESTIONS.DATE_LAST_CONSULT")}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMedicalQuestions() {
        if (MesureStore.selected_act?.nature === ActNature.TELEMEDICAL.name) {
            return <div className="col s8"><MedicalQuestions json={telemedical_question_json}/></div>
        }
        if (MesureStore.selected_act?.nature === ActNature.NURSE.name) {
            return <div className="col s8"><MedicalQuestions json={nurse_question_json}/></div>
        }
        return null
    }

    render() {
        const cutomQuestionNeeded = [ActNature.TELEMEDICAL.name, ActNature.NURSE.name].includes(MesureStore.selected_act?.nature)
        return <div className="row" style={{padding: 20, gap: 20, overflowY: "scroll"}}>
            {this.renderMedicalQuestions()}
            <div className={`col s${cutomQuestionNeeded ? "4" : "12"}`}>
                <h2 className="center">{t("MEDICAL_QUESTIONS.CURRENT_TREATMENT")}</h2>
                <div style={{marginTop: 50}}>
                    <UsedDrugs/>
                </div>
                {this.questions67()}
            </div>
        </div>
    }
}
const Switch = (props) => {
    return (
        <div className="switch">
            <label>
                Non
                <input checked={props.checked} onChange={props.onChange} name={props.name} type="checkbox"/>
                <span className="lever"></span>
                Oui
            </label>
        </div>
    )
}

const filter = createFilterOptions({
    limit: 10
});

const UsedDrugs = (props) => {
    const [drugs, setDrugs] = useState([]);
    const save = (event, newValue) => {
        setDrugs(newValue)
        axios.patch("/api/appointements/drugs", {
            drugs: newValue,
            appointement_id: MesureStore.appointement_id
        }).then((res) => {
            MesureStore.patient = {...MesureStore.patient, drugs: (newValue || [])}
        })
    }
    const load = () => {
        axios.get("/api/appointements/drugs?appointement_id=" + MesureStore.appointement_id).then((res) => {
            setDrugs(res.data.drugs || []);
        })
    }
    useEffect(() => {
        load();
    }, [])
    return (
        <div>
            <Autocomplete
                data-cy="CurrentUsedMedication"
                disablePortal
                id="combo-box-demo"
                filterOptions={filter}
                multiple
                value={drugs}
                options={Drugs}

                onChange={save}
                sx={{width: "100%"}}
                renderInput={(params) => <TextField {...params} label={t("MEDICAL_QUESTIONS.MEDECINES_USED")}/>}
            />
        </div>
    )

}


