import React, {useEffect} from "react";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import axios from "axios";
import {toast} from "react-toastify";

const ExamsThisMonth = ({}) => {

    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [exams, setExams] = React.useState({});

    useEffect(() => {
        getExamsMonth()
    }, []);

    const getExamsMonth = () => {
        axios.get("/admin/statistiques/api/orthoptist/monthAppointments")
            .then((response) => {
                setExams(response.data)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((err) => {
                toast.error("Une erreur est survenue.")
                setRequestState(RequestState.ERROR)
            })
    }

    const renderExamsMonth = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <>
            <h3 style={{margin: "0px 20px", paddingBlock: 10}}>
                <b style={{fontSize: 25}}> {exams.general} </b>
            </h3>
            <h5 style={{marginInline: 20, paddingBottom: 5}}><b>{exams.telemedicine}</b> en télémédecine</h5>
        </>
    }

    return <div style={{borderRadius: "10px", border: "1px solid lightgrey", height: "100%"}}>
        <h2 style={{marginLeft: 20, marginBlock: 10}}>Examens réalisés ce mois</h2>
        {renderExamsMonth()}
    </div>
}

export default ExamsThisMonth;