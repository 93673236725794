import React from "react";
import {Link} from "react-router-dom";
import ImportMediaButton from "./ImportMediaButton";

const PhonePortal = ({patient_fullname}) => {
    return <div className="valign-wrapper" style={{flexDirection: "column", justifyContent: "center", height: "100svh"}}>
        <h1 className="center">Examen de {patient_fullname}</h1>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", flex: 1, paddingBottom: 100, width: "80vw"}}>
            <Link className="btn blue darken-2 z-depth-0" to="/picture">
                <i className="material-icons left">photo_camera</i>
                Prendre une photo
            </Link>
            <Link className="btn blue darken-2 z-depth-0" to="/video">
                <i className="material-icons left">videocam</i>
                Prendre une vidéo
            </Link>
            <ImportMediaButton/>
        </div>
    </div>
}

export default PhonePortal
