import React, {Component} from 'react';

export default class Strips extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return (
    <div id="connect-background" className={this.props.children}>
    <div className="strip">
    <div className="strip-1"></div>
    <div className="strip-2"></div>
    <div className="strip-right-2"></div>
    </div>
      </div>
    );
  }
}
