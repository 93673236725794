import React, {useEffect} from "react"
import ConsoleDashboardStore from "../../ConsoleDashboardStore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {observer} from "mobx-react";

const OfficesFilter = observer(() => {

    useEffect(() => {
        ConsoleDashboardStore.loadFilterOrthoptists()
    }, [ConsoleDashboardStore.selectedOffices]);

    return <Autocomplete
        sx={{width: "300px"}}
        multiple
        disablePortal
        filterSelectedOptions
        className="ui-autocomplete"
        key="office_filter"
        value={ConsoleDashboardStore.selectedOffices}
        options={ConsoleDashboardStore.officeList}
        getOptionLabel={(option) => option.displayed_value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event) => ConsoleDashboardStore.loadFilterOffices(event.target.value, false)}
        onChange={(_, array) => ConsoleDashboardStore.setSelectedOffices(array)}
        renderInput={(params) => <TextField {...params} label="Sélectionner cabinets"/>}
    />
})

export default OfficesFilter