import {computed, makeObservable, observable} from "mobx"
import axios from "axios"
import {UserPermissions} from "../../Shared/Enums/Enums";

const _ = require('lodash')
class RightsStore {
    @observable rights = []
    @observable rights_loaded = false

    constructor() {
        makeObservable(this)
        this.loadRights()
    }

    loadRights() {
        axios.get('/admin/console/users/rights')
            .then(response => {
                this.rights = response.data
                this.rights_loaded = true
            })
    }

    @computed
    get getRights() {
        return this.rights
    }

    @observable
    can_do(field, permission) {
        if (!this.rights_loaded) return undefined
        field = field.value
        permission = permission.value
        const field_rights = this.rights.map(right => {
            if (right.field === field) return right.permission
        }).filter(r => r !== undefined)
        if (!this.rights) return false
        if (_.isEmpty(field_rights)) return false
        if(field_rights.includes(UserPermissions.READ_WRITE.value)) return true

        return field_rights.includes(permission)
    }

}

let store = new RightsStore
global.RightsStore = store
export default store