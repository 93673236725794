import React, {useEffect} from "react";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import axios from "axios";
import {toast} from "react-toastify";
import BarChart from "../BarChart";
import {t} from "../../../I18n/i18n";

const MonthlyRevenues = ({}) => {

    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [monthlyRevenues, setMonthlyRevenues] = React.useState({});

    useEffect(() => {
        getMonthlyRevenues()
    }, []);

    const getMonthlyRevenues = () => {
        axios.get("/admin/statistiques/api/orthoptist/earnings")
            .then(response => {
                const labels = response.data.map((month_revenues) => {
                    let dateObject = new Date(Date.parse(month_revenues.date))
                    return Helper.capitalizeFirstLetter(dateObject.toLocaleString('fr-FR', {month: 'long'}))
                })
                const revenues = response.data.map((month_revenue) => month_revenue.sum)
                const chartData = {labels: labels, datasets: [{data: revenues}]}
                setMonthlyRevenues(chartData)
                setRequestState(RequestState.SUCCESS)
            }).catch((error) => {
            toast.error(t("ORTHOPTIST_DASHBOARD.TOAST.ERROR.INCOME_DATA"))
            setRequestState(RequestState.ERROR)
            console.log(error)
        })
    }

    const renderMonthlyRevenues = () => {
        if (requestState === RequestState.LOADING) return <div className="center"><Loader size="small" text=""/></div>
        if (requestState === RequestState.ERROR) return <div>Une erreur est survenue.</div>
        if (requestState === RequestState.SUCCESS) return <div style={{marginInline: "10px", height: "25vh"}}>
            <BarChart withLegend={false}
                      data={monthlyRevenues}
                      beginAtZero={true}/>
        </div>

    }

    return <div style={{
        borderRadius: "10px",
        border: "1px solid lightgrey"
    }}>
        <h2 className="center" style={{margin: 10}}>CA sur les 12 derniers mois</h2>
        {renderMonthlyRevenues()}
    </div>
}

export default MonthlyRevenues;

