import React from "react"
import AddOrthoptiste from "../AddOrthoptiste";
import {IS_OPTICIAN} from "../Shared/Helper";

function OrthoptistList(props) {
    const renderOrtho = (orthoptist) => {
        return <tr key={orthoptist.id} className="highlighted">
            <td>{orthoptist.nom}</td>
            <td>{orthoptist.prenom}</td>
            <td>{orthoptist.email}</td>
            <td>{orthoptist.phone_number}</td>
            <td>{orthoptist.rpps}</td>
        </tr>
    }

    return <div className="container" style={{marginTop: 70}}>
        <h3 className="center">Mes {IS_OPTICIAN ? "opticiens" : "orthoptistes"} pour le
            cabinet {props.office.titre}</h3>
        <AddOrthoptiste cabinet_id={props.office.id}/>
        <table className="temeoo-table" style={{marginTop: 30}}>
            <thead>
            <tr>
                <th>Nom</th>
                <th>Prenom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>RPPS</th>
            </tr>
            </thead>
            <tbody>
            {props.orthoptists.map((orthoptist) => renderOrtho(orthoptist))}
            </tbody>
        </table>
    </div>
}

export default OrthoptistList;