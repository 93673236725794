import Cookies from 'universal-cookie';

// Element that authorize or rejects children considering scope
// Scope is defined by scope cookie and defines user roles
// Props :
//  scope : render if at least one user role is in that least
//          if scope is empty, everything's authorized
//  exclude : do not render if at least one user role is in that list
const userScope = () => {
    const cookies = new Cookies();
    const userScope = cookies.get("scope")
    return userScope === undefined ? [] : userScope.split("&")
}

export const rejected = (hidden, exclude) => {
    return hidden ||  userScope().filter((role) => exclude.includes(role)).length > 0
}

export const authorized = (scope) => {
    // authorized scope is empty : everything's authorized
    if (scope.length === 0) return true
    return userScope().filter((role) => scope.includes(role)).length > 0
}
function ScopedElement({scope, exclude, hidden, children}) {

    if(rejected(hidden, exclude)) return null
    else if (authorized(scope)) return children
    return null
}

ScopedElement.defaultProps = {
    scope: [],
    exclude: []
}

export default ScopedElement