import React, {Component} from 'react';
import {HashRouter as Router, Route, Routes} from "react-router-dom"
import Installation from "./Installation"
import Connect from "./Connect"
import Unmount from "./Unmount"
import Refractor from "./Refractor"
import Directory from "./Directory"

export default class Onboarding extends Component {
  render() {
    return (
      <div>
        <h1 className="center"> Connexion nouvelle machine</h1>
        <Router>
            <Routes>
              <Route path='/' exact element={<Installation />} />
              <Route path='/Unmount' exact element={<Unmount />} />
              <Route path='/connect' element={<Connect />} />
              <Route path='/Connect_usb' element={<Refractor />} />
              <Route path='/Directory' element={<Directory />} />
            </Routes>

        </Router>
      </div>
    );
  }
}
