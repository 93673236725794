import React, {useState} from 'react';
import Error from "../Shared/Error";
import DocumentTile from "./DocumentTile";
import ViewerMetadata from "./ViewerMetadata";
import {PDFDocument} from "pdf-lib";
import {t} from "../I18n/i18n"


function DocumentViewer({documents, metadata, is_patient}) {
    const [selectedPdfBlob, setSelectedPdfBlob] = useState(null);

    const renderPDF = () => {
        if (!selectedPdfBlob) {
            return <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    gap: 10,
                    paddingTop: 0
                }}>
                <i className="material-icons large">pageview</i>
                <h5>{t("VIEWER_METADATA.SELECT_DOCUMENT_PREVIEW")}</h5>
            </div>
        }

        return <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                gap: 10,
                paddingTop: 0
            }}>
            <iframe id="pdf-frame" src={window.URL.createObjectURL(selectedPdfBlob)}
                    title="pdf-viewer"
                    style={{height: "100%", width: "100%"}}/>
        </div>
    }

    if (metadata?.errorCode) {
        const default_message = t("VIEWER_METADATA.NO_ACCESS_DOCUMENT")
        return <div id="full-height" style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 50
        }}>
            <Error errorText={metadata.errorPrompt || default_message}/>
            <div>
                <a className="btn blue darken-2 z-depth-0" onClick={() => window.history.back()}>
                    <i className="material-icons left">arrow_back</i>
                    {t("BACK")}
                </a>
            </div>
        </div>
    }
    //
    // const renderPatientAds = () => {
    //     if (!is_patient) return null
    //     if (metadata.user_type !== User.PATIENT.value) return null;
    //     return <AdsPanel/>
    // }

    const selectDocument = async (document1Blob, metadata) => {
        if (metadata.annexes === null || metadata.annexes === undefined || metadata.annexes.length < 1) {
            setSelectedPdfBlob(document1Blob)
        } else {
            try {
                const url = metadata.annexes[0]; // for now, we only support one annex

                // Fetch the PDF from the given URL
                const response = await fetch(url);
                const pdfBytes = await response.arrayBuffer();
                const pdfDoc = await PDFDocument.load(await document1Blob.arrayBuffer());
                const externalPdfDoc = await PDFDocument.load(pdfBytes);
                const copiedPages = await pdfDoc.copyPages(externalPdfDoc, externalPdfDoc.getPageIndices());
                copiedPages.forEach((page) => pdfDoc.addPage(page));

                const mergedPdfBytes = await pdfDoc.save();
                setSelectedPdfBlob(new Blob([mergedPdfBytes], {type: "application/pdf"}));

            } catch (error) {
                console.error("Error merging PDFs:", error);
                setSelectedPdfBlob(document1Blob)
            }
        }
    }


    return <div id="full-height" className="row">
        <div className="col xl3 m12 s12" style={{
            boxShadow: "2px 1px 0 0 #E4E4E4",
            overflowY: "scroll"
        }}>
            <ul className="collection" style={{borderColor: "transparent", margin: 0}}>
                {<ViewerMetadata metadata={metadata}/>}
                {documents.map((document, index) => {
                    return <li id="hoverable" className="collection-item" key={index}
                               style={{width: "100%"}}>
                        <DocumentTile doc={document} metadata={metadata}
                                      mobile={window.innerWidth < 992.99}
                                      onPdfSelect={(blob) => selectDocument(blob, document)}/>
                    </li>
                })}
                <li/>
                {/* Empty li to add divider at the bottom */}
            </ul>
            {/*<div style={{padding: 20}}>{renderPatientAds()}</div>*/}
        </div>
        <div className="col xl9 m12 s12 hide-on-med-and-down center">
            {renderPDF()}
        </div>
    </div>
}

export default DocumentViewer;