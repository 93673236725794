import React from 'react';
import {Mesures, Side, TypesMesures} from "../../../Shared/Enums/Enums";
import {formatAcuities, formatRetinos} from "../../../Appointments/Mesures/mesure_components/mesures";
import {t} from "../../../I18n/i18n"
import moment from "moment";

function DisplayMeasures({measures, title}) {

    const handleTensionInOtherMeasure = (measures) => {
        // If tension measure already exists, we don't need to add it again
        if (measures.find(m => m.name === Mesures.TENSION.name)) return measures

        // If tension exists in other measures, we need to add it to the measures
        const {od_tension, og_tension} = measures.find(m => m.od_tension || m.og_tension) || {}
        if (od_tension || og_tension) return [{name: Mesures.TENSION.name, od_tension, og_tension}, ...measures]
        return measures
    }

    const sortMeasures = (measures) => {
        // We follow the order of the enum TypesMesures
        return Object.values(TypesMesures).map((type) => measures.find(m => m.name === type)).filter(m => m)
    }

    const retinos = (mesure, side) => {
        return formatRetinos(mesure, side)
    }

    const acuities = (mesure, side) => {
        return formatAcuities(mesure, side)
    }

    const renderMeasure = (measure, side) => {
        const right = side === Side.RIGHT;
        if (measure.name === TypesMesures.TENSION) return <div>{right ? measure.od_tension : measure.og_tension}</div>
        if (measure.name === TypesMesures.PACHYMETRY) return <div>{right ? measure.od_pachymetrie : measure.og_pachymetrie} µm</div>
        if (measure.name === TypesMesures.KERATOMETRY) return <div>{right ? "R1 " + measure.od_radius_1 + " R2 " + measure.od_radius_2 + " D " + measure.od_degree + "°" : "R1 " + measure.og_radius_1 + " R2 " + measure.og_radius_2 + " D " + measure.og_degree + "°"}</div>
        return <>
            <div>{retinos(measure, side)}</div>
            <div>{acuities(measure, side)}</div>
        </>
    }

    const displayTitle = () => {
        if (!title) return null
        return <div className="valign-wrapper" style={{gap: 20}}>
            <i className="material-icons red darken-2 white-text"
               style={{padding: 10, borderRadius: 25, fontSize: 16}}>list_alt</i>
            <h4 style={{margin: 0}}>{t("MEASURES")}</h4>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        {displayTitle()}
        <div className="tabs-mesures-synthese-v2">
            <table className="striped bordered">
                <tbody>
                <tr>
                    <th>{t("TITLE")}</th>
                    <th>{t("RIGHT")}</th>
                    <th>{t("LEFT")}</th>
                </tr>
                </tbody>
                <tbody>
                {sortMeasures(handleTensionInOtherMeasure(measures)).map((measure, index) => {
                    let hour = ""
                    if (measure.updated_at && measure.name === Mesures.TENSION.name) {
                        const date = moment(measure.updated_at)
                        hour = `(à ${date.format("HH:mm")})`
                    }
                    return <tr key={index}>
                        <th>{measure.name} {hour}</th>
                        <td style={{padding: "1vh 0.2vw"}}>
                            {renderMeasure(measure, Side.RIGHT)}
                        </td>
                        <td style={{padding: "1vh 0.2vw"}}>
                            {renderMeasure(measure, Side.LEFT)}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    </div>
}

DisplayMeasures.defaultProps = {
    title: true
}

export default DisplayMeasures;