import Validator from "../../Shared/Validator";
import React from "react";
import axios from "axios";
import {wrapper} from "../../I18n/i18n";

const AskForReworkButtonOrtho = ({appointment_id, reworkable, onValidation, big}) => {
    const {t} = wrapper("EXAM_HISTORY.REWORK")

    if(!reworkable) return null

    const rework_appointment = () => {
        axios.patch(`/patients/appointment/rework/${appointment_id}`).then(() => {
            onValidation(appointment_id)
        })
    }

    return <a className={`btn-small z-depth-0`}><i className="material-icons left">undo</i>{t("REWORK")}</a>
};

AskForReworkButtonOrtho.defaultProps = {
    reworkable: true,
    onValidation: () => {
    }
}

export default AskForReworkButtonOrtho;