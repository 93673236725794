import React from "react"
import axios from "axios"
import ControleStore from "./ControleStore"
import {PrescriptionType} from "../../Shared/Enums/Enums";
import SmartTextArea from "../../Shared/UiKit/SmartTextArea";
import {observer} from "mobx-react";
import {toast} from "react-toastify";
import {measure_to_prescription} from "../../Shared/Measures/MeasuresHelper";

// fonction d aides
const to_s = (integer) => {
    let r = ""
    if (integer != "") {
        if (integer < 0) {
            r = "" + parseFloat(integer).toFixed(2) + ""
        } else {
            r = "+" + parseFloat(integer).toFixed(2) + ""
        }
    }
    return (r)
}

function f(integer) {
    return (integer)
}

const checkEmpty = (txt, val1, val2) => {
    if (val1 === "" || val1 === null || val2 === "" || val2 === null) {
        return ("")
    } else {
        return (txt)
    }
}
const sphereOrPlan = (data) => {
    let cleaned_data = ""
    try {
        cleaned_data = to_s(data).replace(/[-+]*/g, "")
    } catch {
        cleaned_data = ""
    }
    if (cleaned_data == "" || data == null) {
        return (" plan ")
    } else {
        return (to_s(data))
    }
}


const prescriptions_possibles = ["Verres de loin", "Verres de près", "Verres progressifs"]
@observer
export default class EditPrescription extends React.Component {
    constructor(props) {
        super(props)
        this.savePrescription = this.savePrescription.bind(this)
        this.savePrescription = _.debounce(this.savePrescription, 500)
        this.updatePrescription = this.updatePrescription.bind(this)
        this.prescribe = this.prescribe.bind(this)
        this.changeTab = this.changeTab.bind(this)
        this.state = {
            prescription: [props.prescription],
            refraction: {},
            selectedPrescription: 0
        }
        this.props.mesures.map((mesure, index) => {
            if (this.props.typedemesures[index].titre == "Réfraction subjective") {
                this.state.refraction = mesure
            }
        })

    }

    resize() {
        // let area = document.querySelector("#prescription")
        // M.Forms.textareaAutoResize(area);
        let elems = document.querySelectorAll('textarea');
        elems.forEach((elem) => {
            M.Forms.textareaAutoResize(elem);
        });
    }

    prescribe(e) {
        e.preventDefault();

        let type = e.target.attributes.type.value
        let prescriptionText = this.state.prescription[this.state.selectedPrescription]
        if (prescriptionText == null) prescriptionText = ""
        if (prescriptionText.trim().length > 0) prescriptionText += "\n\n"
        let mesure = this.state.refraction
        let MesureStore = mesure;
        if (type === PrescriptionType.LONG_SIGHTED_GLASSES) {
            prescriptionText += ("Une monture pour vision de loin\n")
            prescriptionText += ("     OD : " + sphereOrPlan(f(MesureStore.od_sphere)) + checkEmpty(" ( " + to_s(f(MesureStore.od_cylindre)) + " à " + MesureStore.od_axe + "°)", MesureStore.od_cylindre, MesureStore.od_axe) + "\n")
            prescriptionText += ("     OG : " + sphereOrPlan(f(MesureStore.og_sphere)) + checkEmpty(" ( " + to_s(f(MesureStore.og_cylindre)) + " à " + MesureStore.og_axe + "°)", MesureStore.og_cylindre, MesureStore.og_axe) + "\n\n")
        } else if (type === PrescriptionType.SHORT_SIGHTED_GLASSES) {
            prescriptionText += ("Monture + verres\n")
            prescriptionText += ("     OD : " + sphereOrPlan(f(MesureStore.od_sphere)) + checkEmpty(" ( " + to_s(f(MesureStore.od_cylindre)) + " à " + MesureStore.od_axe + "°)", MesureStore.od_cylindre, MesureStore.od_axe) + "\n")
            prescriptionText += ("     OG : " + sphereOrPlan(f(MesureStore.og_sphere)) + checkEmpty(" ( " + to_s(f(MesureStore.og_cylindre)) + " à " + MesureStore.og_axe + "°)", MesureStore.og_cylindre, MesureStore.og_axe) + "\n\n")

        } else if (type === PrescriptionType.PROGRESSIVE_GLASSES) {
            prescriptionText += ("Verres progressifs et monture\n")
            prescriptionText += ("     OD : " + sphereOrPlan(f(MesureStore.od_sphere)) + checkEmpty(" ( " + to_s(f(MesureStore.od_cylindre)) + " à " + MesureStore.od_axe + "°) ", MesureStore.od_cylindre, MesureStore.od_axe) + checkEmpty("Add " + to_s(MesureStore.od_addition), to_s(MesureStore.od_addition), 1) + "\n")
            prescriptionText += ("     OG : " + sphereOrPlan(f(MesureStore.og_sphere)) + checkEmpty(" ( " + to_s(f(MesureStore.og_cylindre)) + " à " + MesureStore.og_axe + "°) ", MesureStore.og_cylindre, MesureStore.og_axe) + checkEmpty("Add " + to_s(MesureStore.og_addition), to_s(MesureStore.og_addition), 1) + "\n\n")
        }
        const prescr = this.state.prescription
        prescr[this.state.selectedPrescription] = prescriptionText
        this.setState({
            prescription: prescr
        }, () => {
            this.resize();
            this.savePrescription();
        })
    }

    editPrescription = (type) => {
        const prescription = this.state.prescription[this.state.selectedPrescription]
        let ref_subj = this.state.refraction
        let toAdd = measure_to_prescription(ref_subj, type)

        let newPrescription = prescription?.trim() ?? ""

        if (newPrescription.length > 0) newPrescription += "\n\n"
        newPrescription += toAdd

        const warning = "\n\nLa prescription ne peut pas être modifiée sauf avis médical."
        newPrescription = newPrescription.replaceAll(warning, "")
        newPrescription = newPrescription.trim()
        newPrescription += warning

        const prescr = this.state.prescription
        prescr[this.state.selectedPrescription] = newPrescription

        this.setState({
            prescription: prescr
        }, () => {
            this.resize();
            this.savePrescription();
        })
    }

    changeTab(tabIndex) {
        this.setState({
            selectedPrescription: tabIndex
        })
        const contents = document.getElementsByClassName("content");
        for (let i = 0; i < contents.length; i++) {
            contents[i].style.display = "none";
        }

        const selectedContent = document.getElementsByClassName("content")[tabIndex];
        if (selectedContent) {
            selectedContent.style.display = "flex";
        }
        this.resize();
    }

    updatePrescription(e) {
        const updatedPrescription = {...this.state.prescription};
        updatedPrescription[this.state.selectedPrescription] = e.target.value;
        this.setState({
            prescription: updatedPrescription
        }, this.resize)
        this.savePrescription();
    }


    savePrescription() {
        axios.post("/api/admin/controle/updatePrescription", {
            id: this.props.app_id,
            prescription: this.state.prescription[this.state.selectedPrescription],
            level: this.state.selectedPrescription
        }).then((data) => {
        })
    }

    componentDidMount() {
        this.resize()
        this.setState({prescription: this.state.prescription.concat(this.props.prescription_secondaire.map((presc) => presc.text))})
        this.changeTab(0)
    }

    render() {
        const alert = this.props.priority === 1;
        const mystyle = {
            borderRadius: "0px 0px 30px 30px",
            borderTop: "thick solid #32a1ce",
        };
        const tab = {
            cursor: "pointer",
            display: "inline-block",
            padding: "10px",
            backgroundColor: "rgb(50, 161, 206)",
            marginRight: "10px",
            fontSize: "1.2em",
            color: "white",
            marginTop: '10px',
            borderRadius: "70%",
            width: "42px",
            textAlign: "center"
        };
        if (alert === true) {
            mystyle.borderTop = "thick solid crimson";
        }
        return (
            <div>
                <h3 className={"valign-wrapper"} style={{height: "10px"}}>
                    <div style={{display: "flex", justifyContent: "space-between", width: "100%", gap: 20}}>
                        <div className="valign-wrapper" style={{gap: 20}}>
                            <i className="material-icons red white-text center-align"
                               style={{padding: 10, borderRadius: "50%"}}>edit</i>
                            <h5 style={{margin: 0}}>Prescription</h5>
                        </div>
                        <div style={{display: "flex"}}>
                            <div title="Prescription primaire" style={this.state.selectedPrescription === 0 ? {
                                ...tab,
                                backgroundColor: '#c2e4fc',
                                marginRight: '10px'
                            } : {...tab, marginRight: '10px'}}
                                 onClick={() => this.changeTab(0)}>1
                            </div>
                            {this.state.prescription[1] !== undefined ? (
                                    <div title="Prescription secondaire" style={this.state.selectedPrescription === 1 ? {
                                        ...tab,
                                        backgroundColor: '#c2e4fc',
                                        marginRight: '10px'
                                    } : {...tab, marginRight: '10px'}}
                                         onClick={() => this.changeTab(1)}>2
                                    </div>)
                                :
                                (<div title="Pas de prescription secondaire" style={{
                                    cursor: "not-allowed",
                                    display: "inline-block",
                                    padding: "10px", backgroundColor: "gray",
                                    marginRight: "10px",
                                    fontsize: "1.2em",
                                    color: "white",
                                    marginTop: '10px',
                                    borderRadius: "70%",
                                    width: "42px",
                                    textAlign: "center"
                                }}>2</div>)}
                            {this.state.prescription[2] !== undefined ? (<div title="Prescription tertiaire"
                                                                              style={this.state.selectedPrescription === 2 ? {
                                                                                  ...tab,
                                                                                  backgroundColor: '#c2e4fc'
                                                                              } : tab}
                                                                              onClick={() => this.changeTab(2)}>3</div>)
                                :
                                (<div title="Pas de prescription tertiaire" style={{
                                    cursor: "not-allowed",
                                    display: "inline-block",
                                    padding: "10px", backgroundColor: "gray",
                                    marginRight: "10px",
                                    fontsize: "1.2em",
                                    color: "white",
                                    marginTop: '10px',
                                    borderRadius: "70%",
                                    width: "42px",
                                    textAlign: "center"
                                }}>3</div>)}

                        </div>
                    </div>
                </h3>
                <div className={alert ? "card-panel red lighten-4" : "card-panel blue lighten-5"}
                     style={mystyle}>
                    {ControleStore.edit_prescription === true ? (
                        <ul className={`tabs tabs-fixed-width ${alert ? "red lighten-3" : "lighten-4 blue"}`}
                            style={{borderRadius: 30}}>
                            <li className={`tab`}>
                                <a className={`grey-text text-darken-2`}
                                   style={{color: alert ? "#9b2727" : "#067cac"}}
                                   type={PrescriptionType.LONG_SIGHTED_GLASSES}
                                   onClick={(() => this.editPrescription("LONGSIGHT_GLASSES"))}>
                                    Lun. Loin
                                </a>
                            </li>
                            <li className={`tab`}>
                                <a className={`grey-text text-darken-2`}
                                   style={{color: alert ? "#9b2727" : "#067cac"}}
                                   onClick={(() => this.editPrescription("SHORTSIGHT_GLASSES"))}
                                   type={PrescriptionType.SHORT_SIGHTED_GLASSES}>
                                    Lun. Près
                                </a>
                            </li>
                            <li className={`tab`}>
                                <a className={`grey-text text-darken-2`}
                                   onClick={(() => this.editPrescription("PROGRESSIVE_GLASSES"))}
                                   style={{color: alert ? "#9b2727" : "#067cac"}}
                                   type={PrescriptionType.PROGRESSIVE_GLASSES}>
                                    Lun. Prog
                                </a>
                            </li>
                            {/*<li className="tab">*/}
                            {/*    <a onClick={(() => editPrescription("LONGSIGHT_GLASSES"))}>*/}
                            {/*        type={PrescriptionType.LONG_SIGHTED_GLASSES}*/}
                            {/*        style={{color: alert ? "#9b2727" : "#067cac"}}>Lun.*/}
                            {/*        Loin</a></li>*/}
                            {/*<li className="tab"><a onClick={this.prescribe} href="#"*/}
                            {/*                       type={PrescriptionType.SHORT_SIGHTED_GLASSES}*/}
                            {/*                       style={{color: alert ? "#9b2727" : "#067cac"}}>Lun.*/}
                            {/*    Près</a></li>*/}
                            {/*<li className="tab"><a onClick={this.prescribe} href="#"*/}
                            {/*                       type={PrescriptionType.PROGRESSIVE_GLASSES}*/}
                            {/*                       style={{color: alert ? "#9b2727" : "#067cac"}}>Lun.*/}
                            {/*    Prog</a></li>*/}
                        </ul>
                    ) : (<React.Fragment></React.Fragment>)}
                    <div className="tab-content s12"
                         style={{marginTop: "10px", minHeight: '350px', display: "flex", flexDirection: "column"}}>
                        <div className="content"
                             style={{display: 'flex', padding: '10px', flex: 1, flexDirection: "column"}}>
                            <SmartTextArea
                                shortcuts={ControleStore.shortcuts}
                                className="row card-panel transparent z-depth-0"
                                value={this.state.prescription[0]} onChange={this.updatePrescription}
                                containerStyle={{height: "100%", display: "flex", flexDirection: "column", flex: 1}}
                                style={{
                                    fontSize: "0.8em",
                                    width: '100%',
                                    resize: "none",
                                    outline: 'none',
                                    border: "none",
                                    flex: 1,
                                    display: "flex",
                                    paddingBlock: 10
                                }}
                            />
                        </div>
                        <div className="content" style={{display: 'none', padding: '10px', minHeight: '220px'}}>
                            <SmartTextArea
                                shortcuts={ControleStore.shortcuts}
                                className="row card-panel transparent z-depth-0"
                                value={this.state.prescription[1]} onChange={this.updatePrescription}
                                containerStyle={{height: "100%", display: "flex", flexDirection: "column", flex: 1}}
                                style={{
                                    fontSize: "0.8em",
                                    width: '100%',
                                    resize: "none",
                                    outline: 'none',
                                    border: "none",
                                    flex: 1,
                                    display: "flex",
                                    paddingBlock: 10
                                }}
                            />
                        </div>
                        <div className="content" style={{display: 'none', padding: '10px', minHeight: '220px'}}>
                            <SmartTextArea
                                shortcuts={ControleStore.shortcuts}
                                className="row card-panel transparent z-depth-0"
                                value={this.state.prescription[2]} onChange={this.updatePrescription}
                                containerStyle={{height: "100%", display: "flex", flexDirection: "column", flex: 1}}
                                style={{
                                    fontSize: "0.8em",
                                    width: '100%',
                                    resize: "none",
                                    outline: 'none',
                                    border: "none",
                                    flex: 1,
                                    display: "flex",
                                    paddingBlock: 10
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
