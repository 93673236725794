import React from "react"
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";


export default function OfficeOphRef({externs}) {
    if (!externs || externs.length === 0) {
        return
    }
    return <div style={{marginTop: 20}}>
        <BorderedDisplay text="Ophtalmologistes référents">
            <table style={{width: '100%', marginBottom: 10}}>
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Email</th>
                    <th>RPPS</th>
                </tr>
                </thead>
                <tbody>
                {externs.map((extern, index) => (
                    <tr key={index}>
                        <td>{extern.fullname}</td>
                        <td>{extern.email}</td>
                        <td>{extern.rpps}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </BorderedDisplay>
    </div>
}