import React, {useEffect} from 'react';
import Loader from "../../../../Shared/Loader";
import Helper from "../../../../Shared/Helper";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import RightsStore from "../../RightsStore";
import Error from "../../../../Shared/Error";
import {Observer} from "mobx-react";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";
import {Link} from "react-router-dom";
import CoordinatePickerModal from "../../../../Shared/Map/CoordinatePickerModal";
import useOffice from "./Hooks/useOffice";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useBillingCategories from "../Hooks/useBillingCategories";
import {isLoading} from "../../../../Shared/StateHelper";

const _ = require('lodash');

function Offices(props) {
    const modal_id = `console_offices_modal`
    const ophtalmologists = props.ophtalmologists
    const [onEditOffice, setOnEditOffice] = React.useState({})

    const initialFilter = {textual: "", billingCategory: undefined}
    const [filter, setFilter] = React.useState(initialFilter)

    const {billingCategories} = useBillingCategories()

    const {offices, externs, state, create: createOffice, update: updateOffice} = useOffice(filter)
    
    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [offices, RightsStore.rights])

    const new_office = () => {
        setOnEditOffice({onEdit: false})
        open_modal()
    }

    const edit_office = (office) => {
        setOnEditOffice({...office, onEdit: true})
        open_modal()
    }

    const open_modal = () => {
        setTimeout(() => {
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.open();
        }, 100)
    }

    const create_office = () => {
        createOffice(onEditOffice).then((resp) => {
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        })
    }

    const update_office = (office) => {
        updateOffice(office).then((_) => {
            M.Modal.getInstance(document.getElementById(modal_id))?.close();
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content container">
                <h3 className="center"
                    style={{marginTop: 20}}>{onEditOffice.onEdit ? "Éditer" : "Nouveau"} cabinet</h3>
                <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Nom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOffice.titre || ""}
                                   onChange={(e) => setOnEditOffice({
                                       ...onEditOffice,
                                       titre: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Ophtalmologiste</span>
                        <select className="browser-default outlined" style={{width: "100%"}}
                                value={ophtalmologists.find(r => r.id === parseInt(onEditOffice.ophtalmologiste_id))?.id || ""}
                                onChange={(e) => setOnEditOffice({
                                    ...onEditOffice,
                                    ophtalmologiste_id: e.target.value
                                })}>
                            <option value="" disabled>Choisir un ophtalmologiste</option>
                            {ophtalmologists.filter(o => !_.isEmpty(o.nom + o.prenom))
                                .sort((a, b) => a.nom > b.nom)
                                .map((ophtalmologist) => {
                                    return <option key={ophtalmologist.id}
                                                   value={ophtalmologist.id}>{Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom)}</option>
                                })}
                        </select>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Google My Business</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditOffice.google_rating_url || ""}
                                   onChange={(e) => setOnEditOffice({
                                       ...onEditOffice,
                                       google_rating_url: e.target.value
                                   })}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                {onEditOffice.onEdit ?
                    <a className="btn btn-small blue darken-2 z-depth-0"
                       onClick={() => update_office(onEditOffice)}>Valider</a>
                    :
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={create_office}>Créer</a>}
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderOffices = () => {
        if(isLoading(state)){
            return <div style={{margin: 20}}>
                <Loader size="tiny" text=""/>
            </div>
        }

        const filteredOffices = offices.filter(office => {
            const str = `${office.titre}`.toLocaleLowerCase()
            return str.includes(filter.textual.toLowerCase())
        })

        if(_.isEmpty(filteredOffices)) {
            return <h5 className="center" style={{paddingBlock: 50}}>
                Aucun cabinet n'est disponible pour ce filtre
            </h5>
        }

        return filteredOffices.sort((a, b) => a.titre > b.titre).map((office) => renderOffice(office, externs))

    }

    const renderOffice = (office, externs) => {
        const extern_ref = externs.filter(extern => extern.cabinet_id === office.id)
        return (
            <div className="collection-item transparent" key={office.id}
                 style={{display: "flex", width: "100%", gap: 10}}>
                <div style={{width: "100%", margin: 0, display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                    <CoordinatePickerModal defaultCoordinates={office.coordinates}
                                           onValidation={(coordinates) => update_office({
                                               ...office,
                                               coordinates: coordinates
                                           })}/>
                    <div style={{width: 40, margin: 0}}>
                        <span>#{office.id}</span>
                    </div>
                    <div style={{width: 500, margin: 0}}>
                        <span>{office.titre}</span>
                    </div>
                    <div style={{width: 200, margin: 0}}>
                        <span>{office.ophtalmologiste ? Helper.formatName(office.ophtalmologiste.prenom, office.ophtalmologiste.nom) : ""}</span>
                    </div>
                    {extern_ref.length > 0 ? <div className="blue lighten-5 grey-text text-darken-3"
                                                  style={{
                                                      padding: "5px 10px",
                                                      borderRadius: 20,
                                                      border: "thin solid #1976d2"
                                                  }}>
                        {`${extern_ref.length} ophtalmologiste(s) référent(s)`}
                    </div> : null}
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <div style={{flex: 1}}>
                            <ResponsiveMoreButton id={`more_button_patient_${office.id}`} options={[
                                <a className="btn btn-small blue darken-2 z-depth-0"
                                   data-target={modal_id}
                                   onClick={() => edit_office(office)}>
                                    <i className="material-icons left">edit</i>Modifier</a>,
                                <Link to={`/offices/${office.id}`} className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">dashboard</i>
                                    Tableau de bord
                                </Link>
                            ]}/>
                        </div>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    const render = () => {
        if (RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY) === undefined) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Loader text=""/>
            </div>
        }
        if (!RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY)) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
            </div>
        }

        return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
            <h3 className="valign-wrapper" style={{margin: 20}}>
                <i className="material-icons left">business</i>
                Cabinets
            </h3>
            <div className="divider"/>
            <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div className="valign-wrapper" style={{gap: 20}}>
                        <div className="input-field outlined">
                            <input type="text" style={{height: 40}} placeholder={"Rechercher"} autoFocus
                                   value={filter.textual ?? ""}
                                   onChange={e => setFilter(prevState => {
                                       return {...prevState, textual: e.target.value}
                                   })}
                            />
                        </div>
                        <Autocomplete
                            disablePortal
                            className="ui-autocomplete"
                            value={filter.billingCategory}
                            options={billingCategories ?? []}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            onChange={(_, data) => {
                                setFilter(prevState => {
                                    return {...prevState, billingCategory: data}
                                })
                            }}
                            sx={{width: "300px"}}
                            renderInput={(params) => <TextField {...params} label="Filtrer par formules"/>}
                        />
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <a className="btn btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
                           onClick={new_office}>
                            <i className="material-icons left">add</i>
                            Nouveau
                        </a>
                    </RestrictedElement>
                </div>

                <div className="collection"
                     style={{borderRadius: 5, height: "100%", overflowY: "scroll"}}>
                    {renderOffices()}
                </div>
                <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                    {renderModal()}
                </RestrictedElement>
            </div>
        </div>
    }

    return <Observer>
        {() => render()}
    </Observer>
}

export default Offices;