import {makeObservable, action, observable, computed, configure} from "mobx"
import axios from "axios"
import {PrescriptionType} from "../Shared/Enums/Enums";
import {toast} from "react-toastify";
import {t} from "../I18n/i18n"

configure({enforceActions: 'never'}) // done during the migration

const to_s = (integer) => {
    let r = ""
    if (integer != "") {
        if (integer < 0) {
            r = "" + parseFloat(integer).toFixed(2) + ""
        } else {
            r = "+" + parseFloat(integer).toFixed(2) + ""
        }
    }
    return (r)
}

function f(integer) {
    return (integer)
}

const checkEmpty = (txt, val1, val2) => {
    if (val1 === "" || val1 === null || val2 === "" || val2 === null) {
        return ("")
    } else {
        return (txt)
    }
}
const sphereOrPlan = (data) => {
    let cleaned_data = ""
    try {
        cleaned_data = to_s(data).replace(/[-+]*/g, "")
    } catch {
        cleaned_data = ""
    }
    if (cleaned_data == "") {
        return (" plan ")
    } else {
        return (to_s(data))
    }
}

const astigmatisme = (cylindre, axe) => {
    if (cylindre === "" || cylindre === null || cylindre === 0 || cylindre === " " || cylindre === 0.0) {
        return ("")
    } else {
        axe = axe === "" || axe === null ? 0 : axe
        return checkEmpty(" ( " + to_s(f(cylindre)) + " à " + axe + "°)", cylindre, axe)
    }
}

class PrescriptionStore {
    @observable prescriptionText = ""
    @observable sourceData = 0
    @observable needToSave = true
    @observable level = 0
    prescriptions = new Map()
    loadedPrescriptionsEmpty = true
    @observable savedPrescriptions = new Map()

    constructor() {
        makeObservable(this)
    }

    @action saveAll(appointement_id, callback = () => console.log("no callback")) {
        toast.promise(
            axios.post("/api/savePrescriptions", {
                appointement_id: appointement_id,
                prescriptions: Object.fromEntries(this.prescriptions)
            }),
            {
                pending: t("PRESCRIPTION_AUTOFILL.PRESCRIPTION_REGISTRATION"),
                success: t("PRESCRIPTION_AUTOFILL.REGISTERED_PRESCRIPTION"),
                error: t("PRESCRIPTION_AUTOFILL.ERROR_WHEN")
            }
        ).then((data) => {
            callback()
            if (this.loadedPrescriptionsEmpty) {
                MesureStore.updated()
                this.loadedPrescriptionsEmpty = false
            }
            this.savedPrescriptions = this.prescriptions
        })

    }

    @action loadIfExist(appointement_id) {
        let p = new Map()
        axios.post("/api/loadPrescriptions", {appointement_id: appointement_id}).then((resp) => {
            resp.data.prescriptions.map((prescr) =>
                p.set(prescr.level.toString(), prescr.text ? prescr.text : "")
            )
            this.prescriptions = p
            this.savedPrescriptions = p
            this.prescriptionText = p.get(this.level.toString())
            this.loadedPrescriptionsEmpty = p.get("0") === "" || !p.get("0")
        })

    }

    @action changeLevel(level) {
        this.level = level.toString()
        this.prescriptionText = this.getTxt()
    }

    @action clearLevel() {
        if (this.prescriptions) {
            this.prescriptions.set(this.level.toString(), "")
        }
        this.prescriptionText = this.getTxt()
    }

    @action clearTxt() {
        if (this.prescriptions) {
            this.prescriptions.forEach((v, k) => {
                this.prescriptions.set(k.toString(), "")
            })
        }
        this.prescriptionText = this.getTxt()
    }

    @action getTxt() {
        return this.prescriptions.get(this.level.toString()) || ""
    }

    @action setTxt(txt) {
        if (this.prescriptions) {
            this.prescriptions.set(this.level.toString(), txt)
        }
        this.prescriptionText = this.getTxt()
    }

    @action append(txt) {
        if (this.prescriptions) {
            this.setTxt(this.getTxt() + txt)
        } else {
            this.setTxt(txt)
        }
    }

    hasSkiacol() {
        //j etais pas inspire, si qq un a mieux qu il le fasse (mais les boucles ne voulaient pas)
        var resp = Object.values(Object.fromEntries(this.prescriptions)).join(' ')
        return resp.toLowerCase().includes("skiacol");
    }

    @action addPrescription(type, MesureStore) {
        MesureStore.updateForm(this.sourceData, type, this.loadAndAddPrescription)
    }

    appendLongsight = (MesureStore) => {
        this.append(t("PRESCRIPTION_AUTOFILL.FAR"))
        this.append("     " + t("RIGHT_EYE") + " : " + sphereOrPlan(f(MesureStore.od_sphere)) + astigmatisme(MesureStore.od_cylindre, MesureStore.od_axe) + "\n")
        this.append("     " + t("LEFT_EYE") + " : " + sphereOrPlan(f(MesureStore.og_sphere)) + astigmatisme(MesureStore.og_cylindre, MesureStore.og_axe) + "\n\n")
    }

    appendShortsight = (MesureStore) => {
        this.append(t("PRESCRIPTION_AUTOFILL.CLOSE"))
        this.append("     " + t("RIGHT_EYE") + " : " + sphereOrPlan(to_s(f(MesureStore.od_sphere) + f(MesureStore.od_addition))) + astigmatisme(MesureStore.od_cylindre, MesureStore.od_axe) + "\n")
        this.append("     " + t("LEFT_EYE") + " : " + sphereOrPlan(to_s(f(MesureStore.og_sphere) + f(MesureStore.og_addition))) + astigmatisme(MesureStore.og_cylindre, MesureStore.og_axe) + "\n\n")
    }

    NO_EDIT_WARNING_TEXT = t("PRESCRIPTION_AUTOFILL.ADVICE")
    appendNoEditWarning = () => {
        this.setTxt(this.getTxt().replace(this.NO_EDIT_WARNING_TEXT, ""))
        this.append(this.NO_EDIT_WARNING_TEXT)
    }

    @action loadAndAddPrescription(source, MesureStore, StorePrescription, type) {
        if (type.titre === PrescriptionType.LONG_SIGHTED_GLASSES) {
            StorePrescription.appendLongsight(MesureStore)
            StorePrescription.appendNoEditWarning()
        } else if (type.titre === PrescriptionType.GLASSES_AND_FRAME) {
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.FRAME_LENSES"))
            StorePrescription.append("     " + t("RIGHT_EYE") + " : " + sphereOrPlan(f(MesureStore.od_sphere)) + astigmatisme(MesureStore.od_cylindre, MesureStore.od_axe) + "\n")
            StorePrescription.append("     " + t("LEFT_EYE") + " : " + sphereOrPlan(f(MesureStore.og_sphere)) + astigmatisme(MesureStore.og_cylindre, MesureStore.og_axe) + "\n\n")
            StorePrescription.appendNoEditWarning()
        } else if (type.titre === PrescriptionType.SHORT_SIGHTED_GLASSES) {
            StorePrescription.appendShortsight(MesureStore)
            StorePrescription.appendNoEditWarning()
        } else if (type.titre === PrescriptionType.PROGRESSIVE_GLASSES) {
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.PROGRESSIVE"))
            StorePrescription.append("     " + t("RIGHT_EYE") + " : " + sphereOrPlan(f(MesureStore.od_sphere)) + astigmatisme(MesureStore.od_cylindre, MesureStore.od_axe) + checkEmpty("Add " + to_s(MesureStore.od_addition), to_s(MesureStore.od_addition), 1) + "\n")
            StorePrescription.append("     " + t("LEFT_EYE") + " : " + sphereOrPlan(f(MesureStore.og_sphere)) + astigmatisme(MesureStore.og_cylindre, MesureStore.og_axe) + checkEmpty("Add " + to_s(MesureStore.og_addition), to_s(MesureStore.og_addition), 1) + "\n\n")
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.INTOLERANCE"))
            StorePrescription.appendLongsight(MesureStore)
            StorePrescription.appendShortsight(MesureStore)
            StorePrescription.appendNoEditWarning()
        } else if (type.titre === PrescriptionType.DEGRESSIVE_GLASSES) {
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.REDUCING"))
            StorePrescription.append("     " + t("RIGHT_EYE") + " : " + sphereOrPlan(f(MesureStore.od_sphere)) + astigmatisme(MesureStore.od_cylindre, MesureStore.od_axe) + checkEmpty("Add " + to_s(MesureStore.od_addition), to_s(MesureStore.od_addition), 1) + "\n")
            StorePrescription.append("     " + t("LEFT_EYE") + " : " + sphereOrPlan(f(MesureStore.og_sphere)) + astigmatisme(MesureStore.og_cylindre, MesureStore.og_axe) + checkEmpty("Add " + to_s(MesureStore.og_addition), to_s(MesureStore.og_addition), 1) + "\n\n")
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.INTOLERANCE"))
            StorePrescription.appendLongsight(MesureStore)
            StorePrescription.appendShortsight(MesureStore)
            StorePrescription.appendNoEditWarning()
        } else if (type.titre === PrescriptionType.NO_CORRECTION) {
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.NO_CORRECTION"))
        } else if (type.titre === PrescriptionType.ORTHOPTIC_EVALUATION) {
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.ORTHOPTIC_ASSESSMENT"))
        } else if (type.titre === PrescriptionType.VISUAL_ACUITY_CHECK) {
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.VA_CHECK"))
        } else if (type.titre === PrescriptionType.SKIACOL) {
            StorePrescription.append(t("PRESCRIPTION_AUTOFILL.SKIACOL"))

        }
    }

    @computed get isEmpty() {
        if (this.savedPrescriptions.size === 0) return true
        return Array.from(this.savedPrescriptions.values()).every(val => !val)
    }

    @computed get hasPrimaryPrescription() {
        return !!this.savedPrescriptions.get("0")
    }
}

let store = new PrescriptionStore
global.PrescriptionStore = store
export default store
