import React, {useEffect, useState} from "react"
import axios from "axios";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import Tooltip from "../../../Shared/Tootip";
import {t} from "../../../I18n/i18n";

export default function PatientSatisfaction(props) {
    const [request_state, setRequestState] = useState(RequestState.LOADING)
    const [patient_ratings, setPatientRatings] = useState([])
    const [ratingDetails, setRatingDetails] = useState({})

    useEffect(() => {
        get_patient_ratings()
    }, []);

    const get_patient_ratings = () => {
        axios.get("/patient_ratings/orthoptist")
            .then((resp) => {
                setRatingDetails(resp.data.details ?? {})
                setPatientRatings(resp.data.patient_ratings ?? [])
                setRequestState(RequestState.SUCCESS)
            })
            .catch((_) => setRequestState(RequestState.ERROR))
    }

    const render_rating_count = () => {
        return <div style={{
            border: "1px solid lightgrey",
            borderRadius: "10px",
            width: 250,
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
            <p style={{marginLeft: 20, marginBottom: 0, fontSize: "1.1em"}}>
                <b>{t("STATISTICS_TABS.PATIENT_SATISFACTION_TAB.RATING_COUNT")}</b>
            </p>
            <p style={{margin: 20, fontSize: "1.6em", textAlign: "center"}}>
                <b>{ratingDetails.rating_count}</b>
            </p>
        </div>
    }

    const render_average_score = () => {
        return <div style={{
            border: "1px solid lightgrey",
            borderRadius: "10px",
            width: 250,
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
            <p style={{marginLeft: 20, marginBottom: 0, fontSize: "1.1em"}}>
                <b>{t("STATISTICS_TABS.PATIENT_SATISFACTION_TAB.AVERAGE_SCORE")}</b>
            </p>
            <p style={{margin: 20, fontSize: "1.6em", textAlign: "center"}}>
                <b>{ratingDetails.average}</b>
            </p>
        </div>
    }

    const render_satisfaction_rate = () => {
        const tooltip_text = t("STATISTICS_TABS.PATIENT_SATISFACTION_TAB.SATISFACTION_RATE_TOOLTIP")
        return <div style={{
            border: "1px solid lightgrey",
            borderRadius: "10px",
            width: 250,
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }}>
            <p style={{marginLeft: 20, marginBottom: 0, fontSize: "1.1em"}}>
                <b>{t("STATISTICS_TABS.PATIENT_SATISFACTION_TAB.SATISFACTION_RATE")}</b>
            </p>
            <p style={{margin: 20, fontSize: "1.6em", position: "relative", textAlign: "center"}}>
                <b>{`${ratingDetails.good_ratings} %`}</b>
                <Tooltip text={tooltip_text} id="client_satisfaction_tooltip">
                    <span style={{position: "absolute", top: "-10px"}}>
                    <i className="material-icons" style={{fontSize: 15}}>help_outline</i>
                </span>
                </Tooltip>

            </p>
        </div>
    }

    const renderRating = (rating) => {
        return <div style={{display: "flex", flexDirection: "column", gap: 5}}>
            <h6 style={{paddingInline: 10}}>{Helper.formatName(rating.first_name, rating.last_name)}</h6>
            <div className="card">
                <h5 style={{fontWeight: "bolder"}}>Note : {rating.note}/5</h5>
                <h5 style={{whiteSpace: "pre-wrap"}}>{rating.comment}</h5>
            </div>
            <h6 className="right-align" style={{paddingInline: 10}}>{Helper.computeDate(rating.date)}</h6>
        </div>
    }

    const renderRatings = () => {
        return <div className="container">
            {patient_ratings.sort((a, b) => a.date < b.date ? 1 : -1).map((rating) => {
                return renderRating(rating)
            })}
        </div>
    }

    if (request_state === RequestState.ERROR) return <div
        className="center">{t("STATISTICS_TABS.PATIENT_SATISFACTION_TAB.ERROR_OCCURRED")}</div>
    if (request_state === RequestState.LOADING) return <div className="center">
        <Loader wide text={t("STATISTICS_TABS.PATIENT_SATISFACTION_TAB.LOADING")}/>
    </div>
    return <div style={{display: "flex", gap: 20, padding: 20, flexDirection: "column"}}>
        <div style={{display: "flex", gap: 10, justifyContent: "space-evenly"}}>
            {render_rating_count()}
            {render_average_score()}
            {render_satisfaction_rate()}
        </div>
        {renderRatings()}
    </div>
}