import React, {Component} from "react";
import DisplayBlocks from "./DisplayBlocks";

export default class DisplayText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: {}
        }
    }

    componentDidMount() {
        this.setState({block: this.props.block})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({block: this.props.block})
        }
    }

    render() {
        if (this.state.block.data) {
            return (
                <div style={{
                    paddingBlock: 10,
                    display: "flex",
                    textAlign: `${this.state.block.data.justify}`,
                    whiteSpace: "pre-wrap",
                    fontSize: `${DisplayBlocks.computeSize(this.state.block.data.size)}`,
                    fontWeight: `${this.state.block.data.weight}`,
                    fontStyle: `${this.state.block.data.style}`,
                    maxWidth: "100%"
                }}>
                    <div id="display_block_html"  dangerouslySetInnerHTML={{__html: this.state.block.data.text || ""}}/>
                </div>
            );
        } else return null
    }

    static preview(block) {
        return <div style={{
            paddingBlock: 10,
            display: "flex",
            justifyContent: `${block.data.justify}`,
            whiteSpace: "pre-wrap",
            fontSize: `${DisplayBlocks.computeSize(block.data.size)}`,
            fontWeight: `${block.data.weight}`,
            fontStyle: `${block.data.style}`
        }}>
            <div className="display_block" dangerouslySetInnerHTML={{__html: block.data.text || ""}}/>
        </div>
    }
}