import React, {useState} from 'react';
import axios from 'axios'
import moment from "moment"
import {i18n, t} from "../../I18n/i18n";

const LogsAppointments = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [summaryData, setSummaryData] = useState(null);
    const [details, setDetails] = useState(null);
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col s5">
                        <label>{t("START_DATE")}</label>
                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                    </div>
                    <div className="col s5">
                        <label>{t("END_DATE")}</label>
                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                    </div>
                    <div className="col s2">
                        <button className="btn" onClick={() => {
                            axios.get(`/externes/rapports/logs_appointments/${startDate}/${endDate}`).then((data)=>{
                                setSummaryData(data.data.summary)
                                setDetails(null)
                            })
                        }}>{t("SEARCH")}</button>
                    </div>
                </div>

            </div>
            {summaryData == null ? null : (
            <div className="container">
                <p> Dans cette période, il y'a eu :</p>
                <ul>
                    <li>{summaryData[0]} consultation(s) incomplètes </li>
                    <li>{summaryData[1]} consultation(s) en attente de lecture de l'opthalmologue</li>
                    <li>{summaryData[2]} consultation(s) validés par l'ophtalmologue</li>
                    <li>{summaryData[3]} consultation(s) refusés (pathologies) par l'ophtalmologue</li>
                </ul>
                <p><a
                    onClick={()=>{
                        axios.get(`/externes/rapports/logs_appointments/${startDate}/${endDate}?details=true`).then((data)=>{
                                setDetails(data.data)
                            })
                    }}
                >Clickez-ici pour avoir la liste de patients</a></p>
            </div>
            )}
            {details == null ? null : (
                <table className={"striped bordered"}>
                    <thead>
                        <tr>
                            <th> ID consult</th>
                            <th> Date </th>
                            <th> Prénom patient </th>
                            <th> Nom patient</th>
                            <th> Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                    {details.map((consult) => (
                       <tr key={consult.id}>
                            <td>{consult.id}</td>
                            <td>{moment(consult.start).format("DD-MM-YYYY h:mm")}</td>
                            <td>{consult.patient.prenom}</td>
                            <td>{consult.patient.nom}</td>
                            <td>{consult.status_controle}</td>
                       </tr>
                    ))}
                    </tbody>

                </table>
            )}
        </div>
    )
}

export default LogsAppointments;
