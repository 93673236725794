import React from "react";

const Error = (props) => {

    return (
        <div className="container center-align" style={{marginBlock: 50, ...props.style}}>
            <i className={`material-icons red-text text-darken-2 ${props.size} fill`}>cancel</i>
            {props.show_text? <h3 className={props.textStyle}>{props.errorText}</h3>: null}
        </div>
    )
}

Error.defaultProps = {
    errorText: "Erreur de l'opération",
    size: "large",
    show_text:true,
    style:{},
    textStyle:"",
}

export default Error