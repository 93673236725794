import React, {useState} from 'react';
import axios from 'axios'
import socket from "../../../Shared/socket";
import debounce from 'lodash.debounce';
import SimpleCamera from "../SimpleCamera";
import {wrapper} from "../../../I18n/i18n";
const {c} = wrapper("ORTHOPTIST_PHONE");
export default function EyeFundus(props) {

    const [current_appointment, setCurrent_appointment] = useState(null);
    const clearAppointment = debounce(() => {
        setCurrent_appointment(null);
    }, 10000);
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    socket.on("connect", () => {
        console.log("connected");
        socket.emit("machineID", { machine: props.bridge_uid });
        socket.on("current_appointment", (data) => {
            console.log("current_appointment", data);
            setCurrent_appointment(data.appointement);
            clearAppointment();
        })
    });
    if(current_appointment == null){
        return (
            <div>
                <h1 className={"center"}>{c("WAITING_FOR_APPOINTMENT")}</h1>
            </div>
        )
    }
    return (
        <div>

            <SimpleCamera
                handleSave={(blob) => {
                    const formData = new FormData();
                    formData.append('image', blob);
                    formData.append('appointment_id', current_appointment);
                    axios.post('/orthoptistes/phone/eye_fundus', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response) => {
                    }).catch((error) => {
                    });
                }} />

        </div>
            )
}