import React from 'react'

const EventCanceled = () => {

    return <div id="full-height" style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15vh"}}>
        <i className={"material-icons red-text"} style={{fontSize: 100}}>sentiment_very_dissatisfied</i>
        <h2>Cet événement a été annulé. Vous pouvez le relancer ou en créer un autre.</h2>
    </div>
}

export default EventCanceled