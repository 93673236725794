import React from "react";
import PatientInfo from "./PatientInfo";
import Images from "./Images";
import Alert from "./Alert";
import Prescription from "./Prescription";
import {format_measures} from "../../Appointments/Mesures/mesure_components/mesures";
import DisplayMeasures from "../../Orthoptistes/Appointment/Check/DisplayMeasures";

export default function Overview({patient, typedemesures, mesures, appointement, images_url, nb_rdv, prescriptions, actepossible, orthoptist}) {

    return (
        <div id="full-height" className="patient-overview">
            <div  style={{margin: 0, height: "100%", padding: "20px", fontFamily: "Arial"}}>
                <div style={{display: "flex", margin: 0, height: "100%", gap: 20}}>
                    <div style={{height: "100%", width: "50%"}}>
                        <div  style={{display: "flex", flexDirection: "column", height: "100%", gap: 20}}>
                            <div style={{height: "60%"}}>
                                <PatientInfo patient={patient} nb_rdv={nb_rdv} appointement={appointement} actepossible={actepossible} orthoptist={orthoptist}/>
                            </div>
                            <div style={{height: "40%"}}>
                                <Images key={patient.id} images_url={images_url}/>
                            </div>
                        </div>
                    </div>
                    <div style={{height: "100%", width: "50%"}}>
                        <div style={{display: "flex", flexDirection: "column", height: "100%", gap: 20}}>
                            <div style={{height: "60%"}}>
                                <DisplayMeasures title={false} measures={format_measures(mesures, typedemesures)}/>
                            </div>
                            <div style={{height: "40%"}}>
                                <Prescription prescriptions={prescriptions}/>
                            </div>
                        </div>
                    </div>
                    <div style={{height: "100%", width: "20%"}}>
                        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div style={{height: "100%"}}>
                                <Alert appointement={appointement}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}