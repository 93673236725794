import React, {useEffect, useRef, useState} from "react"
import {Mesures, Side, TypesMesures} from "../../Shared/Enums/Enums";
import mesures_types from "../../Appointments/Mesures/mesure_components/MesureTypes.json";
import {observer} from "mobx-react";
import MedicalEventStore from "../MedicalEventStore";

const AddMeasure = observer(({measures, onAdd}) => {
    const measureRef = useRef()
    const [measure, setMeasure] = useState({})
    const selectedMeasureType = MedicalEventStore.selectedMeasureTypeId

    const fillableMeasures = [
        Mesures.SPHERE,
        Mesures.CYLINDER,
        Mesures.AXIS,
        Mesures.ADDITION,
        Mesures.ACUITY,
        Mesures.PARINAUD
    ]

    useEffect(() => {
        MedicalEventStore.getMeasureTypes()
    }, []);

    useEffect(() => {
        if (!selectedMeasureType) return
        const measureTypeId = parseInt(selectedMeasureType)
        let selectedMeasure = measures.find(m => m.measure_type_id === parseInt(selectedMeasureType))
        const formatAcuity = (m) => {
            const float = parseFloat(m)
            // if NaN return m
            if (isNaN(float)) return m
            return (float * 10).toString()
        }
        if (!selectedMeasure) selectedMeasure = {}
        else selectedMeasure = {
            ...selectedMeasure,
            od_av_loin: formatAcuity(selectedMeasure.od_av_loin),
            og_av_loin: formatAcuity(selectedMeasure.og_av_loin)
        }
        setMeasure({...selectedMeasure, measure_type_id: measureTypeId, name: measureTypeName(measureTypeId)})
    }, [MedicalEventStore.selectedMeasureTypeId]);

    const measureTypeName = (id) => {
        const type = MedicalEventStore.measureTypes.find(t => t.id === id)
        return type?.name
    }

    const getNeededMeasure = () => {
        const id = parseInt(selectedMeasureType)
        const type = MedicalEventStore.measureTypes.find(t => t.id === id)
        if (!type) return []

        return mesures_types.find(t => TypesMesures[t.name] === type.name)
    }

    const renderInputs = (side) => {
        const requiredMeasures = getNeededMeasure().mandatory ?? []
        const optionalMeasures = getNeededMeasure().optional ?? []
        const neededMeasure = [...optionalMeasures, ...requiredMeasures]
        return <div>
            <h5 className="center" style={{margin: 0}}>{side === Side.LEFT ? "OG" : "OD"}</h5>
            <div className="valign-wrapper">
                {fillableMeasures.map((m, index) => {
                    if (!neededMeasure.includes(m.value)) return null
                    const measure_name = Helper.computeMeasureName(side, m)
                    const required = requiredMeasures.includes(m.value)
                    if (measure_name.includes("av_loin")) {
                        return <div className="input-field outlined" style={{marginLeft: 10, gap: 20}}>
                            <h6 className="center" style={{fontWeight: required ? "600" : "400"}}>{m.name}</h6>
                            <input type="text" value={measure[measure_name] || ""}
                                   onChange={(e) => setMeasure({...measure, [measure_name]: e.target.value})}/>
                        </div>
                    }
                    return <div className="input-field outlined" style={{marginLeft: 10, gap: 20}}>
                        <h6 className="center" style={{fontWeight: required ? "600" : "400"}}>{m.name}</h6>
                        <input type="text" value={measure[measure_name] || ""}
                               onChange={(e) => setMeasure({...measure, [measure_name]: e.target.value})}/>
                    </div>
                })}
            </div>
        </div>

    }

    const renderMesureForm = () => {
        if (!selectedMeasureType) return null

        return <div style={{
            display: "flex", flexDirection: "column", gap: 10,
            border: "thin solid lightgrey", borderRadius: 7, padding: 10, position: "relative"
        }}>
            <a className="btn-flat btn-small transparent"
               style={{position: "absolute", top: -4, right: -4, paddingInline: 10}}
               onClick={() => MedicalEventStore.selectedMeasureTypeId = undefined}>
                <i className="material-icons">close</i>
            </a>
            {renderInputs(Side.RIGHT)}
            {renderInputs(Side.LEFT)}
        </div>

    }

    const canSave = () => {
        if (!selectedMeasureType) return false
        const unvoidCount = Object.entries(measure).reduce((acc, [key, value]) => {
            if (!key.includes("od") && !key.includes("og")) return acc
            return value === null ? acc : acc + 1
        }, 0)
        return unvoidCount > 0
    }
    return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
        <h5 className="center" style={{margin: 0}}>Nouvelle mesure</h5>
        <div className="valign-wrapper" style={{gap: 20}}>
            <h6>Type de mesure</h6>
            <select className="browser-default" value={selectedMeasureType ?? ""}
                    onChange={(e) => MedicalEventStore.selectedMeasureTypeId = e.target.value}
                    ref={measureRef} style={{width: "fit-content"}}>
                <option value={""} disabled>Choisir un type de mesure</option>
                {Object.values(TypesMesures).filter((type) => ![TypesMesures.TENSION, TypesMesures.PACHYMETRY].includes(type))
                    .map((type) => MedicalEventStore.measureTypes.find(t => t.name === type)).filter(Boolean)
                    .map((type, index) => {
                        return <option key={index} value={type.id}>{type.name}</option>
                    })}
            </select>
            <a className={`btn-small blue darken-2 z-depth-0 ${canSave() ? "" : "disabled"}`}
               style={{marginLeft: "auto"}}
               onClick={() => onAdd(measure)}>
                {measures.find(m => m.id === measure.id) ?
                    <>
                        <i className="material-icons left">save</i>
                        Éditer
                    </> :
                    <>
                        <i className="material-icons left">add</i>
                        Ajouter
                    </>
                }
            </a>
        </div>
        {renderMesureForm()}
    </div>


})

export default AddMeasure