import React, {Component} from 'react';
import BridgeStore from "../BridgeStore"
import {observer} from "mobx-react"

@observer
export default class InputRefracteur extends Component {
  constructor(props){
    super(props);
    this.state = {
      interval: null
    }

    setTimeout(()=>{
      if(BridgeStore.connected) BridgeStore.socket.emit("scan_usb")
    },1000);
  }
  render() {
    return (
      <div className="row">
            <div className="col s6">
            <div className="input-field">
              <select className="browser-default"
               value={BridgeStore.type_refracteur}
                onChange={(e) => BridgeStore.update_type_refracteur(e.target.value)} >
                <option disabled value=""> Type de réfracteur</option>
                {BridgeStore.refracteurs.map((ref,index)=>(
                  <option value={ref} key={index}>{ref}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col s6">
            <div className="input-field">

              <select className="browser-default"
               value={BridgeStore.force_usb_refracteur}
                onChange={(e) => BridgeStore.update_usb_refracteur(e.target.value)} >

               >
                <option value="auto"> Automatique </option>
                <option value={BridgeStore.usb_refracteur}> {BridgeStore.usb_refracteur}</option>
                <option value="NA" disabled> Autre usbs disponibles : </option>
                {BridgeStore.avaiable_usbs.map((usb,index)=>(
                  <option value={usb["port"]} key={index}>{usb["port"]}</option>
                  ))}
              </select>
              </div>
          </div>
      </div>
    );
  }
}
