import React from "react";
import PlaceholderSignature from "../../Shared/PlaceholderSignature";

const DocumentSignature = ({prescription, ophtalmologist}) => {
    if (!prescription) return null;
    const renderSignature = () => {
        if (prescription.signature) {
            return <img style={{height: "150px", float: "right"}}
                        src={prescription.signature}
                        alt="Signature de l'ophtalmologiste"/>
        } else {
            return <PlaceholderSignature
                style={{
                    float: "right",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                firstname={ophtalmologist.first_name}
                lastname={ophtalmologist.nom}
            />
        }
    }
    return <div className="pdf-footer prevent-break" style={{marginTop: "auto"}}>
        {renderSignature()}
    </div>
}
export default DocumentSignature;