import React, {Component} from 'react';
import Store from "./MessagesStore"

export default class Popup extends Component {
  constructor(props) {
    super(props)
    Store.setCable(props.target)
  }
  render() {
    return (
      <div></div>
    );
  }
}
