import React, {useEffect, useState} from 'react';
import axios from "axios";
import {InvoiceStatus, PaymentObject, User} from "../../../Shared/Enums/Enums";
import {wrapper} from "../../../I18n/i18n";
import {Link} from "react-router-dom";

const {t} = wrapper("PAYMENT_ERRORS");
const PaymentErrors = () => {
    const [errors, setErrors] = useState([]);
    const [paymentObject, setPaymentObject] = useState(PaymentObject.PAYMENT);

    useEffect(() => {
        get_errors();
    }, [paymentObject]);

    const get_errors = () => {
        axios.get(`/admin/console/payments/errored/${paymentObject}`).then((resp) => {
            setErrors(resp.data)
        })
    }
    const renderStatus = (status) => {
        switch (status) {
            case InvoiceStatus.WAITING:
                return <i className="material-icons grey-text left">schedule</i>
            case InvoiceStatus.PENDING:
                return <i className="material-icons orange-text left">pending</i>
            case InvoiceStatus.SUCCESS:
                return <i className="material-icons green-text left">done</i>
            case InvoiceStatus.FAILED:
                return <i className="material-icons red-text left">error</i>
            default:
                return null
        }
    }

    const renderObjectSelect = () => {
        return <div>
            <select className="browser-default" value={paymentObject} style={{textAlign: "center"}}
                    onChange={(e) => setPaymentObject(e.target.value)}>
                {Object.values(PaymentObject).map((object) => {
                    return <option key={object} value={object}>{t(object)}</option>
                })}
            </select>
        </div>

    }

    const renderPaymentErrors = () => {
        return <table className="temeoo-table">
            {/*<thead>*/}
            {/*<tr>*/}
            {/*    <th> ID</th>*/}
            {/*    <th> Status paiement</th>*/}
            {/*    <th> Date</th>*/}
            {/*    <th> Type Utilisateur</th>*/}
            {/*    <th> Utilisateur</th>*/}
            {/*    <th> Nb erreurs</th>*/}
            {/*</tr>*/}
            {/*</thead>*/}
            <tbody>
            {errors.map((error, index) => {
                const user_type = Object.values(User).find(user => user.value === error.user_type);
                const user_name = error.user_fullname ?? Helper.formatName(error.user_firstname, error.user_lastname)
                console.log(error)
                let color = "black"
                if(error.error_count < 20 ) color = "red darken-5"
                if(error.error_count < 10 ) color = "red darken-4"
                if(error.error_count < 5 ) color = "red darken-2"
                if(error.error_count < 2 ) color = "red lighten-2"
                return <tr key={index}>
                    <td>#{error.id}</td>
                    <td>{renderStatus(error.status)}</td>
                    <td>
                        <h5 className={`card ${error.amount > 0 ? "blue darken-2" : "grey darken-2"} z-depth-0 white-text center`}
                            style={{margin: "0 20px", padding: "5px 10px", borderRadius: 3, maxWidth: 75}}>
                            {(error.amount ?? 0) / 100.0}€
                        </h5>
                    </td>
                    <td>{Helper.formatDate(error.created_at)}</td>
                    <td>{user_type.name}</td>
                    <td>{user_name}</td>
                    <td style={{width: 150}}>
                        <Link to={`/paiement_errors/${paymentObject.toLowerCase()}/${error.id}`} className={`btn-small ${color} z-depth-0 right`} style={{width: "100%"}}>
                            <i className={"material-icons left"}>warning</i>
                            {error.error_count} {error.error_count > 1 ? t("ERRORS") : t("ERROR")}
                        </Link>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    }


    return <div className="fc">
        {renderObjectSelect()}
        {renderPaymentErrors()}
    </div>
}

export default PaymentErrors;