import React from 'react';
import AdsPanel from "./AdsPanel";

const AdsPage = ({campaigns, redirection_url}) => {

    const redirect = () => {
        window.location.href = redirection_url
    }
    return <div style={{display: "flex", flexDirection: "column", gap: 40, alignItems: "center", paddingBlock: 40}}>
        <div>
            <a className="btn blue darken-2 z-depth-0" onClick={() => redirect()}>
                J'accède à mes documents
            </a>
        </div>
        <div className="container">
            <AdsPanel campaigns={campaigns}/>
        </div>
    </div>
}

export default AdsPage;