import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import DatePicker from "../../../Shared/DatePicker";
import Helper from "../../../Shared/Helper";
import {downloadFile} from "../../../Shared/download";
import StateButton from "../../../Shared/StateButton";
import {loading, RequestState} from "../../../Shared/StateHelper";
import OrthoptistSelect from "../../../Users/OrthoptistSelect";
import {toast} from "react-toastify";

function BillingConsole({orthoptists}) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [historyState, setHistoryState] = useState(RequestState.IDLE);
    const [selectedOrthoptists, setSelectedOrthoptists] = useState({
        bills: [],
        invoices: [],
        resync: []
    })
    const [authorized, setAuthorized] = useState(false)

    const csrfToken = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    const getBills = () => {
        setHistoryState(loading(historyState))
        axios.get("/admin/console/bills", {
            params: {
                start_date: Helper.formatDate(startDate, 'YYYY-MM-DD'),
                end_date: Helper.formatDate(endDate, 'YYYY-MM-DD'),
                orthoptist_ids: selectedOrthoptists.bills
            }
        }).then(response => {
            downloadFile(response.data, 'historique_examens.csv')
            setHistoryState(RequestState.SUCCESS)
        }).catch(_ => {
            setHistoryState(RequestState.ERROR)
        })
    }
    const getInvoices = () => {
        axios.get("/admin/console/invoices", {params: {orthoptist_ids: selectedOrthoptists.invoices}})
            .then(response => downloadFile(response.data, 'prochaine_facture.csv'));
    }

    const submitResync = () => {
        // authorizedRef.current.checked = false
        axios.post("/admin/console/bills/resync", {
            start_date: Helper.formatDate(startDate, 'YYYY-MM-DD'),
            end_date: Helper.formatDate(endDate, 'YYYY-MM-DD'),
            orthoptist_ids: selectedOrthoptists.resync
        }).then(_ => toast.success("Synchronisation en cours"))
            .catch((_) => toast.error("Une erreur est survenue"))
    }

    return (
        <div>
            <div style={{marginBottom: 10}}>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">receipt</i>Télécharger
                    l'historique
                    des rendez-vous</h3>
                <h6 className="grey-text text-darken-2"
                    style={{fontStyle: "italic", paddingLeft: "2vw"}}>
                    L'ensemble des informations des examens réalisés entre les dates (pas de date = pas de limite)</h6>
                <div className="valign-wrapper container" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
                    <div style={{width: "300px"}}>
                        <OrthoptistSelect multiple
                                          value={orthoptists.bills}
                                          onChange={ids => setSelectedOrthoptists(prevState => {
                                              return {...prevState, bills: ids}
                                          })}/>
                    </div>
                    <DatePicker id="bills_start_date" txt="Date de début" onChange={(date) => setStartDate(date)}/>
                    <DatePicker id="bills_end_date" txt="Date de fin" onChange={(date) => setEndDate(date)}/>
                    <div className="hide-on-small-only">
                        <StateButton side='left' state={historyState} onClick={getBills} text="Export CSV"
                                     icon="file_download" color="blue darken-2"/>
                    </div>
                    <div className="center hide-on-med-and-up" style={{width: "100%", marginTop: 10}}>
                        <StateButton side='left' state={historyState} onClick={getBills} text="Export CSV"
                                     icon="file_download" color="blue darken-2"/>
                    </div>
                </div>
            </div>
            <div className="divider"/>
            <div style={{marginBottom: 10}}>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">euro</i>Donnée
                    prochaine facture</h3>
                <h6 className="grey-text text-darken-2" style={{fontStyle: "italic", paddingLeft: "2vw"}}>
                    Ce qui sera réglé par un ou plusieurs orthoptistes à la prochaine échéance</h6>
                <div className="valign-wrapper container" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
                    <div style={{width: "300px"}}>
                        <OrthoptistSelect multiple
                                          value={orthoptists.invoices}
                                          onChange={ids => setSelectedOrthoptists(prevState => {
                                              return {...prevState, invoices: ids}
                                          })}/>
                    </div>
                    <div className="hide-on-small-only">
                        <StateButton side='left' state={RequestState.IDLE} onClick={getInvoices} text="Export CSV"
                                     icon="file_download" color="blue darken-2"/>
                    </div>
                    <div className="center hide-on-med-and-up" style={{width: "100%", marginTop: 10}}>
                        <StateButton side='left' state={RequestState.IDLE} onClick={getInvoices} text="Export CSV"
                                     icon="file_download" color="blue darken-2"/>
                    </div>
                </div>
            </div>
            <div className="divider"/>
            <div style={{marginBottom: 10}}>
                <h3 className="valign-wrapper" style={{margin: 20}}>
                    <i className="material-icons left">sync</i>Resynchroniser les montants d'un orthoptiste
                </h3>
                <h6 className="grey-text text-darken-2" style={{fontStyle: "italic", paddingLeft: "2vw"}}>
                    Applique rétroactivemnet les tarifs actuels d'un orthoptist pour les consultations déjà réalisées.
                    Attention, cette action est irréversible, cette action n'impacte <b>PAS</b> des consultations déjà
                    facturée ou en cours de facturation.
                    <br/>Cette opération sera effectuée de manière <b>asynchrone</b>, patientez donc quelques minutes
                    avant de voir le résultat
                </h6>
                <div className="valign-wrapper container" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
                    <div style={{width: "300px"}}>
                        <OrthoptistSelect multiple
                                          value={orthoptists.resync}
                                          onChange={ids => setSelectedOrthoptists(prevState => {
                                              return {...prevState, resync: ids}
                                          })}/>
                    </div>
                    <div className="col s6">
                        <DatePicker id="sync_bills_start_date" txt="Date de début"
                                    onChange={(date) => setStartDate(date)}/>
                    </div>
                    <div className="col s6">
                        <DatePicker id="sync_bills_end_date" txt="Date de fin"
                                    onChange={(date) => setEndDate(date)}/>
                    </div>
                    <label>
                        <input type="checkbox" checked={authorized} className="filled-in" onChange={(e) => setAuthorized(!authorized)}/>
                        <span>Je sais ce que je fais.</span>
                    </label>
                    <a className={!authorized ? "disabled btn" : "btn z-depth-0 blue darken-2"}
                       onClick={submitResync}>Resync
                    </a>
                </div>
            </div>
            <div className="divider"/>
        </div>
    );
}

export default BillingConsole;