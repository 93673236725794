import React, {useEffect, useRef} from 'react';
import MesureStore from "../MesureStore";
import Comments from "./SideBar/Comments";
import Measures from "./SideBar/Measures";
import Questions from "./SideBar/Questions";
import axios from "axios";
import {toast} from "react-toastify";


export const is_appointment_recent = (appointment) => {
    const now = new Date();
    const appointment_date = new Date(appointment.start);
    const diff = now - appointment_date;
    const days = diff / (1000 * 60 * 60 * 24);
    return days < 30;
}

const ImportLastAppointmentModal = ({last_appointment}) => {
    if(!last_appointment) return null;
    const modal_element = useRef(null)
    useEffect(() => {
        if (last_appointment && is_appointment_recent(last_appointment)) {
            const modal = M.Modal.init(modal_element.current, {
                onCloseStart: () => {
                    MesureStore.is_appointment_new = false;
                }
            })
            modal.open()
            return () => modal.destroy()
        }
    }, []);

    const handleImport = () => {
        axios.patch("/api/appointment/import_last", {appointment_id: MesureStore.appointement_id}).then((res) => {
            // TODO: giga lazy, to improve
            window.location.reload()
        }).catch((_) => {
            toast.error("Une erreur est survenue lors de l'importation du rendez-vous")
            M.Modal.getInstance(modal_element.current).close()
        })
    }

    const renderSummary = () => {
        return <div className="row" style={{marginInline: 20, gap: 20}}>
            <div className="col s6">
                <Comments appointment={last_appointment}/>
                <Measures appointment={last_appointment}/>
            </div>
            <div className="col s6">
                <Questions report={last_appointment?.report}/>
            </div>
        </div>
    }

    return <div className="modal modal-fixed-footer" ref={modal_element}>
        <div className="modal-content">
            <h3 className="center">Un rendez-vous a été effectué récemment</h3>
            <h6>Une rendez-vous récent a été réalisé avec ce patient, vous pouvez choisir d'importer les données de ce rendez vous.</h6>
            <h6>Résumé des données :</h6>
            {renderSummary()}
        </div>
        <div className="modal-footer" style={{display: "flex", justifyContent: "right", gap: 20}}>
            <a className="btn blue darken-2 z-depth-0" onClick={handleImport}>Importer</a>
            <a className="modal-close btn black z-depth-0">Annuler</a>
        </div>
    </div>
}

export default ImportLastAppointmentModal;