import React, {useRef, useState} from 'react';
import {TextareaAutosize} from "@mui/material";
import {t} from "../../I18n/i18n"


const MessageInput = ({ onSend }) => {
    const [text, setText] = useState('');
    const messageRef = useRef(null);

    const sendMessage = () => {
        if (messageRef.current.value) {
            onSend(messageRef.current.value);
            messageRef.current.value = "";
            setText(''); // Reset the text state
        }
    }

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            if (event.ctrlKey || event.shiftKey) {
                // Insert a line break at the current cursor position
                const cursorPosition = messageRef.current.selectionStart;
                const textBeforeCursor = messageRef.current.value.substring(0, cursorPosition);
                const textAfterCursor = messageRef.current.value.substring(cursorPosition);
                const newText = textBeforeCursor + "\n" + textAfterCursor;
                messageRef.current.value = newText;
                setText(newText); // Update the text state to trigger re-render

                // Update the cursor position
                messageRef.current.selectionStart = cursorPosition + 1;
                messageRef.current.selectionEnd = cursorPosition + 1;

                event.preventDefault();
            } else {
                event.preventDefault();
                sendMessage();
            }
        }
    }

    return (
        <div className="valign-wrapper white" style={{ borderRadius: 10, border: "thin solid black", margin: 10 }}>
            <div className="input-field outlined" style={{ flex: 1, paddingBlock: 10 }}>
                <TextareaAutosize className="messenger-textarea" placeholder={t("CONVERSATION.WRITE_MESSAGE")}
                                  type={"search"}
                                  ref={messageRef}
                                  style={{ borderRadius: 10, border: "none", boxShadow: "none" }}
                                  onKeyDown={onKeyDown}
                                  value={text}
                                  maxRows={6}
                                  onChange={(e) => setText(e.target.value)}
                />
            </div>
            <div style={{ paddingInline: "5px 5px" }}>
                <a className="btn-floating transparent z-depth-0" onClick={sendMessage}>
                    <i className="material-icons blue-text text-darken-2">send</i>
                </a>
            </div>
        </div>
    );
}

export default MessageInput;