import React, {useEffect} from "react"
import {observer} from "mobx-react";
import ConsoleDashboardStore from "../../ConsoleDashboardStore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const _ = require('lodash');

const OphtalmologistsFilter = observer(() => {


    useEffect(() => {
        ConsoleDashboardStore.loadFilterOffices()
    }, [ConsoleDashboardStore.selectedOphtalmologists]);



    return <Autocomplete
        sx={{width: "300px"}}
        disablePortal
        multiple
        filterSelectedOptions
        className="ui-autocomplete"
        key="ophtalmologist_filter"
        value={ConsoleDashboardStore.selectedOphtalmologists}
        options={ConsoleDashboardStore.ophtalmologistList}
        getOptionLabel={(option) => option.displayed_value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, array) => ConsoleDashboardStore.setSelectedOphtalmologists(array)}
        onInputChange={(event) => ConsoleDashboardStore.loadFilterOphtalmologists(event?.target.value, false)}
        renderInput={(params) => <TextField{...params} label="Sélectionner ophtalmologistes"/>}
    />
})

export default OphtalmologistsFilter