import React from 'react'

function CommentSideBar(props) {

    return (
        <div className="card" style={props.style}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 20}}>
                <h2 style={{marginTop: 0, marginBottom: "10px"}}>Commentaires</h2>
            </div>
            <hr/>
        </div>
    )
}

export default CommentSideBar