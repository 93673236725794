import React from "react"
import axios from "axios"
import ShowConversation from "../../Messages/ShowConversation"
import MessagesStore from "../../Messages/MessagesStore";
import Popup from "../../Messages/Popup";
import {t} from "../../I18n/i18n"

export default class SendMessageToOrthoptiste extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this)
        this.openModal = this.openModal.bind(this)
        this.state = {
            message: "",
            instances: []
        };
    }

    componentDidMount() {
        M.Modal._count = 0;
        var elems = document.querySelectorAll('.modal');
        var instances = M.Modal.init(elems, {});
        this.setState({instances: instances})
    }

    openModal(e) {
        e.preventDefault();
        this.setState({
            message: MessagesStore.messages.length === 0 ? `
        Concernant le rendez-vous de ${this.props.patient}, le ${this.props.date}.
        Accessible au lien /mesure/${this.props.app_id}
      ` : ""
        })
       // M.textareaAutoResize(document.querySelector("#message-textarea"));
        MessagesStore.messagesRead(this.props.orthoptiste_id, this.props.appointement_id)
    }

    submit(e) {
        e.preventDefault()
        axios.post("/messages/ophtalmologiste/api/sendmessage", {
            orthoptiste: this.props.orthoptiste_id,
            appointement_id: this.props.appointement_id,
            message: this.state.message
        }).then((data) => {
            MessagesStore.loadMessages(this.props.orthoptiste_id, this.props.appointement_id)
        })

    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <a onClick={this.openModal} id={MessagesStore.newMessage ? "blink" : ""}
                       style={{borderRadius: "10px"}}
                       className={`btn-large blue modal-trigger truncate-nohide z-depth-0`}
                       href="#modal-messages">
                        <i className={`material-icons right hide`}>chat</i>
                        {t("CONTACT")}
                    </a>
                    <div id="modal-messages" className="modal">
                        <div className="modal-content">
                            <h4 className="center">Message {this.props.orthoptiste.prenom} {this.props.orthoptiste.nom}</h4>
                            <form action="" onSubmit={this.submit}>
                                <div className="container">
                                    <div className="row valign-wrapper">
                                        <div className="input-field col s10 grey lighten-2"
                                        style={{borderRadius: "30px"}}>
                        <textarea
                            onChange={(e) => this.setState({message: e.target.value})}
                            className="materialize-textarea"
                            id="message-textarea"
                            style={{
                                minHeight: "150px",
                                borderBottom: "hidden",
                                boxShadow: "none",
                                padding: "1vw"
                            }}
                            value={this.state.message}
                        >
                        </textarea>
                                        </div>
                                        <div className="col s2">
                                            <button className="btn-floating waves-effect waves-light green" type="submit"
                                                    name="action"> <i className="material-icons" style={{color:"white"}}>send</i>
                                            </button>
                                        </div>
                                    </div>
                                    <Popup
                                        key={"p"+this.props.app_id}

                                        target={this.props.orthoptiste_id}/>
                                    <ShowConversation target="ophtalmologiste"
                                                      target_id={this.props.orthoptiste_id}
                                                      appointement_id={this.props.appointement_id}
                                                      key={this.props.app_id}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

