import React from 'react';
import {t} from "../../../I18n/i18n";
import MoreButton from "../../../Shared/UiKit/MoreButton";
import EditAppointmentButton from "../EditAppointmentButton";
import {IS_ORTHOPTIST} from "../../../Shared/Helper";
import {Act} from "../../../Shared/Enums/Enums";
import axios from "axios";

const ActionButtonsOrthoptist = ({appointment, appointmentChanged}) => {
    if(!IS_ORTHOPTIST) return null

    const reworkable = ([2, 3].includes(appointment.status_controle))
        && appointment.act?.titre !== Act.ONLY_PRESCRITPION

    const isAbsent = appointment.act?.titre.trim() === Act.NOTHING || appointment.status_controle <= 0

    const scorButtons = [
        <a className="btn-small z-depth-0"
           href={`/orthoptiste/download/prescription?appointment_id=${appointment.id}`}
           target="_blank"><i
            className="material-icons left">picture_as_pdf</i>{t("EXAM_HISTORY.DOWNLOAD.SCOR_PDF")}</a>,
        <a className="btn-small z-depth-0"
           href={`/orthoptiste/download/prescription?html=1&appointment_id=${appointment.id}`}
           target="_blank"><i className="material-icons left">image</i>{t("EXAM_HISTORY.DOWNLOAD.SCOR_IMG")}</a>
    ]

    const documentsButton = <a className={`btn-small green darken-2 z-depth-0`}
                               target={"_blank"} href={`documents/${appointment.id}`}>
        <i className="material-symbols-outlined left">table_eye</i>
        {appointment.document_count > 1 ?
            `${appointment.document_count} ${t("EXAM_HISTORY.DOCUMENTS_AVAILABLE")}`
            :
            `${appointment.document_count} ${t("EXAM_HISTORY.DOCUMENT_AVAILABLE")}`}
    </a>

    const archiveButton = isAbsent ? <a className={`btn-small red darken-2 z-depth-0 ${isAbsent ? "hide" : ""}`}
                                        onClick={() => archive_appointment(appointment.id)}>
        <i className="material-icons left hide-on-med-and-down">delete</i>
        <i className="material-icons hide-on-large-only">delete</i>
        <span className="hide-on-med-and-down">{t("DELETE")}</span>
    </a> : null


    const archive_appointment = (id) => {
        axios.patch(`/patients/appointment/archive/${id}`).then((resp) => {
            appointmentChanged({...appointment, archived: true})
        })
    }

    const rework_appointment = () => {
        axios.patch(`/patients/appointment/rework/${appointment.id}`).then((resp) => {
            appointmentChanged({...appointment, status_controle: 0})
        })
    }

    const reworkButton = () => {
        if (!reworkable) return null
        return <a className={`btn-small z-depth-0`} onClick={rework_appointment}>
            <i className="material-icons left">undo</i>
            {t("EXAM_HISTORY.REWORK.DELETE_ANALYSIS")}
        </a>
    }

    return <>
        <div>
            <a className={`btn-small z-depth-0 blue darken-2 ${appointment.status_controle < 1 ? "disabled" : ""}`}
               target={"_blank"} title={"Consulter la synthèse"}
               href={`/syntheses/rendez-vous/${appointment.id}`}
               style={{paddingInline: 10}}>
                <i className={`material-icons`}>search</i>
            </a>
            <EditAppointmentButton appointment={appointment}/>
        </div>
        <MoreButton id={`patient_history_appt_${appointment.id}_btns`}
                    options={[documentsButton, reworkButton(), archiveButton, ...scorButtons]}/>
    </>
}

export default ActionButtonsOrthoptist;