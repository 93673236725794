import React, {useEffect, useState} from 'react';
import axios from "axios";
import Helper from "../../../Shared/Helper";


const LinkHandler = ({event}) => {
    const [short, setShort] = useState(null)
    const host = process.env.APP_HOST

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
        getShort(event)
    }, []);

    const getShort = (event) => {
        axios.get(`/admin/console/medical_events/short/${event.id}`)
            .then((resp) => {
                setShort(resp.data)
                const interval = setInterval(() => {
                    setShort(prevState => {
                        if (prevState.expiration <= 0) {
                            clearInterval(interval)
                            return {...prevState, expiration: null}
                        }
                        return {...prevState, expiration: prevState.expiration - 1}
                    })
                }, 1000)
            })
    }

    const resetExpiration = () => {
        axios.post(`/admin/console/medical_events/short/${event.id}/expiration`)
            .then((resp) => setShort(prevState => {
                return {...prevState, expiration: resp.data}
            }))
    }


    const copyLinkToClipboard = () => {
        const link = `${host}/medical_events/join/${short.short}`
        navigator.clipboard.writeText(link)
    }

    const renderExpiration = () => {
        if(!short) return null
        if (short.expiration < 0) return "Lien infini"
        if (!short.expiration) return "Lien expiré"
        const jhms = Helper.secondsToJhms(short.expiration)
        const jhms_obj = Helper.secondsToJhms(short.expiration, true)
        const title = `Le lien expire dans ${jhms_obj.days} jours, ${jhms_obj.hours} heures, ${jhms_obj.minutes} minutes et ${jhms_obj.seconds} secondes`
        return <span className="grey-text valign-wrapper" title={title}
                     style={{fontSize: 12, gap: 5}}>
            <i className="material-icons" style={{fontSize: 14}}>access_time</i>
            {jhms}
        </span>
    }

    return <div className="valign-wrapper" style={{gap: 10}}>
        <a className="btn-small btn-flat blue lighten-5" onClick={() => navigator.clipboard.writeText(short?.short)} style={{minWidth: 140}}>
            <i className="material-icons left">content_copy</i>
            {short?.short}
        </a>
        <a className="btn-small blue darken-2 z-depth-0" onClick={copyLinkToClipboard}>
            <i className="material-icons left">link</i>
            Copier lien
        </a>
        {renderExpiration()}
        <a className="btn-small blue darken-2 z-depth-0" title="Rendre le lien disponible pour 2 semaines de plus"
           onClick={resetExpiration}>
            <i className="material-icons">refresh</i>
        </a>

    </div>
}

export default LinkHandler;