import React from "react"
import axios from "axios"
import {observer} from "mobx-react"
import moment from "moment"
import MesureStore from '../MesureStore'
import {CENTRE_OPHTALMO_URL} from "../../../Shared/Helper";
import Logo from "../../../Shared/UiKit/Logo";

const isUpper = (ki) => {
    return ki == ki.toUpperCase() && ki != ki.toLowerCase();
}

@observer
class PrintAcces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txt: "",
            key: []
        };
    }

    componentDidMount() {
        moment.locale("fr")
        axios.get("/api/getUserKey", {params: {appointement_id: MesureStore.appointement_id}}).then((data) => {
            this.setState({key: data.data.key.split("")})
        })

    }

    render() {
        return <div style={{margin: 20}}>
            <div style={{display: "flex", flexDirection: "column", marginLeft: 40, marginRight: 40}}>
                <div className="center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Logo text style={{maxWidth: 240}}/>
                </div>
                <p style={{marginTop: 70}}>{MesureStore.patient.prenom} {MesureStore.patient.nom},</p>
                <p> Vous avez effectué un contrôle de vue chez un orthoptiste collaborant avec Temeoo dans le cadre d'un
                    protocole de télémédecine. Votre dossier va être examiné par un ophtalmologue sous 8 jours. Vos
                    résultats seront alors disponible sur ce lien : <b
                        style={{fontSize: "18px"}}>{CENTRE_OPHTALMO_URL}</b></p>
                <div style={{display: "flex"}}>
                    <h2 style={{marginRight: 20}}>Votre code patient : </h2>
                    {this.state.key.map((ki, index) => (
                        <h2 key={index} style={{textDecoration: isUpper(ki) ? "underline" : "none"}}>
                            {ki}
                        </h2>
                    ))}
                </div>
                <h5 style={{fontStyle: "italic", marginTop: 0}}>* Les lettres majuscules sont soulignées</h5>
                {MesureStore.orthoptiste.genererapport === true && MesureStore.cabinet.email ? (
                    <div className="center">
                        <p className="center">Vous avez un problème ? Vous pouvez nous contacter
                            par <b>email</b> à cet adresse :<br/>
                        </p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <b>{MesureStore.cabinet.email}</b>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    }
}

export default PrintAcces
