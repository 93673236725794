import React, {useState} from 'react'
import {toast} from "react-toastify";
import BorderedDisplay from "../Shared/UiKit/BorderedDisplay";
import MedicalEventStore from "./MedicalEventStore";
import Regex from "../Shared/Validation/Regex";
import PatientForm from "./PatientForm";

const _ = require('lodash')
const AddPatient = () => {
    const defaultPatient = {
        firstname: "",
        lastname: "",
        email: "",
        telephone: "",
        birthday: "",
        ssn: "",
        organism_code: undefined,
        health_insurance: undefined
    }
    const [patient, setPatient] = useState(defaultPatient)

    const addPatient = () => {
        MedicalEventStore.addPatient(patient).then(_ => {
            setPatient(defaultPatient)
        }).catch(_ => toast.error("Erreur lors de l'ajout du patient"))

    }

    return <PatientForm patient={patient} onChange={setPatient} onSubmit={addPatient}
                        title={"Ajouter un patient"} buttonText={"Ajouter"} buttonIcon={"add"}/>
}

export default AddPatient