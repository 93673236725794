import React, {useState} from "react";
import {isLoading, RequestState} from "../../Shared/StateHelper";

const ImportMediaButton = () => {
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    const handleSubmission = (files) => {
        setRequestState(RequestState.LOADING)
        PhoneStore.saveMultipleFiles(files).then(() => {
            setRequestState(RequestState.SUCCESS)
        }).catch(() => {
            setRequestState(RequestState.ERROR)
        })
    }

    const buttonState = () => {
        switch (requestState) {
            case RequestState.LOADING:
                return {color: "blue darken-2", icon: "sync"}
            case RequestState.SUCCESS:
                return {color: "green darken-2", icon: "done"}
            case RequestState.ERROR:
                return {color: "red darken-2", icon: "error"}
            default:
                return {color: "blue darken-2", icon: "upload"}
        }
    }

    const btnColor = buttonState().color
    const btnIcon = buttonState().icon
    return <>
        <a className={`btn ${btnColor} z-depth-0`} onClick={() => document.getElementById("media_input").click()}>
            <i className={`material-icons left ${isLoading(requestState) ? "rotating": ""}`}>{btnIcon}</i>
            Importer
        </a>
        <input id="media_input" type="file" multiple={true}
               style={{display: "none"}} onChange={(e) => handleSubmission(e.target.files)}/>
    </>
}

export default ImportMediaButton