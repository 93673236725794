import React, {useEffect, useState} from "react"
import SmartTextArea from "../../Shared/UiKit/SmartTextArea";
import Comment from "./Comment";
import Prescription from "./Prescription";

const Texts = () => {
    return <div className="row" style={{gap: 20}}>
        <div className="col m6 s12" style={{display: "flex", flexDirection: "column", gap: 20}}>
            <Prescription/>
        </div>
        <div className="col m6 s12" style={{display: "flex", flexDirection: "column", gap: 20}}>
            <Comment/>
        </div>
    </div>

}

export default Texts