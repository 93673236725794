import {action, computed, makeObservable, observable} from "mobx"
import axios from "axios"
import Preferences from "../../Shared/Preferences/Preferences";
import {defaultShortcuts} from "../../Shared/Preferences/hooks/useShortcuts";

class ControleStore {
    @observable skip = []
    @observable app_id = ""
    @observable history = []
    @observable shortcuts = []
    edit_prescription = false
    possible_examens = [
        {id: 0, title: "Fond d'oeil", default: true},
        {id: 1, title: "OCT", default: true},
        {id: 2, title: "Champ visuel", default: true},
        {id: 3, title: "Angiographie", default: true},
        {id: 4, title: "Topographie de cornée", default: true}
    ];


    constructor() {
        this.loadPossibleExamens()
        makeObservable(this)
        this.getTextualShortcuts()
    }


    getTextualShortcuts = () => {
        Preferences.get('textual_shortcuts').then(shortcuts => {
            this.shortcuts = [...JSON.parse(shortcuts), ...defaultShortcuts] ?? []
        })
    }

    @action addHistory(id) {
        this.history.push(id)
    }

    @action backToHistory() {
        return this.history.pop()
    }

    @computed get hasHistory() {
        return (this.history.length > 0)
    }

    loadPossibleExamens() {
        axios.get('/admin/possible_examens/examens').then((response) => {
            this.possible_examens = this.possible_examens.concat(response.data.examens)
        })
    }
}

let store = new ControleStore
global.ControleStore = store
export default store
