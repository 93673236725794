import React, {useEffect} from 'react';
import {t} from "../../I18n/i18n";
import axios from "axios";
import {toast} from "react-toastify";
import ValidationMessage from "./ValidationMessage";

const Messages = ({messages}) => {

    const renderMessage = (text) => {
        // get all the tokens (on the form !token!) and replace them by render token, the rest is render like spans
        const arr = text.split(/(!.*?!)/g);
        const res = [];
        arr.forEach((item, index) => {
            if (item.match(/(!.*?!)/g)) {
                res.push(renderToken(item))
            } else {
                res.push(<span>{item}</span>)
            }
        })
        return res;
    }
    const renderToken = (token) => {
        token = token.replace(/!/g, "");
        let value = t(`TOKENS.${token}`)
        if (value === token) value = "???"
        return <span className="badge grey lighten-1 white-text"
                     style={{borderRadius: 5, fontSize: "0.9em", whiteSpace: "pre-wrap"}}>
            {value}
        </span>
    }

    const renderMessages = () => {
        return <table>
            <thead>
            <tr>
                <th className="center">{t("OPHTALMOLOGIST_MESSAGES.SEVERITY")}</th>
                <th className="center">{t("OPHTALMOLOGIST_MESSAGES.MESSAGE")}</th>
                <th className="center">{t("OPHTALMOLOGIST_MESSAGES.MESSAGE_HN")}</th>
                <th className="center">{t("ACTIONS")}</th>
            </tr>
            </thead>
            <tbody>
            {messages.map(message => {
                return <tr>
                    <td><strong>{message.cle}</strong></td>
                    <td>{renderMessage(message.valeur_defaut)}</td>
                    <td>{renderMessage(message.valeur_hors_nomenclature)}</td>
                    <td>
                        <a className="btn-small blue darken-2 z-depth-0"
                           href={`/admin/textemessages/edit/${message.id}`}>
                            <i className="material-icons">edit</i>
                        </a>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    }

    return <div id='full-height'
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    overflowY: "scroll",
                    alignItems: "center",
                    padding: 20
                }}>
        <h1 style={{marginBottom: 20, marginTop: 0}}>{t("OPHTALMOLOGIST_MESSAGES.TITLE")}</h1>
        <ValidationMessage/>
        <div className="divider"/>
        <label>{t("OPHTALMOLOGIST_MESSAGES.DETAIL")}.</label>
        {renderMessages()}
    </div>
};

export default Messages;