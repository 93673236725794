export const UserAlertsTabs = {
    orthoptist: {
        tabs: [
            {type: "to_reconvoke", title: "À réorienter", default_tab: true},
            {type: "reconvoked", title: "Réorienté", default_tab: false}
        ],
        links: {
            get_alert_list: "/orthoptistes/alert/alert_list",
            get_alert_count: "/orthoptistes/alert/alert_count",
            archivate_alert: "/orthoptistes/alert/archive/",
            comment_url: "/orthoptistes/alert/comment",
            honored_url: "/orthoptistes/alert/honored",
            convoked_url: "/orthoptistes/alert/convoked"
        }
    },
    ophtalmologist: {
        tabs: [
            {type: "unread", title: "Non lues", default_tab: false},
            {type: "to_reconvoke", title: "À reconvoquer", default_tab: true},
            {type: "reconvoked", title: "Reconvoqué", default_tab: false}
        ],
        links: {
            get_alert_list: "/admin/alert/alert_list",
            get_alert_count: "/admin/alert/alert_count",
            archivate_alert: "/admin/alert/archive/",
            comment_url: "/secretariat/api/alert/saveComment",
            honored_url: "/secretariat/api/setHonored",
            convoked_url: "/secretariat/api/setConvoqued"
        }
    },
    extern: {
        tabs: [
            {type: "unread", title: "Non lues", default_tab: false},
            {type: "to_reconvoke", title: "À reconvoquer", default_tab: true},
            {type: "reconvoked", title: "Reconvoqué", default_tab: false}
        ],
        links: {
            get_alert_list: "/externes/alert/alert_list",
            get_alert_count: "/externes/alert/alert_count",
            archivate_alert: "externes/alerte/archive/",
            comment_url: "/secretariat/api/alert/saveComment",
            honored_url: "/secretariat/api/setHonored",
            convoked_url: "/secretariat/api/setConvoqued"
        }
    }
}