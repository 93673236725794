import React, {useEffect, useState} from "react"
import moment from "moment";
import axios from "axios";
import {toast} from "react-toastify";
import EarningsMetric from "./Metrics/EarningsMetric";
import ActiveUsersMetric from "./Metrics/ActiveUsersMetric";
import EarningsByActMetric from "./Metrics/EarningsByActMetric";
import EarningsByOrthoptistMetric from "./Metrics/EarningsByOrthoptistMetric";
import AppointmentCountMetric from "./Metrics/AppointmentCountMetric";
import EquipedOfficesMetric from "./Metrics/EquipedOfficesMetric";

import {TimeRange} from "../../../Shared/Enums/Enums";
import {observer} from "mobx-react";
import ConsoleDashboardStore from "../ConsoleDashboardStore";
import FormulasFilter from "./AdvancedFilters/FormulasFilter";
import ActsFilter from "./AdvancedFilters/ActsFilter";
import OphtalmologistsFilter from "./AdvancedFilters/OphtalmologistsFilter";
import OrthoptistsFilter from "./AdvancedFilters/OrthoptistsFilter";
import OfficesFilter from "./AdvancedFilters/OfficesFilter";

const Overview = observer(() => {

        const [displayAdvancedFilters, setDisplayAdvancedFilters] = useState(false)
        const [pricing, setPricing] = useState({
            earnings: 0,
            nb_active: 0,
            nb_bills: 0,
            offices: {}
        })

        useEffect(() => {
            getEarnings()
        }, [
            ConsoleDashboardStore.currentDateOption,
            ConsoleDashboardStore.startDate,
            ConsoleDashboardStore.endDate,
            ConsoleDashboardStore.selectedFormulas,
            ConsoleDashboardStore.selectedActs,
            ConsoleDashboardStore.selectedOphtalmologists,
            ConsoleDashboardStore.selectedOffices,
            ConsoleDashboardStore.selectedOrthoptists,
        ]);


        const getEarnings = () => {
            let dateRange = ConsoleDashboardStore.getDateRange()

            axios.get("/admin/console/bills/earnings", {
                params: {
                    start_date: dateRange.start_date,
                    end_date: dateRange.end_date,
                    dinu: ConsoleDashboardStore.includeDinu,
                    formula_ids: ConsoleDashboardStore.selectedFormulas.map((formula) => formula.id) || undefined,
                    act_codes: ConsoleDashboardStore.selectedActs.map((act) => act.code) || undefined,
                    orthoptist_ids: ConsoleDashboardStore.selectedOrthoptists.map((orthoptist) => orthoptist.id),
                    office_ids: ConsoleDashboardStore.selectedOffices.map((office) => office.id),
                    ophtalmologist_ids: ConsoleDashboardStore.selectedOphtalmologists.map((ophtalmologist) => ophtalmologist.id)
                }
            }).then((response) => {
                setPricing(response.data)
            }).catch(_ => {
                toast.error("Une erreur est survenue lors de la recherche des factures")
            })
        }

        const monthlyPreview = (pricing) => {
            if (ConsoleDashboardStore.currentDateOption !== TimeRange.MONTH.value) return null
            const nb_working_days = get_nb_working_days(ConsoleDashboardStore.startDate, moment())
            const average = (pricing.earnings / nb_working_days)

            const total_month_days = get_nb_working_days(ConsoleDashboardStore.startDate, moment(ConsoleDashboardStore.startDate).endOf('month'))
            return (average * total_month_days).toFixed(2)
        }

        const get_nb_working_days = (start, end) => {
            const dates = []
            const date = new Date(start);
            const endDate = new Date(end);
            while (date <= endDate) {
                dates.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            return dates.filter(date => date.getDay() !== 0 && date.getDay() !== 6).length
        }

        const renderAdvancedFilters = () => {
            if (!displayAdvancedFilters) return null
            return <div style={{
                display: "flex",
                gap: 10,
                marginTop: 15,
                flexWrap: "wrap",
                justifyContent: "center"
            }}>
                <FormulasFilter/>
                <ActsFilter/>
                <OphtalmologistsFilter/>
                <OfficesFilter/>
                <OrthoptistsFilter/>
            </div>
        }

        return <>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                <div style={{display: "flex", flexDirection: "column", width: "85%"}}>
                    <div style={{
                        display: "flex",
                        gap: 15,
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {Object.values(TimeRange).map((option, index) => {
                            const selected = ConsoleDashboardStore.currentDateOption === option.value
                            const color = selected ? 'blue darken-2 white-text' : 'transparent black-text'
                            return <a
                                key={index}
                                className={`btn-flat btn-small ${color}`}
                                onClick={() => ConsoleDashboardStore.setDateOption(option.value)}
                                style={{border: '1px solid grey'}}
                            >
                                {option.name}
                            </a>
                        })}
                        <input style={{margin: 0, height: 30, maxWidth: 150}} type="date" id="start"
                               name="trip-start"
                               min="2021-01-01" max={moment().format("YYYY-MM-DD")}
                               onChange={e => ConsoleDashboardStore.setCustomDate("start", e.target.value)}
                               value={ConsoleDashboardStore.startDate}/>
                        <p style={{margin: 0}}>au</p>
                        <input style={{margin: 0, height: 30, maxWidth: 150}} type="date" id="end" name="trip-end"
                               min="2021-01-01" max={moment().format("YYYY-MM-DD")}
                               onInput={e => ConsoleDashboardStore.setCustomDate("end", e.target.value)}
                               value={ConsoleDashboardStore.endDate}/>
                        <label>
                            <input type="checkbox" className="filled-in" checked={ConsoleDashboardStore.includeDinu}
                                   onChange={(e) => ConsoleDashboardStore.includeDinu = e.target.checked}/>
                            <span>Inclure Dinu</span>
                        </label>
                        <i className="material-icons clickable"
                           onClick={() => setDisplayAdvancedFilters(!displayAdvancedFilters)}>filter_list</i>
                    </div>
                    {renderAdvancedFilters()}
                </div>
            </div>
            <div style={{display: "flex", flexWrap: "wrap", marginTop: 15, gap: 10, justifyContent: "space-evenly"}}>
                <EarningsMetric earnings={pricing.earnings} monthly_preview={monthlyPreview(pricing)}/>
                <ActiveUsersMetric pricing={pricing}/>
                <EarningsByActMetric pricing={pricing}/>
                <EarningsByOrthoptistMetric pricing={pricing}/>
                <AppointmentCountMetric appointment_count={pricing.nb_bills}/>
                <EquipedOfficesMetric offices={pricing.offices}/>
            </div>
        </>

    }
)

export default Overview