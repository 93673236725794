import React from "react";

const DisplayCard = ({title, children, color, link, style}) => {
    return <div className={`${color} ${link ? 'clickable' : ""}`}
                onClick={() => {
                    if (link) window.location.href = link
                }}
                style={{
                    ...style,
                    borderRadius: "10px",
                    color: "white",
                    height: "100%",
                    padding: "10px",
                    display: "flex", flexDirection: "column"
                }}>
        <div style={{marginLeft: 20, marginBottom: 0, fontSize: 20}}>
            <h3 className="white-text"
                style={{
                    marginBlock: 10,
                    fontSize: "1,2em",
                    fontWeight: 400
                }}>
                {title}
            </h3>
        </div>
        {children}
    </div>
}

DisplayCard.defaultProps = {
    color: "blue lighten-2",
    title: "Test",
    children: <div/>
}

export default DisplayCard