import axios from "axios";
import {toast} from "react-toastify";
import {ActNature} from "../../Shared/Enums/Enums";

const {observable, makeObservable} = require("mobx");
const {TimeRange} = require("../../Shared/Enums/Enums");


class ConsoleDashboardStore {

    constructor() {
        makeObservable(this)
        this.loadFilterFormulas()
        this.loadFilterOphtalmologists()
    }

    formulaList = []
    actList = Object.values(ActNature)
    @observable ophtalmologistList = []
    @observable officeList = []
    @observable orthoptistList = []


    @observable startDate = TimeRange.TODAY.start()
    @observable endDate = TimeRange.TODAY.end()
    @observable includeDinu = false
    @observable currentDateOption = TimeRange.TODAY.value

    @observable selectedFormulas = []
    @observable selectedOphtalmologists = []
    @observable selectedOffices = []
    @observable selectedOrthoptists = []
    @observable selectedActs = []


    loadFilterFormulas = () => {
        axios.get("/admin/console/filters_data/formulas")
            .then((response) => {
                this.formulaList = response.data.formulas
            })
            .catch((_) => toast.error("Erreur dans le chargement des formules"))
    }

    loadFilterOphtalmologists = (searchTerm = null, updateOffices = true) => {
        axios.get("/admin/console/filters_data/ophtalmologists", {
            params: {
                search_term: searchTerm
            }
        })
            .then((response) => {
                this.ophtalmologistList = response.data.ophtalmologists.map((ophtalmologist) => {
                    return Object.assign(ophtalmologist, {displayed_value: `${ophtalmologist.last_name} ${ophtalmologist.first_name} #${ophtalmologist.id}`})
                })
                if(updateOffices) this.loadFilterOffices()
            })
            .catch((_) => toast.error("Erreur dans le chargement des ophtalmologistes"))
    }

    loadFilterOffices = (searchTerm = null, updateOrthoptists = true) => {
        axios.get("/admin/console/filters_data/offices", {
            params: {
                ophtalmologist_ids: this.selectedOphtalmologists.map((ophtalmologist) => ophtalmologist.id),
                search_term: searchTerm
            }
        })
            .then((response) => {
                this.officeList = response.data.offices.map((office) => {
                    return Object.assign(office, {displayed_value: `${office.title} #${office.id}`})
                })
                if(updateOrthoptists) this.loadFilterOrthoptists()
            })
            .catch((_) => toast.error("Erreur dans le chargement des offices"))
    }

    loadFilterOrthoptists = (searchTerm = null) => {
        axios.get("/admin/console/filters_data/orthoptists", {
            params: {
                ophtalmologist_ids: this.selectedOphtalmologists.map((ophtalmologist) => ophtalmologist.id),
                office_ids: this.selectedOffices.map((office) => office.id),
                search_term: searchTerm
            }
        })
            .then((response) => {
                this.orthoptistList = response.data.orthoptists.map((orthoptist) => {
                    return Object.assign(orthoptist, {displayed_value: `${orthoptist.last_name} ${orthoptist.first_name} #${orthoptist.id}`})
                })
            })
            .catch((_) => toast.error("Erreur dans le chargement des orthoptistes"))
    }

    setCustomDate = (type, value) => {
        if (type === "start") this.startDate = value
        else this.endDate = value
        this.currentDateOption = null
    }

    setDateOption = (option) => {
        this.startDate = TimeRange[option].start()
        this.endDate = TimeRange[option].end()
        this.currentDateOption = option
    }

    setSelectedOphtalmologists = (ophtalmologists) => {
        this.selectedOphtalmologists = ophtalmologists
        this.loadFilterOphtalmologists()
    }

    setSelectedOffices = (offices) => {
        this.selectedOffices = offices
    }

    setSelectedOrthoptists = (orthoptists) => {
        this.selectedOrthoptists = orthoptists
    }

    getDateRange = () => {
        let start_date
        let end_date
        if (this.currentDateOption !== null) {
            start_date = TimeRange[this.currentDateOption].start()
            end_date = TimeRange[this.currentDateOption].end()
        } else {
            start_date = this.startDate.toString()
            end_date = this.endDate.toString()
        }

        return {start_date: start_date, end_date: end_date}
    }

}

let store = new ConsoleDashboardStore
global.ConsoleDashboardStore = store
export default store