import React, {useEffect} from 'react';
import ZoneVisualization from "./ZoneVisualization";

const ZoneVisualizationModal = ({onValidation, coordinates, ...props}) => {
    const [displayModal, setDisplayModal] = React.useState(false);
    const [radius, setRadius] = React.useState(props.radius);

    useEffect(() => {
        if(!displayModal) return;

    }, [displayModal]);

    useEffect(() => {
        const handleKeypress = (e) => {
            if (e.key === "Escape") {
                setDisplayModal(false);
            }
        };
        window.addEventListener("keydown", handleKeypress);
        return () => {
            window.removeEventListener("keydown", handleKeypress);
        }
    }, []);


    const handleValidation = () => {
        setDisplayModal(false);
        onValidation(radius);
    };

    const renderModal = () => {
        if (!displayModal) return null;
        return <div className="card"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") handleValidation()
                    }}
                    style={{
                        position: "fixed",
                        height: "70%",
                        width: "70%",
                        right: 0,
                        left: 0,
                        top: "10vh",
                        margin: "auto",
                        zIndex: 1000,
                        padding: 0,
                        borderRadius: 3
                    }}>
            <div style={{
                position: "absolute",
                top: -15,
                display: "flex",
                justifyContent: "center",
                left: 0,
                margin: "30px 20px",
                padding: 10,
                gap: 10,
                zIndex: 1001,
                backgroundColor: "white",
                borderRadius: 30
            }}>
                <h3 style={{margin: 0}}>{((radius ?? props.radius ?? 30000)/1000).toFixed(1)}km</h3>
            </div>
            <ZoneVisualization lat={coordinates.lat} lng={coordinates.lng} radius={radius}
                               onChange={(radius) => setRadius(radius)}/>
            <div style={{
                position: "absolute",
                top: -15,
                display: "flex",
                justifyContent: "center",
                right: 0,
                margin: "40px 20px",
                gap: 10,
                zIndex: 1001,
                backgroundColor: "white",
                padding: 5, borderRadius: 30
            }}>
                <a className="btn btn-floating green darken-2">
                    <i className="material-icons white-text" onClick={handleValidation}>check</i>
                </a>
                <a className="btn btn-floating red darken-2">
                    <i className="material-icons white-text" onClick={() => setDisplayModal(false)}>close</i>
                </a>

            </div>
        </div>
    };

    return <>
        <a className={`btn btn-flat transparent ${(coordinates?.lat && coordinates?.lng) ? "" : "disabled"} `}
           title="Définir la zone d'action de la campagne"
           onClick={() => setDisplayModal(true)}>
            <i className={`material-icons`}>wifi_tethering</i>
        </a>
        {renderModal()}
    </>
};

ZoneVisualizationModal.defaultProps = {
    onValidation: (radius) => console.log(`radius: ${radius}`),
    id: "mapbox"
};

export default ZoneVisualizationModal;

