import React, {useState} from 'react'
import PatientHistory from "./PatientHistory";
import axios from "axios";
import {toast} from "react-toastify";

function PatientSideBar(props) {
    const [patient, setPatient] = useState(props.patient)

    const updatePatient = (data) => {
        axios.post("/api/v3/patients/update", {...data, id: patient.id}).then(res => {
            setPatient(res.data.patient)
            props.onHistoryChange(res.data.patient.history)
        }).catch(err => {
            toast.error("Erreur lors de la sauvegarde des données")
        })
    }

    return (
        <div className="card" style={props.style}>
            <PatientHistory history={patient.history} autosave={props.autosave}
                            onEdit={history => updatePatient({history: history})}/>
        </div>
    )
}

PatientSideBar.defaultProps = {
    onHistoryChange: () => null,
    autosave: false
}
export default PatientSideBar