import React from 'react';
import AppointmentHistory from "./AppointmentHistory";
import MesureStore from "../../MesureStore";
import {t} from "../../../../I18n/i18n"

function AppointmentsHistory() {
    const appointments = MesureStore.last_appointements

    if(!appointments?.length) return null
    return <div>
        <h6>{t("PREVIOUS_CONSULTATION")}</h6>
        <div style={{display: "flex", flexDirection: "column", gap: 10, overflowX: "clip"}}>
            {appointments.map((appointment, index) => {
                return <div key={index}>
                    <AppointmentHistory appointment={appointment} is_last_appointment={!index}/>
                </div>
            })}
        </div>
    </div>
}

export default AppointmentsHistory;