import React, {Component} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios'
import Helper, {JOB} from "../../../Shared/Helper";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import {t} from "../../../I18n/i18n"

export default class Log extends Component {
    constructor(props) {
        super(props)
        this.loadData = this.loadData.bind(this)
        this.fetchMoreData = this.fetchMoreData.bind(this)
        this.state = {
            page: 1,
            data: [],
            request_state: RequestState.LOADING
        }
        this.loadData(1);
    }

    loadData(page) {
        axios.get("/admin/logs/appointments/data?page=" + page)
            .then((data) => {
                console.log(data)
                this.setState({
                    page: page + 1,
                    data: this.state.data.concat(data.data),
                    request_state: RequestState.SUCCESS,
                })
            })
            .catch((error) => {
                this.setState({request_state: RequestState.ERROR})
            })
    }

    fetchMoreData() {
        this.loadData(this.state.page)
    }

    render() {
        const history = this.state.data
        if (this.state.request_state === RequestState.LOADING) return <Loader/>
        if (this.state.request_state === RequestState.ERROR) return <div
            className="center">{t("OPHTALMO_HISTORY.ERROR_HAS_OCCURED")}</div>
        if (this.state.request_state === RequestState.SUCCESS) return (
            <div>
                <InfiniteScroll
                    dataLength={this.state.data.length}
                    next={this.fetchMoreData}
                    hasMore={true}
                    loader={<h4 className="center">{t("OPHTALMO_HISTORY.LOADING")}</h4>}
                >
                    <div className="container">
                        <h1 className="center">{t("OPHTALMO_HISTORY.TITLE")}</h1>
                        <table className="striped bordered">
                            <thead>
                            <tr>
                                <th className="center"> {t("DATE")}</th>
                                <th className="center"> {t("PATIENT")}</th>
                                <th className="center"> {JOB}</th>
                                <th className="center"> {t("STATUS")}</th>
                                <th className="center"> {t("OPHTALMO_HISTORY.SMS_SENT")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {history.map((appointment, index) => (
                                <tr key={index}>
                                    <td>{Helper.formatDate(appointment.start, "DD-MM-YYYY h:mm")}</td>
                                    <td>
                                        <a className="btn-flat blue-text text-darken-2"
                                           href={"/patients/" + appointment.patient.id} target="_blank">
                                            {Helper.formatName(appointment.patient.prenom, appointment.patient.nom)}
                                        </a>
                                    </td>
                                    <td>{Helper.formatName(appointment.orthoptiste.nom, appointment.orthoptiste.prenom)}</td>
                                    <td><StatusApp statut={appointment.status_controle}
                                                   unlock={appointment.override_lock}/></td>
                                    <td>

                                        {appointment.sms ? (
                                            <span><i
                                                className="material-icons left orange-text">more_time</i> {t("PENDING")}</span>
                                        ) : (
                                            <span><i
                                                className="material-icons left green-text">done</i> {t("SENT")}</span>

                                        )}
                                    </td>

                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>

                </InfiniteScroll>

            </div>
        );
    }
}


export class StatusApp extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {statut, unlock} = this.props;
        if (statut == 0) {

            return (
                <React.Fragment>
                    <i className="material-icons left">announcement</i> N/A
                </React.Fragment>
            );
        } else if (statut == 1) {
            return (
                <React.Fragment>
                    <i className="material-icons left orange-text">pending</i> {t("OPHTALMO_HISTORY.PROCESS_OF_VALIDATION")}
                </React.Fragment>
            );
        } else if (statut == 2) {
            return (
                <React.Fragment>
                    <i className="material-icons left green-text">done</i> {t("OPHTALMO_HISTORY.VALIDATED_AND_ACCESSIBLE")}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <i className="material-icons left red-text">report_problem</i> {t("ALERT")}
                    {unlock ? (
                        <span> {t("OPHTALMO_HISTORY.BUT_UNLOCK")}</span>
                    ) : (
                        <span></span>
                    )}
                </React.Fragment>
            );
        }
    }

}
