import React, {useEffect, useRef, useState} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from "mapbox-gl-geocoder";
import mapboxgl from '!mapbox-gl';

mapboxgl.accessToken = process.env.MAPBOX_TOKEN

function Mapbox({defaultCoordinates, onChange}) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lat, setLat] = useState(defaultCoordinates?.lat ?? 50.6277);
    const [lng, setLng] = useState(defaultCoordinates?.lng ?? 3.0646);
    const [zoom, setZoom] = useState(12);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
        });

        // set coordinates on search
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: "Chercher une adresse",
        })
        map.current.addControl(geocoder, 'top-left');

        // set coordinates on search result
        geocoder.on('result', (e) => {
            setLng(e.result.center[0])
            setLat(e.result.center[1])
        });

        map.current.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }), 'bottom-right');


        map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        // map.current.addControl(new mapboxgl.FullscreenControl(), 'top-right');


        // change cursor to pointer when hovering over a pin
        map.current.getCanvas().style.cursor = 'pointer';

        // add pin on click
        map.current.on('click', (e) => {
            setLng(e.lngLat.lng);
            setLat(e.lngLat.lat);
        });
    }, []);

    useEffect(() => {
        // add pin on current selected location
        const marker = new mapboxgl.Marker({
            draggable: true
        }).setLngLat([lng, lat]).addTo(map.current);

        onChange({lng: lng, lat: lat})
        return () => marker.remove();
    }, [lng, lat]);

    return <div ref={mapContainer} className="map-container" style={{height: "100%", width: "100%", borderRadius: 3}}/>
}

Mapbox.defaultProps = {
    onChange: () => null,
    // defaultCoordinates: [50.6357, 3.0545]
    defaultCoordinates: {lat: 50.6357, lng: 3.0545}
}


export default Mapbox;
