import React, {useState} from 'react';
import axios from "axios";
import {toast} from "react-toastify";

const LoginPage = () => {
    const [rpps, setRpps] = useState("");
    const [key, setKey] = useState("");



    const login = () => {
        axios.get("/prescriptors/login", {params: {rpps, key}}).then(res => {
            window.location.href = "dashboard";
        }).catch(err => {
            toast.error("Identifiants incorrects")
        })
    }

    return <div id="full-height" className="center valign-wrapper" style={{justifyContent: "center"}}>
        <div style={{maxWidth: 700, textAlign: "center", paddingBottom: 200}}>
            <h1 style={{fontSize: "2.4rem", marginBlock: 10}}>Accès aux comptes rendus de vos patients</h1>
            <h4 style={{fontSize: "1.2rem", marginBlock: 20, paddingBottom: 50, color: "#5f6368"}}>
                Vous avez récemment adressé l'un de vos patients vers un orthoptiste. Retrouvez son compte rendu sur
                votre espace Temeoo.
            </h4>
            <div className="valign-wrapper" style={{justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "column", gap: 10, alignItems: "center"}}>
                    <div className="input-field outlined" style={{maxWidth: 300}}>
                        <input id="rpps" type="text" className="validate" value={rpps}
                               onChange={(e) => {
                                   setRpps(e.target.value.replace(" ", ""))
                               }}/>
                        <label htmlFor="rpps">N° RPPS</label>
                    </div>
                    <div className="input-field outlined" style={{maxWidth: 300}}>
                        <input id="key" type="text" className="validate" value={key}
                               onChange={(e) => {
                                   setKey(e.target.value.replace(" ", ""))
                               }}/>
                        <label htmlFor="key">Clé d'accès</label>
                    </div>
                    <a style={{maxWidth: 100, marginLeft: 10}} className={`btn blue darken-2 z-depth-0`}
                       onClick={login}>Connexion</a>
                </div>


            </div>
        </div>
    </div>
}

export default LoginPage;