import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import AddPatient from "../AddPatient";
import EditPatient from "../EditPatient";
import useFilter from "../Hooks/useFilter";

const _ = require('lodash')
const ListPatients = observer(() => {
    const nav = useNavigate();
    const [onEditPatient, setOnEditPatient] = useState(undefined)
    const filters = ["firstname", "lastname", "ssn", "email", "telephone", "regime_code", "fund_code", "centre_code"]
    const {filteredArray: filteredPatients, searchBar} = useFilter("", MedicalEventStore.patients, filters)

    const renderPortalKey = (patient) => {
        if (!patient.portal_key) return null
        return <a className="btn-flat btn-small blue lighten-5 truncate" style={{width: '100%'}}
                  onClick={() => navigator.clipboard.writeText(patient.portal_key)}
                  title="Clé de connection au portail patient">
            {patient.portal_key}
        </a>
    }

    const renderPatient = (patient) => {
        const isDone = patient.done
        const hasReport = !_.isEmpty(patient.report)
        return <tr className="highlighted">
            <td width={130}>{renderPortalKey(patient)}</td>
            <td>{patient.act_name ?? "Non renseigné"}</td>
            <td>{Helper.formatName(patient.firstname, patient.lastname)}</td>
            <td>Né(e)
                le {patient.birthday} ({Helper.dateToAge(patient.birthday)} ans)
            </td>
            <td>{Helper.formatSsn(patient.ssn)}</td>
            <td>{patient.email}</td>
            <td>{patient.telephone}</td>
            <td>
                {Helper.formatPatientCodes(patient.regime_code, patient.fund_code, patient.centre_code)}
            </td>
            <td>{patient.health_insurance}</td>
            <td style={{width: 350}}>
                <div style={{display: "flex", justifyContent: "right"}}>
                    <a className={`btn-small ${hasReport ? "green" : "blue"} darken-2 z-depth-0 truncate`}
                       onClick={() => {
                           nav(`/appointment/${patient.appointment_id}/report`)
                       }}>
                        <i className="material-icons left">{hasReport ? "done" : "description"}</i>
                        Questionnaire
                    </a>
                    <a className={`btn-small ${isDone ? "green" : "blue"} darken-2 z-depth-0 truncate`}
                       onClick={() => {
                           nav(`/appointment/${patient.appointment_id}`)
                       }}>
                        <i className="material-icons left">{isDone ? "done" : "description"}</i>
                        {isDone ? "Réexaminer" : "Examiner"}
                    </a>

                    <a className="btn-small orange darken-4 z-depth-0 truncate"
                       onClick={() => setOnEditPatient(patient)}>
                        <i className="material-icons">edit</i>
                    </a>
                    <a className="btn-small red darken-2 z-depth-0 truncate"
                       onClick={() => MedicalEventStore.removePatient(patient)}>
                        <i className="material-icons">delete</i>
                    </a>
                </div>
            </td>
        </tr>
    }

    const renderPatients = () => {
        if (_.isEmpty(filteredPatients)) {
            return <div className="center-align" style={{paddingTop: 50}}>
                <h5>Aucun patient test n'est affecté à l'évènement, rajoutez-en ci-dessus</h5>
            </div>
        }


        return <table className="temeoo-table">
            <thead>
            <tr>
                <th>Cle espace patient</th>
                <th>Acte</th>
                <th>Nom</th>
                <th>Date de naissance</th>
                <th>Numéro de sécurité sociale</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Code organisme</th>
                <th>Mutuelle</th>
            </tr>
            </thead>
            <tbody>
            {filteredPatients.map((patient) => renderPatient(patient))}
            </tbody>
        </table>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 40}}>
        {onEditPatient ?
            <EditPatient patient={onEditPatient}
                         onSuccess={() => setOnEditPatient(null)}
                         onCancel={() => setOnEditPatient(null)}
            />
            : <AddPatient/>}
        {searchBar}
        {renderPatients()}
    </div>
})

export default ListPatients