import React from 'react';
import {t} from "../../../I18n/i18n";
import MoreButton from "../../../Shared/UiKit/MoreButton";
import {IS_LEVEL_2} from "../../../Shared/Helper";
import {Act} from "../../../Shared/Enums/Enums";
import axios from "axios";

const ActionButtonsOphtalmo = ({appointment, appointmentChanged}) => {
    if (!IS_LEVEL_2) return null

    const reworkable = ([2, 3].includes(appointment.status_controle))
        && appointment.act?.titre !== Act.ONLY_PRESCRITPION

    const isAbsent = appointment.act?.titre.trim() === Act.NOTHING || appointment.status_controle <= 0


    const documentsButton = <a className={`btn-small green darken-2 z-depth-0`}
                               target={"_blank"} href={`documents/${appointment.id}`}>
        <i className="material-symbols-outlined left">table_eye</i>
        {appointment.document_count > 1 ?
            `${appointment.document_count} ${t("EXAM_HISTORY.DOCUMENTS_AVAILABLE")}`
            :
            `${appointment.document_count} ${t("EXAM_HISTORY.DOCUMENT_AVAILABLE")}`}
    </a>

    const synthesisButton = <a className="btn-small blue darken-2 z-depth-0"
                               href={`/syntheses/rendez-vous/${appointment.id}`}
                               target="_blank"
                               title="Consulter la synthèse du rendez-vous associé.">
        <i className="material-icons left">search</i>
        <span
            className="hide-on-med-and-down">{t("EXAM_HISTORY.CONSULT_SUMMARY")}</span>
    </a>

    const toggle_lock = () => {
        axios.post(`/admin/appointement/toggle_override_lock?id=${appointment.id}`).then(() => {
            appointmentChanged({...appointment, override_lock: !appointment.override_lock})
        })
    }

    const lockButton = () => {
        if(appointment.status_controle !== 3 ) return null
        return <a className={`btn-small btn-flat transparent z-depth-0`} onClick={toggle_lock} 
                  title={t(`EXAM_HISTORY.LOCK.${appointment.override_lock ? "UNLOCKED" : "LOCKED"}_TOOLTIP`)}>
            <i className="material-icons">{appointment.override_lock ? "lock_open" : "lock"}</i>
        </a>
    }
    const askForRework = (reason) => {
        reason = reason || ""
        axios.post(`/admin/appointement/rework?id=${appointment_id}`, {reason: reason}).then(() => {
            appointmentChanged({...appointment, status_controle: 0})
        })
    }

    const reworkButton = () => {
        if (!reworkable) return null
        return <a className={`btn-small z-depth-0`} onClick={askForRework}>
            <i className="material-icons left">undo</i>
            {t("EXAM_HISTORY.REWORK.TO_REWORK")}
        </a>
    }

    return <>
        <div>
            {lockButton()}
            <a className={`btn-small blue darken-2 z-depth-0 ${isAbsent ? "disabled" : ""}`}
               title={"Contrôler ce dossier"}
               href={`/admin/controle_rdv/${appointment.id}`}>
                <i className="material-icons">find_in_page</i>
            </a>
        </div>
        <MoreButton id={`patient_history_appt_${appointment.id}_btns`}
                    options={[documentsButton, reworkButton(), synthesisButton]}/>
    </>
}

export default ActionButtonsOphtalmo;