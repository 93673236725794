import React, {useEffect, useState} from "react";
import Loader from "../Shared/Loader";
import axios from "axios";
import Helper from "../Shared/Helper"
import {RequestState} from "../Shared/StateHelper";
import Toastr from "../Shared/Toastr";
import {toast} from "react-toastify"
import Tooltip from "../Shared/Tootip";
import {t} from "../I18n/i18n"


export default function EditAlertText(props) {
    const [original_alert_text_title, setOriginalAlertTextTitle] = useState("")
    const [alert_text_title, setAlertTextTitle] = useState("")
    const [alert_text_message, setAlertTextMessage] = useState("")
    const [request_state, setRequestState] = useState(RequestState.LOADING)

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
        getTextAlert()
    }, []);

    function isSubmitable() {
        return !(!alert_text_title || !alert_text_message);
    }

    function renderErrorTooltip() {
        let initial_text = t("ALERTS_PAGE.ELEMENT_MISSING")
        if (!alert_text_title) initial_text += t("ALERTS_PAGE.PLEASE_ENTER_TITLE")
        if (!alert_text_message) initial_text += t("ALERTS_PAGE.PLEASE_ENTER_MESSAGE")
        return initial_text
    }

    function getTextAlert() {
        axios.get(`/admin/text_alerts/${props.id}`)
            .then((response) => {
                setRequestState(RequestState.SUCCESS)
                setOriginalAlertTextTitle(response.data.text_alert.title)
                setAlertTextTitle(response.data.text_alert.title)
                setAlertTextMessage(response.data.text_alert.message)
            })
            .catch((error) => setRequestState(RequestState.SUCCESS))
    }

    function editMessage() {
        axios.patch(`/admin/text_alerts/${props.id}`, {
            title: alert_text_title,
            message: alert_text_message
        })
            .then((response) => {
                toast.success(t("ALERTS_PAGE.TOAST.EDIT_SUCCESSFUL"))
                setTimeout(() => document.location.href = "/admin/text_alerts/index", 2000)
            })
            .catch((error) => toast.error(t("ALERTS_PAGE.TOAST.ERROR_WHEN_DELETED")))
    }

    if (request_state === RequestState.LOADING) return <div className="center"><Loader
        text={t("RISK_ALERT_MANAGEMENT.LOADING")}
                                                                                       wide/></div>
    if (request_state === RequestState.ERROR) return <div className="center">{t("ALERTS_PAGE.AN_ERROR_OCCURRED")}</div>
    if (request_state === RequestState.SUCCESS) return (
        <div className="container">
            <h1 className="center">{t("ALERTS_PAGE.MESSAGE_EDITING")} : {Helper.capitalizeFirstLetter(original_alert_text_title)}</h1>
            <div className="row" style={{marginBottom: "2vh"}}>
                <div className="input-field col s12 outlined">
                    <h6>{t("ALERTS_PAGE.TITLE_PATHO")}</h6>
                    <input
                        id="title"
                        type="text"
                        placeholder={t("ALERTS_PAGE.ENTER_TITLE_PATHO")}
                        value={alert_text_title}
                        onChange={(e) => setAlertTextTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 outlined">
                    <h6 style={{marginTop: "0"}}>{t("ALERTS_PAGE.ALERT_MESSAGE")}</h6>
                    <textarea id="alert_message"
                              style={{minHeight: "150px", paddingTop: "1.5vh", resize: "none"}}
                              placeholder={t("ALERTS_PAGE.MESSAGE_RELATED_TO_PATHO")}
                              value={alert_text_message}
                              onChange={(e) => setAlertTextMessage(e.target.value)}
                    />
                </div>
            </div>
            {isSubmitable() ?
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <a className="center btn blue darken-2 z-depth-0"
                       onClick={editMessage}>{t("ALERTS_PAGE.UPDATE_MESSAGE")}</a>
                </div> :
                <Tooltip id="update_text_alert_tootip" text={renderErrorTooltip()}>
                    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                        <a className="center btn blue darken-2 z-depth-0 disabled"
                           onClick={editMessage}>{t("ALERTS_PAGE.UPDATE_MESSAGE")}</a>
                    </div>
                </Tooltip>
            }
            <Toastr/>
        </div>
    )
}