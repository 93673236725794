import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import MedicalEventForm from "./MedicalEventForm";
import useMedicalEvents from "./Hooks/useMedicalEvents";
import axios from "axios";

const CreateMedicalEvent = () => {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    const nav = useNavigate();
    const [medicalEvent, setMedicalEvent] = useState({name: "", date: undefined, time: undefined, guests: []});
    const {create} = useMedicalEvents(undefined, false);

    const canSave = () => {
        if(!medicalEvent.orthoptiste_id || !medicalEvent.ophtalmologiste_id) return false;
        return !!medicalEvent.name;
    }

    return <div style={{height: "100%", overflowY: "scroll"}}>
        <div className="valign-wrapper" style={{marginLeft: 10}}>
            <a className="btn-flat" style={{borderRadius: 20}} onClick={() => nav("/medical_events")}>
                <i className="material-icons">arrow_back</i>
            </a>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">event</i>
                Créer un nouvel évènement
            </h3>
            <a className={`btn blue darken-2 z-depth-0 ${canSave() ? "" : "disabled"}`}
                style={{marginInline: 20, marginLeft: "auto"}}
                onClick={() => create(medicalEvent).then(() => nav("/medical_events"))}
            >
                <i className={"material-icons left"}>save</i>
                Créer
            </a>
        </div>
        <div className="divider"/>
        <div className="row" style={{padding: 20, gap: 10, minHeight: 400}}>
            <MedicalEventForm medicalEvent={medicalEvent} onChange={setMedicalEvent}/>
        </div>
    </div>
}

export default CreateMedicalEvent;