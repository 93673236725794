import React, {Component} from 'react';
import {Link} from "react-router-dom"
import BridgeStore from "../BridgeStore"
import {observer} from "mobx-react"
import Strips from "../../Shared/Strips"
import InputRefracteur from "../Shared/InputRefracteur"

@observer
export default class Refractor extends Component {
  constructor(props) {
    super(props);
    this.init = this.init.bind(this)
    this.state = {
      scanner_int: null,
      initial: [],
      refracteur_localise: false,

      initialized: false,
      message: "Veuillez patienter, scan en cours"

    }
    setTimeout(()=>{
      this.init()
    },1000)

  }
  init(){
    var intervalId = setInterval(this.scan, 1000);
    this.setState({scanner_int: intervalId});
    BridgeStore.socket.on("scan_usb",(data)=>{
      if(!this.state.refracteur_localise){
      if(!this.state.initialized){
        this.setState({
          initial: JSON.parse(data),
          initialized: true,
          message: "Veuillez brancher le réfracteur dans le port usb, et veuillez l'allumer"
        })
        }else{

          let u0 = this.state.initial
          let u1 = JSON.parse(data)
          if(u0.length != u1.length){
            for(let usb of u1){
              if(!u0.includes(usb)){
                this.setState({
                  refracteur_localise: true,
                  message: "Réfracteur détecté"
                })
                BridgeStore.usb_refracteur = usb["port"]
                BridgeStore.uid_refracteur = usb["desc"]+" "+usb["hwid"]
                clearInterval(this.state.scanner_int);
              }
            }
          }
        }
      }

    })
    this.scan()

  }
  scan(){
    BridgeStore.socket.emit("scan_usb_server",{})
  }
   componentWillUnmount() {
     clearInterval(this.state.scanner_int);
   }

  render() {
    return (
      <div>
        <Strips>white orthoptiste</Strips>


        <h1 className="center">{this.state.message}</h1>
        {this.state.refracteur_localise ? (
            <TypeRefracteur />
          ) : (<div></div>)}
        <div className="container">
          {BridgeStore.uid_refracteur !="" ? (
            <p style={{textAlign: 'right'}}><Link to="Directory" className="btn btn-large mauve"> Continuer</Link></p>
            ) : (
              <span></span>
            )}
        </div>
      </div>
    );
  }
}

@observer
export class TypeRefracteur extends Component {
  render() {
    return (
      <div>

        <p className="center"> Le réfracteur a été détecté au port {BridgeStore.usb_refracteur} <br />
        avec comme identifiant (souvent) unique {BridgeStore.uid_refracteur}</p>
        <div className="container row">
          <InputRefracteur />
        </div>
      </div>
    );
  }
}
