import React from "react";
import axios from "axios";
import AlertCard from "./AlertCard"
import Loader from "../../Shared/Loader";
import {wrapper} from "../../I18n/i18n"

const {t} = wrapper("ALERTS_FOLLOW_UP")
export default class AlertPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification_list: null
        }

    }

    componentDidMount() {
        axios.get("list_notifications")
            .then((response) => {
                this.setState({notification_list: response.data.notifications})
            })
    }

    handleNotificationDeletion = (notification_id) => {
        this.setState({notification_list: this.state.notification_list.filter((notification) => notification.id !== notification_id)})
    }

    render() {
        return (
            <div style={{width: "90%", margin: "auto"}}>
                <h1 className="center">{t("ALERTS_FOLLOW_UP")}</h1>
                <div>
                    {this.state.notification_list ?
                        this.state.notification_list.length > 0 ?
                            this.state.notification_list.map((notification, index) => {
                                return <AlertCard key={index} notification={notification} handleNotificationDeletion={this.handleNotificationDeletion}/>})
                            : <div className="center">{t("NO_FOLLOW_UP")}</div>
                        : <Loader/>
                    }
                </div>
            </div>
        )
    }
}