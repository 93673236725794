import React from 'react';
import {t} from "../../I18n/i18n"

function EmptyConversation() {

    return <div id="full-height" style={{display: "flex", flexDirection: "column", paddingTop: 200}}>
        <h1 className="center" style={{marginBlock: 10}}>{t("CONVERSATION.NO_CONVERSATION")}</h1>
        <p className="center">{t("CONVERSATION.SELECT_NEW_CONVERSATION")}</p>
    </div>
}

export default EmptyConversation;