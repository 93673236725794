import React, {useState} from "react"
import axios from "axios";
import {toast} from "react-toastify";
import OrthoptistDocuments from "./OrthoptistDocuments";
import {ArsDocumentsType} from "../../../Shared/Enums/ArsDocumentsType";
import {downloadPDFFolders} from "../../../Shared/PDF/PDFManager";
import Helper from "../../../Shared/Helper";


const sortOrthoptists = (orthoptists) => {
    return orthoptists.sort((orthoptist_a, orthoptist_b) => {
        let orthoptist_a_full_signed = orthoptist_a.documents.every((document) => {
            return document.user_signatures.every((signature) => signature.date !== null)
        })

        let orthoptist_b_full_signed = orthoptist_b.documents.every((document) => {
            return document.user_signatures.every((signature) => signature.date !== null)
        })

        if (orthoptist_a_full_signed === orthoptist_b_full_signed) return 0
        if (orthoptist_a_full_signed && !orthoptist_b_full_signed) return 1
        return -1
    })
}

export default function OfficeDocuments(props) {

    const [office_location, setOfficeLocation] = useState({
        previous: props.ophtalmologist.city,
        current: props.ophtalmologist.city
    })

    const updateOfficeLocalization = () => {
        axios.patch(`/office_location/${props.office.office_id}`, {city: office_location.current})
            .then((_) => {
                let temp_location = {...office_location}
                temp_location.previous = temp_location.current
                setOfficeLocation(temp_location)
                toast.success("Enregistrement réussi.")
            })
            .catch((_) => toast.error("Erreur lors de l'enregistrement."))
    }

    const handleLocalizationChange = (new_location) => {
        let temp_localization = {...office_location}
        temp_localization.current = Helper.capitalizeFirstLetter(new_location)
        setOfficeLocation(temp_localization)
    }

    const validateLocalization = () => !!office_location.current && office_location.current.length > 0

    const localizationChanged = () => validateLocalization() && office_location.previous !== office_location.current

    const getSignatureDate = (document_signatures) => {
        let signature_count = document_signatures.map((signature) => signature.date !== null).filter(Boolean).length

        let signature_date
        if (signature_count === document_signatures.length) {
            signature_date = document_signatures.reduce((a, b) => {
                return new Date(a.date) > new Date(b.date) ? a : b;
            })
            signature_date = signature_date.date
        }
        if (document_signatures.length - signature_count === 1) signature_date = new Date()
        return signature_date
    }

    const getPdfData = (document, orthoptist) => {
        let signature_ophtalmologist = Helper.toCamelCase(`${props.ophtalmologist.first_name} ${props.ophtalmologist.last_name}`) ?? ""
        let signature_orthoptist = Helper.toCamelCase(`${orthoptist.first_name} ${orthoptist.last_name}`) ?? ""
        let signature_date = getSignatureDate(document.user_signatures)

        switch (document.document_type) {
            case ArsDocumentsType.EMPLOYER_ACCORD_6_15.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_16_50.document_type:
            case ArsDocumentsType.EMPLOYER_ACCORD_RETINO.document_type:
                return {
                    nom_employeur: Helper.toCamelCase(props.office.director_fullname) ?? "",
                    nom_ophtalmologiste1: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    ville_employeur: props.office.city ?? "",
                    nom_structure: props.office.name ?? "",
                    nom_orthoptiste1: Helper.formatName(orthoptist.first_name, orthoptist.last_name) ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_employeur: Helper.toCamelCase(props.office.director_fullname) ?? ""
                }

            case ArsDocumentsType.ENGAGEMENT_ACCORD_6_15.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_16_50.document_type:
            case ArsDocumentsType.ENGAGEMENT_ACCORD_RETINO.document_type:
                return {
                    nom_ophtalmo1: Helper.capitalizeFirstLetter(props.ophtalmologist.last_name) ?? "",
                    prenom_ophtalmo1: Helper.capitalizeFirstLetter(props.ophtalmologist.first_name) ?? "",
                    profession_delegant: "Ophtalmologiste",
                    profession_delegue: "Orthoptiste",
                    ville_ophtalmo: office_location.current ?? "",
                    rpps_ophtalmo1: props.ophtalmologist.rpps ?? "",
                    adeli_ortho1: orthoptist.adeli ?? "",
                    prenom_ortho1: Helper.capitalizeFirstLetter(orthoptist.first_name) ?? "",
                    nom_ortho1: Helper.capitalizeFirstLetter(orthoptist.last_name) ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophtalmo1: signature_ophtalmologist,
                    signature_ortho1: signature_orthoptist
                }

            case ArsDocumentsType.PREREQUISITE_VALIDATION_6_15.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_16_50.document_type:
            case ArsDocumentsType.PREREQUISITE_VALIDATION_RETINO.document_type:
                return {
                    nom_ophtalmologiste1: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    nom_ophtalmologiste1b: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    nom_orthoptiste1: Helper.formatName(orthoptist.first_name, orthoptist.last_name) ?? "",
                    ville_ophtalmo: office_location.current ?? "",
                    date: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophtalmo1: signature_ophtalmologist
                }

            case ArsDocumentsType.ACQUISITION_ATTESTATION_RETINO.document_type:
                return {
                    nom_ophtalmologiste1: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    ville_ophta: office_location.current ?? "",
                    date_ophta: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ophta: signature_ophtalmologist,
                    nom_orthoptiste1: Helper.formatName(orthoptist.first_name, orthoptist.last_name) ?? "",
                    nom_ophtalmologiste1b: Helper.formatName(props.ophtalmologist.first_name, props.ophtalmologist.last_name) ?? "",
                    ville_ortho: office_location.current ?? "",
                    date_ortho: signature_date ? Helper.formatDate(signature_date) : "",
                    signature_ortho: signature_orthoptist
                }

            default:
                return
        }
    }

    const formatAndDownloadDocuments = () => {
        const zip_data = props.office.orthoptists.map((orthoptist) => {
            return {
                type: "folder",
                name: `${Helper.formatName(orthoptist.first_name, orthoptist.last_name)}`,
                content: orthoptist.documents.map((document) => {
                    return {
                        type: "file",
                        name: `${Helper.formatName(orthoptist.first_name, orthoptist.last_name)} ${ArsDocumentsType[document.document_type].title}.pdf`,
                        uri: ArsDocumentsType[document.document_type].url,
                        content: getPdfData(document, orthoptist)
                    }
                })
            }
        })
        downloadPDFFolders(props.office.name, zip_data)
    }

    const areAllDocumentsSigned = () => {
        if(!props.office.orthoptists.length) return false
        return props.office.orthoptists.every((orthoptist) => {
            return orthoptist.documents.every((document) => {
                return document.user_signatures.every((signature) => signature.date !== null)
            })
        })
    }

    return <div key={`${props.ophtalmologist.user_id}_${props.office.office_id}`}>

        <div className="center" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <h4>{Helper.capitalizeFirstLetter(props.office.name) ?? "Nom du cabinet manquant"}</h4>
            <a className={`btn-small blue darken-2 ${areAllDocumentsSigned() ? "" : "disabled"}`}
               onClick={formatAndDownloadDocuments}
               style={{marginLeft: 10}}>
                <i className="material-icons">file_download</i></a>
        </div>
        {props.office.orthoptists.length ?
            sortOrthoptists(props.office.orthoptists).map((orthoptist) => {
                return <OrthoptistDocuments orthoptist={orthoptist}
                                            key={`${props.ophtalmologist.user_id}_${props.office.office_id}_${orthoptist.id}`}
                                            validateLocalization={validateLocalization}
                                            localizationChanged={localizationChanged}
                                            handleLocalizationChange={handleLocalizationChange}
                                            updateOfficeLocalization={updateOfficeLocalization}
                                            office={props.office}
                                            office_location={office_location}
                                            ophtalmologist={props.ophtalmologist}/>
            })
            : <div className="center" style={{paddingBottom: 25}}>Aucun orthoptiste n'existe dans ce cabinet.</div>
        }
    </div>
}