import React, {useEffect, useState} from "react"
import {HashRouter as Router, NavLink, Route, Routes} from "react-router-dom";
import {Role} from "../../Shared/Enums/Scope";
import {t} from "../../I18n/i18n";
import PersonalInformationPanel from "./GeneralPanels/PersonalInformationPanel";
import OfficePanel from "./GeneralPanels/OfficePanel";
import ReadingPanel from "./GeneralPanels/Reading/ReadingPanel";
import SecurityPanel from "./GeneralPanels/SecurityPanel";
import OpticianBillingPanel from "./GeneralPanels/Billing/Optician/OpticianBillingPanel";
import TwoFactorAuthPanel from "./GeneralPanels/TwoFactorAuthPanel";
import CustomizationPanel from "./GeneralPanels/CustomizationPanel";
import AddOphRef from "./GeneralPanels/AddOphRef";
import AddPrescripteur from "./GeneralPanels/AddPrescripteur";
import ManagementPanel from "./GeneralPanels/ManagementPanel";
import OrthoptistPanel from "./ArsTabs/OrthoptistPanel";
import OphtalmologistPanel from "./ArsTabs/OphtalmologistPanel";
import ScopedElement from "../../Shared/ScopedElement";
import BillingPanel from "./GeneralPanels/Billing/BillingPanel";
import Reports from "../../Orthoptistes/Reports/Reports";

export default function OptionRouter(props) {
    const [navCollapsed, setNavCollapsed] = useState(false);

    useEffect(() => {
        const nav = document.getElementById("options_sidenav")
        const instance = M.Sidenav.init(nav, {edge: "left", draggable: true})
        const collapsibles = document.querySelectorAll("[id=collapsibleOptionPanel]");
        const collapsible_instances = M.Collapsible.init(collapsibles, {});
        return () => {
            instance?.destroy();
            collapsible_instances.forEach(collapsible_instance => {
                collapsible_instance?.destroy();
            })
        }

    }, [navCollapsed]);

    const URL_PREFIX = {
        "Orthoptiste": "/orthoptistes",
        "Secretaire": "/secretaires",
        "Ophtalmologiste": "/ophtalmologistes",
        "Externe": "/externes",
        "Opticien": "/opticians"
    }
    const url_prefix = URL_PREFIX[props.user_type]

    const PERSONAL_INFO = {
        name: t("OPTIONS.PERSONAL_INFORMATIONS"),
        icon: "account_circle",
        path: "/personal_infos",
        scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.EXTERN, Role.OPTICIAN],
        element: <PersonalInformationPanel
            user_type={props.user_type}
            url_prefix={url_prefix}
            rpps={props.rpps}
            phone_number={props.phone_number}
            email={props.email}
            username={props.username}
            first_name={props.first_name}
            last_name={props.last_name}
            adeli={props.adeli}
            full_name={props.full_name}
            invoice_label={props.invoice_label}
            address={props.address}
            city={props.city}
            postal_code={props.postal_code}/>
    }
    const OFFICE = {
        name: t("OPTIONS.OFFICE.TITLE"),
        icon: "home",
        path: "/office",
        scope: [Role.ORTHOPTIST],
        element: <OfficePanel url_prefix={url_prefix} office={props.office}/>
    }
    const READING = {
        name: t("OPTIONS.READING.TITLE"),
        icon: "preview",
        path: "/reading",
        scope: [Role.OPHTALMOLOGIST],
        element: <ReadingPanel url_prefix={url_prefix}/>
    }
    const SECURITY = {
        name: t("OPTIONS.SECURITY"),
        icon: "security",
        path: "/security",
        scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.SECRETARY, Role.EXTERN, Role.OPTICIAN],
        element: <SecurityPanel url_prefix={url_prefix}/>
    }
    const BILLING = {
        name: t("OPTIONS.BILLING.TITLE"),
        icon: "credit_card",
        path: "/billing",
        scope: [Role.OPTICIAN, Role.ORTHOPTIST],
        element: <BillingPanel {...props} url_prefix={url_prefix}/>
    }
    const TWOFA_HANDLING = {
        name: t("OPTIONS.2FA_HANDLING"),
        icon: "qr_code_scanner",
        path: "/two_factor_auth",
        scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.SECRETARY],
        element: <TwoFactorAuthPanel
            url_prefix={url_prefix}
            activated={props.two_fa_activated}
            code_generated={props.two_fa_code_generated}
        />
    }
    const REPORTS = {
        name: t("OPTIONS.CUSTOM_REPORTS.TITLE"),
        icon: "edit_note",
        path: "/custom_reports",
        scope: [Role.ORTHOPTIST],
        element: <Reports/>
    }
    const PERSONAL_SHORTCUT = {
        name: t("OPTIONS.CUSTOMIZATION.TITLE"),
        icon: "account_box",
        path: "/customization",
        scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST],
        element: <CustomizationPanel/>
    }
    const ADD_OPH_REF = {
        name: t("OPTIONS.ADD_OPH_REF.TITLE"),
        icon: "group_add",
        path: "/add_oph_ref",
        scope: [Role.ORTHOPTIST],
        element: <AddOphRef/>
    }
    const ADD_PRESC = {
        name: "Prescripteurs",
        icon: "rate_review",
        path: "/add_prescripteur",
        scope: [Role.ORTHOPTIST],
        element: <AddPrescripteur prescripteurs={props.prescripteurs}/>
    }
    const MANAGE_EXAM_RISK = {
        name: t("POSSIBLE_EXAM.NAV_TITLE"),
        icon: "medical_information",
        path: "/management",
        scope: [Role.OPHTALMOLOGIST],
        element: <ManagementPanel/>
    }
    const ARS_DOCUMENTS = {
        name: "Documents ARS",
        icon: "draw",
        scope: [Role.OPHTALMOLOGIST, Role.ORTHOPTIST],
        path: "/ars_documents",
        element: props.user_type === Role.ORTHOPTIST ? <OrthoptistPanel data={props}/> :
            <OphtalmologistPanel data={props}/>
    }

    const categories = {
        HOME: {
            name: "Configuration",
            entries: [
                PERSONAL_INFO, OFFICE, READING, SECURITY, TWOFA_HANDLING
            ],
            active: true
        },
        BILLING: {
            // name: "Facturation",
            name: undefined,
            entries: [BILLING],
            scope: [Role.ORTHOPTIST, Role.OPTICIAN]
        },
        ARS_DOCUMENTS: {
            name: undefined,
            entries: [
                ARS_DOCUMENTS
            ],
            scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
        },
        MANAGE: {
            name: "Gestion",
            entries: [
                REPORTS, PERSONAL_SHORTCUT, MANAGE_EXAM_RISK, ADD_OPH_REF, ADD_PRESC
            ],
            active: true,
            scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
        }
    };

    const renderNav = () => {
        return <div className="fc" style={{height: "100%", position: "relative", marginTop: 10}}>
            <a className="btn-flat btn-small transparent" style={{position: "absolute", top: 0, right: 0}}
               onClick={() => setNavCollapsed(true)}>
                <i className="material-symbols-outlined">left_panel_close</i>
            </a>
            <ul className="white temeoo-nav"
                style={{marginTop: 0, height: "100%"}}>
                {Object.values(categories).map((category, index) => {
                    if (!category.name) {
                        return [
                            ...category.entries.map((entry, entryIndex) => (
                                <ScopedElement scope={entry.scope}
                                               key={`console_entry_${entry.name}_${entryIndex}`}>
                                    <li className="navlink">
                                        <NavLink className="btn-flat" to={entry.path}>
                                            <i style={{margin: "0 12px 0 0"}}
                                               className="material-icons left">{entry.icon}</i>
                                            {entry.name}
                                        </NavLink>
                                    </li>
                                </ScopedElement>
                            )),
                            <ScopedElement scope={category.scope}>
                                <li key={`divider_noname_${index}`}>
                                    <div className="divider" style={{margin: "0 0"}}></div>
                                </li>
                            </ScopedElement>
                        ];
                    }
                    return (
                        <ScopedElement scope={category.scope} key={`console_category_${category.name}`}>
                            <li>
                                <ul className="collapsible collapsible-accordion"
                                    id="collapsibleOptionPanel">
                                    <li className={category.active ? "active" : ""}>
                                        <a className="collapsible-header grey-text text-darken-2">
                                            {category.name}
                                            <i className="material-icons">arrow_drop_down</i>
                                        </a>
                                        <ul className="collapsible-body temeoo-collapsible-nav">
                                            {category.entries.map((entry, entryIndex) => (
                                                <ScopedElement scope={entry.scope}
                                                               key={`console_entry_${entry.name}_${entryIndex}`}>
                                                    <li className="navlink">
                                                        <NavLink className="btn-flat" to={entry.path}>
                                                            <i className="material-icons left">{entry.icon}</i>
                                                            {entry.name}
                                                        </NavLink>
                                                    </li>
                                                </ScopedElement>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <ScopedElement scope={category.scope}>
                                <li>
                                    <div className="divider" key={`divider_${category.name}`}
                                         style={{margin: "0 0"}}></div>
                                </li>
                            </ScopedElement>
                        </ScopedElement>
                    );
                })}
            </ul>
        </div>
    }

    const renderCollapsedNav = () => {
        return <div className="fc" style={{height: "100%"}}>
            <div className="fr" style={{justifyContent: "right"}}>
                <a className="btn-flat btn-small transparent" style={{marginInline: 0}}
                   onClick={() => setNavCollapsed(false)}>
                    <i className="material-symbols-outlined">left_panel_open</i>
                </a>
            </div>
            <ul className="white temeoo-nav collapsed"
                style={{marginTop: 0, height: "100%", overflowX: "clip"}}>
                {Object.values(categories).map((category, index) => {
                    return [
                        ...category.entries.map((entry, entryIndex) => (
                            <ScopedElement scope={entry.scope}
                                           key={`console_entry_${entry.name}_${entryIndex}`}>
                                <li className="navlink">
                                    <NavLink className="btn-flat" to={entry.path}
                                             title={entry.name}>
                                        <i style={{margin: "0 12px 0 0"}}
                                           className="material-icons">{entry.icon}</i>
                                    </NavLink>
                                </li>
                            </ScopedElement>
                        )),
                        <li key={`divider_noname_${index}`}>
                            <div className="divider" style={{margin: "10px 0"}}></div>
                        </li>
                    ];
                })}
            </ul>
        </div>
    }

    return <Router>
        <div id="full-height" style={{display: "flex"}}>
            <div style={{boxShadow: "2px 0 0 0 #E4E4E4"}}>
                {navCollapsed ? renderCollapsedNav() : renderNav()}
            </div>
            <div style={{width: "100%", height: "100%", overflowY: "scroll"}}>
                <Routes>
                    {Object.values(categories).map((category) => {
                        return category.entries.map((entry) => {
                            return <Route key={entry.path} path={entry.path} element={entry.element}/>
                        })
                    })}
                </Routes>
            </div>
        </div>

    </Router>
}
