import React, {useState} from 'react';
import useGuests from "./Hooks/useGuests";
import {GuestTypes} from "./Enums/Enums";
import {t, wrapper} from "../../../I18n/i18n";

const {t: w} = wrapper("MEDICAL_EVENTS.CONSOLE.ADD_GUEST")

const CreateGuest = ({onCreated}) => {
    const defaultGuest = {firstname: "", lastname: "", email: "", telephone: ""}
    const [guest, setGuest] = useState(defaultGuest)
    const {create} = useGuests()

    const canCreate = () => {
        return guest.firstname && guest.lastname && guest.email && guest.user_type
    }

    const createGuest = () => {
        if (!canCreate()) return
        create(guest).then(response => {
            onCreated(response.data)
            setGuest(defaultGuest)
        })
    }

    return <div style={{flex: 1, display: "flex", gap: 20, alignItems: "center"}}>
        <div className="row" style={{flex: 1, gap: "10px 10px"}}>
            <div className="input-field col s6 outlined">
                <input id="first_name" type="text" value={guest.firstname}
                       onChange={e => setGuest({...guest, firstname: e.target.value})}/>
                <label htmlFor="first_name">{t("FIRSTNAME")} (*)</label>
            </div>
            <div className="input-field col s6 outlined">
                <input id="last_name" type="text" value={guest.lastname}
                       onChange={e => setGuest({...guest, lastname: e.target.value})}/>
                <label htmlFor="last_name">{t("LASTNAME")} (*)</label>
            </div>
            <div className="input-field col s6 outlined">
                <input id="email" type="email" value={guest.email}
                       onChange={e => setGuest({...guest, email: e.target.value})}/>
                <label htmlFor="email">{t("EMAIL")} (*)</label>
            </div>
            <div className="input-field col s6 outlined">
                <input id="phone" type="tel" value={guest.telephone}
                       onChange={e => setGuest({...guest, telephone: e.target.value})}/>
                <label htmlFor="phone">{t("PHONE_NUMBER")}</label>
            </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <select className="browser-default" value={guest.user_type ?? ""}
                    onChange={e => setGuest({...guest, user_type: e.target.value})}>
                <option value="" disabled selected>{w("CHOOSE_ROLE")}</option>
                {Object.values(GuestTypes).map(type => <option key={type} value={type}>{w(`ROLE.${type}`)}</option>)}
            </select>
            <a className={`btn blue darken-2 z-depth-0 ${canCreate() ? "" : "disabled"}`} onClick={createGuest}>
                <i className="material-icons left">add</i>
                {t("ADD")}
            </a>
        </div>

    </div>
}

export default CreateGuest;