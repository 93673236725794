import React, {useEffect, useState} from "react";
import {formatAcuities, formatRetinos} from "../../Appointments/Mesures/mesure_components/mesures";
import {ActNature, Mesures, Side} from "../../Shared/Enums/Enums";
import BrandedElement from "../../Shared/BrandedElement";
import {t} from "../../I18n/i18n"
import MedicalReport from "../../admin/Controle/Report/MedicalReport";
import telemedical_questions_json
    from "../../Appointments/Mesures/mesure_components/Questions/telemedical_questions.json";

export default function ExamRecapTabs(props) {
    // const [displayed_prescription, setDisplayedPrescription] = useState(props.appointment.main_prescription)

    useEffect(() => {
        const instances = M.Tabs.init(document.getElementsByClassName(`tabs apt${props.appointment.id}`))
        return () => instances[0].destroy()
    }, []);

    const renderQuestions = () => {
        const report = props.appointment.report
        if (!report) return null
        return <MedicalReport report={report} json={telemedical_questions_json}/>
    }

    // function renderTension(measure) {
    //     return <div style={{display: "flex", paddingBottom: "10px"}}>
    //         <div style={{width: "150px"}}>{measure.title}:</div>
    //         <div style={{display: "flex", flexDirection: "column"}}>
    //             {measure.od_tension ?
    //                 <div style={{margin: 0}}>OD : {measure.od_tension < 0 ? "NM" : measure.od_tension}</div> : null}
    //             {measure.og_tension ?
    //                 <div style={{margin: 0}}>OG : {measure.og_tension < 0 ? "NM" : measure.og_tension}</div> : null}
    //         </div>
    //     </div>
    //
    // }
    //
    // function renderPachymetry(measure) {
    //     return <div style={{display: "flex", paddingBottom: "10px"}}>
    //         <div style={{width: "150px"}}>{measure.title}:</div>
    //         <div style={{display: "flex", flexDirection: "column"}}>
    //             {measure.od_pachymetrie ? <div style={{margin: 0}}>OD : {measure.od_pachymetrie} µm</div> : null}
    //             {measure.og_pachymetrie ? <div style={{margin: 0}}>OG : {measure.og_pachymetrie} µm</div> : null}
    //         </div>
    //     </div>
    // }
    //
    // function renderMesure(measure) {
    //     return <div style={{display: "flex", paddingBottom: "10px"}}>
    //         <div style={{width: "150px"}}>{measure.title}:</div>
    //         <div style={{display: "flex", flexDirection: "column"}}>
    //             <div style={{margin: 0}}>
    //                 OD : {formatRetinos(measure, Side.RIGHT)} {formatAcuities(measure, Side.RIGHT, true)}
    //             </div>
    //             <div style={{margin: 0}}>
    //                 OG : {formatRetinos(measure, Side.LEFT)} {formatAcuities(measure, Side.LEFT, true)}
    //             </div>
    //         </div>
    //     </div>
    // }

    // const renderSummarise = () => {
    //     return <>
    //         {props.appointment.alert && props.act === "RNM" || props.act === "RNO" ? (
    //             <div className="row">
    //                 <i className="material-icons red-text center">report_problem</i>
    //                 <p className="col s11" style={{ margin: 0 }}>{props.appointment.alert.comment.gravite} - {props.appointment.alert.comment.conclusion_text}</p>
    //             </div>
    //         ) : null}
    //         {props.appointment.comment ? (
    //             <div data-cy="AnamnesisContainer">
    //                 <b>{t("ANAMNESIS") + " : "}</b>{props.appointment.comment}
    //             </div>
    //         ) : ""}
    //         {props.appointment.orthoptic_text ? (
    //             <BrandedElement temeoo>
    //                 <div data-cy="OrthopticTextContainer">
    //                     <b>{t("ORTHOPTIC") + " : "}</b>{props.appointment.orthoptic_text}
    //                 </div>
    //             </BrandedElement>
    //         ) : ""}
    //         {props.appointment.ophtalmo_text ? (
    //             <div>
    //                 <b>Commentaire ophtalmologiste : </b>{props.appointment.ophtalmo_text}
    //             </div>
    //         ) : ""}
    //         {props.appointment.private_comment ? (
    //             <div data-cy="PrivateCommentContainer">
    //                 <b>{t("PRIVATE_COMMENT") + " : "}</b>{props.appointment.private_comment}
    //             </div>
    //         ) : ""}
    //         {props.appointment.main_prescription ? (
    //             <div data-cy="PrescriptionContainer">
    //                 <b>Prescription : </b>{props.appointment.main_prescription.text}
    //             </div>
    //         ) : ""}
    //         {props.appointment.secondary_prescriptions.length > 0 ? (
    //             <div data-cy="PrescriptionContainer">
    //                 <b>Prescription secondaire : </b>{props.appointment.secondary_prescriptions[0].text}
    //             </div>
    //         ) : ""}
    //         {props.appointment.secondary_prescriptions.length === 2 ? (
    //             <div data-cy="PrescriptionContainer">
    //                 <b>Prescription tertiaire : </b>{props.appointment.secondary_prescriptions[1].text}
    //             </div>
    //         ) : ""}
    //     </>
    // }

    const renderImgs = (array) => {
        return <div>
            {array.map((img, index) => (
                <div key={index}>
                    <a href={img} target="_blank">Image n°{index + 1}</a>
                </div>
            ))}
        </div>
    }

    // function chooseMeasureRender(measure) {
    //     switch (measure.title) {
    //         case Mesures.TENSION.name :
    //             return renderTension(measure);
    //         case Mesures.PACHYMETRY.name :
    //             return renderPachymetry(measure);
    //         default :
    //             return renderMesure(measure);
    //     }
    // }

    return <td colSpan={5}>
        <div style={{paddingLeft: "10px"}}>
            <ul className={`tabs apt${props.appointment.id}`}
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "10px"
                }}>
                <li className="tab col s6"><a className="active"
                                              href={`#apt${props.appointment.id}_general_infos`}>{t("EXAM_HISTORY.GENERAL_INFO")}</a>
                </li>
            </ul>
            <div id={`apt${props.appointment.id}_general_infos`} className="row" style={{paddingInline: 50}}>
                <div className="col s6" style={{display: "flex", flexDirection: "column", gap: 10, fontSize: 12.5}}>
                    {renderSummarise()}
                    {renderQuestions()}
                    {props.appointment.media ?
                        renderImgs(props.appointment.media)
                        : null
                    }

                </div>
                <div className="col s6"
                     style={{display: "flex", flexDirection: "column", gap: 20, marginLeft: "15vh", fontSize: 12.5}}>
                    {!props.appointment.measures || props.appointment.measures.length === 0 ?
                        <div data-cy="MeasuresContainer">Aucune mesures disponibles</div> :
                        <div data-cy="MeasuresContainer">
                            {props.appointment.measures.map((measures) => <div style={{paddingBottom: "2px"}}>
                                {chooseMeasureRender(measures)}
                            </div>)}
                        </div>
                    }
                </div>
            </div>
        </div>
    </td>
}