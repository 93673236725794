import React, {Component} from 'react';

export default class Patient extends Component {
  render() {
    return (
      <div>


      </div>
    );
  }
}
