import React, {useEffect} from "react";
import axios from "axios";
import {wrapper} from "../../../../I18n/i18n";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Preferences from "../../../../Shared/Preferences/Preferences";
import {toast} from "react-toastify";


const ReadingPanel = ({url_prefix}) => {
    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    const {t: w} = wrapper("OPTIONS.READING")
    const [acts, setActs] = React.useState([])
    const [offices, setOffices] = React.useState([])
    const [preferences, setPreferences] = React.useState({})
    const [selectedOfficeId, setSelectedOfficeId] = React.useState(null)

    useEffect(() => {
        getActs()
    }, [])

    const getActs = () => {
        axios.get(`${url_prefix}/administration/acts`).then((response) => {
            setActs(response.data.acts)
            setOffices(response.data.offices)
            setPreferences(response.data.accepted_acts)
        }).catch((_) => {
            toast.error(w("ERROR"))
        })
    }

    const save = (pref) => {
        Preferences.set({accepted_acts_ids: pref}).then(() => {
            setPreferences(pref)
        }).catch((_) => {
            toast.error(w("ACCEPTED_ACTS.ERROR"))
        })
    }

    const elemToOption = (elem) => {
        return {
            label: elem.name,
            value: elem.id
        }
    }

    const renderOffice = (office) => {
        return <div key={office.id} style={{display: "flex", flexDirection: "column", gap: 10}}>
            <h5 style={{margin: 0}}>{office.name}</h5>
            <div className="valign-wrapper" style={{gap: 10}}>
                <Autocomplete
                    data-cy="OfficeAcceptedActsAutocomplete"
                    disablePortal
                    id={`office_accepted_acts_autocomplete_${office.id}`}
                    multiple
                    value={acts.filter((act) => preferences[office.id].includes(act.id)).map((act) => elemToOption(act))}
                    options={acts.map((act) => elemToOption(act)) ?? []}
                    onChange={(e, v) => {
                        const ids = v.map((a) => a.value)
                        const pref = {...preferences, [office.id]: ids}
                        save(pref)
                    }}
                    sx={{width: "100%"}}
                    renderInput={(params) => <TextField {...params} size="small"
                                                        label={w("ACCEPTED_ACTS.PLACEHOLDER")}/>}
                />
                <a className="btn-small btn-floating red darken-2 z-depth-0" onClick={() => {
                    const pref = {...preferences}
                    delete pref[office.id]
                    save(pref)
                }}>
                    <i className="material-icons white-text">delete</i>
                </a>
            </div>
        </div>
    }

    return <div className="container"
                style={{width: "100%", padding: 20, height: "100%", display: "flex", flexDirection: "column", gap: 20}}>
        <h1 className="center" style={{margin: 0}}>{w("TITLE")}</h1>
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <h3 style={{margin: 0}}>{w("ACCEPTED_ACTS.TITLE")}</h3>
            <label>{w("ACCEPTED_ACTS.DESCRIPTION")}</label>
            <Autocomplete
                data-cy="AcceptedActsAutocomplete"
                disablePortal
                id="accepted_acts_autocomplete"
                multiple
                value={acts.filter((act) => preferences.all?.includes(act.id)).map((act) => elemToOption(act))}
                options={acts.map((act) => elemToOption(act)) ?? []}
                onChange={(e, v) => {
                    const ids = v.map((a) => a.value)
                    const pref = {...preferences, "all": ids}
                    save(pref)
                }}
                sx={{width: "100%"}}
                renderInput={(params) => <TextField {...params} label={w("ACCEPTED_ACTS.PLACEHOLDER")}/>}
            />
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <h3 style={{margin: 0}}>{w("BY_OFFICE.TITLE")}</h3>
            <label>{w("BY_OFFICE.DESCRIPTION")}</label>
            <div className="valign-wrapper" style={{height: 50, marginLeft: "auto", gap: 10}}>
                <Autocomplete
                    data-cy="OfficeAutocomplete"
                    disablePortal
                    id="office_autocomplete"
                    options={offices.map((office) => elemToOption(office)) ?? []}
                    onChange={(e, v) => setSelectedOfficeId(v?.value)}
                    sx={{width: "300px"}}
                    renderInput={(params) => <TextField {...params} size="small"
                                                        label={w("BY_OFFICE.ADD_OFFICE.PLACEHOLDER")}/>}
                />
                <a className="btn btn-floating blue darken-2 z-depth-0" onClick={() => {
                    if (selectedOfficeId) {
                        const pref = {...preferences, [selectedOfficeId]: []}
                        save(pref)
                    }
                }}>
                    <i className="material-icons white-text">add</i>
                </a>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                {Object.keys(preferences).filter((key) => key !== "all").map((key) => {
                    const office = offices.find((office) => office.id === parseInt(key))
                    return renderOffice(office)
                })}
            </div>
        </div>
    </div>

}

export default ReadingPanel