import React, {useState} from "react";
import {PractitionerTypes, SpecialtyCodes} from "../../../Shared/Enums/Enums";
import {t} from "../../../I18n/i18n";
import {toast} from "react-toastify";
import axios from "axios";
import CSVButtonComponent from "../../../Shared/Search/Patients/CSVButtonComponent";
import {downloadFile} from "../../../Shared/download";
import Helper from "../../../Shared/Helper";
import Validator from "../../../Shared/Validator";
import r from "../../../Shared/Validation/Regex";
import PrescriptorForm from "./PrescriptorForm";
import EditPrescriptorButton from "./EditPrescriptorButton";

export default function AddPrescripteur(props) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const [prescriptors, setPrescriptors] = useState(props.prescripteurs)

    const downloadCSV = () => {
        axios.get("/prescripteurs/get_prescriptor_csv", {})
            .then(response => downloadFile(response.data, 'liste_prescripteurs.csv'))
    }

    const validateForm = ({lastname, firstname, rpps, adeli, specialty_code, practitioner_type, email}) => {
        if (!lastname.value || lastname.value.length === 0) {
            toast.error("Le champ Nom est obligatoire")
            return false
        }
        if (!firstname.value || firstname.value.length === 0) {
            toast.error("Le champ Prénom est obligatoire")
            return false
        }
        if (!rpps.value || rpps.value.length === 0) {
            toast.error("Le champ RPPS est obligatoire")
            return false
        }
        if (!r.validateRpps(rpps.value)) {
            toast.error(t("OPTIONS.ADD_OPH_REF.BASS_RPPS_NUMBER"))
            return false;
        }
        if (!adeli.value || adeli.value.length === 0) {
            toast.error("Le champ Adeli est obligatoire")
            return false
        }
        if (!r.validateAdeli(adeli.value)) {
            toast.error("Le numéro Adeli doit contenir 9 caractères")
            return false;
        }
        if (!specialty_code.value || specialty_code.value.length === 0) {
            toast.error("Le champ Spécialité est obligatoire")
            return false
        }
        if (!practitioner_type.value || practitioner_type.value.length === 0) {
            toast.error("Le champ Type de praticien est obligatoire")
            return false
        }

        return true
    }

    const save = (e) => {
        e.preventDefault()

        const {lastname, firstname, rpps, adeli, specialty_code, practitioner_type, email} = e.target.elements;
        if (!validateForm(e.target.elements)) return

        axios.put(`/prescripteurs/create`, {
            lastname: lastname.value,
            firstname: firstname.value,
            rpps: rpps.value,
            adeli: adeli.value,
            specialty_code: specialty_code.value,
            practitioner_type: practitioner_type.value,
            email: email.value
        }).then((response) => {
            setPrescriptors([...prescriptors, response.data.prescriptor]);
            toast.success("Le prescripteur a bien été créé")
        }).catch(() => {
            toast.error("Une erreur s'est produite lors de la création du prescripteur")
        })
    }

    const update = (e, prescriptor_id) => {
        e.preventDefault()
        console.log(e.target.elements)
        const {lastname, firstname, rpps, adeli, specialty_code, practitioner_type, email} = e.target.elements;
        if (!validateForm(e.target.elements)) return


        axios.patch(`/prescripteurs/update/${prescriptor_id}`, {
            lastname: lastname.value,
            firstname: firstname.value,
            rpps: rpps.value,
            adeli: adeli.value,
            specialty_code: specialty_code.value,
            practitioner_type: practitioner_type.value,
            email: email.value
        }).then((response) => {
            const prescriptor = response.data.prescriptor
            const new_prescriptors = prescriptors.map(p => p.id === prescriptor_id ? prescriptor : p)
            setPrescriptors(new_prescriptors);
            const modal_id = `edit_prescriptor_${prescriptor.id}`
            const modal = document.getElementById(modal_id);
            const instance = M.Modal.getInstance(modal);
            instance.close();
            toast.success("Le prescripteur a bien été créé")
        }).catch(() => {
            toast.error("Une erreur s'est produite lors de la création du prescripteur")
        })
    }

    const handleCSVData = (csvData) => {
        const newPrescripteurs = csvData.new_prescriptors.map(data => ({
            lastname: data.lastname,
            firstname: data.firstname,
            rpps: data.rpps,
            adeli: data.adeli,
            specialty_code: parseInt(data.specialty_code),
            practitioner_type: parseInt(data.practitioner_type),
            email: data.email
        }));
        setPrescriptors([...prescriptors, ...newPrescripteurs]);
    }

    const deletePrescriptor = (prescriptor_id) => {
        axios.delete(`/prescripteurs/delete/${prescriptor_id}`)
            .then(() => {
                setPrescriptors(prescriptors.filter(p => p.id !== prescriptor_id))
                toast.success("Le prescripteur a bien été supprimé")
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    return toast.error("Impossible de supprimer le prescripteur car il est lié à des examens")
                }
                toast.error("Une erreur s'est produite lors de la suppression du prescripteur");
            });
    }

    const renderListPrescripteurs = (prescripteurs) => {
        return <table className="temeoo-table">
            <thead>
            <tr>
                <th className="center">Nom</th>
                <th className="center">Email</th>
                <th className="center">Rpps</th>
                <th className="center">Adeli</th>
                <th className="center">Spécialité</th>
                <th className="center">Type de practicien</th>
            </tr>
            </thead>
            <tbody>
            {prescripteurs.map((prescriptor) => {
                const specialty = Object.values(SpecialtyCodes).find(
                    spec => spec.code === prescriptor.specialty_code
                )
                const practicien_type = Object.values(PractitionerTypes).find(
                    pract => pract.code === prescriptor.practitioner_type
                )
                return <tr key={prescriptor.id}>
                    <td className="center">{Helper.formatName(prescriptor.firstname, prescriptor.lastname)}</td>
                    <td className="center">{prescriptor.email}</td>
                    <td className="center">{prescriptor.rpps}</td>
                    <td className="center">{prescriptor.adeli}</td>
                    <td className="center">{specialty ? specialty.name : "Inconnue"}</td>
                    <td className="center">{practicien_type.name}</td>
                    <td className="center">
                        <EditPrescriptorButton prescriptor={prescriptor} onEdit={(e) => update(e, prescriptor.id)}/>
                        <Validator
                            onValidation={() => deletePrescriptor(prescriptor.id)}
                            text={`${t("RISK_ALERT_MANAGEMENT.RISK_ALERT_MANAGEMENT")} ?`}
                            detail={t("RISK_ALERT_MANAGEMENT.ACTION_IRREVERSIBLE")}
                            validationText={t("DELETE")} validationColor="red darken-2"
                            validationIcon="delete"
                            abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                            id={`delete_prescriptor_${prescriptor.id}`}>
                            <a className="btn-small red darken-2 z-depth-0">
                                <i className="material-icons">delete</i>
                            </a>
                        </Validator>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    }

    return <div className="container" style={{width: "100%", height: "100%"}}>
        <div className="fc" style={{padding: 20, gap: 10}}>
            <h1 className="center" style={{margin: 0}}>Prescripteurs</h1>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <CSVButtonComponent
                    url={"/prescripteurs/create_prescriptor_with_csv"}
                    title={"Ajouter prescripteurs"}
                    sub_title={"Ajouter des prescripteurs via un CSV"}
                    onDataUpload={handleCSVData}
                />
                <a style={{fontWeight: 'bold'}} className="btn-small blue darken-2 z-depth-0" onClick={downloadCSV}>
                    <i className={"material-icons left"}>download</i>
                    Télécharger liste</a>
            </div>
            <PrescriptorForm onSubmit={save} buttonText={t("OPTIONS.ADD_PRESCRIPTOR.BTN_ADD_PRESC")}/>
            {renderListPrescripteurs(prescriptors)}
        </div>
    </div>
}