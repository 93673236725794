import {useEffect, useState} from "react";
import {RequestState} from "../../../../Shared/StateHelper";
import axios from "axios";

const useMedicalEvents = (id, load = true) => {
    const [medicalEvents, setMedicalEvents] = useState([]);
    const [medicalEvent, setMedicalEvent] = useState({});
    const [state, setState] = useState(RequestState.IDLE);

    useEffect(() => {
        if(!load) return

        if(id === undefined) get_all();
        else get(id);
    }, [id, load]);

    const get_all = () => {
        axios.get("/admin/console/medical_events/all").then(response => {
            setMedicalEvents(response.data);
            setState(RequestState.SUCCESS);
        }).catch(() => {
            setState(RequestState.ERROR);
        })
    }

    const get = (id) => {
        // TODO : do back-end when needed

        // axios.get(`/admin/console/medical_events/${id}`).then(response => {
        //     setMedicalEvent(response.data);
        //     setState(RequestState.SUCCESS);
        // }).catch(() => {
        //     setState(RequestState.ERROR);
        // })
    }

    const create = (medicalEvent) => {
        return axios.post("/admin/console/medical_events", medicalEvent)
    }

    const update = (medicalEvent) => {
        return axios.patch(`/admin/console/medical_events/${medicalEvent.id}`, medicalEvent)
    }

    return { medicalEvents, event: medicalEvent, create, update, state };
}

export default useMedicalEvents;