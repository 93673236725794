import React from 'react';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react';
import PatientList from './PatientList';
import PatientDetails from './PatientDetails';

const PrescriptorDashboardRouter = observer(({}) => {


    return <div id="prescriptor-dashboard-view">
        <Router>
            <Routes>
                <Route path="/" exact element={<PatientList/>}/>
                <Route path="/patient/:patient_id" exact element={<PatientDetails/>}/>
            </Routes>
        </Router>
    </div>
})

export default PrescriptorDashboardRouter