import React from 'react';
import {InvoiceStatus} from "../Enums/Enums";
import {wrapper} from "../../I18n/i18n";

const StatusIcon = ({status}) => {

    const {t} = wrapper("ORTHOPTIST_BILLING.BILLING_OVERVIEW")

    switch (status) {
        case InvoiceStatus.WAITING:
            return <i className="material-icons grey-text" title={t("TOOLTIP.WAITING")}>schedule</i>
        case InvoiceStatus.PENDING:
            return <i className="material-icons orange-text" title={t("TOOLTIP.PENDING")}>pending</i>
        case InvoiceStatus.SUCCESS:
            return <i className="material-icons green-text" title={t("TOOLTIP.SUCCESS")}>done</i>
        case InvoiceStatus.FAILED:
            return <i className="material-icons red-text" title={t("TOOLTIP.FAILED")}>error</i>
        default:
            return null
    }
}

export default StatusIcon;