import React from "react";

function Prescription({prescriptions}) {
    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%", gap: 20}}>
            <div className="valign-wrapper" style={{justifyContent: "center", margin: 0}}>
                <h2 className="center" style={{margin: 0}}>Prescriptions</h2>
            </div>
            <div className="card-panel blue lighten-5"
                 style={{borderRadius: "0px 0px 30px 30px", borderTop: "thick solid #32a1ce", height: "100%", margin: 0}}>
                {prescriptions === null ?
                    (<span>Aucune prescription n’a pu être prescrite à la suite de l’examen.</span>)
                    :
                    (
                        <div style={{
                            textAlign: "left",
                            justifySelf: "center",
                            overflowY: 'scroll',
                            height: "100%",
                            maxHeight: "500px",
                            whiteSpace: "pre-wrap",
                        }}>
                            {prescriptions.text}
                        </div>
                    )}
            </div>
        </div>
    )
}

export default Prescription;