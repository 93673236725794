import React from 'react';
import Helper from "../Shared/Helper";
import {t} from "../I18n/i18n"

const ViewerMetadata = ({metadata}) => {

    const render_return_button = () => {
        const fallback_url = metadata?.fallback_url;
        if (!fallback_url) return null;
        return <li>
            <a className="btn-flat" style={{margin: 10}} href={fallback_url}>
                <i className="material-icons left">arrow_back</i>
                {t("BACK")}
            </a>
        </li>
    }

    const render_appointement_meta = () => {
        const appointment = metadata?.appointment;
        if (!appointment) return null;
        const orthoptist = appointment?.orthoptiste;
        const analyst = appointment?.analyst;
        const ok = appointment?.status_controle === 2
        return <li className="collection-item">
            <div style={{margin: 20, fontWeight: "bold"}}>
                <h5 style={{margin: 0, fontWeight: "bold"}}>
                    {appointment ? `${t("VIEWER_METADATA.APPOINTMENT_OF")} ${Helper.formatDate(appointment.start)}` : ""}
                    {orthoptist ? ` ${t("VIEWER_METADATA.WITH")} ${Helper.formatName(orthoptist.prenom, orthoptist.nom)}` : ""}</h5>
                <h5
                    className={appointment.status_controle > 1 ? "valign-wrapper" : "hide"}
                    title={ok ? t("VIEWER_METADATA.DOCUMENT_HAS_BEEN_VALIDATED") : t("VIEWER_METADATA.DOCUMENT_HASNT_BEEN_VALIDATED")}
                >
                    <i className={`material-icons left ${ok ? "green" : "red"}-text text-darken-2`}>{ok ? "done" : "error"}</i>
                    {`${t("VIEWER_METADATA.ANALYSED_BY")} ${Helper.formatName(analyst.prenom, analyst.nom)}`}
                </h5>
            </div>
        </li>
    }

    const render_invoice_meta = () => {
        const invoice = metadata?.invoice;
        if (!invoice) return null;
        return <li className="collection-item">
            <h5 className="center" style={{
                margin: 0,
                fontWeight: "bold"
            }}>{t("VIEWER_METADATA.DOCUMENT_ISSUED_ON")} {Helper.formatDate(invoice.date)}</h5>
        </li>

    }

    return <>
        {render_return_button()}
        {render_appointement_meta()}
        {render_invoice_meta()}
    </>
}

export default ViewerMetadata;