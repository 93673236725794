import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";

const ValidationMessage = () => {
    const [validationMessage, setValidationMessage] = useState("");
    const [saved, setSaved] = useState(false);

    useEffect(() => getValidatedMessage(), []);
    useEffect(() => setSaved(false), [validationMessage]);

    const getValidatedMessage = () => {
        axios.get("/messages/ophtalmologiste/api/validation_message")
            .then((resp) => {
                setValidationMessage(resp.data.validation_message)
            })
    }

    const saveValidatedMessage = () => {
        axios.patch("/messages/ophtalmologiste/api/validation_message", {validation_message: validationMessage})
            .then((_) => setSaved(true))
            .catch((_) => toast.error("Une erreur est survenue lors de la modification du message"))
    }

    return <div className="fc" style={{width: "100%", alignItems: "center"}}>
        <label>Ce message sera affiché dans votre compte rendu après validation d'un dossier.</label>
        <div className="input-field outlined" style={{display: "flex", width: "60%", gap: 20}}>
            <input id="name" type="text" defaultValue={validationMessage}
                   onChange={(e) => setValidationMessage(e.target.value)}/>
            <label>Message de validation</label>
            <a className={`btn ${saved ? "green" : "blue"} darken-2 z-depth-0`}
               style={{margin: 5, height: "90%", display: "flex", alignItems: "center", justifyContent: "center"}}
               onClick={saveValidatedMessage}>
                <i className="material-icons">{saved ? "done" : "edit_note"}</i>
            </a>
        </div>
    </div>
}

export default ValidationMessage;