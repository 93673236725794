import React from "react";

const DocumentSurvey = ({appointment, patient}) => {
    const getBooleanRepresentation = (bool) => (bool === true) ? "oui" : "non";

    const renderLastValuePair = (label, value, isBoolean) => (
        <div style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
            {isBoolean ? (
                <>
                    <h5 style={{width: 80, margin: 0,}}>{label}</h5>
                    <h5 style={{
                        width: 20, margin: 0, display: "inline",
                        fontWeight: getBooleanRepresentation(value) === "oui" ? 'bold' : 'normal'
                    }}>
                        {getBooleanRepresentation(value)}
                    </h5>
                </>
            ) : (
                <>
                    <h5 style={{width: 100, margin: 0, marginLeft: 200,}}>
                        {label}
                    </h5>
                    <h5 style={{
                        width: 20, margin: 0, display: "inline", paddingRight: 150, paddingLeft: 20,
                        fontWeight: getBooleanRepresentation(value) === "oui" ? 'bold' : 'normal'
                    }}>
                        {getBooleanRepresentation(value)}
                    </h5>
                </>
            )}
        </div>
    );

    const renderDrugs = () => {
        if (!patient.drugs?.length) return null;
        return <h5 style={{marginBlock: patient.drugs.length !== 0 ? 15 : 0}}>
                <b>Médicaments utilisés : </b>
                {patient.drugs.map((drug, index) => (
                    <h5 style={{marginTop: 5, marginBottom: 0}} key={index}>{drug.label}</h5>
                ))}
            </h5>
    }

    return <>
        <h5 style={{marginTop: 20, marginBottom: 5}}><b>Questionnaire :</b></h5>
        <div style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
            {renderLastValuePair('BAV de près', appointment.report["BAV de près"], true)}
            {renderLastValuePair('Photopsies', appointment.report["Flash"], false)}
            {renderLastValuePair('Diplopie', appointment.report["Diplopie"], true)}
        </div>
        <div style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
            {renderLastValuePair('BAV de loin', appointment.report["BAV de loin"], true)}
            {renderLastValuePair('Myodesopties', appointment.report["Mouche"], false)}
            {renderLastValuePair('Vertiges', appointment.report["Vertiges"], true)}
        </div>
        <div style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
            {renderLastValuePair('Céphalée', appointment.report["Céphalée"], true)}
            {renderLastValuePair('Voile', appointment.report["Voile"], false)}
        </div>
        <div style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
            <h5 style={{
                width: 80, margin: 0,
                fontWeight: appointment.report["Amblyopie"] === "Oui" ? 'bold' : 'normal'
            }}>
                Amblyopie
            </h5>
            <h5 style={{
                width: 20, margin: 0, display: "inline",
                fontWeight: appointment.report["Amblyopie"] === "Oui" ? 'bold' : 'normal'
            }}>
                {appointment.report["Amblyopie"] === "Oui" ? "oui" : "non"}
            </h5>
            <h5 style={{
                width: 100, margin: 0, marginLeft: 200,
                fontWeight: appointment.report["Diabète"] === "Oui" ? 'bold' : 'normal'
            }}>
                Diabète
            </h5>
            <h5 style={{
                width: 20, margin: 0, display: "inline", paddingRight: 150, paddingLeft: 20,
                fontWeight: appointment.report["Diabète"] === "Oui" ? 'bold' : 'normal'
            }}>
                {appointment.report["Diabète"] === "Oui" ? "oui" : "non"}
            </h5>
        </div>
        {/*Antécédents familiaux*/}
        <h5 style={{marginBottom: 5, marginTop: 20}}><b>Antécédents familiaux :</b></h5>
        <div style={{display: "flex", flexDirection: "row", paddingRight: 40}}>
            {renderLastValuePair('Diabète', appointment.report['Diabète '], true)}
            {renderLastValuePair('Glaucome', appointment.report["Glaucome"], false)}
        </div>
        {renderDrugs()}
    </>
}

export default DocumentSurvey;