import React, {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {User} from "../../../Shared/Enums/Enums";
import GlobalNotificationsStore from "./GlobalNotificationsStore";

const SendNotificationButton = ({notification}) => {
    const modal_id = `send_notif_modal_${notification.id}`
    const user_types = [
        User.ORTHOPTIST,
        User.OPHTALMOLOGIST,
        User.SECRETARY,
        User.EXTERN,
        User.OPTICIAN
    ]

    useEffect(() => {
        const modal = document.getElementById(modal_id)
        const instance = M.Modal.init(modal, {});

        return () => {
            instance?.destroy()
        }
    }, [notification])

    const notify = (notification) => {
        GlobalNotificationsStore.notify(notification, userTypes()).catch(() => {
            toast.error("Impossible d'envoyer la notification")
        })
    }

    const userTypes = () => {
        return user_types.reduce((acc, user_type) => {
            const choice = document.getElementById(`choice_${user_type.value}`)
            if (choice?.checked) acc.push(user_type.value)
            return acc
        }, [])
    }

    const renderModal = () => {
        return <div id={modal_id} className="modal left" style={{padding: 20}}>
            <div className="modal-header center">
                <h1>Envoyer la notification</h1>
            </div>
            <div className="modal-content">
                <div className="fc">
                    <h4>Choisir les types de destinataires</h4>
                    {user_types.map((user_type) => {
                        return <label key={user_type.value}>
                            <input type="checkbox" className="filled-in" id={`choice_${user_type.value}`}/>
                            <span>{user_type.name}</span>
                        </label>
                    })}
                </div>
            </div>
            <div className="modal-footer">
                <a className="btn-small blue darken-2 z-depth-0" onClick={() => notify(notification)}>
                    <i className="material-icons left">send</i>
                    Notifier
                </a>
            </div>
        </div>
    }

    return <>
        <a className="btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}>
            <i className="material-icons left">send</i>
            Notifier
        </a>
        {renderModal()}
    </>
}

export default SendNotificationButton