import React, {useEffect} from "react";
import axios from "axios";
import Helper from "../Shared/Helper";
import {RequestState} from "../Shared/StateHelper";
import Loader from "../Shared/Loader";
import Error from "../Shared/Error";
import RatingCard from "./RatingCard";
import {t} from "../I18n/i18n"
import HelpIcon from "../Shared/Help/HelpIcon";
import {useCoordinates} from "./Ads/Hooks/useCoordinates";
import AccountSwap from "./Account/AccountSwap";


function ListPatientAccess(props) {
    const [patient_data, setPatientData] = React.useState(null);
    const [appointments_list, setAppointmentsList] = React.useState([]);
    const [request_status, setRequestStatus] = React.useState(RequestState.LOADING);
    const [allow_rating, setAllowRating] = React.useState(true)

    const coordinates = useCoordinates(() => null, () => null)

    useEffect(() => {
        getAppointments()
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
    }, [])

    const getAppointments = () => {
        axios.get("/appointment_list", {params: {hide_useless_appointments: true}})
            .then((response) => {
                setPatientData(response.data.patient_data)
                setAppointmentsList(response.data.appointments_list)
                setRequestStatus(RequestState.SUCCESS)
                if (response.data.rating) setAllowRating(false)
            }).catch((_) => {
            setRequestStatus(RequestState.ERROR)
        })
    }

    const render_appointment = (appointment) => {
        if (appointment.need_to_pay) return <tr key={appointment["id"]}>
            <td className="center">
                {Helper.formatDate(appointment.start)}
            </td>
            <td className="center">
                <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left blue-text text-darken-2 hide-on-med-and-down">error</i>
                    <span className="hide-on-med-and-down">{t("PATIENT_PORTAL.AWAITING_PAYMENT")}</span>
                    <i className="material-icons blue-text text-darken-2 hide-on-large-only hide-on-extra-large-only">error</i>
                </h5>
            </td>
            <td className="center">
                <a className="btn blue darken-2 z-depth-0"
                   style={{overflow: "hidden", minWidth: 200}}
                   href={appointment.payment_infos.link}>{t("PATIENT_PORTAL.PAYMENT_IN_WAITING")}
                </a>
            </td>
        </tr>


        let status
        switch (appointment.status_controle) {
            case 2:
                status = <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left green-text text-darken-2 hide-on-med-and-down">done</i>
                    <span className="hide-on-med-and-down">{t("PATIENT_PORTAL.APPOINTMENT_SUCCESS")}</span>
                    <i className="material-icons green-text text-darken-2 hide-on-large-only hide-on-extra-large-only">done</i>
                </h5>
                break
            case 3:
                status = <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left red-text text-darken-2 hide-on-med-and-down">error</i>
                    <span className="hide-on-med-and-down">{t("PATIENT_PORTAL.FILE_ALERT")}</span>
                    <i className="material-icons red-text text-darken-2 hide-on-large-only hide-on-extra-large-only">error</i>
                </h5>
                break
            case -1:
                status = <h5 className="center" style={{margin: 0}}>
                    <span>{t("PATIENT_PORTAL.TEST_EXAM")}</span>
                </h5>
            default:
                status = <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left orange-text text-darken-2 hide-on-med-and-down">pending</i>
                    <span className="hide-on-med-and-down">{t("PATIENT_PORTAL.AWAITING_VALIDATION")}</span>
                    <i className="material-icons orange-text text-darken-2 hide-on-large-only hide-on-extra-large-only">pending</i>
                </h5>
        }

        if (appointment.orthoptic_act) {
            status = <h5 className="center" style={{margin: 0}}>
                <i className="material-icons left green-text text-darken-2 hide-on-med-and-down">done</i>
                <span className="hide-on-med-and-down">{t("PATIENT_PORTAL.APPOINTMENT_DONE")}</span>
                <i className="material-icons green-text text-darken-2 hide-on-large-only hide-on-extra-large-only">done</i>
            </h5>
        }

        return <tr key={appointment.id}>
            <td className="center">
                {Helper.formatDate(appointment.start)}
            </td>
            <td className="center">
                {status}
            </td>
            <td className="center">
                <a className={`btn green darken-2 z-depth-0 truncate ${appointment.document_count || "disabled"} `}
                   onClick={() => {
                       const lat = coordinates?.latitude
                       const lng = coordinates?.longitude
                       let url = `/documents/${appointment.id}`
                       if (lat && lng) url += `?lat=${lat}&lng=${lng}`
                       window.location.href = url
                   }}
                   style={{minWidth: 200}}
                >{appointment.document_count} {t("PATIENT_PORTAL.DOCUMENT_AVAILABLE")}</a>
            </td>
        </tr>
    }

    const render_appointments = () => {
        if (request_status === RequestState.LOADING) return <div id="full-height" style={{paddingTop: 200}}>
            <Loader text="" size="small"/>
        </div>

        if (request_status === RequestState.ERROR) return <div id="full-height" style={{paddingTop: 200}}>
            <Error errorText={t("PATIENT_PORTAL.UNABLE_TO_LOAD")}/>
        </div>

        if (!appointments_list.length) return <div className="center"
                                                   style={{marginTop: "200px"}}>{t("PATIENT_PORTAL.NO_APPOINTMENT")}</div>

        return <div>
            <div className="center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10}}>
                <h2>{t("PATIENT_PORTAL.YOUR_EXAMS")}</h2>
                {/*<HelpIcon url="https://www.temeoo.fr/orthoptists/documentation/espace-patient"/>*/}
            </div>
            <table className="striped bordered" style={{width: "100%", overflowY: "scroll"}}>
                <thead>
                <tr>
                    <td className="center">{t("PATIENT_PORTAL.EXAM_DATE")}</td>
                    <td className="center">{t("PATIENT_PORTAL.STATUS")}</td>
                    <td className="center">{t("PATIENT_PORTAL.ACTION")}</td>
                </tr>
                </thead>
                <tbody style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                }}>
                {appointments_list ? appointments_list.map(render_appointment) : null}
                </tbody>
            </table>
        </div>
    }

    const render_patient = () => {
        return <div className="card" style={{width: "80%", margin: 20}}>
            {
                !patient_data ? <Loader text="" size="small"/> :
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                        <div className="valign-wrapper"
                             style={{justifyContent: "center", display: patient_data.gender ? "" : "none"}}>
                            <img style={{padding: 20, maxWidth: 200}} src={`/imgs/${patient_data.gender}.svg`}/>
                        </div>
                        <div className="fc" style={{gap: 3}}>
                            <AccountSwap accounts={props.accounts} patient={patient_data}>
                                <h4 style={{margin: 0}}>{Helper.formatName(patient_data.first_name, patient_data.last_name)}</h4>
                            </AccountSwap>
                            <h5 style={{margin: "0 5px"}}>{Helper.formatDate(patient_data.birthday)}</h5>
                            <h5 style={{margin: "0 5px"}}>{patient_data.email}</h5>
                            <h5 style={{margin: "0 5px"}}>{patient_data.phone}</h5>
                        </div>
                    </div>
            }
        </div>
    }

    const render_rating_card = () => {
        if (request_status !== RequestState.SUCCESS || !allow_rating) return null
        const last_appt = appointments_list.length > 0 ? appointments_list[0] : null
        const ortho_firstname = last_appt?.orthoptist_firstname
        const ortho_lastname = last_appt?.orthoptist_lastname
        return <RatingCard last_orthoptist_name={Helper.formatName(ortho_firstname, ortho_lastname)}
                           google_rating_url={last_appt?.google_rating_url}/>
    }

    return (
        <div style={{
            height: "calc(100vh - 90px)",
            display: "flex",
            flexDirection: "column",
            paddingTop: 40,
        }}>
            <div className="row hide-on-med-and-down" style={{height: "100%", gap: 20, margin: "0 40px"}}>
                <div className="col l4 m12 s12 valign-wrapper" style={{display: "flex", flexDirection: "column"}}>
                    {render_patient()}
                    {render_rating_card()}
                </div>
                <div className="col l8 m12 s12" style={{overflowY: "scroll"}}>
                    {render_appointments()}
                </div>
            </div>
            <div className="row hide-on-large-only hide-on-extra-large-only"
                 style={{height: "100%", gap: 20, margin: 0}}>
                <div className="col l4 m12 s12"
                     style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {render_patient()}
                    {render_rating_card()}
                </div>
                <div className="col l8 m12 s12">
                    {render_appointments()}
                </div>
            </div>
        </div>
    )
}

export default ListPatientAccess;