import React, {useEffect, useState} from "react"
import {downloadFilesAsZip, generatePdfFileFromUrl} from "../../Shared/PDF/PDFManager";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

const _ = require('lodash')
const DownloadDocuments = ({title, documents}) => {
    const generateArchiveFromDocuments = async (documents) => {
        return new Promise(async (resolve, reject) => {
            const files = []
            const id = toast.loading(`Préparation des documents, cela peut prendre quelques minutes... (0/${documents.length})`)
            for (let i = 0; i < documents.length; i++) {
                const file = await generatePdfFileFromUrl(documents[i].uri, documents[i].name).catch((error) => {
                    reject(id)
                    // wait 5 seconds and reload the page
                    setTimeout(() => window.location.reload(), 5000)
                })
                files.push(file)
                toast.update(id, {render: `Préparation des documents, cela peut prendre quelques minutes... (${files.length}/${documents.length})`})
            }
            await downloadFilesAsZip(files)
            resolve(id)
        })
    }

    const download = () => {
        generateArchiveFromDocuments(documents)
            .then((id) => toast.update(id, {render: `Documents téléchargés !`, type: `success`, isLoading: false}))
            .catch((id) => toast.update(id, {
                render: `Une erreur est survenue lors de la préparation des documents`,
                type: `error`,
                isLoading: false
            }))
    }

    return <div>
        <a className={`btn-small blue darken-2 z-depth-0 ${_.isEmpty(documents) ? "disabled" : ""}`}
           onClick={() => download()}>
            <i className={"material-icons left"}>cloud_download</i>
            {title} {_.isEmpty(documents) ? "" : `(${documents.length})`}
        </a>
    </div>
}

DownloadDocuments.propTypes = {
    documents: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
}

DownloadDocuments.defaultProps = {
    documents: [],
    title: "Télécharger les documents"
}

export default DownloadDocuments