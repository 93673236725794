import {Chart, registerables} from "chart.js";
import React, {useEffect} from "react";
import {Bar} from "react-chartjs-2";
import axios from "axios";
import {t} from "../../../I18n/i18n";
import {observer} from "mobx-react";

import ConsoleDashboardStore from "../ConsoleDashboardStore";

Chart.register(...registerables);

const _ = require('lodash');
const MonthlyIncome = observer(({title, data_type}) => {
    const [data, setData] = React.useState({});

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: title?.length !== 0,
                text: title
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        },
        maintainAspectRatio: false
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [
        data_type,
        ConsoleDashboardStore.selectedFormulas,
        ConsoleDashboardStore.selectedActs,
        ConsoleDashboardStore.selectedOphtalmologists,
        ConsoleDashboardStore.selectedOffices,
        ConsoleDashboardStore.selectedOrthoptists,
    ]);


    const getData = () => {
        axios.get("/admin/console/bills/monthly", {
            params: {
                data_type: data_type,
                dinu: ConsoleDashboardStore.includeDinu,
                formula_ids: ConsoleDashboardStore.selectedFormulas.map((formula) => formula.id) || undefined,
                act_codes: ConsoleDashboardStore.selectedActs.map((act) => act.code) || undefined,
                orthoptist_ids: ConsoleDashboardStore.selectedOrthoptists.map((orthoptist) => orthoptist.id),
                office_ids: ConsoleDashboardStore.selectedOffices.map((office) => office.id),
                ophtalmologist_ids: ConsoleDashboardStore.selectedOphtalmologists.map((ophtalmologist) => ophtalmologist.id)
            }
        }).then((response) => {
            const labels = Object.keys(response.data).map((key) => {
                const month = key.split("-")[0]
                const year = key.split("-")[1]
                return `${t(`MONTH.${month}`)} ${year}`
            })

            const data = Object.values(response.data)
            setData({
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: ['#1976d2',],
                    },
                ],
            })
        }).catch((_) => {
        })
    }

    if (_.isEmpty(data)) return null

    return <div>
        <div className="container" style={{width: "85%"}}>
            <Bar height={450} data={data} options={options}></Bar>
        </div>
    </div>

})

export default MonthlyIncome;