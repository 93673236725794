import React, {useEffect} from 'react';
import InvoiceHeader from "../../../Orthoptistes/Paiements/Invoice/InvoiceHeader";
import Helper from "../../../Shared/Helper";
import {t} from "../../../I18n/i18n";

const InvoiceDocument = ({name, invoice}) => {
    useEffect(() => {
        window.status = "ready"
    }, []);

    const renderBody = () => {
        const total = invoice.amount / 100.0
        const retrocession_total = invoice.retrocessions / 100.0
        const hn_total = invoice.hn / 100.0
        return <div style={{display: "flex", flexDirection: "column"}}>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Montant total : {total.toFixed(2)}€
            </h5>
            <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                <h5 style={{fontWeight: "bold", margin: 0}}>Document N°{invoice.id}</h5>
                <h5 style={{fontWeight: "bold", margin: 0, color: "#de4791"}}>
                    Mois : {t(`MONTH.${invoice.month}`)} {invoice.year}
                </h5>
            </div>
            <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
            <div style={{backgroundColor: "#e2e2e2", padding: "0 10px"}}>
                <div style={{display: "flex", flexDirection: "column", padding: "20px 0", gap: 5}}>
                    {retrocession_total ? (<div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0, fontWeight: 600}}>Rétrocessions</h5>
                        <h5 style={{margin: 0, fontWeight: 600}}>{retrocession_total.toFixed(2)}€</h5>
                    </div>) : null}
                    {hn_total ? (<div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0, fontWeight: 600}}>Hors-Nomenclature</h5>
                        <h5 style={{margin: 0, fontWeight: 600}}>{hn_total.toFixed(2)}€</h5>
                    </div>) : null}
                </div>
            </div>
            <div className="divider" style={{margin: "20px 0", height: "3px", backgroundColor: "#de4791"}}/>
        </div>
    }

    return <div style={{padding: 20, display: "flex", justifyContent: "center"}}>
        <div className="card" style={{minHeight: "297mm", width: "210mm"}}>
            <div className="pdf-document" style={{display: "flex", flexDirection: "column"}}>
                <InvoiceHeader title="REVENUS"/>

                <div style={{display: "flex", margin: "40px 0"}}>
                    <h5 style={{margin: 0}}><b>Pour :</b> Dr. {name}</h5>
                </div>
                <div style={{margin: "100px 0 0 0"}}>

                    {renderBody()}
                </div>
            </div>
        </div>
    </div>
}

export default InvoiceDocument;