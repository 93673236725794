import React from 'react';
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import {t} from "../../../I18n/i18n";
import {PractitionerTypes, SpecialtyCodes} from "../../../Shared/Enums/Enums";

const PrescriptorForm = ({onSubmit, buttonText, prescriptor = {}}) => {

    return <BorderedDisplay containerStyle={{borderRadius: 20}} style={{padding: 10}}>
        <form style={{display: "flex", flexDirection: "column", gap: 20, fontSize: "1rem"}} onSubmit={onSubmit}>
            <div style={{display: 'flex', gap: 20}}>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="lastname" type="text" className="validate" required defaultValue={prescriptor.lastname}/>
                    <label htmlFor="lastname">{t("LASTNAME")} *</label>
                </div>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="firstname" type="text" className="validate" required defaultValue={prescriptor.firstname}/>
                    <label htmlFor="firstname">{t("FIRSTNAME")} *</label>
                </div>
            </div>
            <div className="row" style={{gap: 20}}>
                <div className="input-field outlined col s12 m6">
                    <input id="email" type="email" className="validate" defaultValue={prescriptor.email}/>
                    <label htmlFor="email">{t("EMAIL")}</label>
                </div>
            </div>
            <div style={{display: 'flex', gap: 20}}>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="rpps" type="number" className="validate" required defaultValue={prescriptor.rpps}/>
                    <label htmlFor="rpps">RPPS *</label>
                </div>
                <div className="input-field outlined" style={{flex: 1}}>
                    <input id="adeli" type="number" className="validate" required defaultValue={prescriptor.adeli}/>
                    <label htmlFor="adeli">Numéro Adeli *</label>
                </div>
            </div>
            <div style={{display: 'flex', gap: 20}}>
                <div className="input-field outlined" style={{flex: 1}}>
                    <select id="specialty_code" className="browser-default" required defaultValue={prescriptor.specialty_code}>
                        <option value="" disabled>Choisir la spécialité *</option>
                        {Object.values(SpecialtyCodes).map((spec) => (
                            <option key={spec.code} value={spec.code}>{spec.name}</option>
                        ))}
                    </select>
                </div>
                <div className="input-field outlined" style={{flex: 1}}>
                    <select id="practitioner_type" className="browser-default" required defaultValue={prescriptor.practitioner_type}>
                        <option value="" disabled>Choisir le type de praticien *</option>
                        {Object.values(PractitionerTypes).map((spec) => (
                            <option key={spec.code} value={spec.code}>{spec.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div style={{color: "gray"}}>
                * {t("OPTIONS.ADD_OPH_REF.REQUIRED_FIELD")}
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <button className="btn blue darken-2 z-depth-0" type="submit"
                        name="action">
                    {buttonText}
                </button>
            </div>
        </form>
    </BorderedDisplay>
}

export default PrescriptorForm;