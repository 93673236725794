import React, {useEffect, useState} from "react"
import SmartTextArea from "../../Shared/UiKit/SmartTextArea";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import useDebounceV2 from "../../Hooks/useDebounceV2";
import {PrescriptionType, TypesMesures} from "../../Shared/Enums/Enums";
import {observer} from "mobx-react";
import MedicalEventStore from "../MedicalEventStore";
import {measure_to_prescription} from "../../Shared/Measures/MeasuresHelper";

const Prescription = observer(() => {
    const {appointment_id} = useParams()
    const [prescription, setPrescription] = useState(undefined)
    const debouncedSavePrescription = useDebounceV2((prescr) => MedicalEventStore.savePrescription(prescr, appointment_id), 1000)

    useEffect(() => {
        MedicalEventStore.getPrescription(appointment_id).then((comment) => {
            setPrescription(comment)
        }).catch(() => {
            setPrescription("")
            toast.error("Impossible de charger la prescription")
        })
    }, []);

    const saveComment = (comment) => {
        setPrescription(comment)
        debouncedSavePrescription(comment)
    }

    const ref_subj = MedicalEventStore.currentMeasures.find(m => m.name === TypesMesures.REF_SUBJ)
    const editPrescription = (type) => {
        if (!ref_subj) return toast.error("Veuillez renseigner une réfraction avant d'auto-remplir une prescription")
        let toAdd = measure_to_prescription(ref_subj, type)

        let newPrescription = prescription?.trim() ?? ""

        if(newPrescription.length > 0) newPrescription += "\n\n"
        newPrescription += toAdd

        setPrescription(newPrescription)
        debouncedSavePrescription(newPrescription)
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
        <h6 className="center" style={{margin: 0}}>Prescription</h6>
        <div>
            <ul className={`tabs tabs-fixed-width ${ref_subj ? "" : "disabled"}`}
                title={ref_subj ? "Préremplir d'après la réfraction" : "Veuillez renseigner une réfraction avant d'auto-remplir une prescription"}
                style={{borderRadius: "4px 4px 0 0", cursor: "pointer",
                    border: "thin solid var(--font-color-medium)",
                    borderBottom: "none"}}>
                <li className={`tab ${ref_subj ? "" : "disabled"}`}>
                    <a className={`grey-text ${ref_subj ? "text-darken-2" : "text-lighten-1"}`}
                       style={{backgroundColor: ref_subj ? "": "#e1e1e1"}}
                       type={PrescriptionType.LONG_SIGHTED_GLASSES}
                       onClick={(() => editPrescription("LONGSIGHT_GLASSES"))}>
                        Lun. Loin
                    </a>
                </li>
                <li className={`tab ${ref_subj ? "" : "disabled"}`}>
                    <a className={`grey-text ${ref_subj ? "text-darken-2" : "text-lighten-1"}`}
                       style={{backgroundColor: ref_subj ? "": "#e1e1e1"}}
                       onClick={(() => editPrescription("SHORTSIGHT_GLASSES"))}
                       type={PrescriptionType.SHORT_SIGHTED_GLASSES}>
                        Lun. Près
                    </a>
                </li>
                <li className={`tab ${ref_subj ? "" : "disabled"}`}>
                    <a className={`grey-text ${ref_subj ? "text-darken-2" : "text-lighten-1"}`}
                       onClick={(() => editPrescription("PROGRESSIVE_GLASSES"))}
                       style={{backgroundColor: ref_subj ? "": "#e1e1e1"}}
                       type={PrescriptionType.PROGRESSIVE_GLASSES}>
                        Lun. Prog
                    </a>
                </li>
            </ul>
            <SmartTextArea value={prescription ?? ""} onChange={(e) => saveComment(e.target.value)}
                           style={{height: 352, resize: "none", borderRadius: "0 0 4px 4px"}}
            />
        </div>
    </div>
})

export default Prescription