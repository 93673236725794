import React, {useState} from 'react';
import Overview from "./Overview";
import MonthlyIncome from "./MonthlyIncome";

function DashboardConsole({}) {

    const [showAppointmentCount, setShowAppointmentCount] = useState(false)

    return <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
        <div style={{padding: 20}}>
            <Overview/>
        </div>
        <div style={{padding: 20, paddingTop: 0, position: "relative"}}>
            <div className="clickable"
                 onClick={() => setShowAppointmentCount(!showAppointmentCount)}
                 style={{
                     position: "absolute",
                     right: "5%",
                     border: "1px solid grey",
                     borderRadius: "5px",
                     padding: "0 5px"
                 }}>
                {showAppointmentCount ? "Chiffre d'affaires" : "Nombre RDV"}
            </div>
            <></>
            <MonthlyIncome title={showAppointmentCount ? "Nombre de rendez-vous" : "Chiffre d'affaires"}
                           data_type={showAppointmentCount? "APPOINTMENTS_COUNT" : "REVENUES"}/>
        </div>
        <div>
            {/*<DetailledDashboard/>*/}
        </div>
    </div>
}

export default DashboardConsole;