import React from "react"
import {HashRouter as Router, Route, Routes} from "react-router-dom"
import {observer} from "mobx-react";
import ListPatients from "./ListPatients";
import MedicalEventQuestionnaire from "../Appointment/MedicalEventQuestionnaire";

const ScheduledEventRouter = observer(() => {

    return <div id="scheduled-event-view">
                <Router>
                    <Routes>
                        <Route path="/" exact element={<ListPatients/>}/>
                        <Route path="/appointment/:appointment_id" exact element={<MedicalEventQuestionnaire/>}/>
                    </Routes>
                </Router>
            </div>
})

export default ScheduledEventRouter