import React, {useEffect} from 'react';
import {User, UserStatus} from "../Enums/Enums";
import Helper from "../Helper";
import BrandedElement from "../BrandedElement";
import {capitalize, t as t_i18n} from "../../I18n/i18n";
import {Role} from "../Enums/Scope";
import ScopedElement from "../ScopedElement";
import BorderedDisplay from "../UiKit/BorderedDisplay";
import axios from "axios";
import {toast} from "react-toastify";

const t = (key) => capitalize(t_i18n("USER DROPDOWN." + key))
const _ = require('lodash');
function UserDropdown({user}) {
    useEffect(() => {
        const elems = document.getElementById("user_dropdown_trigger");
        const instances = M.Dropdown.init(elems, {coverTrigger: false, constrainWidth: false});

        return () => {
            instances.forEach(instance => instance.destroy());
        }
    }, []);

    const initials = () => {
        const initials = (user.firstname?.charAt(0)?.toUpperCase() ?? "") + (user.lastname?.charAt(0)?.toUpperCase() ?? "");
        return initials ? initials : (user.email?.charAt(0)?.toUpperCase() ?? "?");
    }

    const link = (str) => {
        switch (user.type) {
            case User.ORTHOPTIST.value:
                return `/orthoptistes/${str}`;
            case User.OPHTALMOLOGIST.value:
                return `/ophtalmologistes/${str}`;
            case User.EXTERN.value:
                return `/externes/${str}`;
            case User.SECRETARY.value:
                return `/secretaires/${str}`;
            case User.INTERNAL_USER.value:
                return `/internal_users/${str}`;
            case User.OPTICIAN.value:
                return `/opticians/${str}`;
        }
    }

    const color = () => {
        switch (user.status) {
            case UserStatus.LOCKED.value:
                return "#d32f2f";
            case UserStatus.ARCHIVED.value:
                return "#616161";
            case UserStatus.TEST.value:
                return "#616161";
            default:
                return "#1976d2";
        }
    }

    if (!user) return null;

    const renderLogo = () => {
        return <span className="circle" style={{backgroundColor: color(), color: "white"}}>
                    {initials()}
                </span>
    }

    const renderButton = () => {
        if (_.isEmpty(user.accounts)) {
            return <a id="user_dropdown_trigger" className="dropdown-trigger" data-target="user_dropdown">
                {renderLogo()}
            </a>
        }
        return <a id="user_dropdown_trigger" className="dropdown-trigger office-user-dropdown fr"
                  data-target="user_dropdown">
            <div className="fr" style={{paddingInline: 20, gap: 20, alignItems: "center", height: "100%"}}>
                <div className="fc" style={{gap: 0, flex: 1}}>
                    <span className="truncate" style={{lineHeight: "25px", maxWidth: 200}}>{user.account.city}</span>
                    <label style={{paddingLeft: 10, lineHeight: "10px"}}>{user.account.adeli}</label>
                </div>
                {renderLogo()}
            </div>

        </a>
    }

    const renderAccountSwap = () => {
        if (_.isEmpty(user.accounts)) return null;
        return <BorderedDisplay containerStyle={{borderRadius: 20}}>
            <div className="select-office fc">
                <h6 style={{margin: 0}}>Basculer vers :</h6>
                {user.accounts.map((account, index) => {
                        return <a key={`office_swap_to_${index}`} style={{borderRadius: 20}}
                                  href={`/account_swap?adeli=${account.adeli}&url=${window.location.href}`}>
                            <div className="fc" style={{gap: 0}}>
                                <span style={{fontWeight: "bold"}}>{account.city}</span>
                                <label className="center" style={{lineHeight: "15px"}}>{account.adeli}</label>
                            </div>
                        </a>
                    })}
            </div>
        </BorderedDisplay>
    }

    return <>
        {renderButton()}
        <ul id="user_dropdown" className='dropdown-content user-dropdown'>
            <li className="user_dropdown_header">
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0,
                    justifyContent: "center",
                    margin: 10
                }}>
                    <h5 style={{fontWeight: "bold", margin: 0}}>{Helper.formatName(user.firstname, user.lastname)}</h5>
                    <label style={{margin: 0}}>{user.email}</label>
                    {user.account.rpps ?<label style={{marginBlock: "0 10px", lineHeight: "12px"}}>RPPS : {user.account.rpps}</label> : null}
                    {renderAccountSwap()}
                </div>


            </li>
            <li className="divider" tabIndex="-1"></li>
            {
                ![User.INTERNAL_USER.value].includes(user.type) ?
                    <li><a data-cy="OptionPanel"
                           href={link(`administration/#/${user.type === User.SECRETARY.value ? "security" : "personal_infos"}`)}>
                        {t("SETTINGS")}</a></li> : null
            }
            <ScopedElement scope={Role.ORTHOPTIST}>
                <li>
                    <a href="/orthoptistes/statistics">{t("STATISTICS")}</a>
                </li>
            </ScopedElement>
            <ScopedElement scope={Role.ORTHOPTIST}>
                <li>
                    <a href="https://www.temeoo.fr/orthoptists/documentation/premiers-pas" target="_blank">{t("HELP")}</a>
                </li>
            </ScopedElement>
            <ScopedElement scope={Role.OPHTALMOLOGIST}>
                <li>
                    <a href="https://www.temeoo.fr/ophthalmologists/documentation/premiers-pas" target="_blank">{t("HELP")}</a>
                </li>
            </ScopedElement>
            <BrandedElement temeoo>
                <li>
                    <a href="https://temeoo.atlassian.net/servicedesk/customer/portal/1" target="_blank">
                        {t("CONTACT_SUPPORT")}
                    </a>
                </li>
            </BrandedElement>
            <li><a data-cy="MainDisconnect" href={link("sign_out")} data-method={"delete"}>{t("LOGOUT")}</a></li>
        </ul>
    </>
}

export default UserDropdown;