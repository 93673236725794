import {ActNature} from "../../Shared/Enums/Enums";

export const MesureTabsType = {
    QUESTIONS: "QUESTIONNAIRE",
    MEASURES: "MEASURES",
    FUNDUS: "FUNDUS",
    PATHOLOGY: "PATHOLOGY",
    PRESCRIPTION: "PRESCRIPTION",
    REPORT: "REPORT",
    PAYMENT: "PAYMENT",
    REEDUCATION: "REEDUCATION",
}
export const MesureTabs = {
    [ActNature.TELEMEDICAL.name]: [
        MesureTabsType.QUESTIONS,
        MesureTabsType.MEASURES,
        MesureTabsType.FUNDUS,
        MesureTabsType.PATHOLOGY,
        MesureTabsType.PRESCRIPTION,
        MesureTabsType.REPORT,
        MesureTabsType.PAYMENT

    ],
    [ActNature.ORTHOPTIC_EVALUATION.name]: [
        MesureTabsType.QUESTIONS,
        MesureTabsType.MEASURES,
        MesureTabsType.FUNDUS,
        MesureTabsType.REPORT,
        MesureTabsType.PAYMENT
    ],
    [ActNature.REEDUCATION.name]: [
        MesureTabsType.QUESTIONS,
        MesureTabsType.MEASURES,
        MesureTabsType.FUNDUS,
        MesureTabsType.REEDUCATION,
        MesureTabsType.REPORT,
        MesureTabsType.PAYMENT
    ],
    [ActNature.FUNCTIONAL_EXPLORATION.name]: [
        MesureTabsType.QUESTIONS,
        MesureTabsType.MEASURES,
        MesureTabsType.FUNDUS,
        MesureTabsType.REEDUCATION,
        MesureTabsType.REPORT,
        MesureTabsType.PAYMENT
    ],
    [ActNature.NURSE.name]: [
        MesureTabsType.QUESTIONS,
        MesureTabsType.MEASURES,
        MesureTabsType.FUNDUS,
        MesureTabsType.PATHOLOGY,
        MesureTabsType.PRESCRIPTION,
        MesureTabsType.PAYMENT
    ],
    [ActNature.SCREENING.name]: [
        MesureTabsType.QUESTIONS,
        MesureTabsType.MEASURES,
        MesureTabsType.FUNDUS,
        MesureTabsType.PATHOLOGY,
        MesureTabsType.PRESCRIPTION,
        MesureTabsType.PAYMENT
    ]
}