import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../../Shared/Loader";
import axios from "axios";
import {toast} from "react-toastify";
import AppointmentList from "./AppointmentList";

function PatientDashboard() {
    const {id} = useParams();
    const [patient, setPatient] = useState(null);
    const nav = useNavigate();

    useEffect(() => {
        loadPatient();
    }, []);

    const loadPatient = () => {
        axios.get(`/admin/console/users/patient/${id}`)
            .then(res => {
                setPatient({...res.data.patient, appointments: res.data.appointments});
            })
            .catch(_ => {
                toast.error("Une erreur est survenue lors du chargement du patient")
            })
    }

    if (!patient) return <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
        <Loader/>
    </div>

    return <div id="full-height" style={{overflowY: "clip"}}>
        <div className="valign-wrapper" style={{marginLeft: 10}}>
            <a className="btn-flat" style={{borderRadius: 20}} onClick={() => nav("/patients")}>
                <i className="material-icons">arrow_back</i>
            </a>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">personal_injury</i>
                Gestion de {Helper.formatName(patient.prenom, patient.nom)}
            </h3>
        </div>
        <div className="divider"/>
        <div className="row" style={{padding: 20, gap: 10, height: "100%"}}>
            <div className="col s12 card" style={{height: "50%", overflowY: "hidden"}}>
                <AppointmentList appointments={patient.appointments}/>
            </div>
        </div>
    </div>
}

export default PatientDashboard;