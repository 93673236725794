import React from 'react';
import RightsStore from "./RightsStore";
import Loader from "../../Shared/Loader";
import Error from "../../Shared/Error";
import PropTypes from "prop-types";

const RestrictedPage = ({children, field, permission}) => {
    if (RightsStore.can_do(field, permission) === undefined) {
        return <div id="full-height"
                    style={{margin: 50}}>
            <Loader text=""/>
        </div>
    }

    if (RightsStore.can_do(field, permission) === false) {
        return <div id="full-height"
                    style={{margin: 50}}>
            <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
        </div>
    }

    return children;
}

RestrictedPage.propTypes = {
    field: PropTypes.object.isRequired,
    permission: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
}

export default RestrictedPage;