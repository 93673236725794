import React, {useEffect} from "react";
import Helper from "../../Shared/Helper";
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import {toast} from "react-toastify";
import ADRIRightsButton from "../../CarteVitale/ADRIRightsButton";
import axios from "axios";
import moment from "moment/moment";
import Loader from "../../Shared/Loader";
import {ActType, SpecialtyCodes} from "../../Shared/Enums/Enums";
import Tooltip from "../../Shared/Tootip";
import socket from "../../Shared/socket";
import {displayMedocError} from "../../Shared/Enums/ErrorCodes";

function CreateFseButton(props) {
    const {intent, bridge_uid, ophtalmologist} = props
    const modalId = `edit-fse-modal-${intent.id}`

    const [acts, setActs] = React.useState([]);
    const [selectedAct, setSelectedAct] = React.useState(null);

    useEffect(() => {
        const modal = document.getElementById(modalId)
        const instance = M.Modal.init(modal, {onOpenStart: fetchActs})
        return () => {
            instance.destroy()
        }
    }, [])

    const closeModal = () => {
        const modal = document.getElementById(modalId)
        M.Modal.getInstance(modal).close()
    }

    const fetchActs = () => {
        axios.get("/api/acts", {params: {ids: [...intent.induced]}}).then((resp) => {
            setSelectedAct(resp.data[0])
            setActs(resp.data)
        })
    }

    const handleFseReturn = (resp) => {
        socket.off("fseCreationResponse")
        if (resp.status === "success") {
            console.debug("FSE Creation Response: ", resp.data)
            if (resp.data.bFactureCreee) {
                const medoc_id = resp.data.sNumeroFSE
                validateIntent(medoc_id)
            } else toast.info("Edition de la feuille de soin abandonnée")
        } else {
            console.debug("Error occured while creating FSE with error: ", displayMedocError(resp.errorCode));
            toast.error("Impossible de créer la feuille de soin")
        }
    }


    const validateIntent = (medoc_id) => {
        axios.patch(`/secretariat/intent/${intent.id}/validate`, {
            act_id: selectedAct.id,
            medoc_id: medoc_id
        }).then((resp) => {
            props.validateIntent(intent.id)
            closeModal()
            toast.success("La feuille de soin a bien été envoyée")
        }).catch((err) => {
            if (err.response) toast.error("Impossible de valider l'acte")
        })
    }

    const openSocket = (uid) => {
        socket.emit("machineID", {machine: uid});
    }

    const sendFseRequest = () => {
        openSocket(bridge_uid);
        const data = {
            invoiceId: intent.id, // id bound to the intent
            time: moment(intent.date).format("YYYY-MM-DDTHH:mm:ss.SSS"), // "2022-05-03T15:31:27.452",
            comment: "Feuille de soin générée par Temeoo",
            rpps: ophtalmologist.rps,
            adeli: ophtalmologist.adeli,
            patient_firstname: intent.patient_firstname,
            patient_lastname: intent.patient_lastname,
            patient_ssn: intent.patient_ssn,
            patient_birthday: Helper.formatDate(intent.patient_birthday, "YYYYMMDD"),
            patient_rank: intent.patient_birth_rank,
            patient_regime_code: intent.patient_regime_code,
            patient_fund_code: intent.patient_fund_code,
            patient_centre_code: intent.patient_centre_code,
            acts: [{
                type: selectedAct.actType,
                action: "creation",
                isValid: true,
                isBillable: selectedAct.prix !== 0,
                isFree: selectedAct.prix === 0,
                emiterId: "7", // TODO but optionnal
                actCode: selectedAct.actCode || "",
                activityCode: selectedAct.activityCode || "",
                phaseCode: selectedAct.phaseCode || "",
                associationCode: 1,
                price: selectedAct.prix * 100, // cents
                time: moment(intent.date).format("YYYY-MM-DDTHH:mm:ss.SSS"), //"2022-05-03T15:00:00.000",
                coefficient: selectedAct.coefficient,
                quantity: 1
            }],
            // prescriptor
            scor: {
                prescriptor_adeli: intent.orthoptist_adeli,
                prescriptor_specialty_code: SpecialtyCodes.ORTHOPTIST.code,
                prescription_date: moment(intent.appointment_date).format("YYYY-MM-DD"), //"2022-05-03",
                prescriptor_firstname: intent.orthoptist_firstname.toUpperCase(),
                prescriptor_lastname: intent.orthoptist_lastname.toUpperCase(),
                type: intent.orthoptist_practitioner_type, // 0 = Libéral, 1 =  Dentiste, 2 = Salarié, 3 = Bénévole
                prescriptor_structure_number: "", // empty string according to Med'OC
            }
        }
        console.debug("FSE Creation Request with data: ", data)
        socket.emit("fseCreationRequest", {
                machine: bridge_uid, data: data
            }
        )
        socket.on("fseCreationResponse", (resp) => {
            handleFseReturn(resp)
        })
    }

    const renderNgapDetails = (act) => {
        return <>
            <div className="col s6 center">
                <h6>N° Activité</h6>
                <h2 style={{margin: 5}}>{act.activityCode}</h2>
            </div>
            <div className="col s6 center">
                <h6>Coefficient</h6>
                <h2 style={{margin: 5}}>{act.coefficient}</h2>
            </div>
        </>
    }

    const renderCcamDetails = (act) => {
        return <>
            <div className="col s6 center">
                <h6>N° Activité</h6>
                <h2 style={{margin: 5}}>{act.activityCode}</h2>
            </div>
            <div className="col s6 center">
                <h6>N° Phase</h6>
                <h2 style={{margin: 5}}>{act.phaseCode}</h2>
            </div>
        </>
    }

    const chooseAct = () => {
        if (!acts || acts.length === 0) return (
            <div style={{height: "250px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Loader text="" size="small"/>
            </div>
        )
        return (
            <BorderedDisplay text="Acte" color="grey lighten-4">
                <div>
                    <div className="col s12">
                        <select className="browser-default" defaultValue={selectedAct.id}>
                            {acts.map((act, index) => {
                                    return <option key={index} value={act.id}>{act.titre}</option>
                                }
                            )}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col s6 center">
                        <h6>Nomenclature</h6>
                        <h2 style={{margin: 5}}>{selectedAct.actType}</h2>
                    </div>
                    <div className="col s6 center">
                        <h6>Prix</h6>
                        <h2 style={{margin: 5}}>{selectedAct.prix}€</h2>
                    </div>
                    {selectedAct.actType === ActType.CCAP ? renderCcamDetails(selectedAct) : renderNgapDetails(selectedAct)}
                </div>
            </BorderedDisplay>
        )
    }

    const renderPrescriptor = (intent) => {
        return <BorderedDisplay text="Requérant" color="grey lighten-4">
            <div className="row">
                <div className="col s4 center">
                    <h6>Nom</h6>
                    <h2 onClick={() => { navigator.clipboard.writeText(Helper.formatName(intent.orthoptist_firstname, intent.orthoptist_lastname))
                        .then(() => toast.success("Nom/Prénom copiés dans le presse-papier")) }}
                        style={{margin: 5, cursor: "copy"}}>
                        {Helper.formatName(intent.orthoptist_firstname, intent.orthoptist_lastname)}
                    </h2>
                </div>
                <div className="col s4 center">
                    <h6>Num. facturation</h6>
                    <h2 onClick={() => { navigator.clipboard.writeText(intent.orthoptist_adeli)
                        .then(() => toast.success("Numéro de facturation copié dans le presse-papier")) }}
                        style={{margin: 5, cursor: "copy"}}>
                        {intent.orthoptist_adeli}
                    </h2>
                </div>
                <div className="col s4 center">
                    <h6>Date examen</h6>
                    <h2 onClick={() => { navigator.clipboard.writeText(Helper.formatDate(intent.date))
                        .then(() => toast.success("Date copiée dans le presse-papier")) }}
                        style={{margin: 5, cursor: "copy"}}>
                        {Helper.formatDate(intent.date)}
                    </h2>
                </div>
            </div>
        </BorderedDisplay>
    }

    return (
        <div>
            <Tooltip id={`intent_fse_tt_${intent.id}`} text={"Éditer une feuille de soin électronique"}>
                <a className={`btn btn-small blue darken-2 z-depth-0 modal-trigger ${props.disabled ? "disabled" : ""}`}
                   data-target={modalId}><i className="material-icons">rss_feed</i></a>
            </Tooltip>
            <div id={modalId} className="modal modal-fixed-footer"
                 style={{width: "90%", borderRadius: "0", minHeight: "95%!important"}}>
                <div className="modal-content" style={{display: "flex", flexDirection: "column"}}>
                    <h4 className="center">Créer feuille de soin</h4>
                    <div className="row" style={{width: "100%", gap: 20}}>
                        <div className="col s12 l6">
                            <BorderedDisplay text="Fiche patient" color="grey lighten-4">
                                <div className="row center">
                                    <div className="col s6">
                                        <h6>Prénom</h6>
                                        <h2 style={{margin: 5}}>{Helper.toCamelCase(intent.patient_firstname) || "-"}</h2>
                                    </div>
                                    <div className="col s6">
                                        <h6>Nom</h6>
                                        <h2 style={{margin: 5}}>{Helper.toCamelCase(intent.patient_lastname) || "-"}</h2>
                                    </div>
                                    <div className="col s6">
                                        <h6>Age</h6>
                                        <h2 style={{margin: 5}}>{Helper.dateToAge(intent.patient_birthday) || "-"} ans</h2>
                                    </div>
                                    <div className="col s6">
                                        <h6>Numéro de S. Sociale</h6>
                                        <h2 style={{margin: 0}}>{intent.patient_ssn || "-"}</h2>
                                    </div>
                                    <div className="col s12">
                                        <h6>Code</h6>
                                        {
                                            !intent.patient_regime_code && !intent.patient_fund_code && !intent.patient_centre_code ?
                                                <h2 style={{margin: 5}}>-</h2>
                                                :
                                                <h2 style={{margin: 5}}>{`${intent.patient_regime_code} ${intent.patient_fund_code} ${intent.patient_centre_code}`}</h2>
                                        }
                                    </div>
                                </div>
                            </BorderedDisplay>
                            <div style={{padding: "20px 0"}}>
                                {chooseAct(intent.act)}
                            </div>
                            <div>
                                {renderPrescriptor(intent)}
                            </div>
                        </div>
                        <div className="col s12 l6">
                            <ADRIRightsButton bridge_uid={bridge_uid} user_id={ophtalmologist.id}
                                              patient={{
                                                  ssn: intent.patient_ssn,
                                                  firstname: intent.patient_firstname,
                                                  lastname: intent.patient_lastname,
                                                  birthday: intent.patient_birthday,
                                                  regime_code: intent.patient_regime_code,
                                                  fund_code: intent.patient_fund_code,
                                                  centre_code: intent.patient_centre_code
                                              }}/>
                        </div>
                    </div>
                </div>
                <div className="modal-footer" style={{display: "flex"}}>
                    <a className="btn btn-small red darken-2 z-depth-0 modal-close"
                       style={{marginLeft: "auto"}} id="fse_modal_close">Annuler</a>
                    <a className={`btn btn-small blue darken-2 z-depth-0 ${(intent.patient_ssn) && selectedAct || "disabled"}`}
                       style={{marginLeft: "10px"}} onClick={() => sendFseRequest()}
                    >Editer feuille de soin</a>
                </div>
            </div>
        </div>
    )

}

export default CreateFseButton