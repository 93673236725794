import React from "react"
import {observer} from "mobx-react";
import ConsoleDashboardStore from "../../ConsoleDashboardStore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";


const ActsFilter = observer(() => {
    return <Autocomplete
        disablePortal
        className="ui-autocomplete"
        multiple
        value={ConsoleDashboardStore.selectedActs}
        options={ConsoleDashboardStore.actList}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        getOptionLabel={(option) => option.short}
        filterSelectedOptions
        onChange={(_, array) => ConsoleDashboardStore.selectedActs = array}
        sx={{width: "300px"}}
        renderInput={(params) => <TextField {...params} label="Sélectionner actes"/>}
    />
})

export default ActsFilter