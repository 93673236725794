import React from "react";

function PricesOverview({prices, fixed, variable, pricing}) {

    return <div style={{display: "flex", flexDirection: "column"}}>
        <h4 style={{margin: 0}}>Revenus par acte</h4>
        <label>Ce tableau recense vos gains pour chaque acte réalisé</label>
        <table className="bordered striped highlight">
            <thead>
            <tr>
                <th>Acte</th>
                <th>Gains</th>
            </tr>
            </thead>
            <tbody>
            {prices?.sort((a, b) => b.income - a.income).map((price, index) => <tr key={index}>
                <td>{price.act_name}</td>
                <td>{price.income.toFixed(2)} €</td>
            </tr>)}
            </tbody>
        </table>
    </div>
}

export default PricesOverview;