import React, {useState} from 'react';
import StatusIcon from "../../../Appointments/StatusIcon";
import AppointmentDetails from "./AppointmentDetails";
import DOMPurify from "dompurify";
import ActionButtonsOrthoptist from "./ActionButtonsOrthoptist";
import ActionButtonsOphtalmo from "./ActionButtonsOphtalmo";
import Helper from "../../../Shared/Helper";
import AppointmentDate from "./AppointmentDate";
import AppointmentMetadata from "./AppointmentMetadata";

const Appointment = (props) => {
    const [appointment, setAppointment] = useState(props.appointment)
    const [expanded, setExpanded] = useState(false)
    if (!appointment.done || appointment.archived) return null


    const renderStatus = () => {
        return <div className="valign-wrapper" style={{position: "relative"}}>
            <div style={{height: "100%", width: 2, backgroundColor: "grey", position: "absolute", left: 11}}/>
            <div style={{zIndex: 0}} className="valign-wrapper">
                <StatusIcon status={appointment.status_controle} act={appointment.act}/>
            </div>
        </div>
    }

    const detail = () => {
        if(expanded) return null
        const anamnesis = appointment.comment
        const orthoptic_text = appointment.orthoptic_text

        let text = ""
        if (anamnesis) {
            text += "<b>Anamnèse:</b> " + anamnesis + "<br>"
        }
        if (orthoptic_text) {
            text += "<b>Orthoptie:</b> " + orthoptic_text + "<br>"
        }
        return text.length > 200 ? text.substring(0, 100) + "..." : text
    }

    return <>
        <div className="fr" style={{paddingBlock: 0}} key={`appointment-${appointment.id}-date`}>
            <div className="valign-wrapper" style={{position: "relative"}}>
                <div style={{
                    width: 2, backgroundColor: "grey",
                    position: "absolute", left: 11, top: -1, bottom: -1
                }}/>
                <div style={{
                    position: "absolute", left: 2.5,
                    height: 30, width: 20, backgroundColor: "white"
                }}/>
                <div style={{
                    position: "absolute", left: 4.5,
                    height: 14, width: 14, backgroundColor: "black", borderRadius: 7.5
                }}/>
            </div>
            <div className="valign-wrapper" style={{minHeight: 40, marginLeft: 20, flex: 1}}>
                <AppointmentMetadata appointment={appointment} appointmentChanged={setAppointment}/>
            </div>
        </div>
        <div className="divider" style={{marginLeft: 30}}/>
        <div style={{paddingBlock: 0}} key={`appointment-${appointment.id}-line`}>
            <div className="fr">
                {renderStatus()}
                <div className="fc" style={{width: "100%", gap: 0}}>
                    <div className="fr valign-wrapper" style={{minHeight: 60, gap: 20}}>
                        {/*Not expanded*/}
                        <div className="fr valign-wrapper clickable" style={{flex: 1, gap: 20, height: "100%"}}
                             onClick={() => setExpanded(!expanded)}>
                            <div>
                                {appointment.act ? appointment.act.titre.toUpperCase() : "Aucun acte"}
                            </div>
                            <div style={{flex: 1}}>
                                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(detail())}}/>
                            </div>
                        </div>
                        <ActionButtonsOrthoptist appointment={appointment} appointmentChanged={setAppointment}/>
                        <ActionButtonsOphtalmo appointment={appointment} appointmentChanged={setAppointment}/>
                        <a className="btn-small btn-flat transparent" onClick={() => setExpanded(!expanded)}>
                            <i className={`material-icons ${expanded ? "flipped" : ""}`}>expand_more</i>
                        </a>
                    </div>
                    <div className={expanded ? "" : "hide"} style={{minHeight: 150}}>
                        <AppointmentDetails appointment={appointment}/>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Appointment;

