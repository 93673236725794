import React from "react";
import Turnstile, {useTurnstile} from "react-turnstile";

const TurnstileWidget = ({onTokenAcquisition, style}) => {
    const siteKey = process.env.CF_TURNSTILE_PK
    const turnstile = useTurnstile();

    const isMobilePhone = window.matchMedia("only screen and (max-width: 760px)").matches;
    const inner_style = isMobilePhone ? {} : {position: "fixed", bottom: 0, right: 0}

    return <div style={{...inner_style, ...style}}>
        <Turnstile sitekey={siteKey} onVerify={(token) => onTokenAcquisition(token, turnstile)} theme="light"/>
    </div>
}

TurnstileWidget.defaultProps = {
    onTokenAcquisition: (token, turnstile) => null,
    style: {}
}

export default TurnstileWidget;