import React from "react";

const _ = require("lodash");
const DocumentImg = ({appointment}) => {
    if (_.isEmpty(appointment?.image)) return null
    return <>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                marginBlock: 20,
                backgroundColor: "black",
                padding: 20,
                borderRadius: 20,
            }}>
            <div className="row" style={{width: "100%"}}>
                {appointment?.image.map((img, index) => {
                    return <div className="col s6" style={{display: "flex", justifyContent: "center"}}>
                        <img style={{width: "300px", float: "left"}} src={img} alt={"image n°" + (index + 1)}/>
                    </div>
                })}
            </div>
        </div>
        { appointment.comment_fo_od || appointment.comment_fo_og ?
            <div style={{gap: 20}}>
                <p><b>Commentaire FO droit :</b> {appointment.comment_fo_od ? appointment.comment_fo_od : "Aucun commentaire laissé pour cette oeil"}</p>
                <p><b>Commentaire FO gauche :</b> {appointment.comment_fo_og ? appointment.comment_fo_og : "Aucun commentaire laissé pour cette oeil"}</p>
            </div>
            :
            <></>
        }
    </>

};

export default DocumentImg;
