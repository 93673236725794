import React, {Component} from "react";
import Helper from "../../Shared/Helper";

export default class Notification extends Component {

    constructor(props) {
        super(props);
    }

    computeNotification = (type) => {
        switch (type) {
            case "ALERT":
                return {text: "Une alerte a été signalée par votre ophtalmologiste lecteur", icon: "warning", link: ""}
            case "BILLING_ERROR":
                return {
                    text: "Une erreur s'est produite lors de votre dernier paiement",
                    icon: "error",
                    link: "/orthoptistes/administration/#/billing"
                }
            case "INVOICE_READY":
                return {
                    text: "Votre facture mensuelle est disponible",
                    icon: "receipt_long",
                    link: "/orthoptistes/administration/#/billing"
                }
            case "STRIPE_NOT_INITIALIZED":
                return {
                    text: "Vos informations de facturation ne sont pas renseignées",
                    icon: "credit_card",
                    link: "/orthoptistes/paiements/entrer_carte"
                }
            default:
                return null;
        }
    }

    render() {
        const notification = this.computeNotification(this.props.type)
        return (
            <div>
                <a href={notification.link} style={{backgroundColor: "transparent", padding: 0}}>
                    <div style={{fontSize: "15px"}}>
                        <i className="large material-icons left"
                           style={{fontSize: "35px", color: "#382be7"}}>{notification.icon}</i>
                        <div>
                            {notification.text}
                            <div style={{display: "flex"}}>
                                <h6 className="grey-text text-darken-3"
                                    style={{fontSize: "0.7em", fontStyle: "italic"}}>
                                    {Helper.computeDate(new Date(this.props.date))}
                                </h6>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}

Notification.defaultProps = {
    type: "",
    date: "",
}