import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {RequestState} from "../../../../Shared/StateHelper";

const useBillingCategories = () => {
    const [billingCategories, setBillingCategories] = useState([]);
    const [requestState, setRequestState] = useState(RequestState.IDLE);

    useEffect(() => {
        get()
    }, []);

    const get = () => {
        setRequestState(RequestState.LOADING);
        axios.get("/admin/console/filters_data/formulas")
            .then((response) => {
                setRequestState(RequestState.SUCCESS);
                setBillingCategories(response.data.formulas)
            })
            .catch((_) => {
                setRequestState(RequestState.ERROR);
                toast.error("Erreur dans le chargement des formules")
            })
    }

    return {billingCategories, state: requestState};
}

export default useBillingCategories;