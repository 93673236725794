import React, {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Regex from "../Shared/Validation/Regex";
import {PractitionerTypes, SpecialtyCodes} from "../Shared/Enums/Enums";

function AddPrescriptorButton({onSave, onCancel, disabled, editedPrescriptor}) {
    const [onEdit, setOnEdit] = React.useState(false);
    const [prescriptor, setPrescriptor] = React.useState({});
    const isUpdating = !!editedPrescriptor;

    useEffect(() => {
        setPrescriptor(editedPrescriptor ?? {});
        if (!!editedPrescriptor) setOnEdit(true);
    }, [editedPrescriptor]);

    useEffect(() => {
        const elems = document.getElementById('specialty_select');
        const form = M.FormSelect.init(elems, {});
        return () => {
            form?.destroy();
        }
    }, []);

    const savePrescriptor = () => {
        if (prescriptor.rpps && !Regex.validateRpps(prescriptor.rpps)) {
            toast.error("Le RPPS doit être composé de 11 chiffres");
            return
        }

        if (prescriptor.adeli && !Regex.validateAdeli(prescriptor.adeli)) {
            toast.error("Le numéro de facturation doit être composé de 9 chiffres");
            return
        }

        axios.put('/prescripteurs/create', {...prescriptor, appointment_id: MesureStore.appointement_id}).then(res => {
            onSave(res.data.prescriptor);
            setOnEdit(false);
        }).catch(_ => toast.error("Erreur lors de la création du prescripteur"))
    }

    const editPrescriptor = () => {
        if (prescriptor.rpps && !Regex.validateRpps(prescriptor.rpps)) {
            toast.error("Le RPPS doit être composé de 11 chiffres");
            return
        }

        if (prescriptor.adeli && !Regex.validateAdeli(prescriptor.adeli)) {
            toast.error("Le numéro de facturation doit être composé de 9 chiffres");
            return
        }

        axios.patch(`/prescripteurs/update/${prescriptor.id}`, {...prescriptor, appointment_id: MesureStore.appointement_id}).then(res => {
            onSave(res.data.prescriptor);
            setOnEdit(false);
        }).catch(_ => toast.error("Erreur lors de la création du prescripteur"))
    }

    const cancel = () => {
        setPrescriptor({})
        setOnEdit(false);
        onCancel()
    }

    if (!onEdit) return <a className={`btn blue darken-2 z-depth-0 ${disabled ? "disabled" : ""}`}
                           onClick={() => setOnEdit(true)}>
        <i className="material-icons left">add</i>
        Nouveau prescripteur
    </a>

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <div className="row" style={{gap: "10px 10px"}}>
                <div className="input-field col s6 outlined">
                    <input value={prescriptor.firstname}
                           onChange={e => setPrescriptor({...prescriptor, firstname: e.target.value})}/>
                    <label htmlFor="first_name">Prénom *</label>
                </div>
                <div className="input-field col s6 outlined">
                    <input value={prescriptor.lastname}
                           onChange={e => setPrescriptor({...prescriptor, lastname: e.target.value})}/>
                    <label htmlFor="last_name">Nom *</label>
                </div>
                <div className="input-field col s12 outlined">
                    <input value={prescriptor.email}
                           onChange={e => setPrescriptor({...prescriptor, email: e.target.value})}/>
                    <label htmlFor="last_name">Email</label>
                </div>
                <div className="input-field col s6 outlined">
                    <input value={prescriptor.rpps}
                           onChange={e => setPrescriptor({...prescriptor, rpps: e.target.value})}/>
                    <label htmlFor="first_name">RPPS *</label>
                </div>
                <div className="input-field col s6 outlined">
                    <input value={prescriptor.adeli}
                           onChange={e => setPrescriptor({...prescriptor, adeli: e.target.value})}/>
                    <label htmlFor="last_name">Num. Facturation *</label>
                </div>
                <div className="input-field col s6 outlined">
                    <select className="browser-default" defaultValue={-1}
                            style={{borderRadius: 4, border: "1px solid #707070"}}
                            value={prescriptor.specialty_code}
                            onChange={e => setPrescriptor({...prescriptor, specialty_code: e.target.value})}>
                        <option value={-1} disabled={true}>Code spécialité *</option>
                        {Object.values(SpecialtyCodes).map((specialty, index) => {
                            return <option key={index}
                                           value={specialty.code}>{specialty.code} - {specialty.name}</option>
                        })}
                    </select>
                </div>
                <div className="input-field col s6 outlined">
                    <select className="browser-default" defaultValue={-1}
                            style={{borderRadius: 4, border: "1px solid #707070"}}
                            value={prescriptor.practitioner_type}
                            onChange={e => setPrescriptor({...prescriptor, practitioner_type: e.target.value})}>
                        <option value={-1} disabled={true}>Type de practicien *</option>
                        {Object.values(PractitionerTypes).map((type, index) => {
                            return <option key={index}
                                           value={type.code}>{type.name}</option>
                        })}
                    </select>
                </div>
            </div>
            <label>(*) Champs obligatoires pour la télétransmission</label>
            <div className="row">
                <a className="btn green darken-2 z-depth-0 col s12 l6" onClick={isUpdating ? editPrescriptor : savePrescriptor}>
                    <i className="material-icons left">{isUpdating ? "edit" : "save"}</i>
                    {isUpdating ? "Editer" : "Enregistrer"}
                </a>
                <a className="btn red darken-2 z-depth-0 col s12 l6" onClick={cancel}>
                    <i className="material-icons left">cancel</i>
                    Annuler
                </a>
            </div>
        </div>

    );
}

AddPrescriptorButton.defaultProps = {
    disabled: false
};


export default AddPrescriptorButton;