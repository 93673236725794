import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom";
import Helper from "../../Shared/Helper";
import {observer} from "mobx-react";
import MedicalEventStore from "../MedicalEventStore";
import Examination from "./Examination";
import {toast} from "react-toastify";
import ActSelection from "../../Appointments/Mesures/mesure_components/ActSelection";
import SelectAct from "../Appointment/SelectAct";

const PerformExamination = observer(() => {
    const {appointment_id} = useParams();
    const nav = useNavigate();
    const [patient, setPatient] = useState({})

    useEffect(() => {
        setPatient(MedicalEventStore.patients.find(p => p.appointment_id === parseInt(appointment_id)) ?? {})
    }, [MedicalEventStore.patients]);


    const validate = () => {
        MedicalEventStore.saveAppointment({...patient, done: true}).then(() => {
            nav("/")
        }).catch(() => {
            toast.error("Erreur lors de l'enregistrement du dossier patient")
        })
    }

    return <div style={{display: "flex", flexDirection: "column"}}>
        <div className="valign-wrapper" style={{gap: 20, padding: 5}}>
            <a className="btn-small btn-flat grey lighten-4 grey-text text-darken-2 z-depth-0"
               onClick={() => {
                nav("/")
            }}>
                <i className="material-icons left">list</i>
                Liste des patients
            </a>
            <h5 style={{margin: 0}}>Dossier de {Helper.formatName(patient.firstname, patient.lastname)}</h5>
            <SelectAct/>
            <a className="btn-small blue darken-2 z-depth-0" onClick={() => {
                validate()
            }} style={{marginLeft: "auto"}}>
                <i className="material-icons left">save</i>
                Terminer
            </a>

            <a className="btn-small btn-flat grey lighten-4 grey-text text-darken-2 z-depth-0"
               onClick={() => nav(`/appointment/${appointment_id}/report`)}>
                Passer au questionnaire
                <i className="material-icons right">chevron_right</i>
            </a>
        </div>
        <div className="divider"/>
        <div style={{padding: 20}}>
            <Examination/>
        </div>
    </div>
})

export default PerformExamination