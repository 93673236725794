import React from 'react';
import Helper from "../../Shared/Helper";

const CampaignDisplay = ({campaign, preview}) => {
    const distance = preview ? 1000 : campaign.distance;

    const renderImage = () => {
        if (!campaign.image_url) return null;
        return <div className="center" style={{width: "100%", maxHeight: 200, overflowY: "hidden"}}>
            <img src={campaign.image_url} alt={campaign.name} width="100%"
                 style={{
                     objectFit: "cover",
                     objectPosition: "center",
                     borderRadius: "10px 10px 0 0",
                     maxWidth: "100%",
                     height: "100%"
                 }}
            />
        </div>
    }

    return <div className="card"
                style={{
                    padding: 0,
                    margin: 10,
                    // border: "1px solid grey",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative"
                }}>
        {/*border only bottom and left*/}
        <h6 className="right" style={{
            alignSelf: "flex-end",
            margin: 0, fontSize: "10px", padding: 7, border: "1px solid grey", borderRadius: "0 10px 0 10px",
            borderColor: "transparent transparent lightgrey lightgrey",
            position: "absolute", top: 0, right: 0, backgroundColor: "white"
        }}>
            À {Helper.formatDistance(distance)}
        </h6>
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            paddingBlock: "0 10px",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center"
        }}>
            {renderImage()}
            {campaign.text ? <div style={{flex: 1, paddingInline: 20}}>
                <span style={{fontSize: "1.8vb", lineHeight: "1.8vb"}}>{campaign.text.trim()}</span>
            </div> : null}
            <div className="valign-wrapper" style={{width: "fit-content"}}>
                <a className="btn-large blue darken-2 truncate" href={`/campaigns/redirect/${campaign.id}`}
                   target="_blank">
                    <i className="material-icons right">play_arrow</i>
                    Voir plus
                </a>
            </div>
        </div>

    </div>
}

CampaignDisplay.defaultProps = {
    preview: false
}

export default CampaignDisplay;