import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Act, ActNature} from "../../../Shared/Enums/Enums";
import {t} from "../../../I18n/i18n";
import ScopedElement from "../../../Shared/ScopedElement";
import {Role} from "../../../Shared/Enums/Scope";
import Appointment from "./Appointment";
import NewAppointment from "./NewAppointment";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../admin/Controle/Loader";
import Error from "../../../Shared/Error";

const ExamHistory = ({patient_id}) => {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content

    const [filter, setFilter] = useState({
        expanded: false,
        show_canceled: true,
        act_types: [ActNature.TELEMEDICAL, ActNature.ORTHOPTIC_EVALUATION, ActNature.REEDUCATION, ActNature.FUNCTIONAL_EXPLORATION]
    })
    const [appointments, setAppointments] = useState([]);
    const [requestState, setRequestState] = useState(RequestState.IDLE);

    useEffect(() => {
        get_appointments();
    }, []);

    const get_appointments = () => {
        setRequestState(RequestState.LOADING);
        axios.get(`/patients/${patient_id}/appointments`).then((response) => {
            setAppointments(response.data);
            setRequestState(RequestState.SUCCESS);
        })
    }

    const filterAppointments = (appointments) => {
        return appointments.filter(appointment => {
            const act = appointment.act
            if (!filter.show_canceled && (!act || act.titre.trim() === Act.NOTHING)) return false
            if (!act) return true
            if ([ActNature.NURSE, ActNature.SCREENING].map(n => n.name).includes(act.nature)) return true
            const filter_natures = filter.act_types.map(act => act.name)
            return filter_natures.includes(act.nature);
        })
    }

    const renderFilter = () => {
        if (!filter.expanded) return null
        return <div className="valign-wrapper" style={{gap: 20, marginTop: 20}}>
            {[ActNature.TELEMEDICAL, ActNature.ORTHOPTIC_EVALUATION, ActNature.REEDUCATION, ActNature.FUNCTIONAL_EXPLORATION].map((act_type, index) => {
                const toggle = () => {
                    if (filter.act_types.includes(act_type)) {
                        setFilter(prevState => {
                            return {...prevState, act_types: prevState.act_types.filter(act => act !== act_type)}
                        })
                    } else {
                        setFilter(prevState => {
                            return {...prevState, act_types: [...prevState.act_types, act_type]}
                        })
                    }
                }
                return <label>
                    <input type="checkbox" className="filled-in"
                           checked={filter.act_types.includes(act_type)}
                           onChange={toggle}/>
                    <span>{act_type.short}</span>
                </label>
            })}
            <label>
                <input type="checkbox" className="filled-in"
                       checked={filter.show_canceled}
                       onChange={() => setFilter(prevState => {
                           return {...prevState, show_canceled: !prevState.show_canceled}
                       })}/>
                <span>{t("EXAM_HISTORY.EXAM_NOT_PERFORMED")}</span>
            </label>
        </div>
    }

    const renderAppointments = () => {
        if (requestState === RequestState.LOADING) return <div className="center" style={{marginTop: "20px"}}>
            <Loader/>
        </div>
        if (requestState === RequestState.ERROR) return <div className="center" style={{marginTop: "20px"}}>
            <Error errorText={"Une erreur est survenue lors du chargement des examens"}/>
        </div>
        if (appointments.length === 0) return <p className="center">{t("EXAM_HISTORY.WITHOUT_EXAM")}</p>
        return <ul className="collection">
            <NewAppointment appointments_todo={appointments.filter(appt => !appt.done)}/>
            {filterAppointments(appointments).map((appointment) => {
                return <div className="collection-item" style={{paddingBlock: 0}} key={`appointment-${appointment.id}-group`}>
                    <Appointment appointment={appointment}/>
                </div>
            })}
        </ul>
    }

    return <div className="card fc dropdown-wrapper" style={{height: '100%'}}
                data-cy="ExamHistoryComponent">
        <div className="valign-wrapper">
            <div style={{flex: 1}}>
                <h2 className="center"
                    style={{marginTop: 11, marginBottom: 3}}>{t("EXAM_HISTORY.EXAM_HISTORY_TITLE")}</h2>
            </div>
            <a className="btn-flat btn-small transparent z-depth-0" onClick={() => setFilter(prevState => {
                return {...prevState, expanded: !prevState.expanded}
            })}>
                <i className="material-icons">filter_list</i>
            </a>
        </div>
        <div style={{marginInline: 20}}>
            {renderFilter()}
        </div>
        <div style={{flex: "1 1 0", overflowY: "scroll"}}>
            {renderAppointments()}
        </div>
        <ScopedElement scope={[Role.ORTHOPTIST]}>
            <div className="center" style={{width: "100%"}}>
                <a className="btn btn-small blue darken-2 z-depth-0"
                   title={`Lancer une nouvelle consultation`}
                   href={`/NewAppointement/${patient_id}`}>
                    <i className="material-icons left">add</i>{t("EXAM_HISTORY.NEW_CONSULTATION")}
                </a>
            </div>
        </ScopedElement>
    </div>
}

export default ExamHistory;